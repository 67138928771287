import { useFormikContext } from 'formik';
import * as R from 'ramda';
import type { MafSearchQuery } from '~/apollo/generated/v3/graphql';
import type { MAFToolFormValues } from '~/routes/modern/index';
import { classificationColor } from '~/utils/modules/modern';

type Props = {
  coordinates: MafSearchQuery['modernAnalogueFinderSearch']['coordinates'];
};

export function ModernMapLegend({ coordinates }: Props) {
  const {
    values: { gde },
  } = useFormikContext<MAFToolFormValues>();

  if (!coordinates.length) return null;

  const classifications = R.pipe(
    R.pluck('classification'),
    R.uniq,
  )(coordinates);

  return (
    <div className="flex gap-2 p-6 my-4 bg-slate-100 justify-center items-center">
      Legend
      {classifications.map(classification => (
        <div
          key={classification}
          className="flex gap-2 items-center text-lg text-base-content"
        >
          <span
            className="h-4 w-4 rounded-lg inline-block"
            style={{
              backgroundColor: classificationColor(gde, classification),
            }}
          />
          <div className="inline-block mr-4 font-bold">{classification}</div>
        </div>
      ))}
    </div>
  );
}
