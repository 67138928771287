import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';
import React from 'react';

import * as fragments from '~/apollo/fragments';
import type {
  OutcropTagFieldQuery,
  OutcropTagFieldQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

const OUTCROP_TAG_FIELD = gql`
  query OutcropTagField($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      outcrops {
        ...outcropParts
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.outcropParts}
`;

type Props = FieldProps & {
  studyId: number;
};

export function OutcropTagField({ form, field, studyId, ...props }: Props) {
  const { data, loading } = useQuery<
    OutcropTagFieldQuery,
    OutcropTagFieldQueryVariables
  >(OUTCROP_TAG_FIELD, {
    variables: { studyId },
  });

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const outcrops = study?.outcrops ?? [];
  const options = outcrops.map(oc => ({ value: oc.id, label: oc.name }));

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    form.setFieldValue(field.name, ~~e.target.value || '');

  return (
    <FormikSelectField
      field={field}
      form={form}
      {...props}
      onChange={onChange}
      options={options}
      disabled={loading}
    />
  );
}
