import { GoogleMap, InfoWindowF, PolygonF } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Link } from 'react-router';
import type {
  GeoreferencePartsFragment,
  OutcropOverviewTabQuery,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { studyRoute, vomRoute } from '~/paths';
import { rejectNil } from '~/utils/common';
import {
  createBounds,
  createEntity,
  georefColors,
  markerIcon,
  polygonOptions,
  sortGeoreferenceVisibility,
} from '~/utils/georeference';
import type { VisibilityFilter, VomWithOutline } from './index';

const mapContainerStyle = {
  width: '100%',
  height: '550px',
};

type Outcrop = OutcropOverviewTabQuery['outcropList'][number];

type StudyCrossSections = {
  studyId: number;
  crossSections: Outcrop['studies'][number]['crossSections'];
};
type StudySedimentaryLogs = {
  studyId: number;
  sedimentaryLogs: Outcrop['studies'][number]['sedimentaryLogs'];
};
type StudyGigaPans = {
  studyId: number;
  gigaPans: Outcrop['studies'][number]['gigaPans'];
};

type Props = {
  outcrop: Outcrop;
  outcropOutline: GeoreferencePartsFragment | null;
  vomsWithOutlines: VomWithOutline[];
  crossSections: Outcrop['crossSections'];
  sedimentaryLogs: Outcrop['sedimentaryLogs'];
  gigaPans: Outcrop['gigaPans'];
  studyCrossSections: StudyCrossSections[];
  studySedimentaryLogs: StudySedimentaryLogs[];
  studyGigaPans: StudyGigaPans[];
};

export function GeoreferenceMap({
  outcrop,
  outcropOutline,
  vomsWithOutlines,
  crossSections,
  sedimentaryLogs,
  gigaPans,
  studyCrossSections,
  studySedimentaryLogs,
  studyGigaPans,
}: Props) {
  const { values } = useFormikContext<VisibilityFilter>();
  const [map, setMap] = useState<google.maps.Map>();
  const [infoWindow, setInfoWindow] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (map) {
      const georefsForBounds: GeoreferencePartsFragment[] = [];

      if (values.outcropOutline && outcropOutline) {
        georefsForBounds.push(outcropOutline);
      }
      if (values.voms) {
        const vomGeoreferences = vomsWithOutlines.flatMap(
          ({ outline }) => outline,
        );
        vomGeoreferences.forEach(g => georefsForBounds.push(g));
      }
      if (values.crossSections) {
        crossSections
          .concat(studyCrossSections.flatMap(scs => scs.crossSections))
          .flatMap(cs => cs.georeference)
          .forEach(g => georefsForBounds.push(g));
      }
      if (values.sedimentaryLogs) {
        sedimentaryLogs
          .concat(studySedimentaryLogs.flatMap(ssl => ssl.sedimentaryLogs))
          .flatMap(sl => sl.georeference)
          .forEach(g => georefsForBounds.push(g));
      }
      if (values.gigaPans) {
        gigaPans
          .concat(studyGigaPans.flatMap(sgp => sgp.gigaPans))
          .flatMap(gp => gp.georeference)
          .forEach(g => georefsForBounds.push(g));
      }

      const bounds = createBounds(georefsForBounds);
      map.fitBounds(bounds);
    }
  }, [
    map,
    outcropOutline,
    vomsWithOutlines,
    crossSections,
    sedimentaryLogs,
    gigaPans,
    studyCrossSections,
    studySedimentaryLogs,
    studyGigaPans,
    values.outcropOutline,
    values.voms,
    values.crossSections,
    values.sedimentaryLogs,
    values.gigaPans,
  ]);

  function onLoad(m: google.maps.Map) {
    setMap(m);

    const georefs: GeoreferencePartsFragment[] = [
      outcropOutline,
      ...vomsWithOutlines.map(vwo => vwo.outline),
    ].filter(rejectNil);

    const bounds = createBounds(georefs);
    m.fitBounds(bounds);
  }

  function handleOutcropOutlineClick(event: google.maps.MapMouseEvent) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>{outcropOutline?.name}</Heading>
          {outcropOutline?.description && <p>{outcropOutline?.description}</p>}
        </>
      </InfoWindowF>,
    );
  }

  function handleVomClick(
    vom: Outcrop['virtualOutcropModels'][number],
    event: google.maps.MapMouseEvent,
  ) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>{vom.name}</Heading>
          <div className="space-y-0">
            <Link to={vomRoute(outcrop.id, vom.id)} className="link">
              View Virtual Outcrop &raquo;
            </Link>
          </div>
        </>
      </InfoWindowF>,
    );
  }

  function handleSOClicked(
    label: string,
    so: { name: string },
    georeference: GeoreferencePartsFragment,
    event: google.maps.MapMouseEvent,
  ) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>{label}</Heading>
          <div className="space-y-0">
            <p>
              <strong>{so.name}</strong>
            </p>
            <p>{georeference.name}</p>
            {georeference.description && <p>{georeference.description}</p>}
          </div>
        </>
      </InfoWindowF>,
    );
  }

  function handleStudySOClicked(
    label: string,
    studyId: number,
    so: { name: string },
    georeference: GeoreferencePartsFragment,
    event: google.maps.MapMouseEvent,
  ) {
    const position = event.latLng;
    if (!position) {
      setInfoWindow(null);
      return;
    }

    setInfoWindow(
      <InfoWindowF position={position} onCloseClick={() => setInfoWindow(null)}>
        <>
          <Heading level={4}>{label}</Heading>
          <div className="space-y-0">
            <p>
              <strong>{so.name}</strong>
            </p>
            <p>{georeference.name}</p>
            {georeference.description && <p>{georeference.description}</p>}
            <p>
              <Link to={studyRoute(studyId)} className="link">
                View Study &raquo;
              </Link>
            </p>
          </div>
        </>
      </InfoWindowF>,
    );
  }

  return (
    <GoogleMap
      id="outcropMap"
      onLoad={onLoad}
      mapContainerStyle={mapContainerStyle}
      options={{
        scaleControl: true,
        mapTypeId: 'terrain',
        streetViewControl: false,
        fullscreenControl: true,
      }}
    >
      {infoWindow}

      {values.outcropOutline && outcropOutline && (
        <PolygonF
          path={outcropOutline.data}
          options={polygonOptions()}
          onClick={handleOutcropOutlineClick}
        />
      )}

      {values.voms &&
        vomsWithOutlines.map(({ vom, outline }) => (
          <PolygonF
            key={vom.id}
            path={outline.data}
            options={polygonOptions()}
            onClick={e => handleVomClick(vom, e)}
          />
        ))}

      {values.crossSections && (
        <>
          {crossSections.map(cs =>
            cs.georeference
              .slice()
              .sort(sortGeoreferenceVisibility)
              .map(georef =>
                createEntity(georef, {
                  sharedProps: {
                    key: georef.id,
                    onClick: e =>
                      handleSOClicked('Cross Section', cs, georef, e),
                  },
                }),
              ),
          )}

          {studyCrossSections.map(scs =>
            scs.crossSections.map(cs =>
              cs.georeference
                .slice()
                .sort(sortGeoreferenceVisibility)
                .map(g =>
                  createEntity(g, {
                    sharedProps: {
                      key: g.id,
                      onClick: e =>
                        handleStudySOClicked(
                          'Cross Section',
                          scs.studyId,
                          cs,
                          g,
                          e,
                        ),
                    },
                    markerProps: {
                      icon: markerIcon({
                        stroke: georefColors.study,
                        fill: georefColors.study,
                      }),
                    },
                    polygonProps: {
                      options: polygonOptions({
                        strokeColor: georefColors.study,
                      }),
                    },
                    polylineProps: {
                      options: polygonOptions({
                        strokeColor: georefColors.study,
                      }),
                    },
                  }),
                ),
            ),
          )}
        </>
      )}

      {values.sedimentaryLogs && (
        <>
          {sedimentaryLogs.map(sl =>
            sl.georeference
              .slice()
              .sort(sortGeoreferenceVisibility)
              .map(georef =>
                createEntity(georef, {
                  sharedProps: {
                    key: georef.id,
                    onClick: e =>
                      handleSOClicked('Sedimentary Log', sl, georef, e),
                  },
                }),
              ),
          )}

          {studySedimentaryLogs.map(ssl =>
            ssl.sedimentaryLogs.map(sl =>
              sl.georeference
                .slice()
                .sort(sortGeoreferenceVisibility)
                .map(g =>
                  createEntity(g, {
                    sharedProps: {
                      key: g.id,
                      onClick: e =>
                        handleStudySOClicked(
                          'Study Sedimentary Log',
                          ssl.studyId,
                          sl,
                          g,
                          e,
                        ),
                    },
                    markerProps: {
                      icon: markerIcon({
                        stroke: georefColors.study,
                        fill: georefColors.study,
                      }),
                    },
                    polygonProps: {
                      options: polygonOptions({
                        strokeColor: georefColors.study,
                      }),
                    },
                    polylineProps: {
                      options: polygonOptions({
                        strokeColor: georefColors.study,
                      }),
                    },
                  }),
                ),
            ),
          )}
        </>
      )}
      {values.gigaPans && (
        <>
          {gigaPans.map(gp =>
            gp.georeference
              .slice()
              .sort(sortGeoreferenceVisibility)
              .map(georef =>
                createEntity(georef, {
                  sharedProps: {
                    key: georef.id,
                    onClick: e => handleSOClicked('Panorama', gp, georef, e),
                  },
                }),
              ),
          )}

          {studyGigaPans.map(sgp =>
            sgp.gigaPans.map(gp =>
              gp.georeference
                .slice()
                .sort(sortGeoreferenceVisibility)
                .map(g =>
                  createEntity(g, {
                    sharedProps: {
                      key: g.id,
                      onClick: e =>
                        handleStudySOClicked(
                          'Study Panorama',
                          sgp.studyId,
                          gp,
                          g,
                          e,
                        ),
                    },
                    markerProps: {
                      icon: markerIcon({
                        stroke: georefColors.study,
                        fill: georefColors.study,
                      }),
                    },
                    polygonProps: {
                      options: polygonOptions({
                        strokeColor: georefColors.study,
                      }),
                    },
                    polylineProps: {
                      options: polygonOptions({
                        strokeColor: georefColors.study,
                      }),
                    },
                  }),
                ),
            ),
          )}
        </>
      )}
    </GoogleMap>
  );
}
