import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { Outlet, useOutletContext } from 'react-router';

import * as fragments from '~/apollo/fragments';
import type {
  AesTabQuery,
  AesTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

const AES_TAB = gql`
  query AesTab($id: Int!) {
    studyList(id: $id) {
      ...studyParts
      architecturalElements {
        ...aeParts
        isTrashed
        validKeyParameters
        measurements {
          ...measurementParts
          outcropArchitecturalElement {
            name
          }
        }
        outcrop {
          ...outcropParts
        }
      }
      outcrops {
        ...outcropParts
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.aeParts}
  ${fragments.measurementParts}
  ${fragments.outcropParts}
`;

type StudyType = AesTabQuery['studyList'][number];
type OutletContext = {
  study: StudyType;
  architecturalElements: StudyType['architecturalElements'];
  outcrops: StudyType['outcrops'];
  refetchQueries: PureQueryOptions[];
};

export default function UploadStudyUpdateArchitecturalElementsRoute() {
  const ctx = useUpdateStudyOutletContext();

  const { data, loading } = useQuery<AesTabQuery, AesTabQueryVariables>(
    AES_TAB,
    {
      variables: { id: ctx.study.id },
    },
  );

  const refetchQueries: [PureQueryOptions<AesTabQueryVariables>] = [
    { query: AES_TAB, variables: { id: ctx.study.id } },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === ctx.study.id);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  const outletContext: OutletContext = {
    study,
    architecturalElements: study.architecturalElements,
    outcrops: study.outcrops,
    refetchQueries,
  };

  return <Outlet context={outletContext} />;
}

export function useStudyAEsOutletContext() {
  return useOutletContext<OutletContext>();
}
