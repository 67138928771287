import type { ComponentPropsWithoutRef } from 'react';
import { Link } from 'react-router';
import { cn } from '~/utils/common';

export type CarouselProps = {
  name?: string;
  /** List of image URLs */
  items: string[] | { alt: string; src: string }[];
} & ComponentPropsWithoutRef<'div'>;

export function Carousel({ name, items, className, ...props }: CarouselProps) {
  const hasPrev = (index: number) => items.length && index > 0;
  const hasNext = (index: number) => items.length && index !== items.length - 1;

  const slideId = (index: number) => `slide-${index}`;

  return (
    <div className={cn('carousel w-full', className)}>
      {items.map((item, index) => (
        <div
          key={index}
          id={slideId(index)}
          className="carousel-item relative w-full"
        >
          <img
            src={typeof item === 'string' ? item : item.src}
            alt={typeof item === 'object' ? item.alt : 'Carousel image'}
            className="w-full object-cover"
          />
          <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
            {hasPrev(index) ? (
              <Link to={`#${slideId(index - 1)}`} className="btn btn-circle">
                ❮
              </Link>
            ) : (
              <span />
            )}
            {hasNext(index) && (
              <Link to={`#${slideId(index + 1)}`} className="btn btn-circle">
                ❯
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
