import { useQuery } from '@apollo/client';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { redirect } from 'react-router';
import { gql } from '~/apollo/client-v3';
import { Button } from '~/components/ui/button';

import type {
  CompanyRegisterListQuery,
  CompanyRegisterListQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { RegistrationForm } from '~/components/register/RegisterUser';
import { dashboardRoute } from '~/paths';
import { requireOptionalUser } from '~/utils/auth';

export const COMPANY_REGISTER_LIST = gql`
  query CompanyRegisterList {
    companyRegisterList {
      id
      name
      mails
      requireSSO
    }
  }
`;
export type RegisterCompany =
  CompanyRegisterListQuery['companyRegisterList'][number];

export async function loader() {
  const authority = await requireOptionalUser();
  if (authority) throw redirect(dashboardRoute());
  return null;
}

export default function RegisterRoute() {
  const [company, setCompany] = useState<RegisterCompany | null>(null);

  const { data, loading } = useQuery<
    CompanyRegisterListQuery,
    CompanyRegisterListQueryVariables
  >(COMPANY_REGISTER_LIST, {});

  const companyRegisterList = data?.companyRegisterList ?? [];
  const companies = R.sortBy(c => c.name, companyRegisterList);

  const handleCompanyClick = (company: RegisterCompany) => () => {
    setCompany(company);
  };

  function clearCompany() {
    const confirmText = 'Are you sure you would like to start over?';
    if (!window.confirm(confirmText)) return;
    setCompany(null);
  }

  const companyPicker = (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Step 1: Select Company</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <div className="grid grid-cols-2">
          {companies.map(c => (
            <div key={c.name} className="col-span-2 lg:col-span-1">
              <Button
                type="button"
                color="ghost"
                block
                className="justify-start"
                onClick={handleCompanyClick(c)}
              >
                {c.name}
              </Button>
            </div>
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-6 lg:col-start-4">
        <PageHeading>Register</PageHeading>

        <SpinnerPlaceholder
          show={loading}
          containerProps={{ className: 'my-6' }}
        >
          Loading companies...
        </SpinnerPlaceholder>

        {!company && companyPicker}
        {company && (
          <Panel>
            <Panel.Heading>
              <Panel.Title className="flex items-center gap-x-2">
                <button
                  type="button"
                  onClick={clearCompany}
                  className="btn-link text-base-content"
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                </button>
                Step 2: Account Details
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <RegistrationForm company={company} />
            </Panel.Body>
          </Panel>
        )}
      </div>
    </div>
  );
}
