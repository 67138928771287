import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router';
import invariant from 'tiny-invariant';

import { vomParts } from '~/apollo/fragments';
import type {
  UpdateVomRouteQuery,
  UpdateVomRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { outcropVirtualOutcropsTabRoute } from '~/paths';
import { UpdateVomNavigation } from '~/routes/upload/vom/$vomId/__UpdateVomNavigation';

export const UPDATE_VOM_ROUTE = gql`
  query UpdateVomRoute($id: Int!) {
    virtualOutcropModelList(id: $id) {
      ...vomParts
      outcrop {
        id
      }
      pictures {
        id
      }
      cesiumAsset {
        id
        assetToken
        localPath
        state
        approved
      }
    }
  }

  ${vomParts}
`;

type OutletContext = {
  vom: UpdateVomRouteQuery['virtualOutcropModelList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function UpdateVomRoute() {
  const params = useParams();
  invariant(params.vomId, 'vomId param required');
  const vomId = parseInt(params.vomId);

  const { data, loading } = useQuery<
    UpdateVomRouteQuery,
    UpdateVomRouteQueryVariables
  >(UPDATE_VOM_ROUTE, {
    variables: { id: vomId },
  });

  const refetchQueries: [PureQueryOptions<UpdateVomRouteQueryVariables>] = [
    {
      query: UPDATE_VOM_ROUTE,
      variables: { id: vomId },
    },
  ];

  const vomList = data?.virtualOutcropModelList ?? [];
  const vom = vomList.find(v => v.id === vomId);
  const cesiumAssetApproved = vom?.cesiumAsset?.approved;

  useBreadcrumb('routes/upload/vom/$vomId', vom?.name ?? 'Update VOM');

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  const outletContext: OutletContext = {
    vom,
    refetchQueries,
  };

  return (
    <>
      {vom.outcrop && (
        <Link
          to={outcropVirtualOutcropsTabRoute(vom.outcrop.id, vom.id)}
          target="_blank"
          className="float-right btn btn-primary btn-sm gap-1"
        >
          View VOM <FontAwesomeIcon icon={faExternalLink} />
        </Link>
      )}

      <PageHeading hasSubtitle>Update Vitual Outcrop Model</PageHeading>
      <PageHeading.Subtitle>{vom.name}</PageHeading.Subtitle>

      <div className="grid lg:grid-cols-12 gap-6 mt-4">
        <div className="lg:col-span-2">
          <UpdateVomNavigation
            vomId={vom.id}
            assetApproved={cesiumAssetApproved}
          />
        </div>

        <div className="lg:col-span-10">
          <Outlet context={outletContext} />
        </div>
      </div>
    </>
  );
}

export function useUpdateVomOutletContext() {
  return useOutletContext<OutletContext>();
}
