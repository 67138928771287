import { Link } from 'react-router';
import type { DashboardSearchWikisQuery } from '~/apollo/generated/v3/graphql';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { useDashboardOutletContext } from '~/routes/dashboard';
import {
  lithostratFormationRoute,
  lithostratGroupRoute,
  lithostratMemberRoute,
} from '~/paths';

type SearchLithostratResults =
  DashboardSearchWikisQuery['searchWikis']['lithostrat'];

type FormationResults = SearchLithostratResults['formations'][number];
type GroupResults = SearchLithostratResults['groups'][number];
type MemberResults = SearchLithostratResults['members'][number];

type ComboResult = {
  type: 'Formation' | 'Group' | 'Member';
  id: number;
  name: string;
};
type FormationCombo = FormationResults & ComboResult;
type GroupCombo = GroupResults & ComboResult;
type MemberCombo = MemberResults & ComboResult;
type CombinedResult = FormationCombo | GroupCombo | MemberCombo;

export default function DashboardSearchLithostratResultsRoute() {
  const ctx = useDashboardOutletContext();
  const results = ctx.wikiResults.lithostrat;

  const itemLink = (item: CombinedResult) => {
    switch (item.type) {
      case 'Formation':
        return lithostratFormationRoute(item.id);
      case 'Group':
        return lithostratGroupRoute(item.id);
      case 'Member':
        return lithostratMemberRoute(item.id);
    }
  };

  const fmCombos: FormationCombo[] = results.formations.map(res => ({
    ...res,
    type: 'Formation',
    id: res.formation.id,
    name: res.formation.name,
  }));
  const groupCombos: GroupCombo[] = results.groups.map(res => ({
    ...res,
    type: 'Group',
    id: res.group.id,
    name: res.group.name,
  }));
  const memberCombos: MemberCombo[] = results.members.map(res => ({
    ...res,
    type: 'Member',
    id: res.member.id,
    name: res.member.name,
  }));

  const combinedResults: CombinedResult[] = [
    ...fmCombos,
    ...groupCombos,
    ...memberCombos,
  ];

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Lithostratigraphy Articles</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {!combinedResults.length && (
          <p className="text-muted">
            <em>
              No lithostratigraphy articles found matching the given search
              criteria.
            </em>
          </p>
        )}

        {combinedResults.length > 0 && (
          <ListGroup>
            {combinedResults.map(item => (
              <ListGroup.Item
                as={Link}
                key={item.id}
                to={itemLink(item)}
                className="flex justify-between p-4"
              >
                <div>
                  <strong>{item.name}</strong>
                </div>
                <div className="text-muted">Lithostrat {item.type}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Panel.Body>
    </Panel>
  );
}
