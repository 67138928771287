import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type {
  CreatePaleoMapOutcropLinkMutation,
  CreatePaleoMapOutcropLinkMutationVariables,
  OutcropPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';
import { useModalState } from '~/hooks/modal';
import { yup } from '~/utils/validation';

export const CREATE_PALEO_MAP_OUTCROP_LINK = gql`
  mutation CreatePaleoMapOutcropLink($outcropId: Int!, $paleoMapId: Int!) {
    createPaleoMapOutcropLink(outcropId: $outcropId, paleoMapId: $paleoMapId) {
      id
    }
  }
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  paleoMapId: number;
  /** Existing outcrops on the paleoMap that should be filtered out */
  outcrops: OutcropPartsFragment[];
  refetchQueries: PureQueryOptions[];
};

type FormValues = {
  outcropId: string;
};
const initialValues = (): FormValues => ({ outcropId: '' });

const validationSchema = yup.object({
  outcropId: yup.number().integer().label('outcrop').required(),
});

export function CreatePaleoMapOutcropLink({
  children,
  paleoMapId,
  outcrops,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [createLink, { loading, error }] = useMutation<
    CreatePaleoMapOutcropLinkMutation,
    CreatePaleoMapOutcropLinkMutationVariables
  >(CREATE_PALEO_MAP_OUTCROP_LINK, {
    refetchQueries,
  });

  const currentOutcropIds = outcrops.map(oc => oc.id);

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await createLink({
        variables: {
          paleoMapId,
          outcropId: parseInt(values.outcropId),
        },
      });
      toast.success('Outcrop linked successfully.');
      helpers.resetForm();
      hideModal();
    } catch (err) {
      console.log('Error creating outcrop link', err);
      toast.error('There was a problem creating the link.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues()}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body heading="Link Outcrop">
              <div className="space-y-4">
                <Field
                  name="outcropId"
                  label="Outcrop"
                  component={FormikField}
                  type={OutcropSelect}
                  disabledIds={currentOutcropIds}
                  required
                />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                disabled={loading}
                onClick={hideModal}
              >
                Cancel
              </Button>

              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
