import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { Tooltip } from '~/components/common/Tooltip';
import type { OutcropApprovalState } from '~/utils/modules/outcrop';
import { ucwords } from '~/utils/text';

export function OutcropApprovalIcon({
  approvalState,
}: {
  approvalState: OutcropApprovalState;
}) {
  const tooltipWrapper = (icon: JSX.Element) => (
    <Tooltip message={ucwords(approvalState)}>{icon}</Tooltip>
  );

  switch (approvalState) {
    case 'approved':
      return tooltipWrapper(<EnabledIndicator value={true} />);

    case 'ready for approval':
      return tooltipWrapper(
        <FontAwesomeIcon icon={faFlag} className="text-warning" />,
      );

    default:
      return tooltipWrapper(<EnabledIndicator value={false} />);
  }
}

export function OutcropQCIcon({ qcCompleted }: { qcCompleted: boolean }) {
  return <EnabledIndicator value={qcCompleted} />;
}
