import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import type {
  UpdateStudyAccessRouteQuery,
  UpdateStudyAccessRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import {
  StudyAccessManager,
  StudyAccessParent,
} from '~/components/upload/study/studyAccessManager/StudyAccessManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

const ACCESS_TAB_QUERY = gql`
  query UpdateStudyAccessRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      project {
        ...projectParts
        studyAccess {
          ...studyAccessParts
          company {
            ...companyParts
          }
        }
      }
      studyAccess {
        ...studyAccessParts
        company {
          ...companyParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.studyAccessParts}
  ${fragments.projectParts}
  ${fragments.companyParts}
`;

export default function UpdateStudyAccessRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UpdateStudyAccessRouteQuery,
    UpdateStudyAccessRouteQueryVariables
  >(ACCESS_TAB_QUERY, {
    variables: { studyId },
  });

  const refetchQueries: [PureQueryOptions<any>] = [
    { query: ACCESS_TAB_QUERY, variables: { studyId } },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const studyAccess = (study?.studyAccess ?? []).filter(
    (p): p is typeof p & { company: NonNullable<(typeof p)['company']> } =>
      !!p.company,
  );
  const projectStudyAccess = (study?.project?.studyAccess ?? []).filter(
    (p): p is typeof p & { company: NonNullable<(typeof p)['company']> } =>
      !!p.company,
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <>
      {study.project && (
        <div className="mb-2">
          <Heading level={3}>Project: {study.project.name}</Heading>
        </div>
      )}

      <StudyAccessManager
        parentType={StudyAccessParent.Study}
        parentId={study.id}
        studyAccess={studyAccess}
        projectStudyAccess={projectStudyAccess}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
