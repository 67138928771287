import type { PureQueryOptions } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { FormikField } from '~/components/common/FormikField';
import { useRefetchQueries } from '~/hooks/apollo';
import { imageMimes } from '~/utils/formik';
import { yup } from '~/utils/validation';

export enum ThumbnailParent {
  Outcrop = 'outcrop',
}

type CreateThumbnailFormValues = {
  file: File | '';
};

type Props = {
  parentType: ThumbnailParent;
  parentId: number;
  refetchQueries?: PureQueryOptions[];
};

export function ThumbnailUpload({
  parentType,
  parentId,
  refetchQueries,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [refetch, { loading: refetching }] = useRefetchQueries(refetchQueries);

  async function handleSubmit(
    values: CreateThumbnailFormValues,
    helpers: FormikHelpers<CreateThumbnailFormValues>,
  ) {
    if (!values.file) {
      throw new Error('Form submitted without a file.');
    }

    const formData = new FormData();
    formData.append('parentType', parentType);
    formData.append('parentId', String(parentId));
    formData.append('file', values.file);

    try {
      setLoading(true);
      const res = await fetch('/api/v3/file/thumbnail', {
        method: 'post',
        body: formData,
      });
      if (!res.ok) {
        throw new Error(await res.json());
      }

      toast.success('File uploaded successfully.');
      refetch();
    } catch (err) {
      console.log('Error uploading file', err);
      toast.error('There was a problem uploading the file.');
    } finally {
      setLoading(false);
      helpers.resetForm();
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ file: '' }}
      validationSchema={yup.object({
        file: yup
          .mixed()
          .required()
          .test('mimes', 'File must be a valid image', value => {
            if (value === '') return false;
            const validMimes = ['image/png', 'image/jpeg', 'image/gif'];
            return validMimes.includes((value as File).type);
          }),
      })}
    >
      <Form encType="multipart/form-data" className="space-y-2">
        <Field
          name="file"
          label="Picture"
          component={FormikField}
          type="file"
          accept={imageMimes}
          required
        />
        <p className="text-muted">
          <em>
            The selected picture will be automatically resized and optimized in
            thumbnail format on upload.
          </em>
        </p>

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading || refetching}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
