import { Outlet } from 'react-router';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { uploadProjectsRoute } from '~/paths';

export default function UploadProjectBreadcrumbWrapper() {
  // Wrapper component for generating a breadcrumb entry
  useBreadcrumb(
    'routes/upload/model/project',
    'Projects',
    uploadProjectsRoute(),
  );
  return <Outlet />;
}
