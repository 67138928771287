import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { vomParts } from '~/apollo/fragments';
import type {
  UpdateVirtualOutcropModelMutation,
  UpdateVirtualOutcropModelMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { FormErrors } from '~/components/common/FormErrors';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteVom } from '~/components/upload/vom/DeleteVom';
import { VomDescriptionForm } from '~/components/upload/vom/VomDescriptionForm';
import { uploadVomRoute } from '~/paths';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';
import type { VomFormValues } from '~/utils/modules/vom';
import { initialVom, toVomInput, validationSchema } from '~/utils/modules/vom';

export const UPDATE_VIRTUAL_OUTCROP_MODEL = gql`
  mutation updateVirtualOutcropModel(
    $id: Int!
    $vom: VirtualOutcropModelInput!
  ) {
    updateVirtualOutcropModel(id: $id, vom: $vom) {
      ...vomParts
    }
  }

  ${vomParts}
`;

export default function UpdateVomOverviewTabRoute() {
  const { vom, refetchQueries } = useUpdateVomOutletContext();
  const navigate = useNavigate();

  const [updateVom, { loading, error }] = useMutation<
    UpdateVirtualOutcropModelMutation,
    UpdateVirtualOutcropModelMutationVariables
  >(UPDATE_VIRTUAL_OUTCROP_MODEL, {});

  async function handleSubmit(values: VomFormValues) {
    try {
      await updateVom({
        variables: {
          id: vom.id,
          vom: toVomInput(values),
        },
      });
      toast.success('VOM updated successfully.');
    } catch (err) {
      toast.error('There was a problem updating the VOM. Please try again.');
    }
  }

  function handleDeleteSuccess() {
    navigate(uploadVomRoute());
  }

  const hasPictures = vom.pictures.length > 0;
  const hasCesiumAsset = !!vom.cesiumAsset;
  const canDelete = !hasPictures && !hasCesiumAsset;

  const cantDeleteTooltip = (() => {
    if (hasCesiumAsset) {
      return 'VOM cannot be deleted because it has a linked Cesium asset.';
    } else if (hasPictures) {
      return 'VOM cannot be deleted because it has linked pictures.';
    } else {
      return '';
    }
  })();

  const handleDeleteClick = (cb: Function) => (e: React.MouseEvent) => {
    e.preventDefault();
    if (canDelete) {
      cb();
    }
  };

  return (
    <div className="space-y-6">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialVom(vom)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form className="space-y-4">
          <VomDescriptionForm />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Formik>

      <div className="text-center">
        <DeleteVom
          vomId={vom.id}
          refetchQueries={refetchQueries}
          onSuccess={handleDeleteSuccess}
        >
          {handleDelete => (
            <Confirm
              onConfirm={handleDelete}
              title="Delete VOM"
              text="The selected VOM and its interpretations will be permanently deleted."
            >
              {onConfirm => (
                <Tooltip message={cantDeleteTooltip} disabled={canDelete}>
                  <Button
                    type="button"
                    variant="link"
                    disabled={!canDelete}
                    onClick={handleDeleteClick(onConfirm)}
                    className="text-error hover:text-error"
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete Virtual Outcrop Model
                  </Button>
                </Tooltip>
              )}
            </Confirm>
          )}
        </DeleteVom>
      </div>
    </div>
  );
}
