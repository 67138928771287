import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { useState } from 'react';
import { graphql } from '~/apollo/generated/v3';
import type {
  SearchOutcropsOptionsQuery,
  SearchOutcropsOptionsQueryVariables,
  SearchOutcropsOutcropsQuery,
  SearchOutcropsOutcropsQueryVariables,
} from '~/apollo/generated/v3/graphql';
import type { QueryOptionsBrowse } from '~/apollo/operations/analogueSearch';
import { SEARCH_OUTCROPS_OPTIONS } from '~/apollo/operations/analogueSearch';
import type { FilterSearchProps } from '~/components/common/FilterSearch';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { useImperativeQuery } from '~/hooks/apollo';
import type { SortChangeFn } from '~/hooks/data';
import { useSortFilter } from '~/hooks/data';
import { useDebounce } from '~/hooks/debounce';
import type {
  AnalogueSearchFormValues,
  AnalogueSearchOptions,
} from '~/utils/modules/analogueSearch';
import {
  defaultAnalogueSearch,
  defaultAnalogueSearchOptions,
  updateCounts,
} from '~/utils/modules/analogueSearch';

const SEARCH_OUTCROPS_OUTCROPS = graphql(`
  query SearchOutcropsOutcrops(
    $grossDepositionalEnvironment: [String!]
    $depositionalEnvironment: [String!]
    $depositionalSubEnvironment: [String!]
    $architecturalElement: [String!]
    $climate: [String!]
    $basinType: [String!]
    $outcropCategory: [String!]
    $supportingObjectTypes: [String!]
    $geologyAge: [String!]
    $geologyType: [String!]
    $country: [String!]
    $textSearch: String
    $netToGross: [String!]
    $distanceToSourceArea: [String!]
  ) {
    searchOutcrops(
      grossDepositionalEnvironment: $grossDepositionalEnvironment
      depositionalEnvironment: $depositionalEnvironment
      depositionalSubEnvironment: $depositionalSubEnvironment
      architecturalElement: $architecturalElement
      climate: $climate
      basinType: $basinType
      outcropCategory: $outcropCategory
      supportingObjectTypes: $supportingObjectTypes
      geologyAge: $geologyAge
      geologyType: $geologyType
      country: $country
      textSearch: $textSearch
      netToGross: $netToGross
      distanceToSourceArea: $distanceToSourceArea
    ) {
      outcrops {
        id
        name
        shortDescription
        geologyType
        outcropCategory
        region {
          id
          name
        }
        thumbnail {
          id
          signedUrl
        }
      }
    }
  }
`);

type Outcrop =
  SearchOutcropsOutcropsQuery['searchOutcrops']['outcrops'][number];

type SearchOutcropsRenderProperties = {
  query: AnalogueSearchFormValues;
  options: AnalogueSearchOptions;
  outcrops: Outcrop[];
  loadingOptions: boolean;
  loadingOutcrops: boolean;
  resetForm: () => void;
  sortIndicatorProps: SortIndicatorProps;
  onSortChange: SortChangeFn;
  filterSearchProps: FilterSearchProps;
};

type Props = {
  children: (renderProps: SearchOutcropsRenderProperties) => any;
};

function SearchOutcropsQuery({ children }: Props): JSX.Element {
  const [options, setOptions] = useState<AnalogueSearchOptions>(
    defaultAnalogueSearchOptions(),
  );
  const { values, setValues } = useFormikContext<AnalogueSearchFormValues>();
  const debouncedQuery = useDebounce(values, 100);

  // Query to load outcrop results
  const [loadOutcrops, { data: dataOutcrops, loading: loadingOutcrops }] =
    useImperativeQuery<
      SearchOutcropsOutcropsQuery,
      SearchOutcropsOutcropsQueryVariables
    >(SEARCH_OUTCROPS_OUTCROPS, { variables: debouncedQuery });

  // Query to load options/counts when filters are updated
  const { loading: loadingOptions } = useQuery<
    SearchOutcropsOptionsQuery,
    SearchOutcropsOptionsQueryVariables
  >(SEARCH_OUTCROPS_OPTIONS, {
    variables: values,
    onCompleted: result => {
      setOptions(
        updateCounts(
          options,
          result.searchOutcrops.queryOptions as QueryOptionsBrowse, // TODO make sure this lines up
        ),
      );
      loadOutcrops();
    },
  });

  const resetForm = () => setValues(defaultAnalogueSearch());

  const outcrops = R.sortBy(
    R.prop('name'),
    dataOutcrops?.searchOutcrops.outcrops ?? [],
  );

  const { items, sortIndicatorProps, onSortChange, filterSearchProps } =
    useSortFilter(outcrops, 'name', 'name', 'searchOutcrops');

  return children({
    query: debouncedQuery,
    options,
    outcrops: items,
    loadingOptions,
    loadingOutcrops,
    resetForm,

    sortIndicatorProps,
    onSortChange,
    filterSearchProps,
  });
}

export default SearchOutcropsQuery;
