import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { HTMLProps } from 'react';
import { toast } from 'react-toastify';
import { gql } from '~/apollo/client-v3';
import { keyParametersParts } from '~/apollo/fragments';
import type {
  DeleteKeyParametersMutation,
  DeleteKeyParametersMutationVariables,
  KeyParametersPartsFragment,
  SetKeyParametersDominanceMutation,
  SetKeyParametersDominanceMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { Button } from '~/components/ui/button';
import { useSortFilter } from '~/hooks/data';
import { cn } from '~/utils/common';

function StyledTD({
  dominant,
  className,
  ...props
}: { dominant: boolean } & HTMLProps<HTMLTableCellElement>) {
  return (
    <td
      className={cn(className, 'align-middle', {
        'bg-orange-200 text-orange-900 font-semibold': dominant,
      })}
      {...props}
    />
  );
}

const SET_KEY_PARAMETERS_DOMINANCE = gql`
  mutation SetKeyParametersDominance($id: Int!, $dominant: Boolean!) {
    setKeyParametersDominance(id: $id, dominant: $dominant) {
      ...keyParametersParts
    }
  }

  ${keyParametersParts}
`;

const DELETE_KEY_PARAMETERS = gql`
  mutation DeleteKeyParameters($id: Int!) {
    deleteKeyParameters(id: $id)
  }
`;

type Props = {
  keyParameters: Omit<KeyParametersPartsFragment, '__typename'>[];
  refetchQueries?: PureQueryOptions[];
  disabled?: boolean;
};

export function CurrentKeyParameters({
  keyParameters,
  refetchQueries,
  disabled = false,
}: Props) {
  const [setDominance, { loading: loadingDominance }] = useMutation<
    SetKeyParametersDominanceMutation,
    SetKeyParametersDominanceMutationVariables
  >(SET_KEY_PARAMETERS_DOMINANCE, {});

  const [deleteKeyParameters, { loading }] = useMutation<
    DeleteKeyParametersMutation,
    DeleteKeyParametersMutationVariables
  >(DELETE_KEY_PARAMETERS, { refetchQueries });

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    keyParameters,
    'id',
    'id',
    'outcropUploadKeyParametersTable',
  );

  const handleDelete = (id: number) => async () => {
    const kps = keyParameters.find(kp => kp.id === id);

    try {
      if (!kps) throw new Error(`KPs not found with id ${id}`);
      await deleteKeyParameters({ variables: { id } });
      toast.success('Key parameters deleted successfully.');
    } catch (err) {
      console.log('Error deleting key parameters', err);
      toast.error(
        'There was a problem deleting the key parameters. It may have been deleted already.',
      );
    }
  };

  const handleDominanceClick = (id: number, dominant: boolean) => async () => {
    try {
      await setDominance({ variables: { id, dominant } });
      toast.success(`Key parameters' dominance updated successfully.`);
    } catch (err) {
      console.log('Error setting dominance', err);
      toast.error(
        'There was a problem updating the dominance. Please refresh and try again.',
      );
    }
  };

  return (
    <table className="table table-compact w-full bg-base-100">
      <thead>
        <tr>
          <th className="text-center">
            <SortTrigger
              colName="valid"
              sortIndicatorProps={siProps}
              filterable
              renderFilterOption={opt => (opt === 'true' ? 'Valid' : 'Invalid')}
            >
              Valid
            </SortTrigger>
          </th>

          <th className="text-center">
            <SortTrigger colName="geologyType" sortIndicatorProps={siProps}>
              <Tooltip message="Geology type">
                <span>GT</span>
              </Tooltip>
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="grossDepositionalEnvironment"
              sortIndicatorProps={siProps}
            >
              GDE
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="depositionalEnvironment"
              sortIndicatorProps={siProps}
            >
              DE
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="depositionalSubEnvironment"
              sortIndicatorProps={siProps}
            >
              DSE
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="architecturalElement"
              sortIndicatorProps={siProps}
            >
              AE
            </SortTrigger>
          </th>
          <th className="text-right">Dominant</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {items.map(kp => (
          <tr key={kp.id}>
            <StyledTD dominant={kp.dominant} className="text-center">
              <EnabledIndicator value={kp.valid ?? false} />
            </StyledTD>

            <StyledTD dominant={kp.dominant} className="text-center">
              {kp.geologyType ? (
                <GeologyTypeIcon geologyType={kp.geologyType} className="h-6" />
              ) : (
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="text-error"
                />
              )}
            </StyledTD>
            <StyledTD dominant={kp.dominant}>
              {kp.grossDepositionalEnvironment}
            </StyledTD>
            <StyledTD dominant={kp.dominant}>
              {kp.depositionalEnvironment}
            </StyledTD>
            <StyledTD dominant={kp.dominant}>
              {kp.depositionalSubEnvironment}
            </StyledTD>
            <StyledTD dominant={kp.dominant}>
              {kp.architecturalElement}
            </StyledTD>
            <StyledTD dominant={kp.dominant} className="text-right space-x-2">
              <Tooltip
                message={
                  kp.dominant
                    ? `Unset dominant key parameters`
                    : `Mark as dominant key parameters`
                }
              >
                <span>
                  <input
                    type="checkbox"
                    onChange={handleDominanceClick(kp.id, !kp.dominant)}
                    checked={kp.dominant}
                    disabled={loadingDominance}
                    className={cn(
                      'toggle toggle-sm',
                      kp.dominant && 'toggle-warning',
                    )}
                  />
                </span>
              </Tooltip>
            </StyledTD>
            <StyledTD dominant={kp.dominant}>
              <Confirm
                onConfirm={handleDelete(kp.id)}
                text="This set of key parameters will be permanently deleted."
              >
                {onConfirm => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    disabled={disabled}
                    loading={loading}
                    onClick={onConfirm}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                )}
              </Confirm>
            </StyledTD>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
