import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { graphql } from '~/apollo/generated/v3';
import { BookmarkParentType } from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { TaggedSupportingObjectList } from '~/components/supportingObject/TaggedSupportingObjectList';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const OUTCROP_WELL_LOGS_ROUTE = graphql(`
  query OutcropWellLogsRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts

      wellLogs {
        ...wellLogParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
        files {
          ...fileParts
          signedUrl
        }
        georeference {
          ...georeferenceParts
        }
      }

      studies {
        ...studyParts

        dataHistory {
          ...dataHistoryParts
        }

        wellLogs {
          ...wellLogParts
          pictures {
            ...pictureParts
            file {
              ...fileParts
              signedUrl
            }
          }
          files {
            ...fileParts
            signedUrl
          }
          georeference {
            ...georeferenceParts
          }
        }
      }
    }
  }
`);

export default function OutcropWellLogsRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery(OUTCROP_WELL_LOGS_ROUTE, {
    variables: { outcropId },
  });

  const refetchQueries: PureQueryOptions[] = [
    { query: OUTCROP_WELL_LOGS_ROUTE, variables: { outcropId } },
  ];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);
  const wellLogs = outcrop?.wellLogs ?? [];
  const outcropStudies = R.sortBy(R.prop('name'), outcrop?.studies ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <>
      <SupportingObjectList
        items={wellLogs}
        soParentType="outcrop"
        soParentId={outcropId}
        bookmarkParentType={BookmarkParentType.WellLog}
        refetchQueries={refetchQueries}
      />

      <TaggedSupportingObjectList
        outcropId={outcropId}
        studies={outcropStudies}
        supportObjectKey="wellLogs"
        refetchQueries={refetchQueries}
      />
    </>
  );
}
