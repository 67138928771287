import { Outlet } from 'react-router';
import { NavLink } from '~/components/common/NavLink';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';

export default function ExportGeoreferencesRoute() {
  useBreadcrumb('routes/upload/export/georeferences', 'Export Georeferences');

  return (
    <>
      <PageHeading>Export Georeferences</PageHeading>

      <div className="grid lg:grid-cols-4 gap-6">
        <div>
          <Menu>
            <Menu.Item>
              <NavLink to={routes.exportGeoreferencesAllRegionsRoute()}>
                All Regions
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to={routes.exportGeoreferencesAllOutcropsRoute()}>
                All Outcrops
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to={routes.exportGeoreferencesOutcropRoute()}>
                Outcrop
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to={routes.exportGeoreferencesAllVomsRoute()}>
                All Virtual Outcrop Models
              </NavLink>
            </Menu.Item>
          </Menu>
        </div>

        <div className="lg:col-span-3">
          <Outlet />
        </div>
      </div>
    </>
  );
}
