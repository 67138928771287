import { decamelize } from 'humps';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { Link } from 'react-router';
import { useKPPanelContext } from '~/components/keyParameter/KeyParametersPanel/KPPanelContext';
import { otherWikiDetailRoute } from '~/paths';

type Props = {
  fieldName: string;
  children: React.ReactNode;
};

export function LinkedOtherValue({ fieldName, children }: Props) {
  const { otherList } = useKPPanelContext();

  // Try to find a possible wiki page link by converting camel case title
  const possibleTitle = useMemo(() => {
    return R.pipe(
      () => fieldName,
      value => decamelize(value, { separator: ' ' }),
      R.trim,
      R.toLower,
    )();
  }, [fieldName]);

  const wikiPage = otherList.find(wp => {
    const matchableTitle = R.pipe(
      () => wp.title,
      R.replace(/[^a-zA-Z\s]/g, ''),
      R.trim,
      R.toLower,
    )();

    return matchableTitle === possibleTitle;
  });

  if (!wikiPage) return <>{children}</>;

  return (
    <Link
      to={otherWikiDetailRoute(wikiPage.id)}
      target="_blank"
      className="link"
    >
      {children}
    </Link>
  );
}
