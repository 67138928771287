import * as R from 'ramda';
import type {
  AuthorityUserPartsFragment,
  CreateSavedDataSearchInput,
  CurrentAuthorityQuery,
  DataSearchMeasurement,
  DataSearchOptionsQuery,
  DataSearchQueryInput,
  DepositionalHierarchyQuery,
  MySafariSavedDataSearchesQuery,
  QueryOptionField,
  SavedDataSearchDataPartsFragment,
  SavedDataSearchPartsFragment,
  UpdateSavedDataSearchInput,
} from '~/apollo/generated/v3/graphql';
import { Role } from '~/apollo/generated/v3/graphql';
import { filterUnique } from '~/utils/common';
import { toLocalDateString } from '~/utils/date';
import { ucwords } from '~/utils/text';
import { yup } from '~/utils/validation';

type DataSearchDataGroupable = keyof Pick<
  DataSearchMeasurement,
  | 'architecturalElement'
  | 'basinType'
  | 'climate'
  | 'depositionalEnvironment'
  | 'depositionalSubEnvironment'
  | 'grossDepositionalEnvironment'
  | 'outcropName'
  | 'netToGross'
  | 'distanceToSourceAreaDesc'
  | 'outcropCategory'
>;
export const dataGroupableBy: DataSearchDataGroupable[] = [
  'outcropName',
  'architecturalElement',
  'grossDepositionalEnvironment',
  'depositionalEnvironment',
  'depositionalSubEnvironment',
  'basinType',
  'climate',
  'netToGross',
  'distanceToSourceAreaDesc',
  'outcropCategory',
];
function defaultGroupableBy(value: string): DataSearchDataGroupable {
  if (dataGroupableBy.includes(value as any as DataSearchDataGroupable)) {
    return value as DataSearchDataGroupable;
  }
  return 'outcropName';
}

export function formatGroupBy(groupable: DataSearchDataGroupable): string {
  switch (groupable) {
    case 'depositionalSubEnvironment':
      return 'Subenvironment';
    case 'distanceToSourceAreaDesc':
      return 'Distance to Source Area';
    case 'outcropCategory':
      return 'Analogue Category';
    default:
      return ucwords(groupable);
  }
}

export const orderMeasurementQuality = [
  'level_1',
  'level_2',
  'level_3',
  'level_4',
  'undifferentiated',
];
export const orderMeasurementCompleteness = [
  'complete',
  'partial',
  'incomplete',
  'undefined',
];

export type DataSearchFormValues = {
  // Form meta
  allCollapsed: boolean;

  // Filters
  outcropIds: number[];
  studyIds: number[];
  geologyType: string[];
  outcropCategory: string[];
  grossDepositionalEnvironment: string[];
  depositionalEnvironment: string[];
  depositionalSubEnvironment: string[];
  architecturalElement: string[];
  basinType: string[];
  climate: string[];
  startAge: string[];
  country: string[];
  netToGross: string[];
  distanceToSourceAreaDesc: string[];

  graphType: 'crossPlot' | 'histogram';
  measurementCompletenessX: string[];
  measurementCompletenessY: string[];
  measurementQualityX: string[];
  measurementQualityY: string[];
  groupDataBy: DataSearchDataGroupable;
  crossPlot: {
    dataTypeX: string | null;
    dataTypeY: string | null;
    logScaleX: boolean;
    logScaleY: boolean;
    showRegressionLine: boolean;
    showZIRegressionLine: boolean;
  };
  histogram: {
    dataType: string | null;
    numBins: number | null;
    binWidth: number | null;
    nice: boolean;
    minX: number | null;
    maxX: number | null;
  };
};

export const dataSearchInitialValues = (): DataSearchFormValues => ({
  allCollapsed: false,

  outcropIds: [],
  studyIds: [],
  geologyType: [],
  outcropCategory: [],
  grossDepositionalEnvironment: [],
  depositionalEnvironment: [],
  depositionalSubEnvironment: [],
  architecturalElement: [],
  basinType: [],
  climate: [],
  startAge: [],
  country: [],
  netToGross: [],
  distanceToSourceAreaDesc: [],

  graphType: 'crossPlot',
  measurementCompletenessX: [],
  measurementCompletenessY: [],
  measurementQualityX: [],
  measurementQualityY: [],
  groupDataBy: 'outcropName',
  crossPlot: {
    dataTypeX: null,
    dataTypeY: null,
    logScaleX: false,
    logScaleY: false,
    showRegressionLine: true,
    showZIRegressionLine: false,
  },
  histogram: {
    dataType: null,
    binWidth: null,
    numBins: null,
    nice: false,
    minX: null,
    maxX: null,
  },
});

export const dataSearchValidationSchema = yup.object({
  graphType: yup.string().label('graph type').required(),
  crossPlot: yup
    .object()
    .when('graphType', {
      is: 'crossPlot',
      then: schema =>
        schema.shape({
          dataTypeX: yup.string().label('data type X').required(),
          dataTypeY: yup.string().label('data type Y').required(),
        }),
    })
    .required(),
  histogram: yup
    .object()
    .when('graphType', {
      is: 'histogram',
      then: schema =>
        schema.shape({
          dataType: yup.string().label('data type').required(),
        }),
    })
    .required(),
});

export type DataSearchOption = {
  name: string;
  count: number;
};

export type DataSearchOptions = {
  outcropCategory: DataSearchOption[];
  grossDepositionalEnvironment: DataSearchOption[];
  depositionalEnvironment: DataSearchOption[];
  depositionalSubEnvironment: DataSearchOption[];
  architecturalElement: DataSearchOption[];
  climate: DataSearchOption[];
  basinType: DataSearchOption[];
  geologyType: DataSearchOption[];
  startAge: DataSearchOption[];
  country: DataSearchOption[];
  netToGross: DataSearchOption[];
  distanceToSourceAreaDesc: DataSearchOption[];

  measurementQualityX: DataSearchOption[];
  measurementQualityY: DataSearchOption[];
  measurementCompletenessX: DataSearchOption[];
  measurementCompletenessY: DataSearchOption[];
  dataTypeX: DataSearchOption[];
  dataTypeY: DataSearchOption[];

  outcrops: DataSearchOptionsQuery['dataSearchOptions']['outcrops'];
  studies: DataSearchOptionsQuery['dataSearchOptions']['studies'];
};

export function initialDataSearchOptions(): DataSearchOptions {
  return {
    geologyType: [],
    outcropCategory: [],
    grossDepositionalEnvironment: [],
    depositionalEnvironment: [],
    depositionalSubEnvironment: [],
    architecturalElement: [],
    climate: [],
    basinType: [],
    startAge: [],
    country: [],
    netToGross: [],
    distanceToSourceAreaDesc: [],

    outcrops: [],
    studies: [],
    measurementQualityX: [],
    measurementQualityY: [],
    measurementCompletenessX: [],
    measurementCompletenessY: [],
    dataTypeX: [],
    dataTypeY: [],
  };
}

export function updateCounts(
  oldOpts: DataSearchOptions,
  newOpts: DataSearchOptionsQuery['dataSearchOptions'],
): DataSearchOptions {
  type EnumerableKeys = keyof Omit<
    DataSearchOptionsQuery['dataSearchOptions'],
    '__typename' | 'outcrops' | 'studies'
  >;
  const queryResultKeys: Array<keyof DataSearchOptions & EnumerableKeys> = [
    'geologyType',
    'outcropCategory',
    'grossDepositionalEnvironment',
    'depositionalEnvironment',
    'depositionalSubEnvironment',
    'architecturalElement',
    'climate',
    'basinType',
    'startAge',
    'country',
    'netToGross',
    'distanceToSourceAreaDesc',

    'measurementCompletenessX',
    'measurementCompletenessY',
    'measurementQualityX',
    'measurementQualityY',
    'dataTypeX',
    'dataTypeY',
  ];

  // The database might have some nulls for the name, filter those out
  const filterNullOption = (
    option: QueryOptionField,
  ): option is DataSearchOption => !!option.name;

  const updateCountsIn = (
    oldOpts: DataSearchOption[],
    newOptions: DataSearchOption[],
  ) => {
    const oldZeroOptions = oldOpts.reduce<DataSearchOption[]>((acc, cur) => {
      const newOptExists = !!newOptions.find(opt => opt.name === cur.name);
      if (newOptExists) return acc;
      return [...acc, { name: cur.name, count: 0 }];
    }, []);

    return [...newOptions, ...oldZeroOptions];
  };

  const nextOpts: DataSearchOptions = { ...oldOpts };
  queryResultKeys.forEach(key => {
    nextOpts[key] = updateCountsIn(
      oldOpts[key],
      newOpts[key].filter(filterNullOption),
    );
  });
  nextOpts.outcrops = newOpts.outcrops;
  nextOpts.studies = newOpts.studies;

  return nextOpts;
}

export function formValuesToOptionsInput(
  fv: DataSearchFormValues,
): DataSearchQueryInput {
  const input: DataSearchQueryInput = {
    outcropIds: fv.outcropIds,
    studyIds: fv.studyIds,
    geologyType: fv.geologyType,
    outcropCategory: fv.outcropCategory,
    grossDepositionalEnvironment: fv.grossDepositionalEnvironment,
    depositionalEnvironment: fv.depositionalEnvironment,
    depositionalSubEnvironment: fv.depositionalSubEnvironment,
    architecturalElement: fv.architecturalElement,
    basinType: fv.basinType,
    climate: fv.climate,
    startAge: fv.startAge,
    country: fv.country,
    netToGross: fv.netToGross,
    distanceToSourceAreaDesc: fv.distanceToSourceAreaDesc,

    measurementCompletenessX: fv.measurementCompletenessX,
    measurementCompletenessY: fv.measurementCompletenessY,
    measurementQualityX: fv.measurementQualityX,
    measurementQualityY: fv.measurementQualityY,
  };

  if (fv.graphType === 'crossPlot') {
    input.dataTypeX = fv.crossPlot.dataTypeX;
    input.dataTypeY = fv.crossPlot.dataTypeY;
  } else if (fv.graphType === 'histogram') {
    input.dataTypeX = fv.histogram.dataType;
  }

  return input;
}

export const initialSavedDataSearch = (
  sds: SavedDataSearchPartsFragment,
): DataSearchFormValues => ({
  allCollapsed: false,

  outcropIds: sds.outcropIds ?? [],
  studyIds: sds.studyIds ?? [],
  geologyType: sds.geologyType ?? [],
  outcropCategory: sds.outcropCategory ?? [],
  grossDepositionalEnvironment: sds.grossDepositionalEnvironment ?? [],
  depositionalEnvironment: sds.depositionalEnvironment ?? [],
  depositionalSubEnvironment: sds.depositionalSubEnvironment ?? [],
  architecturalElement: sds.architecturalElement ?? [],
  basinType: sds.basinType ?? [],
  climate: sds.climate ?? [],
  startAge: sds.startAge ?? [],
  country: sds.country ?? [],
  netToGross: sds.netToGross ?? [],
  distanceToSourceAreaDesc: sds.distanceToSourceAreaDesc ?? [],

  graphType: sds.graphType === 'cross_plot' ? 'crossPlot' : 'histogram',
  measurementCompletenessX: sds.measurementCompletenessX ?? [],
  measurementCompletenessY: sds.measurementCompletenessY ?? [],
  measurementQualityX: sds.measurementQualityX ?? [],
  measurementQualityY: sds.measurementQualityY ?? [],
  groupDataBy: defaultGroupableBy(sds.groupDataBy),
  crossPlot: {
    dataTypeX: sds.dataTypeX,
    dataTypeY: sds.dataTypeY ?? null,
    logScaleX: sds.logScaleX,
    logScaleY: sds.logScaleY,
    showRegressionLine: sds.showRegression,
    showZIRegressionLine: sds.showRegressionThroughOrigin,
  },
  histogram: {
    dataType: sds.dataTypeX ?? null,
    binWidth: sds.binWidth ?? null,
    numBins: sds.numBins ?? null,
    nice: sds.niceBins,
    minX: sds.minX ?? null,
    maxX: sds.maxX ?? null,
  },
});

export type SavedDataSearchFormValuesCreate = {
  name: string;
  note: string;
  isCompany: boolean;
};

export type SavedDataSearchFormValuesUpdate = {
  name: string;
};

export function toUpdateSavedDataSearchInput(
  fv: SavedDataSearchFormValuesUpdate,
): UpdateSavedDataSearchInput {
  return {
    name: fv.name.trim(),
  };
}

// Assume that everything was validated beforehand
export function toSavedDataSearchInput(
  fv: SavedDataSearchFormValuesCreate,
  values: DataSearchFormValues,
): CreateSavedDataSearchInput {
  return {
    name: fv.name.trim(),
    note: fv.note.trim() || null,
    isCompany: fv.isCompany,

    architecturalElement: values.architecturalElement,
    outcropCategory: values.outcropCategory,
    basinType: values.basinType,
    climate: values.climate,
    country: values.country,
    depositionalEnvironment: values.depositionalEnvironment,
    depositionalSubEnvironment: values.depositionalSubEnvironment,
    geologyType: values.geologyType,
    grossDepositionalEnvironment: values.grossDepositionalEnvironment,
    outcropIds: values.outcropIds,
    studyIds: values.studyIds,
    startAge: values.startAge,
    netToGross: values.netToGross,
    distanceToSourceAreaDesc: values.distanceToSourceAreaDesc,
    graphType: values.graphType === 'crossPlot' ? 'cross_plot' : 'histogram',
    dataTypeX:
      values.graphType === 'crossPlot'
        ? (values.crossPlot.dataTypeX ?? '')
        : (values.histogram.dataType ?? ''),
    dataTypeY:
      values.graphType === 'crossPlot' ? values.crossPlot.dataTypeY : null,
    measurementCompletenessX: values.measurementCompletenessX,
    measurementCompletenessY: values.measurementCompletenessY,
    measurementQualityX: values.measurementQualityX,
    measurementQualityY: values.measurementQualityY,
    logScaleX: values.crossPlot.logScaleX ?? false,
    logScaleY: values.crossPlot.logScaleY ?? false,
    showRegression: values.crossPlot.showRegressionLine ?? false,
    showRegressionThroughOrigin: values.crossPlot.showZIRegressionLine ?? false,
    numBins: values.histogram.numBins,
    binWidth: values.histogram.binWidth,
    niceBins: values.histogram.nice ?? false,
    minX: values.histogram.minX || null,
    maxX: values.histogram.maxX || null,
    groupDataBy: values.groupDataBy,
  };
}

export function savedDataSearchToDataSearchFormValues(
  sds: SavedDataSearchPartsFragment,
): DataSearchFormValues {
  return {
    allCollapsed: true,

    outcropIds: sds.outcropIds ?? [],
    studyIds: sds.studyIds ?? [],
    geologyType: sds.geologyType ?? [],
    outcropCategory: sds.outcropCategory ?? [],
    grossDepositionalEnvironment: sds.grossDepositionalEnvironment ?? [],
    depositionalEnvironment: sds.depositionalEnvironment ?? [],
    depositionalSubEnvironment: sds.depositionalSubEnvironment ?? [],
    architecturalElement: sds.architecturalElement ?? [],
    basinType: sds.basinType ?? [],
    climate: sds.climate ?? [],
    startAge: sds.startAge ?? [],
    country: sds.country ?? [],
    netToGross: sds.netToGross ?? [],
    distanceToSourceAreaDesc: sds.distanceToSourceAreaDesc ?? [],

    graphType: sds.graphType === 'cross_plot' ? 'crossPlot' : 'histogram',
    measurementCompletenessX: sds.measurementCompletenessX ?? [],
    measurementCompletenessY: sds.measurementCompletenessY ?? [],
    measurementQualityX: sds.measurementQualityX ?? [],
    measurementQualityY: sds.measurementQualityY ?? [],
    groupDataBy: defaultGroupableBy(sds.groupDataBy),
    crossPlot: {
      dataTypeX: sds.dataTypeX,
      dataTypeY: sds.dataTypeY ?? null,
      logScaleX: sds.logScaleX,
      logScaleY: sds.logScaleY,
      showRegressionLine: sds.showRegression,
      showZIRegressionLine: sds.showRegressionThroughOrigin,
    },
    histogram: {
      dataType: sds.dataTypeX,
      numBins: sds.numBins ?? null,
      binWidth: sds.binWidth ?? null,
      nice: sds.niceBins,
      minX: null,
      maxX: null,
    },
  };
}

export type CollatedHieararchy = {
  gde: string[];
  de: string[];
  se: string[];
  ae: string[];
};

export function collateHierarchyOptions(
  hierarchy: DepositionalHierarchyQuery['depositionalHierarchyFull'],
): CollatedHieararchy {
  function reduceFlatten<T>(acc: T[], cur: T[]): T[] {
    return [...acc, ...cur];
  }

  const clasticGDEs = hierarchy.clastic.grossDepositionalEnvironment;
  const clasticDEs = clasticGDEs
    .map(gde => gde.depositionalEnvironment)
    .reduce(reduceFlatten);
  const clasticSEs = clasticDEs
    .map(de => de.depositionalSubEnvironment)
    .reduce(reduceFlatten);
  const clasticAEs = clasticSEs
    .map(se => se.architecturalElement)
    .reduce(reduceFlatten);

  const carbonateGDEs = hierarchy.carbonate.grossDepositionalEnvironment;
  const carbonateDEs = carbonateGDEs
    .map(gde => gde.depositionalEnvironment)
    .reduce(reduceFlatten);
  const carbonateSEs = carbonateDEs
    .map(de => de.depositionalSubEnvironment)
    .reduce(reduceFlatten);
  const carbonateAEs = carbonateSEs
    .map(se => se.architecturalElement)
    .reduce(reduceFlatten);

  const structuralGDEs = hierarchy.structural.grossDepositionalEnvironment;
  const structuralDEs = structuralGDEs
    .map(gde => gde.depositionalEnvironment)
    .reduce(reduceFlatten);
  const structuralSEs = structuralDEs
    .map(de => de.depositionalSubEnvironment)
    .reduce(reduceFlatten);
  const structuralAEs = structuralSEs
    .map(se => se.architecturalElement)
    .reduce(reduceFlatten);

  const combine = <T extends { name: string }>(...items: T[][]) =>
    items
      .flat()
      .flatMap(item => item.name)
      .filter(filterUnique)
      .sort();

  return {
    gde: combine(clasticGDEs, carbonateGDEs, structuralGDEs),
    de: combine(clasticDEs, carbonateDEs, structuralDEs),
    se: combine(clasticSEs, carbonateSEs, structuralSEs),
    ae: combine(clasticAEs, carbonateAEs, structuralAEs),
  };
}

export function mayModifySds(
  authority: CurrentAuthorityQuery['currentAuthority'],
  sds: Pick<SavedDataSearchPartsFragment, 'userId' | 'companyId'>,
) {
  // Admins may edit edverything
  if (authority.roles.includes(Role.RoleAdmin)) return true;

  // Users may edit their own searches (personal or company)
  if (sds.userId === authority.user.id) return true;

  // Company admins may edit company searches
  if (
    sds.companyId &&
    sds.companyId === authority.user.companyId &&
    authority.roles.includes(Role.RoleCompanyAdmin)
  ) {
    return true;
  }

  return false;
}

export function revisionLabel(
  sdsData: Pick<SavedDataSearchDataPartsFragment, 'revision' | 'insertedAt'>,
): string {
  const date = toLocalDateString(sdsData.insertedAt);

  if (sdsData.revision === 0) {
    return `Original - ${date}`;
  }

  return `Revision ${sdsData.revision} - ${date}`;
}

export function latestRevision<T extends { revision: number }>(
  data: T[],
): T | null {
  const sortedData = R.sortWith([R.descend(d => d.revision)], data);
  return sortedData.at(0) ?? null;
}

export function isAlreadyCopied(
  user: AuthorityUserPartsFragment,
  sds: MySafariSavedDataSearchesQuery['savedDataSearchList'][number],
  copies: MySafariSavedDataSearchesQuery['copies'],
): boolean {
  if (sds.copiedFrom) {
    if (sds.companyId) {
      if (sds.copiedFrom.userId === user.id) {
        return true;
      }
    } else {
      // This check probably isn't necessary since users can only
      // copy to their own company, but here it is anyway.
      if (sds.copiedFrom.companyId === user.companyId) {
        return true;
      }
    }
  }

  const copyMatches = copies.filter(c => c.copiedFromId === sds.id);
  if (sds.companyId) {
    return !!copyMatches.find(c => c.userId === user.id);
  }

  return !!copyMatches.find(c => c.companyId === user.companyId);
}
