import { useQuery } from '@apollo/client';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { useState } from 'react';
import { Badge } from '~/components/ui/badge';
import ReactSelect from 'react-select';
import { DEPOSITIONAL_HIERARCHY } from '~/apollo/operations/depositional';
import type { SelectOption } from '~/components/common/FormikField/FormikSelectField';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { filterUnique } from '~/utils/common';
import type { MiniModelFormValues } from '~/utils/modules/urlBasedSO';

function useHierarchyAEs(geologyTypes: string[]) {
  const { data, loading } = useQuery(DEPOSITIONAL_HIERARCHY);
  const hierarchy = data?.depositionalHierarchyFull;

  type HierarchyKey = keyof Omit<NonNullable<typeof hierarchy>, '__typename'>;

  const supportedGeologyTypes: HierarchyKey[] = [
    'clastic',
    'carbonate',
    'structural',
  ];

  let aes: string[] = [];

  if (hierarchy) {
    aes = geologyTypes
      .filter((gt): gt is HierarchyKey =>
        supportedGeologyTypes.some(sgt => sgt === gt),
      )
      .flatMap(gt =>
        hierarchy[gt].grossDepositionalEnvironment.flatMap(gde =>
          gde.depositionalEnvironment
            .flatMap(de => de.depositionalSubEnvironment)
            .flatMap(dse => dse.architecturalElement)
            .map(ae => ae.name),
        ),
      )
      .filter(filterUnique)
      .sort(R.ascend(ae => ae));
  }

  return {
    loading,
    aes,
  };
}

export function AEsManager({
  geologyTypes,
  fieldName,
  architecturalElements,
}: {
  geologyTypes: string[];
  fieldName: string;
  architecturalElements: string[];
}) {
  const { aes, loading } = useHierarchyAEs(geologyTypes);
  const { setFieldValue } = useFormikContext<MiniModelFormValues>();

  const [selectedAE, setSelectedAE] = useState<SelectOption | null>(null);

  function handleAddAE() {
    if (!selectedAE) return;
    setFieldValue(
      fieldName,
      [...architecturalElements, selectedAE.value].filter(filterUnique),
    );
    setSelectedAE(null);
  }

  function handleRemoveAE(aeToRemove: string) {
    setFieldValue(
      fieldName,
      architecturalElements.filter(ae => ae !== aeToRemove),
    );
  }

  const options: SelectOption[] = aes.map(value => ({
    value,
    label: value,
    isDisabled: architecturalElements.includes(value),
  }));

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      <div className="flex gap-6 items-end">
        <div className="grow">
          <FormLabel label="Architectural Elements" name="aes" />
          <ReactSelect<SelectOption>
            name="aes"
            isSearchable
            options={options}
            onChange={setSelectedAE}
            value={selectedAE}
            classNames={{
              control: () => 'w-full h-12',
            }}
          />
        </div>
        <div className="flex-shrink">
          <button
            type="button"
            onClick={handleAddAE}
            disabled={!selectedAE}
            className="btn btn-primary h-4"
          >
            Add
          </button>
        </div>
      </div>

      <div className="flex flex-wrap gap-2">
        {R.sortBy(ae => ae, architecturalElements).map(ae => (
          <Badge key={ae} color="ghost" className="gap-2 p-1 px-4">
            <span>{ae}</span>
            <Button
              size="xs"
              color="ghost"
              className="rounded-full"
              onClick={() => handleRemoveAE(ae)}
            >
              <FontAwesomeIcon icon={faRemove} />
            </Button>
          </Badge>
        ))}
      </div>
    </div>
  );
}
