import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { FormikField } from '~/components/common/FormikField';
import { InputGroup } from '~/components/common/InputGroup';
import type { RegisterCompanyUserFormValues } from '~/utils/modules/user';

type Props = {
  mails: string[];
};

function CreateCompanyUserFormFields({ mails }: Props) {
  const { values, setFieldValue } =
    useFormikContext<RegisterCompanyUserFormValues>();

  useEffect(() => {
    let username = '';
    if (values.usernamePrefix && values.usernameSuffix) {
      username = `${values.usernamePrefix}${values.usernameSuffix}`;
    }
    setFieldValue('username', username);
  }, [values.usernamePrefix, values.usernameSuffix, setFieldValue]);

  return (
    <>
      <Field name="name" label="Full Name" component={FormikField} required />

      <label htmlFor="usernamePrefix" className="label label-text">
        Email address
      </label>
      <Field
        name="usernamePrefix"
        component={FormikField}
        required
        className="join-item grow"
        renderInput={(input: JSX.Element) => (
          <InputGroup className="w-full">
            <InputGroup.Addon>
              <FontAwesomeIcon icon={faEnvelope} />
            </InputGroup.Addon>

            {input}
            <Field
              name="usernameSuffix"
              component={FormikField}
              type="select"
              options={mails.map(value => ({ value, label: value }))}
              required
              className="select join-item w-full"
            />
          </InputGroup>
        )}
      />

      {values.username && (
        <div className="text-center m-4 text-sm text-slate-500">
          The user will log in with the username:
          <br />
          <strong className="text-slate-700 text-base">
            {values.username}
          </strong>
        </div>
      )}

      <Field
        name="password"
        label="Password"
        component={FormikField}
        required
      />
    </>
  );
}

export default CreateCompanyUserFormFields;
