import { useQuery } from '@apollo/client';
import { graphql } from '~/apollo/generated/v3';
import type { OutcropPhoto360sPageQuery } from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useRouteParam } from '~/hooks/routing';

const OUTCROP_PHOTO_360S_PAGE = graphql(`
  query OutcropPhoto360sPage($id: Int!) {
    outcropList(id: $id) {
      id
      photo360s {
        id
        name
        url
      }
    }
  }
`);

export default function OutcropPhoto360sPage() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const { data, loading } = useQuery(OUTCROP_PHOTO_360S_PAGE, {
    variables: {
      id: outcropId,
    },
  });

  const outcrop = data?.outcropList.find(outcrop => outcrop.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <PageInner photo360s={outcrop.photo360s} />;
}

function PageInner({
  photo360s,
}: {
  photo360s: OutcropPhoto360sPageQuery['outcropList'][number]['photo360s'];
}) {
  return (
    <div className="grid lg:grid-cols-2 gap-6">
      {photo360s.map(p => (
        <div key={p.id} className="w-full aspect-square thumbnail">
          <iframe title={p.name} src={p.url} className="w-full h-full" />
          <div className="caption text-center">{p.name}</div>
        </div>
      ))}
    </div>
  );
}
