import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { UploadFormationListPageQuery } from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import {
  uploadLithostratFormationCreateRoute,
  uploadLithostratFormationUpdateMembersRoute,
  uploadLithostratFormationUpdateRoute,
  uploadLithostratGroupUpdateRoute,
} from '~/paths';
import { truncateText } from '~/utils/common';
import { mostSpecificAge } from '~/utils/modules/lithostratAge';

export const UPLOAD_FORMATION_LIST_PAGE = graphql(`
  query UploadFormationListPage {
    lithostratFormationList {
      ...lithostratFormationParts
      lithostratType {
        ...lithostratTypeParts
      }
      members {
        ...lithostratMemberParts
      }
      startAge {
        ...lithostratAgeParts
      }
      group {
        ...lithostratGroupParts
      }
      wikiPage {
        ...lithostratWikiPageParts
      }
    }
  }
`);

type FormationWithMeta =
  UploadFormationListPageQuery['lithostratFormationList'][number] & {
    wikiPublished: boolean;
    memberCount: number;
    age: string | null;
  };

export default function UploadFormationListRoute() {
  const { data, loading } = useQuery(UPLOAD_FORMATION_LIST_PAGE);

  const formationList = data?.lithostratFormationList ?? [];
  const formations: FormationWithMeta[] = formationList.map(f => ({
    ...f,
    memberCount: f.members.length,
    wikiPublished: f.wikiPage?.published ?? false,
    age: mostSpecificAge(f.startAge),
  }));

  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    formations,
    'name',
    'name',
    'uploadFormationList',
  );

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>
          Formations
          <small className="text-muted" style={{ marginLeft: '10px' }}>
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>

        <Link
          to={uploadLithostratFormationCreateRoute()}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlus} /> Create Formation
        </Link>
      </Panel.Heading>

      <Panel.Body>
        <FilterSearch {...filterSearchProps} />

        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger
                  colName="id"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  ID
                </SortTrigger>
              </th>
              <th className="lg:w-2/12">
                <SortTrigger
                  colName="name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="lithostratType.name"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Type
                </SortTrigger>
              </th>
              <th className="lg:w-4/12">Description</th>
              <th>
                <SortTrigger
                  colName="group.name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Group
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="age"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Start Age
                </SortTrigger>
              </th>
              <th className="w-2/12 text-center">
                <SortTrigger
                  colName="memberCount"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Members
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="wikiPublished"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Published
                </SortTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map(formation => (
              <tr key={formation.id}>
                <td>{formation.id}</td>
                <td>
                  <Link
                    to={uploadLithostratFormationUpdateRoute(formation.id)}
                    className="link"
                  >
                    {formation.name}
                  </Link>
                </td>
                <td>{formation.lithostratType?.name}</td>
                <td>{truncateText(formation.description, 100)}</td>
                <td>
                  {formation.group && (
                    <Link
                      to={uploadLithostratGroupUpdateRoute(formation.group.id)}
                      className="link"
                    >
                      {formation.group.name}
                    </Link>
                  )}
                </td>
                <td>{formation.age}</td>
                <td className="text-center">
                  <Link
                    to={uploadLithostratFormationUpdateMembersRoute(
                      formation.id,
                    )}
                    className="link"
                  >
                    {formation.memberCount}
                  </Link>
                </td>
                <td className="text-center">
                  <EnabledIndicator value={formation.wikiPublished} />
                </td>
              </tr>
            ))}

            <NoItemsRow show={!items.length} colSpan={8} />
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
