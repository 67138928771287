import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReportItemSummaryPartsFragment } from '~/apollo/generated/v3/graphql';
import { Tooltip } from '~/components/common/Tooltip';
import { Badge } from '~/components/ui/badge';
import { cn } from '~/utils/common';

function CountBadge({
  name,
  icon,
  count,
  isCompany,
}: {
  name: string;
  icon: FontAwesomeIconProps['icon'];
  count: number;
  isCompany: boolean;
}) {
  return (
    <Tooltip message={name} className="cursor-default">
      <Badge
        className={cn('inline-block space-x-2', {
          'border-sky-100 bg-sky-50': count && !isCompany,
          'border-wine-100 bg-wine-50': count && isCompany,
          'border-slate-100': !count,
        })}
      >
        <FontAwesomeIcon
          icon={icon}
          className={cn({
            'text-sky-500': count && !isCompany,
            'text-wine-500': count && isCompany,
            'text-slate-300': count === 0,
          })}
        />
        <span>{count}</span>
      </Badge>
    </Tooltip>
  );
}

export function ReportItemSummary({
  summary,
  isCompany,
}: {
  summary: ReportItemSummaryPartsFragment;
  isCompany: boolean;
}) {
  return (
    <div className="space-x-2">
      <CountBadge
        name="Bookmarks"
        icon={faBookmark}
        count={summary.bookmarkCount}
        isCompany={isCompany}
      />
      <CountBadge
        name="Text Items"
        icon={faComment}
        count={summary.textCount}
        isCompany={isCompany}
      />
    </div>
  );
}
