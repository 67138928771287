import { useQuery } from '@apollo/client';
import type {
  PagesByPageNameQuery,
  PagesByPageNameQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { PAGES_BY_PAGE_NAME } from '~/apollo/operations/page';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { FeaturedLinks } from '~/components/homeSponsor/FeaturedLinks';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Page } from '~/components/page/Page';

const pageName = 'how-to';

export default function HowToRoute() {
  const { data, loading } = useQuery<
    PagesByPageNameQuery,
    PagesByPageNameQueryVariables
  >(PAGES_BY_PAGE_NAME, {
    variables: { pageNames: [pageName] },
  });

  const pageList = data?.pageList ?? [];
  const page = pageList.find(p => p.pageName === pageName);

  useBreadcrumb('routes/how-to', page?.title ?? 'How To');

  if (loading) return <SpinnerPlaceholder />;
  if (!page) return <NotFound />;

  return (
    <>
      <Page page={page} />
      <FeaturedLinks />
    </>
  );
}
