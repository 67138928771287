import { useQuery } from '@apollo/client';
import { sortBy } from 'ramda';
import { graphql } from '~/apollo/generated/v3';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PictureNamesManager } from '~/components/upload/picture-names/PictureNamesManager';

const STUDY_PICTURE_NAMES_PAGE = graphql(`
  query StudyPictureNamesPage {
    studyList {
      id
      name
      pictures {
        ...pictureNameMgrParts
      }
      facies {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      crossSections {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      sedimentaryLogs {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      wellLogs {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      production {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      reservoirModels {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      trainingImages {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      variograms {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
      gigaPans {
        id
        name
        pictures {
          ...pictureNameMgrParts
        }
      }
    }
  }
`);

export default function StudyPictureNamesPage() {
  useBreadcrumb('routes/upload/util/picture-names', 'Picture name manager');

  const { data, loading, refetch } = useQuery(STUDY_PICTURE_NAMES_PAGE);

  const studies = sortBy(study => study.name, data?.studyList ?? []);

  if (loading) {
    return (
      <SpinnerPlaceholder>Loading all study pictures...</SpinnerPlaceholder>
    );
  }

  return <PictureNamesManager entities={studies} onSectionOpen={refetch} />;
}
