import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  UploadOutcropPicturesTabQuery,
  UploadOutcropPicturesTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { OutcropThumbnailManager } from '~/components/upload/outcrop/OutcropThumbnailManager';
import { SupportingObjectPictureList } from '~/components/upload/supportingObject/SupportingObjectPictureList';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';
import { PictureParent } from '~/apollo/generated/v3/graphql';
import { useState } from 'react';
import { Badge } from '~/components/ui/badge';

export const UPLOAD_OUTCROP_PICTURES_TAB = gql`
  query UploadOutcropPicturesTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      thumbnail {
        ...fileParts
        signedUrl
      }
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.fileParts}
  ${fragments.pictureParts}
`;

export default function UpdateOutcropPicturesRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropPicturesTabQuery,
    UploadOutcropPicturesTabQueryVariables
  >(UPLOAD_OUTCROP_PICTURES_TAB, {
    variables: { outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropPicturesTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_PICTURES_TAB,
      variables: { outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  const unpublishedCount =
    outcrop?.pictures.reduce((acc, cur) => acc + (cur.published ? 0 : 1), 0) ??
    0;

  const [showUnpublished, setShowUnpublished] = useState(true);

  const pictures = R.pipe(
    () => outcrop?.pictures ?? [],
    pictures =>
      showUnpublished ? pictures : pictures.filter(p => p.published),
    R.sortBy(R.propOr(Infinity, 'priority')),
  )();

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-6">
      <Panel>
        <Panel.Heading>
          <Panel.Title>Thumbnail</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <OutcropThumbnailManager
            outcropId={outcropId}
            thumbnail={outcrop.thumbnail}
            refetchQueries={refetchQueries}
          />
        </Panel.Body>
      </Panel>

      <div className="form-control items-end">
        <label
          htmlFor="showUnpublished"
          className="label justify-start gap-2 cursor-pointer"
        >
          <input
            type="checkbox"
            id="showUnpublished"
            checked={showUnpublished}
            onChange={() => setShowUnpublished(!showUnpublished)}
            className="checkbox checkbox-sm"
            disabled={!unpublishedCount}
          />
          Show unpublished items{' '}
          <Badge color="ghost" className="font-mono">
            {unpublishedCount}
          </Badge>
        </label>
      </div>

      <Panel>
        <Panel.Heading>
          <Panel.Title>Outcrop Pictures</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <SupportingObjectPictureList
            parentType={PictureParent.Outcrop}
            parentId={outcrop.id}
            pictures={pictures}
            refetchQueries={refetchQueries}
            showUnpublished={showUnpublished}
          />
        </Panel.Body>
      </Panel>
    </div>
  );
}
