import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import * as R from 'ramda';
import type { ChangeEvent, ChangeEventHandler } from 'react';
import React from 'react';
import { cn } from '~/utils/common';

export type Props = FieldProps & {
  className?: string;
  label?: React.ReactNode;
  isInvalid?: boolean;
  color?: 'primary' | 'success' | 'warning' | 'info' | 'error';
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /** onChange can be defined as a prop on <Field> that is *different* from formik's `field.onChange` handler. */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** Manual override of 'checked' prop */
  checked?: boolean;
};

export function FormikCheckboxField({
  field,
  form,
  className,
  label,
  color,
  disabled,
  size,
  onChange,
  checked,
}: Props) {
  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    // Stopping propagation on the label click event prevents dropdown boxes from
    // being immediately closed. Clicking the label still seems to fire the change
    // event on the input element even though this event is stopped.
    event.stopPropagation();
  };

  // Override default formik behavior for arrays to support non-string values
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    // If an onChange handler was defined on the <Field>, let it handle there.
    if (onChange) {
      return void onChange(event);
    }

    const curValue = form.values[field.name];

    if (Array.isArray(curValue)) {
      if (curValue.includes(field.value)) {
        form.setFieldValue(field.name, R.without([field.value], curValue));
      } else {
        form.setFieldValue(field.name, R.append(field.value, curValue));
      }
    } else {
      // Fall back to the default formik behavior for non-arrays (usually booleans?).
      // May not work correctly if there are non-bool non-string values somewhere.
      field.onChange(event);
    }
  }

  return (
    <div className={className}>
      <label className="label cursor-pointer py-1" onClick={handleLabelClick}>
        <input
          {...field}
          type="checkbox"
          onChange={handleChange}
          checked={checked ?? field.checked}
          className={cn('checkbox', {
            'checkbox-xs': size === 'xs',
            'checkbox-sm': size === 'sm' || !size,
            'checkbox-md': size === 'md',
            'checkbox-lg': size === 'lg',
            'checked:checkbox-primary': color === 'primary',
            'checked:checkbox-success': color === 'success',
            'checked:checkbox-warning': color === 'warning',
            'checked:checkbox-info': color === 'info',
            'checked:checkbox-error': color === 'error',
          })}
          disabled={disabled}
        />
        {label}
      </label>
      <div className="text-error">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
}
