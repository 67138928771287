import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v3';
import { FormErrors } from '~/components/common/FormErrors';
import { MiniModelFormFields } from '~/components/supportingObject/urlBasedSO/MiniModelFormFields';
import type { MiniModelFormValues } from '~/utils/modules/urlBasedSO';
import {
  initialMiniModel,
  miniModelValidationSchema,
  toMiniModelInput,
} from '~/utils/modules/urlBasedSO';

const CREATE_MINI_MODEL = graphql(`
  mutation CreateMiniModel($miniModel: CreateMiniModelInput!) {
    createMiniModel(miniModel: $miniModel) {
      id
    }
  }
`);

export function CreateMiniModelForm({
  outcropId,
  geologyTypes,
  refetchQueries,
  onCreateSuccess,
}: {
  outcropId: number;
  geologyTypes: string[];
  refetchQueries: PureQueryOptions[];
  onCreateSuccess?: () => void;
}) {
  const [createMiniModel, { loading, error }] = useMutation(CREATE_MINI_MODEL, {
    refetchQueries,
  });

  async function handleSubmit(values: MiniModelFormValues) {
    try {
      await createMiniModel({
        variables: {
          miniModel: {
            ...toMiniModelInput(values),
            outcropId,
          },
        },
      });
      toast.success('Mini-model created successfully.');
      if (onCreateSuccess) onCreateSuccess();
    } catch (err) {
      console.log('Error creating mini-model', err);
      toast.error(
        'There was a problem creating the mini-model. Please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialMiniModel()}
      validationSchema={miniModelValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <MiniModelFormFields geologyTypes={geologyTypes} />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
