import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';

import * as fragments from '~/apollo/fragments';
import type {
  UploadOutcropLithostratigraphyTabQuery,
  UploadOutcropLithostratigraphyTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateLithostratLinkModal } from '~/components/upload/outcrop/lithostratLink/CreateLinkModal';
import { OutcropLithostratLinksTable } from '~/components/upload/outcrop/OutcropLithostratLinksTable';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

const UPLOAD_OUTCROP_LITHOSTRATIGRAPHY_TAB = gql`
  query UploadOutcropLithostratigraphyTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      lithostratOutcropLinks {
        ...lithostratOutcropLinkParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.lithostratOutcropLinkParts}
`;

export default function UpdateOutcropLithostratigraphyRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropLithostratigraphyTabQuery,
    UploadOutcropLithostratigraphyTabQueryVariables
  >(UPLOAD_OUTCROP_LITHOSTRATIGRAPHY_TAB, {
    variables: { outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropLithostratigraphyTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_LITHOSTRATIGRAPHY_TAB,
      variables: { outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const links = outcrop?.lithostratOutcropLinks ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>Lithostrat Links</Panel.Title>

        <CreateLithostratLinkModal
          outcropId={outcropId}
          refetchQueries={refetchQueries}
        >
          {showModal => (
            <Button
              type="button"
              color="primary"
              size="xs"
              onClick={showModal}
              className="gap-1"
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Create Link
            </Button>
          )}
        </CreateLithostratLinkModal>
      </Panel.Heading>

      <Panel.Body>
        <OutcropLithostratLinksTable
          links={links}
          refetchQueries={refetchQueries}
        />
      </Panel.Body>
    </Panel>
  );
}
