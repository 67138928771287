import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import {
  faExternalLink,
  faPlus,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type * as schema from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { FormErrors } from '~/components/common/FormErrors';
import { Heading } from '~/components/common/Heading';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { ScaleTransition } from '~/components/common/ScaleTransition';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { ItemFormFieldsBookmark } from '~/components/report/ReportEditor/ItemFormFieldsBookmark';
import { useCreateReportItemMutation } from '~/components/report/ReportEditor/operations';
import { useSortFilter } from '~/hooks/data';
import type {
  BookmarkFilterableType,
  StandardizedBookmark,
} from '~/utils/modules/bookmark';
import {
  bookmarkTypeFilterMergeFn,
  bookmarkTypeOptionsSortFn,
  prettyFilterableType,
  prettyTargetType,
  toStandardizedBookmark,
} from '~/utils/modules/bookmark';
import type { ReportItemFormValuesBookmark } from '~/utils/modules/report';
import {
  reportItemBookmarkInitialValues,
  reportItemBookmarkValidationSchema,
} from '~/utils/modules/report';

const ADD_BOOKMARK_TO_REPORT_FORM = gql`
  query AddBookmarkToReportForm(
    $includePersonal: Boolean!
    $includeCompany: Boolean!
  ) {
    bookmarkList(
      includePersonal: $includePersonal
      includeCompany: $includeCompany
    ) {
      ...bookmarkParts
      user {
        ...publicUserParts
      }
      parent {
        ...bookmarkParentParts
      }
      target {
        ...bookmarkTargetParts
      }
      collections {
        ...bookmarkCollectionParts
      }
    }
  }

  ${fragments.bookmarkParts}
  ${fragments.publicUserParts}
  ${fragments.bookmarkParentParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.bookmarkCollectionParts}
  ${fragments.fileParts}
  ${fragments.pictureParts}
`;

export function AddBookmark({
  reportId,
  currentBookmarkIds,
  isCompany,
  refetchQueries,
}: {
  reportId: number;
  currentBookmarkIds: number[];
  isCompany: boolean;
  refetchQueries: PureQueryOptions[];
}) {
  // The selected bookmark to be added to the report
  const [bookmark, setBookmark] = useState<StandardizedBookmark | null>(null);

  const queryVariables: schema.AddBookmarkToReportFormQueryVariables = {
    includePersonal: !isCompany,
    includeCompany: isCompany,
  };

  const { data, loading } = useQuery<
    schema.AddBookmarkToReportFormQuery,
    schema.AddBookmarkToReportFormQueryVariables
  >(ADD_BOOKMARK_TO_REPORT_FORM, { variables: queryVariables });

  const isInReport = (bookmarkId: number) =>
    currentBookmarkIds.includes(bookmarkId);

  const stdBookmarks = (data?.bookmarkList ?? [])
    .map(toStandardizedBookmark)
    .map(bm => ({ ...bm, isInReport: isInReport(bm.id) }));

  const { items, filterSearchProps, sortIndicatorProps, isFiltered } =
    useSortFilter(
      stdBookmarks,
      [
        ['isInReport', 'desc'],
        ['targetName', 'asc'],
      ],
      'targetName',
      'addBookmarkToReportSelector',
      'asc',
      bookmarkTypeFilterMergeFn,
      bookmarkTypeOptionsSortFn,
    );

  const bookmarks = items;

  if (loading) return <SpinnerPlaceholder />;

  if (!bookmarks.length) {
    return <p>No bookmarks available.</p>;
  }

  if (bookmark) {
    return (
      <AddBookmarkForm
        reportId={reportId}
        bookmark={bookmark}
        refetchQueries={refetchQueries}
        onDone={() => setBookmark(null)}
      />
    );
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Add a bookmark</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <div className="space-y-4">
          <div className="flex justify-between items-center gap-6">
            <div className="flex-grow">
              <FilterSearch
                {...filterSearchProps}
                showAlphabet={false}
                stick={false}
                renderSearch={input => <div>{input}</div>}
              />
            </div>

            <div className="flex justify-end items-center gap-1">
              <Disclosure defaultOpen={isFiltered}>
                {({ open }) => (
                  <>
                    <ScaleTransition show={open}>
                      <Disclosure.Panel static>
                        <div className="flex justify-end items-center gap-1">
                          <SortTrigger
                            colName="isInReport"
                            sortIndicatorProps={sortIndicatorProps}
                          >
                            In Report
                          </SortTrigger>
                          <SortTrigger
                            colName="targetName"
                            sortIndicatorProps={sortIndicatorProps}
                          >
                            Name
                          </SortTrigger>
                          <SortTrigger
                            colName="insertedAt"
                            sortIndicatorProps={sortIndicatorProps}
                          >
                            Created
                          </SortTrigger>
                          <SortTrigger
                            colName="filterableType"
                            sortIndicatorProps={sortIndicatorProps}
                            filterable
                            renderFilterOption={val => {
                              if (val === 'PICTURE') return 'Picture';
                              return prettyFilterableType(
                                val as BookmarkFilterableType,
                              );
                            }}
                          >
                            Type
                          </SortTrigger>
                          <SortTrigger
                            colName="user.name"
                            sortIndicatorProps={sortIndicatorProps}
                            filterable
                          >
                            Author
                          </SortTrigger>
                          <SortTrigger
                            colName="hasNote"
                            sortIndicatorProps={sortIndicatorProps}
                            filterable
                            renderFilterOption={val => {
                              if (val === 'true') return 'Has a note';
                              return 'Does not have a note';
                            }}
                          >
                            Note
                          </SortTrigger>
                          <SortTrigger
                            colName="isInCollection"
                            sortIndicatorProps={sortIndicatorProps}
                            filterable
                            renderFilterOption={val => {
                              if (val === 'true') return 'Is in a collection';
                              return 'Is not in a collection';
                            }}
                          >
                            Collection
                          </SortTrigger>
                        </div>
                      </Disclosure.Panel>
                    </ScaleTransition>

                    <Disclosure.Button
                      as={Button}
                      color={open ? undefined : 'ghost'}
                      size="sm"
                      className="gap-1"
                    >
                      <FontAwesomeIcon icon={faSort} /> Sort
                    </Disclosure.Button>
                  </>
                )}
              </Disclosure>
            </div>
          </div>

          <ListGroup>
            {bookmarks.map(bookmark => (
              <ListGroup.Item key={bookmark.id}>
                <div className="flex justify-between gap-6">
                  <div className="flex gap-2">
                    <Tooltip
                      disabled={!isInReport(bookmark.id)}
                      message="This item is already included in the report."
                    >
                      <Button
                        type="button"
                        onClick={() => setBookmark(bookmark)}
                        color={isInReport(bookmark.id) ? 'ghost' : 'primary'}
                        size="xs"
                        disabled={isInReport(bookmark.id)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Tooltip>

                    <span className="space-x-2">
                      <span>{bookmark.targetName}</span>
                      <Link
                        to={bookmark.path}
                        className="text-slate-300 hover:text-slate-500"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faExternalLink} />
                      </Link>
                    </span>
                  </div>

                  <div className="text-muted">
                    {prettyTargetType(bookmark.targetType, bookmark.parentType)}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </Panel.Body>
    </Panel>
  );
}

function AddBookmarkForm({
  reportId,
  bookmark,
  refetchQueries,
  onDone,
}: {
  reportId: number;
  bookmark: StandardizedBookmark;
  refetchQueries: PureQueryOptions[];
  /** Callback function that is fired when the item is created or cancelled */
  onDone: () => void;
}) {
  const [createReportItem, { loading, error }] = useCreateReportItemMutation({
    refetchQueries,
  });

  async function handleSubmit(values: ReportItemFormValuesBookmark) {
    const reportItem: schema.CreateReportItemInput = {
      bookmark: {
        bookmarkId: bookmark.id,
        text: values.text.trim() || null,
        outcropShowDescription: values.outcropShowDescription ?? false,
        outcropShowIntroduction: values.outcropShowIntroduction ?? false,
        outcropShowEnvironments: values.outcropShowEnvironments ?? false,
        outcropShowStratigraphy: values.outcropShowStratigraphy ?? false,
        outcropShowDiagenesisAndPetrophysicalProperties:
          values.outcropShowDiagenesisAndPetrophysicalProperties ?? false,
        outcropShowStructuralGeology:
          values.outcropShowStructuralGeology ?? false,
        outcropShowNotablesAndAnalogues:
          values.outcropShowNotablesAndAnalogues ?? false,
        outcropShowLocation: values.outcropShowLocation ?? false,
        outcropShowSafari: values.outcropShowSafari ?? false,
        outcropShowKeyParameters: values.outcropShowKeyParameters ?? false,
        outcropShowPaleogeography: values.outcropShowPaleogeography ?? false,
        studyShowAbstract: values.studyShowAbstract ?? false,
      },
    };

    try {
      await createReportItem({
        variables: {
          reportId,
          reportItem,
        },
      });
      toast.success('Bookmark added to report.');
      onDone();
    } catch (err) {
      console.log('Error adding bookmark to report', err);
      toast.error('There was a problem adding the bookmark to the report.');
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={reportItemBookmarkInitialValues(undefined, bookmark)}
        validationSchema={reportItemBookmarkValidationSchema}
      >
        <Form>
          <Panel>
            <Panel.Heading>
              <div className="flex justify-between items-center gap-6">
                <Panel.Title>Add Bookmark To Report</Panel.Title>
                <Button type="button" onClick={onDone} color="ghost" size="xs">
                  Cancel
                </Button>
              </div>
            </Panel.Heading>

            <Panel.Body>
              <Heading level={3}>
                {prettyTargetType(bookmark.targetType, bookmark.parentType)}:{' '}
                {bookmark.targetName}
              </Heading>

              <div className="space-y-4">
                <ItemFormFieldsBookmark bookmark={bookmark} />
                <FormErrors graphQLError={error} />
              </div>
            </Panel.Body>

            <Panel.Footer>
              <div className="text-right">
                <Button type="submit" color="primary" loading={loading}>
                  Save
                </Button>
              </div>
            </Panel.Footer>
          </Panel>
        </Form>
      </Formik>
    </>
  );
}
