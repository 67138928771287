import type { LinkProps } from 'react-router';
import { Link, useSearchParams } from 'react-router';
import { cn } from '~/utils/common';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { Panel } from '~/components/common/Panel';
import { useQueryString } from '~/hooks/routing';
import type {
  MeasurementCounts,
  OutcropCounts,
  WikiCounts,
} from '~/routes/dashboard';
import {
  searchRoute,
  wikiSearchGeologyRoute,
  wikiSearchLithostratigraphyRoute,
  wikiSearchOtherRoute,
} from '~/paths';

type ResultLinkProps = {
  to: LinkProps['to'];
  count: number;
  children: ReactNode;
};
function ResultLink({ to, count, children }: ResultLinkProps) {
  return (
    <div className="text-base space-x-2 space-y-1">
      <Link to={to} className={count ? 'link' : 'text-muted'}>
        {children}
      </Link>
      <div
        className={cn('badge cursor-default', {
          'badge-ghost text-muted': !count,
          'badge-primary text-white': count > 0,
        })}
      >
        {count}
      </div>
    </div>
  );
}

type Props = {
  measurementCount?: MeasurementCounts | null;
  outcropCount?: OutcropCounts | null;
  wikiCount?: WikiCounts | null;
};

export function DashboardSearchResults({
  measurementCount,
  outcropCount,
  wikiCount,
}: Props) {
  const { stringify } = useQueryString();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const searchLink = useCallback(
    (outcropCategory: string): LinkProps['to'] => {
      return {
        pathname: searchRoute(),
        search: stringify({
          textSearch: searchParams.get('q') ?? '',
          outcropCategory,
        }),
      };
    },
    [searchParams, stringify],
  );

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Search results</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <div className="lg:w-3/4 mx-auto space-y-4">
          {measurementCount && (
            <div className="grid lg:grid-cols-3 gap-6">
              <div className="text-right text-muted">
                Numerical data and statistics
              </div>
              <div className="lg:col-span-2">
                <b>{measurementCount.measurements}</b> measurements from{' '}
                <b>{measurementCount.studies}</b> studies
              </div>
            </div>
          )}

          {outcropCount && (
            <div className="grid lg:grid-cols-3 gap-6">
              <div className="text-right text-muted">Analogue descriptions</div>
              <div className="lg:col-span-2">
                <div>
                  <ResultLink
                    to={searchLink('outcrop')}
                    count={outcropCount.outcrops}
                  >
                    Outcrops
                  </ResultLink>
                </div>
                <div>
                  <ResultLink
                    to={searchLink('production')}
                    count={outcropCount.production}
                  >
                    Production
                  </ResultLink>
                </div>
                <div>
                  <ResultLink
                    to={searchLink('seismic')}
                    count={outcropCount.seismic}
                  >
                    Seismics
                  </ResultLink>
                </div>
                <div>
                  <ResultLink
                    to={searchLink('modern')}
                    count={outcropCount.modern}
                  >
                    Modern
                  </ResultLink>
                </div>
              </div>
            </div>
          )}

          {wikiCount && (
            <div className="grid lg:grid-cols-3 gap-6">
              <div className="text-right text-muted">Knowledge base</div>
              <div className="lg:col-span-2">
                <div>
                  <ResultLink
                    to={{
                      pathname: wikiSearchGeologyRoute(),
                      search: queryString,
                    }}
                    count={wikiCount.geology}
                  >
                    Geology articles
                  </ResultLink>
                </div>
                <div>
                  <ResultLink
                    to={{
                      pathname: wikiSearchLithostratigraphyRoute(),
                      search: queryString,
                    }}
                    count={wikiCount.lithostrat}
                  >
                    Lithostrat
                  </ResultLink>
                </div>
                <div>
                  <ResultLink
                    to={{
                      pathname: wikiSearchOtherRoute(),
                      search: queryString,
                    }}
                    count={wikiCount.other}
                  >
                    Other articles
                  </ResultLink>
                </div>
              </div>
            </div>
          )}
        </div>
      </Panel.Body>
    </Panel>
  );
}
