import type { UserPartsFragment } from '~/apollo/generated/v3/graphql';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import CompanyAdminUserListDeletedUsers from './CompanyAdminUserListDeletedUsers';
import CompanyAdminUserListItem from './CompanyAdminUserListItem';

type Props = {
  users: UserPartsFragment[];
};

export function CompanyAdminUserList({ users }: Props) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    users,
    [
      ['isCompanyAdmin', 'desc'],
      ['name', 'asc'],
    ],
    'name',
    'companyAdminList',
  );

  const activeUsers = items.filter(user => !user.accountLocked);
  const lockedUsers = items.filter(user => user.accountLocked);

  return (
    <table className="table table-compact table-auto w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={siProps}>
              Name
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="username" sortIndicatorProps={siProps}>
              Email
            </SortTrigger>
          </th>
          <th className="text-center">
            <SortTrigger colName="insertedAt" sortIndicatorProps={siProps}>
              Registered
            </SortTrigger>
          </th>
          <th className="text-center">Email Verified</th>
          <th className="text-center">Company Admin</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {activeUsers.map(user => (
          <CompanyAdminUserListItem key={user.id} user={user} />
        ))}
      </tbody>

      {lockedUsers.length > 0 && (
        <CompanyAdminUserListDeletedUsers userList={lockedUsers} colSpan={5} />
      )}
    </table>
  );
}
