import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { SupportingObjectPlacementUpdateInput } from '~/apollo/generated/v4/graphql';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { LifecycleStatusIcon } from '~/components/common/icons/LifecycleStatusIcon';
import { Modal } from '~/components/common/Modal';
import { Tooltip } from '~/components/common/Tooltip';
import { Button } from '~/components/ui/button';
import { useModalState } from '~/hooks/modal';
import type { SO } from '~/routes/upload/model/outcrop/$outcropId/so-placements';
import { snakeCapsToHuman } from '~/utils/text';

const UPDATE_SO_PLACEMENT_STATUS = graphql(`
  mutation UpdateSOPlacementStatus(
    $id: ID!
    $input: SupportingObjectPlacementUpdateInput!
  ) {
    supportingObjectPlacementUpdate(id: $id, input: $input) {
      result {
        ...placementSOPlacementParts
      }
    }
  }
`);

type FormValues = {
  status: LifecycleStatus;
};

export function SOPlacementStatusModal({
  placement,
}: {
  placement: NonNullable<SO['placement']>;
}) {
  const { show, showModal, hideModal } = useModalState();

  const [updateStatus, { loading, error }] = useMutation(
    UPDATE_SO_PLACEMENT_STATUS,
  );

  async function handleSubmit(values: FormValues) {
    const input: SupportingObjectPlacementUpdateInput = {
      lifecycleStatus: values.status,
    };

    try {
      await updateStatus({
        variables: {
          id: placement.id,
          input,
        },
      });
      toast.success('Status updated.');
      hideModal();
    } catch (err) {
      console.log('Error updating status:', err);
      toast.error(
        'There was a problem updating the status. Please reload the page and try again.',
      );
    }
  }

  return (
    <>
      <Tooltip
        message={snakeCapsToHuman(placement.lifecycleStatus)}
        position="left"
        className="p-0"
      >
        <Button
          type="button"
          onClick={showModal}
          color="ghost"
          size="xs"
          startIcon={<LifecycleStatusIcon status={placement.lifecycleStatus} />}
        />
      </Tooltip>

      <Modal open={show} onHide={hideModal} closeable={!loading}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ status: placement.lifecycleStatus }}
        >
          <Form>
            <Modal.Body heading="Update Status">
              <div className="space-y-4">
                <div>
                  <div className="label">From</div>
                  <div className="input w-full">
                    <LifecycleStatusIcon status={placement.lifecycleStatus} />
                    {snakeCapsToHuman(placement.lifecycleStatus)}
                  </div>
                </div>

                <fieldset className="fieldset border border-slate-300 rounded px-2">
                  <legend className="fieldset-legend px-2">To</legend>

                  <Field
                    name="status"
                    component={FormikField}
                    type="radio"
                    options={[
                      { value: LifecycleStatus.Created, text: 'Created' },
                      {
                        value: LifecycleStatus.ReadyForApproval,
                        text: 'Ready for Approval',
                      },
                      { value: LifecycleStatus.Approved, text: 'Approved' },
                      { value: LifecycleStatus.Published, text: 'Published' },
                    ]}
                  />
                </fieldset>

                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right space-x-2">
                <Button
                  type="button"
                  color="ghost"
                  onClick={() => {
                    hideModal();
                  }}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={loading}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
