import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import type { ChangeEvent, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { Badge } from '~/components/ui/badge';
import { FormikField } from '~/components/common/FormikField';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { FormikCheckboxArrayDropdown } from '~/components/common/FormikField/FormikCheckboxArrayDropdown';
import { Heading } from '~/components/common/Heading';
import { Tooltip } from '~/components/common/Tooltip';
import { Well } from '~/components/common/Well';
import { useHistogramData } from '~/components/dataSearch/useHistogramData';
import { GraphTypeSelectors } from '~/components/statistics/MeasurementGraph/GraphTypeSelectors';
import { Button, type ButtonProps } from '~/components/ui/button';
import { sortByArray } from '~/utils/common';
import type {
  DataSearchFormValues,
  DataSearchOption,
  DataSearchOptions,
} from '~/utils/modules/dataSearch';
import {
  orderMeasurementCompleteness,
  orderMeasurementQuality,
} from '~/utils/modules/dataSearch';
import { snakeToHuman, ucwords } from '~/utils/text';

type Props = {
  options: DataSearchOptions;
  disabled?: boolean;
  locked?: boolean;
  children?: React.ReactNode;
  prependFields?: React.ReactNode;
};

export function GraphFormFields({
  options,
  disabled = false,
  locked = false,
  children,
  prependFields,
}: Props) {
  const { values, setFieldValue } = useFormikContext<DataSearchFormValues>();

  useEffect(() => {
    if (
      values.crossPlot.dataTypeX &&
      values.crossPlot.dataTypeY &&
      values.crossPlot.dataTypeX === values.crossPlot.dataTypeY
    ) {
      setFieldValue('crossPlot.dataTypeY', null);
    }
  }, [values.crossPlot.dataTypeX, values.crossPlot.dataTypeY, setFieldValue]);

  const handleLogScaleChange =
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.target.checked;
      setFieldValue(fieldName, nextValue);

      // Clear zero-intercept regression when any log axis is enabled
      if (nextValue) {
        setFieldValue('crossPlot.showZIRegressionLine', false);
      }
    };

  type EnumerableFields = keyof Omit<DataSearchOptions, 'outcrops'>;

  const mapSelectOption =
    (field: EnumerableFields) => (value: DataSearchOption) => {
      let label = `${ucwords(value.name)} (${value.count})`;

      const disabled =
        field === 'dataTypeY' && value.name === values.crossPlot.dataTypeX;

      return {
        value: value.name,
        label,
        disabled,
      };
    };

  return (
    <div className="space-y-4">
      <GraphTypeSelectors disabled={disabled || locked} />

      {prependFields}

      {/* X-Axis */}
      <fieldset className="border border-slate-200 p-2">
        <legend className="px-2 font-bold">X-Axis</legend>

        <div className="-mt-3 space-y-2">
          <div>
            <Field
              name={
                values.graphType === 'crossPlot'
                  ? 'crossPlot.dataTypeX'
                  : 'histogram.dataType'
              }
              label="Data type"
              component={FormikField}
              type="select"
              options={options.dataTypeX
                .filter(opt => opt.count > 0)
                .map(mapSelectOption('dataTypeX'))}
              showBlankOption
              required
              disabled={disabled || locked}
            />
          </div>

          <div className="grid lg:grid-cols-2 print:grid-cols-2 gap-6">
            <div>
              <MeasurementQualityField
                name="measurementQualityX"
                options={options.measurementQualityX}
                disabled={disabled || locked}
                locked={locked}
              />
            </div>

            <div>
              <MeasurementCompletenessField
                name="measurementCompletenessX"
                options={options.measurementCompletenessX}
                disabled={disabled || locked}
                locked={locked}
              />
            </div>
          </div>

          {values.graphType === 'crossPlot' && (
            <div>
              <Field
                name="crossPlot.logScaleX"
                label="Logarithmic"
                component={FormikField}
                type="checkbox"
                size="sm"
                onChange={handleLogScaleChange('crossPlot.logScaleX')}
              />
            </div>
          )}
        </div>
      </fieldset>

      {/* Y-Axis */}
      {values.graphType === 'crossPlot' && (
        <fieldset className="border border-slate-200 p-2">
          <legend className="px-2 font-bold">Y-Axis</legend>
          <div className="-mt-3 space-y-2">
            <div>
              <Field
                name="crossPlot.dataTypeY"
                label="Data type"
                component={FormikField}
                type="select"
                options={options.dataTypeY
                  .filter(opt => opt.count > 0)
                  .map(mapSelectOption('dataTypeY'))}
                showBlankOption
                required
                disabled={disabled || locked}
              />
            </div>

            <div className="grid lg:grid-cols-2 print:grid-cols-2 gap-6">
              <div>
                <MeasurementQualityField
                  name="measurementQualityY"
                  options={options.measurementQualityY}
                  disabled={disabled || locked}
                  locked={locked}
                />
              </div>

              <div>
                <MeasurementCompletenessField
                  name="measurementCompletenessY"
                  options={options.measurementCompletenessY}
                  disabled={disabled || locked}
                  locked={locked}
                />
              </div>
            </div>

            <div>
              <Field
                name="crossPlot.logScaleY"
                label="Logarithmic"
                component={FormikField}
                type="checkbox"
                size="sm"
                onChange={handleLogScaleChange('crossPlot.logScaleY')}
              />
            </div>
          </div>
        </fieldset>
      )}

      {children}

      {values.graphType === 'histogram' && (
        <Well>
          <Heading level={4}>Graph Options</Heading>
          <HistogramFormFields />
        </Well>
      )}
    </div>
  );
}

function OptionLabel({ name, count }: { name: string; count: number }) {
  return (
    <span className="label-text space-x-1">
      <span>{name}</span>
      <Badge color="ghost">{count}</Badge>
    </span>
  );
}

function LockedField({
  label,
  name,
  options,
  renderName = str => str,
}: {
  label: React.ReactNode;
  name: string;
  options: DataSearchOption[];
  renderName?: (fieldName: string) => string;
}) {
  return (
    <div>
      {label}

      <div className="bg-neutral-100 rounded-lg p-2">
        {!options.length && (
          <div className="text-sm text-muted italic">(Not selected)</div>
        )}
        {options.map(opt => (
          <Field
            key={opt.name}
            name={name}
            component={FormikField}
            type="checkbox"
            value={opt.name}
            label={
              <OptionLabel name={renderName(opt.name)} count={opt.count} />
            }
            disabled
            size="sm"
          />
        ))}
      </div>
    </div>
  );
}

function MeasurementCompletenessField({
  name,
  options,
  disabled,
  locked,
}: {
  name: string;
  options: DataSearchOption[];
  disabled: boolean;
  locked: boolean;
}) {
  const label = (
    <FormLabel name={name} label="Completeness" wikiPopoverId={31} />
  );

  if (locked) {
    return (
      <LockedField
        label={label}
        name={name}
        options={options}
        renderName={ucwords}
      />
    );
  }

  return (
    <>
      {label}
      <Field
        name={name}
        component={FormikField}
        type={FormikCheckboxArrayDropdown}
        options={sortByArray(
          options,
          orderMeasurementCompleteness,
          opt => opt.name,
        ).map(opt => ({
          value: opt.name,
          label: <OptionLabel name={ucwords(opt.name)} count={opt.count} />,
        }))}
        disabled={disabled}
        dropdownBtnClass="w-full"
      />
    </>
  );
}

function MeasurementQualityField({
  name,
  options,
  disabled,
  locked,
}: {
  name: string;
  options: DataSearchOption[];
  disabled: boolean;
  locked: boolean;
}) {
  const label = <FormLabel name={name} label="Confidence" wikiPopoverId={83} />;

  if (locked) {
    return (
      <LockedField
        name={name}
        label={label}
        options={options}
        renderName={snakeToHuman}
      />
    );
  }

  return (
    <>
      {label}

      <Field
        name={name}
        component={FormikField}
        type={FormikCheckboxArrayDropdown}
        options={sortByArray(
          options,
          orderMeasurementQuality,
          opt => opt.name,
        ).map(opt => ({
          value: opt.name,
          label: (
            <OptionLabel name={snakeToHuman(opt.name)} count={opt.count} />
          ),
        }))}
        disabled={disabled}
        dropdownBtnClass="w-full"
      />
    </>
  );
}

function HistogramFormFields() {
  const { values, setFieldValue, setValues } =
    useFormikContext<DataSearchFormValues>();

  const { dataRange, binnedData } = useHistogramData();

  const handleBinWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValues(prevValues => ({
      ...prevValues,
      histogram: {
        ...prevValues.histogram,
        binWidth: value ? parseInt(value) : null,
        numBins: null,
      },
    }));
  };

  const handleNumBinsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValues(prevValues => ({
      ...prevValues,
      histogram: {
        ...prevValues.histogram,
        numBins: value ? parseInt(value) : null,
        binWidth: null,
      },
    }));
  };

  return (
    <>
      <div className="grid lg:grid-cols-2 gap-6">
        <div>
          <Field
            name="histogram.minX"
            label="Data range"
            component={FormikField}
            type="number"
            placeholder={dataRange[0]}
            className="w-full input-sm"
            renderInput={(input: JSX.Element) => (
              <MagicField
                input={input}
                fieldValue={values.histogram.minX}
                onClick={() => setFieldValue('histogram.minX', null)}
              />
            )}
          />
        </div>
        <div>
          <Field
            name="histogram.maxX"
            label={<>&nbsp;</>}
            component={FormikField}
            type="number"
            placeholder={dataRange[1]}
            className="w-full input-sm"
            renderInput={(input: JSX.Element) => (
              <MagicField
                input={input}
                fieldValue={values.histogram.maxX}
                onClick={() => setFieldValue('histogram.maxX', null)}
              />
            )}
          />
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-6">
        <div>
          <Field
            name="histogram.binWidth"
            label="Bin width"
            component={FormikField}
            type="number"
            onChange={handleBinWidthChange}
            placeholder={binnedData?.binWidth}
            className="w-full input-sm"
            renderInput={(input: JSX.Element) => (
              <MagicField
                input={input}
                fieldValue={values.histogram.binWidth}
                onClick={() => setFieldValue('histogram.binWidth', null)}
              />
            )}
          />
        </div>
        <div>
          <Field
            name="histogram.numBins"
            label="Number of bins"
            component={FormikField}
            type="number"
            onChange={handleNumBinsChange}
            placeholder={binnedData?.numBins}
            className="w-full input-sm"
            renderInput={(input: JSX.Element) => (
              <MagicField
                input={input}
                fieldValue={values.histogram.numBins}
                onClick={() => setFieldValue('histogram.numBins', null)}
              />
            )}
          />
        </div>
      </div>

      <div className="mt-2">
        <Field
          name="histogram.nice"
          label="Regular bin widths"
          component={FormikField}
          type="checkbox"
        />
        <div className="text-muted text-sm -mt-2 ml-9">
          Constrains the width of each bin to rounded numbers.
        </div>
      </div>
    </>
  );
}

function MagicField({
  fieldValue,
  input,
  ...buttonProps
}: ButtonProps & {
  input: ReactNode;
  fieldValue: number | null;
}) {
  return (
    <Tooltip message="Calculate automatically based on data">
      <div className="flex items-center gap-1">
        {input}
        <Button
          type="button"
          color={fieldValue !== null ? 'ghost' : 'primary'}
          size="xs"
          onClick={buttonProps.onClick}
        >
          <FontAwesomeIcon icon={faWandMagicSparkles} />
        </Button>
      </div>
    </Tooltip>
  );
}
