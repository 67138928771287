import * as R from 'ramda';
import type { OutcropOverviewTabQuery } from '~/apollo/generated/v3/graphql';
import { Carousel } from '~/components/ui/carousel';

type OutcropPicture =
  OutcropOverviewTabQuery['outcropList'][number]['pictures'][number];

type Props = {
  pictures: OutcropPicture[];
};

export function OutcropPicturesCarousel({ pictures }: Props) {
  const sortedPictures = R.sortBy(pic => pic.priority ?? Infinity, pictures);

  return (
    <Carousel
      className="rounded-box h-48"
      items={sortedPictures.map(pic => ({
        src: pic.file.signedUrl,
        alt: pic.name,
      }))}
    />
  );
}
