import { Outlet } from 'react-router';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import {
  outcropPictureNamesUtilRoute,
  studyPictureNamesUtilRoute,
} from '~/paths';

export function PictureNamesUtilPage() {
  // useFullWidthPage();
  useBreadcrumb('routes/upload/util/picture-names', 'Picture name manager');

  return (
    <>
      <PageHeading>Picture Name Manager</PageHeading>
      <div className="space-y-2">
        <NavTabs>
          <NavTabs.Tab to={outcropPictureNamesUtilRoute()}>
            Outcrops
          </NavTabs.Tab>
          <NavTabs.Tab to={studyPictureNamesUtilRoute()}>Studies</NavTabs.Tab>
        </NavTabs>
        <Outlet />
      </div>
    </>
  );
}
