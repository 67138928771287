import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '~/components/ui/badge';

export function CoordinatesText({
  exifLocation,
  location,
}: {
  exifLocation: google.maps.LatLngLiteral | null;
  location: google.maps.LatLngLiteral | null;
}) {
  if (!location && !exifLocation) {
    return (
      <div className="border border-amber-100 bg-amber-50 p-4 flex gap-6 items-center rounded-xs text-amber-900">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-3xl text-amber-200"
        />

        <div>
          GPS coordinates could not be detected automatically from this image.
          <br />
          You can manually select coordinates using the map to load suggested
          outcrops, or pick an outcrop from the list.
        </div>
      </div>
    );
  }

  if (location) {
    return (
      <div className="text-muted font-mono text-center">
        ( <Badge color="ghost">Lng</Badge> {location.lng},{' '}
        <Badge color="ghost">Lat</Badge> {location.lat} )
      </div>
    );
  }

  return <div>No location set.</div>;
}
