import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { FieldErrors } from '~/components/ui/forms/FieldErrors';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { FormLabel } from '~/components/ui/forms/FormLabel';

export type InputBaseProps = {
  name: string;
  id: string;
  containerProps?: ComponentPropsWithoutRef<'div'>;
  label?: FormLabelProps['label'];
  required?: boolean;
  children: ReactNode;
};

export function InputBase({
  containerProps,
  name,
  label,
  required,
  children,
}: InputBaseProps) {
  return (
    <div {...containerProps}>
      <FormLabel name={name} label={label} required={required} />
      {children}
      <FieldErrors name={name} />
    </div>
  );
}
