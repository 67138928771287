import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { Field } from 'formik';
import type { LithostratWikiFormOptionsQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';
import { CountryPicker } from '~/components/gis/CountryPicker';
import { BasinTypeSelect } from '~/components/upload/keyParameter/BasinTypeSelect';
import { ClimateSelect } from '~/components/upload/keyParameter/ClimateSelect';

const LITHOSTRAT_WIKI_FORM_OPTIONS = gql`
  query LithostratWikiFormOptions {
    outcropEnumerations(type: GEOLOGY_TYPE) {
      values
    }

    regionList {
      id
      name
      location {
        country
      }
    }
  }
`;

export function LithostratWikiFormFields() {
  const { data, loading } = useQuery<LithostratWikiFormOptionsQuery>(
    LITHOSTRAT_WIKI_FORM_OPTIONS,
  );

  const geologyTypes = data?.outcropEnumerations.values ?? [];
  const geologyTypeOpts = geologyTypes.map(opt => ({ value: opt, label: opt }));

  return (
    <div className="space-y-2">
      <Field name="name" label="Name" component={FormikField} required />

      <Field
        name="alternativeNames"
        label="Alternative names"
        component={FormikField}
      />

      <div className="grid lg:grid-cols-3 gap-6">
        <div>
          <Field
            name="climate"
            label="Climate"
            component={FormikField}
            type={ClimateSelect}
          />
        </div>
        <div>
          <Field
            name="basinType"
            label="Basin Type"
            component={FormikField}
            type={BasinTypeSelect}
          />
        </div>
        <div>
          <Field
            name="geologyType"
            label="Geology Type"
            component={FormikField}
            type="select"
            options={geologyTypeOpts}
            disabled={loading}
            multiple
          />
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-6">
        <div>
          <Field
            name="region"
            label={
              <span className="text-muted italic">
                <span className="line-through">Region</span> (deprecated)
              </span>
            }
            component={FormikField}
          />
        </div>
        <div>
          <Field
            name="country"
            label="Country"
            component={FormikField}
            type={CountryPicker}
            required
          />
        </div>
      </div>

      <Field
        name="introduction"
        label="Introduction"
        component={FormikField}
        type={FormikTinyMceField}
      />
      <Field
        name="distributionThickness"
        label="Distribution and thickness"
        component={FormikField}
        type={FormikTinyMceField}
      />
      <Field
        name="lithologyDepositional"
        label="Lithology and depositional environments"
        component={FormikField}
        type={FormikTinyMceField}
      />
      <Field
        name="lithologySequence"
        label="Litho- and sequence stratigraphy"
        component={FormikField}
        type={FormikTinyMceField}
      />
      <Field
        name="analogues"
        label="Analogues and notable aspects"
        component={FormikField}
        type={FormikTinyMceField}
      />
      <Field
        name="literatureReferences"
        label="Key references"
        component={FormikField}
        type={FormikTinyMceField}
      />

      <Field
        name="published"
        label="Published"
        component={FormikField}
        type="checkbox"
      />
    </div>
  );
}
