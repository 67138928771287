import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useParams } from 'react-router';
import * as R from 'ramda';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  IssueDetailPageQuery,
  IssueDetailPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { IssueComment } from '~/components/issue/IssueComment';
import { CreateComment } from '~/components/issue/CreateComment';
import { IssueActions } from '~/components/issue/IssueActions';

export const ISSUE_DETAIL_PAGE = gql`
  query IssueDetailPage($issueId: Int) {
    issueList(id: $issueId) {
      ...issueParts
      author {
        ...publicUserParts
        company {
          ...publicCompanyParts
        }
      }
      comments {
        ...issueCommentParts
        author {
          ...publicUserParts
          company {
            ...publicCompanyParts
          }
        }
      }
    }
  }

  ${fragments.issueParts}
  ${fragments.publicUserParts}
  ${fragments.publicCompanyParts}
  ${fragments.issueCommentParts}
`;

export default function IssueDetailRoute() {
  const params = useParams<{ issueId: string }>();
  invariant(params.issueId, 'issueId param required');
  const issueId = parseInt(params.issueId);

  const { data, loading } = useQuery<
    IssueDetailPageQuery,
    IssueDetailPageQueryVariables
  >(ISSUE_DETAIL_PAGE, {
    variables: { issueId },
  });

  const refetchQueries: [PureQueryOptions<IssueDetailPageQueryVariables>] = [
    { query: ISSUE_DETAIL_PAGE, variables: { issueId } },
  ];

  const issueList = data?.issueList ?? [];
  const issue = issueList.find(i => i.id === issueId);

  useBreadcrumb('routes/support/$issueId', issue?.title ?? 'Issue Detail');

  const comments = R.sortBy(R.prop('dateCreated'), issue?.comments ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!issue) return <NotFound />;

  return (
    <>
      <PageHeading hasSubtitle>Issue Detail </PageHeading>
      <PageHeading.Subtitle>{issue.title}</PageHeading.Subtitle>

      <div className="grid lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <IssueComment
            comment={issue}
            refetchQueries={refetchQueries}
            isMainComment
          />

          {comments.map(comment => (
            <IssueComment
              key={comment.id}
              comment={comment}
              refetchQueries={refetchQueries}
            />
          ))}

          <CreateComment issueId={issue.id} refetchQueries={refetchQueries} />
        </div>

        <div>
          <IssueActions issue={issue} />
        </div>
      </div>
    </>
  );
}
