import { useQuery } from '@apollo/client';
import { cn } from '~/utils/common';
import { Field } from 'formik';
import { useState } from 'react';

import { OUTCROP_ADDTL_FIELDS_ENUMS } from '~/apollo/operations/outcrop';
import type { OutcropAddtlFieldsEnumsQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { Panel } from '~/components/common/Panel';
import type {
  KPFields,
  OCAdditionalFieldKey,
  OCAddtlField,
} from '~/utils/modules/outcrop';
import {
  carbonateFields,
  clasticFields,
  structuralFields,
} from '~/utils/modules/outcrop';
import { ucwords } from '~/utils/text';

type GeologyTypeSectionProps = {
  children: JSX.Element;
  geologyType: string;
  enabled: boolean;
  visible: boolean;
  toggleVisibility: () => void;
};
function GeologyTypeSection({
  children,
  geologyType,
  enabled,
  visible,
  toggleVisibility,
}: GeologyTypeSectionProps) {
  if (!enabled) return null;
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>
          <button
            type="button"
            onClick={toggleVisibility}
            className={cn('flex items-center gap-2', {
              cursor: enabled ? 'pointer' : 'cursor-not-allowed',
            })}
          >
            <GeologyTypeIcon
              geologyType={geologyType}
              style={{ height: '30px', width: 'auto', marginRight: '10px' }}
              hideTooltip
            />
            {ucwords(geologyType)}
            <ExpandedIcon expanded={visible} />
          </button>
        </Panel.Title>
      </Panel.Heading>

      {visible && <Panel.Body>{children}</Panel.Body>}
    </Panel>
  );
}

type Props = {
  entity?: 'outcrop' | 'ae';
  geologyTypes: string[];
  keyParameters: KPFields[];
};

export function OutcropAdditionalFields({
  entity = 'outcrop',
  geologyTypes,
  keyParameters,
}: Props) {
  const { data, loading } = useQuery<OutcropAddtlFieldsEnumsQuery>(
    OUTCROP_ADDTL_FIELDS_ENUMS,
    {},
  );

  type VisibleSectionsState = Record<string, boolean>;
  const enabledSections: VisibleSectionsState = {
    clastic: geologyTypes.includes('clastic'),
    carbonate: geologyTypes.includes('carbonate'),
    structural: geologyTypes.includes('structural'),
  };

  const [visibleSections, setVisibleSections] =
    useState<VisibleSectionsState>(enabledSections);

  const toggleSection = (sectionName: string) => () =>
    setVisibleSections(prevState => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));

  const enumOptions = (fieldName: OCAdditionalFieldKey) => {
    const values = data?.[fieldName]?.values ?? [];
    return values.map((opt: string) => ({ value: opt, label: opt }));
  };

  const mapField = ({ name, multiple, label }: OCAddtlField) => (
    <div className="col-span-6" key={name}>
      <Field
        name={name}
        label={label ?? ucwords(name)}
        component={FormikField}
        type="select"
        options={enumOptions(name)}
        multiple={multiple}
        disabled={loading}
      />
    </div>
  );

  return (
    <>
      <GeologyTypeSection
        enabled={enabledSections['clastic']}
        geologyType="clastic"
        visible={visibleSections['clastic']}
        toggleVisibility={toggleSection('clastic')}
      >
        <div className="grid lg:grid-cols-12 gap-6">
          {clasticFields(entity).map(mapField)}
        </div>
      </GeologyTypeSection>

      <GeologyTypeSection
        enabled={enabledSections['carbonate']}
        geologyType="carbonate"
        visible={visibleSections['carbonate']}
        toggleVisibility={toggleSection('carbonate')}
      >
        <div className="grid lg:grid-cols-12 gap-6">
          {carbonateFields().map(mapField)}
        </div>
      </GeologyTypeSection>

      <GeologyTypeSection
        enabled={enabledSections['structural']}
        geologyType="structural"
        visible={visibleSections['structural']}
        toggleVisibility={toggleSection('structural')}
      >
        <div className="grid lg:grid-cols-12 gap-6">
          {structuralFields(keyParameters).map(mapField)}
        </div>
      </GeologyTypeSection>
    </>
  );
}
