import { decamelize } from 'humps';
import * as R from 'ramda';
import React from 'react';
import type {
  KeyParametersPartsFragment,
  OutcropPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { WikiPopover } from '~/components/wiki/WikiPopover';
import type { OCAddtlField } from '~/utils/modules/outcrop';
import {
  carbonateFields,
  clasticFields,
  structuralFields,
} from '~/utils/modules/outcrop';
import { capitalize } from '~/utils/text';
import { KPTitle } from './index';
import { LinkedOtherValue } from '~/components/keyParameter/KeyParametersPanel/LinkedOtherValue';

const defaultFieldLabel = (fieldName: string) =>
  R.pipe(
    () => fieldName,
    value => decamelize(value, { separator: ' ' }),
    R.toLower,
    capitalize,
  )();

export type OCAdditionalFields = Pick<
  OutcropPartsFragment,
  | 'channelMorphology'
  | 'diageneticGeometry'
  | 'diageneticProcess'
  | 'diageneticSetting'
  | 'dominantLithology'
  | 'duneShape'
  | 'faultRocksMembranes'
  | 'interactionNetwork'
  | 'multipleFolds'
  | 'netToGross'
  | 'reactivation1stPhase'
  | 'reactivation2ndPhase'
  | 'riverProfileLocation'
  | 'secondaryStructures'
  | 'shorelineTractory'
  | 'symmetryGeometry'
  | 'synSedimentaryDeformation'
  | 'systemsTract'
  | 'tectonicSetting'
  | 'waterTemperature'
  | 'lateralAggregation'
>;

function shouldRenderField(value?: string | string[] | null) {
  return !R.isNil(value) && value.length > 0;
}

function getValue(outcrop: OCAdditionalFields, name: keyof OCAdditionalFields) {
  return outcrop[name];
}

type AddtlFieldProps = {
  field: OCAddtlField;
  value: string | string[] | null | undefined;
  leftColMd: number;
  rightColMd: number;
};

function AddtlField({ field, value, leftColMd, rightColMd }: AddtlFieldProps) {
  return (
    <KPGrid key={field.name}>
      <KPCol span={leftColMd}>
        <span className="space-x-1">
          <KPTitle name={field.label ?? defaultFieldLabel(field.name)} />
          {field.wikiId && <WikiPopover otherWikiId={field.wikiId} />}
        </span>
      </KPCol>
      <KPCol span={rightColMd}>
        <LinkedOtherValue fieldName={field.name}>
          {Array.isArray(value) ? value.join(', ') : value}
        </LinkedOtherValue>
      </KPCol>
    </KPGrid>
  );
}

type Props = {
  /** The current geology type in view */
  geologyType: string;
  /** Partial outcrop object with the additional fields selected */
  outcrop: OCAdditionalFields;
  keyParameters: KeyParametersPartsFragment[];
  leftColMd: number;
  rightColMd: number;
};

export default function OCAdditionalFieldsDisplay({
  geologyType,
  outcrop,
  keyParameters,
  leftColMd,
  rightColMd,
}: Props) {
  const mapField = (field: OCAddtlField) => {
    const value = getValue(outcrop, field.name);
    if (!shouldRenderField(value)) return null;

    return (
      <AddtlField
        key={field.name}
        field={field}
        value={value}
        leftColMd={leftColMd}
        rightColMd={rightColMd}
      />
    );
  };

  let fields: React.ReactNode[] = [];
  if (geologyType === 'clastic') {
    fields = clasticFields('outcrop')
      .filter(field => field.name !== 'netToGross') // NtG is rendered in the main details area
      .map(mapField);
  } else if (geologyType === 'carbonate') {
    fields = carbonateFields().map(mapField);
  } else if (geologyType === 'structural') {
    fields = structuralFields(keyParameters).map(mapField);
  }

  if (!fields.length || R.all(R.isNil, fields)) {
    return null;
  }

  return (
    <div className="mt-4">
      <Heading level={4} className="mb-2">
        Additional Properties
      </Heading>

      {fields}
    </div>
  );
}
