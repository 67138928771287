import React from 'react';
import type { GeologyWikiPageQuery } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { LastUpdated } from '~/components/common/LastUpdated';
import { Prose } from '~/components/common/Prose';
import { DiagramRenderer } from '~/components/diagram/DiagramRenderer';

type HtmlSectionProps = {
  title?: string;
  content: string | null | undefined;
};
const HtmlSection: React.FC<HtmlSectionProps> = ({ title, content }) => {
  if (!content) return null;

  return (
    <>
      {title && <Heading level={3}>{title}</Heading>}
      <Prose dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};

type Props = {
  wikiPage: GeologyWikiPageQuery['depositionalList'][number];
};

export function GeologyWikiOverview({ wikiPage }: Props) {
  const htmlSections: [string, string | null | undefined][] = [
    ['Description', wikiPage.description],
    ['Diagnostic', wikiPage.diagnostic],
    ['In Subsurface', wikiPage.subsurface],
    ['Reservoir', wikiPage.reservoir],
    ['Modeling', wikiPage.modeling],
    ['Modern Example', wikiPage.modernExample],
    ['References', wikiPage.references],
  ];

  return (
    <div className="space-y-4">
      <HtmlSection content={wikiPage.intro} />
      {htmlSections.map(([title, content]) => (
        <HtmlSection key={title} title={title} content={content} />
      ))}

      {wikiPage.diagram && (
        <>
          <Heading level={3}>Diagram</Heading>
          <DiagramRenderer diagram={wikiPage.diagram} />
        </>
      )}

      <LastUpdated date={wikiPage.updatedAt} />
    </div>
  );
}
