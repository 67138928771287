import { useMutation } from '@apollo/client';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faEnvelope,
  faEnvelopeCircleCheck,
  faPowerOff,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import type {
  SetUserAccountLockedMutation,
  SetUserAccountLockedMutationVariables,
  SetUserCompanyAdminMutation,
  SetUserCompanyAdminMutationVariables,
  SetUserEnabledMutation,
  SetUserEnabledMutationVariables,
  UserPartsFragment,
} from '~/apollo/generated/v3/graphql';
import {
  SET_USER_ACCOUNT_LOCKED,
  SET_USER_COMPANY_ADMIN,
  SET_USER_ENABLED,
} from '~/apollo/operations/user';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LocalDate } from '~/components/common/LocalDate';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { cn } from '~/utils/common';

type User = UserPartsFragment;

export function AdminUserListItem({
  user,
  deleted = false,
}: {
  user: User;
  deleted?: boolean;
}) {
  const tdClassName = user.accountLocked ? 'text-muted' : '';

  const toReadable = (date: string | null) => {
    if (!date) return '--';
    return <LocalDate date={date} />;
  };

  return (
    <tr className={deleted ? 'text-slate-400' : undefined}>
      <td className={tdClassName}>{user.id}</td>
      <td className={tdClassName}>{user.name}</td>
      <td className={tdClassName}>{user.username}</td>
      <td className={cn('text-center', tdClassName)}>
        {toReadable(user.lastActivity)}
      </td>
      <td className={cn('text-center', tdClassName)}>
        {toReadable(user.insertedAt)}
      </td>
      <td className={cn('text-center', tdClassName)}>
        {user.activityCount ?? '--'}
      </td>
      <td className={cn('flex justify-center items-center gap-2', tdClassName)}>
        <FontAwesomeIcon
          icon={user.enabled ? faEnvelopeCircleCheck : faEnvelope}
          className={user.enabled ? 'text-success' : 'text-error'}
        />
        {user.enabled ? 'Verified' : 'No'}
      </td>
      <td className={cn('text-center', tdClassName)}>
        <EnabledIndicator
          value={user.isCompanyAdmin}
          trueText={
            <div className="flex justify-center items-center gap-2">
              <FontAwesomeIcon icon={faUserCircle} className="text-info" />
              Admin
            </div>
          }
          falseText=""
        />
      </td>
      <td className="text-right text-neutral">
        <UserActionsMenu user={user} />
      </td>
    </tr>
  );
}

function UserActionsMenu({ user }: { user: User }) {
  const userId = user.id;

  const [toggleUserEnabled] = useMutation<
    SetUserEnabledMutation,
    SetUserEnabledMutationVariables
  >(SET_USER_ENABLED, {
    variables: { userId, enabled: !user.enabled },
  });
  const [toggleUserAccountLocked] = useMutation<
    SetUserAccountLockedMutation,
    SetUserAccountLockedMutationVariables
  >(SET_USER_ACCOUNT_LOCKED, {
    variables: { userId, accountLocked: !user.accountLocked },
  });
  const [toggleUserCompanyAdmin] = useMutation<
    SetUserCompanyAdminMutation,
    SetUserCompanyAdminMutationVariables
  >(SET_USER_COMPANY_ADMIN, {
    variables: { userId, isCompanyAdmin: !user.isCompanyAdmin },
  });

  const handleClick = (mutation: Function) => async () => {
    try {
      await mutation();
      toast.success('User updated successfully.');
    } catch (err) {
      console.log('Error updating user', err);
      toast.error('There was a problem updating the user.');
    }
  };

  return (
    <DropdownMenu
      label={<FontAwesomeIcon icon={faCog} />}
      color="ghost"
      size="xs"
    >
      <DropdownMenu.Items>
        <UserActionButton
          onClick={handleClick(toggleUserCompanyAdmin)}
          icon={faUserCircle}
        >
          {user.isCompanyAdmin ? 'Revoke' : 'Grant'} Company Admin
        </UserActionButton>

        <UserActionButton
          onClick={handleClick(toggleUserEnabled)}
          icon={faEnvelope}
        >
          {user.enabled ? 'Unverify email' : 'Verify email'}
        </UserActionButton>

        <UserActionButton
          onClick={handleClick(toggleUserAccountLocked)}
          icon={faPowerOff}
        >
          {user.accountLocked ? 'Un-delete User' : 'Delete User'}
        </UserActionButton>
      </DropdownMenu.Items>
    </DropdownMenu>
  );
}

function UserActionButton({
  onClick,
  icon,
  children,
}: {
  onClick: () => Promise<void>;
  icon: IconProp;
  children: ReactNode;
}) {
  return (
    <DropdownMenu.Item>
      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        <div className="flex items-center gap-2 flex-nowrap">
          <FontAwesomeIcon icon={icon} />
          <div>{children}</div>
        </div>
      </button>
    </DropdownMenu.Item>
  );
}
