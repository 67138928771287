import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { Button } from '~/components/ui/button';

import * as fragments from '~/apollo/fragments';
import type {
  UploadReviewCommentListQuery,
  UploadReviewCommentListQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { LocalDate } from '~/components/common/LocalDate';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { DeleteReviewComment } from '~/components/reviewComment/DeleteReviewComment';
import { useSortFilter } from '~/hooks/data';
import * as routes from '~/paths';
import { ucwords } from '~/utils/text';

export const REVIEW_COMMENT_LIST = gql`
  query UploadReviewCommentList {
    reviewCommentList {
      ...reviewCommentParts
      author {
        ...publicUserParts
      }
    }
  }
  ${fragments.reviewCommentParts}
  ${fragments.publicUserParts}
`;

export default function ReviewCommentsPage() {
  useBreadcrumb('routes/upload/review-comments', 'Review Comments');

  const { loading, data } = useQuery<UploadReviewCommentListQuery>(
    REVIEW_COMMENT_LIST,
    {},
  );

  const reviewComments = data?.reviewCommentList ?? [];

  const refetchQueries: [
    PureQueryOptions<UploadReviewCommentListQueryVariables>,
  ] = [{ query: REVIEW_COMMENT_LIST }];

  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    reviewComments,
    'parentName',
    'parentName',
    'uploadReviewCommentList',
  );

  function parentLink(parentType: string, parentId: number): string {
    switch (parentType) {
      case 'study':
        return routes.studyRoute(parentId);
      case 'outcrop':
        return routes.outcropRoute(parentId);
      case 'region':
        return routes.regionRoute(parentId);
      case 'wiki_depositional':
        return routes.geologyWikiPageRoute(parentId);
      case 'wiki_other':
        return routes.otherWikiDetailRoute(parentId);
      case 'lithostrat_formation':
        return routes.lithostratFormationRoute(parentId);
      case 'lithostrat_member':
        return routes.lithostratMemberRoute(parentId);
      case 'lithostrat_group':
        return routes.lithostratGroupRoute(parentId);
      default:
        return '';
    }
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Review Comments Overview</PageHeading>

      <Panel>
        <Panel.Heading>
          <Panel.Title>Comments List</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <FilterSearch {...filterSearchProps} />

          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th className="w-2/12">
                  <SortTrigger
                    colName="parentName"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Name
                  </SortTrigger>
                </th>
                <th className="w-4/12">
                  <SortTrigger
                    colName="comment"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Comment
                  </SortTrigger>
                </th>
                <th className="w-2/12">
                  <SortTrigger
                    colName="author.name"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Author
                  </SortTrigger>
                </th>
                <th className="w-2/12">
                  <SortTrigger
                    colName="insertedAt"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Date Created
                  </SortTrigger>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {items.map(comment => (
                <tr key={comment.id}>
                  <td>
                    <Link
                      to={parentLink(comment.parentType, comment.parentId)}
                      target="_blank"
                    >
                      {comment.parentName || (
                        <i className="text-slate-500">
                          {ucwords(comment.parentType)} {comment.parentId}
                        </i>
                      )}
                    </Link>
                  </td>
                  <td>
                    <div className="break-words" style={{ hyphens: 'auto' }}>
                      {comment.comment}
                    </div>
                  </td>
                  <td>{comment.author.name}</td>
                  <td>
                    <LocalDate date={comment.insertedAt} />
                  </td>
                  <td className="text-right">
                    <DeleteReviewComment
                      commentId={comment.id}
                      refetchQueries={refetchQueries}
                    >
                      {(confirmDelete, loading) => (
                        <Button
                          type="button"
                          color="ghost"
                          size="xs"
                          onClick={confirmDelete}
                          loading={loading}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      )}
                    </DeleteReviewComment>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
