import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import type {
  CreateRegionMutation,
  CreateRegionMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { RegionFormFields } from '~/components/upload/region/RegionFormFields';
import { uploadRegionUpdateRoute } from '~/paths';
import type { RegionFormValues } from '~/utils/modules/region';
import { initialRegion, regionValidationSchema } from '~/utils/modules/region';

const CREATE_REGION = gql`
  mutation CreateRegion($region: RegionInput!) {
    createRegion(region: $region) {
      id
    }
  }
`;

const UploadRegionCreatePage: React.FC = () => {
  useBreadcrumb('routes/upload/model.region/create', 'Create Region');
  const navigate = useNavigate();
  const [createRegion, { loading, error }] = useMutation<
    CreateRegionMutation,
    CreateRegionMutationVariables
  >(CREATE_REGION, {});

  async function handleSubmit(values: RegionFormValues) {
    try {
      const res = await createRegion({ variables: { region: values } });
      const regionId = res.data?.createRegion.id;
      if (!regionId) throw new Error('Error parsing createRegion response');
      navigate(uploadRegionUpdateRoute(regionId));
      toast.success('Region created successfully.');
    } catch (err) {
      toast.error('There was a problem creating the region.');
      console.log('Error creating region:', err);
    }
  }

  return (
    <>
      <PageHeading>Create Region</PageHeading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialRegion()}
        validationSchema={regionValidationSchema}
      >
        <Form>
          <Panel>
            <Panel.Heading>
              <Panel.Title>Region Details</Panel.Title>
            </Panel.Heading>
            <Panel.Body className="space-y-4">
              <RegionFormFields />
              <FormErrors graphQLError={error} />
            </Panel.Body>

            <Panel.Footer className="text-right">
              <Button type="submit" color="primary" loading={loading}>
                Create
              </Button>
            </Panel.Footer>
          </Panel>
        </Form>
      </Formik>
    </>
  );
};

export default UploadRegionCreatePage;
