import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';

import type {
  DeleteStudyAccessMutation,
  DeleteStudyAccessMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Tooltip } from '~/components/common/Tooltip';

export const DELETE_STUDY_ACCESS = gql`
  mutation DeleteStudyAccess($id: Int!) {
    deleteStudyAccess(id: $id)
  }
`;

type Props = {
  studyAccessId: number;
  companyName: string;
  refetchQueries: PureQueryOptions[];
};

export function StudyAccessItem({
  studyAccessId,
  companyName,
  refetchQueries,
}: Props) {
  const [deleteStudyAccess, { loading }] = useMutation<
    DeleteStudyAccessMutation,
    DeleteStudyAccessMutationVariables
  >(DELETE_STUDY_ACCESS, {
    variables: { id: studyAccessId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteStudyAccess();
      toast.success('Study access revoked.');
    } catch (err) {
      console.log('Error deleting study access', err);
      toast.error('There was a problem revoking the study access.');
    }
  }

  return (
    <div className="border border-slate-100 p-2 flex justify-between items-center">
      <div>{companyName}</div>
      <Confirm
        onConfirm={handleDelete}
        text={`Study access to ${companyName} will be revoked.`}
      >
        {showConfirm => (
          <Tooltip message={`Revoke access from ${companyName}`}>
            <Button
              type="button"
              color="ghost"
              size="xs"
              onClick={showConfirm}
              loading={loading}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>
        )}
      </Confirm>
    </div>
  );
}
