import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { Outlet, useOutletContext, useParams } from 'react-router';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  UploadLtUpdatePageQuery,
  UploadLtUpdatePageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import * as routes from '~/paths';

const UPLOAD_LT_UPDATE_PAGE = gql`
  query UploadLTUpdatePage($id: Int!) {
    lithostratTypeList(id: $id) {
      ...lithostratTypeParts
      diagram {
        ...diagramParts
        file {
          ...fileParts
        }
        diagramAreas {
          ...diagramAreaParts
        }
      }
    }
  }

  ${fragments.lithostratTypeParts}
  ${fragments.diagramParts}
  ${fragments.diagramAreaParts}
  ${fragments.fileParts}
`;

type OutletContext = {
  lithostratType: UploadLtUpdatePageQuery['lithostratTypeList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function UploadLithostratTypeUpdateRoute() {
  const params = useParams<{ lithostratTypeId: string }>();
  invariant(params.lithostratTypeId, 'lithostratTypeId param required');
  const ltId = parseInt(params.lithostratTypeId);

  const { data, loading } = useQuery<
    UploadLtUpdatePageQuery,
    UploadLtUpdatePageQueryVariables
  >(UPLOAD_LT_UPDATE_PAGE, {
    variables: { id: ltId },
  });

  const refetchQueries: [PureQueryOptions<UploadLtUpdatePageQueryVariables>] = [
    {
      query: UPLOAD_LT_UPDATE_PAGE,
      variables: { id: ltId },
    },
  ];

  const lt = data?.lithostratTypeList.find(lt => lt.id === ltId);

  useBreadcrumb(
    'routes/upload/lithostrat-type/$lithostratTypeId',
    lt?.name ?? 'Update Lithostrat Type',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!lt) return <NotFound />;

  const outletContext: OutletContext = {
    lithostratType: lt,
    refetchQueries,
  };

  return (
    <>
      <PageHeading hasSubtitle={!!lt}>Update Lithostrat type</PageHeading>
      {lt && <PageHeading.Subtitle>{lt.name}</PageHeading.Subtitle>}

      <NavTabs className="mb-2">
        <NavTabs.Tab to={routes.uploadLithostratTypeUpdateRoute(lt.id)} end>
          Details
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratTypeUpdateDiagramTabRoute(lt.id)}
        >
          Diagram
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratTypeUpdateEntitiesTabRoute(lt.id)}
        >
          Entities
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />
    </>
  );
}

export function useLithostratTypeUpdateOutletContext() {
  return useOutletContext<OutletContext>();
}
