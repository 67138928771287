import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import type { PagePartsFragment } from '~/apollo/generated/v3/graphql';
import { Role } from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { Heading } from '~/components/common/Heading';
import { Prose } from '~/components/common/Prose';
import { adminPageUpdateRoute } from '~/paths';

type Props = {
  page?: PagePartsFragment;
  /** Alias for 'page' prop, used for backward-compat */
  pageData?: PagePartsFragment;
  showTitle?: boolean;
};

/** Render a loaded Page type with HTML content */
export function Page({ page, pageData, showTitle = true }: Props) {
  const pageInfo = page || pageData;
  if (!pageInfo) return null;

  return (
    <div id={pageInfo.pageName}>
      {showTitle && (
        <Heading level={1} className="flex-grow">
          {pageInfo.title}
        </Heading>
      )}

      <Prose dangerouslySetInnerHTML={{ __html: pageInfo.content }} />

      <RoleSecured roles={[Role.RoleAdmin]}>
        <div className="mt-2">
          <Link
            to={adminPageUpdateRoute(pageInfo.id)}
            className="text-slate-400 text-xs hover:underline"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit {page?.pageName}
          </Link>
        </div>
      </RoleSecured>
    </div>
  );
}
