import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_FACIES,
  DELETE_FACIES,
  UPDATE_FACIES,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropUpdateFaciesTabQuery,
  UploadOutcropUpdateFaciesTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const UPLOAD_OUTCROP_UPDATE_FACIES_TAB = gql`
  query UploadOutcropUpdateFaciesTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      facies {
        ...faciesParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.faciesParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateOutcropFaciesRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateFaciesTabQuery,
    UploadOutcropUpdateFaciesTabQueryVariables
  >(UPLOAD_OUTCROP_UPDATE_FACIES_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateFaciesTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_UPDATE_FACIES_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const facies = outcrop?.facies ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <>
      <SupportObjectManager
        outcropId={outcropId}
        supportObject={facies}
        supportObjectType="facies"
        parentType="FACIES"
        createMutation={CREATE_FACIES}
        updateMutation={UPDATE_FACIES}
        deleteMutation={DELETE_FACIES}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
