import { graphql } from '~/apollo/generated/v3';

export const STUDY_ENUMERATIONS = graphql(`
  query StudyEnumerations {
    studyType: studyEnumerations(enumType: TYPE) {
      values
    }
    qpSpatialObservationType: studyQualityParameterEnumerations(
      enumType: SPATIAL_OBSERVATION_TYPE
    ) {
      values
    }
    qpDataAcquisitionMethod: studyQualityParameterEnumerations(
      enumType: DATA_ACQUISITION_METHOD
    ) {
      values
    }
    qpPublicationType: studyQualityParameterEnumerations(
      enumType: PUBLICATION_TYPE
    ) {
      values
    }
  }
`);
