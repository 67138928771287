import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React from 'react';
import type { WikiPopoverProps } from '~/components/wiki/WikiPopover';
import { cn } from '~/utils/common';
import type { FormLabelProps } from './FormLabel';
import { FormLabel } from './FormLabel';

export type Props = FieldProps & {
  helpText?: string;
  helpTextPlacement?: FormLabelProps['helpTextPlacement'];
  className?: string;
  isInvalid?: boolean;
  showSpin?: boolean;
  label?: React.ReactNode;
  renderInput?: (input: JSX.Element) => JSX.Element;
  required?: boolean;
  wikiPopoverId?: number;
  wikiPopoverProps?: WikiPopoverProps;
};

export const FormikNumberField: React.FC<Props> = ({
  field,
  form,
  className,
  isInvalid = false,
  label,
  renderInput,
  helpText,
  helpTextPlacement,
  showSpin,
  required = false,
  wikiPopoverId,
  wikiPopoverProps,
  ...props
}: Props) => {
  const input = (
    <input
      {...field}
      {...props}
      id={field.name}
      type="number"
      className={cn(
        'input w-full',
        {
          'border-error border-2': isInvalid,
          'show-spin': showSpin,
        },
        className,
      )}
    />
  );

  return (
    <div className="form-control">
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        helpTextPlacement={helpTextPlacement}
        required={required}
        wikiPopoverId={wikiPopoverId}
        wikiPopoverProps={wikiPopoverProps}
      />
      {renderInput ? renderInput(input) : input}
      {isInvalid && (
        <label className="label">
          <span className="text-error">
            <ErrorMessage name={field.name} />
          </span>
        </label>
      )}
    </div>
  );
};
