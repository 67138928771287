import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { otherWikiParts } from '~/apollo/fragments';
import type {
  OtherWikiUpdatePageQuery,
  UpdateOtherWikiMutation,
  UpdateOtherWikiMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { OtherWikiFormFields } from '~/components/upload/other/OtherWikiFormFields';
import type { OtherWikiFormValues } from '~/utils/modules/wiki';
import {
  initialOtherWiki,
  otherWikiValidationSchema,
} from '~/utils/modules/wiki';

export const UPDATE_OTHER_WIKI = gql`
  mutation UpdateOtherWiki($id: Int!, $other: OtherWikiInput!) {
    updateOtherWiki(id: $id, other: $other) {
      ...otherWikiParts
    }
  }

  ${otherWikiParts}
`;

type Props = {
  wikiPageId: number;
  wikiPage: OtherWikiUpdatePageQuery['otherList'][number];
};

export function UpdateOtherWikiForm({ wikiPageId, wikiPage }: Props) {
  const [updateOtherWiki, { loading, error }] = useMutation<
    UpdateOtherWikiMutation,
    UpdateOtherWikiMutationVariables
  >(UPDATE_OTHER_WIKI, {});

  async function handleSubmit(values: OtherWikiFormValues) {
    try {
      await updateOtherWiki({
        variables: {
          id: wikiPageId,
          other: values,
        },
      });
      toast.success('Wiki page updated successfully.');
    } catch (err) {
      console.log('Error updating wiki page', err);
      toast.error(
        'There was a problem updating the wiki page. Please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialOtherWiki(wikiPage)}
      validationSchema={otherWikiValidationSchema()}
      enableReinitialize
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Update Wiki Page</Panel.Title>
          </Panel.Heading>

          <Panel.Body className="space-y-4">
            <OtherWikiFormFields />
            <FormErrors graphQLError={error} />
          </Panel.Body>

          <Panel.Footer className="text-right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
