import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { StudyIndexRouteQuery } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';

type Props = {
  literature: StudyIndexRouteQuery['studyList'][number]['literatureReferences'][number];
};

export function StudyLiteraturePanel({ literature }: Props) {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>{literature.title}</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <table className="table table-compact w-full">
          <tbody>
            <tr>
              <th>Author</th>
              <td>{literature.author}</td>
            </tr>
            <tr>
              <th>Year</th>
              <td>{literature.year}</td>
            </tr>
            <tr>
              <th>Source</th>
              <td>{literature.publicationSource}</td>
            </tr>
          </tbody>
        </table>
      </Panel.Body>

      {literature.files && (
        <Panel.Footer className="space-y-2">
          {literature.files.map(file => (
            <a
              key={file.id}
              href={file.signedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-ghost btn-sm btn-block text-primary h-auto p-2 justify-start"
            >
              <div className="flex items-center gap-4">
                <div>
                  <FontAwesomeIcon icon={faDownload} />
                </div>
                <div className="break-words text-left">{file.name}</div>
              </div>
            </a>
          ))}
        </Panel.Footer>
      )}
    </Panel>
  );
}
