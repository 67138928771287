import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import * as R from 'ramda';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type { UserCompanyAdminsPageQuery } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';

const USER_COMPANY_ADMINS_PAGE = gql`
  query UserCompanyAdminsPage {
    currentAuthority {
      user {
        ...authorityUserParts
        company {
          ...companyParts
          admins {
            ...companyAdminUserParts
          }
        }
      }
    }
  }
  ${fragments.authorityUserParts}
  ${fragments.companyParts}
  ${fragments.companyAdminUserParts}
`;

export default function ProfileCompanyAdminsRoute() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');

  const { data, loading } = useQuery<UserCompanyAdminsPageQuery>(
    USER_COMPANY_ADMINS_PAGE,
    {},
  );

  if (loading) return <SpinnerPlaceholder />;

  const admins = R.sortBy(
    R.prop('name'),
    data?.currentAuthority.user.company.admins ?? [],
  );

  return (
    <>
      <Heading level={3}>My Company Admins</Heading>
      <p>The following users are Safari administrators for your company:</p>

      <table className="table table-compact w-full mt-4 border">
        <thead className="border">
          <tr>
            <th>Name</th>
            <th>E-mail</th>
          </tr>
        </thead>

        <tbody>
          {admins.map(user => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>
                <a
                  href={`mailto:${user.username}`}
                  title="Send E-mail"
                  className="link"
                >
                  {user.username}
                </a>
              </td>
            </tr>
          ))}
          <NoItemsRow show={!admins.length} colSpan={2} />
        </tbody>
      </table>
    </>
  );
}
