import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { georeferenceParts } from '~/apollo/fragments';
import {
  GeoreferenceDataType,
  GeoreferenceParent,
  type UploadRegionUpdateGeoreferencesTabQuery,
  type UploadRegionUpdateGeoreferencesTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GeoreferenceManager } from '~/components/upload/georeference/GeoreferenceManager';
import { useUploadRegionPageContext } from '~/routes/upload/model/region/$regionId';

const UPLOAD_REGION_UPDATE_GEOREFERENCES_TAB = gql`
  query UploadRegionUpdateGeoreferencesTab($regionId: Int!) {
    regionList(id: $regionId) {
      id
      georeferences {
        ...georeferenceParts
      }
    }
  }

  ${georeferenceParts}
`;

export default function UpdateRegionGeoreferencesRoute() {
  const ctx = useUploadRegionPageContext();
  const regionId = ctx.region.id;

  const { data, loading } = useQuery<
    UploadRegionUpdateGeoreferencesTabQuery,
    UploadRegionUpdateGeoreferencesTabQueryVariables
  >(UPLOAD_REGION_UPDATE_GEOREFERENCES_TAB, {
    variables: { regionId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadRegionUpdateGeoreferencesTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_REGION_UPDATE_GEOREFERENCES_TAB,
      variables: { regionId },
    },
  ];

  const regionList = data?.regionList ?? [];
  const region = regionList.find(r => r.id === regionId);
  const georeferences = region?.georeferences ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!region) return <NotFound />;

  return (
    <GeoreferenceManager
      georeferences={georeferences}
      dataTypes={[
        GeoreferenceDataType.Centre,
        GeoreferenceDataType.Outline,
        GeoreferenceDataType.Polygon,
        GeoreferenceDataType.Polyline,
        GeoreferenceDataType.Point,
      ]}
      limits={{ OUTLINE: 1, CENTRE: 1 }}
      parentType={GeoreferenceParent.Region}
      parentId={region.id}
      refetchQueries={refetchQueries}
    />
  );
}
