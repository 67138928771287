import type { ApolloError } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormikErrors, FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect } from 'react';
import { cn } from '~/utils/common';
import { setValidationErrors } from '~/utils/formik';
import { ucwords } from '~/utils/text';
import { Heading } from './Heading';

/** Recursively walk the errors object until an array of messages is found.
 *  The last key will be used for the field name.*/
export function formatErrors(
  errors: FormikErrors<FormikValues>,
): React.ReactNode {
  return Object.keys(errors).map(key => {
    const fieldErrors = errors[key as keyof typeof errors];
    const fieldName = ucwords(key);

    if (Array.isArray(fieldErrors)) {
      return (
        <li key={key}>
          <b>{fieldName}</b>: {fieldErrors.join(', ')}
        </li>
      );
    } else if (typeof fieldErrors === 'string') {
      return (
        <li key={key}>
          <b>{fieldName}</b>: {fieldErrors}
        </li>
      );
    } else if (typeof fieldErrors === 'object') {
      return formatErrors(fieldErrors);
    }

    return null;
  });
}

type Props = {
  graphQLError?: ApolloError | null;
  className?: string;
};

export function FormErrors({ graphQLError, className }: Props) {
  const formik = useFormikContext<any>();
  const { setErrors } = formik;

  const hasBeenSubmitted = formik.submitCount > 0;
  const hasValidationErrors = Object.keys(formik.errors).length > 0;

  useEffect(() => {
    if (graphQLError) {
      // Try to set validation errors inside Formik if it finds any
      setValidationErrors(graphQLError, { setErrors });
    }
  }, [graphQLError, setErrors]);

  // console.log('Checking GraphQL Error:',JSON.parse(JSON.stringify(graphQLError ?? {})));
  // Apollo Server 4 path to the thrown exception
  const gqlErrorPath = ['graphQLErrors', 0, 'extensions', 'originalError'];
  const gqlError = R.path(gqlErrorPath, graphQLError);

  const errorMessage = hasValidationErrors
    ? 'Please check the data submitted and try again.'
    : mapServerErrors(gqlError);

  if (!hasBeenSubmitted) {
    return null;
  }

  if (hasValidationErrors) {
    return (
      <FormikFormErrors
        message={errorMessage}
        validationErrors={formik.errors}
        className={className}
      />
    );
  }

  if (errorMessage) {
    return <FormikFormErrors message={errorMessage} className={className} />;
  }

  return null;
}

function FormikFormErrors({
  message,
  validationErrors,
  className,
}: {
  message?: string | null | undefined;
  validationErrors?: FormikErrors<FormikValues>;
  className?: string | null;
}) {
  return (
    <div
      className={cn(
        'border-2 border-error bg-red-50 p-6 flex gap-6 items-center rounded-md text-red-900',
        className,
      )}
    >
      <div>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-5xl text-error"
        />
      </div>

      <div>
        <Heading level={3} className="font-bold">
          There was a problem submitting the form
        </Heading>

        <div className="font-bold">
          {message ?? 'Something went wrong, please try again.'}
        </div>

        {validationErrors && (
          <ul className="list-disc list-outside pl-4">
            {formatErrors(validationErrors)}
          </ul>
        )}
      </div>
    </div>
  );
}

function mapServerErrors(message: unknown) {
  if (typeof message !== 'string') return null;

  switch (message) {
    // There are many custom error messages on the server, map them to a nice message here
    default:
      return 'Something went wrong, please try again.';
  }
}
