import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import {
  basinParts,
  keyParametersParts,
  lithostratAgeParts,
  outcropParts,
  outcropQualityParametersParts,
} from '~/apollo/fragments';
import type {
  UploadOutcropKeyParametersTabQuery,
  UploadOutcropKeyParametersTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { KeyParametersParent } from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersUpload } from '~/components/upload/keyParameter/KeyParametersUpload';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const UPLOAD_OUTCROP_KEY_PARAMETERS_TAB = gql`
  query UploadOutcropKeyParametersTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      basin {
        ...basinParts
      }
      qualityParameters {
        ...outcropQualityParametersParts
      }
      keyParameters {
        ...keyParametersParts
      }
    }
  }
  ${outcropParts}
  ${lithostratAgeParts}
  ${basinParts}
  ${outcropQualityParametersParts}
  ${keyParametersParts}
`;

export default function UpdateOutcropKeyParametersRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropKeyParametersTabQuery,
    UploadOutcropKeyParametersTabQueryVariables
  >(UPLOAD_OUTCROP_KEY_PARAMETERS_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropKeyParametersTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_KEY_PARAMETERS_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <>
      <Panel>
        <Panel.Heading>
          <Panel.Title>Outcrop Key Parameters</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <KeyParametersUpload
            parentType={KeyParametersParent.Outcrop}
            parentId={outcrop.id}
            geologyTypes={outcrop.geologyType}
            keyParameters={outcrop.keyParameters}
            refetchQueries={refetchQueries}
          />
        </Panel.Body>
      </Panel>
    </>
  );
}
