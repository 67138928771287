import { gql } from '~/apollo/client-v3';
import { useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  InterpretationPartsFragment,
  InterpretationStudyIdsQuery,
  InterpretationStudyIdsQueryVariables,
  UpdateInterpretationMutation,
  UpdateInterpretationMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { InterpretationFormValues } from '~/utils/modules/vom';
import {
  interpretationInitialValues,
  toInterpretationInput,
} from '~/utils/modules/vom';

const INTERPRETATION_STUDY_IDS = gql`
  query InterpretationStudyIds($id: Int!) {
    virtualOutcropModelList(id: $id) {
      id
      outcrop {
        id
        studies {
          ...studyParts
        }
      }
    }
  }

  ${fragments.studyParts}
`;

const UPDATE_INTERPRETATION = gql`
  mutation UpdateInterpretation(
    $interpretationId: Int!
    $interpretation: InterpretationInput!
  ) {
    updateVirtualOutcropInterpretation(
      interpretationId: $interpretationId
      interpretation: $interpretation
    ) {
      ...interpretationParts
      study {
        ...studyParts
      }
    }
  }

  ${fragments.interpretationParts}
  ${fragments.studyParts}
`;

type Props = {
  interpretation: InterpretationPartsFragment;
  children: (showModal: () => void) => JSX.Element;
};

export function UpdateInterpretationModal({ interpretation, children }: Props) {
  const vomId = interpretation.virtualOutcropModelId;

  const { show, showModal, hideModal } = useModalState();

  const { data: dataVomList, loading: loadingVomList } = useQuery<
    InterpretationStudyIdsQuery,
    InterpretationStudyIdsQueryVariables
  >(INTERPRETATION_STUDY_IDS, {
    variables: { id: vomId },
  });
  const vomList = dataVomList?.virtualOutcropModelList ?? [];
  const vom = vomList.find(vom => vom.id === vomId);
  const studies = vom?.outcrop?.studies ?? [];

  const [updateInterpretation, { loading: loadingUpdate, error }] = useMutation<
    UpdateInterpretationMutation,
    UpdateInterpretationMutationVariables
  >(UPDATE_INTERPRETATION, {});

  const handleSubmit = async (values: InterpretationFormValues) => {
    const input = toInterpretationInput(values);

    try {
      await updateInterpretation({
        variables: {
          interpretationId: interpretation.id,
          interpretation: input,
        },
      });
      toast.success('Interpretation updated successfully.');
      hideModal();
    } catch (err) {
      console.log('Error saving interpretation', err);
      toast.error('There was a problem saving the interpretation.');
    }
  };

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={interpretationInitialValues(interpretation)}
        >
          <Form>
            <Modal.Body
              heading={`Edit Interpretation ${interpretation.id}`}
              className="space-y-4"
            >
              <Field
                name="studyId"
                label="Study"
                component={FormikField}
                type="select"
                options={[
                  { value: null, label: 'No study linked' },
                  ...studies?.map(s => ({
                    value: s.id,
                    label: `${s.name} (${s.id})`,
                  })),
                ]}
                disabled={loadingVomList}
              />

              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary"
                loading={loadingUpdate}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
