import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '~/components/ui/alert';
import { Link } from 'react-router';
import { ReportState } from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { ReportEditorAddItem } from '~/components/report/ReportEditor/AddItem';
import { editReportRoute } from '~/paths';
import { useEditReportOutletContext } from '~/routes/my-safari/reports/$reportId';

export default function EditReportAddItemPage() {
  const { report, refetchQueries } = useEditReportOutletContext();

  if (report.state !== ReportState.Draft) {
    return (
      <Alert status="warning">
        <FontAwesomeIcon icon={faInfoCircle} />
        <div>
          This report is currently published and cannot have items added to it.
          <p>
            <Link to={editReportRoute(report.id)} className="link">
              <FontAwesomeIcon icon={faChevronLeft} /> Back to Report
            </Link>
          </p>
        </div>
      </Alert>
    );
  }

  return (
    <>
      <div className="space-y-4">
        <div className="space-y-1">
          <Link
            to={editReportRoute(report.id)}
            className="btn btn-default btn-sm"
          >
            <span className="space-x-2">
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="font-light">Back to</span>
              <span className="font-bold">{report.title}</span>
            </span>
          </Link>

          <PageHeading className="mt-0">Add an item</PageHeading>
        </div>

        <ReportEditorAddItem
          report={report}
          isCompany={!!report.companyId}
          refetchQueries={refetchQueries}
        />
      </div>
    </>
  );
}
