import {
  type InterpretationInput,
  type InterpretationPartsFragment,
  type VirtualOutcropModelInput,
  type VomPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type VomFormValues = {
  vom: {
    name: string;
    projectId: string;
    outcropId: string;
    size: string;
    smallestVisibleFeature: string;
    approved: boolean;
    climate: string;
    basinType: string;
    quality: string;
    notableAspects: string;
    acquisitionYear: string;
    aquiringPerson: string;
    acquisitionType: string;
    acquisitionEquipment: string;
    processingYear: string;
    processingPerson: string;
    copyright: string[];
    copyrightLogo: string[];
    comments: string;
    v3GeoId: string;
  };
};

export function initialVom(vom?: VomPartsFragment): VomFormValues {
  return {
    vom: {
      name: vom?.name ?? '',
      projectId: `${vom?.projectId ?? ''}`,
      outcropId: `${vom?.outcropId ?? ''}`,
      size: vom?.size ?? '',
      smallestVisibleFeature: vom?.smallestVisibleFeature ?? '',
      approved: vom?.approved ?? false,
      climate: vom?.climate ?? '',
      basinType: vom?.basinType ?? '',
      quality: vom?.quality ?? '',
      notableAspects: vom?.notableAspects ?? '',
      acquisitionYear: `${vom?.acquisitionYear ?? ''}`,
      aquiringPerson: vom?.aquiringPerson ?? '',
      acquisitionType: vom?.acquisitionType ?? '',
      acquisitionEquipment: vom?.acquisitionEquipment ?? '',
      processingYear: `${vom?.processingYear ?? ''}`,
      processingPerson: vom?.processingPerson ?? '',
      copyright: vom?.copyright ?? [],
      copyrightLogo: vom?.copyrightLogo ?? [],
      comments: vom?.comments ?? '',
      v3GeoId: `${vom?.v3GeoId ?? ''}`,
    },
  };
}

export const toVomInput = (values: VomFormValues): VirtualOutcropModelInput => {
  const intOrNull = (value: string | number) => {
    if (typeof value === 'number') return value;
    if (value.length) return parseInt(value);
    return null;
  };

  const input: VirtualOutcropModelInput = {
    name: values.vom.name,
    projectId: parseInt(values.vom.projectId),
    outcropId: parseInt(values.vom.outcropId),
    size: values.vom.size,
    smallestVisibleFeature: values.vom.smallestVisibleFeature,
    approved: values.vom.approved,
    climate: values.vom.climate,
    basinType: values.vom.basinType,
    quality: values.vom.quality,
    notableAspects: values.vom.notableAspects,
    acquisitionYear: parseInt(values.vom.acquisitionYear),
    aquiringPerson: values.vom.aquiringPerson,
    acquisitionType: values.vom.acquisitionType,
    acquisitionEquipment: values.vom.acquisitionEquipment,
    processingYear: parseInt(values.vom.processingYear),
    processingPerson: values.vom.processingPerson,
    copyright: values.vom.copyright,
    copyrightLogo: values.vom.copyrightLogo,
    comments: values.vom.comments,
    v3GeoId: intOrNull(values.vom.v3GeoId),
  };

  return input;
};

export const validationSchema = yup.object({
  vom: yup.object({
    // Required
    name: yup.string().label('name').required(),
    projectId: yup.number().label('project').required(),
    outcropId: yup.number().label('outcrop').required(),
    size: yup.string().label('size').required(),
    smallestVisibleFeature: yup
      .string()
      .label('smallest visible feature')
      .required(),
    acquisitionYear: yup.number().label('acquisition year').required(),
    aquiringPerson: yup.string().label('aquiring person').required(),
    acquisitionType: yup.string().label('acquisition type').required(),
    acquisitionEquipment: yup
      .string()
      .label('acquisition equipment')
      .required(),
    processingYear: yup.number().label('processing year').required(),
    processingPerson: yup.string().label('processing person').required(),
    copyright: yup.array(yup.string()).label('copyright').required(),

    // Nullable
    approved: yup.boolean().label('approved').nullable(),
    climate: yup.string().label('climate').nullable(),
    basinType: yup.string().label('basin type').nullable(),
    quality: yup.string().label('quality').nullable(),
    notableAspects: yup.string().label('notable aspects').nullable(),
    copyrightLogo: yup.array(yup.string()).label('copyright logo').nullable(),
    v3GeoId: yup.number().label('V3Geo model ID').nullable(),
  }),
});

export type VomInterpretationState = 'published' | 'unpublished' | 'none';

export type InterpretationFormValues = {
  studyId: number | string | null;
};

export const interpretationInitialValues = (
  i?: InterpretationPartsFragment,
): InterpretationFormValues => {
  return {
    studyId: i?.studyId ?? null,
  };
};

export const toInterpretationInput = (
  values: InterpretationFormValues,
): InterpretationInput => {
  const input: InterpretationInput = { studyId: null };

  if (values.studyId) {
    if (typeof values.studyId === 'string') {
      input.studyId = parseInt(values.studyId);
    } else {
      input.studyId = values.studyId;
    }
  }

  return input;
};

export function isMini(vom: Pick<VomPartsFragment, 'acquisitionType'>) {
  return vom.acquisitionType === 'MLS - Mobile phone-based laser scanning';
}
