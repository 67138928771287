import { Link, useMatches } from 'react-router';
import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { rootRoute } from '~/paths';

export type BreadcrumbItem = {
  routeId: string;
  pathname: string;
  title: React.ReactNode;
  prepended?: BreadcrumbItem[];
};

type BreadcrumbContextValue = {
  items: BreadcrumbItem[];
  addBreadcrumb: (item: BreadcrumbItem) => void;
  removeBreadcrumb: (routeId: string) => void;
  title: string;
};

export const BreadcrumbContext = createContext<BreadcrumbContextValue>({
  items: [],
  addBreadcrumb: () => {},
  removeBreadcrumb: () => {},
  title: 'Safari',
});

type BreadcrumbProviderProps = {
  children: React.ReactNode;
};

export function BreadcrumbProvider({ children }: BreadcrumbProviderProps) {
  const matches = useMatches();
  const [items, setItems] = useState<BreadcrumbItem[]>([]);

  const addBreadcrumb = useCallback((item: BreadcrumbItem) => {
    setItems(prevItems => {
      // Route IDs should be unique. The order here doesn't matter, they'll
      // be reordered below
      const exists = prevItems.find(prv => prv.routeId === item.routeId);
      if (exists) return prevItems;
      return [...prevItems, item];
    });
  }, []);

  const removeBreadcrumb = useCallback((routeId: string) => {
    setItems(prevItems => prevItems.filter(item => item.routeId !== routeId));
  }, []);

  // Reduces the breadcrumbs over the route matches so they are ordered correctly
  const breadcrumbItems = useMemo(
    () =>
      matches.reduce<BreadcrumbItem[]>((acc, cur) => {
        const breadcrumb = items.find(item => item.routeId === cur.id);
        if (!breadcrumb) return acc;
        const prependedItems = breadcrumb.prepended ?? [];
        return [...acc, ...prependedItems, breadcrumb];
      }, []),
    [items, matches],
  );

  const activeItem = breadcrumbItems[breadcrumbItems.length - 1];
  const title = activeItem ? `${activeItem.title} | Safari` : 'Safari';

  return (
    <BreadcrumbContext.Provider
      value={{
        items: breadcrumbItems,
        addBreadcrumb,
        removeBreadcrumb,
        title,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
}

export function useBreadcrumb(
  routeId: string,
  title: React.ReactNode,
  pathnameOverride?: string | null,
  prependedItems?: BreadcrumbItem[] | null,
) {
  const matches = useMatches();
  const { addBreadcrumb, removeBreadcrumb } = useContext(BreadcrumbContext);

  const pathname = matches.find(m => m.id === routeId)?.pathname ?? '';

  useEffect(() => {
    addBreadcrumb({
      routeId,
      pathname: pathnameOverride ?? pathname,
      title,
      prepended: prependedItems ?? undefined,
    });

    return () => {
      removeBreadcrumb(routeId);
    };
  }, [
    routeId,
    title,
    pathname,
    pathnameOverride,
    addBreadcrumb,
    removeBreadcrumb,
    // Causes infinite render loop. I don't care about the effect firing here;
    // if this actually needs to trigger a rerender figure out how to diff this.
    // prependedItems,
  ]);
}

export function Breadcrumb() {
  const { items } = useContext(BreadcrumbContext);

  if (!items.length) return null;

  // Prepend 'home' route
  const breadcrumbs: BreadcrumbItem[] = [
    { routeId: 'routes/index', pathname: rootRoute(), title: 'Home' },
    ...items,
  ];

  const isLast = (index: number) => index === breadcrumbs.length - 1;

  return (
    <div className="bg-slate-100 px-4 py-2 rounded-b text-base">
      {breadcrumbs.map((item, i) => (
        <Fragment key={i}>
          {isLast(i) ? (
            <span className="text-slate-600 cursor-default">{item.title}</span>
          ) : (
            <Link to={item.pathname} className="link">
              {item.title}
            </Link>
          )}
          {!isLast(i) && <span className="text-slate-500 mx-2">/</span>}
        </Fragment>
      ))}
    </div>
  );
}
