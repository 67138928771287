import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import type {
  DeleteDiagramMutation,
  DeleteDiagramMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';

export const DELETE_DIAGRAM = gql`
  mutation DeleteDiagram($diagramId: Int!) {
    deleteDiagram(id: $diagramId)
  }
`;

type Props = {
  diagramId: number;
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function DeleteDiagram({ diagramId, refetchQueries, children }: Props) {
  const [deleteDiagram] = useMutation<
    DeleteDiagramMutation,
    DeleteDiagramMutationVariables
  >(DELETE_DIAGRAM, {
    variables: { diagramId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteDiagram();
      toast.success('Diagram deleted successfully.');
    } catch (err) {
      toast.error('There was a problem deleting the diagram.');
      console.log('Error deleting diagram', err);
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="The selected image and all of its areas will be permanently deleted."
    >
      {onConfirm => children(onConfirm)}
    </Confirm>
  );
}
