import { useNavigate } from 'react-router';
import { cn } from '~/utils/common';
import React, { useCallback, useState } from 'react';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';

type Props = {
  children?: React.ReactNode;
  label: React.ReactNode;
  href?: string;
  defaultExpanded?: boolean;
  sectionHeading?: boolean;
};

export function WikiMenuGroup({
  label,
  href,
  children,
  defaultExpanded = false,
  sectionHeading = false,
}: Props) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(defaultExpanded);

  const toggleExpanded = useCallback(() => {
    const nextExpanded = !expanded;
    setExpanded(nextExpanded);

    if (href && (nextExpanded || !children)) {
      navigate(href);
    }
  }, [expanded, href, children, navigate]);

  return (
    <ul className="space-y-2">
      <li>
        <button
          type="button"
          onClick={toggleExpanded}
          className={cn(
            'flex justify-between items-center gap-1 text-black font-semibold',
            {
              'text-lg': sectionHeading,
              '': !sectionHeading,
            },
          )}
        >
          <span>{label}</span>
          {children && <ExpandedIcon expanded={expanded} />}
        </button>
      </li>

      {expanded && children && (
        <ul className="list-inside ml-4 space-y-2">{children}</ul>
      )}
    </ul>
  );
}
