import { Outlet } from 'react-router';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import {
  uploadLithostratFormationListRoute,
  uploadLithostratGroupListRoute,
  uploadLithostratMemberListRoute,
} from '~/paths';

export default function UploadLithostratigraphyRoute() {
  useBreadcrumb('routes/upload/lithostrat/__overview', 'Lithostratigraphy');

  return (
    <>
      <PageHeading>Lithostratigraphy</PageHeading>

      <NavTabs>
        <NavTabs.Tab to={uploadLithostratGroupListRoute()}>Groups</NavTabs.Tab>
        <NavTabs.Tab to={uploadLithostratFormationListRoute()}>
          Formations
        </NavTabs.Tab>
        <NavTabs.Tab to={uploadLithostratMemberListRoute()}>
          Members
        </NavTabs.Tab>
      </NavTabs>

      <div className="mt-2" />
      <Outlet />
    </>
  );
}
