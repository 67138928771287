import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql } from '~/apollo/client-v3';
import * as fragments from '~/apollo/fragments';
import type {
  IssueListPageQuery,
  IssueListPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { IssueWorkStatus } from '~/apollo/generated/v3/graphql';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateIssueModal } from '~/components/issue/CreateIssueModal';
import { IssueListItem } from '~/components/issue/IssueListItem';
import { IssueTypeFilter } from '~/components/issue/IssueTypeFilter';
import { PageLoader } from '~/components/page/PageLoader';
import { Button } from '~/components/ui/button';
import { useSortFilter } from '~/hooks/data';

export const ISSUE_LIST_PAGE = gql`
  query IssueListPage($issueId: Int) {
    issueList(id: $issueId) {
      ...issueParts
      author {
        ...publicUserParts
      }
      comments {
        id
      }
    }
  }

  ${fragments.issueParts}
  ${fragments.publicUserParts}
`;

type QueryIssueType = IssueListPageQuery['issueList'][number];
type IssueWithMetadata = QueryIssueType & {
  numComments: number;
};

export default function SupportIndexRoute() {
  const { data, loading } = useQuery<
    IssueListPageQuery,
    IssueListPageQueryVariables
  >(ISSUE_LIST_PAGE, {});

  const refetchQueries: [PureQueryOptions<IssueListPageQueryVariables>] = [
    { query: ISSUE_LIST_PAGE },
  ];

  const rawIssues = data?.issueList ?? [];
  const augmentedIssues = rawIssues.map(iss => {
    return {
      ...iss,
      numComments: iss.comments ? iss.comments.length : 0,
    } as IssueWithMetadata;
  });

  const {
    items: sortedIssues,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(
    augmentedIssues,
    'dateCreated',
    'issueType',
    'issueList',
    'desc',
  );

  const filterByWorkStatus =
    (allowed: IssueWorkStatus[]) => (issue: QueryIssueType) =>
      allowed.includes(issue.workStatus);

  const openIssues = sortedIssues.filter(
    filterByWorkStatus([IssueWorkStatus.Open, IssueWorkStatus.WorkInProgress]),
  );
  const closedIssues = sortedIssues.filter(
    filterByWorkStatus([IssueWorkStatus.Resolved, IssueWorkStatus.Closed]),
  );

  const numIssues = openIssues.length + closedIssues.length;

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading className="my-0">Support Issues</PageHeading>
      <PageLoader pageName="support" showTitle={false} />

      <div className="text-right space-x-2">
        <IssueTypeFilter
          issueType={filterSearchProps.includesValue}
          onChange={filterSearchProps.onIncludesChange}
        />
        <CreateIssueModal refetchQueries={refetchQueries}>
          {showCreateIssueModal => (
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={showCreateIssueModal}
              className="gap-1"
            >
              <FontAwesomeIcon icon={faPlus} /> Create New Issue
            </Button>
          )}
        </CreateIssueModal>
      </div>

      <table className="table border border-slate-200 table-compact w-full">
        <thead className="border border-slate-200 bg-slate-50">
          <tr>
            <th>
              <SortTrigger colName="title" sortIndicatorProps={siProps}>
                Issue
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="dateCreated" sortIndicatorProps={siProps}>
                Created
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="workStatus"
                sortIndicatorProps={siProps}
                filterable
              >
                Status
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger colName="numComments" sortIndicatorProps={siProps}>
                Comments
              </SortTrigger>
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {openIssues.map(issue => (
            <IssueListItem key={issue.id} issue={issue} />
          ))}
          {closedIssues.map(issue => (
            <IssueListItem key={issue.id} issue={issue} />
          ))}
          <NoItemsRow colSpan={5} show={numIssues === 0} />
        </tbody>
      </table>
    </>
  );
}
