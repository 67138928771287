import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type {
  RemoveItemFromReportMutation,
  RemoveItemFromReportMutationVariables,
} from '~/apollo/generated/v3/graphql';

const REMOVE_ITEM_FROM_REPORT = gql`
  mutation RemoveItemFromReport($reportId: Int!, $reportItemId: Int!) {
    removeItemFromReport(reportId: $reportId, reportItemId: $reportItemId)
  }
`;

export function RemoveItem({
  children,
  refetchQueries,
  reportId,
  reportItemId,
}: {
  children: (deleteItem: () => Promise<void>, loading: boolean) => JSX.Element;
  refetchQueries: PureQueryOptions[];
  reportId: number;
  reportItemId: number;
}) {
  const [removeItem, { loading }] = useMutation<
    RemoveItemFromReportMutation,
    RemoveItemFromReportMutationVariables
  >(REMOVE_ITEM_FROM_REPORT, {
    variables: { reportId, reportItemId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await removeItem();
      toast.success('Item removed from report.');
    } catch (err) {
      console.log('Error removing item from report', err);
      toast.error(
        'There was a problem removing the item from the report. Please reload the page and try again.',
      );
    }
  }

  return children(handleDelete, loading);
}
