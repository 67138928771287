import { useQuery } from '@apollo/client';
import { graphql } from '~/apollo/generated/v3';
import type { OutcropVideosPageQuery } from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useRouteParam } from '~/hooks/routing';

const OUTCROP_VIDEOS_PAGE = graphql(`
  query OutcropVideosPage($id: Int!) {
    outcropList(id: $id) {
      id
      videos {
        id
        name
        url
      }
    }
  }
`);

export default function OutcropVideosPage() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const { data, loading } = useQuery(OUTCROP_VIDEOS_PAGE, {
    variables: {
      id: outcropId,
    },
  });

  const outcrop = data?.outcropList.find(outcrop => outcrop.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <PageInner videos={outcrop.videos} />;
}

function PageInner({
  videos,
}: {
  videos: OutcropVideosPageQuery['outcropList'][number]['videos'];
}) {
  return (
    <div className="grid lg:grid-cols-2 gap-6">
      {videos.map(v => (
        <div key={v.id} className="w-full aspect-square thumbnail">
          <iframe title={v.name} src={v.url} className="w-full h-full" />
          <div className="caption text-center">{v.name}</div>
        </div>
      ))}
    </div>
  );
}
