import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faPencil, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  UpdateOutcropVomsTabQuery,
  UpdateOutcropVomsTabQueryVariables,
  VomPriority,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';
import { uploadVomRoute, uploadVomUpdateRoute } from '~/paths';
import { cn } from '~/utils/common';
import { UpdateVomPriorities } from '~/components/upload/vom/UpdateVomPriorities';
import { Tooltip } from '~/components/common/Tooltip';

const VOMS_TAB = gql`
  query UpdateOutcropVomsTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      virtualOutcropModels {
        ...vomParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.vomParts}
`;

export default function UpdateOutcropVirtualOutcropsRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UpdateOutcropVomsTabQuery,
    UpdateOutcropVomsTabQueryVariables
  >(VOMS_TAB, {
    variables: { outcropId },
  });

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const voms = R.sortWith(
    [
      R.ascend(R.propOr<number>(Infinity, 'priority')),
      R.ascend(R.prop('name')),
    ],
    outcrop?.virtualOutcropModels ?? [],
  );

  function vomPriorities(vomId: number): VomPriority[] {
    const isCurrentPriority = voms.find(v => v.id === vomId)?.priority === 1;
    if (isCurrentPriority) {
      return voms.map(vom => ({
        id: vom.id,
        priority: null,
      }));
    } else {
      return voms.map(vom => ({
        id: vom.id,
        priority: vom.id === vomId ? 1 : null,
      }));
    }
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      {!voms.length && (
        <p className="text-center text-muted">
          No VOMs are linked to this outcrop.
          <br />
          <Link to={uploadVomRoute()} className="link">
            View master VOM list
          </Link>
        </p>
      )}

      {voms.map(vom => (
        <Panel key={vom.id}>
          <Panel.Heading className="flex justify-between items-center">
            <Panel.Title>{vom.name}</Panel.Title>
            <Link
              to={uploadVomUpdateRoute(vom.id)}
              className="btn btn-ghost btn-xs float-right gap-1"
            >
              <FontAwesomeIcon icon={faPencil} /> Edit
            </Link>
          </Panel.Heading>
          <Panel.Body>
            <div className="flex items-center justify-start gap-6">
              <div>
                <UpdateVomPriorities
                  outcropId={outcropId}
                  priorities={vomPriorities(vom.id)}
                >
                  {(updatePriorities, loading) => (
                    <Tooltip message="Set default VOM for this outcrop">
                      <button
                        type="button"
                        onClick={updatePriorities}
                        disabled={loading}
                        className={cn(
                          'btn btn-ghost text-3xl hover:text-amber-600',
                          {
                            'text-amber-500': vom.priority === 1,
                            'text-slate-100': vom.priority !== 1,
                          },
                        )}
                      >
                        <FontAwesomeIcon icon={faStar} />
                      </button>
                    </Tooltip>
                  )}
                </UpdateVomPriorities>
              </div>
              <div>
                <Heading level={4}>{vom.name}</Heading>
                <Heading level={4} className="text-muted mt-0">
                  {vom.acquisitionYear}
                </Heading>
                <p>{vom.aquiringPerson}</p>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      ))}
    </div>
  );
}
