import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { graphql } from '~/apollo/generated/v3';
import {
  Role,
  type CompanyAdminUsersPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { LocalDate } from '~/components/common/LocalDate';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CompanyAdminUserList } from '~/components/company/CompanyAdminUserList';
import { CreateUserModal } from '~/components/company/CreateUserModal';
import { useAuthenticated } from '~/contexts/auth';

const COMPANY_ADMIN_USERS_PAGE = graphql(`
  query CompanyAdminUsersPage($id: Int!) {
    companyList(id: $id) {
      ...companyParts
      user {
        ...userParts
      }
    }
  }
`);

export default function CompanyAdminUsersPage() {
  const { authority } = useAuthenticated();
  const companyId = authority.user.companyId;

  const { data, loading } = useQuery(COMPANY_ADMIN_USERS_PAGE, {
    variables: { id: companyId },
  });

  const refetchQueries: [
    PureQueryOptions<CompanyAdminUsersPageQueryVariables>,
  ] = [{ query: COMPANY_ADMIN_USERS_PAGE, variables: { id: companyId } }];

  const company = data?.companyList.find(c => c.id === companyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!company) return <NotFound />;

  const activeUsers = company.user.filter(user => !user.accountLocked);

  return (
    <Panel>
      <Panel.Heading>
        <div className="float-right">
          <CreateUserModal company={company} refetchQueries={refetchQueries}>
            {toggleCreateUserModal => (
              <Button
                color="primary"
                size="xs"
                onClick={toggleCreateUserModal}
                className="gap-2"
                disabled={company.isAadConfigured}
              >
                <FontAwesomeIcon icon={faPlus} />
                Create User
              </Button>
            )}
          </CreateUserModal>
        </div>
        <Panel.Title>
          <strong>Users</strong> {activeUsers.length} active users
          <RoleSecured roles={[Role.RoleAdmin]}>
            <div className="text-slate-600 text-sm">
              {company.lastActivity && (
                <>
                  Last Activity <LocalDate date={company.lastActivity} />
                </>
              )}
              {company.activityCount && (
                <>
                  {' - '}
                  Activity Count {company.activityCount}
                </>
              )}
            </div>
          </RoleSecured>
        </Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <CompanyAdminUserList users={company.user} />
      </Panel.Body>
    </Panel>
  );
}
