import type { PureQueryOptions } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import type { TargetReviewCommentsQuery } from '~/apollo/generated/v3/graphql';
import { Role } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { LocalDate } from '~/components/common/LocalDate';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteReviewComment } from '~/components/reviewComment/DeleteReviewComment';
import { useAuth } from '~/contexts/auth';

type Props = {
  reviewComment: TargetReviewCommentsQuery['reviewCommentList'][number];
  refetchQueries: PureQueryOptions[];
};

export function ReviewComment({ reviewComment, refetchQueries }: Props) {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be logged in');

  const isAuthor = authority.user.id === reviewComment.authorId;
  const canDelete = isAuthor || authority.roles.includes(Role.RoleAdmin);

  return (
    <div className="p-2 bg-base-200">
      {canDelete && (
        <div className="float-right">
          <DeleteReviewComment
            commentId={reviewComment.id}
            refetchQueries={refetchQueries}
          >
            {(confirmDelete, loading) => (
              <Tooltip message="Delete comment">
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={confirmDelete}
                  disabled={loading}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            )}
          </DeleteReviewComment>
        </div>
      )}

      <div className="space-y-2">
        <div>
          <Heading level={4} className="m-0">
            {reviewComment.author.name}
          </Heading>
          <div className="text-muted text-sm">
            <LocalDate
              date={reviewComment.insertedAt}
              format={{
                dateStyle: 'medium',
                timeStyle: 'short',
              }}
            />
          </div>
        </div>
        <div className="break-words whitespace-pre-line">
          {reviewComment.comment}
        </div>
      </div>
    </div>
  );
}
