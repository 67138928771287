import type { DocumentNode } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type {
  CrossSectionPartsFragment,
  FaciesPartsFragment,
  GigaPanPartsFragment,
  PicturePartsFragment,
  ProductionPartsFragment,
  ReservoirModelPartsFragment,
  SedimentaryLogPartsFragment,
  TrainingImagePartsFragment,
  UpdateCrossSectionMutationVariables,
  UpdateFaciesMutationVariables,
  UpdateGigaPanMutationVariables,
  UpdatePictureMutationVariables,
  UpdateProductionMutationVariables,
  UpdateReservoirModelMutationVariables,
  UpdateSedimentaryLogMutationVariables,
  UpdateTrainingImageMutationVariables,
  UpdateVariogramMutationVariables,
  UpdateWellLogMutationVariables,
  VariogramPartsFragment,
  WellLogPartsFragment,
} from '~/apollo/generated/v3/graphql';
import {
  UPDATE_CROSS_SECTION,
  UPDATE_FACIES,
  UPDATE_GIGA_PAN,
  UPDATE_PICTURE,
  UPDATE_PRODUCTION,
  UPDATE_RESERVOIR_MODEL,
  UPDATE_SEDIMENTARY_LOG,
  UPDATE_TRAINING_IMAGE,
  UPDATE_VARIOGRAM,
  UPDATE_WELL_LOG,
} from '~/apollo/operations/supportingObject';
import {
  initialCrossSection,
  toSOInputType,
  type SupportObjectType,
  initialFacies,
  initialSedimentaryLog,
  initialWellLog,
  initialProduction,
  initialReservoirModel,
  initialTrainingImage,
  initialVariogram,
  initialGigaPan,
  formValuesToPictureInput,
  initialPicture,
} from '~/utils/modules/supportObject';

interface BaseProps {
  published: boolean;
  soType: SupportObjectType | 'picture';
  children: (
    togglePublished: () => Promise<void>,
    loading: boolean,
  ) => JSX.Element;
}

interface PropsCrossSection extends BaseProps {
  soType: 'crossSection';
  crossSection: CrossSectionPartsFragment;
}
interface PropsSedimentaryLog extends BaseProps {
  soType: 'sedimentaryLog';
  sedimentaryLog: SedimentaryLogPartsFragment;
}
interface PropsFacies extends BaseProps {
  soType: 'facies';
  facies: FaciesPartsFragment;
}
interface PropsWellLog extends BaseProps {
  soType: 'wellLog';
  wellLog: WellLogPartsFragment;
}
interface PropsProduction extends BaseProps {
  soType: 'production';
  production: ProductionPartsFragment;
}
interface PropsReservoirModel extends BaseProps {
  soType: 'reservoirModel';
  reservoirModel: ReservoirModelPartsFragment;
}
interface PropsTrainingImage extends BaseProps {
  soType: 'trainingImage';
  trainingImage: TrainingImagePartsFragment;
}
interface PropsVariogram extends BaseProps {
  soType: 'variogram';
  variogram: VariogramPartsFragment;
}
interface PropsGigaPan extends BaseProps {
  soType: 'gigaPan';
  gigaPan: GigaPanPartsFragment;
}
interface PropsPicture extends BaseProps {
  soType: 'picture';
  picture: PicturePartsFragment;
}

type Props =
  | PropsCrossSection
  | PropsSedimentaryLog
  | PropsFacies
  | PropsWellLog
  | PropsProduction
  | PropsReservoirModel
  | PropsTrainingImage
  | PropsVariogram
  | PropsGigaPan
  | PropsPicture;

function mutation(soType: BaseProps['soType']): DocumentNode {
  switch (soType) {
    case 'crossSection':
      return UPDATE_CROSS_SECTION;
    case 'sedimentaryLog':
      return UPDATE_SEDIMENTARY_LOG;
    case 'facies':
      return UPDATE_FACIES;
    case 'wellLog':
      return UPDATE_WELL_LOG;
    case 'production':
      return UPDATE_PRODUCTION;
    case 'reservoirModel':
      return UPDATE_RESERVOIR_MODEL;
    case 'trainingImage':
      return UPDATE_TRAINING_IMAGE;
    case 'variogram':
      return UPDATE_VARIOGRAM;
    case 'gigaPan':
      return UPDATE_GIGA_PAN;
    case 'picture':
      return UPDATE_PICTURE;
  }
}

function variables(props: Props, published: boolean): Record<string, any> {
  switch (props.soType) {
    case 'crossSection':
      return {
        id: props.crossSection.id,
        crossSection: toSOInputType(
          'crossSection',
          initialCrossSection({ ...props.crossSection, published }),
        ),
      } satisfies UpdateCrossSectionMutationVariables;
    case 'sedimentaryLog':
      return {
        id: props.sedimentaryLog.id,
        sedimentaryLog: toSOInputType(
          'sedimentaryLog',
          initialSedimentaryLog({ ...props.sedimentaryLog, published }),
        ),
      } satisfies UpdateSedimentaryLogMutationVariables;
    case 'facies':
      return {
        id: props.facies.id,
        facies: toSOInputType(
          'facies',
          initialFacies({ ...props.facies, published }),
        ),
      } satisfies UpdateFaciesMutationVariables;
    case 'wellLog':
      return {
        id: props.wellLog.id,
        wellLog: toSOInputType(
          'wellLog',
          initialWellLog({ ...props.wellLog, published }),
        ),
      } satisfies UpdateWellLogMutationVariables;
    case 'production':
      return {
        id: props.production.id,
        production: toSOInputType(
          'production',
          initialProduction({ ...props.production, published }),
        ),
      } satisfies UpdateProductionMutationVariables;
    case 'reservoirModel':
      return {
        id: props.reservoirModel.id,
        reservoirModel: toSOInputType(
          'reservoirModel',
          initialReservoirModel({ ...props.reservoirModel, published }),
        ),
      } satisfies UpdateReservoirModelMutationVariables;
    case 'trainingImage':
      return {
        id: props.trainingImage.id,
        trainingImage: toSOInputType(
          'trainingImage',
          initialTrainingImage({ ...props.trainingImage, published }),
        ),
      } satisfies UpdateTrainingImageMutationVariables;
    case 'variogram':
      return {
        id: props.variogram.id,
        variogram: toSOInputType(
          'variogram',
          initialVariogram({ ...props.variogram, published }),
        ),
      } satisfies UpdateVariogramMutationVariables;
    case 'gigaPan':
      return {
        id: props.gigaPan.id,
        gigaPan: toSOInputType(
          'gigaPan',
          initialGigaPan({ ...props.gigaPan, published }),
        ),
      } satisfies UpdateGigaPanMutationVariables;
    case 'picture':
      return {
        id: props.picture.id,
        picture: formValuesToPictureInput(
          initialPicture({ ...props.picture, published }),
        ),
      } satisfies UpdatePictureMutationVariables;
  }
}

export function TogglePublished(props: Props) {
  const [updateSO, { loading }] = useMutation(mutation(props.soType), {
    variables: variables(props, !props.published),
  });

  async function handleClick() {
    try {
      await updateSO();
      toast.success(
        `Successfully ${props.published ? 'unpublished' : 'published'}`,
      );
    } catch (err) {
      console.log('Error toggling published:', props, err);
      toast.error(
        'There was a problem toggling the published state, please reload the page and try again.',
      );
    }
  }

  return props.children(handleClick, loading);
}
