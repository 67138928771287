import { Navigate } from 'react-router';
import { Role } from '~/apollo/generated/v3/graphql';
import { useAuth } from '~/contexts/auth';
import { adminUploadRoute, companyAdminRoute } from '~/paths';

export default function AdminIndexRoute() {
  const { hasAnyRole } = useAuth();

  if (hasAnyRole([Role.RoleAdmin])) {
    return <Navigate to={adminUploadRoute()} />;
  }

  if (hasAnyRole([Role.RoleCompanyAdmin])) {
    return <Navigate to={companyAdminRoute()} />;
  }

  throw new Error('Authentication required');
}
