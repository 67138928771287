import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '~/components/ui/alert';
import { Link } from 'react-router';
import { Heading } from '~/components/common/Heading';
import { dashboardRoute } from '~/paths';

export default function ForbiddenRoute() {
  return (
    <Alert status="error">
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-3xl" />
      <div>
        <Heading level={3}>Forbidden</Heading>
        <p>You don't have permission to view this page.</p>
        <p>
          <Link to={dashboardRoute()} className="link">
            Return to dashboard
          </Link>
        </p>
      </div>
    </Alert>
  );
}
