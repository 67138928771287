import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v3';
import type {
  StudyEnumerationsQuery,
  UploadStudyUpdateOverviewTabQuery,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { filterUnique } from '~/utils/common';
import type { StudyFormValues } from '~/utils/modules/study';
import {
  defaultStudy,
  toStudyInput,
  validationSchema,
} from '~/utils/modules/study';
import { StudyOverviewFormFields } from './StudyOverviewFormFields';

const UPDATE_STUDY = graphql(`
  mutation UpdateStudy($studyId: Int!, $study: StudyInput!) {
    updateStudy(id: $studyId, study: $study) {
      ...studyParts
      dataHistory {
        ...dataHistoryParts
      }
      qualityParameters {
        ...studyQualityParametersParts
      }
    }
  }
`);

type Props = {
  studyId: number;
  study: UploadStudyUpdateOverviewTabQuery['studyList'][number];
  enumOptions: StudyEnumerationsQuery;
};

export function UpdateStudyForm({ studyId, study, enumOptions }: Props) {
  const [updateStudy, { loading, error }] = useMutation(UPDATE_STUDY);

  async function handleSubmit(values: StudyFormValues) {
    const studyInput = toStudyInput(values);

    try {
      await updateStudy({
        variables: {
          studyId,
          study: studyInput,
        },
      });
      toast.success('Study updated successfully');
    } catch (err) {
      console.log('Error updating study', err);
      toast.error('There was a problem updating the study.');
    }
  }

  const geologyTypes = study.outcrops
    .flatMap(oc => oc.geologyType)
    .filter(filterUnique);

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Study Overview</Panel.Title>
      </Panel.Heading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={defaultStudy(study)}
        validationSchema={validationSchema()}
      >
        <Form>
          <Panel.Body>
            <StudyOverviewFormFields
              action="update"
              enumOptions={enumOptions}
              geologyTypes={geologyTypes}
            />

            <FormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer className="p-2 text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
            </button>
          </Panel.Footer>
        </Form>
      </Formik>
    </Panel>
  );
}

export default UpdateStudyForm;
