import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { dmvParts } from '~/apollo/fragments';
import type {
  StudyDefaultMeasurementsViewPageQuery,
  StudyDefaultMeasurementsViewPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { UpdateDmvForm } from '~/components/upload/defaultMeasurementsView/UpdateDmvForm';

const STUDY_DEFAULT_MEASUREMENTS_VIEW_PAGE = gql`
  query StudyDefaultMeasurementsViewPage($studyId: Int!) {
    studyList(id: $studyId) {
      id
      defaultMeasurementsView {
        ...dmvParts
      }
    }
  }

  ${dmvParts}
`;

export function StudyDefaultMeasurementsViewPage() {
  const params = useParams();
  invariant(params.studyId);
  const studyId = parseInt(params.studyId);

  const { data, loading } = useQuery<
    StudyDefaultMeasurementsViewPageQuery,
    StudyDefaultMeasurementsViewPageQueryVariables
  >(STUDY_DEFAULT_MEASUREMENTS_VIEW_PAGE, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<StudyDefaultMeasurementsViewPageQueryVariables>,
  ] = [{ query: STUDY_DEFAULT_MEASUREMENTS_VIEW_PAGE, variables: { studyId } }];

  useBreadcrumb(
    'routes/upload/model/study/$studyId/default-measurements-view',
    'Measurements Page',
  );

  const study = data?.studyList.find(s => s.id === studyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  const dmv = study.defaultMeasurementsView ?? null;

  return (
    <UpdateDmvForm
      type="study"
      studyId={study.id}
      dmv={dmv}
      refetchQueries={refetchQueries}
    />
  );
}
