import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
  DataSearchInfoIconContentQuery,
  DataSearchInfoIconContentQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/common/Popover';
import { Prose } from '~/components/common/Prose';

const DATA_SEARCH_INFO_ICON_CONTENT = gql`
  query DataSearchInfoIconContent($pageNames: [String!]!) {
    pageList(pageNames: $pageNames) {
      id
      pageName
      title
      content
    }
  }
`;

const Icon = () => (
  <FontAwesomeIcon icon={faInfoCircle} className="text-2xl mb-2 text-warning" />
);

const pageName = 'intro_data_search';

export function DataSearchInfoIcon() {
  const { data, loading } = useQuery<
    DataSearchInfoIconContentQuery,
    DataSearchInfoIconContentQueryVariables
  >(DATA_SEARCH_INFO_ICON_CONTENT, {
    variables: { pageNames: [pageName] },
  });

  const page = data?.pageList.find(p => p.pageName === pageName);

  if (loading || !page) {
    return <Icon />;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Icon />
      </PopoverTrigger>
      <PopoverContent
        side="right"
        className="block w-[600px] max-h-[400px] overflow-auto"
      >
        <Prose dangerouslySetInnerHTML={{ __html: page.content }} />
      </PopoverContent>
    </Popover>
  );
}
