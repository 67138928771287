import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_PRODUCTION,
  DELETE_PRODUCTION,
  UPDATE_PRODUCTION,
} from '~/apollo/operations/supportingObject';
import type {
  UploadStudyUpdateProductionTabQuery,
  UploadStudyUpdateProductionTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const PRODUCTION_TAB = gql`
  query UploadStudyUpdateProductionTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      production {
        ...productionParts
        outcropTag {
          ...outcropParts
        }
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.productionParts}
  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateStudyProductionRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateProductionTabQuery,
    UploadStudyUpdateProductionTabQueryVariables
  >(PRODUCTION_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateProductionTabQueryVariables>,
  ] = [
    {
      query: PRODUCTION_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const production = study?.production ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SupportObjectManager
      studyId={studyId}
      supportObject={production}
      supportObjectType="production"
      parentType="PRODUCTION"
      createMutation={CREATE_PRODUCTION}
      updateMutation={UPDATE_PRODUCTION}
      deleteMutation={DELETE_PRODUCTION}
      refetchQueries={refetchQueries}
    />
  );
}
