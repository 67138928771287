import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_PRODUCTION,
  DELETE_PRODUCTION,
  UPDATE_PRODUCTION,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropUpdateProductionTabQuery,
  UploadOutcropUpdateProductionTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const PRODUCTION_TAB = gql`
  query UploadOutcropUpdateProductionTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      production {
        ...productionParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.productionParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateOutcropProductionRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateProductionTabQuery,
    UploadOutcropUpdateProductionTabQueryVariables
  >(PRODUCTION_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateProductionTabQueryVariables>,
  ] = [
    {
      query: PRODUCTION_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const production = outcrop?.production ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SupportObjectManager
      outcropId={outcropId}
      supportObject={production}
      supportObjectType="production"
      parentType="PRODUCTION"
      createMutation={CREATE_PRODUCTION}
      updateMutation={UPDATE_PRODUCTION}
      deleteMutation={DELETE_PRODUCTION}
      refetchQueries={refetchQueries}
    />
  );
}
