import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useOutcropVomsOutletContext } from '~/routes/outcrop/$outcropId/virtual-outcrops';
import { outcropRoute, outcropVirtualOutcropsTabRoute } from '~/paths';

export default function OutcropVomsIndexRoute() {
  const navigate = useNavigate();
  const { outcropId, linkedVoms } = useOutcropVomsOutletContext();

  const firstVomId = linkedVoms[0]?.id;

  useEffect(() => {
    if (firstVomId) {
      navigate(outcropVirtualOutcropsTabRoute(outcropId, firstVomId));
    } else {
      navigate(outcropRoute(outcropId));
    }
  }, [outcropId, firstVomId, navigate]);

  return null;
}
