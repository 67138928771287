import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';

import * as fragments from '~/apollo/fragments';
import type {
  IssueDetailPageQuery,
  IssueInput,
  UpdateIssueStatusMutation,
  UpdateIssueStatusMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { IssueWorkStatus, Role } from '~/apollo/generated/v3/graphql';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { useAuth } from '~/contexts/auth';
import { castWorkStatus } from '~/utils/modules/issue';
import { snakeCapsToHuman } from '~/utils/text';

export const UPDATE_ISSUE_STATUS = gql`
  mutation UpdateIssueStatus($id: Int!, $issue: IssueInput!) {
    updateIssue(id: $id, issue: $issue) {
      ...issueParts
    }
  }

  ${fragments.issueParts}
`;

type Props = {
  issue: IssueDetailPageQuery['issueList'][number];
};

export function IssueActions({ issue }: Props) {
  const { hasAnyRole } = useAuth();
  const canEdit = hasAnyRole([Role.RoleAdmin]);

  const [updateIssue, { loading }] = useMutation<
    UpdateIssueStatusMutation,
    UpdateIssueStatusMutationVariables
  >(UPDATE_ISSUE_STATUS, {});

  async function handleStatusChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const nextIssue: IssueInput = {
      title: issue.title,
      content: issue.content,
      issueType: issue.issueType,
      workStatus: castWorkStatus(event.target.value),
    };

    try {
      await updateIssue({
        variables: {
          id: issue.id,
          issue: nextIssue,
        },
      });
      toast.success('Status updated successfully.');
    } catch (err) {
      console.log('Error updating issue', err);
      toast.error(
        'There was a problem updating the issue status. Please try again.',
      );
    }
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Issue Details</Panel.Title>
      </Panel.Heading>

      <Panel.Body className="space-y-4">
        <div className="input">
          <label className="label" htmlFor="issueType">
            Created
          </label>
          <div className="cursor-not-allowed">
            <LocalDate
              date={issue.dateCreated}
              format={{ dateStyle: 'long' }}
            />
          </div>
        </div>

        <div className="input">
          <FormLabel name="issueType" label="Issue type" />
          <div className="cursor-not-allowed">
            {snakeCapsToHuman(issue.issueType)}
          </div>
        </div>

        <div className="select">
          <FormLabel name="workStatus" label="Status" />
          {canEdit && (
            <select
              id="workStatus"
              value={issue.workStatus}
              onChange={handleStatusChange}
              disabled={loading}
            >
              {Object.values(IssueWorkStatus).map(option => (
                <option key={option} value={option}>
                  {snakeCapsToHuman(option)}
                </option>
              ))}
            </select>
          )}
          {!canEdit && (
            <div className="border rounded-lg p-3 cursor-not-allowed">
              {issue.workStatus}
            </div>
          )}
        </div>
      </Panel.Body>
    </Panel>
  );
}
