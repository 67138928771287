import { useQuery } from '@apollo/client';
import type { FieldProps } from 'formik';
import * as R from 'ramda';
import { graphql } from '~/apollo/generated/v3';
import { FormikReactSelectField } from '~/components/common/FormikField/FormikReactSelectField';

const OUTCROP_SELECT_DROPDOWN = graphql(`
  query OutcropSelectDropdown {
    outcropList {
      id
      name
    }
  }
`);

type Props = FieldProps & {
  disabled?: boolean;
  onChange?: (value: number | null) => void;
  disabledIds?: number[];
};

export function OutcropSelect({
  form,
  field,
  disabled = false,
  onChange,
  disabledIds = [],
  ...props
}: Props) {
  const { loading, data } = useQuery(OUTCROP_SELECT_DROPDOWN);
  const outcrops = R.sortBy(oc => oc.name, data?.outcropList ?? []);

  const options = outcrops.map(oc => ({
    value: oc.id,
    label: `${oc.name} (${oc.id})`,
    disabled: disabledIds.length > 0 && disabledIds.includes(oc.id),
  }));

  function handleChange(outcropId: number | null) {
    if (onChange) onChange(outcropId);
    else form.setFieldValue(field.name, outcropId);
  }

  return (
    <FormikReactSelectField
      form={form}
      field={field}
      {...props}
      onChange={handleChange}
      options={options}
      disabled={disabled || loading}
      reactSelectProps={{
        isSearchable: true,
      }}
    />
  );
}
