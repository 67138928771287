import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import type {
  DeleteStudyMutation,
  DeleteStudyMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Panel } from '~/components/common/Panel';
import { uploadStudiesRoute } from '~/paths';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const DELETE_STUDY = gql`
  mutation DeleteStudy($id: Int!) {
    deleteStudy(id: $id)
  }
`;

export default function UpdateStudyDeleteRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const navigate = useNavigate();
  const [deleteStudy, { loading }] = useMutation<
    DeleteStudyMutation,
    DeleteStudyMutationVariables
  >(DELETE_STUDY, {
    variables: { id: studyId },
  });

  async function handleDelete() {
    try {
      await deleteStudy();

      toast.success('The study was deleted successfully.');
      navigate(uploadStudiesRoute());
    } catch (err) {
      console.log('Error deleting study', err);
      toast.error('There was a problem deleting the study.');
    }
  }

  return (
    <div className="lg:w-10/12 lg:mx-auto">
      <Panel variant="error">
        <Panel.Heading>
          <Panel.Title>Delete Study</Panel.Title>
        </Panel.Heading>

        <Panel.Body className="space-y-2">
          <p>
            This study will be <b>PERMANENTLY DELETED</b>.
          </p>
          <p>
            All supporting objects that belong to this study will be{' '}
            <b>PERMANENTLY DELETED</b>.
          </p>

          <p>This action cannot be undone.</p>

          <div className="text-center">
            <Confirm
              onConfirm={handleDelete}
              submitText="Permanently Delete Study"
              submitButtonColor="error"
            >
              {handleConfirm => (
                <Button
                  type="button"
                  color="error"
                  onClick={handleConfirm}
                  loading={loading}
                >
                  Delete Study
                </Button>
              )}
            </Confirm>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
