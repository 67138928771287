import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import { graphql } from '~/apollo/generated/v3';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { AdminCompanyListItem } from '~/components/company/AdminCompanyListItem';
import CreateCompanyModal from '~/components/company/CreateCompanyModal';

const SUPERADMIN_PAGE = graphql(`
  query SuperadminPage {
    companyList {
      ...companyParts
      user {
        ...userParts
      }
      studyAccess {
        id
        project {
          id
          name
        }
      }
    }
  }
`);

export default function SuperadminPage() {
  const [usersExpanded, setUsersExpanded] = useState(false);

  const { loading, data } = useQuery(SUPERADMIN_PAGE);

  const refetchQueries: [PureQueryOptions] = [{ query: SUPERADMIN_PAGE }];

  const toggleExpanded = () => setUsersExpanded(!usersExpanded);

  const companyList = R.pipe(
    () => data?.companyList ?? [],
    R.sortBy(R.prop('name')),
  )();

  if (loading) {
    return <SpinnerPlaceholder>Loading companies...</SpinnerPlaceholder>;
  }

  return (
    <>
      <div className="float-right">
        <Button
          type="button"
          color="primary"
          size="sm"
          onClick={toggleExpanded}
        >
          <ExpandedIcon expanded={usersExpanded} />
          {usersExpanded ? 'Collapse' : 'Expand'} All Companies
        </Button>

        <CreateCompanyModal refetchQueries={refetchQueries}>
          {showModal => (
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={showModal}
              className="ml-2"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Create Company
            </Button>
          )}
        </CreateCompanyModal>
      </div>
      <div className="clear-both mb-3" />

      <div className="space-y-4">
        {companyList.map(company => (
          <AdminCompanyListItem
            key={company.id}
            company={company}
            usersExpanded={usersExpanded}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>
    </>
  );
}
