import {
  ReportItemType,
  type ReportPageQuery,
} from '~/apollo/generated/v3/graphql';
import { ReportItemBookmark } from '~/components/report/ReportDisplay/ReportItem/ReportItemBookmark';
import { ReportItemText } from '~/components/report/ReportDisplay/ReportItem/ReportItemText';

export type ReportDisplayReport = ReportPageQuery['reportList'][number];
export type ReportDisplayReportItem = ReportDisplayReport['items'][number];

export function ReportItem({ item }: { item: ReportDisplayReportItem }) {
  if (item.itemType === ReportItemType.Bookmark && item.bookmark) {
    return <ReportItemBookmark item={item} bookmark={item.bookmark} />;
  } else if (item.itemType === ReportItemType.Text) {
    return <ReportItemText item={item} />;
  }

  return null;
}
