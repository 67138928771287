import { Field } from 'formik';
import { useCallback } from 'react';
import type { OutcropEnumerationsQuery } from '~/apollo/generated/v3/graphql';
import type { OutcropEnumerationsKey } from '~/apollo/operations/outcrop';
import { FormikField } from '~/components/common/FormikField';

type Props = {
  enumOptions: OutcropEnumerationsQuery;
  disabled?: boolean;
};

export function OutcropQPsFormFields({ enumOptions, disabled = false }: Props) {
  const options = useCallback(
    (fieldName: OutcropEnumerationsKey) => {
      const values = enumOptions[fieldName]?.values ?? [];
      return values.map(opt => ({ value: opt, label: opt }));
    },
    [enumOptions],
  );

  return (
    <div className="space-y-2">
      <Field
        name="depositionalDipDirection"
        label="Depositional Dip Direction"
        component={FormikField}
        type="select"
        helpText="The dominant direction for the study interval."
        options={options('depositionalDipDirection')}
        disabled={disabled}
      />
      <Field
        name="orientation"
        label="Outcrop Orientation"
        component={FormikField}
        type="select"
        helpText="Dominant orientation of the outcrop."
        options={options('orientation')}
        disabled={disabled}
      />

      {/* Field is snake cased due to absinthe bug with camelcasing */}
      <Field
        name="qualityParameters.outcrop_3d_control"
        label="Outcrop 3D control"
        component={FormikField}
        type="select"
        helpText="How good is the 3D control?"
        options={options('outcrop3dControl')}
        disabled={disabled}
        required
      />
      <Field
        name="qualityParameters.exposureQuality"
        label="Exposure Quality"
        component={FormikField}
        type="select"
        helpText="How good is the outcrop?"
        options={options('exposureQuality')}
        disabled={disabled}
        required
      />
      <Field
        name="qualityParameters.structuralComplexity"
        label="Structural Complexity"
        component={FormikField}
        type="select"
        helpText="How deformed is the outcrop?"
        options={options('structuralComplexity')}
        disabled={disabled}
        required
      />
      <Field
        name="qualityParameters.datasetScale"
        label="Dataset Scale"
        component={FormikField}
        type="select"
        helpText="Select the appropriate scale: Lithofacies - metre; AE at the tens m; SE at the hundreds of m, depositional system >1km"
        options={options('datasetScale')}
        disabled={disabled}
        required
      />
    </div>
  );
}
