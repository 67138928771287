import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v3';
import { BookmarkCollectionList } from '~/components/bookmark/BookmarkCollectionList';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';
import { useMySafariOutletContext } from '~/routes/my-safari';

const MY_SAFARI_COMPANY_COLLECTIONS_PAGE = graphql(`
  query MySafariCompanyCollectionsPage {
    bookmarkCollectionList(includePersonal: false, includeCompany: true) {
      ...bookmarkCollectionListParts
    }
  }
`);

export default function MySafariCompanyCollectionsPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');

  const { data, loading } = useQuery(MY_SAFARI_COMPANY_COLLECTIONS_PAGE);

  const ctx = useMySafariOutletContext();

  const refetchQueries: [...typeof ctx.refetchQueries, PureQueryOptions] = [
    ...ctx.refetchQueries,
    { query: MY_SAFARI_COMPANY_COLLECTIONS_PAGE },
  ];

  if (loading) return <SpinnerPlaceholder />;
  if (!data?.bookmarkCollectionList) return <NotFound />;

  return (
    <BookmarkCollectionList
      collections={data.bookmarkCollectionList}
      isCompany={true}
      refetchQueries={refetchQueries}
    />
  );
}
