import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router';
import * as R from 'ramda';
import { Button } from '~/components/ui/button';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  UploadStudyOutcropsPageQuery,
  UploadStudyOutcropsPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { CreateOutcropLinkForm } from '~/components/upload/study/CreateOutcropLinkForm';
import { DeleteOutcropLink } from '~/components/upload/study/DeleteOutcropLink';
import { uploadOutcropUpdateRoute } from '~/paths';

const UPLOAD_STUDY_OUTCROPS_PAGE = gql`
  query UploadStudyOutcropsPage($studyId: Int!) {
    # Load study to see current outcrop links
    studyList(id: $studyId) {
      ...studyParts
      outcrops {
        ...outcropParts
      }
    }

    # Load list of outcrops to show available options
    outcropList {
      ...outcropParts
    }
  }

  ${fragments.studyParts}
  ${fragments.outcropParts}
`;

export default function UpdateStudyOutcropsRoute() {
  const params = useParams<{ studyId: string }>();
  invariant(params.studyId, 'studyId param required');
  const studyId = parseInt(params.studyId);

  const { data, loading } = useQuery<
    UploadStudyOutcropsPageQuery,
    UploadStudyOutcropsPageQueryVariables
  >(UPLOAD_STUDY_OUTCROPS_PAGE, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyOutcropsPageQueryVariables>,
  ] = [
    {
      query: UPLOAD_STUDY_OUTCROPS_PAGE,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const studyOutcrops = R.sortBy(R.prop('name'), study?.outcrops ?? []);
  const studyOutcropIds = studyOutcrops.map(s => s.id);

  const outcropList = R.sortBy(R.prop('name'), data?.outcropList ?? []);
  const availableOutcrops = outcropList.filter(
    o => !studyOutcropIds.includes(o.id),
  );

  const isAllowedDelete = studyOutcrops.length > 1;
  const removeTooltipText = 'Remove link to outcrop';
  const cannotRemoveTooltipText =
    'Cannot remove link - study must be linked to at least one outcrop.';

  if (loading || !data) return <SpinnerPlaceholder />;

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="lg:col-span-8">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Linked Outcrops</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <table className="table table-compact w-full">
              <thead>
                <tr>
                  <th>Outcrop</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {studyOutcrops.map(outcrop => (
                  <tr key={outcrop.id}>
                    <td>
                      <Link
                        to={uploadOutcropUpdateRoute(outcrop.id)}
                        target="_blank"
                        className="link"
                      >
                        {outcrop.name}
                      </Link>
                    </td>
                    <td className="text-right">
                      <Tooltip
                        message={
                          isAllowedDelete
                            ? removeTooltipText
                            : cannotRemoveTooltipText
                        }
                      >
                        <span>
                          <DeleteOutcropLink
                            outcropId={outcrop.id}
                            studyId={studyId}
                            refetchQueries={refetchQueries}
                          >
                            {(deleteLink, isDeleting) => (
                              <Confirm
                                onConfirm={deleteLink}
                                text={`The link to outcrop ${outcrop.name} will be removed.`}
                              >
                                {confirmDelete => (
                                  <Button
                                    color="ghost"
                                    size="xs"
                                    onClick={confirmDelete}
                                    disabled={isDeleting || !isAllowedDelete}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                )}
                              </Confirm>
                            )}
                          </DeleteOutcropLink>
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                ))}

                <NoItemsRow show={studyOutcrops.length === 0} colSpan={2} />
              </tbody>
            </table>
          </Panel.Body>
        </Panel>
      </div>

      <div className="lg:col-span-4">
        <CreateOutcropLinkForm
          studyId={studyId}
          outcrops={availableOutcrops}
          refetchQueries={refetchQueries}
        />
      </div>
    </div>
  );
}
