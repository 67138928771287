import { useMutation, type PureQueryOptions } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import type { ReactNode } from 'react';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v3';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';
import { useModalState } from '~/hooks/modal';
import { yup } from '~/utils/validation';

const transferMiniModelMutation = graphql(`
  mutation TransferMiniModel($miniModelId: Int!, $outcropId: Int!) {
    transferMiniModel(miniModelId: $miniModelId, outcropId: $outcropId) {
      ...miniModelParts
    }
  }
`);

type FormValues = {
  outcropId: number | null;
};

export function TransferMiniModelModal({
  children,
  miniModelId,
  refetchQueries,
}: {
  children: (showModal: () => void) => ReactNode;
  miniModelId: number;
  refetchQueries?: PureQueryOptions[];
}) {
  const { show, showModal, hideModal } = useModalState();

  const [transferMiniModel, { loading, error }] = useMutation(
    transferMiniModelMutation,
    { refetchQueries },
  );

  async function handleSubmit(values: FormValues) {
    const outcropId = values.outcropId;
    if (!outcropId) {
      toast.error('Outcrop is required.');
      return;
    }

    try {
      await transferMiniModel({ variables: { miniModelId, outcropId } });
      toast.success('Mini-Model transferred successfully.');
    } catch (err) {
      console.log('Unexpected error transferring mini-model:', err);
      toast.error(
        'Something went wrong transferring the mini-model, please reload the page and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} closeable>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ outcropId: null }}
          validationSchema={yup.object({
            outcropId: yup.number().required(),
          })}
        >
          <Form>
            <Modal.Body heading="Transfer Mini-Model">
              <div className="space-y-4">
                <p>
                  This Mini-Model will be transferred to the selected Outcrop.
                  After the transfer is complete, ensure its characteristics
                  (e.g. Architectural Elements and placement) are valid on the
                  new Outcrop.
                </p>

                <Field
                  name="outcropId"
                  label="Outcrop"
                  component={FormikField}
                  type={OutcropSelect}
                  required
                />

                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="text-right space-x-1">
                <Button
                  type="button"
                  color="ghost"
                  onClick={hideModal}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={loading}>
                  Transfer
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
