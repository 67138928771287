import { useQuery } from '@apollo/client';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import { FilterSearch } from '~/components/common/FilterSearch';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import {
  uploadRegionCreateRoute,
  uploadRegionUpdateOutcropsRoute,
  uploadRegionUpdateRoute,
} from '~/paths';

const REGION_LIST_PAGE = graphql(`
  query UploadRegionListPage {
    regionList {
      id
      name
      location {
        id
        location
        country
      }
      outcrops {
        id
      }
    }
  }
`);

export default function UploadRegionListPage() {
  const { data, loading } = useQuery(REGION_LIST_PAGE);

  const regions =
    data?.regionList.map(r => ({
      ...r,
      outcropCount: r.outcrops.length,
    })) ?? [];

  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(regions, 'name', 'name', 'uploadRegionList');

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Regions
          <small className="text-muted ml-2">
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>

        <Link
          to={uploadRegionCreateRoute()}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create Region
        </Link>
      </Panel.Heading>
      <Panel.Body className="space-y-4">
        <FilterSearch {...filterSearchProps} />

        <table className="table w-full table-pin-rows table-compact">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="id" sortIndicatorProps={siProps}>
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="location.location"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Location
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="location.country"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Country
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="outcropCount"
                  sortIndicatorProps={siProps}
                >
                  Outcrops
                </SortTrigger>
              </th>
            </tr>
          </thead>

          <tbody>
            <NoItemsRow show={!items.length} colSpan={6} />

            {items.map(region => (
              <tr key={region.id}>
                <td>{region.id}</td>
                <td>
                  <Link
                    to={uploadRegionUpdateRoute(region.id)}
                    className="link"
                  >
                    {region.name}
                  </Link>
                </td>
                <td>{region.location.location}</td>
                <td>{region.location.country}</td>
                <td className="text-center">
                  <Link
                    to={uploadRegionUpdateOutcropsRoute(region.id)}
                    className="link"
                  >
                    {region.outcropCount}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
