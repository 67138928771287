import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { GoogleMapProps } from '@react-google-maps/api';
import {
  GoogleMap,
  InfoWindowF,
  MarkerClustererF,
  MarkerF,
  PolygonF,
} from '@react-google-maps/api';
import { cn } from '~/utils/common';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import * as fragments from '~/apollo/fragments';
import type {
  GeoreferencePartsFragment,
  RegionPartsFragment,
  RegionWikiOverviewPageQuery,
  RegionWikiOverviewPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import { useDebounce } from '~/hooks/debounce';
import { regionRoute } from '~/paths';
import {
  calculateCenter,
  createBounds,
  firstOutlineOrPolygon,
  markerIcon,
  polygonOptions,
} from '~/utils/georeference';

const REGION_WIKI_OVERVIEW_PAGE = gql`
  query RegionWikiOverviewPage {
    regionList {
      ...regionParts
      outcrops {
        id
      }
      georeferences {
        ...georeferenceParts
      }
    }
  }

  ${fragments.regionParts}
  ${fragments.georeferenceParts}
`;

type RegionWithMetadata = RegionPartsFragment & {
  numOutcrops: number;
  outline: GeoreferencePartsFragment;
  center: google.maps.LatLng;
};

const outlineThreshold = 4;

export default function RegionsWikiRoute() {
  useBreadcrumb('routes/wiki/regional/regions', 'Regions/Basins');

  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const [currentZoom, setCurrentZoom] = useState<number | undefined>();
  const [selected, setSelected] = useState<RegionWithMetadata | null>(null);
  const [clickPosition, setClickPosition] = useState<google.maps.LatLng | null>(
    null,
  );
  const [currentBounds, setCurrentBounds] =
    useState<google.maps.LatLngBounds>();
  const debouncedBounds = useDebounce(currentBounds, 200);

  const { data, loading } = useQuery<
    RegionWikiOverviewPageQuery,
    RegionWikiOverviewPageQueryVariables
  >(REGION_WIKI_OVERVIEW_PAGE);

  const regions: RegionWithMetadata[] = useMemo(() => {
    if (!data?.regionList) return [];

    // Only display regions that have an outcrop set and an outline-like georef
    return data.regionList.reduce<RegionWithMetadata[]>((acc, region) => {
      const numOutcrops = region.outcrops.length ?? 0;
      const outline = firstOutlineOrPolygon(region.georeferences);

      if (numOutcrops > 0 && outline) {
        acc.push({
          ...region,
          numOutcrops,
          outline,
          center: getOutlineCenter(outline),
        });
      }

      return acc;
    }, []);
  }, [data?.regionList]);

  const { items, sortIndicatorProps } = useSortFilter(
    regions,
    'name',
    'name',
    'regionWikiOverviewList',
  );
  const itemsRef = useRef(items);

  const handleMapLoad: GoogleMapProps['onLoad'] = map => {
    const georefs = regions.map(r => r.outline);
    const bounds = createBounds(georefs);

    setGoogleMap(map);

    if (georefs.length) {
      map.fitBounds(bounds);
    } else {
      map.setCenter({ lat: 0, lng: 0 });
      map.setZoom(1);
    }
  };

  const handleZoomChange: GoogleMapProps['onZoomChanged'] = () => {
    console.log('Handling zoom changed:', googleMap?.getZoom());
    setCurrentZoom(googleMap?.getZoom());
  };

  const handleBoundsChange: GoogleMapProps['onBoundsChanged'] = () => {
    console.log('Handling bounds changed:', googleMap?.getBounds()?.toJSON());
    setCurrentBounds(googleMap?.getBounds());
  };

  const handleRegionClick =
    (region: RegionWithMetadata) => (e: google.maps.MapMouseEvent) => {
      if (e.latLng) zoomToRegion(region, e.latLng);
    };

  function zoomToRegion(
    region: RegionWithMetadata,
    infoWindowPosition: google.maps.LatLng,
  ) {
    if (selected?.id === region.id) {
      // If the currently selected region is clicked again, deselect it
      setSelected(null);
      return;
    }

    setSelected(region);
    setClickPosition(infoWindowPosition);

    if (currentZoom && currentZoom <= outlineThreshold) {
      const bounds = createBounds([region.outline]);
      googleMap?.fitBounds(bounds);
    }
  }

  function handleInfoWindowClose() {
    setSelected(null);
    setClickPosition(null);
  }

  function getOutlineCenter(georef: GeoreferencePartsFragment) {
    return calculateCenter([georef]);
  }

  const isGeoreferenceVisible = useCallback(
    (outline: GeoreferencePartsFragment) => {
      if (!debouncedBounds) return true;
      const center = getOutlineCenter(outline);
      return debouncedBounds.contains(center);
    },
    [debouncedBounds],
  );

  const handleRegionButtonClick = (region: RegionWithMetadata) => () => {
    const infoWindowPosition = getOutlineCenter(region.outline);
    zoomToRegion(region, infoWindowPosition);
  };

  const visibleRegions = useMemo(() => {
    return items.filter(region => {
      if (!region.outline) return true;
      return isGeoreferenceVisible(region.outline);
    });
  }, [items, isGeoreferenceVisible]);

  useEffect(() => {
    const itemIds = items.map(i => i.id);
    const prevItemIds = itemsRef.current.map(i => i.id);
    const difference = R.symmetricDifference(itemIds, prevItemIds);

    if (difference.length > 0) {
      const bounds = createBounds(
        items
          .filter(regionWithMeta => !!regionWithMeta.outline)
          .map(regionWithMeta => regionWithMeta.outline),
      );
      googleMap?.fitBounds(bounds);
    }

    itemsRef.current = items;
  }, [items, googleMap]);

  // This spinner breaks the marker clusterer from ever appearing
  // if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Regions/Basins</PageHeading>

      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '500px' }}
        mapTypeId={google.maps.MapTypeId.TERRAIN}
        onLoad={handleMapLoad}
        onZoomChanged={handleZoomChange}
        onBoundsChanged={handleBoundsChange}
      >
        {regions.length > 0 && (
          <MarkerClustererF maxZoom={outlineThreshold}>
            {clusterer => (
              <>
                {regions.map(r => (
                  <MarkerF
                    key={r.id}
                    position={r.center}
                    clusterer={clusterer}
                    icon={markerIcon()}
                    visible={(currentZoom ?? Infinity) <= outlineThreshold}
                    onClick={handleRegionClick(r)}
                  />
                ))}
              </>
            )}
          </MarkerClustererF>
        )}

        {regions.map(r => (
          <PolygonF
            key={r.id}
            path={r.outline.data}
            options={polygonOptions()}
            visible={(currentZoom ?? -Infinity) > outlineThreshold}
            onClick={handleRegionClick(r)}
          />
        ))}

        {selected && clickPosition && (
          <InfoWindowF
            position={clickPosition}
            onCloseClick={handleInfoWindowClose}
          >
            <>
              <Heading level={4}>{selected.name}</Heading>
              <Link to={regionRoute(selected.id)} className="link">
                View region &raquo;
              </Link>
            </>
          </InfoWindowF>
        )}
      </GoogleMap>

      <SpinnerPlaceholder show={loading} />

      <table className="table table-compact w-full mt-4">
        <thead>
          <tr>
            <th />
            <th>
              <SortTrigger
                colName="name"
                sortIndicatorProps={sortIndicatorProps}
              >
                Region Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="location.country"
                sortIndicatorProps={sortIndicatorProps}
                filterable
              >
                Country
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numOutcrops"
                sortIndicatorProps={sortIndicatorProps}
              >
                Outcrops
              </SortTrigger>
            </th>
          </tr>
        </thead>

        <tbody>
          {visibleRegions.map(region => (
            <tr key={region.id}>
              <td className="text-center">
                {region.outline && (
                  <Tooltip message="Show on map">
                    <Button
                      type="button"
                      color="ghost"
                      size="sm"
                      onClick={handleRegionButtonClick(region)}
                    >
                      <FontAwesomeIcon
                        icon={faMapMarker}
                        className={cn(
                          'text-xl',
                          region.id === selected?.id
                            ? 'text-error'
                            : 'text-muted',
                        )}
                      />
                    </Button>
                  </Tooltip>
                )}
              </td>
              <td>
                <Link to={regionRoute(region.id)}>{region.name}</Link>
              </td>
              <td>{region.location.country}</td>
              <td className="text-center">{region.numOutcrops}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
