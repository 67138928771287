import { Editor } from '@tinymce/tinymce-react';
import type { IProps as EditorProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import React, { forwardRef } from 'react';
import type { FormLabelProps } from '~/components/common/FormikField/FormLabel';
import { FormLabel } from '~/components/common/FormikField/FormLabel';

const toolbar = [
  'undo redo',
  'styleselect fontsizeselect forecolor',
  'bold italic underline',
  'justifyleft justifycenter justifyright',
  'bullist numlist',
  'link unlink',
  'image',
  'code removeformat',
];

const defaultConfig: EditorProps['init'] = {
  branding: false,
  promotion: false,
  plugins: 'link table code image',
  paste_as_text: true,
  toolbar: toolbar.join(' | '),
  convert_urls: false,
  browser_spellcheck: true,
  contextmenu: false,
  sandbox_iframes: false,
};

export type TinyMceInputProps = {
  name: string;
  value: string;
  onChange: (content: string) => void;
  isInvalid?: boolean;
  label?: FormLabelProps['label'];
  renderInput?: (input: JSX.Element) => JSX.Element;
  helpText?: string;
  config?: EditorProps['init'];
  required?: boolean;
};

export const TinyMceInput = forwardRef<Editor, TinyMceInputProps>(
  (
    {
      name,
      value,
      onChange,
      isInvalid,
      label = null,
      renderInput,
      helpText,
      config,
      required = false,
    },
    ref,
  ) => {
    const tinyMceConfig: EditorProps['init'] = {
      ...defaultConfig,
      ...config,
    };

    const input = (
      <Editor
        // apiKey="ba3lpruzbaxqh33vt7kx84joq8x2i3p7zto3iuve2o1e09ic"
        licenseKey="gpl"
        plugins={tinyMceConfig.plugins}
        initialValue={value}
        onBlur={e => onChange(e.target.getContent())}
        init={tinyMceConfig}
      />
    );

    return (
      <div>
        <FormLabel
          name={name}
          label={label}
          helpText={helpText}
          required={required}
        />
        {renderInput ? renderInput(input) : input}
        {isInvalid && (
          <label className="label">
            <span className="label-text-alt text-error">
              {/* <ErrorMessage name={name} /> */}
              {/* TODO inline error */}
            </span>
          </label>
        )}
      </div>
    );
  },
);
TinyMceInput.displayName = 'TinyMceInput';

export class TinyMceFieldWrapper extends React.Component<TinyMceInputProps> {
  state = {
    caught: false,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log('Caught an error in TinyMCE', error);
    console.log('Error info:', errorInfo);
    this.setState({ caught: true });
  }

  render() {
    if (this.state.caught) {
      return (
        <div>
          There was a problem rendering this field, please reload the page.
        </div>
      );
    }

    return <TinyMceInput {...this.props} />;
  }
}
