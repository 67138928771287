import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import {
  faExternalLink,
  faPencil,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { gql } from '~/apollo/client-v3';
import { otherWikiParts } from '~/apollo/fragments';
import type { UploadOtherListRouteQuery } from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { FilterSearch } from '~/components/common/FilterSearch';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { DeleteOtherWiki } from '~/components/upload/other/DeleteOtherWiki';
import { useSortFilter } from '~/hooks/data';
import {
  otherWikiDetailRoute,
  uploadOtherWikiCreateRoute,
  uploadOtherWikiUpdateRoute,
} from '~/paths';

export const OTHER_LIST = gql`
  query UploadOtherListRoute {
    otherList {
      ...otherWikiParts
    }
  }
  ${otherWikiParts}
`;

export default function UploadOtherWikiListRoute() {
  const { data, loading } = useQuery<UploadOtherListRouteQuery>(OTHER_LIST, {});

  const refetchQueries: [PureQueryOptions] = [{ query: OTHER_LIST }];

  const otherList = data?.otherList ?? [];
  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(otherList, 'title', 'title', 'uploadOtherList');

  function handleDeleteSuccess() {
    toast.info('Other wiki deleted successfully.');
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Other Articles</PageHeading>

      <Panel>
        <Panel.Heading className="flex justify-between align-center">
          <Panel.Title>
            Other Articles
            {!loading && (
              <small className="text-muted ml-2">
                (<strong>{items.length}</strong> results displayed)
              </small>
            )}
          </Panel.Title>

          <Link
            to={uploadOtherWikiCreateRoute()}
            className="btn btn-primary btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faPlus} /> Create Article
          </Link>
        </Panel.Heading>

        <Panel.Body>
          <FilterSearch {...filterSearchProps} />

          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="title" sortIndicatorProps={siProps}>
                    Title
                  </SortTrigger>
                </th>
                <th />
              </tr>
            </thead>

            <tbody>
              {items.map(article => (
                <tr key={article.id}>
                  <td>{article.id}</td>
                  <td>{article.title}</td>
                  <td className="text-right">
                    <div className="join">
                      <Link
                        to={uploadOtherWikiUpdateRoute(article.id)}
                        className="btn btn-ghost btn-sm gap-1 join-item"
                      >
                        <FontAwesomeIcon icon={faPencil} /> Edit
                      </Link>

                      <DropdownMenu color="ghost" size="sm" join>
                        <DropdownMenu.Items>
                          <DropdownMenu.Item>
                            <Link
                              to={otherWikiDetailRoute(article.id)}
                              target="_blank"
                            >
                              View Article{' '}
                              <FontAwesomeIcon icon={faExternalLink} />
                            </Link>
                          </DropdownMenu.Item>

                          <DeleteOtherWiki
                            id={article.id}
                            refetchQueries={refetchQueries}
                            onDeleteSuccess={handleDeleteSuccess}
                          >
                            {handleDelete => (
                              <Confirm onConfirm={handleDelete}>
                                {confirmDelete => (
                                  <DropdownMenu.Item>
                                    <a
                                      href="#delete-wiki"
                                      onClick={confirmDelete}
                                    >
                                      Delete
                                    </a>
                                  </DropdownMenu.Item>
                                )}
                              </Confirm>
                            )}
                          </DeleteOtherWiki>
                        </DropdownMenu.Items>
                      </DropdownMenu>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
