import { Field } from 'formik';
import { FormikField } from '~/components/common/FormikField';
import type { PictureParent } from '~/apollo/generated/v3/graphql';
import { OutcropTagField } from './OutcropTagField';
import { PictureTypeSelectFormik } from './PictureTypeSelectFormik';

interface Props {
  parentType: PictureParent;
  parentId: number;
}

export function PictureFormFields({ parentType, parentId }: Props) {
  return (
    <div className="space-y-1">
      <Field name="name" label="Name" component={FormikField} required />
      <Field
        name="description"
        label="Description"
        component={FormikField}
        type="textarea"
        required
      />
      <Field
        name="type"
        label="Type"
        component={FormikField}
        type={PictureTypeSelectFormik}
      />

      <Field name="author" label="Author" component={FormikField} />

      <Field name="scale" label="Scale" component={FormikField} />

      <Field name="comments" label="Comments" component={FormikField} />

      <Field
        name="priority"
        label="Priority"
        component={FormikField}
        type="number"
      />

      {parentType === 'STUDY' && (
        <Field
          name="outcropTagId"
          label="Outcrop"
          component={FormikField}
          type={OutcropTagField}
          studyId={parentId}
        />
      )}
    </div>
  );
}
