import * as R from 'ramda';
import type {
  Authority,
  BookmarkCollectionInput,
  BookmarkCollectionPartsFragment,
  BookmarkParentPartsFragment,
  BookmarkPartsFragment,
  BookmarkTargetPartsFragment,
  CompanyPartsFragment,
  PublicUserPartsFragment,
} from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
  Role,
} from '~/apollo/generated/v3/graphql';
import { defaultFilterMergeFn, defaultOptionsSortFn } from '~/hooks/data';
import * as routes from '~/paths';
import type {
  SupportObjectParentType,
  SupportObjectType,
} from '~/utils/modules/supportObject';
import { ucwords } from '~/utils/text';
import { yup } from '~/utils/validation';

export type Bookmark = BookmarkPartsFragment & {
  user: PublicUserPartsFragment;
  company?: CompanyPartsFragment | null;
  parent?: BookmarkParentPartsFragment | null;
  target?: BookmarkTargetPartsFragment | null;
  collections: BookmarkCollectionPartsFragment[];
};

export type BookmarkFormValuesCreate = {
  note: string;
  initialCollections: string[];
  newCollection: string;
};

export type BookmarkFormValues = {
  note: string;
};

export const initialBookmarkCreate = (): BookmarkFormValuesCreate => ({
  note: '',
  initialCollections: [],
  newCollection: '',
});

export const initialBookmark = (
  bookmark?: BookmarkPartsFragment | null,
): BookmarkFormValues => ({
  note: bookmark?.note ?? '',
});

export const bookmarkValidationSchema = yup.object({
  note: yup.string().label('note'),
  isCompany: yup.boolean().label('share with company'),
});

export const isCompanyBookmark = (bookmark: Pick<Bookmark, 'companyId'>) =>
  !R.isNil(bookmark.companyId);

export const isBookmarkOwner = (
  userId: number,
  bookmark: Pick<Bookmark, 'userId'>,
) => bookmark.userId === userId;

export type BookmarkFilterableType =
  `${BookmarkParentType}:${BookmarkTargetType}`;

export type StandardizedBookmark = Bookmark & {
  parentName: string;
  targetName: string;
  /** Composite of the parent + target as a single string that can be filtered on */
  filterableType: BookmarkFilterableType;
  hasNote: boolean;
  isInCollection: boolean;
};

function parentName(bookmark: Bookmark): string {
  const { parent } = bookmark;

  // If a linked entity was deleted it will resolve to null
  if (!parent?.__typename)
    return `Deleted ${prettyParentType(bookmark.parentType)}`;

  switch (parent.__typename) {
    case 'Outcrop':
    case 'Region':
    case 'Study':
    case 'LithostratFormation':
    case 'LithostratGroup':
    case 'LithostratMember':
    case 'Facies':
    case 'CrossSection':
    case 'SedimentaryLog':
    case 'WellLog':
    case 'Production':
    case 'ReservoirModel':
    case 'TrainingImage':
    case 'Variogram':
    case 'GigaPan':
    case 'SavedDataSearch':
      return parent.name;
    case 'PaleoMap':
      return parent.description ?? 'Palaeomap';
    case 'DepositionalWiki':
    case 'OtherWiki':
      return parent.title;
  }
}

export function targetName(
  bookmark: Pick<
    BookmarkPartsFragment,
    '__typename' | 'targetType' | 'parentType'
  > & {
    target?: BookmarkTargetPartsFragment | null;
  },
): string {
  const { target } = bookmark;

  // If a linked entity was deleted it will resolve to null
  if (!target?.__typename)
    return `Deleted ${prettyTargetType(
      bookmark.targetType,
      bookmark.parentType,
    )}`;

  switch (target.__typename) {
    case 'Outcrop':
    case 'Region':
    case 'Study':
    case 'VirtualOutcropModel':
    case 'Picture':
    case 'GigaPan':
    case 'LithostratFormation':
    case 'LithostratGroup':
    case 'LithostratMember':
    case 'SavedDataSearch':
      return target.name;
    case 'DepositionalWiki':
    case 'OtherWiki':
      return target.title;
  }
}

export function toStandardizedBookmark(
  bookmark: Bookmark,
): StandardizedBookmark {
  return {
    ...bookmark,
    parentName: parentName(bookmark),
    targetName: targetName(bookmark),
    filterableType: `${bookmark.parentType}:${bookmark.targetType}`,
    hasNote: !!bookmark.note,
    isInCollection: bookmark.collections.length > 0,
  };
}

export function prettyParentType(
  parentType: BookmarkParentType,
  targetType?: BookmarkTargetType,
): string {
  switch (parentType) {
    case BookmarkParentType.Outcrop:
    case BookmarkParentType.Region:
    case BookmarkParentType.Study:
    case BookmarkParentType.Formation:
    case BookmarkParentType.Group:
    case BookmarkParentType.Member:
    case BookmarkParentType.Facies:
    case BookmarkParentType.CrossSection:
    case BookmarkParentType.SedimentaryLog:
    case BookmarkParentType.WellLog:
    case BookmarkParentType.Production:
    case BookmarkParentType.ReservoirModel:
    case BookmarkParentType.TrainingImage:
    case BookmarkParentType.Variogram:
      return ucwords(parentType.toLowerCase());
    case BookmarkParentType.PaleoMap:
      return 'Palaeogeography Figure';
    case BookmarkParentType.DepositionalWiki:
      return 'Geology Wiki Article';
    case BookmarkParentType.OtherWiki:
      return 'Other Wiki Article';
    case BookmarkParentType.GigaPan:
      return 'Panorama';
    case BookmarkParentType.SavedDataSearch:
      return 'Bookmarked Data';
  }
}

export function prettyTargetType(
  targetType: BookmarkTargetType,
  parentType: BookmarkParentType,
): string {
  switch (targetType) {
    case BookmarkTargetType.Outcrop:
    case BookmarkTargetType.Region:
    case BookmarkTargetType.Study:
    case BookmarkTargetType.Formation:
    case BookmarkTargetType.Group:
    case BookmarkTargetType.Member:
      return ucwords(targetType.toLowerCase());
    case BookmarkTargetType.Picture:
      if (['OUTCROP', 'STUDY'].includes(parentType)) {
        return 'Picture';
      }
      // On SO pictures, show the parent type instead of 'picture'
      return prettyParentType(parentType, targetType);
    case BookmarkTargetType.Vom:
      return 'Virtual Outcrop';
    case BookmarkTargetType.GigaPan:
      return 'Panorama';
    case BookmarkTargetType.DepositionalWiki:
      return 'Geology Wiki Article';
    case BookmarkTargetType.OtherWiki:
      return 'Other Wiki Article';
    case BookmarkTargetType.SavedDataSearch:
      return 'Bookmarked Data';
  }
}

export type BookmarkCollectionFormValues = {
  name: string;
  description: string;
};

export function initialBookmarkCollection(
  collection?: Pick<BookmarkCollectionPartsFragment, 'name' | 'description'>,
): BookmarkCollectionFormValues {
  return {
    name: collection?.name ?? '',
    description: collection?.description ?? '',
  };
}

export function toBookmarkCollectionInput(
  values: BookmarkCollectionFormValues,
): BookmarkCollectionInput {
  const input: BookmarkCollectionInput = {
    name: values.name,
  };

  const description =
    typeof values.description === 'string' ? values.description.trim() : null;

  // Null out the description if it's empty
  if (description && description.length > 0) {
    input.description = description;
  } else {
    input.description = null;
  }

  return input;
}

export const bookmarkCollectionValidationSchema = yup.object({
  name: yup.string().min(1).required(),
  description: yup.string().nullable(),
});

export function soBookmarkPath(
  soParentType: SupportObjectParentType,
  soParentId: number,
  bmParentType: BookmarkParentType,
  outcropTagId: number | null | undefined,
): string {
  const useOutcropId = soParentType === 'outcrop' || !!outcropTagId;
  const ocId = outcropTagId ?? soParentId;

  if (bmParentType === 'OUTCROP') {
    return routes.outcropPicturesTabRoute(ocId);
  } else if (bmParentType === 'STUDY') {
    if (useOutcropId) return routes.outcropPicturesTabRoute(ocId);
    else return routes.studyPicturesTabRoute(soParentId);
  } else if (bmParentType === 'CROSS_SECTION') {
    if (useOutcropId) return routes.outcropCrossSectionsTabRoute(ocId);
    else return routes.studyCrossSectionsTabRoute(soParentId);
  } else if (bmParentType === 'SEDIMENTARY_LOG') {
    if (useOutcropId) return routes.outcropSedimentaryLogsTabRoute(ocId);
    else return routes.studySedimentaryLogsTabRoute(soParentId);
  } else if (bmParentType === 'FACIES') {
    if (useOutcropId) return routes.outcropFaciesTabRoute(ocId);
    else return routes.studyFaciesTabRoute(soParentId);
  } else if (bmParentType === 'WELL_LOG') {
    if (useOutcropId) return routes.outcropWellLogsTabRoute(ocId);
    else return routes.studyWellLogsTabRoute(soParentId);
  } else if (bmParentType === 'PRODUCTION') {
    if (useOutcropId) return routes.outcropProductionTabRoute(ocId);
    else return routes.studyProductionTabRoute(soParentId);
  } else if (bmParentType === 'RESERVOIR_MODEL') {
    if (useOutcropId) return routes.outcropReservoirModelsTabRoute(ocId);
    else return routes.studyReservoirModelsTabRoute(soParentId);
  } else if (bmParentType === 'TRAINING_IMAGE') {
    if (useOutcropId) return routes.outcropTrainingImagesTabRoute(ocId);
    else return routes.studyTrainingImagesTabRoute(soParentId);
  } else if (bmParentType === 'VARIOGRAM') {
    if (useOutcropId) return routes.outcropVariogramsTabRoute(ocId);
    else return routes.studyVariogramsTabRoute(soParentId);
  } else if (bmParentType === 'GIGA_PAN') {
    if (useOutcropId) return routes.outcropPanoramasTabRoute(ocId);
    else return routes.studyPanoramasTabRoute(soParentId);
  }

  throw new Error(`SO type ${bmParentType} not handled`);
}

export function soBookmarkParentType(
  soParentType: SupportObjectParentType,
  soType: SupportObjectType | 'picture' | 'gigaPan',
): BookmarkParentType {
  if (soType === 'picture') {
    return soParentType === 'outcrop'
      ? BookmarkParentType.Outcrop
      : BookmarkParentType.Study;
  }

  switch (soType) {
    case 'crossSection':
      return BookmarkParentType.CrossSection;
    case 'sedimentaryLog':
      return BookmarkParentType.SedimentaryLog;
    case 'facies':
      return BookmarkParentType.Facies;
    case 'wellLog':
      return BookmarkParentType.WellLog;
    case 'production':
      return BookmarkParentType.Production;
    case 'reservoirModel':
      return BookmarkParentType.ReservoirModel;
    case 'trainingImage':
      return BookmarkParentType.TrainingImage;
    case 'variogram':
      return BookmarkParentType.Variogram;
    case 'gigaPan':
      return BookmarkParentType.GigaPan;
    default:
      throw new Error(`Unsupported parent type ${soParentType}`);
  }
}

export function prettyFilterableType(
  filterable: BookmarkFilterableType,
): string {
  const [p, t] = filterable.split(':');
  return prettyTargetType(t as BookmarkTargetType, p as BookmarkParentType);
}

type RequiredAuthorityProps = {
  user: Pick<Authority['user'], 'id' | 'companyId'>;
  roles: Authority['roles'];
};

export function canEditBookmark(
  authority: RequiredAuthorityProps,
  bookmark: Bookmark,
) {
  const user = authority.user;
  const isAdmin = authority.roles.includes(Role.RoleAdmin);
  const isCompanyAdmin = authority.roles.includes(Role.RoleCompanyAdmin);

  // Admins may edit all bookmarks
  if (isAdmin) return true;

  // Users may edit bookmarks they created
  // This case also covers a user's own company bookmarks
  if (isBookmarkOwner(user.id, bookmark)) return true;

  if (isCompanyBookmark(bookmark)) {
    // Company admins may edit bookmarks within their company
    if (isCompanyAdmin && bookmark.companyId === user.companyId) {
      return true;
    }
  }

  return false;
}

export function isCompanyCollection(
  collection: BookmarkCollectionPartsFragment,
): boolean {
  return !R.isNil(collection.companyId);
}

export function isCollectionOwner(
  collection: BookmarkCollectionPartsFragment,
  user: RequiredAuthorityProps['user'],
): boolean {
  return collection.userId === user.id;
}

/** Tests whether the current user may edit the details of or delete a given collection */
export const canEditCollection = (
  authority: RequiredAuthorityProps,
  collection: BookmarkCollectionPartsFragment,
): boolean => {
  const user = authority.user;
  const isAdmin = authority.roles.includes(Role.RoleAdmin);
  const isCompanyAdmin = authority.roles.includes(Role.RoleCompanyAdmin);

  // Superadmins may edit all collections
  if (isAdmin) return true;

  // Users may edit their own collections (personal or company)
  if (isCollectionOwner(collection, user)) return true;

  if (isCompanyCollection(collection)) {
    // Company admins may edit company collections
    if (collection.companyId === user.companyId && isCompanyAdmin) {
      return true;
    }
  }

  return false;
};

/** Tests whether the current user may add a given bookmark to or remove a given bookmark from the given collection */
export const mayAddBookmarkToCollection = (
  authority: RequiredAuthorityProps,
  collection: BookmarkCollectionPartsFragment,
  bookmark: Bookmark,
): boolean => {
  const user = authority.user;
  const isAdmin = authority.roles.includes(Role.RoleAdmin);
  const isCompanyAdmin = authority.roles.includes(Role.RoleCompanyAdmin);

  if (isAdmin) return true;

  // Users can manage their own collections (personal or company)
  if (isCollectionOwner(collection, user)) return true;

  if (collection.companyId && collection.companyId === user.companyId) {
    // Users can add/remove their own bookmarks from collections in their company
    if (isBookmarkOwner(user.id, bookmark)) return true;

    // Company admins can add/remove any bookmarks from any collection within their company
    if (isCompanyAdmin) return true;
  }

  return false;
};

export const isAddableBookmarkInCollection = (
  authority: RequiredAuthorityProps,
  bookmark: Bookmark,
) => {
  for (const collection of bookmark.collections) {
    if (mayAddBookmarkToCollection(authority, collection, bookmark)) {
      return true;
    }
  }

  return false;
};

export function bookmarkTypeFilterMergeFn(column: string, value: string) {
  if (column === 'filterableType') {
    if (value === 'OUTCROP:PICTURE' || value === 'STUDY:PICTURE') {
      return 'PICTURE';
    }
  }
  return defaultFilterMergeFn(column, value);
}

export function bookmarkTypeOptionsSortFn(column: string, items: string[]) {
  if (column === 'filterableType') {
    const sortFn = (item: string) => {
      if (item === 'PICTURE') return 'Picture';
      return prettyFilterableType(item as BookmarkFilterableType);
    };

    const sorted = R.sortBy(sortFn, items);
    return sorted;
  }
  return defaultOptionsSortFn(column, items);
}
