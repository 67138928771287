import React from 'react';
import { envVars as env } from '~/environment';

// Redirect back to API endpoint to create session and set cookie for browser
export function buildAuthUrl(postLoginRedirect?: string | null): string {
  const callbackUrl = new URL('/api/v3/oauth/callback', env.VITE_CLIENT_URL);

  if (postLoginRedirect) {
    callbackUrl.searchParams.set('postLoginRedirect', postLoginRedirect);
  }

  const encodedRedirectUri = callbackUrl.toString();

  const authUrl = new URL(`https://${env.VITE_AUTH0_DOMAIN}/authorize`);
  authUrl.searchParams.set('response_type', 'code');
  authUrl.searchParams.set('client_id', env.VITE_AUTH0_CLIENT_ID);
  authUrl.searchParams.set('redirect_uri', encodedRedirectUri);
  authUrl.searchParams.set('audience', env.VITE_AUTH0_CLIENT_AUD);
  authUrl.searchParams.set('scope', 'openid profile email offline_access');
  return authUrl.toString();
}

type Props = {
  children?: JSX.Element;
  className?: string;
  postLoginRedirect?: string | null;
};
export function LoginButton({ children, className, postLoginRedirect }: Props) {
  return (
    <a href={buildAuthUrl(postLoginRedirect)} className={className}>
      {children ?? 'Log In'}
    </a>
  );
}
