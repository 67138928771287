import type { OtherWikiPartsFragment } from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export function translateType(wikiType: string | null) {
  // Check if wiki type has already been translated
  if (
    wikiType &&
    wikiType.length >= 6 &&
    wikiType.charAt(0) === wikiType.charAt(0).toUpperCase()
  ) {
    return wikiType
      .split('_')
      .map(item => item.charAt(0))
      .join('')
      .toLowerCase();
  }

  switch (wikiType) {
    case 'ae':
      return 'ARCHITECTURAL_ELEMENT';
    case 'de':
      return 'DEPOSITIONAL_ENVIRONMENT';
    case 'dse':
      return 'DEPOSITIONAL_SUB_ENVIRONMENT';
    case 'gde':
      return 'GROSS_DEPOSITIONAL_ENVIRONMENT';
    case 'bt':
      return 'BASIN_TYPE';
    case 'c':
      return 'CLIMATE';
    case 'ns':
    default:
      return 'NOT_SET';
  }
}

export type OtherWikiFormValues = {
  title: string;
  text: string;
};

export const initialOtherWiki = (
  ow?: OtherWikiPartsFragment,
): OtherWikiFormValues => ({
  title: ow?.title ?? '',
  text: ow?.text ?? '',
});

export const otherWikiValidationSchema = () =>
  yup.object().shape({
    title: yup.string().required(),
    text: yup.string().required(),
  });
