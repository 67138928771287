import type { ReportRegionPartsFragment } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Prose } from '~/components/common/Prose';

type Props = {
  region: ReportRegionPartsFragment;
};

export function ReportItemRegion({ region }: Props) {
  return (
    <>
      <Heading level={4} className="text-muted">
        {region.location.country}
      </Heading>

      <Prose dangerouslySetInnerHTML={{ __html: region.description }} />
    </>
  );
}
