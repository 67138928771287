import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import type { GeoreferenceImportInput } from '~/utils/geojson';
import { ConfirmGeoreferenceFormFields } from './ConfirmGeoreferenceFormFields';
import { StatusIcon } from './StatusIcon';

type ConfirmGeoreferenceFormValues = GeoreferenceImportInput & {
  name: string;
  description: string;
  dataType: string;
};

function initialValues(
  input: GeoreferenceImportInput,
): ConfirmGeoreferenceFormValues {
  return {
    ...input,
    name: input.name,
    description: input.description || '',
    dataType: input.dataType,
  };
}

type Props = {
  georeference: GeoreferenceImportInput;
  onSubmit: (georeference: GeoreferenceImportInput) => void;
  onRemove: (tempId: string) => void;
};

export function ConfirmGeoreferenceForm({
  georeference,
  onSubmit,
  onRemove,
}: Props) {
  const { isEditing } = georeference;
  const setIsEditing = (val: boolean) =>
    onSubmit({ ...georeference, isEditing: val });

  function handleSubmit(values: ConfirmGeoreferenceFormValues) {
    onSubmit({ ...values, isEditing: false });
  }

  function handleRemove() {
    if (window.confirm('Remove this georeference from being imported?')) {
      onRemove(georeference.tempId);
    }
  }

  if (isEditing) {
    return (
      <Panel>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues(georeference)}
        >
          <Form>
            <Panel.Body>
              <ConfirmGeoreferenceFormFields dataType={georeference.dataType} />
            </Panel.Body>
            <Panel.Footer className="flex justify-end gap-1">
              <Button
                type="button"
                color="ghost"
                size="sm"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" size="sm">
                Confirm
              </Button>
            </Panel.Footer>
          </Form>
        </Formik>
      </Panel>
    );
  }

  return (
    <Panel
      variant={
        georeference.uploadStatus === 'success'
          ? 'success'
          : georeference.uploadStatus === 'failed'
            ? 'error'
            : 'default'
      }
      className="mb-4"
    >
      <Panel.Body>
        <div className="grid grid-cols-12 gap-6">
          {georeference.uploadStatus !== null && (
            <div className="col-span-3 text-center pt-4">
              <StatusIcon uploadStatus={georeference.uploadStatus} />
            </div>
          )}

          <div
            className={
              georeference.uploadStatus === null ? 'col-span-12' : 'col-span-9'
            }
          >
            {georeference.uploadStatus === null && (
              <div className="float-right space-x-1">
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={() => setIsEditing(true)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </Button>
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={handleRemove}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            )}

            <Heading level={5}>{georeference.dataType}</Heading>
            <div>
              <strong>{georeference.name}</strong>
            </div>
            <div className="text-muted">{georeference.description}</div>
            <div className="clear-both" />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
}
