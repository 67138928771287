import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v3';
import type {
  BookmarkCollectionPartsFragment,
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';

const DELETE_COLLECTION = graphql(`
  mutation DeleteCollection($collectionId: Int!) {
    deleteBookmarkCollection(collectionId: $collectionId)
  }
`);

type Props = {
  children: (confirmDelete: () => void) => JSX.Element;
  collection: Pick<
    BookmarkCollectionPartsFragment,
    'id' | 'companyId' | 'isPinned' | 'isPinnedByCompany'
  >;
  onDeleteSuccess?: () => Promise<void>;
  refetchQueries: PureQueryOptions[];
};

export function DeleteBookmarkCollection({
  children,
  collection,
  refetchQueries,
}: Props) {
  const [deleteCollection] = useMutation<
    DeleteCollectionMutation,
    DeleteCollectionMutationVariables
  >(DELETE_COLLECTION, {
    variables: { collectionId: collection.id },
    refetchQueries,
  });

  const confirmTextPersonal = (
    <div className="space-y-2">
      {collection.isPinned && (
        <p className="font-bold">This collection is currently pinned!</p>
      )}
      <p>Are you sure you want to delete this collection?</p>
    </div>
  );
  const confirmTextCompany = (
    <div className="space-y-2">
      {collection.isPinnedByCompany && (
        <p className="font-bold">
          This collection is currently pinned by someone in your company!
        </p>
      )}
      <p>
        This collection will be removed for all users of your company. Existing
        bookmarks will not be deleted.
      </p>
      <p>Are you sure you want to delete this collection?</p>
    </div>
  );

  async function handleDelete() {
    try {
      await deleteCollection();
      toast.success('Collection deleted successfully.');
    } catch (err) {
      console.log('Error deleting bookmark collection', err);
      toast.error(
        'There was a problem deleting the collection. Please reload the page and try again.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text={collection.companyId ? confirmTextCompany : confirmTextPersonal}
      submitButtonColor="error"
      submitText="Delete Collection"
    >
      {onConfirm => children(onConfirm)}
    </Confirm>
  );
}
