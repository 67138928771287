import { cn } from '~/utils/common';
import type { NearestOutcropsQuery } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';

type Props = {
  disabled?: boolean;
  outcropId: string;
  nearestOutcrops: NearestOutcropsQuery['nearestOutcrops'];
  onOutcropClick: (outcropId: number) => void;
};

export function OutcropSuggestions({
  disabled,
  outcropId,
  nearestOutcrops,
  onOutcropClick,
}: Props) {
  function distanceText(distance: number) {
    if (distance < 1) {
      return Math.round(distance * 1000) + ' meters away';
    }

    return Math.round(Math.round(distance * 100) / 100) + ' km away';
  }

  const isSelected = (resultOcId: number) => String(resultOcId) === outcropId;

  return (
    <div>
      <Heading level={3}>Nearest Outcrops</Heading>
      <ul className="list-disc list-inside">
        {nearestOutcrops.map(result => (
          <li key={result.outcrop.id}>
            <button
              type="button"
              className={cn('space-x-2', {
                'btn-link': !disabled && !isSelected(result.outcrop.id),
                'text-muted': isSelected(result.outcrop.id),
              })}
              onClick={() => onOutcropClick(result.outcrop.id)}
              disabled={disabled || isSelected(result.outcrop.id)}
            >
              {result.outcrop.name}{' '}
              <span className="text-muted text-sm font-mono">
                ({distanceText(result.distance)})
              </span>
              {isSelected(result.outcrop.id) && (
                <EnabledIndicator value={true} />
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
