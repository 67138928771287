import { useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import { graphql } from '~/apollo/generated/v3';
import type { NavLinkProps } from '~/components/common/NavLink';
import { NavLink } from '~/components/common/NavLink';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { Badge } from '~/components/ui/badge';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';

const UPLOAD_OUTCROP_NAVIGATION = graphql(`
  query UploadOutcropNavigation($outcropId: Int!) {
    outcropList(id: $outcropId) {
      id
      keyParameters {
        id
      }
      virtualOutcropModels {
        id
      }
      paleoMaps {
        id
      }
      georeferences {
        id
      }
      startAge {
        id
      }
      endAge {
        id
      }
      studies {
        id
      }
      lithostratOutcropLinks {
        id
      }

      pictures {
        id
      }
      facies {
        id
      }
      crossSections {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      production {
        id
      }
      reservoirModels {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      fieldPictures {
        id
      }
      miniModels {
        id
      }
      photo360s {
        id
      }
      videos {
        id
      }
      supplementalDocuments {
        id
      }
      defaultMeasurementsView {
        id
      }
    }
  }
`);

export function CountBadge({
  disabled,
  count,
  onZero = 'hide',
}: {
  disabled?: boolean;
  count?: number;
  onZero?: 'hide' | 'error' | 'show';
}) {
  // Disabled is used for create mode, so we don't want to show counts at all
  if (disabled) {
    return null;
  }

  // Counts come through as undefined when the data is loading
  if (typeof count === 'undefined') {
    return <SpinnerIcon />;
  }

  if (onZero === 'hide' && !count) {
    return null;
  }

  return (
    <Badge
      color={onZero === 'error' && !count ? 'error' : 'ghost'}
      className="font-mono text-xs"
    >
      {count}
    </Badge>
  );
}

export function MenuNavLink({
  disabled,
  ...props
}: NavLinkProps & {
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <Menu.Item disabled={disabled}>
      <NavLink {...props} disabled={disabled} className="flex justify-between">
        {props.children}
      </NavLink>
    </Menu.Item>
  );
}

type Props = {
  outcropId: number;
  disabled?: boolean;
  isSidebarCollapsed?: boolean;
  setIsSidebarCollapsed?: (collapsed: boolean) => void;
};

export function UploadOutcropNavigation({
  outcropId,
  disabled = false,
  setIsSidebarCollapsed,
}: Props) {
  const { data } = useQuery(UPLOAD_OUTCROP_NAVIGATION, {
    variables: { outcropId },
  });

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  return (
    <div className="space-y-2">
      <Menu>
        <MenuNavLink
          disabled={disabled}
          to={routes.uploadOutcropUpdateRoute(outcropId)}
          end
        >
          Overview
        </MenuNavLink>

        <MenuNavLink
          disabled={disabled}
          to={routes.uploadOutcropUpdateAdditionalFieldsRoute(outcropId)}
        >
          Additional Fields
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateKeyParametersRoute(outcropId)}
          disabled={disabled}
        >
          Key Parameters
          <CountBadge
            disabled={disabled}
            count={outcrop?.keyParameters.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateVirtualOutcropsRoute(outcropId)}
          disabled={disabled}
        >
          Virtual outcrops
          <CountBadge
            disabled={disabled}
            count={outcrop?.virtualOutcropModels?.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdatePalaeogeographyRoute(outcropId)}
          disabled={disabled}
        >
          Palaeogeography
          <CountBadge
            disabled={disabled}
            count={outcrop?.paleoMaps?.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateGeoreferencesRoute(outcropId)}
          disabled={disabled}
        >
          Georeferences
          <CountBadge
            disabled={disabled}
            count={outcrop?.georeferences?.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateGeologicalAgeRoute(outcropId)}
          disabled={disabled}
          className="flex justify-between"
        >
          Age
          {!disabled && (
            <Badge
              color={!outcrop?.startAge && !outcrop?.endAge ? 'error' : 'ghost'}
              className="gap-1"
            >
              <EnabledIndicator value={!!outcrop?.startAge} />/
              <EnabledIndicator value={!!outcrop?.endAge} />
            </Badge>
          )}
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateStudiesRoute(outcropId)}
          disabled={disabled}
        >
          Studies
          <CountBadge
            disabled={disabled}
            count={outcrop?.studies.length}
            onZero="show"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateLithostratigraphyRoute(outcropId)}
          disabled={disabled}
        >
          Lithostratigraphy
          <CountBadge
            disabled={disabled}
            count={outcrop?.lithostratOutcropLinks.length}
            onZero="show"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateDmvRoute(outcropId)}
          disabled={disabled}
        >
          Measurements Page
          {outcrop?.defaultMeasurementsView && (
            <Badge color="ghost">
              <EnabledIndicator value={true} />
            </Badge>
          )}
        </MenuNavLink>
      </Menu>

      <Menu>
        <Menu.Title>
          <span className="cursor-default">Supporting Objects</span>
        </Menu.Title>

        <MenuNavLink
          to={routes.uploadOutcropUpdatePicturesRoute(outcropId)}
          disabled={disabled}
        >
          Pictures
          <CountBadge disabled={disabled} count={outcrop?.pictures.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateFaciesRoute(outcropId)}
          disabled={disabled}
        >
          Facies
          <CountBadge disabled={disabled} count={outcrop?.facies.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateCrossSectionsRoute(outcropId)}
          disabled={disabled}
        >
          Cross Sections
          <CountBadge
            disabled={disabled}
            count={outcrop?.crossSections.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateSedimentaryLogsRoute(outcropId)}
          disabled={disabled}
        >
          Sedimentary Logs
          <CountBadge
            disabled={disabled}
            count={outcrop?.sedimentaryLogs.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateWellLogsRoute(outcropId)}
          disabled={disabled}
        >
          Well logs
          <CountBadge disabled={disabled} count={outcrop?.wellLogs.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateProductionRoute(outcropId)}
          disabled={disabled}
        >
          Production
          <CountBadge disabled={disabled} count={outcrop?.production.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateReservoirModelsRoute(outcropId)}
          disabled={disabled}
        >
          Reservoir Models
          <CountBadge
            disabled={disabled}
            count={outcrop?.reservoirModels.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateTrainingImagesRoute(outcropId)}
          disabled={disabled}
        >
          Training Images
          <CountBadge
            disabled={disabled}
            count={outcrop?.trainingImages.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateVariogramsRoute(outcropId)}
          disabled={disabled}
        >
          Variograms
          <CountBadge disabled={disabled} count={outcrop?.variograms.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdatePanoramasRoute(outcropId)}
          disabled={disabled}
        >
          Panoramas
          <CountBadge disabled={disabled} count={outcrop?.gigaPans.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateFieldPicsRoute(outcropId)}
          disabled={disabled}
        >
          Field Pictures
          <CountBadge
            disabled={disabled}
            count={outcrop?.fieldPictures.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateMiniModelsRoute(outcropId)}
          disabled={disabled}
        >
          Mini-Models
          <CountBadge disabled={disabled} count={outcrop?.miniModels.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdatePhoto360sRoute(outcropId)}
          disabled={disabled}
        >
          360 Photos
          <CountBadge disabled={disabled} count={outcrop?.photo360s.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadOutcropUpdateVideosRoute(outcropId)}
          disabled={disabled}
        >
          Videos
          <CountBadge disabled={disabled} count={outcrop?.videos.length} />
        </MenuNavLink>
      </Menu>

      {!disabled && (
        <Menu>
          <Menu.Title>3D Content</Menu.Title>
          <Menu.Item>
            <NavLink
              to={routes.uploadOutcropSOPlacementsRoute(outcropId)}
              disabled={disabled}
              onClick={() => {
                if (setIsSidebarCollapsed) {
                  setIsSidebarCollapsed(true);
                }
              }}
            >
              SO Placements
            </NavLink>
          </Menu.Item>
        </Menu>
      )}

      {!disabled && (
        <Menu>
          <Menu.Title>
            <span className="cursor-default">Administration</span>
          </Menu.Title>
          <Menu.Item>
            <NavLink to={routes.outcropAuditLogsRoute(outcropId)}>
              Audit Logs
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink
              to={routes.uploadOutcropUpdateSupplementalDocumentsRoute(
                outcropId,
              )}
            >
              Supplemental Documents
              <CountBadge
                disabled={disabled}
                count={outcrop?.supplementalDocuments.length}
              />
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to={routes.deleteOutcropRoute(outcropId)}>
              Delete Outcrop
            </NavLink>
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
}

export default UploadOutcropNavigation;
