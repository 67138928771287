import { faMagnifyingGlass, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import type { AlphabetFilterProps } from '~/components/common/AlphabetFilter';
import { AlphabetFilter } from '~/components/common/AlphabetFilter';
import { Button } from '~/components/ui/button';
import { useDebounce } from '~/hooks/debounce';
import { cn } from '~/utils/common';

export interface FilterSearchProps {
  startsWithValue: string;
  includesValue: string;
  onStartsWithChange: (value: string) => any;
  onIncludesChange: (value: string) => any;
  alphabetFilterProps?: Partial<AlphabetFilterProps>;
  renderSearch?: (component: React.ReactNode) => React.ReactNode;
  stick?: boolean;
  showAlphabet?: boolean;
  searchHelpText?: ReactNode;
}

export function FilterSearch({
  startsWithValue,
  includesValue,
  onStartsWithChange,
  onIncludesChange,
  alphabetFilterProps,
  renderSearch,
  showAlphabet = true,
  searchHelpText,
}: FilterSearchProps) {
  // Some of the lists are pretty heavy (e.g. outcrop list) and typing gets laggy.
  // Let's keep the state local and debounce the value before flushing to the search
  const [inputValue, setInputValue] = useState('');
  const inputValueDb = useDebounce(inputValue, 300);
  useEffect(() => {
    onIncludesChange(inputValueDb);
  }, [onIncludesChange, inputValueDb]);

  const searchBox = (
    <label className="input w-full">
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <input
        type="text"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        placeholder="Search"
      />
      {inputValue.length ? (
        <Button
          type="button"
          onClick={() => setInputValue('')}
          color="ghost"
          size="xs"
          shape="circle"
        >
          <FontAwesomeIcon icon={faX} />
        </Button>
      ) : null}
    </label>
  );

  return (
    <div className={cn('space-y-2')}>
      {showAlphabet && (
        <AlphabetFilter
          value={startsWithValue}
          onFilterChange={onStartsWithChange}
          {...alphabetFilterProps}
        />
      )}
      {renderSearch ? (
        renderSearch(searchBox)
      ) : (
        <div className="space-y-0.5">
          <div className="lg:grid lg:grid-cols-12">
            <div className="lg:col-span-4 lg:col-start-5">{searchBox}</div>
          </div>
          {searchHelpText && (
            <div className="text-center text-xs text-muted italic">
              {searchHelpText}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
