import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { Field } from 'formik';
import React from 'react';
import type { BasinEnumsQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { InputGroup } from '~/components/common/InputGroup';

const BASIN_ENUM_OPTIONS = gql`
  query BasinEnums {
    basinType: basinEnumerations(type: BASIN_TYPE) {
      values
    }
    climate: basinEnumerations(type: CLIMATE) {
      values
    }
    terrestrialMarine: basinEnumerations(type: TERRESTRIAL_MARINE) {
      values
    }
    waterDepth: basinEnumerations(type: WATER_DEPTH) {
      values
    }
    accommodationRegime: basinEnumerations(type: ACCOMMODATION_REGIME) {
      values
    }
  }
`;

type Props = {
  disabled?: boolean;
};

export function BasinFormFields({ disabled }: Props) {
  const { data, loading } = useQuery<BasinEnumsQuery>(BASIN_ENUM_OPTIONS);

  function enumOptions(fieldName: keyof Omit<BasinEnumsQuery, '__typename'>) {
    const values = data?.[fieldName]?.values ?? [];
    return values.map((opt: string) => ({ value: opt, label: opt }));
  }

  const fieldDisabled = loading || disabled;

  return (
    <div className="space-y-2">
      <Field
        name="basin.name"
        label="Name"
        component={FormikField}
        disabled={fieldDisabled}
        required
      />

      <Field
        name="basin.description"
        label="Description"
        component={FormikField}
        disabled={fieldDisabled}
        type="textarea"
      />

      <Field
        name="basin.basinType"
        label="Basin Type"
        component={FormikField}
        disabled={fieldDisabled}
        type="select"
        options={enumOptions('basinType')}
        required
      />

      <Field
        name="basin.climate"
        label="Climate"
        component={FormikField}
        disabled={fieldDisabled}
        type="select"
        options={enumOptions('climate')}
        required
      />

      <Field
        name="basin.terrestrialMarine"
        label="Terrestrial/Marine"
        component={FormikField}
        disabled={fieldDisabled}
        type="select"
        options={enumOptions('terrestrialMarine')}
      />

      <Field
        name="basin.waterDepth"
        label="Water Depth"
        component={FormikField}
        disabled={fieldDisabled}
        type="select"
        options={enumOptions('waterDepth')}
      />

      <Field
        name="basin.catchmentArea"
        label="Catchment Area"
        component={FormikField}
        type="number"
        step="1"
        disabled={fieldDisabled}
        className="w-full join-item"
        renderInput={(input: React.ReactNode) => (
          <InputGroup>
            {input}
            <InputGroup.Addon right>km²</InputGroup.Addon>
          </InputGroup>
        )}
      />

      <Field
        name="basin.accommodationRegime"
        label="Accommodation Regime"
        component={FormikField}
        type="select"
        disabled={fieldDisabled}
        options={enumOptions('accommodationRegime')}
      />

      <Field
        name="basin.comments"
        label="Comments"
        component={FormikField}
        type="textarea"
        disabled={fieldDisabled}
      />
    </div>
  );
}
