import { NavLink } from '~/components/common/NavLink';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';

type Props = {
  /** Set to -1 to disable navigation */
  vomId: number;
  assetApproved?: boolean;
};

export function UpdateVomNavigation({ vomId, assetApproved = false }: Props) {
  const disabled = vomId < 0;

  return (
    <Menu>
      <Menu.Item disabled={disabled}>
        <NavLink
          to={routes.uploadVomUpdateRoute(vomId)}
          end
          disabled={disabled}
        >
          Description
        </NavLink>
      </Menu.Item>

      <Menu.Item disabled={disabled}>
        <NavLink
          to={routes.uploadVomUpdatePicturesRoute(vomId)}
          disabled={disabled}
        >
          Pictures
        </NavLink>
      </Menu.Item>

      <Menu.Item disabled={disabled}>
        <NavLink
          to={routes.uploadVomUpdateGeoreferencesRoute(vomId)}
          disabled={disabled}
        >
          Georeferences
        </NavLink>
      </Menu.Item>

      <Menu.Item disabled={disabled}>
        <NavLink
          to={routes.uploadVomUpdateInterpretationsRoute(vomId)}
          disabled={disabled}
        >
          Interpretations
        </NavLink>
      </Menu.Item>

      <Menu.Item disabled={disabled}>
        <NavLink
          to={routes.uploadVomUpdateVOSpecsRoute(vomId)}
          disabled={disabled}
        >
          VO Specs
        </NavLink>
      </Menu.Item>

      <Menu.Item disabled={disabled}>
        <NavLink
          to={routes.uploadVomUpdateModelDataRoute(vomId)}
          disabled={disabled}
        >
          Model Data
        </NavLink>
      </Menu.Item>
    </Menu>
  );
}
