import type { PureQueryOptions } from '@apollo/client';
import { faArrowTurnUp, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import type {
  LithostratLinkTargetType,
  LithostratStudyLinkPartsFragment,
  OutcropPartsFragment,
  StudyPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import {
  uploadOutcropUpdateLithostratigraphyRoute,
  uploadStudyUpdateLithostratigraphyRoute,
} from '~/paths';
import { CreateLithostratOutcropLink } from './CreateLithostratOutcropLink';
import { CreateLithostratStudyLink } from './CreateLithostratStudyLink';

type OutcropLinkAnalogue = OutcropPartsFragment;
type StudyLinkAnalogue = OutcropPartsFragment & {
  studies: Study[];
};
type Study = StudyPartsFragment & {
  lithostratStudyLinks?: LithostratStudyLinkPartsFragment[];
};

type Props = {
  targetType: LithostratLinkTargetType;
  targetId: number;
  outcropLinks: OutcropLinkAnalogue[];
  studyLinks: StudyLinkAnalogue[];
  refetchQueries: PureQueryOptions[];
};

export function ExampleManager({
  targetType: parentType,
  targetId,
  refetchQueries,
  outcropLinks,
  studyLinks,
}: Props) {
  const studiesWithLithostratLink = (studies: Study[]) =>
    studies.filter(s => {
      const sl = s.lithostratStudyLinks ?? [];
      const matchedLinks = sl.filter(
        link => link.targetType === parentType && link.targetId === targetId,
      );
      return matchedLinks.length > 0;
    });

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div>
        <Panel>
          <Panel.Heading className="flex justify-between items-center">
            <Panel.Title>Outcrop Links</Panel.Title>
            <CreateLithostratOutcropLink
              targetType={parentType}
              targetId={targetId}
              refetchQueries={refetchQueries}
            >
              {showModal => (
                <Button
                  type="button"
                  color="primary"
                  size="xs"
                  onClick={showModal}
                  startIcon={<FontAwesomeIcon icon={faLink} />}
                >
                  Create Outcrop Link
                </Button>
              )}
            </CreateLithostratOutcropLink>
          </Panel.Heading>
          <Panel.Body className="space-y-2">
            <p>
              Outcrop examples for this {parentType} linked directly to the
              outcrop. To remove a link, click the <b>outcrop name</b> to be
              taken to the outcrop's lithostratigraphy tab.
            </p>

            {!outcropLinks.length && (
              <div className="text-center text-muted">
                <em>No outcrops are linked to this {parentType}.</em>
              </div>
            )}
            {outcropLinks.map(oc => (
              <div key={oc.id}>
                <Link
                  to={uploadOutcropUpdateLithostratigraphyRoute(oc.id)}
                  className="link"
                >
                  <strong>{oc.name}</strong>
                </Link>
              </div>
            ))}
          </Panel.Body>
        </Panel>
      </div>

      <div>
        <Panel>
          <Panel.Heading className="flex justify-between items-center">
            <Panel.Title>Study Links</Panel.Title>
            <CreateLithostratStudyLink
              targetType={parentType}
              targetId={targetId}
              refetchQueries={refetchQueries}
            >
              {showModal => (
                <Button
                  type="button"
                  color="primary"
                  size="xs"
                  onClick={showModal}
                  startIcon={<FontAwesomeIcon icon={faLink} />}
                >
                  Create Study Link
                </Button>
              )}
            </CreateLithostratStudyLink>
          </Panel.Heading>
          <Panel.Body className="space-y-2">
            <p>
              Outcrops examples for this {parentType} created by a link from its
              corresponding study. To remove a link, click the <b>study name</b>{' '}
              to be taken to the study's lithostratigraphy tab.
            </p>

            {!studyLinks.length && (
              <div className="text-center text-muted">
                <em>No studies are linked to this {parentType}.</em>
              </div>
            )}
            {studyLinks.map(oc => (
              <div key={oc.id}>
                <Link
                  to={uploadOutcropUpdateLithostratigraphyRoute(oc.id)}
                  className="link"
                >
                  <strong>{oc.name}</strong>
                </Link>
                {studiesWithLithostratLink(oc.studies ?? []).map(s => (
                  <div key={s.id} className="pl-4">
                    <Link
                      to={uploadStudyUpdateLithostratigraphyRoute(s.id)}
                      className="text-sm"
                    >
                      <span className="text-muted">
                        <FontAwesomeIcon
                          icon={faArrowTurnUp}
                          className="rotate-90 mr-2"
                        />
                        Linked via study:{' '}
                      </span>
                      {s.name}
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
