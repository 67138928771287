import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type {
  FileParent,
  FilePartsFragment,
} from '~/apollo/generated/v3/graphql';

export const DELETE_FILE = gql`
  mutation DeleteFile($id: Int!, $parentId: Int!, $parentType: FileParent!) {
    deleteFile(id: $id, parentType: $parentType, parentId: $parentId)
  }
`;

type Props = {
  file: FilePartsFragment;
  parentId: number;
  parentType: FileParent;
  refetchQueries: PureQueryOptions[];
  onDeleteSuccess: () => void | Promise<void>;
};

export function SupportingObjectFileListItem({
  file,
  parentId,
  parentType,
  refetchQueries,
  onDeleteSuccess,
}: Props) {
  const [deleteFile, { loading }] = useMutation(DELETE_FILE, {
    variables: {
      parentId,
      parentType,
      id: file.id,
    },
    refetchQueries,
  });

  async function handleDelete() {
    const confirmText = 'This will permanently delete the selected file.';
    if (!window.confirm(confirmText)) return;

    try {
      await deleteFile();
      if (onDeleteSuccess) {
        await onDeleteSuccess();
      }
      toast.info('File deleted.');
    } catch (err) {
      console.log('Error deleting file', err);
      toast.error('There was a problem deleting the file. Please try again.');
    }
  }

  return (
    <div className="border flex justify-between gap-2 p-1">
      <div className="text-left break-all font-bold text-sm">{file.name}</div>

      <div className="shrink-0 text-right">
        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={handleDelete}
          disabled={loading}
          startIcon={<FontAwesomeIcon icon={faTrash} />}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
