import type { NavLinkProps as RRNavLinkProps } from 'react-router';
import { NavLink as RRNavLink } from 'react-router';

export type NavLinkProps = RRNavLinkProps & {
  disabled?: boolean;
};

export function NavLink({ disabled, onClick, ...navLinkProps }: NavLinkProps) {
  const handleClick: RRNavLinkProps['onClick'] = event => {
    if (disabled) {
      event.preventDefault();
    } else if (onClick) {
      onClick(event);
    }
  };

  return <RRNavLink {...navLinkProps} onClick={handleClick} />;
}
