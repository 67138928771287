import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { pollTilingMutation } from '~/components/upload/vom/modelData/states/UploadingState';
import { cn } from '~/utils/common';

const INTERVAL_DELAY = 1000 * 30;

export function ArchivingState({
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [pollTiling, { loading }] = useMutation(pollTilingMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  useEffect(() => {
    const interval = window.setInterval(pollTiling, INTERVAL_DELAY);
    pollTiling();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [cesiumAssetId, pollTiling]);

  return (
    <div className="text-center">
      <progress
        className={cn(
          'w-56 h-4',
          loading ? 'progress-neutral' : 'progress-success',
        )}
      />
      <div className="italic text-muted">Archiving...</div>
    </div>
  );
}
