import { useEffect, useRef } from 'react';
import { Modal } from '~/components/common/Modal';
import { Button, type ButtonProps } from '~/components/ui/button';
import { useModalState } from '~/hooks/modal';
import { useSafeState } from '~/hooks/state';

type ConfirmProps = {
  onConfirm: () => void | Promise<void>;
  title?: React.ReactNode;
  text?: React.ReactNode;
  submitText?: React.ReactNode;
  submitButtonColor?: ButtonProps['color'];
  cancelText?: React.ReactNode;
  showCancel?: boolean;
  children: (onConfirm: () => void) => React.ReactNode;
};

export function Confirm({
  onConfirm,
  title = 'Confirm',
  text = 'Are you sure you want to perform this action?',
  submitText = 'Confirm',
  cancelText = 'Cancel',
  showCancel = true,
  submitButtonColor,
  children,
}: ConfirmProps) {
  const { show, showModal, hideModal } = useModalState();
  const [disabled, setDisabled] = useSafeState(false);
  const isMountedRef = useRef(false);

  async function handleConfirm() {
    setDisabled(true);
    await onConfirm();
    // Sometimes this component is un-rendered before this fires (and triggers an error).
    // The effect below sets the function to null so that this won't be called.
    if (isMountedRef?.current) {
      hideModal();
      setDisabled(false);
    }
  }

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} closeable={!disabled}>
        <Modal.Body heading={title}>{text}</Modal.Body>

        <Modal.Footer>
          {showCancel && (
            <Button
              type="button"
              color="ghost"
              onClick={hideModal}
              disabled={disabled}
            >
              {cancelText}
            </Button>
          )}

          <Button
            type="button"
            color={submitButtonColor ?? 'primary'}
            onClick={handleConfirm}
            loading={disabled}
          >
            {submitText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
