import { Link } from 'react-router';
import type { SearchOutcropsOutcropResultsQuery } from '~/apollo/generated/v3/graphql';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { SortTrigger } from '~/components/common/SortTrigger';
import { outcropRoute, regionRoute } from '~/paths';

type Outcrop =
  SearchOutcropsOutcropResultsQuery['searchOutcrops']['outcrops'][number];

type Props = {
  outcrops: Outcrop[];
  sortIndicatorProps: SortIndicatorProps;
};

export function ListView({ outcrops, sortIndicatorProps }: Props) {
  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="name" sortIndicatorProps={sortIndicatorProps}>
              Outcrop
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="region.name"
              sortIndicatorProps={sortIndicatorProps}
              filterable
            >
              Region
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="region.location.country"
              sortIndicatorProps={sortIndicatorProps}
              filterable
            >
              Country
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        {outcrops.map((outcrop: Outcrop) => (
          <tr key={outcrop.id}>
            <td>
              <Link to={outcropRoute(outcrop.id)} className="link">
                {outcrop.name}
              </Link>
            </td>
            <td>
              {outcrop.region && (
                <Link to={regionRoute(outcrop.region.id)} className="link">
                  {outcrop.region.name}
                </Link>
              )}
            </td>
            <td>{outcrop.region?.location.country}</td>
            <td className="text-right whitespace-pre align-middle w-1/6 space-x-1">
              {outcrop.geologyType.map(gt => (
                <GeologyTypeIcon
                  key={gt}
                  geologyType={gt}
                  className="inline-block h-4"
                />
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
