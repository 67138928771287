import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type {
  CreateIssueCommentMutation,
  CreateIssueCommentMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Panel } from '~/components/common/Panel';
import { yup } from '~/utils/validation';

export const CREATE_ISSUE_COMMENT = gql`
  mutation CreateIssueComment($issueId: Int!, $comment: IssueCommentInput!) {
    createIssueComment(issueId: $issueId, comment: $comment) {
      id
    }
  }
`;

type Props = {
  issueId: number;
  refetchQueries: PureQueryOptions[];
};

export function CreateComment({ issueId, refetchQueries }: Props) {
  const [createIssueComment, { loading, error }] = useMutation<
    CreateIssueCommentMutation,
    CreateIssueCommentMutationVariables
  >(CREATE_ISSUE_COMMENT, {
    refetchQueries,
  });

  type FormValues = {
    content: string;
  };

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await createIssueComment({
        variables: {
          issueId,
          comment: {
            content: values.content,
          },
        },
      });
      helpers.resetForm();
      toast.success('Comment added successfully.');
    } catch (err) {
      console.log('Error creating comment', err);
      toast.error('There was a problem saving your comment. Please try again.');
    }
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Reply to this issue</Panel.Title>
      </Panel.Heading>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ content: '' }}
        validationSchema={yup.object({
          content: yup
            .string()
            .min(1, "can't be blank")
            .required("can't be blank"),
        })}
      >
        <Form>
          <Panel.Body className="space-y-4">
            <Field name="content" component={FormikField} type="textarea" />
            <FormErrors graphQLError={error} />
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" loading={loading}>
                Submit
              </Button>
            </div>
          </Panel.Footer>
        </Form>
      </Formik>
    </Panel>
  );
}
