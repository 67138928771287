import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import type {
  StudyFaciesRouteQuery,
  StudyFaciesRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { BookmarkParentType } from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { useStudyOutletContext } from '~/routes/study/$studyId';

const STUDY_FACIES_ROUTE = gql`
  query StudyFaciesRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      facies {
        ...faciesParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.faciesParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function StudyFaciesRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    StudyFaciesRouteQuery,
    StudyFaciesRouteQueryVariables
  >(STUDY_FACIES_ROUTE, { variables: { studyId } });

  const refetchQueries: PureQueryOptions[] = [
    { query: STUDY_FACIES_ROUTE, variables: { studyId } },
  ];

  const facies = data?.studyList.find(s => s.id === studyId)?.facies ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <SupportingObjectList
      items={facies}
      soParentType="study"
      soParentId={studyId}
      bookmarkParentType={BookmarkParentType.Facies}
      refetchQueries={refetchQueries}
    />
  );
}
