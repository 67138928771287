import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { STUDY_ENUMERATIONS } from '~/apollo/operations/study';

import { graphql } from '~/apollo/generated/v3';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UpdateStudyForm } from '~/components/upload/study/UpdateStudyForm';

const STUDY_OVERVIEW_TAB = graphql(`
  query UploadStudyUpdateOverviewTab($id: Int!) {
    studyList(id: $id) {
      ...studyParts
      dataHistory {
        ...dataHistoryParts
      }
      qualityParameters {
        ...studyQualityParametersParts
      }
      outcrops {
        id
        geologyType
      }
    }
  }
`);

export default function UploadStudyUpdateOverviewPage() {
  const params = useParams();
  invariant(params.studyId, 'studyId param required');
  const studyId = parseInt(params.studyId);

  const { data, loading } = useQuery(STUDY_OVERVIEW_TAB, {
    variables: { id: studyId },
  });

  const { data: enumsData, loading: loadingEnums } =
    useQuery(STUDY_ENUMERATIONS);

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);

  const isLoading = loading || loadingEnums;

  if (isLoading) return <SpinnerPlaceholder />;
  if (!study || !enumsData) return <NotFound />;

  return (
    <UpdateStudyForm studyId={studyId} study={study} enumOptions={enumsData} />
  );
}
