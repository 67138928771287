import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { Role } from '~/apollo/generated/v3/graphql';
import { useAuth } from '~/contexts/auth';
import { cn } from '~/utils/common';

export function PublishedContainer({
  published,
  children,
  strokeWidth = 'md',
  withIcon = false,
}: {
  published: boolean;
  children: ReactNode;
  strokeWidth?: 'sm' | 'md';
  withIcon?: boolean;
}) {
  const { hasAnyRole } = useAuth();

  // Only admins see published indicators
  if (!hasAnyRole([Role.RoleAdmin])) {
    return children;
  }

  return (
    <div
      className={cn({
        'border-2 p-1': !published && strokeWidth === 'sm',
        'border-4 p-2': !published && strokeWidth === 'md',
        'border-info border-dotted p-2': !published,
        relative: !published && withIcon,
      })}
    >
      {withIcon && !published && (
        <FontAwesomeIcon
          icon={faEyeSlash}
          className={cn('absolute top-2 right-2 opacity-75 text-info text-3xl')}
        />
      )}
      {children}
    </div>
  );
}
