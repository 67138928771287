import { useQuery } from '@apollo/client';
import invariant from 'tiny-invariant';
import type {
  VirtualOutcropDetailTabQuery,
  VirtualOutcropDetailTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { VomDetailPage } from '~/components/vom/VomDetailPage';
import { VIRTUAL_OUTCROP_DETAIL_TAB } from '~/routes/outcrop/$outcropId/virtual-outcrops/$vomId';
import { useGeologyWikiDetailOutletContext } from '~/routes/wiki/geology/$wikiId';

export default function GeologyWikiVirtualOutcropRoute() {
  const { wikiPage } = useGeologyWikiDetailOutletContext();
  const vomId = wikiPage.vomExampleId;
  invariant(vomId, "This wiki page doesn't have a linked VOM.");

  const { data, loading } = useQuery<
    VirtualOutcropDetailTabQuery,
    VirtualOutcropDetailTabQueryVariables
  >(VIRTUAL_OUTCROP_DETAIL_TAB, { variables: { vomId } });

  const vom = data?.virtualOutcropModelList.find(v => v.id === vomId);

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  return <VomDetailPage vom={vom} />;
}
