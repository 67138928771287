import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { graphql } from '~/apollo/generated/v3';
import type { UploadOutcropMiniModelsPageQueryVariables } from '~/apollo/generated/v3/graphql';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateMiniModelForm } from '~/components/supportingObject/urlBasedSO/CreateMiniModelForm';
import { MiniModelItem } from '~/components/supportingObject/urlBasedSO/MiniModelItem';
import { Alert } from '~/components/ui/alert';
import { useRouteParam } from '~/hooks/routing';

const UPLOAD_OUTCROP_MINI_MODELS_PAGE = graphql(`
  query UploadOutcropMiniModelsPage($id: Int!) {
    outcropList(id: $id) {
      id
      geologyType
      miniModels {
        ...miniModelParts
        placement {
          ...urlBasedSoListPlacementParts
        }
      }
    }
  }
`);

export default function UploadOutcropMiniModelsPage() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const [isCreating, setIsCreating] = useState(false);

  const queryVars = { id: outcropId };

  const { data, loading } = useQuery(UPLOAD_OUTCROP_MINI_MODELS_PAGE, {
    variables: queryVars,
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropMiniModelsPageQueryVariables>,
  ] = [{ query: UPLOAD_OUTCROP_MINI_MODELS_PAGE, variables: queryVars }];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);
  const miniModels = R.sortBy(mm => mm.name, outcrop?.miniModels ?? []);
  const awaitingApproval = miniModels.reduce(
    (acc, cur) => acc + (cur.readyForApproval ? 1 : 0),
    0,
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading>
          <button
            type="button"
            onClick={() => setIsCreating(!isCreating)}
            className="w-full flex justify-between gap-6 items-center"
          >
            <Panel.Title>Create Mini-Model</Panel.Title>
            <ExpandedIcon expanded={isCreating} />
          </button>
        </Panel.Heading>

        {isCreating && (
          <Panel.Body>
            <CreateMiniModelForm
              outcropId={outcropId}
              geologyTypes={outcrop.geologyType}
              refetchQueries={refetchQueries}
              onCreateSuccess={() => setIsCreating(false)}
            />
          </Panel.Body>
        )}
      </Panel>

      {!miniModels.length && <p>No mini-models created yet.</p>}

      <div className="space-y-4">
        {awaitingApproval > 0 && (
          <Alert status="warning">
            <FontAwesomeIcon icon={faFlag} /> {awaitingApproval} mini-models are
            ready for approval.
          </Alert>
        )}

        {miniModels.map(mm => (
          <MiniModelItem
            key={mm.id}
            outcropId={outcropId}
            miniModel={mm}
            geologyTypes={outcrop.geologyType}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>
    </div>
  );
}
