import { useQuery } from '@apollo/client';

import { OUTCROP_ENUMS_V3 } from '~/apollo/operations/outcrop';
import type { OutcropEnumerationsQuery } from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { CreateOutcropForm } from '~/components/upload/outcrop/CreateOutcropForm';
import UploadOutcropNavigation from './$outcropId/__UploadOutcropNavigation';

export default function UploadOutcropCreatePage() {
  useBreadcrumb('routes/upload/model.outcrop/create', 'Create Outcrop');

  const { data, loading } = useQuery<OutcropEnumerationsQuery>(
    OUTCROP_ENUMS_V3,
    {},
  );

  return (
    <>
      <PageHeading>Create Outcrop</PageHeading>
      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-2">
          <UploadOutcropNavigation outcropId={-1} disabled />
        </div>
        <div className="lg:col-span-10">
          {loading && <SpinnerPlaceholder />}
          {!loading && data && <CreateOutcropForm outcropEnums={data} />}
        </div>
      </div>
    </>
  );
}
