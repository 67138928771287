// Yup uses template string format sometimes for interpolating values internally
/* eslint-disable no-template-curly-in-string */
import invariant from 'tiny-invariant';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    // required: "${path} can't be empty",
    required: "can't be empty",

    notType(field) {
      switch (field.type) {
        case 'number':
          return `must be a number`;
        case 'string':
          return `can't be empty`;
        default:
          return `is invalid`;
      }
    },
  },
});

export { yup };

export function invariantNonNil<T>(
  value: T | undefined | null,
  message?: string,
): asserts value is NonNullable<T> {
  invariant(typeof value !== 'undefined' && value !== null, message);
}
