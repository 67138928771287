import { CURRENT_AUTHORITY } from '~/apollo/operations/auth';
import type {
  CurrentAuthorityQuery,
  Role,
} from '~/apollo/generated/v3/graphql';
import { apolloClient } from '~/apollo/client-v3';
import { envVars } from '~/environment';

type AuthorityResult = CurrentAuthorityQuery['currentAuthority'] | null;

export async function requireOptionalUser(): Promise<AuthorityResult> {
  const client = apolloClient;
  let authority: AuthorityResult = null;
  try {
    const res = await client.query<CurrentAuthorityQuery>({
      query: CURRENT_AUTHORITY,
    });
    authority = res.data?.currentAuthority;
  } catch (e) {
    // console.log('Error loading currentAuthority:', e);
  }

  return authority;
}

export async function requireAnyRole(
  allowedRoles?: Role[],
): Promise<AuthorityResult> {
  const authority = await requireOptionalUser();

  if (!authority) {
    throw new Response('unauthorized', { status: 401 });
  }

  if (!allowedRoles?.length) {
    // No roles needed, user just needs to be logged in.
    // We don't need to check for deleted/disabled because currentAuthority
    // responds with a null user if they aren't in the right state.
    return authority;
  }

  for (const userRole of authority.roles) {
    if (allowedRoles.includes(userRole)) {
      return authority;
    }
  }

  // throw forbidden({ authority, roles: allowedRoles });
  throw new Response('forbidden', { status: 403 });
}

export function logoutUrl() {
  const url = new URL(`https://${envVars.VITE_AUTH0_DOMAIN}/v2/logout`);
  url.searchParams.set('client_id', envVars.VITE_AUTH0_CLIENT_ID);
  url.searchParams.set(
    'returnTo', // returnTo, not return_to 🤷‍♀️
    encodeURI(`${envVars.VITE_CLIENT_URL}/api/v3/oauth/logout`),
  );
  return url.toString();
}
