import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { lithostratAgeParts, paleoMapParts } from '~/apollo/fragments';
import type {
  UpdatePaleoMapMutation,
  UpdatePaleoMapMutationVariables,
  UpdatePaleomapPageQuery,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { LithostratAgeFormFields } from '~/components/upload/lithostratAge/LithostratAgeFormFields';
import { PaleoMapFormFields } from '~/components/upload/paleomap/PaleoMapFormFields';
import type { PaleoMapFormValues } from '~/utils/modules/paleomap';
import {
  defaultPaleoMap,
  paleoMapValidationSchema,
} from '~/utils/modules/paleomap';

const UPDATE_PALEO_MAP = gql`
  mutation UpdatePaleoMap(
    $id: Int!
    $paleoMap: PaleoMapInput!
    $age: LithostratAgeInput!
  ) {
    updatePaleoMap(id: $id, paleoMap: $paleoMap, age: $age) {
      ...paleoMapParts
      age {
        ...lithostratAgeParts
      }
    }
  }

  ${paleoMapParts}
  ${lithostratAgeParts}
`;

type Props = {
  paleoMap: UpdatePaleomapPageQuery['paleoMapList'][number];
};

export function UpdatePaleoMap({ paleoMap }: Props) {
  const [updatePaleoMap, { loading, error }] = useMutation<
    UpdatePaleoMapMutation,
    UpdatePaleoMapMutationVariables
  >(UPDATE_PALEO_MAP, {});

  async function handleSubmit(values: PaleoMapFormValues) {
    try {
      await updatePaleoMap({
        variables: {
          id: paleoMap.id,
          paleoMap: values.paleoMap,
          age: values.age,
        },
      });
      toast.success('Paleomap updated successfully.');
    } catch (err) {
      console.log('Error updating paleomap', err);
      toast.error('There was a problem updating the paleomap.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultPaleoMap(paleoMap)}
      validationSchema={paleoMapValidationSchema()}
    >
      <Form className="space-y-6">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Details</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <PaleoMapFormFields />
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Lithostrat Age</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <LithostratAgeFormFields ageName="age" />
          </Panel.Body>
        </Panel>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
}
