import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Badge } from '~/components/ui/badge';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { gql } from '~/apollo/client-v3';
import type {
  DeleteLiteratureReferenceMutation,
  DeleteLiteratureReferenceMutationVariables,
  UploadStudyUpdateLiteratureTabQuery,
} from '~/apollo/generated/v3/graphql';
import { FileParent } from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Panel } from '~/components/common/Panel';
import { Button } from '~/components/ui/button';
import { SOFileList } from '~/components/upload/supportingObject/SOFileList';
import { uploadOutcropUpdateRoute } from '~/paths';
import { UpdateLiteratureForm } from './UpdateLiteratureForm';

export const DELETE_LITERATURE_REFERENCE = gql`
  mutation DeleteLiteratureReference($id: Int!) {
    deleteLiteratureReference(id: $id)
  }
`;

type Props = {
  studyId: number;
  literature: UploadStudyUpdateLiteratureTabQuery['studyList'][number]['literatureReferences'][number];
  refetchQueries: PureQueryOptions[];
};

export function StudyLiteratureListItem({
  studyId,
  literature,
  refetchQueries,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [deleteLiterature, { loading }] = useMutation<
    DeleteLiteratureReferenceMutation,
    DeleteLiteratureReferenceMutationVariables
  >(DELETE_LITERATURE_REFERENCE, {
    variables: { id: literature.id },
    refetchQueries,
  });

  const toggleIsEditing = () => setIsEditing(!isEditing);

  async function handleDelete() {
    try {
      await deleteLiterature();
      toast.success('Literature deleted successfully.');
    } catch (err) {
      console.log('Error deleting literature', err);
      toast.error(
        'There was a problem deleting the literature reference. It may have already been deleted.',
      );
    }
  }

  const editButton = (
    <Button
      type="button"
      color="primary"
      size="xs"
      onClick={toggleIsEditing}
      className="gap-1"
    >
      <FontAwesomeIcon icon={faPencil} /> Edit
    </Button>
  );

  const cancelDeleteButton = (
    <div className="space-x-1">
      <Confirm
        onConfirm={handleDelete}
        text="The selected literature reference will be permanently deleted."
      >
        {showConfirm => (
          <Button
            type="button"
            color="error"
            size="xs"
            onClick={showConfirm}
            disabled={loading}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            Delete
          </Button>
        )}
      </Confirm>

      <Button
        type="button"
        color="ghost"
        size="xs"
        onClick={toggleIsEditing}
        disabled={loading}
      >
        Cancel
      </Button>
    </div>
  );

  const literatureDetails = (
    <div>
      <table className="text-left">
        <tbody>
          <tr>
            <th>Title</th>
            <td>{literature.title}</td>
          </tr>
          <tr>
            <th>Author</th>
            <td>{literature.author}</td>
          </tr>
          <tr>
            <th>Comments</th>
            <td>{literature.comments}</td>
          </tr>
          <tr>
            <th>Digital Object Identifier</th>
            <td>{literature.digitalObjectIdentifier}</td>
          </tr>
          <tr>
            <th>Publication Source</th>
            <td>{literature.publicationSource}</td>
          </tr>
          <tr>
            <th>Publication Type</th>
            <td>{literature.publicationType}</td>
          </tr>
          <tr>
            <th>Web Source</th>
            <td>{literature.webSource}</td>
          </tr>
          <tr>
            <th>Year</th>
            <td>{literature.year}</td>
          </tr>
          <tr>
            <th>Priority</th>
            <td>{literature.priority ?? '--'}</td>
          </tr>
          {literature.outcropTag && (
            <tr>
              <th>Outcrop Tag</th>
              <td>
                <Link
                  to={uploadOutcropUpdateRoute(literature.outcropTag.id)}
                  target="_blank"
                  className="link"
                >
                  {literature.outcropTag.name}
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="grid lg:grid-cols-2 gap-6">
        <div>
          <SOFileList
            parentType={FileParent.LiteratureReference}
            parentId={literature.id}
            files={literature.files ?? []}
            refetchQueries={refetchQueries}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          {literature.title}{' '}
          <Badge color="ghost" className="text-sm font-mono">
            {literature.id}
          </Badge>
        </Panel.Title>
        {isEditing ? cancelDeleteButton : editButton}
      </Panel.Heading>
      <Panel.Body>
        {isEditing ? (
          <UpdateLiteratureForm
            studyId={studyId}
            literature={literature}
            onUpdateSuccess={() => setIsEditing(false)}
            refetchQueries={refetchQueries}
          />
        ) : (
          literatureDetails
        )}
      </Panel.Body>
    </Panel>
  );
}
