import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Button, type ButtonProps } from '~/components/ui/button';
import { cn } from '~/utils/common';

type DropdownMenuProps = {
  children: React.ReactNode;
  label?: React.ReactNode;
  join?: boolean;
} & ButtonProps;

export function DropdownMenu({
  children,
  label,
  join = false,
  ...buttonProps
}: DropdownMenuProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          as={Button}
          {...buttonProps}
          className={cn('gap-1', { 'join-item': join })}
        >
          <div className="flex gap-1 justify-end items-center">
            <div>{label}</div>
            <div>
              <FontAwesomeIcon icon={faCaretDown} aria-hidden="true" />
            </div>
          </div>
        </Menu.Button>
      </div>
      {children}
    </Menu>
  );
}

type DropdownMenuItemsProps = {
  children: React.ReactNode;
};

function DropdownMenuItems({ children }: DropdownMenuItemsProps) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-hidden">
        <div className="py-1">{children}</div>
      </Menu.Items>
    </Transition>
  );
}

DropdownMenu.Items = DropdownMenuItems;

type DropdownMenuItemProps = {
  children: JSX.Element;
  disabled?: boolean;
};

function DropdownMenuItem({ children, disabled }: DropdownMenuItemProps) {
  return (
    <Menu.Item disabled={disabled}>
      {({ active }) =>
        React.cloneElement(children, {
          className: cn(
            children.props.className,
            'block px-4 py-2 text-sm w-full text-left',
            {
              'bg-slate-100 text-slate-900': active,
              'text-slate-700': !active,
            },
          ),
        })
      }
    </Menu.Item>
  );
}

DropdownMenu.Item = DropdownMenuItem;
