import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import type {
  CreateOtherWikiMutation,
  CreateOtherWikiMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { OtherWikiFormFields } from '~/components/upload/other/OtherWikiFormFields';
import { uploadOtherWikiUpdateRoute } from '~/paths';
import type { OtherWikiFormValues } from '~/utils/modules/wiki';
import {
  initialOtherWiki,
  otherWikiValidationSchema,
} from '~/utils/modules/wiki';

export const CREATE_OTHER_WIKI = gql`
  mutation CreateOtherWiki($other: OtherWikiInput!) {
    createOtherWiki(other: $other) {
      id
    }
  }
`;

export default function CreateOtherWikiRoute() {
  const navigate = useNavigate();
  useBreadcrumb('routes/upload/other/create', 'Create Other Wiki');

  const [createOtherWiki, { loading, error }] = useMutation<
    CreateOtherWikiMutation,
    CreateOtherWikiMutationVariables
  >(CREATE_OTHER_WIKI, {});

  async function handleSubmit(values: OtherWikiFormValues) {
    try {
      const res = await createOtherWiki({
        variables: {
          other: values,
        },
      });
      const wikiPageId = res.data?.createOtherWiki.id;
      if (!wikiPageId) throw new Error('Error parsing response body');
      toast.success('Wiki page created successfully.');
      navigate(uploadOtherWikiUpdateRoute(wikiPageId));
    } catch (err) {
      console.log('Error creating wiki page', err);
      toast.error(
        'There was a problem creating the wiki page. Please try again.',
      );
    }
  }

  return (
    <>
      <PageHeading>Create Other Wiki</PageHeading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialOtherWiki()}
        validationSchema={otherWikiValidationSchema}
      >
        <Form>
          <Panel>
            <Panel.Heading>
              <Panel.Title>Create Other Wiki</Panel.Title>
            </Panel.Heading>

            <Panel.Body className="space-y-4">
              <OtherWikiFormFields />
              <FormErrors graphQLError={error} />
            </Panel.Body>

            <Panel.Footer className="text-right">
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </Form>
      </Formik>
    </>
  );
}
