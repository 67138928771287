import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Button } from '~/components/ui/button';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import type { UpdateProfileFormValues } from '~/utils/modules/user';

type ToggleShowProps = {
  show: boolean;
  onClick: () => void;
};

function ToggleShow({ show, onClick }: ToggleShowProps) {
  return (
    <Button variant="link" type="button" onClick={onClick}>
      {show ? 'Cancel' : 'Change My Username'}
    </Button>
  );
}

type Props = {
  currentUsername: string;
  companyMails: string[];
  disabled: boolean;
};

export function UpdateUsernameField({
  currentUsername,
  companyMails,
  disabled,
}: Props): JSX.Element {
  const [show, setShow] = useState(false);
  const { values, setFieldValue, setValues } =
    useFormikContext<UpdateProfileFormValues>();

  function resetUsername() {
    const [prefix, suffix] = currentUsername.split('@');
    setValues({
      ...values,
      username: currentUsername,
      usernamePrefix: prefix,
      usernameSuffix: `@${suffix}`,
    });
  }

  function toggleShow() {
    setShow(val => !val);
    resetUsername();
  }

  useEffect(() => {
    setFieldValue(
      'username',
      `${values.usernamePrefix}${values.usernameSuffix}`,
    );
  }, [values.usernamePrefix, values.usernameSuffix, setFieldValue]);

  const currentDomain = `@${currentUsername.split('@')[1]}`;
  const suffixOptions = R.pipe(
    R.prepend(currentDomain),
    R.uniq,
    R.map(mail => ({ value: mail, label: mail })),
  )(companyMails);

  if (!show) {
    return <ToggleShow show={show} onClick={toggleShow} />;
  }

  return (
    <div className="m-6 bg-base-200 space-y-2 p-4">
      {!disabled && (
        <div className="float-right">
          {' '}
          <ToggleShow show={show} onClick={toggleShow} />
        </div>
      )}

      <Heading level={3} className="p-0 m-0 mt-0">
        New Username
      </Heading>

      <p>
        If you would like to update your username, enter a new one and choose an
        email domain from the list that is authorized by your company.
      </p>
      <p>
        After saving this form, you&apos;ll receive an email at the selected
        address to confirm your new username.
      </p>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <Field
            name="usernamePrefix"
            label="Email"
            component={FormikField}
            required={show}
            disabled={disabled}
          />
        </div>
        <div>
          <Field
            name="usernameSuffix"
            label="Email Domain"
            component={FormikField}
            type="select"
            options={suffixOptions}
            required={show}
            disabled={disabled}
          />
        </div>
      </div>

      {values.username !== currentUsername && (
        <div className="text-center">
          Your new username to log in to SafariDB will be:
          <br />
          <strong>{values.username}</strong>
        </div>
      )}
    </div>
  );
}
