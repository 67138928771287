import { useQuery } from '@apollo/client';
import { Badge } from '~/components/ui/badge';
import { gql } from '~/apollo/client-v3';
import type {
  UploadStudyNavigationQuery,
  UploadStudyNavigationQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { NavLink } from '~/components/common/NavLink';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';
import {
  CountBadge,
  MenuNavLink,
} from '~/routes/upload/model/outcrop/$outcropId/__UploadOutcropNavigation';

const UPLOAD_STUDY_NAVIGATION = gql`
  query UploadStudyNavigation($studyId: Int!) {
    studyList(id: $studyId) {
      id
      outcrops {
        id
      }
      keyParameters {
        id
      }
      georeferences {
        id
      }
      architecturalElements {
        id
        measurementCount
      }
      lithostratStudyLinks {
        id
      }

      literatureReferences {
        id
      }

      pictures {
        id
      }
      facies {
        id
      }
      crossSections {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      production {
        id
      }
      reservoirModels {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      supplementalDocuments {
        id
      }
      defaultMeasurementsView {
        id
      }
    }
  }
`;

type Props = {
  studyId: number;
  disabled?: boolean;
};

export function UploadStudyNavigation({ studyId, disabled = false }: Props) {
  const { data } = useQuery<
    UploadStudyNavigationQuery,
    UploadStudyNavigationQueryVariables
  >(UPLOAD_STUDY_NAVIGATION, {
    variables: { studyId },
  });

  const study = data?.studyList.find(s => s.id === studyId);
  const measurementCount =
    study?.architecturalElements
      .map(ae => ae.measurementCount)
      .reduce((acc, cur) => acc + cur, 0) ?? 0;

  return (
    <div className="space-y-2">
      <Menu>
        <MenuNavLink
          to={routes.uploadStudyUpdateRoute(studyId)}
          end
          disabled={disabled}
        >
          Overview
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateOutcropsRoute(studyId)}
          disabled={disabled}
        >
          Outcrop Links
          <CountBadge
            disabled={disabled}
            count={study?.outcrops.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateKeyParametersRoute(studyId)}
          disabled={disabled}
        >
          Key Parameters
          <CountBadge
            disabled={disabled}
            count={study?.keyParameters.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateGeoreferencesRoute(studyId)}
          disabled={disabled}
        >
          Georeferences
          <CountBadge
            disabled={disabled}
            count={study?.georeferences.length}
            onZero="error"
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateAEsRoute(studyId)}
          disabled={disabled}
        >
          Arch. Elems/
          <br />
          Measurements
          {!disabled && (
            <Badge color="ghost" className="font-mono">
              {study?.architecturalElements.length}/<br />
              {measurementCount}
            </Badge>
          )}
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateLithostratigraphyRoute(studyId)}
          disabled={disabled}
        >
          Lithostratigraphy
          <CountBadge
            disabled={disabled}
            count={study?.lithostratStudyLinks.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyDmvRoute(studyId)}
          disabled={disabled}
        >
          Measurements Page
          {study?.defaultMeasurementsView && (
            <Badge color="ghost">
              <EnabledIndicator value={true} />
            </Badge>
          )}
        </MenuNavLink>
      </Menu>

      <Menu>
        <Menu.Title>
          <span>Supporting Objects</span>
        </Menu.Title>

        <MenuNavLink
          to={routes.uploadStudyUpdatePicturesRoute(studyId)}
          disabled={disabled}
        >
          Pictures
          <CountBadge disabled={disabled} count={study?.pictures.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateFaciesRoute(studyId)}
          disabled={disabled}
        >
          Facies
          <CountBadge disabled={disabled} count={study?.facies.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateCrossSectionsRoute(studyId)}
          disabled={disabled}
        >
          Cross Sections
          <CountBadge disabled={disabled} count={study?.crossSections.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateSedimentaryLogsRoute(studyId)}
          disabled={disabled}
        >
          Sedimentary Logs
          <CountBadge
            disabled={disabled}
            count={study?.sedimentaryLogs.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateWellLogsRoute(studyId)}
          disabled={disabled}
        >
          Well logs
          <CountBadge disabled={disabled} count={study?.wellLogs.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateProductionRoute(studyId)}
          disabled={disabled}
        >
          Production
          <CountBadge disabled={disabled} count={study?.production.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateLiteratureRoute(studyId)}
          disabled={disabled}
        >
          Literature
          <CountBadge
            disabled={disabled}
            count={study?.literatureReferences.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateReservoirModelsRoute(studyId)}
          disabled={disabled}
        >
          Reservoir Models
          <CountBadge
            disabled={disabled}
            count={study?.reservoirModels.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateTrainingImagesRoute(studyId)}
          disabled={disabled}
        >
          Training Images
          <CountBadge
            disabled={disabled}
            count={study?.trainingImages.length}
          />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdateVariogramsRoute(studyId)}
          disabled={disabled}
        >
          Variograms
          <CountBadge disabled={disabled} count={study?.variograms.length} />
        </MenuNavLink>

        <MenuNavLink
          to={routes.uploadStudyUpdatePanoramasRoute(studyId)}
          disabled={disabled}
        >
          Panoramas
          <CountBadge disabled={disabled} count={study?.gigaPans.length} />
        </MenuNavLink>
      </Menu>

      <Menu>
        <Menu.Title>
          <span>Administration</span>
        </Menu.Title>

        <MenuNavLink
          to={routes.uploadStudyUpdateAccessRoute(studyId)}
          disabled={disabled}
        >
          Access
        </MenuNavLink>

        {/* Hide these entirely when "disabled" (i.e. create-mode) */}
        {!disabled && (
          <>
            <Menu.Item>
              <NavLink to={routes.studyAuditLogsRoute(studyId)}>
                Audit Logs
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to={routes.mergeStudyRoute(studyId)}>
                Merge Study
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink
                to={routes.uploadStudySupplementalDocumentsRoute(studyId)}
              >
                Supplemental Documents
                <CountBadge
                  disabled={disabled}
                  count={study?.supplementalDocuments.length}
                />
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              <NavLink to={routes.deleteStudyRoute(studyId)}>
                Delete Study
              </NavLink>
            </Menu.Item>
          </>
        )}
      </Menu>
    </div>
  );
}
