import { z } from 'zod';
import type { PagePartsFragment } from '~/apollo/generated/v3/graphql';

export type PageFormValues = {
  pageName: string;
  title: string;
  content: string;
  secured: boolean;
};

export function pageFormValues(page?: PagePartsFragment): PageFormValues {
  return {
    pageName: page?.pageName ?? '',
    title: page?.title ?? '',
    content: page?.content ?? '',
    secured: page?.secured ?? false,
  };
}

export const pageSchema = z.object({
  pageName: z
    .string()
    .min(1)
    .regex(
      /^[\w\d-_]+$/,
      'Must only contain alphanumeric characters, dashes, and underscores',
    ),
  title: z.string().min(1),
  content: z.string(),
  secured: z.boolean(),
});
