import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { Link, useMatches } from 'react-router';
import * as fragments from '~/apollo/fragments';
import type {
  DepositionalHierarchyQuery,
  DepositionalHierarchyQueryVariables,
  GeologyWikisForMenuQuery,
  GrossDepositionalEnvironmentHierarchyItem,
} from '~/apollo/generated/v3/graphql';
import { WikiMenuGroup } from '~/components/wiki/WikiNavigation/WikiMenuGroup';
import { geologyWikiOverviewRoute, geologyWikiPageRoute } from '~/paths';

type GeologyType = 'clastic' | 'carbonate';

const HIERARCHY_QUERY = gql`
  query GeologyHierarchy {
    depositionalHierarchyFull {
      clastic {
        grossDepositionalEnvironment {
          name
        }
      }

      carbonate {
        grossDepositionalEnvironment {
          name
          depositionalEnvironment {
            name
          }
        }
      }
    }
  }
`;

const GEOLOGY_WIKIS_QUERY = gql`
  query GeologyWikisForMenu {
    depositionalList {
      ...depositionalWikiParts
    }
  }

  ${fragments.depositionalWikiParts}
`;

type GdeLink = {
  name: string;
  route: string;
};

export function GeologyWikiNavMenu() {
  const matches = useMatches();
  const onGeologyPage = !!matches.find(m => m.id === 'routes/wiki/geology');

  const { data: hierarchyData } = useQuery<
    DepositionalHierarchyQuery,
    DepositionalHierarchyQueryVariables
  >(HIERARCHY_QUERY, {});

  const { data: geologyWikisData } = useQuery<GeologyWikisForMenuQuery>(
    GEOLOGY_WIKIS_QUERY,
    {},
  );

  const geologyWikis = geologyWikisData?.depositionalList ?? [];

  const clasticGdes =
    hierarchyData?.depositionalHierarchyFull.clastic
      .grossDepositionalEnvironment ?? [];
  const carbonateGdes =
    hierarchyData?.depositionalHierarchyFull.carbonate
      .grossDepositionalEnvironment ?? [];

  const findWiki = (gdeName: string, geologyType: GeologyType) =>
    geologyWikis.find(
      w => (w.value ?? []).includes(gdeName) && w.geologyType === geologyType,
    );

  const mapGdeLink =
    (geologyType: GeologyType) =>
    (gde: GrossDepositionalEnvironmentHierarchyItem) => {
      const wiki = findWiki(gde.name, geologyType);
      if (wiki) {
        return {
          name: gde.name,
          route: geologyWikiPageRoute(wiki.id),
        };
      }
      return null;
    };

  const filterNulls = (gdeLink: GdeLink | null): gdeLink is GdeLink =>
    gdeLink !== null;

  const clasticGdeLinks: GdeLink[] = clasticGdes
    .map(mapGdeLink('clastic'))
    .filter(filterNulls);

  const carbonateGdeLinks: GdeLink[] = carbonateGdes
    .map(mapGdeLink('carbonate'))
    .filter(filterNulls);

  return (
    <WikiMenuGroup
      label="Geology"
      href={geologyWikiOverviewRoute()}
      sectionHeading
      defaultExpanded={onGeologyPage}
    >
      {clasticGdeLinks.length > 0 && (
        <WikiMenuGroup label="Clastic">
          {clasticGdeLinks.map(({ name, route }) => (
            <li key={name}>
              <Link to={route} className="link">
                {name}
              </Link>
            </li>
          ))}
        </WikiMenuGroup>
      )}

      {carbonateGdeLinks.length > 0 && (
        <WikiMenuGroup label="Carbonate">
          {carbonateGdeLinks.map(({ name, route }) => (
            <li key={name}>
              <Link to={route} className="link">
                {name}
              </Link>
            </li>
          ))}
        </WikiMenuGroup>
      )}
    </WikiMenuGroup>
  );
}
