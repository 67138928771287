import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import {
  faBookmark,
  faMagnifyingGlassPlus,
  faPencil,
  faRefresh,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useEffect } from 'react';
import {
  Link,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';
import invariant from 'tiny-invariant';
import { gql } from '~/apollo/client-v3';
import * as fragments from '~/apollo/fragments';
import type {
  SavedDataSearchPageQuery,
  SavedDataSearchPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NavLink } from '~/components/common/NavLink';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteSds } from '~/components/dataSearch/savedDataSearch/DeleteSds';
import { UpdateDataModal } from '~/components/dataSearch/savedDataSearch/UpdateDataModal';
import { UpdateSdsForm } from '~/components/dataSearch/savedDataSearch/UpdateSdsForm';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Button } from '~/components/ui/button';
import { Menu } from '~/components/ui/menu';
import { useQueryString } from '~/hooks/routing';
import {
  bookmarksRoute,
  dataSearchRoute,
  savedDataSearchRevisionRoute,
} from '~/paths';
import { revisionLabel } from '~/utils/modules/dataSearch';

export const SAVED_DATA_SEARCH_PAGE = gql`
  query SavedDataSearchPage($id: Int!) {
    savedDataSearchList(id: $id) {
      ...savedDataSearchParts
      data {
        id
        revision
        insertedAt
      }
    }
  }

  ${fragments.savedDataSearchParts}
`;

type OutletContext = {
  sds: SavedDataSearchPageQuery['savedDataSearchList'][number];
  onDeleteSuccess: () => Promise<void>;
  refetchQueries: PureQueryOptions[];
};

export default function BookmarkedDataRoute() {
  const { stringify } = useQueryString();
  const navigate = useNavigate();
  const params = useParams<{ sdsId: string; revisionNumber?: string }>();
  invariant(params.sdsId, 'sdsId param required');
  const sdsId = parseInt(params.sdsId);

  const revisionNumberParam =
    typeof params.revisionNumber !== 'undefined'
      ? parseInt(params.revisionNumber)
      : null;

  const { data, loading, refetch } = useQuery<
    SavedDataSearchPageQuery,
    SavedDataSearchPageQueryVariables
  >(SAVED_DATA_SEARCH_PAGE, {
    variables: { id: sdsId },
  });

  const refetchQueries: [PureQueryOptions<SavedDataSearchPageQueryVariables>] =
    [
      {
        query: SAVED_DATA_SEARCH_PAGE,
        variables: { id: sdsId },
      },
    ];

  const sdsList = data?.savedDataSearchList ?? [];
  const sds = sdsList.find(s => s.id === sdsId);
  const sdsData = R.sortWith([R.ascend(d => d.revision)], sds?.data ?? []);
  const latestRevisionNumber = R.sortWith(
    [R.descend(d => d.revision)],
    sdsData,
  ).at(0)?.revision;

  useEffect(() => {
    // Have to be careful checking here because revisions start at 0
    if (
      revisionNumberParam === null &&
      typeof latestRevisionNumber === 'number'
    ) {
      console.log('Revision available, redirecting to:', latestRevisionNumber);
      navigate(savedDataSearchRevisionRoute(sdsId, latestRevisionNumber), {
        replace: true,
      });
    }
  }, [sdsId, revisionNumberParam, latestRevisionNumber, navigate]);

  useBreadcrumb(
    'routes/bookmarked-data/$sdsId',
    sds?.name ?? 'Bookmarked Data',
    null,
    [
      {
        routeId: dataSearchRoute(),
        pathname: dataSearchRoute(),
        title: 'Data Search',
      },
    ],
  );

  const handleDeleteSuccess = async () => {
    await refetch();
  };

  if (loading) return <SpinnerPlaceholder />;
  if (!sds) return <NotFound />;

  const outletContext: OutletContext = {
    sds,
    onDeleteSuccess: handleDeleteSuccess,
    refetchQueries,
  };

  return (
    <>
      <div className="flex justify-between items-center gap-6">
        <div className="flex-grow">
          <UpdateSdsForm sdsId={sds.id} savedDataSearch={sds}>
            {toggleEditing => (
              <>
                <PageHeading>
                  {sds.name}

                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={toggleEditing}
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                </PageHeading>
              </>
            )}
          </UpdateSdsForm>
        </div>

        <div className="flex-shrink mt-2 space-x-1">
          <UpdateDataModal searchId={sdsId} refetchQueries={refetchQueries}>
            {showUpdateDataModal => (
              <Button
                type="button"
                color="ghost"
                size="sm"
                className="gap-1"
                onClick={showUpdateDataModal}
              >
                <FontAwesomeIcon icon={faRefresh} /> Update Data
              </Button>
            )}
          </UpdateDataModal>

          <Tooltip message="Start a new data search based on the saved filters from this search">
            <Link
              to={{
                pathname: dataSearchRoute(),
                search: stringify({ sdsId: sds.id }),
              }}
              className="btn btn-primary btn-sm gap-1"
            >
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> Copy to new
              search
            </Link>
          </Tooltip>

          <Link to={bookmarksRoute()} className="btn btn-sm btn-primary">
            <FontAwesomeIcon icon={faBookmark} /> Go to Bookmark List
          </Link>
        </div>
      </div>

      <div className="clear-both" />

      <SpinnerPlaceholder show={loading}>
        Loading revisions...
      </SpinnerPlaceholder>

      {sdsData.length > 1 && (
        <Menu direction="horizontal" className="gap-1 mt-2 mb-4 px-0">
          {sdsData.map(r => (
            <Menu.Item key={r.id}>
              <NavLink to={savedDataSearchRevisionRoute(sdsId, r.revision)}>
                {revisionLabel(r)}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
      )}

      {sdsData.length === 0 && (
        <div className="text-center">
          This saved search doesn't have any data associated with it. Click the
          button below to update the data.
          <UpdateDataModal searchId={sdsId} refetchQueries={refetchQueries}>
            {showUpdateDataModal => (
              <button
                type="button"
                className="btn btn-link"
                onClick={showUpdateDataModal}
              >
                <FontAwesomeIcon icon={faRefresh} /> Update Data
              </button>
            )}
          </UpdateDataModal>
        </div>
      )}

      <Outlet context={outletContext} />

      <div className="text-center mt-6">
        <DeleteSds
          searchId={sdsId}
          onDeleteSuccess={() => navigate(dataSearchRoute())}
        >
          {confirmDelete => (
            <Button
              type="button"
              color="ghost"
              size="xs"
              className="text-error hover:text-error gap-1"
              onClick={confirmDelete}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete Saved Bookmark
            </Button>
          )}
        </DeleteSds>
      </div>
    </>
  );
}

export function useSdsOutletContext() {
  return useOutletContext<OutletContext>();
}
