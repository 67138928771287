import { faFloppyDisk, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { Button } from '~/components/ui/button';
import { FormikField } from '~/components/common/FormikField';
import CreateSavedDataSearchModal from '~/components/dataSearch/CreateSavedDataSearchModal';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import { useCrossPlotData } from '~/components/dataSearch/useCrossPlotData';
import { useHistogramData } from '~/components/dataSearch/useHistogramData';
import { HelpBox } from '~/components/HelpBox';
import { MeasurementGraphCrossPlot } from '~/components/statistics/MeasurementGraph/MeasurementGraphCrossPlot';
import { MeasurementGraphHistogram } from '~/components/statistics/MeasurementGraph/MeasurementGraphHistogram';
import { MeasurementStatistics } from '~/components/statistics/MeasurementGraph/MeasurementStatistics';
import { RegressionFormFields } from '~/components/statistics/MeasurementGraph/RegressionFormFields';
import { useDebounce } from '~/hooks/debounce';
import {
  dataGroupableBy,
  formatGroupBy,
  type DataSearchFormValues,
} from '~/utils/modules/dataSearch';
import { GraphFormFields } from './GraphFormFields';

type Props = {
  disabled: boolean;
  collapsed: boolean;
};
export function GraphContainer({ disabled, collapsed }: Props) {
  const { values, isValid, dirty } = useFormikContext<DataSearchFormValues>();
  const { options, loadingMeasurements, loadingOptions } =
    useDataSearchContext();
  const { dataCrossPlot } = useCrossPlotData();
  const { dataHistogram } = useHistogramData();

  const hasData = dataCrossPlot.length || dataHistogram.length;

  return (
    <div className="space-y-4">
      <div className="grid lg:grid-cols-3 gap-6">
        <div>
          <GraphFormFields options={options} disabled={disabled} />

          {isValid && (
            <div className="mt-2 flex gap-2 w-full">
              <CreateSavedDataSearchModal dataSearch={values}>
                {showCreateSdsModal => (
                  <Button
                    type="button"
                    color="primary"
                    variant="outline"
                    onClick={showCreateSdsModal}
                    className="block space-x-2 whitepace-nowrap"
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    <span>Bookmark Data</span>
                  </Button>
                )}
              </CreateSavedDataSearchModal>

              <Button
                type="submit"
                color="primary"
                disabled={loadingOptions || loadingMeasurements || !dirty}
                className="block grow space-x-2 whitepace-nowrap"
              >
                <FontAwesomeIcon icon={faRefresh} />
                <span>{hasData ? 'Update' : 'Plot'} Data</span>
              </Button>
            </div>
          )}
        </div>

        <div className="lg:col-span-2">
          {!isValid && (
            <HelpBox>
              Select a data type
              {values.graphType === 'crossPlot' ? ' for each axis' : ''} to
              continue.
            </HelpBox>
          )}

          {isValid && (
            <div className="space-y-4">
              {values.graphType === 'crossPlot' && (
                <CrossPlotWrapper collapsed={collapsed} />
              )}
              {values.graphType === 'histogram' && (
                <HistogramWrapper collapsed={collapsed} />
              )}
            </div>
          )}
        </div>
      </div>

      {values.graphType === 'crossPlot' && dataCrossPlot.length > 0 && (
        <MeasurementStatistics
          dataX={dataCrossPlot.map(d => d.x)}
          dataY={dataCrossPlot.map(d => d.y)}
        >
          <div>
            <RegressionFormFields data={dataCrossPlot} />
          </div>
        </MeasurementStatistics>
      )}
      {values.graphType === 'histogram' && dataHistogram.length > 0 && (
        <MeasurementStatistics dataX={dataHistogram.map(d => d.value)} />
      )}
    </div>
  );
}

export function CrossPlotWrapper({ collapsed }: { collapsed: boolean }) {
  const { values } = useFormikContext<DataSearchFormValues>();
  const { searchInput } = useDataSearchContext();
  const { dataCrossPlot } = useCrossPlotData();

  if (!dataCrossPlot.length) {
    return null;
  }

  return (
    <div className="space-y-4">
      <Field
        name="groupDataBy"
        label="Colour data by"
        component={FormikField}
        type="select"
        showBlankOption={false}
        options={dataGroupableBy.map(value => ({
          value,
          label: formatGroupBy(value),
        }))}
      />

      <MeasurementGraphCrossPlot
        data={dataCrossPlot}
        xAxisDataType={searchInput.dataTypeX ?? 'X'}
        yAxisDataType={searchInput.dataTypeY ?? 'Y'}
        logScaleX={values.crossPlot.logScaleX}
        logScaleY={values.crossPlot.logScaleY}
        showRegressionLine={values.crossPlot.showRegressionLine}
        showZIRegressionLine={values.crossPlot.showZIRegressionLine}
        expanded={!collapsed}
      />
    </div>
  );
}

export function HistogramWrapper({ collapsed }: { collapsed: boolean }) {
  const { values } = useFormikContext<DataSearchFormValues>();
  const { searchInput } = useDataSearchContext();
  const { dataHistogram } = useHistogramData();

  const numBins = useDebounce(values.histogram.numBins, 1000);
  const binWidth = useDebounce(values.histogram.binWidth, 1000);

  if (!dataHistogram.length) {
    return null;
  }

  return (
    <div className="space-y-4">
      <Field
        name="groupDataBy"
        label="Colour data by"
        component={FormikField}
        type="select"
        showBlankOption={false}
        options={dataGroupableBy.map(value => ({
          value,
          label: formatGroupBy(value),
        }))}
      />

      <MeasurementGraphHistogram
        data={dataHistogram}
        xAxisDataType={searchInput.dataTypeX ?? ''}
        numBins={numBins}
        binWidth={binWidth}
        nice={values.histogram.nice}
        expanded={!collapsed}
      />
    </div>
  );
}
