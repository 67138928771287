import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { graphql } from '~/apollo/generated/v3';
import {
  Role,
  type OutcropMiniModelsPageQuery,
} from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Badge } from '~/components/ui/badge';
import { useRouteParam } from '~/hooks/routing';

const OUTCROP_MINI_MODELS_PAGE = graphql(`
  query OutcropMiniModelsPage($id: Int!) {
    outcropList(id: $id) {
      id
      miniModels {
        id
        name
        description
        url
        approved
      }
    }
  }
`);

export default function OutcropMiniModelsPage() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const { data, loading } = useQuery(OUTCROP_MINI_MODELS_PAGE, {
    variables: {
      id: outcropId,
    },
  });

  const outcrop = data?.outcropList.find(outcrop => outcrop.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <PageInner miniModels={outcrop.miniModels} />;
}

function PageInner({
  miniModels,
}: {
  miniModels: OutcropMiniModelsPageQuery['outcropList'][number]['miniModels'];
}) {
  const [showUnpublished, setShowUnpublished] = useState(true);

  const items = showUnpublished
    ? miniModels
    : miniModels.filter(mm => mm.approved);

  const unpublishedCount = miniModels.reduce((acc, mm) => {
    if (!mm.approved) return acc + 1;
    return acc;
  }, 0);

  return (
    <div className="space-y-4">
      <RoleSecured roles={[Role.RoleAdmin]}>
        <div className="form-control items-end">
          <label htmlFor="showUnpublished" className="label">
            <input
              type="checkbox"
              id="showUnpublished"
              checked={showUnpublished}
              onChange={() => setShowUnpublished(!showUnpublished)}
              className="checkbox"
              disabled={!unpublishedCount}
            />
            Show unapproved items{' '}
            <Badge color="ghost">{unpublishedCount}</Badge>
          </label>
        </div>
      </RoleSecured>

      <div className="grid lg:grid-cols-2 gap-6">
        {items.map(mm => (
          <div key={mm.id} className="w-full aspect-square thumbnail">
            <iframe title={mm.name} src={mm.url} className="w-full h-full" />
            <div className="caption text-left">
              <Heading level={5}>{mm.name}</Heading>
              {mm.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
