import type { PureQueryOptions } from '@apollo/client';
import {
  faCheckCircle,
  faExclamationCircle,
  faHourglass,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Form, Formik } from 'formik';
import { useReducer } from 'react';
import { Button } from '~/components/ui/button';
import type { DropzoneProps, FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import type { SuppDocFormValues } from '~/apollo/operations/supportingObject';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { FormikAutosave } from '~/components/common/FormikAutosave';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { SuppDocFormFields } from '~/components/upload/supportingObject/supplementalDocs/SuppDocFormFields';
import { envVars } from '~/environment';
import { useRefetchQueries } from '~/hooks/apollo';
import { fileSizeText } from '~/utils/text';
import { toast } from 'react-toastify';

type QueueItem = {
  tmpId: string;
  file: File;
  input: SuppDocFormValues;
  status: 'pending' | 'uploading' | 'success' | 'error';
};

type State = {
  status: 'idle' | 'uploading';
  items: QueueItem[];
};

const initialState: State = {
  status: 'idle',
  items: [],
};

type Action =
  | { type: 'ADD_ITEM'; file: File }
  | { type: 'REMOVE_ITEM'; tmpId: string }
  | { type: 'UPDATE_INPUT'; tmpId: string; input: SuppDocFormValues }
  | { type: 'SET_QUEUE_STATUS'; status: State['status'] }
  | { type: 'SET_ITEM_STATUS'; tmpId: string; status: QueueItem['status'] };

function reducer(state: State, action: Action): State {
  console.log('Dispatched', action);

  switch (action.type) {
    case 'ADD_ITEM': {
      const nextItems = [
        ...state.items,
        {
          tmpId: uuid(),
          file: action.file,
          input: { note: '' },
          status: 'pending',
        } satisfies QueueItem,
      ];

      return { ...state, items: nextItems };
    }

    case 'REMOVE_ITEM':
      return {
        ...state,
        items: state.items.filter(item => item.tmpId !== action.tmpId),
      };

    case 'UPDATE_INPUT': {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.tmpId !== action.tmpId) return item;
          return { ...item, input: action.input };
        }),
      };
    }

    case 'SET_QUEUE_STATUS':
      return {
        ...state,
        status: action.status,
      };

    case 'SET_ITEM_STATUS': {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.tmpId !== action.tmpId) return item;
          return { ...item, status: action.status };
        }),
      };
    }

    default:
      return state;
  }
}

async function uploadSuppDoc({
  outcropId,
  studyId,
  file,
  input,
}: {
  outcropId?: number;
  studyId?: number;
  file: File;
  input: SuppDocFormValues;
}) {
  const url = new URL(
    'api/v3/supporting-object/supplemental-document',
    envVars.VITE_CLIENT_URL,
  );

  const formData = new FormData();
  formData.set('note', input.note?.trim() || '');
  formData.set('file', file);
  if (outcropId) formData.set('outcropId', String(outcropId));
  if (studyId) formData.set('studyId', String(studyId));

  return fetch(url, { method: 'post', body: formData });
}

// We'll hold this outside React lifecycle to help prevent double clicks
let processing = false;

export function SuppDocUploader({
  outcropId,
  studyId,
  refetchQueries,
}: {
  outcropId?: number;
  studyId?: number;
  refetchQueries: PureQueryOptions[];
}) {
  if (!outcropId && !studyId) {
    throw new Error('Must supply either an outcropId or studyId');
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [refetch] = useRefetchQueries(refetchQueries);

  const handleDrop: DropzoneProps['onDrop'] = acceptedFiles => {
    acceptedFiles.forEach(file => dispatch({ type: 'ADD_ITEM', file }));
  };

  const handleUpdateInput = (tmpId: string) => (values: SuppDocFormValues) => {
    dispatch({ type: 'UPDATE_INPUT', tmpId, input: values });
  };

  const handleRemove = (tmpId: string) => () => {
    dispatch({ type: 'REMOVE_ITEM', tmpId });
  };

  const handleStart = async () => {
    if (processing) {
      console.log('Queue already started');
      return;
    } else {
      console.log('Starting upload');
      processing = true;
    }

    dispatch({ type: 'SET_QUEUE_STATUS', status: 'uploading' });

    const pendingItems = state.items.filter(
      item => item.status === 'pending' || item.status === 'error',
    );

    for (const item of pendingItems) {
      dispatch({
        type: 'SET_ITEM_STATUS',
        tmpId: item.tmpId,
        status: 'uploading',
      });

      const result = await uploadSuppDoc({
        outcropId,
        studyId,
        file: item.file,
        input: item.input,
      });
      if (result.ok) {
        dispatch({
          type: 'SET_ITEM_STATUS',
          tmpId: item.tmpId,
          status: 'success',
        });
      } else {
        const err = await result.json();
        console.log('Error uploading file:', item, err);
        dispatch({
          type: 'SET_ITEM_STATUS',
          tmpId: item.tmpId,
          status: 'error',
        });
      }
    }

    await refetch();
    dispatch({ type: 'SET_QUEUE_STATUS', status: 'idle' });
    processing = false;
  };

  function handleDropRejected(rejects: FileRejection[]) {
    const names = rejects.map(r => r.file.name).join('\n');
    toast.error(`The following files weren't accepted:\n${names}`);
  }

  const pendingItems = state.items.filter(item => item.status === 'pending');
  const errorItems = state.items.filter(item => item.status === 'error');
  const hasPendingItems = pendingItems.length > 0;

  const isQueueLocked = state.status !== 'idle';

  const isItemLocked = (tmpId: string) =>
    isQueueLocked ||
    state.items.find(item => item.tmpId === tmpId)?.status !== 'pending';

  console.log('Items:', state.items);

  return (
    <div className="space-y-6">
      <Dropzone
        onDrop={handleDrop}
        onDropRejected={handleDropRejected}
        multiple
        disabled={isQueueLocked}
        maxSize={100_000_000}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer {...getRootProps()}>
            <div
              className={cn('flex items-center', {
                'h-16': state.items.length,
                'h-96': !state.items.length,
              })}
              style={{
                transition: 'height 0.5s',
              }}
            >
              <input {...getInputProps()} />
              {isQueueLocked ? (
                <p>Uploading...</p>
              ) : (
                <p>Drop files here or click to browse</p>
              )}
            </div>
          </DropzoneContainer>
        )}
      </Dropzone>

      <div className="space-y-2">
        {state.items.map(item => (
          <div
            key={item.tmpId}
            className=" grid grid-cols-12 gap-6 border b-slate-300 p-2"
          >
            <div className="flex w-full h-full items-center justify-center">
              <ItemStatusIcon status={item.status} />
            </div>

            <div className="col-span-11 flex justify-between gap-6">
              <div className="grow space-y-2">
                <div className="font-bold text-base">
                  {item.file.name}{' '}
                  <span className="text-sm text-muted">
                    {fileSizeText(item.file.size)}
                  </span>
                </div>

                <Formik
                  onSubmit={handleUpdateInput(item.tmpId)}
                  initialValues={{ note: '' }}
                >
                  <Form>
                    <FormikAutosave debounceDelay={300} />
                    <SuppDocFormFields disabled={isItemLocked(item.tmpId)} />
                  </Form>
                </Formik>
              </div>

              <div className="flex h-full items-center justify-end">
                <Button
                  type="button"
                  color="ghost"
                  size="sm"
                  disabled={isItemLocked(item.tmpId)}
                  onClick={handleRemove(item.tmpId)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center">
        <Button
          type="button"
          onClick={handleStart}
          color="primary"
          disabled={
            (!hasPendingItems && !errorItems.length) || state.status !== 'idle'
          }
          loading={state.status === 'uploading'}
        >
          Upload
        </Button>
      </div>
    </div>
  );
}

function ItemStatusIcon({ status }: { status: QueueItem['status'] }) {
  switch (status) {
    case 'pending':
      return (
        <FontAwesomeIcon
          icon={faHourglass}
          className=" text-slate-200 text-2xl"
        />
      );
    case 'error':
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-error text-2xl"
        />
      );
    case 'success':
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-success text-2xl"
        />
      );
    case 'uploading':
      return <SpinnerIcon className="text-2xl" />;
  }
}
