import { Field, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import type { BookmarkPartsFragment } from '~/apollo/generated/v3/graphql';
import { BookmarkTargetType } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { Heading } from '~/components/common/Heading';
import type { ReportItemFormValuesBookmark } from '~/utils/modules/report';

export function ItemFormFieldsBookmark({
  bookmark,
}: {
  bookmark: BookmarkPartsFragment;
}) {
  const { values, setFieldValue } =
    useFormikContext<ReportItemFormValuesBookmark>();

  const textRef = useRef(values.text);
  const useNoteAsTextRef = useRef(values.useNoteAsText);

  // Update item text when useNoteAsText is checked
  useEffect(() => {
    if (values.useNoteAsText && !useNoteAsTextRef.current) {
      setFieldValue('text', bookmark.note);
      textRef.current = bookmark.note ?? '';
      useNoteAsTextRef.current = values.useNoteAsText;
    } else if (
      !values.useNoteAsText &&
      useNoteAsTextRef.current &&
      values.text === bookmark.note
    ) {
      setFieldValue('text', '');
      textRef.current = '';
      useNoteAsTextRef.current = values.useNoteAsText;
    } else if (useNoteAsTextRef.current && values.text !== textRef.current) {
      setFieldValue('useNoteAsText', false);
      useNoteAsTextRef.current = false;
      textRef.current = values.text;
    }
  }, [values.useNoteAsText, values.text, bookmark.note, setFieldValue]);

  // Automatically uncheck useNoteAsText if the text field is updated
  useEffect(() => {}, [values.text, values.useNoteAsText, setFieldValue]);

  return (
    <div className="space-y-2">
      <Field name="text" label="Text" component={FormikField} type="textarea" />
      <Field
        name="useNoteAsText"
        label="Use bookmark note as item text"
        component={FormikField}
        type="checkbox"
        size="sm"
      />

      {bookmark.targetType === BookmarkTargetType.Outcrop && (
        <>
          <Heading level={3}>
            Select the features to be included in the report:
          </Heading>

          <div>
            <Field
              name="outcropShowDescription"
              label="Description"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowIntroduction"
              label="Introduction"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowEnvironments"
              label="Lithology and Depositional Environment"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowStratigraphy"
              label="Litho- and Sequence Stratigraphy"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowDiagenesisAndPetrophysicalProperties"
              label="Diagenesis and Petrophysical Properties"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowStructuralGeology"
              label="Structural Geology"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowNotablesAndAnalogues"
              label="Analogues and Notable Aspects"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowLocation"
              label="Location and Accessibility"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowSafari"
              label="Key References"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowKeyParameters"
              label="Key Parameters"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
            <Field
              name="outcropShowPaleogeography"
              label="Palaeogeography"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
          </div>
        </>
      )}
      {bookmark.targetType === BookmarkTargetType.Study && (
        <>
          <Heading level={3}>
            Select the features to be included in the report:
          </Heading>

          <div>
            <Field
              name="studyShowAbstract"
              label="Abstract"
              component={FormikField}
              type="checkbox"
              size="sm"
            />
          </div>
        </>
      )}
    </div>
  );
}
