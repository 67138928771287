import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { reportParts } from '~/apollo/fragments';
import type {
  UpdateReportStateMutation,
  UpdateReportStateMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { ReportState } from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';

const UPDATE_REPORT_STATE = gql`
  mutation UpdateReportState($reportId: Int!, $state: ReportState!) {
    updateReportState(reportId: $reportId, state: $state) {
      ...reportParts
    }
  }

  ${reportParts}
`;

export function UpdateReportState({
  children,
  reportId,
  isCompleted,
  isCompany,
}: {
  children: (showConfirm: () => void) => React.ReactNode;
  reportId: number;
  isCompleted: boolean;
  isCompany: boolean;
}) {
  const [updateReportState] = useMutation<
    UpdateReportStateMutation,
    UpdateReportStateMutationVariables
  >(UPDATE_REPORT_STATE);

  async function handlePublish() {
    const state = isCompleted ? ReportState.Draft : ReportState.Completed;

    try {
      const res = await updateReportState({ variables: { reportId, state } });
      const newState = res.data?.updateReportState.state;
      invariant(newState);
      toast.success(`Report successfully set to '${newState}'`);
    } catch (err) {
      console.log('Error updating report state', err);
      toast.error(
        "There was a problem updating the report's state, please reload the page and try again.",
      );
    }
  }

  const completeCompanyText = isCompany
    ? 'Only a company admin or the report owner will be able to unlock it by marking it as Draft.'
    : undefined;

  const confirmText = isCompleted
    ? 'Setting the report back to Draft mode will unlock it for editing and other changes.'
    : [
        'Marking this report as Completed will lock it for editing and display the current timestamp as the publish date.',
        completeCompanyText,
      ].join(' ');

  return (
    <>
      <Confirm text={confirmText} onConfirm={handlePublish}>
        {showConfirm => children(showConfirm)}
      </Confirm>
    </>
  );
}
