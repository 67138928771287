import { useParams } from 'react-router';
import { NavLink } from '~/components/common/NavLink';
import { Menu } from '~/components/ui/menu';
import { useAuth } from '~/contexts/auth';
import { aboutRoute } from '~/paths';
import { cn } from '~/utils/common';

export type AboutMenuItem = {
  pageName: string;
  title: string;
  subMenu?: AboutMenuItem[];
};

export function createMenuStructure(isAuthenticated: boolean) {
  const menu: AboutMenuItem[] = [
    {
      pageName: 'background',
      title: 'Background',
      subMenu: [
        { pageName: 'safari1', title: 'Safari 1' },
        { pageName: 'safari2', title: 'Safari 2' },
        { pageName: 'safari3', title: 'Safari 3' },
        { pageName: 'safari4', title: 'Safari 4' },
        { pageName: 'safari5', title: 'Safari 5' },
      ],
    },
    {
      pageName: 'partners',
      title: 'Partners',
    },
    {
      pageName: 'people',
      title: 'People',
      subMenu: [{ pageName: 'students', title: 'Student Education' }],
    },
  ];

  if (isAuthenticated) {
    menu.push({ pageName: 'tech', title: 'Technical Overview' });
  }

  menu.push({ pageName: 'contact', title: 'Contact' });

  if (isAuthenticated) {
    menu.push({ pageName: 'sc-docs', title: 'SC Documents' });
  }

  if (isAuthenticated) {
    menu.push({ pageName: 'publications', title: 'Publications' });
  }

  return menu;
}

const createMenuLink = (
  item: AboutMenuItem,
  currentPage: string,
  level: number,
) => (
  <Menu.Item
    key={item.pageName}
    className={cn({
      active: item.pageName === currentPage,
    })}
  >
    <NavLink to={aboutRoute(item.pageName)}>{item.title}</NavLink>

    {item.subMenu && (
      <Menu.Submenu>
        {item.subMenu.map(child =>
          createMenuLink(child, currentPage, level + 1),
        )}
      </Menu.Submenu>
    )}
  </Menu.Item>
);

export function SideNavigation() {
  const { isAuthenticated } = useAuth();
  const params = useParams();

  return (
    <Menu>
      {createMenuStructure(isAuthenticated).map(item =>
        createMenuLink(item, params.currentPage ?? '', 0),
      )}
    </Menu>
  );
}
