import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v3';
import type { PagePartsFragment } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useHookForm } from '~/utils/forms';
import {
  pageFormValues,
  pageSchema,
  type PageFormValues,
} from '~/utils/modules/page';
import { PageFormFields } from './PageFormFields';

const UPDATE_PAGE = graphql(`
  mutation UpdatePage($id: Int!, $page: PageInput!) {
    updatePage(id: $id, page: $page) {
      ...pageParts
    }
  }
`);

export function UpdatePageForm({ page }: { page: PagePartsFragment }) {
  const [updatePage, { loading, error }] = useMutation(UPDATE_PAGE);

  const form = useHookForm({
    values: pageFormValues(page),
    schema: pageSchema,
  });

  async function handleSubmit(values: PageFormValues) {
    try {
      await updatePage({ variables: { id: page.id, page: values } });
      toast.success('Page updated successfully.');
    } catch (err) {
      console.log('Error updating page', err);
      toast.error('There was a problem updating the page.');
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Update Page</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="space-y-4">
            <PageFormFields />
            <HookFormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer align="right">
            <Button type="submit" color="primary" disabled={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </FormProvider>
  );
}
