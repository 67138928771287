import type { ComponentPropsWithRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { cn } from '~/utils/common';

export type CheckboxProps = {
  label: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
} & ComponentPropsWithRef<'input'>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, size, className, ...inputProps }, ref) => {
    return (
      <label className="label">
        <input
          {...inputProps}
          ref={ref}
          type="checkbox"
          className={cn(
            'checkbox',
            {
              'checkbox-xs': size === 'xs',
              'checkbox-sm': size === 'sm' || !size,
              'checkbox-md': size === 'md',
              'checkbox-lg': size === 'lg',
              'checkbox-xl': size === 'xl',
            },
            className,
          )}
        />
        {label}
      </label>
    );
  },
);
Checkbox.displayName = 'Checkbox';
