import * as R from 'ramda';
import type {
  LithostratAgePartsFragment,
  LithostratFormationInput,
  LithostratFormationPartsFragment,
  LithostratGroupInput,
  LithostratGroupPartsFragment,
  LithostratMemberInput,
  LithostratMemberPartsFragment,
  LithostratWikiPagePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';
import { mostSpecificAge } from './lithostratAge';

export interface LithostratWikiPageFormValues {
  name: string;
  alternativeNames: string;
  climate: string;
  basinType: string;
  geologyType: string[];
  region: string;
  country: string;
  introduction: string;
  distributionThickness: string;
  lithologyDepositional: string;
  lithologySequence: string;
  analogues: string;
  literatureReferences: string;
  published: boolean;
}

export function initialLithostratWikiPage(
  wp?: LithostratWikiPagePartsFragment | null,
): LithostratWikiPageFormValues {
  return {
    name: wp?.name ?? '',
    alternativeNames: wp?.alternativeNames ?? '',
    climate: wp?.climate ?? '',
    basinType: wp?.basinType ?? '',
    geologyType: wp?.geologyType ?? [],
    region: wp?.region ?? '',
    country: wp?.country ?? '',
    introduction: wp?.introduction ?? '',
    distributionThickness: wp?.distributionThickness ?? '',
    lithologyDepositional: wp?.lithologyDepositional ?? '',
    lithologySequence: wp?.lithologySequence ?? '',
    analogues: wp?.analogues ?? '',
    literatureReferences: wp?.literatureReferences ?? '',
    published: wp?.published ?? false,
  };
}

export const lithostratWikiPageValidationSchema = yup.object({
  // Required
  name: yup.string().label('name').required(),
  country: yup.string().label('country').required(),

  // Nullable
  basinType: yup.string().label('basin type').nullable(),
  geologyType: yup.array(yup.string()).label('geology type').nullable(),
  introduction: yup.string().label('introduction').nullable(),
  distributionThickness: yup
    .string()
    .label('distribution/thickness')
    .nullable(),
  lithologyDepositional: yup.string().label('depositional').nullable(),
  lithologySequence: yup.string().label('sequence').nullable(),
  analogues: yup.string().label('analogues').nullable(),
  literatureReferences: yup.string().label('literature references').nullable(),

  // Add oneOf validation to check for legacy climate types - some older freehand input may be invalid
  climate: yup
    .string()
    .nullable()
    .label('climate')
    .test('valid-climate', 'is invalid', value => {
      if (R.anyPass([R.isEmpty, R.isNil])(value)) return true;
      return R.contains(value, [
        'equatorial',
        'arid',
        'warm temperate',
        'snow/polar',
        'undefined',
      ]);
    }),
});

export type FormationFormValues = {
  name: string;
  description: string;
  lithostratTypeId: string;
  lithostratOrder: string;
  comments: string;
  groupId: number | null;
};

type InitialFormationArg = LithostratFormationPartsFragment & {
  group?: { id: number } | null;
};

export function initialLithostratFormation(
  formation?: InitialFormationArg,
): FormationFormValues {
  const formValues: FormationFormValues = {
    name: formation?.name || '',
    description: formation?.description || '',
    lithostratTypeId: String(formation?.lithostratTypeId ?? ''),
    lithostratOrder: String(formation?.lithostratOrder) ?? '',
    comments: formation?.comments ?? '',
    groupId: formation?.group?.id ?? null,
  };

  return formValues;
}

export const formationValidationSchema = () => {
  return yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    comments: yup.string(),
    groupId: yup.number().integer().nullable(),
    lithostratTypeId: yup.number().integer().required(),
    lithostratOrder: yup.number().integer(),
  });
};

export const formValuesToFormationInput = (
  fv: FormationFormValues,
): LithostratFormationInput => ({
  name: fv.name,
  description: fv.description,
  comments: fv.comments || null,
  groupId: fv.groupId,
  lithostratTypeId: parseInt(fv.lithostratTypeId),
  lithostratOrder: fv.lithostratOrder ? parseInt(fv.lithostratOrder) : null,
});

export type MemberFormValues = {
  name: string;
  description: string;
  lithostratTypeId: string;
  lithostratOrder: string;
  comments: string;
  formationId: number | null;
};

type InitialMemberArg = LithostratMemberPartsFragment & {
  formation?: {
    id: number;
  } | null;
};
export function initialMember(member?: InitialMemberArg): MemberFormValues {
  const formValues: MemberFormValues = {
    name: member?.name ?? '',
    description: member?.description ?? '',
    lithostratTypeId: String(member?.lithostratTypeId ?? ''),
    lithostratOrder: String(member?.lithostratOrder ?? ''),
    comments: member?.comments ?? '',
    formationId: member?.formation?.id ?? null,
  };
  return formValues;
}

export function memberValidationSchema() {
  return yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    lithostratTypeId: yup.number().integer().required(),
    lithostratOrder: yup.number().integer(),
    comments: yup.string(),
    formationId: yup.number().integer().required(),
  });
}

export const formValuesToLithostratMemberInput = (
  values: MemberFormValues,
): LithostratMemberInput => ({
  name: values.name,
  description: values.description,
  comments: values.comments || null,
  formationId: values.formationId ? values.formationId : null,
  lithostratOrder: parseInt(values.lithostratOrder),
  lithostratTypeId: parseInt(values.lithostratTypeId),
});

export interface GroupFormValues {
  group: {
    name: string;
    description: string;
    lithostratTypeId: string;
    comments: string;
  };
}

export function defaultGroup(
  group?: LithostratGroupPartsFragment,
): GroupFormValues {
  return {
    group: {
      name: group?.name ?? '',
      description: group?.description ?? '',
      lithostratTypeId: String(group?.lithostratTypeId ?? ''),
      comments: group?.comments ?? '',
    },
  };
}

export const groupValidationSchema = yup.object({
  group: yup.object({
    name: yup.string().required().label('name'),
    description: yup.string().required().label('description'),
    lithostratTypeId: yup.number().required().label('lithostrat type'),
    comments: yup.string().label('comments'),
  }),
});

export function formValuesToGroupInput(
  values: GroupFormValues,
): LithostratGroupInput {
  return {
    name: values.group.name,
    description: values.group.description,
    comments: values.group.comments || null,
    lithostratTypeId: parseInt(values.group.lithostratTypeId),
  };
}

/** Augmented lithostrat type with a summarized start age for sorting/filtering */
export type SortableLithostrat<T> = T & { startAgeSummary: string | null };

type LithostratEntity = (
  | LithostratFormationPartsFragment
  | LithostratGroupPartsFragment
  | LithostratMemberPartsFragment
) & {
  startAge: LithostratAgePartsFragment | null | undefined;
};

export const toSortableLithostrat = <T extends LithostratEntity>(
  entity: T,
): SortableLithostrat<T> => ({
  ...entity,
  startAgeSummary: mostSpecificAge(entity.startAge),
});
