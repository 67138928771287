import { Link } from 'react-router';
import invariant from 'tiny-invariant';
import type { LithostratMemberRouteQuery } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { lithostratFormationRoute } from '~/paths';

type Props = {
  member: LithostratMemberRouteQuery['lithostratMemberList'][number];
};
export function MemberSidebar({ member }: Props) {
  invariant(member.wikiPage, 'wiki page does not exist or is not published');

  const parentFormation = member.formation?.wikiPage?.published ? (
    <Link to={lithostratFormationRoute(member.formation.id)} className="link">
      {member.formation.name}
    </Link>
  ) : (
    member.formation?.name
  );

  return (
    <div className="space-y-4">
      {parentFormation && (
        <div className="space-y-1">
          <Heading level={4}>Lithostratigraphic Hierarchy</Heading>
          <Panel>
            <Panel.Body>
              <KPGrid>
                <KPCol span={3}>
                  <strong>Belongs To</strong>
                </KPCol>
                <KPCol span={9}>{parentFormation}</KPCol>
              </KPGrid>
            </Panel.Body>
          </Panel>
        </div>
      )}

      {member.wikiPage.keyParameters.length > 0 && (
        <div className="space-y-1">
          <Heading level={4}>Key Parameters</Heading>
          <Panel>
            <Panel.Body>
              <KeyParametersPanel
                keyParameters={member.wikiPage.keyParameters ?? []}
                noHeading
                leftColMd={3}
                rightColMd={9}
              />
            </Panel.Body>
          </Panel>
        </div>
      )}

      <div className="space-y-1">
        <Heading level={4}>Location</Heading>
        <Panel>
          <Panel.Body>
            <KPGrid>
              <KPCol span={3}>
                <strong>Country</strong>
              </KPCol>
              <KPCol span={9}>{member.wikiPage.country}</KPCol>
            </KPGrid>

            <KPGrid>
              <KPCol span={3}>
                <strong>Region</strong>
              </KPCol>
              <KPCol span={9}>{member.wikiPage.region}</KPCol>
            </KPGrid>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
