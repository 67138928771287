import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { cn } from '~/utils/common';
import * as fragments from '~/apollo/fragments';
import {
  type StudyIndexRouteQuery,
  type StudyIndexRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { HtmlSection } from '~/components/common/HtmlSection';
import { LastUpdated } from '~/components/common/LastUpdated';
import { NotFound } from '~/components/common/NotFound';
import { Prose } from '~/components/common/Prose';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { StudyGeoreferencesMap } from '~/components/study/StudyGeoreferencesMap';
import { StudyLiteraturePanel } from '~/components/study/StudyLiteraturePanel';
import { StudyOutcrops } from '~/components/study/StudyOutcrops';
import { useStudyOutletContext } from '~/routes/study/$studyId';

const STUDY_INDEX_ROUTE = gql`
  query StudyIndexRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      outcrops {
        id
        name
        outcropCategory
        virtualOutcropModels {
          ...vomParts
        }
        georeferences {
          ...georeferenceParts
        }
        region {
          ...regionParts
        }
      }
      literatureReferences {
        ...literatureParts
        files {
          ...fileParts
        }
      }
      georeferences {
        ...georeferenceParts
      }
      crossSections {
        id
        name
        georeference {
          ...georeferenceParts
        }
      }
      sedimentaryLogs {
        id
        name
        georeference {
          ...georeferenceParts
        }
      }
      gigaPans {
        id
        name
        georeference {
          ...georeferenceParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.regionParts}
  ${fragments.vomParts}
  ${fragments.literatureParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
`;

type Study = StudyIndexRouteQuery['studyList'][number];
type Outcrop = Study['outcrops'][number];

function outcropCategoryLabel(outcrops: Outcrop[]) {
  const hasOutcropType = outcrops.find(oc => oc.outcropCategory === 'outcrop');
  const hasAnalogueType = outcrops.find(oc => oc.outcropCategory !== 'outcrop');

  if (hasOutcropType && hasAnalogueType) {
    return 'Outcrops/Analogues';
  } else if (hasOutcropType) {
    return 'Outcrops';
  } else {
    return 'Analogues';
  }
}

export default function StudyIndexRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    StudyIndexRouteQuery,
    StudyIndexRouteQueryVariables
  >(STUDY_INDEX_ROUTE, { variables: { studyId } });

  const study = data?.studyList.find(s => s.id === studyId);
  const outcrops = study?.outcrops ?? [];
  const hasOutcrops = outcrops.length > 0;

  const hasLiterature =
    study?.literatureReferences && study.literatureReferences.length > 0;

  const showColumns = hasOutcrops || hasLiterature;

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <>
      <div className="grid lg:grid-cols-12 gap-6">
        <div
          className={cn(
            'space-y-4',
            showColumns ? 'lg:col-span-8' : 'lg:col-span-12',
          )}
        >
          {study.abstract && (
            <HtmlSection
              title="Abstract"
              content={study.abstract}
              truncate
              truncateLength={1500}
            />
          )}

          {study.numericalDataAspects && (
            <div>
              <Heading level={3}>
                Numerical Data and Other Notable Aspects
              </Heading>
              <Prose
                dangerouslySetInnerHTML={{ __html: study.numericalDataAspects }}
              />
            </div>
          )}

          {study.keyReferences && (
            <div>
              <Heading level={3}>Citation</Heading>
              <Prose
                dangerouslySetInnerHTML={{ __html: study.keyReferences }}
              />
            </div>
          )}

          <StudyGeoreferencesMap study={study} />
        </div>

        {showColumns && (
          <div className="lg:col-span-4 space-y-4">
            {hasOutcrops && (
              <div>
                <Heading level={3}>
                  {outcropCategoryLabel(study.outcrops)}
                </Heading>
                <StudyOutcrops outcrops={outcrops} />
              </div>
            )}

            {study.keywords && (
              <div>
                <Heading level={3}>Keywords</Heading>
                <p>{study.keywords.split(',').join(', ')}</p>
              </div>
            )}

            {hasLiterature && (
              <div>
                <Heading level={3}>Literature</Heading>
                <div className="space-y-4">
                  {study.literatureReferences.map(lit => (
                    <StudyLiteraturePanel key={lit.id} literature={lit} />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <LastUpdated date={study.updatedAt} />
    </>
  );
}
