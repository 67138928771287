import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import { Badge } from '~/components/ui/badge';
import { gql } from '~/apollo/client-v3';
import type {
  StudyPageNavigationQuery,
  StudyPageNavigationQueryVariables,
} from '~/apollo/generated/v3/graphql';
import type { NavLinkProps } from '~/components/common/NavLink';
import { NavLink } from '~/components/common/NavLink';
import { Tooltip } from '~/components/common/Tooltip';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';

const STUDY_PAGE_NAVIGATION = gql`
  query StudyPageNavigation($id: Int!) {
    studyList(id: $id) {
      id
      pictures {
        id
      }
      crossSections {
        id
      }
      facies {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      reservoirModels {
        id
      }
      production {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      architecturalElements {
        id
        measurementCount
      }
    }
  }
`;

type CountBadgeProps = {
  children?: React.ReactNode;
  count: number;
};
function CountBadge({ children, count }: CountBadgeProps) {
  return (
    <div>
      <Badge color="ghost" className="break-words">
        {children ? children : count}
      </Badge>
    </div>
  );
}

function StudyNavLink(props: NavLinkProps) {
  return (
    <NavLink {...props} className="flex flex-wrap items-start gap-2">
      {props.children}
    </NavLink>
  );
}

type Props = {
  studyId: number;
};
export function StudyRouteNavigation({ studyId }: Props) {
  const { data } = useQuery<
    StudyPageNavigationQuery,
    StudyPageNavigationQueryVariables
  >(STUDY_PAGE_NAVIGATION, {
    variables: { id: studyId },
  });

  const study = data?.studyList.find(s => s.id === studyId);

  type QueryStudy = StudyPageNavigationQuery['studyList'][number];
  const objectCount = (property: keyof QueryStudy) =>
    R.pipe(R.path<any[]>([property]), R.defaultTo<any[]>([]), R.length)(study);

  const hasItems = (property: keyof QueryStudy) => objectCount(property) > 0;

  const aes = study?.architecturalElements ?? [];
  const aeCount = aes.length;
  const measurementCount = aes.reduce<number>(
    (acc, cur) => acc + cur.measurementCount,
    0,
  );

  const hasAnySOs = R.any(hasItems, [
    'pictures',
    'facies',
    'crossSections',
    'sedimentaryLogs',
    'wellLogs',
    'production',
    'reservoirModels',
    'trainingImages',
    'variograms',
    'gigaPans',
  ]);

  return (
    <Menu>
      <Menu.Item>
        <StudyNavLink to={routes.studySummaryRoute(studyId)} end>
          Summary
        </StudyNavLink>
      </Menu.Item>
      <Menu.Item>
        <StudyNavLink to={routes.studyKeyParametersRoute(studyId)}>
          Key Parameters
        </StudyNavLink>
      </Menu.Item>

      {measurementCount > 0 && (
        <>
          <li />
          <Menu.Item>
            <StudyNavLink to={routes.studyMeasurementsRoute(studyId)}>
              Measurements
              <Tooltip
                message={`${measurementCount} measurements on ${aeCount} architectural elements`}
              >
                <span>
                  <CountBadge count={measurementCount}>
                    {measurementCount} / {aeCount} AEs
                  </CountBadge>
                </span>
              </Tooltip>
            </StudyNavLink>
          </Menu.Item>
        </>
      )}

      {hasAnySOs && <li />}

      {hasItems('pictures') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyPicturesTabRoute(studyId)}>
            Pictures
            <CountBadge count={objectCount('pictures')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('facies') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyFaciesTabRoute(studyId)}>
            Facies
            <CountBadge count={objectCount('facies')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('crossSections') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyCrossSectionsTabRoute(studyId)}>
            Cross Sections
            <CountBadge count={objectCount('crossSections')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('sedimentaryLogs') && (
        <Menu.Item>
          <StudyNavLink to={routes.studySedimentaryLogsTabRoute(studyId)}>
            Sedimentary Logs
            <CountBadge count={objectCount('sedimentaryLogs')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('wellLogs') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyWellLogsTabRoute(studyId)}>
            Well Logs
            <CountBadge count={objectCount('wellLogs')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('production') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyProductionTabRoute(studyId)}>
            Production
            <CountBadge count={objectCount('production')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('reservoirModels') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyReservoirModelsTabRoute(studyId)}>
            Reservoir Models
            <CountBadge count={objectCount('reservoirModels')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('trainingImages') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyTrainingImagesTabRoute(studyId)}>
            Training Images
            <CountBadge count={objectCount('trainingImages')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('variograms') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyVariogramsTabRoute(studyId)}>
            Variograms
            <CountBadge count={objectCount('variograms')} />
          </StudyNavLink>
        </Menu.Item>
      )}
      {hasItems('gigaPans') && (
        <Menu.Item>
          <StudyNavLink to={routes.studyPanoramasTabRoute(studyId)}>
            Panoramas
            <CountBadge count={objectCount('gigaPans')} />
          </StudyNavLink>
        </Menu.Item>
      )}
    </Menu>
  );
}
