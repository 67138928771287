import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import { graphql } from '~/apollo/generated/v3';
import type { OutcropPageNavigationQuery } from '~/apollo/generated/v3/graphql';
import type { NavLinkProps } from '~/components/common/NavLink';
import { NavLink } from '~/components/common/NavLink';
import { Tooltip } from '~/components/common/Tooltip';
import { Badge } from '~/components/ui/badge';
import { Menu } from '~/components/ui/menu';
import * as routes from '~/paths';

const OUTCROP_PAGE_NAVIGATION = graphql(`
  query OutcropPageNavigation($id: Int!) {
    outcropList(id: $id) {
      id
      virtualOutcropModels {
        id
      }
      pictures {
        id
      }
      crossSections {
        id
      }
      facies {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      reservoirModels {
        id
      }
      production {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      fieldPictures {
        id
      }
      miniModels {
        id
      }
      photo360s {
        id
      }
      videos {
        id
      }

      studies {
        id
        architecturalElements {
          id
          outcropId
          measurementCount
        }
        pictures {
          id
          outcropTagId
        }
        crossSections {
          id
          outcropTagId
        }
        facies {
          id
          outcropTagId
        }
        sedimentaryLogs {
          id
          outcropTagId
        }
        wellLogs {
          id
          outcropTagId
        }
        reservoirModels {
          id
          outcropTagId
        }
        production {
          id
          outcropTagId
        }
        trainingImages {
          id
          outcropTagId
        }
        variograms {
          id
          outcropTagId
        }
        gigaPans {
          id
          outcropTagId
        }
      }
    }
  }
`);

type CountBadgeProps = {
  children?: React.ReactNode;
  count: number;
};
function CountBadge({ children, count }: CountBadgeProps) {
  return (
    <div>
      <Badge color="ghost" className="break-words">
        {children ? children : count}
      </Badge>
    </div>
  );
}

function OCNavLink(props: NavLinkProps) {
  return (
    <NavLink {...props} className="flex flex-wrap items-start gap-2">
      {props.children}
    </NavLink>
  );
}

type Outcrop = OutcropPageNavigationQuery['outcropList'][number];
type Study = Outcrop['studies'][number];

type CountableOutcropField = keyof Omit<
  Outcrop,
  '__typename' | 'id' | 'studies'
>;
type CountableStudyField = keyof Omit<
  Study,
  '__typename' | 'id' | 'architecturalElements'
>;

const studySOs: CountableStudyField[] = [
  'pictures',
  'facies',
  'crossSections',
  'sedimentaryLogs',
  'wellLogs',
  'production',
  'reservoirModels',
  'trainingImages',
  'variograms',
  'gigaPans',
];
const outcropSOs: CountableOutcropField[] = [
  ...studySOs,
  'miniModels',
  'photo360s',
  'videos',
];

export function OutcropRouteNavigation({ outcropId }: { outcropId: number }) {
  const { data } = useQuery(OUTCROP_PAGE_NAVIGATION, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (!outcrop) return null;
  return <NavigationInner outcrop={outcrop} />;
}

function NavigationInner({ outcrop }: { outcrop: Outcrop }) {
  const outcropObjectCount = (property: CountableOutcropField) =>
    outcrop[property].length ?? 0;

  const taggedObjectCount = (property: CountableStudyField) =>
    outcrop.studies.reduce((acc, study) => {
      const count = study[property].filter(
        obj => obj.outcropTagId === outcrop.id,
      ).length;
      return acc + count;
    }, 0);

  function totalObjectCount(
    property: CountableOutcropField & CountableStudyField,
  ) {
    const ocCount = outcropObjectCount(property);
    const tagCount = taggedObjectCount(property);
    return ocCount + tagCount;
  }

  const hasOutcropItems = (property: CountableOutcropField) =>
    outcropObjectCount(property) > 0;

  const hasTaggedItems = (property: CountableStudyField) =>
    taggedObjectCount(property) > 0;

  function hasAnyItems(
    ocProp: CountableOutcropField | null,
    studyProp: CountableStudyField | null,
  ) {
    const ocItems = ocProp ? hasOutcropItems(ocProp) : false;
    const studyItems = studyProp ? hasTaggedItems(studyProp) : false;
    return ocItems || studyItems;
  }

  function hasAnySupportingObjects() {
    return outcropSOs
      .map(hasOutcropItems)
      .concat(studySOs.map(hasTaggedItems))
      .reduce((acc, cur) => acc || cur, false);
  }

  const ocStudies = outcrop?.studies ?? [];
  const studyCount = ocStudies.length;
  const ocStudyAEs = ocStudies
    .map(s => s.architecturalElements)
    .reduce(R.concat, [])
    .filter(ae => ae.outcropId === outcrop.id && ae.measurementCount > 0);
  const measurementCount = ocStudyAEs.reduce(
    (acc, cur) => acc + cur.measurementCount,
    0,
  );
  const aeCount = ocStudyAEs.length;

  const hasAnyMeasurements = measurementCount > 0;

  return (
    <>
      <Menu>
        <Menu.Item>
          <OCNavLink to={routes.outcropOverviewTabRoute(outcrop.id)} end>
            Overview
          </OCNavLink>
        </Menu.Item>
        <Menu.Item>
          <OCNavLink to={routes.outcropKeyParametersTabRoute(outcrop.id)}>
            Key Parameters
          </OCNavLink>
        </Menu.Item>
        {hasOutcropItems('virtualOutcropModels') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropVirtualOutcropsTabRoute(outcrop.id)}>
              Virtual Outcrop Models
              <CountBadge count={outcropObjectCount('virtualOutcropModels')} />
            </OCNavLink>
          </Menu.Item>
        )}
        <Menu.Item>
          <OCNavLink to={routes.outcropAnaloguesTabRoute(outcrop.id)}>
            Analogues
          </OCNavLink>
        </Menu.Item>
        <Menu.Item>
          <OCNavLink to={routes.outcropPalaeogeographyTabRoute(outcrop.id)}>
            Palaeogeography
          </OCNavLink>
        </Menu.Item>
        {outcrop.studies.length > 0 && (
          <Menu.Item>
            <OCNavLink to={routes.outcropStudiesTabRoute(outcrop.id)}>
              Studies <CountBadge count={studyCount} />
            </OCNavLink>
          </Menu.Item>
        )}

        {hasAnyMeasurements && (
          <>
            <li />
            <Menu.Item>
              <OCNavLink to={routes.outcropMeasurementsTabRoute(outcrop.id)}>
                Measurements{' '}
                <Tooltip
                  message={`${measurementCount} measurements on ${aeCount} architectural elements`}
                >
                  <span>
                    <CountBadge count={measurementCount}>
                      {measurementCount} / {aeCount} AEs
                    </CountBadge>
                  </span>
                </Tooltip>
              </OCNavLink>
            </Menu.Item>
          </>
        )}

        {hasAnySupportingObjects() && <li />}

        {hasAnyItems('pictures', 'pictures') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropPicturesTabRoute(outcrop.id)}>
              Pictures <CountBadge count={totalObjectCount('pictures')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('facies', 'facies') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropFaciesTabRoute(outcrop.id)}>
              Facies <CountBadge count={totalObjectCount('facies')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('crossSections', 'crossSections') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropCrossSectionsTabRoute(outcrop.id)}>
              Cross Sections
              <CountBadge count={totalObjectCount('crossSections')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('sedimentaryLogs', 'sedimentaryLogs') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropSedimentaryLogsTabRoute(outcrop.id)}>
              Sedimentary Logs
              <CountBadge count={totalObjectCount('sedimentaryLogs')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('wellLogs', 'wellLogs') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropWellLogsTabRoute(outcrop.id)}>
              Well Logs <CountBadge count={totalObjectCount('wellLogs')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('production', 'production') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropProductionTabRoute(outcrop.id)}>
              Production <CountBadge count={totalObjectCount('production')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('reservoirModels', 'reservoirModels') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropReservoirModelsTabRoute(outcrop.id)}>
              Reservoir Models
              <CountBadge count={totalObjectCount('reservoirModels')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('trainingImages', 'trainingImages') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropTrainingImagesTabRoute(outcrop.id)}>
              Training Images
              <CountBadge count={totalObjectCount('trainingImages')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('variograms', 'variograms') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropVariogramsTabRoute(outcrop.id)}>
              Variograms <CountBadge count={totalObjectCount('variograms')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {hasAnyItems('gigaPans', 'gigaPans') && (
          <Menu.Item>
            <OCNavLink to={routes.outcropPanoramasTabRoute(outcrop.id)}>
              Panoramas <CountBadge count={totalObjectCount('gigaPans')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {outcropObjectCount('fieldPictures') > 0 && (
          <Menu.Item>
            <OCNavLink to={routes.outcropFieldPicturesTabRoute(outcrop.id)}>
              Field Pictures{' '}
              <CountBadge count={outcropObjectCount('fieldPictures')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {outcropObjectCount('miniModels') > 0 && (
          <Menu.Item>
            <OCNavLink to={routes.outcropMiniModelsTabRoute(outcrop.id)}>
              Mini-Models{' '}
              <CountBadge count={outcropObjectCount('miniModels')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {outcropObjectCount('photo360s') > 0 && (
          <Menu.Item>
            <OCNavLink to={routes.outcropPhoto360sTabRoute(outcrop.id)}>
              360 Photos <CountBadge count={outcropObjectCount('photo360s')} />
            </OCNavLink>
          </Menu.Item>
        )}
        {outcropObjectCount('videos') > 0 && (
          <Menu.Item>
            <OCNavLink to={routes.outcropVideosTabRoute(outcrop.id)}>
              Videos <CountBadge count={outcropObjectCount('videos')} />
            </OCNavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
}
