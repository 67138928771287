import { Link } from 'react-router';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { useDashboardOutletContext } from '~/routes/dashboard';
import { otherWikiDetailRoute } from '~/paths';

export default function DashboardOtherResultsRoute() {
  const ctx = useDashboardOutletContext();
  const results = ctx.wikiResults.other;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Other Articles</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {results.length === 0 && (
          <p className="text-muted">
            <em>
              No other wiki articles found matching the given search criteria.
            </em>
          </p>
        )}

        <ListGroup>
          {results.map(owResult => (
            <ListGroup.Item
              key={owResult.otherWiki.id}
              as={Link}
              to={otherWikiDetailRoute(owResult.otherWiki.id)}
              className="p-4"
            >
              {owResult.otherWiki.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}
