import { gql } from '~/apollo/client-v3';
import {} from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import type {
  SupplementalDocumentPartsFragment,
  UpdateSupplementalDocumentInput,
} from '~/apollo/generated/v3/graphql';
import { graphql } from '~/apollo/generated/v3';

export const CREATE_CROSS_SECTION = gql`
  mutation CreateCrossSection(
    $studyId: Int
    $outcropId: Int
    $crossSection: CrossSectionInput!
  ) {
    createCrossSection(
      studyId: $studyId
      outcropId: $outcropId
      crossSection: $crossSection
    ) {
      id
    }
  }
`;

export const UPDATE_CROSS_SECTION = gql`
  mutation UpdateCrossSection($id: Int!, $crossSection: CrossSectionInput!) {
    updateCrossSection(id: $id, crossSection: $crossSection) {
      ...crossSectionParts
    }
  }

  ${fragments.crossSectionParts}
`;

export const DELETE_CROSS_SECTION = gql`
  mutation DeleteCrossSection($id: Int!) {
    deleteCrossSection(id: $id)
  }
`;

export const CREATE_FACIES = gql`
  mutation CreateFacies($studyId: Int, $outcropId: Int, $facies: FaciesInput!) {
    createFacies(studyId: $studyId, outcropId: $outcropId, facies: $facies) {
      id
    }
  }
`;

export const UPDATE_FACIES = gql`
  mutation UpdateFacies($id: Int!, $facies: FaciesInput!) {
    updateFacies(id: $id, facies: $facies) {
      ...faciesParts
    }
  }

  ${fragments.faciesParts}
`;

export const DELETE_FACIES = gql`
  mutation DeleteFacies($id: Int!) {
    deleteFacies(id: $id)
  }
`;

export const CREATE_GIGA_PAN = gql`
  mutation CreateGigaPan(
    $studyId: Int
    $outcropId: Int
    $gigaPan: GigaPanInput!
  ) {
    createGigaPan(studyId: $studyId, outcropId: $outcropId, gigaPan: $gigaPan) {
      id
    }
  }
`;

export const UPDATE_GIGA_PAN = gql`
  mutation UpdateGigaPan($id: Int!, $gigaPan: GigaPanInput!) {
    updateGigaPan(id: $id, gigaPan: $gigaPan) {
      ...gigaPanParts
    }
  }

  ${fragments.gigaPanParts}
`;

export const DELETE_GIGA_PAN = gql`
  mutation DeleteGigaPan($id: Int!) {
    deleteGigaPan(id: $id)
  }
`;

export const CREATE_VARIOGRAM = gql`
  mutation CreateVariogram(
    $studyId: Int
    $outcropId: Int
    $variogram: VariogramInput!
  ) {
    createVariogram(
      studyId: $studyId
      outcropId: $outcropId
      variogram: $variogram
    ) {
      id
    }
  }
`;

export const UPDATE_VARIOGRAM = gql`
  mutation UpdateVariogram($id: Int!, $variogram: VariogramInput!) {
    updateVariogram(id: $id, variogram: $variogram) {
      ...variogramParts
    }
  }

  ${fragments.variogramParts}
`;

export const UPDATE_PICTURE = graphql(`
  mutation UpdatePicture($id: Int!, $picture: PictureInput!) {
    updatePicture(id: $id, picture: $picture) {
      ...pictureParts
    }
  }
`);

export const DELETE_PICTURE = gql`
  mutation DeletePicture(
    $id: Int!
    $parentId: Int!
    $parentType: PictureParent!
  ) {
    deletePicture(id: $id, parentType: $parentType, parentId: $parentId)
  }
`;

export const CREATE_PRODUCTION = gql`
  mutation CreateProduction(
    $studyId: Int
    $outcropId: Int
    $production: ProductionInput!
  ) {
    createProduction(
      studyId: $studyId
      outcropId: $outcropId
      production: $production
    ) {
      id
    }
  }
`;

export const UPDATE_PRODUCTION = gql`
  mutation UpdateProduction($id: Int!, $production: ProductionInput!) {
    updateProduction(id: $id, production: $production) {
      ...productionParts
    }
  }

  ${fragments.productionParts}
`;

export const DELETE_PRODUCTION = gql`
  mutation DeleteProduction($id: Int!) {
    deleteProduction(id: $id)
  }
`;

export const CREATE_RESERVOIR_MODEL = gql`
  mutation CreateReservoirModel(
    $studyId: Int
    $outcropId: Int
    $reservoirModel: ReservoirModelInput!
  ) {
    createReservoirModel(
      studyId: $studyId
      outcropId: $outcropId
      reservoirModel: $reservoirModel
    ) {
      id
    }
  }
`;

export const UPDATE_RESERVOIR_MODEL = gql`
  mutation UpdateReservoirModel(
    $id: Int!
    $reservoirModel: ReservoirModelInput!
  ) {
    updateReservoirModel(id: $id, reservoirModel: $reservoirModel) {
      ...reservoirModelParts
    }
  }

  ${fragments.reservoirModelParts}
`;

export const CREATE_SEDIMENTARY_LOG = gql`
  mutation CreateSedimentaryLog(
    $studyId: Int
    $outcropId: Int
    $sedimentaryLog: SedimentaryLogInput!
  ) {
    createSedimentaryLog(
      studyId: $studyId
      outcropId: $outcropId
      sedimentaryLog: $sedimentaryLog
    ) {
      id
    }
  }
`;

export const UPDATE_SEDIMENTARY_LOG = gql`
  mutation UpdateSedimentaryLog(
    $id: Int!
    $sedimentaryLog: SedimentaryLogInput!
  ) {
    updateSedimentaryLog(id: $id, sedimentaryLog: $sedimentaryLog) {
      ...sedimentaryLogParts
    }
  }

  ${fragments.sedimentaryLogParts}
`;

export const DELETE_SEDIMENTARY_LOG = gql`
  mutation DeleteSedimentaryLog($id: Int!) {
    deleteSedimentaryLog(id: $id)
  }
`;

export const CREATE_TRAINING_IMAGE = gql`
  mutation CreateTrainingImage(
    $studyId: Int
    $outcropId: Int
    $trainingImage: TrainingImageInput!
  ) {
    createTrainingImage(
      studyId: $studyId
      outcropId: $outcropId
      trainingImage: $trainingImage
    ) {
      id
    }
  }
`;

export const UPDATE_TRAINING_IMAGE = gql`
  mutation UpdateTrainingImage($id: Int!, $trainingImage: TrainingImageInput!) {
    updateTrainingImage(id: $id, trainingImage: $trainingImage) {
      ...trainingImageParts
    }
  }

  ${fragments.trainingImageParts}
`;

export const CREATE_WELL_LOG = gql`
  mutation CreateWellLog(
    $studyId: Int
    $outcropId: Int
    $wellLog: WellLogInput!
  ) {
    createWellLog(studyId: $studyId, outcropId: $outcropId, wellLog: $wellLog) {
      id
    }
  }
`;

export const UPDATE_WELL_LOG = gql`
  mutation UpdateWellLog($id: Int!, $wellLog: WellLogInput!) {
    updateWellLog(id: $id, wellLog: $wellLog) {
      ...wellLogParts
    }
  }

  ${fragments.wellLogParts}
`;

export const DELETE_WELL_LOG = gql`
  mutation DeleteWellLog($id: Int!) {
    deleteWellLog(id: $id)
  }
`;

export const DELETE_RESERVOIR_MODEL = gql`
  mutation DeleteReservoirModel($id: Int!) {
    deleteReservoirModel(id: $id)
  }
`;

export const DELETE_TRAINING_IMAGE = gql`
  mutation DeleteTrainingImage($id: Int!) {
    deleteTrainingImage(id: $id)
  }
`;

export const DELETE_VARIOGRAM = gql`
  mutation DeleteVariogram($id: Int!) {
    deleteVariogram(id: $id)
  }
`;

export type SuppDocFormValues = {
  note: string;
};

export function initialSuppDoc(
  sd?: SupplementalDocumentPartsFragment,
): SuppDocFormValues {
  return {
    note: sd?.note?.trim() || '',
  };
}

export function toSuppDocUpdateInput(
  formValues: SuppDocFormValues,
): UpdateSupplementalDocumentInput {
  return {
    note: formValues.note.trim() || null,
  };
}
