import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router';

import { Alert } from '~/components/ui/alert';

import * as fragments from '~/apollo/fragments';
import type {
  LithostratGroupPageQuery,
  LithostratGroupPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import { LithostratTypeBacklink } from '~/components/wiki/lithostratType/LithostratTypeBacklink';
import {
  lithostratGroupAnaloguesRoute,
  lithostratGroupExamplesRoute,
  lithostratGroupRoute,
} from '~/paths';

const LITHOSTRAT_GROUP_PAGE = gql`
  query LithostratGroupPage($groupId: Int!) {
    lithostratGroupList(id: $groupId, hasWiki: true) {
      ...lithostratGroupParts
      lithostratType {
        ...lithostratTypeParts
      }
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }

      formations {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      outcropLinkAnalogues {
        ...outcropLinkAnalogueParts
      }
      wikiPage {
        ...lithostratWikiPageParts
        keyParameters {
          ...keyParametersParts
        }
      }
    }
  }

  ${fragments.lithostratGroupParts}
  ${fragments.lithostratTypeParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratAgeParts}
  ${fragments.outcropLinkAnalogueParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.keyParametersParts}
`;

type OutletContext = {
  group: LithostratGroupPageQuery['lithostratGroupList'][number];
};

export default function LithsotratGroupRoute() {
  const params = useParams();
  if (!params.groupId) throw new Error('groupId param required');
  const groupId = parseInt(params.groupId);

  const { data, loading } = useQuery<
    LithostratGroupPageQuery,
    LithostratGroupPageQueryVariables
  >(LITHOSTRAT_GROUP_PAGE, {
    variables: { groupId },
  });

  const group = data?.lithostratGroupList.find(g => g.id === groupId);

  useBreadcrumb(
    'routes/wiki/regional/lithostrat/group.$groupId',
    group?.name ?? 'Lithostrat Group',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!group) return <NotFound />;

  const outletContext: OutletContext = { group };

  return (
    <>
      {!group.wikiPage?.published && (
        <Alert status="info" className="block p-2">
          <Heading level={3}>Notice</Heading>
          <p>
            Until this group is approved, it is only visible to you and Safari
            administrators.
          </p>
        </Alert>
      )}

      <div className="float-right space-x-1">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.Group}
          parentId={group.id}
          targetType={BookmarkTargetType.Group}
          targetId={group.id}
          path={lithostratGroupRoute(group.id)}
        />

        <TargetReviewCommentsModal
          parentId={group.id}
          parentType={ReviewCommentParentType.LithostratGroup}
          name={group.name}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={`/upload/lithostrat/group/${group.id}/wiki`}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </RoleSecured>
      </div>

      <LithostratTypeBacklink />

      <PageHeading hasSubtitle>{group.name}</PageHeading>
      <p className="my-4">{group.description}</p>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={lithostratGroupRoute(group.id)} end>
          Group
        </NavTabs.Tab>

        {group.outcropLinkAnalogues.length > 0 && (
          <NavTabs.Tab to={lithostratGroupExamplesRoute(group.id)}>
            Examples
          </NavTabs.Tab>
        )}

        <NavTabs.Tab to={lithostratGroupAnaloguesRoute(group.id)}>
          Linked Analogues
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />
    </>
  );
}

export function useGroupDetailOutletContext() {
  return useOutletContext<OutletContext>();
}
