import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import type { HTMLProps } from 'react';
import React from 'react';
import type { WikiPopoverProps } from '~/components/wiki/WikiPopover';
import type { FormLabelProps } from './FormLabel';
import { FormLabel } from './FormLabel';

export type SelectOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

export type FormikSelectFieldProps = FieldProps &
  Omit<HTMLProps<HTMLSelectElement>, 'form'> & {
    /** If present, overrides the default field.onChange event */
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => any;
    className?: string;
    isInvalid?: boolean;
    label?: React.ReactNode;
    options: SelectOption[];
    showBlankOption?: boolean;
    renderInput?: (input: JSX.Element) => JSX.Element;
    renderOption?: (option: SelectOption) => JSX.Element;
    helpText?: string;
    helpTextPlacement?: FormLabelProps['helpTextPlacement'];
    multiple?: boolean;
    required?: boolean;
    wikiPopoverId?: number;
    wikiPopoverProps?: WikiPopoverProps;
  };

export const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
  field,
  onChange,
  className,
  isInvalid,
  label,
  options,
  showBlankOption = true,
  renderInput,
  renderOption,
  helpText,
  helpTextPlacement,
  multiple = false,
  required = false,
  type,
  wikiPopoverId,
  wikiPopoverProps,
  ...props
}) => {
  const defaultOptionRenderer = (option: SelectOption) => (
    <option key={option.value} value={option.value} disabled={option.disabled}>
      {option.label}
    </option>
  );

  const value = field.value ?? '';

  const input = (
    <select
      {...field}
      {...props}
      form={undefined} // Unset from field props
      id={field.name}
      value={value}
      // Use provided onChange fn if provided through props
      onChange={onChange ?? field.onChange}
      className={cn(className, 'select select-bordered w-full', {
        'border-error border-2': isInvalid,
        'min-h-28': multiple,
      })}
      multiple={multiple}
      disabled={props.disabled}
    >
      {showBlankOption && !multiple && <option value="">- Select -</option>}
      {options.map(renderOption ? renderOption : defaultOptionRenderer)}
    </select>
  );

  return (
    <div
      className={cn('form-group', {
        'has-error': isInvalid,
        'text-error': isInvalid,
      })}
    >
      <FormLabel
        label={label}
        name={field.name}
        helpText={helpText}
        helpTextPlacement={helpTextPlacement}
        required={required}
        wikiPopoverId={wikiPopoverId}
        wikiPopoverProps={wikiPopoverProps}
      />
      {renderInput ? (
        renderInput(input)
      ) : (
        <>
          {input}
          {multiple && (
            <div>
              <small className="text-muted">
                Hold <span className="kbd">ctrl</span> or{' '}
                <span className="kbd">⌘</span> to select multiple items.
              </small>
            </div>
          )}
        </>
      )}
      {isInvalid && (
        <label className="label">
          <span className="label-text-alt text-error">
            <ErrorMessage name={field.name} />
          </span>
        </label>
      )}
    </div>
  );
};
