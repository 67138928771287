import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v3';
import { DepositionalWikiType } from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { useQueryString } from '~/hooks/routing';
import { uploadGeologyUpdateRoute } from '~/paths';
import type { DepositionalWikiFormValues } from '~/utils/modules/depositionalWiki';
import {
  defaultWikiPage,
  toDepositionalWikiInput,
  validationSchema,
} from '~/utils/modules/depositionalWiki';
import { DepositionalWikiFormFields } from './DepositionalWikiFormFields';
import { isEnumMember } from '~/utils/common';

export const CREATE_DEPOSITIONAL_WIKI = graphql(`
  mutation CreateDepositionalWiki($depositional: DepositionalWikiInput!) {
    createDepositionalWiki(depositional: $depositional) {
      ...depositionalWikiParts
    }
  }
`);

type QueryString = {
  geologyType?: string;
  type?: string;
  value?: string;
};

export function CreateDepositionalWikiForm() {
  const { query } = useQueryString<QueryString>();
  const navigate = useNavigate();
  const [createWikiPage, { loading, error }] = useMutation(
    CREATE_DEPOSITIONAL_WIKI,
  );

  async function handleSubmit(values: DepositionalWikiFormValues) {
    const depositionalInput = toDepositionalWikiInput(values);

    try {
      const result = await createWikiPage({
        variables: { depositional: depositionalInput },
      });
      const wikiPage = result.data?.createDepositionalWiki;
      invariant(wikiPage, 'Error parsing wikiPage from response!');
      toast.success('Wiki page saved successfully.');
      navigate(uploadGeologyUpdateRoute(wikiPage.id));
    } catch (err) {
      console.log('Error saving wiki page', err);
      toast.error('There was a problem saving the wiki page.');
    }
  }

  const initialValues = (): DepositionalWikiFormValues => {
    const queryType = query.type;

    const values = defaultWikiPage();
    if (query.geologyType) values.geologyType = query.geologyType;
    if (queryType && isEnumMember(DepositionalWikiType, queryType)) {
      values.type = queryType;
    }
    values.value = query.value ? [query.value] : [];
    return values;
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues()}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="space-y-4">
          <DepositionalWikiFormFields />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
