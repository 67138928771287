import { ErrorMessage } from '@hookform/error-message';
import { useFormState } from 'react-hook-form';

export function FieldErrors({ name }: { name: string }) {
  const { errors } = useFormState();

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ messages }) => (
        <ul className="list-none text-error text-xs">
          {messages &&
            Object.entries(messages).map(([type, message]) => (
              <li key={type}>{message}</li>
            ))}
        </ul>
      )}
    />
  );
}
