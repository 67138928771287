import { LithostratWikiParentType } from '~/apollo/generated/v3/graphql';
import { CreateLithostratWikiPage } from '~/components/upload/lithostrat/wiki/CreateLithostratWikiPage';
import { UpdateLithostratWikiForm } from '~/components/upload/lithostrat/wiki/UpdateLithostratWiki';
import { useUpdateFormationOutletContext } from '~/routes/upload/lithostrat/formation/$formationId';

export default function FormationUpdateWikiRoute() {
  const { formation, refetchQueries } = useUpdateFormationOutletContext();

  if (!formation.wikiPage) {
    return (
      <CreateLithostratWikiPage
        parentType={LithostratWikiParentType.Formation}
        parentId={formation.id}
        parentName={formation.name}
        refetchQueries={refetchQueries}
      />
    );
  }

  return (
    <UpdateLithostratWikiForm
      wikiPage={formation.wikiPage}
      refetchQueries={refetchQueries}
    />
  );
}
