import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { HTMLProps } from 'react';

export function HelpBox({
  className,
  children,
  ...props
}: HTMLProps<HTMLDivElement>) {
  return (
    <div className={className} {...props}>
      <div className="flex gap-6 bg-amber-50 border border-amber-100 shadow-sm p-4">
        <div className="shrink flex items-center">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="text-amber-200 text-3xl"
          />
        </div>
        <div className="flex items-center">
          <div className="block text-amber-900">{children}</div>
        </div>
      </div>
    </div>
  );
}
