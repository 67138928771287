import { cn } from '~/utils/common';
import type { FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { imageMimes } from '~/utils/formik';
import { toast } from 'react-toastify';

type Props = {
  hasFiles: boolean;
  onDrop: (files: File[]) => void;
};

export function FieldPicsDropzone({ hasFiles, onDrop }: Props) {
  function handleRejects(rejects: FileRejection[]) {
    const names = rejects.map(r => r.file.name).join('\n');
    toast.error(`The following files weren't accepted:\n${names}`);
  }

  return (
    <Dropzone
      onDrop={onDrop}
      accept={imageMimes}
      multiple
      onDropRejected={handleRejects}
      maxSize={100_000_000}
    >
      {({ getRootProps, getInputProps }) => (
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className={cn('flex items-center', {
              'h-72': !hasFiles,
              'h-20': hasFiles,
            })}
            style={{ transition: 'height 0.5s' }}
          >
            Drop pictures or folders of pictures here, or click to browse...
          </div>
        </DropzoneContainer>
      )}
    </Dropzone>
  );
}
