import type { MarkerClustererProps } from '@react-google-maps/api';
import {
  GoogleMap,
  InfoWindowF,
  MarkerClustererF,
  MarkerF,
} from '@react-google-maps/api';
import * as R from 'ramda';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router';
import type { SearchOutcropsOutcropResultsQuery } from '~/apollo/generated/v3/graphql';
import { outcropRoute } from '~/paths';
import { markerIcon } from '~/utils/georeference';

type Outcrop =
  SearchOutcropsOutcropResultsQuery['searchOutcrops']['outcrops'][number];

type OutcropWithCenter = Outcrop & {
  center: NonNullable<Outcrop['center']>;
};

type Props = {
  outcrops: Outcrop[];
  loading: boolean;
  setOutcropIdsInView: React.Dispatch<React.SetStateAction<number[]>>;
};

export const OutcropResultMap: React.FC<Props> = ({
  outcrops,
  loading,
  setOutcropIdsInView,
}: Props) => {
  // Array of outcrop ids for which an open info window should be displayed
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedOC, setSelectedOC] = useState<number[]>([]);

  // This class from @react-google-maps/api doesnt seem exposed
  type Clusterer = Parameters<NonNullable<MarkerClustererProps['onLoad']>>[0];
  const clustererRef = useRef<Clusterer>();

  const outcropsWithCenters = React.useMemo(
    () => outcrops.filter((oc): oc is OutcropWithCenter => !!oc.center),
    [outcrops],
  );

  useEffect(() => {
    clustererRef.current?.repaint();
  }, [outcropsWithCenters]);

  const currentOutcropIds = outcropsWithCenters.map(oc => oc.id);
  const currentOutcropIdsRef = useRef<number[]>(currentOutcropIds);

  const fitBounds = useCallback(
    (map?: google.maps.Map) => {
      if (!map) return;
      if (!outcropsWithCenters.length) {
        map.setZoom(1);
        map.setCenter({ lat: 0, lng: 0 });
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      outcropsWithCenters.forEach(oc => bounds.extend(oc.center));
      map.fitBounds(bounds);
      const zoom = map.getZoom();
      if (zoom) {
        if (zoom > 4) {
          map.setZoom(4);
        } else if (zoom < 1) {
          map.setZoom(1);
        }
      }
      handleBoundsChanged();
    },
    [outcropsWithCenters],
  );

  // When new outcrops are loaded, reset "selected" info windows
  useEffect(() => {
    if (loading) {
      if (selectedOC.length > 0) setSelectedOC([]);
    }
  }, [loading, selectedOC]);

  // When new outcrops are loaded, update map bounds
  useEffect(() => {
    const prevOutcropIds = currentOutcropIdsRef.current;
    const difference = R.symmetricDifference(currentOutcropIds, prevOutcropIds);
    const isOutcropsUpdated = difference.length > 0;

    if (isOutcropsUpdated && map) {
      fitBounds(map);
      updateVisibleOutcrops(map);
    }

    currentOutcropIdsRef.current = currentOutcropIds;
  }, [currentOutcropIds]);

  const findOutcropById = (outcropId: number) => {
    return outcrops.find(oc => oc.id === outcropId);
  };

  /** Bind the map instance to the component state so it can be used later */
  const handleLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
      fitBounds(map);
    },
    [outcropsWithCenters],
  );

  function handleBoundsChanged() {
    const nextZoom = map?.getZoom();
    if (!nextZoom) return;

    if (map) {
      updateVisibleOutcrops(map);
    }
  }

  function updateVisibleOutcrops(map: google.maps.Map) {
    const mapBounds = map.getBounds();
    if (mapBounds) {
      const outcropsInView = outcropsWithCenters.filter(oc =>
        mapBounds.contains(oc.center),
      );
      setOutcropIdsInView(outcropsInView.map(oc => oc.id));
    } else {
      setOutcropIdsInView(outcropsWithCenters.map(oc => oc.id));
    }
  }

  const handleMarkerClick = (outcropId: number) => () => {
    const nextSelected = R.pipe(R.append(outcropId), R.uniq)(selectedOC);
    setSelectedOC(nextSelected);
  };

  const handleInfoWindowClose = (outcropId: number) => () => {
    const nextSelected = R.without([outcropId], selectedOC);
    setSelectedOC(nextSelected);
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '400px',
        border: '1px solid #dddddd',
      }}
      onLoad={handleLoad}
      onIdle={handleBoundsChanged}
      options={{
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        streetViewControl: false,
        fullscreenControl: true,
      }}
    >
      {outcropsWithCenters.length > 0 && (
        <MarkerClustererF
          options={{
            maxZoom: 5,
          }}
          enableRetinaIcons
          onLoad={clusterer => (clustererRef.current = clusterer)}
        >
          {clusterer => (
            <>
              {outcropsWithCenters.map(oc => (
                <MarkerF
                  key={oc.id}
                  position={oc.center}
                  icon={markerIcon()}
                  clusterer={clusterer}
                  onClick={handleMarkerClick(oc.id)}
                  // Required for performance!!! We call repaint manually when outcrops change
                  noClustererRedraw
                />
              ))}
            </>
          )}
        </MarkerClustererF>
      )}

      {/* Selected outcrops' infowindows */}
      {selectedOC.map(outcropId => {
        const outcrop = findOutcropById(outcropId);
        if (!outcrop?.center) return null;
        const position = new google.maps.LatLng(
          outcrop.center?.lat,
          outcrop.center?.lng,
        );
        if (!position) return null;

        return (
          <InfoWindowF
            key={outcropId}
            position={position}
            onCloseClick={handleInfoWindowClose(outcropId)}
          >
            <>
              <h4 style={{ margin: 0 }}>{outcrop.name}</h4>
              <Link to={outcropRoute(outcrop.id)} target="_blank">
                View outcrop &raquo;
              </Link>
            </>
          </InfoWindowF>
        );
      })}
    </GoogleMap>
  );
};
