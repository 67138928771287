import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';
import * as R from 'ramda';

import { lithostratTypeParts } from '~/apollo/fragments';
import type { LithostratTypeSelectorQuery } from '~/apollo/generated/v3/graphql';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

const LITHOSTRAT_TYPE_SELECTOR = gql`
  query LithostratTypeSelector {
    lithostratTypeList {
      ...lithostratTypeParts
    }
  }

  ${lithostratTypeParts}
`;

type Props = FieldProps & {};

export function LithostratTypeSelector({ ...props }: Props): JSX.Element {
  const { data } = useQuery<LithostratTypeSelectorQuery>(
    LITHOSTRAT_TYPE_SELECTOR,
    {},
  );

  const lts = data?.lithostratTypeList ?? [];
  const sortedLTs = R.sortBy(R.propOr('', 'name'), lts);
  const options = sortedLTs.map(lt => ({ value: lt.id, label: lt.name ?? '' }));

  return <FormikSelectField {...props} options={options} />;
}
