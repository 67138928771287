import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router';
import type {
  FilePartsFragment,
  OutcropLinkAnaloguePartsFragment,
  OutcropPartsFragment,
  RegionPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Prose } from '~/components/common/Prose';
import { outcropRoute } from '~/paths';
import { truncateText } from '~/utils/common';

type OutcropRowItemProps = {
  outcrop: OutcropPartsFragment & {
    region?: RegionPartsFragment;
    thumbnail?: FilePartsFragment | null;
  };
};
function OutcropRowItem({ outcrop }: OutcropRowItemProps) {
  const thumbnail = outcrop.thumbnail
    ? outcrop.thumbnail.signedUrl
    : '/assets/images/page/icon-outcrop.jpg';

  // Strip HTML tags from introduction
  // let introduction = outcrop.description.replace(/<(?:.|\n)*?>/gm, '');
  const introduction = truncateText(outcrop.description, 500);

  return (
    <div>
      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-2">
          <Link to={outcropRoute(outcrop.id)}>
            <img
              className="hidden lg:block w-full h-auto mask-square mt-2"
              src={thumbnail}
              alt=""
            />
          </Link>
        </div>

        <div className="lg:col-span-10">
          <Heading level={3}>
            <Link
              to={`/outcrop/${outcrop.id}`}
              target="_blank"
              className="link"
            >
              {outcrop.name}
            </Link>{' '}
            {outcrop.region && (
              <Link
                to={`/region/${outcrop.region.id}`}
                target="_blank"
                className="link"
              >
                <small className="text-muted">
                  <i>{outcrop.region.name}</i>
                </small>
              </Link>
            )}
          </Heading>

          <Prose dangerouslySetInnerHTML={{ __html: introduction }} />

          <div className="mt-2">
            <Link
              to={outcropRoute(outcrop.id)}
              className="btn btn-primary btn-sm gap-1"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> View
            </Link>
          </div>
        </div>
      </div>

      <div className="divider" />
    </div>
  );
}

type Props = {
  analogues: OutcropLinkAnaloguePartsFragment[];
};

/**
 * Outcrops that have been manually linked to the parent
 * NOTE: Called "Examples" in the UI
 */
export function LinkedAnalogues({ analogues: analoguesProp }: Props) {
  const analogues = R.pipe(
    () => analoguesProp,
    R.uniqBy(outcrop => outcrop.id),
    R.sortBy(outcrop => outcrop.name),
  )();

  if (!analogues.length) {
    return (
      <div className="well text-center">
        <i>No outcrops have been linked.</i>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <Heading level={3}>Outcrops that are exposing the object:</Heading>

      {analogues.map(outcrop => (
        <OutcropRowItem key={outcrop.id} outcrop={outcrop} />
      ))}
    </div>
  );
}
