import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faEyeSlash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '~/components/ui/alert';
import { Link, Outlet, useOutletContext, useParams } from 'react-router';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  OutcropPageQuery,
  OutcropPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { Prose } from '~/components/common/Prose';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import type { BreadcrumbItem } from '~/components/layout/Breadcrumb';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import {
  outcropRoute,
  regionRoute,
  searchRoute,
  uploadOutcropUpdateRoute,
} from '~/paths';
import { OutcropRouteNavigation } from '~/routes/outcrop/__OutcropRouteNavigation';

type Outcrop = OutcropPageQuery['outcropList'][number];

const OUTCROP_PAGE = gql`
  query OutcropPage($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      region {
        ...regionParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.regionParts}
`;

function prependBreadcrumb(outcrop?: Outcrop) {
  if (!outcrop) return null;
  invariant(outcrop.region, 'Region must be loaded for breadcrumb');

  return [
    {
      routeId: searchRoute(),
      pathname: searchRoute(),
      title: 'Search Analogues',
    },
    {
      routeId: regionRoute(outcrop.region.id),
      pathname: regionRoute(outcrop.region.id),
      title: outcrop.region.name,
    },
  ] satisfies BreadcrumbItem[];
}

type OutletContext = {
  outcrop: Outcrop;
};

export default function OutcropDetailRoute() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  const { data, loading } = useQuery<
    OutcropPageQuery,
    OutcropPageQueryVariables
  >(OUTCROP_PAGE, { variables: { id: outcropId } });

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  useBreadcrumb(
    'routes/outcrop/$outcropId',
    outcrop?.name ?? 'Outcrop',
    null,
    prependBreadcrumb(outcrop),
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  const outletContext: OutletContext = { outcrop };

  return (
    <>
      {!outcrop.approved && (
        <Alert status="info">
          <FontAwesomeIcon icon={faEyeSlash} className="text-xl" />
          <div>
            <Heading level={4}>Outcrop Not Approved</Heading>
            <p>
              This outcrop has not been approved and is only visible to admins.
            </p>
          </div>
        </Alert>
      )}

      <div className="float-right space-x-1">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.Outcrop}
          parentId={outcropId}
          targetType={BookmarkTargetType.Outcrop}
          targetId={outcropId}
          path={outcropRoute(outcropId)}
        />

        <TargetReviewCommentsModal
          parentType={ReviewCommentParentType.Outcrop}
          parentId={outcrop.id}
          name={outcrop.name}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={uploadOutcropUpdateRoute(outcropId)}
            className="btn btn-sm btn-ghost gap-1"
          >
            <FontAwesomeIcon icon={faPencil} />
            Edit Outcrop
          </Link>
        </RoleSecured>
      </div>

      <PageHeading className="space-x-2">
        <span>{outcrop.name}</span>
        {outcrop.geologyType.map(gt => (
          <GeologyTypeIcon
            key={gt}
            dominant={outcrop.dominantGeologyType === gt}
            geologyType={gt}
            className="w-8 h-8"
          />
        ))}
      </PageHeading>

      {outcrop.description && (
        <Prose dangerouslySetInnerHTML={{ __html: outcrop.description }} />
      )}
      <div className="clear-both" />

      <div className="grid md:grid-cols-12 gap-6">
        <div className="md:col-span-3 lg:col-span-2 pt-2">
          <OutcropRouteNavigation outcropId={outcropId} />
        </div>

        <div className="md:col-span-9 lg:col-span-10 pt-2">
          <Outlet context={outletContext} />
        </div>
      </div>
    </>
  );
}

export function useOutcropOutletContext() {
  return useOutletContext<OutletContext>();
}
