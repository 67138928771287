import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';

import * as fragments from '~/apollo/fragments';
import type {
  UploadRegionOutcropsPageQuery,
  UploadRegionOutcropsPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UploadOutcropList } from '~/components/upload/outcrop/UploadOutcropList';
import { useSortFilter } from '~/hooks/data';
import { uploadOutcropCreateRoute } from '~/paths';
import { toSortableOutcrop } from '~/utils/modules/outcrop';
import { useUploadRegionPageContext } from '../$regionId';

const UPLOAD_REGION_OUTCROPS_PAGE = gql`
  query UploadRegionOutcropsPage($regionId: Int!) {
    outcropList(regionId: $regionId) {
      ...outcropParts
      region {
        ...regionParts
      }
      paleoMaps {
        ...paleoMapParts
      }
      studies {
        ...studyParts
      }
      virtualOutcropModels {
        ...vomParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.regionParts}
  ${fragments.paleoMapParts}
  ${fragments.studyParts}
  ${fragments.vomParts}
`;

export default function UploadRegionOutcropsPage() {
  const { region } = useUploadRegionPageContext();

  const { data, loading } = useQuery<
    UploadRegionOutcropsPageQuery,
    UploadRegionOutcropsPageQueryVariables
  >(UPLOAD_REGION_OUTCROPS_PAGE, {
    variables: { regionId: region.id },
  });

  const outcropList = data?.outcropList ?? [];
  const sortableOutcrops = outcropList.map(toSortableOutcrop);

  const { items, filterSearchProps, sortIndicatorProps } = useSortFilter(
    sortableOutcrops,
    'name',
    'name',
    'uploadRegionOutcropList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Outcrops
          {!loading && (
            <small className="text-muted" style={{ marginLeft: '10px' }}>
              (<strong>{items.length}</strong> results displayed)
            </small>
          )}
        </Panel.Title>

        <Link
          to={uploadOutcropCreateRoute()}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create Outcrop
        </Link>
      </Panel.Heading>

      <Panel.Body>
        {loading && <SpinnerPlaceholder />}
        {!loading && (
          <UploadOutcropList
            outcrops={items}
            sortIndicatorProps={sortIndicatorProps}
            filterSearchProps={filterSearchProps}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
