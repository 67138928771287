import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeleteLithostratTypeMutation,
  DeleteLithostratTypeMutationVariables,
} from '~/apollo/generated/v3/graphql';

const DELETE_LITHOSTRAT_TYPE = gql`
  mutation DeleteLithostratType($id: Int!) {
    deleteLithostratType(id: $id)
  }
`;

type Props = {
  children: (handleDelete: () => Promise<void>) => JSX.Element | null;
  lithostratTypeId: number;
  onDeleteSuccess?: () => void;
};

export function DeleteLithostratType({
  children,
  lithostratTypeId,
  onDeleteSuccess,
}: Props): JSX.Element | null {
  const [deleteLithostratType] = useMutation<
    DeleteLithostratTypeMutation,
    DeleteLithostratTypeMutationVariables
  >(DELETE_LITHOSTRAT_TYPE, {
    variables: { id: lithostratTypeId },
  });

  async function handleDelete() {
    try {
      await deleteLithostratType();
      if (onDeleteSuccess) onDeleteSuccess();
    } catch (err) {
      console.log('Error deleting lithostrat type', err);
      let errMessage = 'There was a problem deleting the lithostrat type.';

      if (err instanceof Error && err.message === 'not_empty_children') {
        errMessage =
          'This lithostrat type has children that must be removed before it can be deleted.';
      }

      toast.error(errMessage);
    }
  }

  return children(handleDelete);
}
