import { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import type { Authority } from '~/contexts/auth';
import { AuthContextProvider } from '~/contexts/auth';
import { requireOptionalUser } from '~/utils/auth';

export default function Root() {
  const [authority, setAuthority] = useState<Authority | null | false>(false);

  useEffect(() => {
    async function loadAuth() {
      const result = await requireOptionalUser();
      setAuthority(result);
    }

    loadAuth();
  }, []);

  if (authority === false) {
    return <SpinnerPlaceholder />;
  }

  return (
    <AuthContextProvider initialAuthority={authority}>
      <Outlet />
      <ScrollRestoration />
    </AuthContextProvider>
  );
}
