import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { useFormState } from 'react-hook-form';
import { z } from 'zod';
import { Heading } from '~/components/common/Heading';
import { Alert } from '~/components/ui/alert';
import { ucwords } from '~/utils/text';

const validationErrorsSchema_v3 = z.object({
  graphQLErrors: z.array(
    z.object({
      extensions: z.object({
        status: z.literal(422),
        originalError: z.object({
          message: z
            .array(
              z.object({
                path: z.string(),
                errors: z.array(z.string()),
              }),
            )
            .min(1),
        }),
      }),
    }),
  ),
});

type ValidationErrorResponse_v3 = z.infer<typeof validationErrorsSchema_v3>;

function parseValidationErrors(gqlErrors: unknown) {
  const result = validationErrorsSchema_v3.safeParse(gqlErrors);
  if (result.success) return result.data;
  else return null;
}

export function HookFormErrors({ graphQLError }: { graphQLError?: unknown }) {
  const formState = useFormState();

  const validationErrorResponse_v3 = parseValidationErrors(graphQLError);

  const hasFormErrors = Object.keys(formState.errors).length > 0;
  const hasApiErrors_v3 = validationErrorResponse_v3 !== null;

  if (!hasFormErrors && !hasApiErrors_v3) {
    return null;
  }

  return (
    <Alert status="error">
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-3xl" />
      <div>
        <Heading level={3}>There is a problem in the data submitted.</Heading>
        {hasFormErrors && (
          <ul className="list-none">
            {Object.keys(formState.errors).map(fieldName => (
              <li key={fieldName}>
                <strong>{ucwords(fieldName)}</strong>
                <ErrorMessage
                  name={fieldName}
                  errors={formState.errors}
                  render={({ messages }) => (
                    <ul className="list-inside list-disc pl-4">
                      {messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <li key={type}>{message}</li>
                        ))}
                    </ul>
                  )}
                />
              </li>
            ))}
          </ul>
        )}

        {hasApiErrors_v3 && (
          <ValidationErrorsV3 response={validationErrorResponse_v3} />
        )}
      </div>
    </Alert>
  );
}

function ValidationErrorsV3({
  response,
}: {
  response: ValidationErrorResponse_v3;
}) {
  const errors = response.graphQLErrors.flatMap(
    gqlError => gqlError.extensions.originalError.message,
  );

  return (
    <ul className="list-none">
      {errors.map(error => (
        <li key={error.path}>
          <strong>{error.path}</strong>
          <ul className="list-disc list-inside pl-4">
            {error.errors.map(e => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
