import { useEffect } from 'react';
import { Link, useRouteError } from 'react-router';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { homeRoute, supportRoute } from '~/paths';

export function ErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      console.log('Caught error:', error);
    }
  }, [error]);

  return (
    <div className="container mx-auto mt-20 max-w-2xl ">
      <Panel>
        <Panel.Body>
          <div className="space-y-4">
            <Heading level={3}>Something went wrong</Heading>
            <p>
              An unexpected error occurred. Please reload the page and try
              again, or if you continue to see this problem, submit a{' '}
              <Link to={supportRoute()} className="link">
                Support Request
              </Link>
              .
            </p>
            <p>
              Or return to the{' '}
              <Link to={homeRoute()} className="link">
                home page
              </Link>
              .
            </p>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
