import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  GeoreferenceDataType,
  GeoreferenceParent,
  type UpdateVomGeoreferencesPageQuery,
  type UpdateVomGeoreferencesPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GeoreferenceManager } from '~/components/upload/georeference/GeoreferenceManager';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';

export const UPDATE_VOM_GEOREFERENCES_PAGE = gql`
  query UpdateVomGeoreferencesPage($id: Int!) {
    virtualOutcropModelList(id: $id) {
      ...vomParts
      georeference {
        ...georeferenceParts
      }
    }
  }

  ${fragments.vomParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateVomGeoreferencesRoute() {
  const ctx = useUpdateVomOutletContext();
  const vomId = ctx.vom.id;

  const { data, loading } = useQuery<
    UpdateVomGeoreferencesPageQuery,
    UpdateVomGeoreferencesPageQueryVariables
  >(UPDATE_VOM_GEOREFERENCES_PAGE, {
    variables: { id: vomId },
  });

  const refetchQueries: [
    PureQueryOptions<UpdateVomGeoreferencesPageQueryVariables>,
  ] = [
    {
      query: UPDATE_VOM_GEOREFERENCES_PAGE,
      variables: { id: vomId },
    },
  ];

  const vom = (data?.virtualOutcropModelList ?? []).find(v => v.id === vomId);
  const georeferences = vom?.georeference ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <GeoreferenceManager
      georeferences={georeferences}
      dataTypes={[
        GeoreferenceDataType.Centre,
        GeoreferenceDataType.Outline,
        GeoreferenceDataType.Polygon,
        GeoreferenceDataType.Polyline,
        GeoreferenceDataType.Point,
      ]}
      limits={{ OUTLINE: 1, CENTRE: 1 }}
      parentType={GeoreferenceParent.Vom}
      parentId={vomId}
      refetchQueries={refetchQueries}
    />
  );
}
