import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type {
  DeleteTrashedAEsMutation,
  DeleteTrashedAEsMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';

const DELETE_TRASHED_AES = gql`
  mutation DeleteTrashedAEs($studyId: Int!) {
    deleteTrashedArchitecturalElements(studyId: $studyId)
  }
`;

type Props = {
  children: (confirmDelete: () => void) => JSX.Element;
  studyId: number;
  numTrashed: number;
  refetchQueries: PureQueryOptions[];
};

export function DeleteTrashedAEs({
  children,
  studyId,
  numTrashed,
  refetchQueries,
}: Props) {
  const [deleteTrashedAEs] = useMutation<
    DeleteTrashedAEsMutation,
    DeleteTrashedAEsMutationVariables
  >(DELETE_TRASHED_AES, {
    refetchQueries,
    variables: { studyId },
  });

  const handleDelete = async () => {
    try {
      const result = await deleteTrashedAEs();
      const numDeleted = result.data?.deleteTrashedArchitecturalElements ?? 0;
      toast.success(`${numDeleted} AEs deleted successfully.`);
    } catch (err) {
      console.log('Error trashing AEs', err);
      toast.error(
        'An error occurred while deleting trashed AEs. Please see the console for more information.',
      );
    }
  };

  if (!numTrashed) return null;

  return (
    <Confirm
      onConfirm={handleDelete}
      text={
        <div className="space-y-2">
          <h3 className="text-2xl">WARNING</h3>
          <p>
            This operation will <b className="text-error">PERMANENTLY DELETE</b>{' '}
            {numTrashed} architectural elements and their associated
            measurements. They will not be able to be recovered.
          </p>
          <p>
            If you are using this tool to export/import AEs and measurements,
            please ensure you have already saved these files before proceeding.
          </p>
        </div>
      }
      submitButtonColor="error"
      submitText="Permanently delete trashed AEs and Measurements"
    >
      {onConfirm => children(onConfirm)}
    </Confirm>
  );
}
