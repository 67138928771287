import type { PureQueryOptions } from '@apollo/client';
import {
  faCheckCircle,
  faCopy,
  faPencil,
  faPencilRuler,
  faScroll,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '~/components/ui/badge';
import { Link } from 'react-router';
import type { ReportListingQuery } from '~/apollo/generated/v3/graphql';
import { ReportState } from '~/apollo/generated/v3/graphql';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { CopyReportModal } from '~/components/report/CopyReportModal';
import { DeleteReport } from '~/components/report/DeleteReport';
import { ReportItemSummary } from '~/components/report/ReportItemSummary';
import { Button } from '~/components/ui/button';
import { editReportRoute, reportRoute } from '~/paths';
import { cn } from '~/utils/common';

export function ReportItemCard({
  report,
  canEdit,
  refetchQueries,
}: {
  report: ReportListingQuery['reportList'][number];
  canEdit: boolean;
  refetchQueries: PureQueryOptions[];
}) {
  const isCompany = !!report.companyId;

  return (
    <Panel
      key={report.id}
      className={cn('border-l-4', {
        'border-l-sky-500': !isCompany,
        'border-l-wine-500': isCompany,
      })}
    >
      <Panel.Body className="p-2">
        <div className="space-y-2">
          <div className="lg:flex justify-between gap-6">
            <div>
              <strong>
                <Link
                  to={editReportRoute(report.id)}
                  className="space-x-2 hover:underline"
                >
                  <FontAwesomeIcon
                    icon={faScroll}
                    className={
                      isCompany
                        ? 'text-wine-500 hover:text-wine-600'
                        : 'text-sky-500 hover:text-sky-600'
                    }
                  />
                  <span>{report.title}</span>
                </Link>
              </strong>
              <div className="text-sm text-muted">
                by {report.company?.name ?? report.user.name} - Last updated{' '}
                <LocalDate date={report.updatedAt} />
              </div>
            </div>

            <div className="flex gap-2 items-center">
              {report.state === ReportState.Draft && (
                <Badge className="bg-slate-100 text-slate-800 border border-slate-200">
                  <span>
                    <FontAwesomeIcon icon={faPencilRuler} /> Draft
                  </span>
                </Badge>
              )}
              {report.state === ReportState.Completed && (
                <Badge className="bg-emerald-200 text-emerald-800 border border-emerald-400">
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} /> Completed
                  </span>
                </Badge>
              )}
              <ReportItemSummary
                summary={report.itemSummary}
                isCompany={isCompany}
              />
            </div>
          </div>

          <div>{report.description}</div>
        </div>
      </Panel.Body>

      <Panel.Footer className="p-1 text-right space-x-1">
        <CopyReportModal reportId={report.id} isCompany={!!report.companyId}>
          {showCopyModal => (
            <Button
              type="button"
              size="sm"
              color="ghost"
              onClick={showCopyModal}
            >
              <FontAwesomeIcon icon={faCopy} /> Copy to{' '}
              {report.companyId ? 'My' : 'Company'} Reports
            </Button>
          )}
        </CopyReportModal>

        {canEdit && (
          <>
            {report.state === ReportState.Draft && (
              <DeleteReport
                reportId={report.id}
                refetchQueries={refetchQueries}
              >
                {showDeleteModal => (
                  <Button
                    type="button"
                    size="sm"
                    color="ghost"
                    onClick={showDeleteModal}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeleteReport>
            )}

            <Link
              to={editReportRoute(report.id)}
              className="btn btn-ghost btn-sm"
            >
              <FontAwesomeIcon icon={faPencil} /> Edit
            </Link>
          </>
        )}

        <Link
          to={reportRoute(report.id)}
          className="btn btn-ghost btn-sm"
          target="_blank"
        >
          <FontAwesomeIcon icon={faScroll} /> View Report
        </Link>
      </Panel.Footer>
    </Panel>
  );
}
