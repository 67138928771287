import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from '~/components/layout/Footer';
import { Navbar } from '~/components/layout/Navbar';
import { cn } from '~/utils/common';
import {
  Breadcrumb,
  BreadcrumbContext,
  BreadcrumbProvider,
} from './Breadcrumb';
import { FullWidthContext, FullWidthProvider } from './fullWidthContext';

type Props = {
  children: React.ReactNode;
};

export function AppShell({ children }: Props) {
  return (
    <>
      <HelmetProvider>
        <BreadcrumbProvider>
          <BreadcrumbContext.Consumer>
            {({ title }) => (
              <Helmet>
                <title>{title}</title>
              </Helmet>
            )}
          </BreadcrumbContext.Consumer>

          <Navbar />

          <FullWidthProvider>
            <FullWidthContext.Consumer>
              {({ isFullWidth }) => (
                <div
                  className={cn('container mx-auto space-y-4', {
                    'max-w-7xl': !isFullWidth,
                    'max-w-full': isFullWidth,
                  })}
                >
                  <Breadcrumb />
                  <main className="mt-2">{children}</main>
                </div>
              )}
            </FullWidthContext.Consumer>
          </FullWidthProvider>

          <div className="h-6" />
          <Footer />
        </BreadcrumbProvider>
      </HelmetProvider>
    </>
  );
}
