import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type {
  CreateIssueMutation,
  CreateIssueMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { FormValues } from '~/utils/modules/issue';
import {
  defaultIssue,
  issueFormValuesToInputType,
  validationSchema,
} from '~/utils/modules/issue';
import { IssueFormFields } from './IssueFormFields';

export const CREATE_ISSUE = gql`
  mutation CreateIssue($issue: IssueInput!) {
    createIssue(issue: $issue) {
      id
    }
  }
`;

type Props = {
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function CreateIssueModal({ refetchQueries, children }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createIssue, { loading, error }] = useMutation<
    CreateIssueMutation,
    CreateIssueMutationVariables
  >(CREATE_ISSUE, {
    refetchQueries,
  });

  async function handleSubmit(
    values: FormValues,
    formikBag: FormikHelpers<FormValues>,
  ) {
    try {
      const issue = issueFormValuesToInputType(values);
      await createIssue({ variables: { issue } });
      toast.success(
        'Your issue has been created successfully. A member of the SAFARI team will follow up as soon as possible.',
      );
      hideModal();
      formikBag.resetForm();
    } catch (err) {
      console.log('Error creating issue', err);
      toast.error('There was a problem creating the issue. Please try again.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultIssue()}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body heading="Create Issue" className="space-y-4">
              <IssueFormFields />
              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
