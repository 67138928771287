import React from 'react';
import { Button } from '~/components/ui/button';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { SortIndicator } from '~/components/common/icons/SortIndicator';
import FilterButton from './FilterButton';

interface Props {
  /** The property name of the column to be sorted */
  colName: string;
  /** JSX rendered as text */
  children: React.ReactNode;
  sortIndicatorProps: SortIndicatorProps;
  filterable?: boolean;
  renderFilterOption?: (value: string) => React.ReactNode;
  disabled?: boolean;
}

export function SortTrigger({
  colName,
  sortIndicatorProps,
  children,
  filterable = false,
  renderFilterOption,
  disabled = false,
}: Props): JSX.Element | null {
  function handleClick() {
    sortIndicatorProps.onSortChange(colName);
  }

  if (disabled) {
    return (
      <span className="btn btn-ghost btn-sm cursor-default p-[2px]">
        {children}
      </span>
    );
  }

  return (
    <>
      <Button
        type="button"
        color="ghost"
        size="sm"
        onClick={handleClick}
        className="inline-block p-0.5"
      >
        {children}&nbsp;
        <SortIndicator {...sortIndicatorProps} colName={colName} />
      </Button>

      {filterable && (
        <FilterButton
          name={typeof children === 'string' ? children : ''}
          options={sortIndicatorProps.categoryFilterOptions(colName)}
          values={sortIndicatorProps.getFiltersForCategory(colName)}
          onChange={sortIndicatorProps.onCategoryFilterChange(colName)}
          renderFilterOption={renderFilterOption}
        />
      )}
    </>
  );
}
