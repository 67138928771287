import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type {
  CreateKeyParametersMutation,
  CreateKeyParametersMutationVariables,
  KeyParametersParent,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import type { KeyParametersFormValues } from '~/utils/modules/keyParameters';
import {
  keyParametersInitialValues,
  kpFormValuesToInput,
} from '~/utils/modules/keyParameters';
import { KeyParametersFormFields } from './KeyParametersFormFields';

const CREATE_KEY_PARAMETERS = gql`
  mutation CreateKeyParameters(
    $parentType: KeyParametersParent!
    $parentId: Int!
    $keyParameters: KeyParametersInput!
  ) {
    createKeyParameters(
      parentType: $parentType
      parentId: $parentId
      keyParameters: $keyParameters
    ) {
      id
    }
  }
`;

type Props = {
  parentType: KeyParametersParent;
  parentId: number;
  geologyTypes: string[];
  refetchQueries?: PureQueryOptions[];
  disabled?: boolean;
};

export function CreateKeyParameters({
  parentType,
  parentId,
  geologyTypes,
  refetchQueries,
  disabled = false,
}: Props) {
  const [createKeyParameters, { loading, error }] = useMutation<
    CreateKeyParametersMutation,
    CreateKeyParametersMutationVariables
  >(CREATE_KEY_PARAMETERS, { refetchQueries });

  async function handleSubmit(
    values: KeyParametersFormValues,
    helpers: FormikHelpers<KeyParametersFormValues>,
  ) {
    const keyParameters = kpFormValuesToInput(values);

    try {
      await createKeyParameters({
        variables: {
          parentId,
          parentType,
          keyParameters,
        },
      });

      toast.success('Key parameters saved successfully.');

      helpers.resetForm({
        values: {
          ...keyParametersInitialValues(),
          geologyType: keyParameters.geologyType || '',
          grossDepositionalEnvironment:
            keyParameters.grossDepositionalEnvironment || '',
          depositionalEnvironment: keyParameters.depositionalEnvironment || '',
          depositionalSubEnvironment:
            keyParameters.depositionalSubEnvironment || '',
        },
      });
    } catch (err) {
      console.log('Error creating key parameters', err);
      toast.error('There was a problem saving the key parameters.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={keyParametersInitialValues()}
    >
      <Form className="space-y-4">
        <div>
          <KeyParametersFormFields
            geologyTypes={geologyTypes}
            disabled={disabled}
          />
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button
            type="submit"
            color="primary"
            disabled={disabled}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
