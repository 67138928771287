import { LithostratWikiParentType } from '~/apollo/generated/v3/graphql';
import { CreateLithostratWikiPage } from '~/components/upload/lithostrat/wiki/CreateLithostratWikiPage';
import { UpdateLithostratWikiForm } from '~/components/upload/lithostrat/wiki/UpdateLithostratWiki';
import { useMemberUpdateOutletContext } from '~/routes/upload/lithostrat/member/$memberId';

export default function MemberUpdateWikiRoute() {
  const { member, refetchQueries } = useMemberUpdateOutletContext();

  if (!member.wikiPage) {
    return (
      <CreateLithostratWikiPage
        parentType={LithostratWikiParentType.Member}
        parentId={member.id}
        parentName={member.name}
        refetchQueries={refetchQueries}
      />
    );
  }

  return (
    <UpdateLithostratWikiForm
      wikiPage={member.wikiPage}
      refetchQueries={refetchQueries}
    />
  );
}
