import * as R from 'ramda';
import type {
  GeologyType,
  KeyParametersInput,
  KeyParametersPartsFragment,
} from '~/apollo/generated/v3/graphql';

export type KeyParametersFormValues = {
  geologyType: string;
  grossDepositionalEnvironment: string;
  depositionalEnvironment: string;
  depositionalSubEnvironment: string;
  architecturalElement: string;
  dominant?: boolean;
};

export const keyParametersInitialValues = (): KeyParametersFormValues => ({
  geologyType: '',
  grossDepositionalEnvironment: '',
  depositionalEnvironment: '',
  depositionalSubEnvironment: '',
  architecturalElement: '',
});

export const keyParamsFields: Array<keyof KeyParametersFormValues> = [
  'geologyType',
  'grossDepositionalEnvironment',
  'depositionalEnvironment',
  'depositionalSubEnvironment',
  'architecturalElement',
  'dominant',
];

export interface MergedKeyParameters {
  grossDepositionalEnvironment: string[];
  depositionalEnvironment: string[];
  depositionalSubEnvironment: string[];
  architecturalElement: string[];
  diageneticProduct: string[];
}

type KPParts = Pick<
  KeyParametersPartsFragment,
  | 'grossDepositionalEnvironment'
  | 'depositionalEnvironment'
  | 'depositionalSubEnvironment'
  | 'architecturalElement'
>;

type KPArg = KPParts[] | KPParts[][];
// type ParamName = keyof Omit<KeyParametersFormValues, 'geologyType'>;
type ParamName = keyof KPParts;

const uniqueParams =
  (keyParameters: KPArg = []) =>
  (paramName: ParamName): string[] => {
    const flattened = R.flatten(keyParameters);

    // When looking at AEs, remove diagenetic products
    const withoutDPs = flattened.filter(kps =>
      paramName === 'architecturalElement'
        ? kps.depositionalSubEnvironment !== 'Diagenetic product'
        : true,
    );

    const plucked = withoutDPs.map(kps => kps[paramName]);

    // If looking at SEs, remove the 'diagenetic product' option
    const withoutDPLabel = plucked.filter(item =>
      paramName === 'depositionalSubEnvironment'
        ? item !== 'Diagenetic product'
        : true,
    );

    const nonNulls = withoutDPLabel.filter(
      (item): item is NonNullable<string> => !!item,
    );

    return R.pipe(R.uniq, R.sortBy(R.identity))(nonNulls);
  };

const diageneticProducts = (keyParameters: KPArg = []): string[] => {
  const dps = R.flatten(keyParameters)
    .filter(kp => kp.depositionalSubEnvironment === 'Diagenetic product')
    .map(kp => kp.architecturalElement)
    .filter((ae): ae is NonNullable<string> => !!ae);

  return R.pipe(R.uniq, R.sortBy(R.identity))(dps);
};

export function mergeKeyParameters(
  keyParameters: KPArg = [],
): MergedKeyParameters {
  const mergeParams = uniqueParams(keyParameters);
  return {
    grossDepositionalEnvironment: mergeParams('grossDepositionalEnvironment'),
    depositionalEnvironment: mergeParams('depositionalEnvironment'),
    depositionalSubEnvironment: mergeParams('depositionalSubEnvironment'),
    architecturalElement: mergeParams('architecturalElement'),
    diageneticProduct: diageneticProducts(keyParameters),
  };
}

export const supportedGeologyTypes = ['structural', 'clastic', 'carbonate'];

export function geologyTypeEnumToValue(gt: GeologyType) {
  switch (gt) {
    case 'STRUCTURAL':
    case 'CLASTIC':
    case 'CARBONATE':
      return gt.toLowerCase();

    default:
      return null;
  }
}

const undefinedIfEmpty = (val: string) => val || 'undefined';
/** Feed this into an R.evolve call. Replaces blank values with "undefined" */
export const evolveUndefined = {
  depositionalEnvironment: undefinedIfEmpty,
  depositionalSubEnvironment: undefinedIfEmpty,
  architecturalElement: undefinedIfEmpty,
};

export const kpFormValuesToInput = (
  fv: KeyParametersFormValues,
): KeyParametersInput => ({
  geologyType: fv.geologyType,
  grossDepositionalEnvironment: fv.grossDepositionalEnvironment,
  depositionalEnvironment: undefinedIfEmpty(fv.depositionalEnvironment),
  depositionalSubEnvironment: undefinedIfEmpty(fv.depositionalSubEnvironment),
  architecturalElement: undefinedIfEmpty(fv.architecturalElement),
});

export const kpFieldName =
  (geologyType: string | null) =>
  (field: string): string => {
    if (geologyType === 'structural') {
      switch (field) {
        case 'grossDepositionalEnvironment':
          return 'Structure class';
        case 'depositionalEnvironment':
          return 'Structure type';
        case 'depositionalSubEnvironment':
          return 'Structure style';
        case 'architecturalElement':
          return 'Architectural element';
        default:
          return field;
      }
    }

    switch (field) {
      case 'grossDepositionalEnvironment':
        return 'Gross depositional environment';
      case 'depositionalEnvironment':
        return 'Depositional environment';
      case 'depositionalSubEnvironment':
        return 'Subenvironment';
      case 'architecturalElement':
        return 'Architectural element';
      default:
        return field;
    }
  };

export const kpFieldAbbr =
  (geologyType: string | null) =>
  (field: string): string => {
    if (geologyType === 'structural') {
      switch (field) {
        case 'grossDepositionalEnvironment':
          return 'Structure class';
        case 'depositionalEnvironment':
          return 'Structure type';
        case 'depositionalSubEnvironment':
          return 'Structure style';
        case 'architecturalElement':
          return 'Arch. Element';
        default:
          return field;
      }
    }

    switch (field) {
      case 'grossDepositionalEnvironment':
        return 'GDE';
      case 'depositionalEnvironment':
        return 'DE';
      case 'depositionalSubEnvironment':
        return 'SE';
      case 'architecturalElement':
        return 'AE';
      default:
        return field;
    }
  };
