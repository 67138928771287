import { type PureQueryOptions } from '@apollo/client';
import {
  faPencil,
  faPlus,
  faSort,
  faThumbtack,
  faThumbTackSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import invariant from 'tiny-invariant';
import type { BookmarkCollectionListPartsFragment } from '~/apollo/generated/v3/graphql';
import CreateCollectionModal from '~/components/bookmark/CreateCollectionModal';
import { DeleteBookmarkCollection } from '~/components/bookmark/DeleteBookmarkCollection';
import { BookmarkCollectionIcon } from '~/components/bookmark/icons';
import { PinCollectionToggle } from '~/components/bookmark/PinCollectionToggle';
import { UpdateCollectionModal } from '~/components/bookmark/UpdateCollectionModal';
import { FilterSearch } from '~/components/common/FilterSearch';
import { Heading } from '~/components/common/Heading';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useAuth } from '~/contexts/auth';
import { useSortFilter } from '~/hooks/data';
import { bookmarkCollectionRoute } from '~/paths';
import { cn } from '~/utils/common';
import { canEditCollection } from '~/utils/modules/bookmark';

export function BookmarkCollectionList({
  collections,
  isCompany,
  refetchQueries,
}: {
  collections: BookmarkCollectionListPartsFragment[];
  isCompany: boolean;
  refetchQueries: PureQueryOptions[];
}) {
  const { items, sortIndicatorProps, filterSearchProps, isFiltered } =
    useSortFilter(collections, 'name', 'name', 'bookmarkCollectionList');

  const [isSortVisible, setIsSortVisible] = useState(isFiltered);

  return (
    <>
      <div className="flex gap-6 justify-between items-center mb-2">
        <Heading level={3}>
          {isCompany ? 'Company' : 'My'} Bookmark Collections
        </Heading>

        <CreateCollectionModal
          isCompany={isCompany}
          refetchQueries={refetchQueries}
        >
          {showModal => (
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={showModal}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              Create{isCompany && ' Company'} Collection
            </Button>
          )}
        </CreateCollectionModal>
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center gap-6">
          <div className="grow">
            <FilterSearch
              {...filterSearchProps}
              stick={false}
              showAlphabet={false}
              renderSearch={input => <div>{input}</div>}
            />
          </div>

          <div className="space-x-2">
            {isSortVisible && (
              <>
                <SortTrigger
                  colName="name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Name
                </SortTrigger>
                <SortTrigger
                  colName="updatedAt"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Last Updated
                </SortTrigger>
              </>
            )}
            <Button
              type="button"
              color="ghost"
              size="sm"
              onClick={() => setIsSortVisible(!isSortVisible)}
              startIcon={<FontAwesomeIcon icon={faSort} />}
            >
              Sort
            </Button>
          </div>
        </div>

        <div className="space-y-4">
          {items.map(collection => (
            <CollectionListItem
              key={collection.id}
              collection={collection}
              refetchQueries={refetchQueries}
            />
          ))}

          {!items.length && (
            <div className="text-center text-muted italic">
              No{isCompany ? ' company' : ''} collections have been created yet.
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function CollectionListItem({
  collection,
  refetchQueries,
}: {
  collection: BookmarkCollectionListPartsFragment;
  refetchQueries: PureQueryOptions[];
}) {
  const { authority } = useAuth();
  invariant(authority);

  const isCompany = !!collection.companyId;

  return (
    <Panel
      className={cn('border-l-4', {
        'border-l-sky-500': !collection.companyId,
        'border-l-wine-500': !!collection.companyId,
      })}
    >
      <Panel.Body className="p-2 space-y-2">
        <div>
          <div className="float-right ml-2 mb-2 text-muted text-base">
            {collection.bookmarkCount}{' '}
            {collection.bookmarkCount === 1 ? 'bookmark' : 'bookmarks'}
          </div>
          <strong>
            <Link
              to={bookmarkCollectionRoute(collection.id)}
              className="space-x-2 hover:underline"
            >
              <BookmarkCollectionIcon isCompany={isCompany} />
              <span className="hover:underline">{collection.name}</span>
            </Link>
          </strong>
          <div className="text-sm text-muted">
            Last updated <LocalDate date={collection.updatedAt} />
          </div>
        </div>

        {collection.description?.length && <div>{collection.description}</div>}
      </Panel.Body>

      <Panel.Footer className="p-1">
        <div className="flex justify-between gap-1">
          <div>
            <PinCollectionToggle
              collectionId={collection.id}
              isPinned={collection.isPinned}
            >
              {(handlePin, loading) => (
                <Button
                  type="button"
                  onClick={handlePin}
                  disabled={loading}
                  color="ghost"
                  size="sm"
                  startIcon={
                    <FontAwesomeIcon
                      icon={
                        collection.isPinned ? faThumbTackSlash : faThumbtack
                      }
                    />
                  }
                >
                  {collection.isPinned ? 'Unpin from' : 'Pin to'} Sidebar
                </Button>
              )}
            </PinCollectionToggle>
          </div>

          {canEditCollection(authority, collection) && (
            <div className="space-x-1">
              <DeleteBookmarkCollection
                collection={collection}
                refetchQueries={refetchQueries}
              >
                {confirmDelete => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    className="gap-1"
                    onClick={confirmDelete}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeleteBookmarkCollection>
              <UpdateCollectionModal collection={collection}>
                {showModal => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={showModal}
                    startIcon={<FontAwesomeIcon icon={faPencil} />}
                  >
                    Edit
                  </Button>
                )}
              </UpdateCollectionModal>
            </div>
          )}
        </div>
      </Panel.Footer>
    </Panel>
  );
}
