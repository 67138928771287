import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v3';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { PageFormFields } from '~/components/upload/page/PageFormFields';
import { adminPageUpdateRoute } from '~/paths';
import { useHookForm } from '~/utils/forms';
import type { PageFormValues } from '~/utils/modules/page';
import { pageFormValues, pageSchema } from '~/utils/modules/page';
import { ucwords } from '~/utils/text';
import { useUploadPageContext } from '../page';

const CREATE_PAGE = graphql(`
  mutation createPage($page: PageInput!) {
    createPage(page: $page) {
      id
    }
  }
`);

function createInitialValues(slug: string | null) {
  const initialPage = pageFormValues();
  if (slug) {
    let title = slug
      .trim()
      .toLowerCase()
      .replaceAll(/[^\w]/g, ' ')
      .replaceAll(/\s+/g, ' ');
    title = ucwords(title);

    initialPage.pageName = slug;
    initialPage.title = title;
  }

  return initialPage;
}

export default function AdminPageCreate() {
  useBreadcrumb('routes/admin/page/index', 'Create Page');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { refetchQueries } = useUploadPageContext();

  const [createPage, { loading, error }] = useMutation(CREATE_PAGE, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const form = useHookForm({
    values: createInitialValues(searchParams.get('slug')),
    schema: pageSchema,
  });

  async function handleSubmit(values: PageFormValues) {
    try {
      const res = await createPage({ variables: { page: values } });
      const pageId = res.data?.createPage.id;
      invariant(pageId, 'Error parsing response');
      toast.success('Page created successfully.');
      navigate(adminPageUpdateRoute(pageId));
    } catch (err) {
      console.log('Error creating page', err);
      toast.error('There was a problem creating the page.');
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Create Page</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="space-y-4">
            <PageFormFields />
            <HookFormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer align="right">
            <Button type="submit" color="primary" disabled={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </FormProvider>
  );
}
