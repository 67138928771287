import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { DepositionalWikiEnumsQuery } from '~/apollo/generated/v3/graphql';
import {
  DepositionalWikiType,
  GeologyType,
} from '~/apollo/generated/v3/graphql';
import {
  DEPOSITIONAL_WIKI_ENUMS,
  GEOLOGY_TYPE_ENUMS,
} from '~/apollo/operations/wiki';
import { Heading } from '~/components/common/Heading';
import { ListGroup } from '~/components/common/ListGroup';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useQueryString } from '~/hooks/routing';
import { uploadGeologyCreateRoute, uploadGeologyUpdateRoute } from '~/paths';
import { supportedGeologyTypes } from '~/utils/modules/keyParameters';
import { snakeCapsToHuman, ucwords } from '~/utils/text';

const DEPOSITIONAL_OVERVIEW_ROUTE = graphql(`
  query DepositionalOverviewRoute {
    depositionalList {
      ...depositionalWikiParts
    }
  }
`);

export default function DepositionalOverviewRoute() {
  useBreadcrumb(
    'routes/upload/depositional.overview',
    'Depositional Article Overview',
  );
  const { stringify } = useQueryString();

  const [showExisting, setShowExisting] = useState<boolean>(true);
  const [hiddenGeologyTypes, setHiddenGeologyTypes] = useState<
    Record<string, boolean>
  >({});

  const toggleShowExisting = () => setShowExisting(!showExisting);

  const toggleHiddenGeologyType = (geologyType: string) => () => {
    const isHidden = hiddenGeologyTypes[geologyType] || false;
    setHiddenGeologyTypes({
      ...hiddenGeologyTypes,
      [geologyType]: !isHidden,
    });
  };

  /** Whether a geology type is currently hidden */
  function isGeologyTypeHidden(geologyType: string) {
    return hiddenGeologyTypes[geologyType] || false;
  }

  const { data: geologyTypeEnumsData, loading: loadingGeologyTypeEnums } =
    useQuery(GEOLOGY_TYPE_ENUMS);

  const { data: enumDataClastic, loading: loadingEnumsClastic } = useQuery(
    DEPOSITIONAL_WIKI_ENUMS,
    { variables: { geologyType: GeologyType.Clastic } },
  );
  const { data: enumDataCarbonate, loading: loadingEnumsCarbonate } = useQuery(
    DEPOSITIONAL_WIKI_ENUMS,
    { variables: { geologyType: GeologyType.Carbonate } },
  );
  const { data: enumDataStructural, loading: loadingEnumsStructural } =
    useQuery(DEPOSITIONAL_WIKI_ENUMS, {
      variables: { geologyType: GeologyType.Structural },
    });

  type EnumData = Record<string, DepositionalWikiEnumsQuery | undefined>;

  const loadingEnums =
    loadingEnumsCarbonate || loadingEnumsClastic || loadingEnumsStructural;

  const enumData: EnumData = {
    clastic: enumDataClastic,
    carbonate: enumDataCarbonate,
    structural: enumDataStructural,
  };

  const {
    data: depositionalWikiListData,
    loading: loadingDepositionalWikiList,
  } = useQuery(DEPOSITIONAL_OVERVIEW_ROUTE);

  function findArticle(
    geologyType: string,
    type: DepositionalWikiType,
    value: string,
  ) {
    const wikis = depositionalWikiListData?.depositionalList ?? [];
    return wikis.find(
      article =>
        article.geologyType === geologyType &&
        article.type === type &&
        (article.value ?? []).includes(value),
    );
  }

  const geologyTypes =
    geologyTypeEnumsData?.outcropEnumerations.values.filter(value =>
      supportedGeologyTypes.includes(value),
    ) ?? [];

  function wikiValuesByType(
    geologyType: keyof EnumData,
    type: DepositionalWikiType,
  ): string[] {
    return R.pipe(
      R.pathOr([], [geologyType, type, 'values']),
      R.sortBy(R.identity),
    )(enumData);
  }

  const loading =
    loadingGeologyTypeEnums || loadingEnums || loadingDepositionalWikiList;

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading all depositional articles, this may take a moment...
      </SpinnerPlaceholder>
    );
  }

  return (
    <>
      <div className="flex justify-between items-end p-2 mb-2">
        <div>
          <PageHeading className="m-0">
            Depositional Article Overview
          </PageHeading>
          <p>
            Listing all depositional wiki articles overlaid on the current
            depositional schema.
          </p>
        </div>

        <div className="form-control">
          <label
            htmlFor="toggleShowExisting"
            className="label justify-start gap-2 cursor-pointer"
          >
            <input
              type="checkbox"
              id="toggleShowExisting"
              value="whatever"
              onChange={toggleShowExisting}
              checked={showExisting}
              className="checkbox"
            />
            <span className="label-text">Show existing articles</span>
          </label>
        </div>
      </div>

      <div className="space-y-6">
        {geologyTypes.map(geologyType => (
          <Panel key={geologyType}>
            <Panel.Heading>
              <Panel.Title>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={toggleHiddenGeologyType(geologyType)}
                >
                  <Tooltip
                    message={`Click to ${
                      isGeologyTypeHidden(geologyType) ? 'show' : 'collapse'
                    } this geology type`}
                  >
                    <span>
                      {ucwords(geologyType)}{' '}
                      <ExpandedIcon
                        expanded={!isGeologyTypeHidden(geologyType)}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Panel.Title>
            </Panel.Heading>

            {!isGeologyTypeHidden(geologyType) && (
              <Panel.Body>
                {Object.values(DepositionalWikiType).map(type => (
                  <React.Fragment key={type}>
                    <Heading level={4} className="pl-2 my-2">
                      {snakeCapsToHuman(type)}
                    </Heading>

                    <ListGroup>
                      {wikiValuesByType(geologyType, type).map(value => {
                        const article = findArticle(geologyType, type, value);
                        if (!showExisting && article) return null;

                        return (
                          <ListGroup.Item
                            key={value}
                            className="flex justify-between items-center"
                          >
                            <div>
                              {article && (
                                <Link
                                  to={uploadGeologyUpdateRoute(article.id)}
                                  target="_blank"
                                >
                                  {value}
                                </Link>
                              )}
                              {!article && <>{value}</>}
                            </div>

                            {!article && (
                              <Link
                                to={{
                                  pathname: uploadGeologyCreateRoute(),
                                  search: stringify({
                                    geologyType,
                                    type,
                                    value,
                                  }),
                                }}
                                target="_blank"
                                className="btn btn-primary btn-xs gap-1"
                              >
                                <FontAwesomeIcon icon={faPlus} /> Create
                              </Link>
                            )}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </React.Fragment>
                ))}
              </Panel.Body>
            )}
          </Panel>
        ))}
      </div>
    </>
  );
}
