import type * as schema from '~/apollo/generated/v3/graphql';
import { ReportItemType, Role } from '~/apollo/generated/v3/graphql';
import { targetName } from '~/utils/modules/bookmark';
import { yup } from '~/utils/validation';

export type ReportFormValues = {
  report: {
    title: string;
    description: string;
  };
  ordering: schema.UpdateReportItemsOrderInput[];
};

export function initialReportFormValues(
  report?: schema.ReportPartsFragment,
): ReportFormValues {
  return {
    report: {
      title: report?.title ?? '',
      description: report?.description ?? '',
    },
    // Items will be set in an editor component effect
    ordering: [],
  };
}

export const toCreateReportInput = (
  values: ReportFormValues,
  isCompany: boolean,
  initialCollectionId?: number,
): schema.CreateReportInput => ({
  ...toUpdateReportInput(values),
  isCompany,
  initialCollectionId,
});

export function toUpdateReportInput(
  formValues: ReportFormValues,
): schema.UpdateReportInput {
  return {
    title: formValues.report.title.trim(),
    description: formValues.report.description.trim() || null,
  };
}

export const reportValidationSchema = yup.object({
  report: yup.object({
    title: yup.string().min(1).required(),
    description: yup.string().optional().nullable(),
  }),
});

export function reportItemTitle(
  reportItem: Pick<schema.ReportItemPartsFragment, 'title' | 'itemType'> & {
    bookmark?: Parameters<typeof targetName>[0] | null;
    savedDataSearch?: Pick<schema.SavedDataSearchPartsFragment, 'name'> | null;
  },
) {
  if (reportItem.title) {
    // If the item has a title set, it always overrides the title inferred from
    // its linked entity
    return reportItem.title;
  } else if (
    reportItem.itemType === ReportItemType.Bookmark &&
    reportItem.bookmark
  ) {
    return targetName(reportItem.bookmark);
  }

  return 'Report Item';
}

export function reportItemTypeName(itemType: ReportItemType) {
  switch (itemType) {
    case ReportItemType.Bookmark:
      return 'Bookmark';
    case ReportItemType.Text:
      return 'Text';
  }
}

// Report Item: Bookmark

export type ReportItemFormValuesBookmark = {
  text: string;
  useNoteAsText: boolean;
  outcropShowDescription: boolean;
  outcropShowIntroduction: boolean;
  outcropShowEnvironments: boolean;
  outcropShowStratigraphy: boolean;
  outcropShowDiagenesisAndPetrophysicalProperties: boolean;
  outcropShowStructuralGeology: boolean;
  outcropShowNotablesAndAnalogues: boolean;
  outcropShowLocation: boolean;
  outcropShowSafari: boolean;
  outcropShowKeyParameters: boolean;
  outcropShowPaleogeography: boolean;
  studyShowAbstract: boolean;
};

export function reportItemBookmarkInitialValues(
  ri?: schema.ReportItemPartsFragment,
  bookmark?: schema.BookmarkPartsFragment,
): ReportItemFormValuesBookmark {
  return {
    text: ri?.text ?? bookmark?.note ?? '',
    useNoteAsText:
      (!ri && !!bookmark?.note?.length) ||
      (!!ri && !!bookmark?.note?.length && ri.text === bookmark.note),
    outcropShowDescription: ri?.outcropShowDescription ?? true,
    outcropShowIntroduction: ri?.outcropShowIntroduction ?? true,
    outcropShowEnvironments: ri?.outcropShowEnvironments ?? false,
    outcropShowStratigraphy: ri?.outcropShowStratigraphy ?? false,
    outcropShowDiagenesisAndPetrophysicalProperties:
      ri?.outcropShowDiagenesisAndPetrophysicalProperties ?? false,
    outcropShowStructuralGeology: ri?.outcropShowStructuralGeology ?? false,
    outcropShowNotablesAndAnalogues:
      ri?.outcropShowNotablesAndAnalogues ?? false,
    outcropShowLocation: ri?.outcropShowLocation ?? false,
    outcropShowSafari: ri?.outcropShowSafari ?? false,
    outcropShowKeyParameters: ri?.outcropShowKeyParameters ?? false,
    outcropShowPaleogeography: ri?.outcropShowPaleogeography ?? false,
    studyShowAbstract: ri?.studyShowAbstract ?? false,
  };
}

export const reportItemBookmarkValidationSchema = yup.object({
  text: yup.string().optional().nullable(),
  outcropShowDescription: yup.boolean().optional(),
  outcropShowIntroduction: yup.boolean().optional(),
  outcropShowEnvironments: yup.boolean().optional(),
  outcropShowStratigraphy: yup.boolean().optional(),
  outcropShowDiagenesisAndPetrophysicalProperties: yup.boolean().optional(),
  outcropShowStructuralGeology: yup.boolean().optional(),
  outcropShowNotablesAndAnalogues: yup.boolean().optional(),
  outcropShowLocation: yup.boolean().optional(),
  outcropShowSafari: yup.boolean().optional(),
  outcropShowKeyParameters: yup.boolean().optional(),
  outcropShowPaleogeography: yup.boolean().optional(),
  studyShowAbstract: yup.boolean().optional(),
});

// Report Item: Text

export type ReportItemFormValuesText = {
  title: string;
  text: string;
};

export function reportItemTextInitialValues(
  ri?: schema.ReportItemPartsFragment,
) {
  return {
    title: ri?.title ?? '',
    text: ri?.text ?? '',
  } satisfies ReportItemFormValuesText;
}

export const reportItemTextValidationSchema = yup.object({
  title: yup.string().min(1),
  text: yup.string().nullable().optional(),
});

export function canEditReport(
  authority: schema.AuthorityPartsFragment,
  report: schema.ReportPartsFragment,
) {
  if (report.userId === authority.user.id) {
    return true;
  }

  if (
    report.companyId &&
    report.companyId === authority.user.companyId &&
    authority.roles.includes(Role.RoleCompanyAdmin)
  ) {
    return true;
  }

  return false;
}
