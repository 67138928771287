import type {
  MiniModelPartsFragment,
  Photo360PartsFragment,
  UpdateMiniModelInput,
  UpdateUrlBasedSoInput,
  VideoPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type UrlBasedSOFormValues = {
  name: string;
  url: string;
  collectedBy: string;
  yearCollected: string;
  equipment: string;
};

type UBSO = Photo360PartsFragment | VideoPartsFragment;

export function initialUrlBasedSO(prev?: UBSO): UrlBasedSOFormValues {
  return {
    name: prev?.name ?? '',
    url: prev?.url ?? '',
    collectedBy: prev?.collectedBy ?? '',
    yearCollected: prev?.yearCollected.toString() ?? '',
    equipment: prev?.equipment ?? '',
  };
}

export function urlBasedSOToInput(fv: UrlBasedSOFormValues) {
  return {
    ...fv,
    yearCollected: parseInt(fv.yearCollected),
  } satisfies UpdateUrlBasedSoInput;
}

export const urlBasedSOValidationSchema = yup.object({
  name: yup.string().required(),
  url: yup.string().url().required(),
  collectedBy: yup.string().required(),
  yearCollected: yup.number().integer().positive().required(),
  equipment: yup.string().required(),
});

///

// Mini-models

export type MiniModelFormValues = {
  name: string;
  url: string;
  collectedBy: string;
  yearCollected: string;
  equipment: string;
  scaniverseName: string;
  sketchfabName: string;
  comments: string;
  description: string;
  latitude: string;
  longitude: string;
  architecturalElements: string[];
  readyForApproval: boolean;
  approved: boolean;
};

export function initialMiniModel(
  mm?: MiniModelPartsFragment,
): MiniModelFormValues {
  return {
    name: mm?.name ?? '',
    url: mm?.url ?? '',
    collectedBy: mm?.collectedBy ?? '',
    yearCollected: String(mm?.yearCollected ?? ''),
    equipment: mm?.equipment ?? '',
    scaniverseName: mm?.scaniverseName ?? '',
    sketchfabName: mm?.sketchfabName ?? '',
    comments: mm?.comments ?? '',
    description: mm?.description ?? '',
    latitude: String(mm?.latitude ?? ''),
    longitude: String(mm?.longitude ?? ''),
    readyForApproval: mm?.readyForApproval ?? false,
    approved: mm?.approved ?? false,
    architecturalElements: mm?.architecturalElements ?? [],
  };
}

export function toMiniModelInput(
  formValues: MiniModelFormValues,
): UpdateMiniModelInput {
  const toFloat = (value: string | null | undefined) => {
    value = value?.trim() || null;

    try {
      if (value?.length) return parseFloat(value);
    } catch (err) {
      console.log(err);
      console.log(`Couldn't parse ${value} to float!`);
    }

    return null;
  };

  return {
    name: formValues.name.trim(),
    url: formValues.url.trim(),
    collectedBy: formValues.collectedBy.trim(),
    yearCollected: parseInt(formValues.yearCollected.trim()),
    equipment: formValues.equipment.trim(),
    scaniverseName: formValues.scaniverseName.trim(),
    sketchfabName: formValues.sketchfabName.trim() || null,
    comments: formValues.comments.trim() || null,
    description: formValues.description.trim() || null,
    latitude: toFloat(formValues.latitude),
    longitude: toFloat(formValues.longitude),
    readyForApproval: formValues.readyForApproval,
    approved: formValues.approved,
    architecturalElements: formValues.architecturalElements,
  };
}

export const miniModelValidationSchema = yup.object({
  name: yup.string().required(),
  url: yup.string().url().required(),
  collectedBy: yup.string().required(),
  yearCollected: yup.number().integer().positive().required(),
  equipment: yup.string().required(),
  scaniverseName: yup.string().required(),
  sketchfabName: yup.string().nullable().optional(),
  comments: yup.string().nullable().optional(),
  description: yup.string().nullable().optional(),
  latitude: yup.number().min(-90).max(90).optional().nullable(),
  longitude: yup.number().min(-180).max(180).optional().nullable(),
  readyForApproval: yup.boolean().required(),
  approved: yup.boolean().required(),
});
