import type { PureQueryOptions } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import type {
  DataHistoryPartsFragment,
  StudyPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { BookmarkParentType } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { studyRoute } from '~/paths';
import type { SupportObject } from '~/utils/modules/supportObject';

type Props<Key extends string, SO extends SupportObject> = {
  outcropId: number;
  studies: Array<
    (StudyPartsFragment & {
      dataHistory?: DataHistoryPartsFragment | null | undefined;
    }) & {
      [key in Key]: SO[];
    }
  >;
  supportObjectKey: Key;
  fade?: boolean;
  refetchQueries: PureQueryOptions[];
};

/** Returns a supporting object list component, but with additional study metadata */
export function TaggedSupportingObjectList<
  Key extends string,
  SO extends SupportObject,
>({
  outcropId,
  studies,
  supportObjectKey,
  fade = false,
  refetchQueries,
}: Props<Key, SO>) {
  const getSuppObjs = (study: (typeof studies)[number]) => {
    const allSOs = study[supportObjectKey] ?? [];
    return allSOs.filter(so => so.outcropTagId === outcropId);
  };

  function authorLine(
    dataHistory?: DataHistoryPartsFragment | null | undefined,
  ) {
    if (!dataHistory) return null;
    if (!dataHistory.collectedBy && !dataHistory.date) {
      return null;
    }

    return (
      <div className="space-x-2">
        {dataHistory.collectedBy && <span>{dataHistory.collectedBy}</span>}
        {dataHistory.date && (
          <span className="text-muted">{dataHistory.date}</span>
        )}
      </div>
    );
  }

  const parentType = (): BookmarkParentType => {
    switch (supportObjectKey) {
      case 'facies':
        return BookmarkParentType.Facies;
      case 'crossSections':
        return BookmarkParentType.CrossSection;
      case 'sedimentaryLogs':
        return BookmarkParentType.SedimentaryLog;
      case 'wellLogs':
        return BookmarkParentType.WellLog;
      case 'production':
        return BookmarkParentType.Production;
      case 'reservoirModels':
        return BookmarkParentType.ReservoirModel;
      case 'trainingImages':
        return BookmarkParentType.TrainingImage;
      case 'variograms':
        return BookmarkParentType.Variogram;
      case 'gigaPans':
        return BookmarkParentType.GigaPan;
      default:
        throw new Error(`Unhandled bookmarkable type ${supportObjectKey}`);
    }
  };

  return (
    <div className="space-y-4">
      {studies.map(study => {
        const suppObjs = getSuppObjs(study);
        if (!suppObjs.length) return null;

        return (
          <Panel key={study.id}>
            <Panel.Heading className="flex justify-between items-start">
              <Panel.Title>
                <strong>{study.name}</strong>
                {authorLine(study.dataHistory)}
              </Panel.Title>

              <Link
                to={studyRoute(study.id)}
                target="_blank"
                className="btn btn-ghost btn-xs gap-1"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} /> View Study
              </Link>
            </Panel.Heading>
            <Panel.Body>
              <SupportingObjectList
                items={suppObjs}
                fade={fade}
                soParentType="study"
                soParentId={study.id}
                bookmarkParentType={parentType()}
                refetchQueries={refetchQueries}
              />
            </Panel.Body>
          </Panel>
        );
      })}
    </div>
  );
}
