import type { ComponentPropsWithoutRef } from 'react';
import { cn } from '~/utils/common';

type BadgeProps = {
  color?:
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'ghost'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'filled' | 'outline';
  mono?: boolean;
} & ComponentPropsWithoutRef<'span'>;

export function Badge({
  color,
  size,
  variant,
  mono,
  className,
  ...spanProps
}: BadgeProps) {
  return (
    <span
      {...spanProps}
      className={cn('badge', {
        'badge-neutral': color === 'neutral',
        'badge-primary': color === 'primary',
        'badge-secondary': color === 'secondary',
        'badge-accent': color === 'accent',
        'badge-ghost': color === 'ghost',
        'badge-info': color === 'info',
        'badge-success': color === 'success',
        'badge-warning': color === 'warning',
        'badge-error': color === 'error',
        'badge-outline': variant === 'outline',
        'badge-lg': size === 'lg',
        'badge-md': size === 'md' || !size,
        'badge-sm': size === 'sm',
        'badge-xs': size === 'xs',
        'font-mono': mono,
      })}
    />
  );
}
