import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ButtonProps } from '~/components/ui/button';
import { Button } from '~/components/ui/button';

export type CollapseButtonProps = {
  collapsed: boolean;
} & ButtonProps;

export function CollapseButton({
  collapsed,
  ...buttonProps
}: CollapseButtonProps) {
  return (
    <Button
      type="button"
      color="ghost"
      size="sm"
      {...buttonProps}
      startIcon={
        <FontAwesomeIcon
          icon={collapsed ? faArrowRightFromBracket : faArrowRightToBracket}
          flip={collapsed ? undefined : 'horizontal'}
        />
      }
    />
  );
}
