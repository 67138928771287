import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeleteOtherWikiMutation,
  DeleteOtherWikiMutationVariables,
} from '~/apollo/generated/v3/graphql';

const DELETE_OTHER_WIKI = gql`
  mutation DeleteOtherWiki($id: Int!) {
    deleteOtherWiki(id: $id)
  }
`;

type Props = {
  id: number;
  children: (onDelete: () => void, loading: boolean) => JSX.Element;
  onDeleteSuccess?: () => any;
  refetchQueries?: PureQueryOptions[];
};

export function DeleteOtherWiki({
  id,
  children,
  onDeleteSuccess,
  refetchQueries,
}: Props) {
  const [deleteWikiPage, { loading }] = useMutation<
    DeleteOtherWikiMutation,
    DeleteOtherWikiMutationVariables
  >(DELETE_OTHER_WIKI, {
    variables: { id },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteWikiPage();
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    } catch (err) {
      console.log('Error deleting wiki page', err);
      toast.error('There was a problem deleting the wiki page.');
    }
  }

  return children(handleDelete, loading);
}
