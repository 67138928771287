import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';
import * as R from 'ramda';
import React from 'react';

import * as fragments from '~/apollo/fragments';
import type { ProjectSelectProjectsQuery } from '~/apollo/generated/v3/graphql';
import type { FormikSelectFieldProps } from '~/components/common/FormikField/FormikSelectField';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

const PROJECT_SELECT_PROJECTS = gql`
  query ProjectSelectProjects {
    projectList {
      ...projectParts
    }
  }

  ${fragments.projectParts}
`;

type Props = FieldProps & FormikSelectFieldProps & { disabled: boolean };

export function ProjectSelect({
  form,
  field,
  disabled = false,
  ...props
}: Props) {
  const { loading, data } = useQuery<ProjectSelectProjectsQuery>(
    PROJECT_SELECT_PROJECTS,
    {},
  );

  const projectList = R.sortBy(p => p.name, data?.projectList ?? []);
  const options = projectList.map(p => ({ value: p.id, label: p.name }));

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    form.setFieldValue(field.name, ~~e.target.value || '');

  return (
    <FormikSelectField
      {...props}
      form={form}
      field={field}
      onChange={onChange}
      options={options}
      disabled={disabled || loading}
    />
  );
}
