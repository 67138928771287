import { gql } from '~/apollo/client-v3';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  UpdateOutcropAgeMutation,
  UpdateOutcropAgeMutationVariables,
  UpdateOutcropAgeRouteQuery,
  UpdateOutcropAgeRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { LithostratAgeFormFields } from '~/components/upload/lithostratAge/LithostratAgeFormFields';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';
import {
  initialLithostratAge,
  toLithostratAgeInput,
} from '~/utils/modules/lithostratAge';
import type { OutcropGeologicalAgeFormValues } from '~/utils/modules/outcrop';

const UPDATE_OUTCROP_AGE_ROUTE = gql`
  query UpdateOutcropAgeRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      id
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
    }
  }

  ${fragments.lithostratAgeParts}
`;

export const UPDATE_OUTCROP_AGE = gql`
  mutation UpdateOutcropAge(
    $id: Int!
    $startAge: LithostratAgeInput
    $endAge: LithostratAgeInput
  ) {
    updateOutcropAge(id: $id, startAge: $startAge, endAge: $endAge) {
      id
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
    }
  }

  ${fragments.lithostratAgeParts}
`;

export default function UpdateOutcropGeologicalAgeRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UpdateOutcropAgeRouteQuery,
    UpdateOutcropAgeRouteQueryVariables
  >(UPDATE_OUTCROP_AGE_ROUTE, {
    variables: { outcropId },
  });

  const [updateOutcrop, { loading: loadingUpdate, error }] = useMutation<
    UpdateOutcropAgeMutation,
    UpdateOutcropAgeMutationVariables
  >(UPDATE_OUTCROP_AGE, {});

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  async function handleSubmit(values: OutcropGeologicalAgeFormValues) {
    invariant(outcrop, 'Outcrop must be loaded!');

    try {
      await updateOutcrop({
        variables: {
          id: outcropId,
          startAge: toLithostratAgeInput(values.startAge),
          endAge: toLithostratAgeInput(values.endAge),
        },
      });
      toast.success('Outcrop saved successfully.');
    } catch (err) {
      console.log('Error saving outcrop', err);
      toast.error('There was a problem saving the outcrop.');
    }
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <Formik
      initialValues={{
        startAge: initialLithostratAge(outcrop.startAge ?? undefined),
        endAge: initialLithostratAge(outcrop.endAge ?? undefined),
      }}
      onSubmit={handleSubmit}
    >
      <Form className="space-y-4">
        <div className="grid lg:grid-cols-2 gap-6">
          <div>
            <Panel>
              <Panel.Heading>
                <Panel.Title>Start Age</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <LithostratAgeFormFields ageName="startAge" />
              </Panel.Body>
            </Panel>
          </div>
          <div>
            <Panel>
              <Panel.Heading>
                <Panel.Title>End Age</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <LithostratAgeFormFields ageName="endAge" />
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading || loadingUpdate}
          >
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
}
