import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React, { useEffect, useRef } from 'react';
import { JoystickShape } from 'react-joystick-component';
import type { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import SignalJoystick from '~/components/cesium/Joystick';
import { cn } from '~/utils/common';
import { FormLabel } from './FormLabel';

export type Props = FieldProps & {
  joystickdirection: 'AxisX' | 'AxisY';
  className?: string;
  label?: React.ReactNode;
  helpText?: string;
  inline?: boolean;
  required?: boolean;
  isInvalid?: boolean;
};

export function FormikJoystickNumberField({
  form,
  field,
  className,
  label,
  helpText,
  inline = false,
  required = false,
  isInvalid,
  ...props
}: Props) {
  const formVal = useRef<number>(field.value);
  // const debouncedVal = useDebounce()
  const handleChange = (value: any) => () => {
    console.log('field changing', field, value);
    form.setFieldValue(field.name, value);
  };

  const handleMove = (event: IJoystickUpdateEvent | null) => {
    if (!event) {
      return;
    }
    if (event.type === 'move' && (event.x || event.y)) {
      if (event.y) {
        formVal.current = Math.round((formVal.current + event.y) * 100) / 100;
      }
    }
    setTimeout(() => {
      form.setFieldValue(field.name, formVal.current);
    }, 0);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <FormLabel
          name={field.name}
          label={label}
          helpText={helpText}
          required={required}
        />
      </div>

      <div className={inline ? 'flex gap-2 items-center' : ''}>
        <div className="form-control">
          <label className={cn('label justify-start gap-2')}>
            <SignalJoystick
              size={100}
              signalRate={50}
              callback={handleMove}
              throttle={50}
              controlPlaneShape={JoystickShape[props.joystickdirection]}
              baseShape={JoystickShape.Square}
              stickShape={JoystickShape.Square}
            ></SignalJoystick>
            <input
              {...props}
              type="number"
              value={field.value}
              onChange={handleChange}
              className="input"
            />
            {/* <span className="label-text">{text}</span> */}
          </label>
        </div>
      </div>

      <div className="text-error">
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
}
