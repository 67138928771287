import type {
  LiteraturePartsFragment,
  LiteratureReferenceInput,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export interface LiteratureFormValues {
  author: string;
  comments: string;
  digitalObjectIdentifier: string;
  publicationSource: string;
  publicationType: string;
  title: string;
  webSource: string;
  year: string;
  outcropTagId: string;
  published: boolean;
  priority: string;
}

export function defaultLiterature(
  lit?: LiteraturePartsFragment,
): LiteratureFormValues {
  return {
    author: lit?.author ?? '',
    comments: lit?.comments ?? '',
    digitalObjectIdentifier: lit?.digitalObjectIdentifier ?? '',
    publicationSource: lit?.publicationSource ?? '',
    publicationType: lit?.publicationType ?? '',
    title: lit?.title ?? '',
    webSource: lit?.webSource ?? '',
    year: String(lit?.year ?? ''),
    outcropTagId: String(lit?.outcropTagId ?? ''),
    published: lit?.published ?? false,
    priority: String(lit?.priority ?? ''),
  };
}

export function toLiteratureReferenceInput(
  fv: LiteratureFormValues,
): LiteratureReferenceInput {
  return {
    author: fv.author ?? '',
    comments: fv.comments ?? '',
    digitalObjectIdentifier: fv.digitalObjectIdentifier ?? '',
    publicationSource: fv.publicationSource ?? '',
    publicationType: fv.publicationType ?? '',
    title: fv.title ?? '',
    webSource: fv.webSource ?? '',
    year: parseInt(fv.year ?? ''),
    outcropTagId: fv.outcropTagId ? parseInt(fv.outcropTagId) : null,
    published: fv.published,
    priority: fv.priority.trim() ? parseInt(fv.priority.trim()) : null,
  };
}

export const validationSchema = yup.object({
  // Required
  author: yup.string().label('author').required(),
  publicationSource: yup.string().label('publication source').required(),
  publicationType: yup.string().label('publication type').required(),
  title: yup.string().label('title').required(),
  year: yup.number().integer().positive().label('year').required(),

  // Nullable
  digitalObjectIdentifier: yup
    .string()
    .label('digitalObjectIdentifier')
    .nullable(),
  webSource: yup.string().label('webSource').nullable(),
  comments: yup.string().label('comments').nullable(),
  outcropTagId: yup.number().integer().label('outcrop tag').nullable(),
  priority: yup.number().integer().label('priority').nullable(),
});
