import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ReactNode,
} from 'react';
import { cn } from '~/utils/common';

export type ButtonProps = {
  color?:
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'ghost';
  variant?: 'soft' | 'outline' | 'link';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  active?: boolean;
  wide?: boolean;
  block?: boolean;
  loading?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  shape?: 'rounded-square' | 'circle';
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      variant,
      className,
      size = 'md',
      active,
      disabled,
      wide,
      block,
      loading,
      startIcon,
      endIcon,
      children,
      shape = 'rounded-square',
      ...buttonProps
    },
    ref,
  ) => {
    return (
      <button
        {...buttonProps}
        ref={ref}
        className={cn(
          'btn',
          {
            'btn-neutral': color === 'neutral',
            'btn-primary': color === 'primary',
            'btn-secondary': color === 'secondary',
            'btn-accent': color === 'accent',
            'btn-info': color === 'info',
            'btn-success': color === 'success',
            'btn-warning': color === 'warning',
            'btn-error': color === 'error',
            'btn-ghost': color === 'ghost',
            'btn-outline': variant === 'outline',
            'btn-soft': variant === 'soft',
            'btn-link': variant === 'link',
            'btn-active': active,
            'btn-disabled': disabled || loading,
            'btn-wide': wide,
            'btn-block': block,
            'btn-xs': size === 'xs',
            'btn-sm': size === 'sm',
            'btn-md': size === 'md' || !size,
            'btn-lg': size === 'lg',
            'btn-xl': size === 'xl',
            'btn-circle': shape === 'circle',
          },
          className,
        )}
        disabled={disabled || loading}
      >
        {loading ? (
          <span className="loading loading-spinner"></span>
        ) : (
          startIcon
        )}
        {children}
        {endIcon}
      </button>
    );
  },
);
Button.displayName = 'Button';
