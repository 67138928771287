import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { regionParts } from '~/apollo/fragments';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { RegionFormFields } from '~/components/upload/region/RegionFormFields';
import type { RegionFormValues } from '~/utils/modules/region';
import {
  initialRegion,
  regionValidationSchema,
  toRegionInput,
} from '~/utils/modules/region';
import { useUploadRegionPageContext } from '../$regionId';

const UPDATE_REGION = gql`
  mutation UpdateRegion($id: Int!, $region: RegionInput!) {
    updateRegion(id: $id, region: $region) {
      ...regionParts
    }
  }

  ${regionParts}
`;

export default function UploadRegionOverviewTab() {
  const { region } = useUploadRegionPageContext();
  invariant(region, 'region is required');

  const [updateRegion, { loading, error }] = useMutation(UPDATE_REGION, {});

  async function handleSubmit(values: RegionFormValues) {
    try {
      await updateRegion({
        variables: {
          id: region.id,
          region: toRegionInput(values),
        },
      });
      toast.success('Region updated successfully.');
    } catch (err) {
      console.log('Error updating region', err);
      toast.error('There was a problem updating the region.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialRegion(region)}
      validationSchema={regionValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Region Details</Panel.Title>
          </Panel.Heading>

          <Panel.Body className="space-y-4">
            <RegionFormFields />
            <FormErrors graphQLError={error} />
          </Panel.Body>

          <Panel.Footer className="text-right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
