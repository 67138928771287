import { useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import { graphql } from '~/apollo/generated/v3';
import type { StudyMeasurementsTabQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { FormikCheckboxArrayDropdown } from '~/components/common/FormikField/FormikCheckboxArrayDropdown';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { EmbeddableDataSearch } from '~/components/dataSearch/EmbeddableDataSearch';
import {
  DataSearchContextProvider,
  useDataSearchContext,
} from '~/components/dataSearch/dataSearchContext';
import { useStudyOutletContext } from '~/routes/study/$studyId';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';
import { dataSearchInitialValues } from '~/utils/modules/dataSearch';

const STUDY_MEASUREMENTS_TAB = graphql(`
  query StudyMeasurementsTab(
    $studyId: Int!
    $outcropIds: [Int!]!
    $studyIds: [Int!]!
  ) {
    studyList(id: $studyId) {
      id
      name
      architecturalElements {
        id
        name
        outcropId
        outcrop {
          id
          name
        }
        measurementCount
      }
      defaultMeasurementsView {
        ...dmvParts
      }
    }

    suggestedDataTypes(outcropIds: $outcropIds, studyIds: $studyIds) {
      dataTypeX
      dataTypeY
      measurements {
        ...dataSearchMeasurementParts
      }
    }
  }
`);

type Study = StudyMeasurementsTabQuery['studyList'][number];
type StudyAE = Study['architecturalElements'][number];
type AEOutcrop = NonNullable<StudyAE['outcrop']>;

function MeasurementsTab({
  study,
  outcrops,
}: {
  study: Study;
  outcrops: AEOutcrop[];
}) {
  const { measurements, searchInput } = useDataSearchContext();
  const { values, submitForm } = useFormikContext<DataSearchFormValues>();

  const hasCachedAxes = searchInput.dataTypeX && searchInput.dataTypeY;

  useEffect(() => {
    if (
      values.graphType === 'crossPlot' &&
      values.crossPlot.dataTypeX &&
      values.crossPlot.dataTypeY &&
      (!measurements.length || !hasCachedAxes)
    ) {
      submitForm();
    } else if (
      values.graphType === 'histogram' &&
      values.histogram.dataType &&
      (!measurements.length || !hasCachedAxes)
    ) {
      submitForm();
    }
    // We only want to fire this once on page load, if it has pre-filled values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.graphType]);

  return (
    <Panel>
      <Panel.Heading>
        <div className="flex justify-between items-center gap-6">
          <Panel.Title>Measurements</Panel.Title>
          <div className="flex items-center gap-2">
            <span className="text-sm text-slate-500">Outcrops</span>
            <Field
              name="outcropIds"
              component={FormikField}
              type={FormikCheckboxArrayDropdown}
              options={outcrops.map(oc => ({
                value: oc.id,
                label: oc.name,
              }))}
              dropdownBtnClass="select-sm"
              align="end"
            />
          </div>
        </div>
      </Panel.Heading>

      <Panel.Body>
        <EmbeddableDataSearch name={study.name} />
      </Panel.Body>
    </Panel>
  );
}

export default function StudyMeasurementsRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery(STUDY_MEASUREMENTS_TAB, {
    variables: {
      studyId,
      outcropIds: [],
      studyIds: [studyId],
    },
  });

  const study = useMemo(() => {
    const studyList = data?.studyList ?? [];
    return studyList.find(s => s.id === studyId);
  }, [studyId, data]);

  const selectableOutcrops = useMemo(() => {
    const aes = study?.architecturalElements ?? [];
    return aes
      .filter(ae => ae.measurementCount > 0)
      .reduce<AEOutcrop[]>((acc, cur) => {
        const aeOutcrop = cur.outcrop;
        if (!aeOutcrop) return acc;
        if (acc.find(oc => oc && oc.id === aeOutcrop.id)) return acc;
        return [...acc, aeOutcrop];
      }, [])
      .sort(R.ascend(oc => oc.name));
  }, [study]);

  const dmv = data?.studyList.find(
    s => s.id === studyId,
  )?.defaultMeasurementsView;

  const initialValues = dataSearchInitialValues();
  initialValues.outcropIds = selectableOutcrops.map(oc => oc.id);
  initialValues.studyIds = [studyId];

  const initialMeasurements = dmv
    ? undefined
    : (data?.suggestedDataTypes.measurements ?? []);

  if (dmv) {
    initialValues.graphType =
      dmv.graphType === 'CROSS_PLOT' ? 'crossPlot' : 'histogram';
    initialValues.crossPlot.dataTypeX = dmv.crossPlotDataTypeX;
    initialValues.crossPlot.dataTypeY = dmv.crossPlotDataTypeY;
    initialValues.crossPlot.logScaleX = dmv.crossPlotLogScaleX ?? false;
    initialValues.crossPlot.logScaleY = dmv.crossPlotLogScaleY ?? false;
    initialValues.histogram.dataType = dmv.histogramDataType;
  } else {
    const dataTypeX = data?.suggestedDataTypes.dataTypeX ?? null;
    const dataTypeY = data?.suggestedDataTypes.dataTypeY ?? null;

    if (dataTypeX && dataTypeY) {
      initialValues.graphType = 'crossPlot';
      initialValues.crossPlot.dataTypeX = dataTypeX;
      initialValues.crossPlot.dataTypeY = dataTypeY;
    } else if (dataTypeX && !dataTypeY) {
      initialValues.graphType = 'histogram';
      initialValues.histogram.dataType = dataTypeX;
    }
  }

  if (loading) {
    return <SpinnerPlaceholder />;
  }
  if (!study) {
    return <NotFound />;
  }

  return (
    <DataSearchContextProvider
      initialValues={initialValues}
      initialMeasurements={initialMeasurements}
    >
      <MeasurementsTab study={study} outcrops={selectableOutcrops} />
    </DataSearchContextProvider>
  );
}
