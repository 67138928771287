import {
  faArrowCircleLeft,
  faExclamationTriangle,
  faPencil,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { Link, useParams } from 'react-router';
import invariant from 'tiny-invariant';
import type { AePartsFragment } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { CreateMeasurementModal } from '~/components/upload/architecturalElement/CreateMeasurementModal';
import { MeasurementList } from '~/components/upload/architecturalElement/MeasurementList';
import { UpdateAEModal } from '~/components/upload/architecturalElement/UpdateAEModal';
import { uploadStudyUpdateAEsRoute } from '~/paths';
import { useStudyAEsOutletContext } from '~/routes/upload/model/study/$studyId/architectural-elements';

export default function UpdateAERoute() {
  const params = useParams<{ aeId: string }>();
  invariant(params.aeId, 'aeId param required');
  const aeId = parseInt(params.aeId);

  const ctx = useStudyAEsOutletContext();
  const ae = ctx.architecturalElements.find(ae => ae.id === aeId);
  invariant(ae, 'ae not found');

  return (
    <>
      <Link
        to={uploadStudyUpdateAEsRoute(ctx.study.id)}
        className="btn btn-ghost btn-sm gap-1 mb-2"
      >
        <FontAwesomeIcon icon={faArrowCircleLeft} /> Back to AE List
      </Link>

      <div className="space-y-6">
        <Panel variant="primary">
          <Panel.Heading className="flex justify-between items-center">
            <Panel.Title>Architectural Element Details</Panel.Title>

            <UpdateAEModal
              architecturalElement={ae}
              outcrops={ctx.outcrops}
              refetchQueries={ctx.refetchQueries}
            >
              {showModal => (
                <Button
                  type="button"
                  color="primary"
                  size="xs"
                  onClick={showModal}
                  className="gap-1"
                >
                  <FontAwesomeIcon icon={faPencil} /> Edit
                </Button>
              )}
            </UpdateAEModal>
          </Panel.Heading>

          <Panel.Body>
            <Heading level={3}>{ae.name}</Heading>
            <div>{ae.description}</div>

            <div className="grid lg:grid-cols-2 gap-6">
              <div>
                <table className="table table-compact w-full">
                  <tbody>
                    <AEField
                      ae={ae}
                      field="connectivity"
                      label="Connectivity"
                    />
                    <AEField
                      ae={ae}
                      field="outcropOrder"
                      label="Outcrop Order"
                    />
                    <AEField ae={ae} field="background" label="Background" />
                    <AEField ae={ae} field="shapeKind" label="Shape Kind" />
                    <AEField
                      ae={ae}
                      field="depositionalConfinement"
                      label="Depositional Confinement"
                    />
                    <AEField ae={ae} field="comments" label="Comments" />
                  </tbody>
                </table>
              </div>

              <div>
                <table className="table table-compact w-full">
                  <tbody>
                    <AEField
                      ae={ae}
                      field="grossDepositionalEnvironment"
                      label="GDE"
                    />
                    <AEField
                      ae={ae}
                      field="depositionalEnvironment"
                      label="DE"
                    />
                    <AEField
                      ae={ae}
                      field="depositionalSubEnvironment"
                      label="SE"
                    />
                    <AEField ae={ae} field="architecturalElement" label="AE" />
                  </tbody>
                </table>
                {!ae.validKeyParameters && (
                  <div className="text-center text-error">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Key
                    Parameters are invalid
                  </div>
                )}
              </div>
            </div>
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading className="flex justify-between items-center">
            <Panel.Title id="measurements">Measurements</Panel.Title>

            <CreateMeasurementModal
              studyId={ae.studyId}
              architecturalElementId={ae.id}
              refetchQueries={ctx.refetchQueries}
            >
              {showModal => (
                <Button
                  type="button"
                  onClick={showModal}
                  color="primary"
                  size="xs"
                  className="gap-1"
                >
                  <FontAwesomeIcon icon={faPlus} /> Create Measurement
                </Button>
              )}
            </CreateMeasurementModal>
          </Panel.Heading>

          <Panel.Body>
            <MeasurementList
              studyId={ae.studyId}
              measurements={ae.measurements}
              refetchQueries={ctx.refetchQueries}
            />
          </Panel.Body>
        </Panel>
      </div>
    </>
  );
}

type AEFieldProps = {
  ae: AePartsFragment;
  field: keyof AePartsFragment;
  label: string;
};

function AEField({ ae: architecturalElement, field, label }: AEFieldProps) {
  const value = architecturalElement[field];
  if (!value) return null;

  return (
    <tr>
      <th>{label}</th>
      <td>{value}</td>
    </tr>
  );
}
