import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  RemoveBookmarkFromCollectionMutation,
  RemoveBookmarkFromCollectionMutationVariables,
} from '~/apollo/generated/v3/graphql';

const REMOVE_FROM_COLLECTION = gql`
  mutation RemoveBookmarkFromCollection(
    $bookmarkId: Int!
    $collectionId: Int!
  ) {
    removeBookmarkFromCollection(
      bookmarkId: $bookmarkId
      collectionId: $collectionId
    )
  }
`;

type Props = {
  children: (
    handleRemove: () => Promise<void>,
    loading: boolean,
  ) => JSX.Element;
  bookmarkId: number;
  collectionId: number;
  refetchQueries: PureQueryOptions[];
};

export function RemoveBookmarkFromCollection({
  children,
  bookmarkId,
  collectionId,
  refetchQueries,
}: Props) {
  const [removeMutation, { loading }] = useMutation<
    RemoveBookmarkFromCollectionMutation,
    RemoveBookmarkFromCollectionMutationVariables
  >(REMOVE_FROM_COLLECTION, {
    variables: { bookmarkId, collectionId },
    refetchQueries,

    awaitRefetchQueries: true,
  });

  async function handleRemove() {
    try {
      await removeMutation();
      toast.success('Bookmark successfully removed from collection.');
    } catch (err) {
      console.log('Error removing bookmark from collection', err);
      toast.error(
        'There was a problem removing the bookmark from the collection. It may have already been removed; please reload the page and try again.',
      );
    }
  }

  return children(handleRemove, loading);
}
