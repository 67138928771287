import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import type {
  GeoreferencePartsFragment,
  LatLngInput,
} from '~/apollo/generated/v3/graphql';
import type { GeoreferenceFormValues } from '~/utils/georeference';
import { getDrawingModes } from '~/utils/georeference';
import { MapEditor } from './MapEditor';
import { toGeoreferenceDataType } from '~/utils/modules/georeference';

type Props = {
  georeferences: GeoreferencePartsFragment[];
};

export function MapEditorContainer({ georeferences }: Props) {
  const { values, setFieldValue } = useFormikContext<GeoreferenceFormValues>();

  function handleDrawingComplete(data: LatLngInput[] | null) {
    setFieldValue('data', data);
  }

  const drawingModes = useMemo(() => {
    try {
      const dataType = toGeoreferenceDataType(values.dataType);
      return getDrawingModes(dataType);
    } catch (_) {
      return [];
    }
  }, [values.dataType]);

  return (
    <MapEditor
      georeferences={georeferences}
      drawingModes={drawingModes}
      handleDrawingComplete={handleDrawingComplete}
    />
  );
}
