import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Alert } from '~/components/ui/alert';
import { Link } from 'react-router';
import { machineTokenParts } from '~/apollo/fragments';
import type {
  CreateMachineTokenMutation,
  CreateMachineTokenMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { Well } from '~/components/common/Well';
import { MachineTokenFormFields } from '~/components/company/MachineTokenFormFields';
import { HelpBox } from '~/components/HelpBox';
import { companyAdminMachineTokensRoute } from '~/paths';
import { cn } from '~/utils/common';
import type { MachineTokenFormValues } from '~/utils/modules/company';
import { machineTokenValidationSchema } from '~/utils/modules/company';

const CREATE_MACHINE_TOKEN = gql`
  mutation CreateMachineToken($input: CreateMachineTokenInput!) {
    createMachineToken(input: $input) {
      machineToken {
        ...machineTokenParts
      }
      token
    }
  }

  ${machineTokenParts}
`;

function Token({ token }: { token: string }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleTokenClick = () => {
    navigator.clipboard.writeText(token);
    setIsCopied(true);
  };

  const handleMouseOut = () => {
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <div>
      <Tooltip message="Click to copy">
        <Alert
          status="success"
          className="font-mono break-all text-xl cursor-pointer block pt-8 text-center"
          onClick={handleTokenClick}
          onMouseOut={handleMouseOut}
        >
          {token}

          <div
            className={cn(
              'text-white space-x-4 transition-opacity duration-200 font-sans',
              {
                'opacity-0': !isCopied,
                'opacity-100': isCopied,
              },
            )}
          >
            <FontAwesomeIcon icon={faCheck} /> Copied to clipboard
          </div>
        </Alert>
      </Tooltip>
    </div>
  );
}

export function CompanyAdminCreateMachineTokenPage() {
  const [createMachineToken, { data, loading, error }] = useMutation<
    CreateMachineTokenMutation,
    CreateMachineTokenMutationVariables
  >(CREATE_MACHINE_TOKEN);

  async function handleSubmit(values: MachineTokenFormValues) {
    await createMachineToken({ variables: { input: values } });
  }

  if (error) {
    return (
      <p>An unexpected error occurred, please reload the page and try again.</p>
    );
  }

  if (data) {
    return (
      <>
        <Well className="text-center">
          <Heading level={3} className="mt-0">
            Token Created Successfully
          </Heading>

          <div className="space-y-2">
            <p>Your machine token was saved successfully.</p>
            <p>
              <strong>
                Copy this token to a safe location;
                <br />
                <span className="underline text-error">
                  IT WILL NOT BE DISPLAYED AGAIN AFTER LEAVING THIS PAGE
                </span>
                .
              </strong>
            </p>
          </div>

          <div className="mt-6 space-y-4">
            <strong>{data.createMachineToken.machineToken.description}</strong>
            <Token token={data.createMachineToken.token} />

            <Link
              to={companyAdminMachineTokensRoute()}
              className="btn btn-primary gap-1"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Return to token list
            </Link>
          </div>
        </Well>
      </>
    );
  }

  return (
    <Formik
      initialValues={{ description: '' }}
      onSubmit={handleSubmit}
      validationSchema={machineTokenValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Create Machine Token</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <HelpBox>
                You'll only be able to see the token one time when it is
                created. Choose a description so it can be identified in the
                future.
              </HelpBox>

              <MachineTokenFormFields />
            </div>
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Generate Token
              </button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
