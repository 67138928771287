import { useNavigate } from 'react-router';
import React, { useEffect } from 'react';
import { Heading } from '~/components/common/Heading';
import { useQueryString } from '~/hooks/routing';
import { homeRoute } from '~/paths';
import { logoutUrl } from '~/utils/auth';

function codeToReadbleError(errorMessage: string): React.ReactNode {
  switch (errorMessage) {
    case 'email_not_verified':
      return 'Please verify your email address to log in.';

    case 'account_locked':
      return 'Your account is not currently active. Please contact your company administrator for help.';

    case 'tenant_not_configured':
      return 'Your Microsoft account is not configured to log in to Safari. Please contact your company administrator to add this permission.';

    case 'use_aad_account':
      return 'It looks like you have a Microsoft account with this email address. Please use this account to log in instead of your legacy Safari username/password.';

    case 'aad_account_required':
      return 'Your company requires that you log in using your Microsoft account. Please log out and try again using the Continue with Microsoft button.';

    default:
      return 'There was a problem logging you in. Please try again.';
  }
}

type QSParams = {
  status?: string;
};

export default function LoginErrorRoute() {
  const { query } = useQueryString<QSParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!query.status) {
      navigate(homeRoute());
    }
  }, [query.status, navigate]);

  // If no message, user will be redirected
  if (!query.status) return null;

  return (
    <div className="lg:w-1/2 mx-auto space-y-4">
      <Heading level={4}>There was a problem logging you in.</Heading>

      <p>{codeToReadbleError(query.status)}</p>

      <p>
        Need help? Send us an email at{' '}
        <a href="mailto:support@safaridb.com" target="_blank" rel="noreferrer">
          support@safaridb.com
        </a>
      </p>

      <div className="text-center">
        <a href={logoutUrl()} className="btn btn-primary btn-sm">
          Log Out
        </a>
      </div>
    </div>
  );
}
