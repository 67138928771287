import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import {
  faCheckCircle,
  faCircleExclamation,
  faHourglass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';

import type {
  UpdateDataSearchMutation,
  UpdateSearchMutation,
} from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { useModalState } from '~/hooks/modal';

const UPDATE_SEARCH = gql`
  mutation UpdateSearch {
    updateSearch
  }
`;

const UPDATE_DATA_SEARCH = gql`
  mutation UpdateDataSearch {
    updateDataSearch
  }
`;

type Props = {
  children: (update: () => void) => JSX.Element;
};

function UpdateSearchView({ children }: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [updateSearch, { data, loading }] = useMutation<UpdateSearchMutation>(
    UPDATE_SEARCH,
    {},
  );
  const [updateDataSearch, { data: dataData, loading: loadingData }] =
    useMutation<UpdateDataSearchMutation>(UPDATE_DATA_SEARCH, {});

  async function handleUpdate() {
    try {
      const results = await Promise.all([updateSearch(), updateDataSearch()]);
      if (
        !results[0].data?.updateSearch ||
        !results[1].data?.updateDataSearch
      ) {
        throw new Error('Update failed');
      }
      toast.success('Search views updated successfully.');
    } catch (err) {
      console.log('Error updating search view', err);
      toast.error(
        'There was a problem updating one of the search views. Please check the server logs.',
      );
    }
  }

  const searchIcon = (status: boolean | undefined) => {
    if (typeof status === 'undefined') return faHourglass;
    return status ? faCheckCircle : faCircleExclamation;
  };

  const statusClassName = (status: boolean | undefined) => {
    if (typeof status === 'undefined') return 'text-base-300';
    return status ? 'text-success' : 'text-error';
  };

  const anyLoading = loading || loadingData;

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} closeable={!anyLoading}>
        <Modal.Body heading="Update Searches">
          <p>
            This process will update the analogue and data searches with any
            changes made to outcrops, architectural elements, their metadata, or
            any related entities used for searching. This will take several
            seconds to complete.
          </p>

          <div className="flex flex-row text-xl mt-3">
            <div className="basis-1/4 text-center">
              {!loading && (
                <FontAwesomeIcon
                  icon={searchIcon(data?.updateSearch)}
                  className={statusClassName(data?.updateSearch)}
                />
              )}
              <SpinnerIcon show={loading} />
            </div>
            <div className="basis-3/4">Analogue Search</div>
          </div>

          <div className="flex flex-row text-xl">
            <div className="basis-1/4 text-center">
              {!loadingData && (
                <FontAwesomeIcon
                  icon={searchIcon(dataData?.updateDataSearch)}
                  className={statusClassName(dataData?.updateDataSearch)}
                />
              )}
              <SpinnerIcon show={loadingData} />
            </div>
            <div className="basis-3/4">Data Search</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            color="ghost"
            onClick={hideModal}
            disabled={anyLoading}
          >
            Close
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={handleUpdate}
            loading={anyLoading}
          >
            Update Searches
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateSearchView;
