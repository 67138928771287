import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { lithostratWikiOverviewRoute } from '~/paths';

export function LithostratTypeBacklink() {
  return (
    <Link
      to={lithostratWikiOverviewRoute()}
      className="btn btn-ghost btn-sm gap-1"
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      Back to <strong>lithostratigraphy</strong> overview
    </Link>
  );
}
