import { Link } from 'react-router';
import * as R from 'ramda';
import React from 'react';
import type { StudyKeyParametersTabQuery } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import {
  KPCol,
  KPGrid,
} from '~/components/keyParameter/KeyParametersPanel/CombinedView';
import { otherWikiDetailRoute } from '~/paths';

type PropertyDisplayProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  shouldRender: boolean;
};

function PropertyDisplay({
  label,
  shouldRender,
  children,
}: PropertyDisplayProps) {
  if (!shouldRender) return null;
  return (
    <KPGrid>
      <KPCol span={5}>
        <strong>{label}</strong>
      </KPCol>
      <KPCol span={7}>{children}</KPCol>
    </KPGrid>
  );
}

type Props = {
  study: StudyKeyParametersTabQuery['studyList'][number];
  noHeading?: boolean;
};
export function QualityParametersTableStudy({
  study,
  noHeading = false,
}: Props) {
  if (!study.qualityParameters) {
    return null;
  }

  const joinMany: (values?: string[] | null) => string = R.pipe(
    R.defaultTo([]),
    R.join(', '),
  );

  const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

  return (
    <>
      {!noHeading && <Heading level={3}>Quality Control Parameters</Heading>}

      <PropertyDisplay
        label="Spatial observation type"
        shouldRender={
          !isNilOrEmpty(study.qualityParameters.spatialObservationType)
        }
      >
        <Link to={otherWikiDetailRoute(14)} target="_blank" className="link">
          {study.qualityParameters.spatialObservationType}
        </Link>
      </PropertyDisplay>

      <PropertyDisplay
        label="Data acquisition method"
        shouldRender={
          !isNilOrEmpty(study.qualityParameters.dataAcquisitionMethod)
        }
      >
        <Link to={otherWikiDetailRoute(7)} target="_blank" className="link">
          {joinMany(study.qualityParameters.dataAcquisitionMethod)}
        </Link>
      </PropertyDisplay>

      <PropertyDisplay
        label="Publication type"
        shouldRender={!isNilOrEmpty(study.qualityParameters.publicationType)}
      >
        <Link to={otherWikiDetailRoute(17)} target="_blank" className="link">
          {joinMany(study.qualityParameters.publicationType)}
        </Link>
      </PropertyDisplay>
    </>
  );
}
