import type { PureQueryOptions } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v4';
import type { ListCesiumAssetStagedFilesQueryVariables } from '~/apollo/generated/v4/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { QueueableMultipartUpload } from '~/components/upload/file/QueueableMultipartUpload';
import type {
  CompletedFilePart,
  UseUploadQueueConfig,
} from '~/components/upload/file/QueueableMultipartUpload/useUploadQueue';
import { cn } from '~/utils/common';

const initializeUploadMutation = graphql(`
  mutation InitializeCesiumAssetUpload(
    $input: CesiumAssetInitiateMultipartInput!
  ) {
    cesiumAssetInitiateMultipart(input: $input) {
      token
      uploadPath
    }
  }
`);

const completeUploadMutation = graphql(`
  mutation CompleteCesiumAssetUpload(
    $input: CesiumAssetCompleteMultipartInput!
  ) {
    cesiumAssetCompleteMultipart(input: $input)
  }
`);

const abortUploadMutation = graphql(`
  mutation AbortCesiumAssetUpload($input: CesiumAssetAbortMultipartInput!) {
    cesiumAssetAbortMultipart(input: $input)
  }
`);

const deleteStagedFileMutation = graphql(`
  mutation DeleteCesiumAssetStagedFile(
    $input: CesiumAssetDeleteStagedFileInput!
  ) {
    cesiumAssetDeleteStagedFile(input: $input)
  }
`);

const sendToCesiumMutation = graphql(`
  mutation SendToCesium($id: ID!) {
    cesiumAssetStartUpload(id: $id) {
      result {
        id
      }
    }
  }
`);

const listStagedFilesQuery = graphql(`
  query ListCesiumAssetStagedFiles($id: ID!) {
    cesiumAssetGet(id: $id) {
      id
      stagedFiles
    }
  }
`);

export function InitializedState({
  vomId,
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const { data, loading, refetch } = useQuery(listStagedFilesQuery, {
    variables: { id: cesiumAssetId },
  });
  const [initializeUpload] = useMutation(initializeUploadMutation);
  const [completeUpload] = useMutation(completeUploadMutation);
  const [abortUpload] = useMutation(abortUploadMutation);
  const [deleteStagedFile] = useMutation(deleteStagedFileMutation, {
    refetchQueries: [
      {
        query: listStagedFilesQuery,
        variables: {
          id: cesiumAssetId,
        } satisfies ListCesiumAssetStagedFilesQueryVariables,
      },
    ],
  });

  async function initializeUploadFn(file: File) {
    try {
      const res = await initializeUpload({
        variables: {
          input: {
            id: cesiumAssetId,
            fileName: file.name,
            contentType: file.type,
          },
        },
      });
      const data = res.data?.cesiumAssetInitiateMultipart;
      invariant(data, 'Unable to parse response data');

      return { token: data.token, uploadPath: data.uploadPath };
    } catch (err) {
      console.log('Error initiating multipart upload', err);
      throw new Error('Error initiating multipart upload');
    }
  }

  async function completeUploadFn(token: string, parts: CompletedFilePart[]) {
    await completeUpload({
      variables: {
        input: { parts, token },
      },
    }).catch(err => {
      console.log('Error completeing multipart upload', err);
      throw new Error('Error completing multipart upload');
    });
  }

  async function abortUploadFn(token: string) {
    await abortUpload({ variables: { input: { token } } }).catch(err => {
      console.log('Error aborting multipart upload', err);
      throw new Error('Error aborting multipart upload');
    });
  }

  const uploadConfig: UseUploadQueueConfig = {
    initializeUploadFn,
    completeUploadFn,
    abortUploadFn,
    onItemCompleted: refetch,
  };

  async function handleDeleteFile(fileName: string) {
    const confirmMsg = 'Are you you sure you want to delete this staged file?';
    if (!window.confirm(confirmMsg)) return;

    await deleteStagedFile({
      variables: {
        input: {
          id: cesiumAssetId,
          fileName,
        },
      },
    }).catch(err => {
      console.log('Error deleting staged file', err);
      throw new Error('Error deleting staged file');
    });
  }

  const currentFiles = data?.cesiumAssetGet?.stagedFiles ?? [];

  return (
    <div className="space-y-6">
      <div className="grid lg:grid-cols-3 gap-6">
        <div>
          <Panel>
            <Panel.Heading>
              <Panel.Title>Uploaded Files</Panel.Title>
            </Panel.Heading>
            <Panel.Body className="p-2">
              <SpinnerPlaceholder show={loading} />

              <CurrentFileList
                files={currentFiles}
                deleteFile={handleDeleteFile}
              />

              {currentFiles.length > 0 && (
                <div className="text-center">
                  <SendToCesium
                    vomId={vomId}
                    cesiumAssetId={cesiumAssetId}
                    refetchQueries={refetchQueries}
                  />
                </div>
              )}
            </Panel.Body>
          </Panel>
        </div>

        <div className="col-span-2">
          <QueueableMultipartUpload uploadConfig={uploadConfig} />
        </div>
      </div>
    </div>
  );
}

function CurrentFileList({
  files,
  deleteFile,
}: {
  files: string[];
  deleteFile: (filename: string) => Promise<void>;
}) {
  return (
    <div>
      {!files.length && (
        <div className="text-center my-3 text-muted italic">
          No files uploaded yet.
        </div>
      )}

      {files.map((f, i) => (
        <div
          key={i}
          className={cn('my-1 py-1 w-full flex justify-between gap-2', {
            'border-b border-b-slate-50': i < files.length - 1,
          })}
        >
          <div className="grow grid grid-cols-12 gap-2">
            <div className="col-span-2 text-sm px-2 pt-[3px] text-slate-400 text-right">
              {i + 1}
            </div>
            <div className="col-span-10 text-left text-slate-800 break-all">
              {f}
            </div>
          </div>

          <div className="shrink">
            <Button
              type="button"
              onClick={() => deleteFile(f)}
              color="ghost"
              size="xs"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

function SendToCesium({
  vomId,
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [sendToCesium, { loading }] = useMutation(sendToCesiumMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  async function handleSend() {
    try {
      await sendToCesium();
    } catch (err) {
      console.log('Error sending to cesium', err);
      toast.error(
        'There was an error sending to Cesium. Are all the required files uploaded?',
      );
    }
  }

  return (
    <Button
      type="button"
      onClick={handleSend}
      startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
      color="primary"
      size="sm"
      loading={loading}
    >
      Send to Cesium
    </Button>
  );
}
