import type { ComponentPropsWithoutRef } from 'react';
import { cn } from '~/utils/common';

export type TooltipProps = {
  message: string;
  /** Disable the tooltip, just show the content instead */
  disabled?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  color?:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
} & ComponentPropsWithoutRef<'div'>;

export function Tooltip({
  message,
  children,
  position,
  color,
  disabled,
  className,
  ...props
}: TooltipProps): JSX.Element {
  if (disabled) return <>{children}</>;
  return (
    <div
      {...props}
      data-tip={message}
      className={cn('tooltip', className, {
        'tooltip-top': position === 'top' || !position,
        'tooltip-bottom': position === 'bottom',
        'tooltip-left': position === 'left',
        'tooltip-right': position === 'right',
        'tooltip-primary': color === 'primary',
        'tooltip-secondary': color === 'secondary',
        'tooltip-accent': color === 'accent',
        'tooltip-info': color === 'info',
        'tooltip-success': color === 'success',
        'tooltip-warning': color === 'warning',
        'tooltip-error': color === 'error',
      })}
    >
      {children}
    </div>
  );
}
