import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { CollapseButton } from '~/components/common/buttons/CollapseButton';
import { AEFilter } from '~/components/dataSearch/AEFilter';
import { CountSummary } from '~/components/dataSearch/CountSummary';
import {
  useDataSearchContext,
  useDataSearchHierarchy,
} from '~/components/dataSearch/dataSearchContext';
import { DataSearchFilter } from '~/components/dataSearch/DataSearchFilter';
import { SelectedFilters } from '~/components/dataSearch/SelectedFilters';
import { countryList } from '~/components/gis/countryList';
import { HelpBox } from '~/components/HelpBox';
import { cn } from '~/utils/common';
import {
  dataSearchInitialValues,
  type DataSearchFormValues,
} from '~/utils/modules/dataSearch';
import { GraphContainer } from './GraphContainer';
import { KeyParamsFilters } from './KeyParamsFilters';
import { OutcropFilters } from './OutcropFilters';

export function DataSearch() {
  const [collapsed, setCollapsed] = useState(false);
  const [leftTab, setLeftTab] = useState<'keyParams' | 'outcrops'>('keyParams');
  const { values, submitCount, resetForm } =
    useFormikContext<DataSearchFormValues>();
  const { loadingMeasurements, options, loadingOptions } =
    useDataSearchContext();
  const hierarchy = useDataSearchHierarchy();

  // https://github.com/omt-tech/safari/issues/2165#issuecomment-2283289648
  // When the form is submitted, automatically switch to the outcrops tab.
  // When the form is reset, switch back to filters tab.
  useEffect(() => {
    setLeftTab(() => (submitCount > 0 ? 'outcrops' : 'keyParams'));
  }, [submitCount]);

  // If all AEs are unchecked, reset the form
  useEffect(() => {
    if (!values.architecturalElement.length) {
      resetForm({ values: dataSearchInitialValues() });
    }
  }, [values.architecturalElement.length, resetForm]);

  const collapseButton = (
    <CollapseButton
      collapsed={collapsed}
      onClick={() => setCollapsed(!collapsed)}
    >
      {collapsed ? 'Show filters' : 'Collapse'}
    </CollapseButton>
  );

  const loading = loadingOptions || loadingMeasurements;

  return (
    <div className="space-y-4">
      <SelectedFilters />

      <AEFilter
        options={options.architecturalElement}
        values={values.architecturalElement}
        hierarchy={hierarchy}
      />

      {!values.architecturalElement.length && (
        <div className="mx-auto lg:w-[600px]">
          <HelpBox>
            Select one or more Architectural Elements to get started.
          </HelpBox>
        </div>
      )}

      {values.architecturalElement.length > 0 && (
        <>
          {collapsed && <div className="grow">{collapseButton}</div>}

          <div className="grid lg:grid-cols-4 gap-6">
            {!collapsed && (
              <div>
                <div className="flex justify-between gap-6 items-center">
                  <div className="space-x-1">{collapseButton}</div>
                </div>

                <div className="clear-both" />

                <CountSummary />

                <ul className="tabs tabs-border my-2">
                  <a
                    role="tab"
                    href="#tab-keyParams"
                    className={cn('tab', {
                      'tab-active': leftTab === 'keyParams',
                    })}
                    onClick={() => setLeftTab('keyParams')}
                  >
                    Filter List
                  </a>
                  <a
                    role="tab"
                    href="#tab-outcrops"
                    className={cn('tab', {
                      'tab-active': leftTab === 'outcrops',
                    })}
                    onClick={() => setLeftTab('outcrops')}
                  >
                    Analogue Filter List
                  </a>
                </ul>

                {leftTab === 'keyParams' && (
                  <KeyParamsFilters hierarchy={hierarchy} options={options} />
                )}
                {leftTab === 'outcrops' && (
                  <div className="space-y-2">
                    <DataSearchFilter
                      fieldName="country"
                      title="Country"
                      options={options.country}
                      values={values.country}
                      defaultCollapsed={!values.country.length}
                      sortOrder={countryList}
                    />
                    <OutcropFilters
                      outcrops={options.outcrops}
                      studies={options.studies}
                      disabled={loading}
                    />
                  </div>
                )}
              </div>
            )}

            <div
              className={cn('space-y-4', {
                'lg:col-span-4': collapsed,
                'lg:col-span-3': !collapsed,
              })}
            >
              <GraphContainer disabled={loading} collapsed={collapsed} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
