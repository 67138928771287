import {
  faBug,
  faLightbulb,
  faMagnifyingGlass,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Link } from 'react-router';
import type { IssueListPageQuery } from '~/apollo/generated/v3/graphql';
import { IssueType, IssueWorkStatus } from '~/apollo/generated/v3/graphql';
import { LocalDate } from '~/components/common/LocalDate';
import { issueDetailRoute } from '~/paths';
import { snakeCapsToHuman } from '~/utils/text';

const isOpen = (status: IssueWorkStatus) =>
  status === IssueWorkStatus.Open || status === IssueWorkStatus.WorkInProgress;

type IssueTypeProps = {
  issueType: string;
  workStatus: IssueWorkStatus;
};
function IssueTypeBadge({ issueType, workStatus }: IssueTypeProps) {
  let icon: FontAwesomeIconProps['icon'];
  if (issueType === 'Bug') {
    icon = faBug;
  } else if (issueType === 'Feature request') {
    icon = faLightbulb;
  } else {
    icon = faQuestionCircle;
  }

  const isBug = issueType === IssueType.Bug;
  const isFeatureRequest = issueType === IssueType.FeatureRequest;
  const isSupport = issueType === IssueType.Support;

  return (
    <span
      className={cn('badge gap-1 cursor-default', {
        'badge-error': isOpen(workStatus) && isBug,
        'badge-success': isOpen(workStatus) && isFeatureRequest,
        'badge-warning': isOpen(workStatus) && isSupport,
        'badge-ghost text-muted': !isOpen(workStatus),
      })}
    >
      <FontAwesomeIcon icon={icon} /> {snakeCapsToHuman(issueType)}
    </span>
  );
}

type Props = {
  issue: IssueListPageQuery['issueList'][number];
};

export function IssueListItem({ issue }: Props) {
  return (
    <tr
      className={cn({
        hover: isOpen(issue.workStatus),
        'text-muted': !isOpen(issue.workStatus),
      })}
    >
      <td>
        <div className="flex items-middle gap-2">
          <strong>{issue.title}</strong>
          <IssueTypeBadge
            issueType={issue.issueType}
            workStatus={issue.workStatus}
          />
        </div>
        <div className="text-sm text-muted">{issue.author.name}</div>
      </td>
      <td>
        <LocalDate date={issue.dateCreated} format={{ dateStyle: 'medium' }} />
      </td>
      <td>{snakeCapsToHuman(issue.workStatus)}</td>
      <td className="text-center">
        {issue.comments ? issue.comments.length : 0}
      </td>
      <td className="text-right">
        <Link
          to={issueDetailRoute(issue.id)}
          className={cn('btn btn-sm gap-1', {
            'btn-primary': isOpen(issue.workStatus),
            'btn-ghost': !isOpen(issue.workStatus),
          })}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} /> View
        </Link>
      </td>
    </tr>
  );
}
