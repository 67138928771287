import type { PureQueryOptions } from '@apollo/client';
import type { FileManagerPageQuery } from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import CmsFile from './CmsFile';

type Props = {
  files: FileManagerPageQuery['cmsFileList'];
  refetchQueries: PureQueryOptions[];
};

function CmsFileList({ files, refetchQueries }: Props) {
  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    files,
    'name',
    'name',
  );

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>File Manager</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-8 lg:col-start-3">
            <FilterSearch
              {...filterSearchProps}
              showAlphabet={false}
              stick={false}
              renderSearch={input => input}
            />
          </div>
        </div>

        <div className="mt-4">
          <table className="table table-fixed table-compact w-full">
            <thead>
              <tr>
                <th className="w-2/12">{/* Thumbnail/links */}</th>
                <th className="w-4/12">
                  <SortTrigger
                    colName="name"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Filename
                  </SortTrigger>
                </th>
                <th className="w-3/12">
                  <SortTrigger
                    colName="type"
                    sortIndicatorProps={sortIndicatorProps}
                    filterable
                  >
                    Type
                  </SortTrigger>
                </th>
                <th className="w-1/12">
                  <SortTrigger
                    colName="size"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Size
                  </SortTrigger>
                </th>
                <th className="text-center w-1/12">
                  <SortTrigger
                    colName="public"
                    sortIndicatorProps={sortIndicatorProps}
                    filterable
                    renderFilterOption={value =>
                      value === 'true' ? 'Public' : 'Private'
                    }
                  >
                    Privacy
                  </SortTrigger>
                </th>
                <th className="w-1/12" />
              </tr>
            </thead>

            <tbody>
              <NoItemsRow show={!items.length} colSpan={6} />

              {items.map(file => (
                <CmsFile
                  key={file.id}
                  file={file}
                  refetchQueries={refetchQueries}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Panel.Body>
    </Panel>
  );
}

export default CmsFileList;
