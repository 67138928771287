import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import type { OutcropEnumerationsQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import type { OutcropFormValues } from '~/utils/modules/outcrop';

type Props = {
  outcropEnums: OutcropEnumerationsQuery;
  disabled?: boolean;
};

export function OutcropEnumFields({ outcropEnums, disabled = false }: Props) {
  const { values, setFieldValue } = useFormikContext<OutcropFormValues>();

  const mapOptions = (value: string) => ({
    value,
    label: value,
  });

  // Unset dominant geology type if it's no longer in the geology types value
  const curGeologyTypes = values.geologyType;
  const curDominantGeologyType = values.dominantGeologyType;

  useEffect(() => {
    if (
      curDominantGeologyType &&
      !curGeologyTypes.includes(curDominantGeologyType)
    ) {
      setFieldValue('dominantGeologyType', '');
      toast.info(
        'The dominant geology type is no longer available and has been unset.',
      );
    }
  }, [curGeologyTypes, curDominantGeologyType, setFieldValue]);

  return (
    <>
      <Field
        name="outcropType"
        label="Outcrop Type"
        component={FormikField}
        type="select"
        options={outcropEnums.outcropType.values.map(mapOptions)}
        disabled={disabled}
        required
      />

      <Field
        name="geologyType"
        label="Geology Type"
        component={FormikField}
        type="select"
        options={outcropEnums.geologyType.values.map(mapOptions)}
        helpText="Select from the dropdown to reflect the key aspects of the outcrop"
        disabled={disabled}
        multiple
        required
      />
      <Field
        name="dominantGeologyType"
        label="Dominant Geology Type"
        component={FormikField}
        type="select"
        options={curGeologyTypes.map(mapOptions)}
        helpText="Select the dominant geology type"
        disabled={disabled}
      />

      <Field
        name="outcropCategory"
        label="Analogue Category"
        component={FormikField}
        type="select"
        options={outcropEnums.outcropCategory.values.map(mapOptions)}
        disabled={disabled}
        required
      />
    </>
  );
}
