import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  PolygonF,
} from '@react-google-maps/api';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router';
import type {
  GeoreferencePartsFragment,
  LatLng,
  RegionMapOutcropPartsFragment,
  RegionPageQuery,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { outcropRoute, regionRoute } from '~/paths';
import { colorByIndex } from '~/utils/chart';
import {
  calculateCenter,
  createBounds,
  markerIcon,
  polygonOptions,
  toLatLng,
} from '~/utils/georeference';

type OutcropWithCenter = RegionMapOutcropPartsFragment & {
  center: LatLng;
};

export type RegionWithOutline = RegionPageQuery['allRegions'][number] & {
  outline: GeoreferencePartsFragment;
};

/** Filter a region's outcrops with the ones that have a center set */
function regionOutcrops(region: RegionWithOutline): OutcropWithCenter[] {
  return region.outcrops.filter((oc): oc is OutcropWithCenter => !!oc.center);
}

type OutcropInfoWindowProps = {
  outcrop: OutcropWithCenter;
  onClose: () => void;
};

function OutcropInfoWindow({ outcrop, onClose }: OutcropInfoWindowProps) {
  function linkText() {
    switch (outcrop?.outcropCategory) {
      case 'production':
        return 'View production analogue';
      case 'modern':
        return 'View modern analogue';
      case 'seismic':
        return 'View seismic analogue';
      case 'outcrop':
      default:
        return 'View outcrop';
    }
  }

  return (
    <InfoWindowF position={outcrop.center} onCloseClick={onClose}>
      <>
        <Heading level={4}>{outcrop.name}</Heading>
        <Link to={outcropRoute(outcrop.id)} className="link">
          {linkText()} &raquo;
        </Link>
      </>
    </InfoWindowF>
  );
}

type Props = {
  regionOutline: GeoreferencePartsFragment | undefined;
  outcrops: RegionPageQuery['regionList'][number]['outcrops'];
  onOutcropClick: (outcropId: number | null) => void;
  selectedOutcropId: number | null;
  allRegions: RegionWithOutline[];
};

export function RegionMap({
  regionOutline,
  outcrops,
  onOutcropClick,
  selectedOutcropId,
  allRegions,
}: Props) {
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedRegion, setSelectedRegion] =
    useState<RegionWithOutline | null>(null);
  const [selectedRegionOutcrop, setSelectedRegionOutcrop] =
    useState<OutcropWithCenter | null>(null);

  const outcropsWithCenter = outcrops.filter(
    (oc): oc is OutcropWithCenter => !!oc.center,
  );

  const selectedOutcrop = outcropsWithCenter.find(
    oc => oc.id === selectedOutcropId,
  );

  function handleLoad(mapEl: google.maps.Map) {
    setMap(mapEl);
    fitBounds(mapEl);
  }

  function fitBounds(mapEl: google.maps.Map) {
    const boundsGeorefs = regionOutline ? [regionOutline] : [];
    const bounds = createBounds(boundsGeorefs);
    mapEl.fitBounds(bounds);
  }

  useEffect(() => {
    if (map && selectedOutcrop) {
      const coords = toLatLng(selectedOutcrop.center);
      map.panTo(coords);
    }
  }, [map, selectedOutcrop]);

  const handleOutcropClick = (ocId: number | null) => () => {
    onOutcropClick(ocId);
  };

  const selectedRegionInfoWindow = useMemo(() => {
    if (!selectedRegion) {
      return null;
    }

    return (
      <InfoWindowF
        position={calculateCenter([selectedRegion.outline])}
        onCloseClick={() => setSelectedRegion(null)}
      >
        <>
          <Heading level={4}>{selectedRegion.name}</Heading>
          <Link to={regionRoute(selectedRegion.id)} className="link">
            View region &raquo;
          </Link>
        </>
      </InfoWindowF>
    );
  }, [selectedRegion]);

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '500px' }}
      mapTypeId={google.maps.MapTypeId.TERRAIN}
      onLoad={handleLoad}
    >
      {outcropsWithCenter.map(oc => (
        <MarkerF
          key={oc.id}
          position={toLatLng(oc.center)}
          onClick={handleOutcropClick(oc.id)}
          icon={markerIcon()}
        />
      ))}

      {allRegions.map((region, i) => (
        <Fragment key={region.id}>
          {regionOutcrops(region).map(oc => (
            <MarkerF
              key={oc.id}
              position={oc.center}
              onClick={() => setSelectedRegionOutcrop(oc)}
              icon={markerIcon({
                stroke: colorByIndex(i),
                fill: colorByIndex(i),
                strokeOpacity: selectedRegion?.id === region.id ? 0.75 : 0.5,
                fillOpacity: selectedRegion?.id === region.id ? 0.3 : 0.1,
              })}
            />
          ))}

          <PolygonF
            path={region.outline.data}
            options={polygonOptions({
              strokeColor: colorByIndex(i),
              fillColor: colorByIndex(i),
              strokeOpacity: selectedRegion?.id === region.id ? 0.75 : 0.5,
              fillOpacity: selectedRegion?.id === region.id ? 0.3 : 0.1,
            })}
            onClick={() => setSelectedRegion(region)}
          />
        </Fragment>
      ))}

      {regionOutline && (
        <PolygonF
          path={regionOutline.data}
          options={polygonOptions({
            strokeOpacity: 0.75,
            fillOpacity: 0.3,
          })}
        />
      )}

      {selectedRegionInfoWindow}
      {selectedOutcrop && (
        <OutcropInfoWindow
          outcrop={selectedOutcrop}
          onClose={() => onOutcropClick(null)}
        />
      )}
      {selectedRegionOutcrop && (
        <OutcropInfoWindow
          outcrop={selectedRegionOutcrop}
          onClose={() => setSelectedRegionOutcrop(null)}
        />
      )}
    </GoogleMap>
  );
}
