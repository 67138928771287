import { faFloppyDisk, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { useRef } from 'react';
import { HelpBox } from '~/components/HelpBox';
import { OptionLabel } from '~/components/analogueSearch/OptionLabel';
import { FormikField } from '~/components/common/FormikField';
import { FormikCheckboxArrayDropdown } from '~/components/common/FormikField/FormikCheckboxArrayDropdown';
import CreateSavedDataSearchModal from '~/components/dataSearch/CreateSavedDataSearchModal';
import {
  CrossPlotWrapper,
  HistogramWrapper,
} from '~/components/dataSearch/GraphContainer';
import { GraphFormFields } from '~/components/dataSearch/GraphFormFields';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import { useCrossPlotData } from '~/components/dataSearch/useCrossPlotData';
import { useHistogramData } from '~/components/dataSearch/useHistogramData';
import { MeasurementStatistics } from '~/components/statistics/MeasurementGraph/MeasurementStatistics';
import { RegressionFormFields } from '~/components/statistics/MeasurementGraph/RegressionFormFields';
import { Button } from '~/components/ui/button';
import { cn } from '~/utils/common';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

export function EmbeddableDataSearch({ name }: { name: string }) {
  const { loadingMeasurements, loadingOptions, options, measurements } =
    useDataSearchContext();
  const { values, isValid } = useFormikContext<DataSearchFormValues>();

  const { dataHistogram } = useHistogramData();
  const { dataCrossPlot } = useCrossPlotData();

  const scrollTarget = useRef<HTMLDivElement>(null);

  const loading = loadingOptions || loadingMeasurements;

  return (
    <div className="space-y-4">
      <div className="grid lg:grid-cols-5 gap-6">
        <div className="lg:col-span-2">
          <GraphFormFields
            options={options}
            disabled={loading}
            prependFields={
              <div className="space-y-2">
                <Field
                  name="outcropCategory"
                  label="Analogue Type"
                  component={FormikField}
                  type={FormikCheckboxArrayDropdown}
                  options={options.outcropCategory.map(opt => ({
                    value: opt.name,
                    label: <OptionLabel option={opt} />,
                  }))}
                  align="start"
                />

                <Field
                  name="architecturalElement"
                  label="Architectural Element"
                  component={FormikField}
                  type={FormikCheckboxArrayDropdown}
                  options={options.architecturalElement.map(opt => ({
                    value: opt.name,
                    label: <OptionLabel option={opt} />,
                  }))}
                  align="start"
                />
              </div>
            }
          />
        </div>

        <div className="lg:col-span-3 space-y-4">
          <div id="graphSection" ref={scrollTarget} />
          {values.graphType === 'crossPlot' && isValid && (
            <CrossPlotWrapper collapsed={false} />
          )}
          {values.graphType === 'histogram' && isValid && (
            <HistogramWrapper collapsed={false} />
          )}

          <div
            className={cn('w-full sticky top-10 bg-white p-2', {
              'top-10': !measurements.length,
            })}
          >
            <div className="text-center space-x-2">
              {isValid && (
                <>
                  {measurements.length && (
                    <CreateSavedDataSearchModal dataSearch={values} name={name}>
                      {showModal => (
                        <Button
                          type="button"
                          onClick={showModal}
                          color="primary"
                          variant="outline"
                          startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
                        >
                          Bookmark Data
                        </Button>
                      )}
                    </CreateSavedDataSearchModal>
                  )}

                  <Button
                    type="submit"
                    color="primary"
                    disabled={loading}
                    startIcon={<FontAwesomeIcon icon={faRefresh} />}
                  >
                    Update Data
                  </Button>
                </>
              )}

              {!isValid && (
                <HelpBox>
                  Select a <strong>data type</strong>
                  {values.graphType === 'crossPlot' ? ' for each axis ' : ' '}
                  to proceed.
                </HelpBox>
              )}
            </div>
          </div>
        </div>
      </div>

      {values.graphType === 'crossPlot' && dataCrossPlot.length > 0 && (
        <MeasurementStatistics
          dataX={dataCrossPlot.map(d => d.x)}
          dataY={dataCrossPlot.map(d => d.y)}
        >
          <RegressionFormFields data={dataCrossPlot} />
        </MeasurementStatistics>
      )}
      {values.graphType === 'histogram' && dataHistogram.length > 0 && (
        <MeasurementStatistics dataX={dataHistogram.map(d => d.value)} />
      )}
    </div>
  );
}
