import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { lithostratTypeParts } from '~/apollo/fragments';
import type {
  UpdateLithostratTypeMutation,
  UpdateLithostratTypeMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { DeleteLithostratType } from '~/components/upload/lithostratType/DeleteLithostratType';
import { LithostratTypeFormFields } from '~/components/upload/lithostratType/LithostratTypeFormFields';
import { uploadLithostratTypesRoute } from '~/paths';
import { useLithostratTypeUpdateOutletContext } from '~/routes/upload/lithostrat-type/$lithostratTypeId';
import type { LithostratTypeFormValues } from '~/utils/modules/lithostratType';
import {
  formValuesToLithostratTypeInput,
  initialLithostratType,
  lithostratTypeValidationSchema,
} from '~/utils/modules/lithostratType';
import { Collapse } from '~/components/ui/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const UPDATE_LITHOSTRAT_TYPE = gql`
  mutation UpdateLithostratType(
    $id: Int!
    $lithostratType: LithostratTypeInput!
  ) {
    updateLithostratType(id: $id, lithostratType: $lithostratType) {
      ...lithostratTypeParts
    }
  }

  ${lithostratTypeParts}
`;

export default function LithostratTypeUpdateDetailsRoute() {
  const { lithostratType } = useLithostratTypeUpdateOutletContext();

  const navigate = useNavigate();

  const [updateLithostratType, { loading, error }] = useMutation<
    UpdateLithostratTypeMutation,
    UpdateLithostratTypeMutationVariables
  >(UPDATE_LITHOSTRAT_TYPE, {});

  async function handleSubmit(values: LithostratTypeFormValues) {
    const updatedLT = formValuesToLithostratTypeInput(values);
    try {
      await updateLithostratType({
        variables: { id: lithostratType.id, lithostratType: updatedLT },
      });
      toast.success('Lithostrat type updated successfully.');
    } catch (err) {
      console.log('Error updating lithostrat type', err);
      toast.error('There was a problem updating the lithostrat type.');
    }
  }

  function handleDeleteSuccess() {
    toast.success('Lithostrat type deleted successfully.');
    navigate(uploadLithostratTypesRoute());
  }

  return (
    <div className="space-y-6">
      <Panel>
        <Panel.Heading>
          <Panel.Title>Update Details</Panel.Title>
        </Panel.Heading>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialLithostratType(lithostratType)}
          validationSchema={lithostratTypeValidationSchema}
          enableReinitialize
        >
          <Form>
            <Panel.Body className="space-y-4">
              <LithostratTypeFormFields />
              <FormErrors graphQLError={error} />
            </Panel.Body>

            <Panel.Footer className="text-right">
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Panel.Footer>
          </Form>
        </Formik>
      </Panel>

      <div className="lg:w-1/2 mx-auto card shadow rounded-2xl">
        <Collapse
          icon="arrow"
          title="Delete Lithostrat Type"
          titleClassName="card-title"
          contentClassName="space-y-4"
        >
          <p>
            Deleting this lithostrat type will remove it from the list of
            available options within each lithostrat entity type (group,
            formation, or member). Lithostrat types may only be deleted if no
            entities are linked to it.
          </p>

          <div className="text-center">
            <DeleteLithostratType
              lithostratTypeId={lithostratType.id}
              onDeleteSuccess={handleDeleteSuccess}
            >
              {handleDelete => (
                <Confirm
                  onConfirm={handleDelete}
                  text="This lithostrat type will be attempted to be deleted. This action cannot be undone."
                  submitButtonColor="error"
                  submitText="Delete Lithostrat Type"
                >
                  {handleConfirm => (
                    <Button
                      type="button"
                      color="error"
                      size="sm"
                      onClick={handleConfirm}
                      startIcon={<FontAwesomeIcon icon={faTrash} />}
                    >
                      Permanently Delete Lithostrat Type
                    </Button>
                  )}
                </Confirm>
              )}
            </DeleteLithostratType>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
