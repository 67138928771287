import type { SearchOutcropsOutcropResultsQuery } from '~/apollo/generated/v3/graphql';
import { SearchResultItem } from '~/components/analogueSearch/SearchResultItem';

type Props = {
  outcrops: SearchOutcropsOutcropResultsQuery['searchOutcrops']['outcrops'];
};

export function ThumbnailView({ outcrops }: Props) {
  return (
    <>
      {outcrops.map(outcrop => (
        <SearchResultItem key={outcrop.id} outcrop={outcrop} />
      ))}
    </>
  );
}
