import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fragments from '~/apollo/fragments';
import {
  type MyBookmarksTabQuery,
  type MyBookmarksTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { BookmarkList } from '~/components/bookmark/BookmarkList';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useMySafariOutletContext } from '~/routes/my-safari';

const MY_BOOKMARKS = gql`
  query MyBookmarksTab {
    unsortedBookmarkList(includePersonal: true, includeCompany: false) {
      ...bookmarkParts
      parent {
        ...bookmarkParentParts
      }
      target {
        ...bookmarkTargetParts
      }
      user {
        ...publicUserParts
      }
      collections {
        ...bookmarkCollectionParts
      }
    }
  }

  ${fragments.bookmarkParts}
  ${fragments.bookmarkCollectionParts}
  ${fragments.bookmarkParentParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.publicUserParts}
`;

export default function MySafariBookmarksRoute() {
  const { personalCollections, refetchQueries } = useMySafariOutletContext();

  const { data, loading } = useQuery<
    MyBookmarksTabQuery,
    MyBookmarksTabQueryVariables
  >(MY_BOOKMARKS);

  const localRefetchQueries: [PureQueryOptions<MyBookmarksTabQueryVariables>] =
    [{ query: MY_BOOKMARKS }];
  const allRefetchQueries: PureQueryOptions[] = [
    ...refetchQueries,
    ...localRefetchQueries,
  ];

  const bookmarks = data?.unsortedBookmarkList ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      {!bookmarks.length && (
        <div className="text-center">
          <p>You haven't created any bookmarks yet.</p>
          <p>
            To create a bookmark, click the Bookmarks button (
            <FontAwesomeIcon icon={faBookmark} />) on the corresponding page or
            element that you wish to bookmark.
          </p>
        </div>
      )}

      <BookmarkList
        bookmarks={bookmarks}
        collections={personalCollections}
        refetchQueries={allRefetchQueries}
        sortStateKey="myBookmarks"
      />
    </>
  );
}
