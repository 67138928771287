import type { Cesium3DTileset, Entity, Viewer } from 'cesium';
import CesiumNavigation from 'cesium-navigation-es6';
import { useEffect, useRef, useState } from 'react';
import type { TilesetParams } from './cesiumUtils';
import {
  addPolygonsToViewer,
  addTilesetToViewer,
  enableTilesetPlacement,
  setDepthTest,
  setShowGlobe,
  setupCesiumViewer,
  TerrainProvider,
} from './cesiumUtils';
import type { PolygonEntityStruct } from './limeParse';

type ViewerProps = {
  terrainProvider?: TerrainProvider;
  initialTilesets: TilesetParams[];
  hideTilesets?: TilesetParams[];
  globeMode?: boolean;
  updatePlacement?: (placement: LatLngHeightHPR) => void;
  sendTileset?: (tileset: Cesium3DTileset) => void;
  polygonsData?: PolygonEntityStruct[];
  enableNavigation?: boolean;
  sendCesiumViewer?: (viewer: Viewer) => void;
  cameraCallback?: (viewer: Viewer) => void;
  enableClickPlacement?: boolean;
  tilesetClipping?: boolean | null;
  showGlobe?: boolean | null;
  infoBox?: boolean;
  afterLoad?: (viewer: Viewer) => void;
};

export type LatLngHeightHPR = {
  latitude: number;
  longitude: number;
  height: number;
  heading: number;
  pitch: number;
  roll: number;
};

export function CesiumViewer({
  terrainProvider = TerrainProvider.None,
  initialTilesets = [],
  hideTilesets = [],
  updatePlacement = () => null,
  sendTileset = () => {},
  polygonsData = [],
  enableNavigation = true,
  sendCesiumViewer = () => null,
  cameraCallback = () => null,
  enableClickPlacement = false,
  tilesetClipping = null,
  showGlobe = null,
  infoBox = false,
  afterLoad = (viewer: Viewer) => null,
}: ViewerProps) {
  const [viewer, setViewer] = useState<Viewer>();
  const [nav, setNav] = useState<CesiumNavigation>();
  const [polygons, setPolygons] = useState<Entity[]>();
  const [tilesets, setTilesets] = useState<Record<string, Cesium3DTileset>>({});

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function initialSetup() {
      if (ref.current !== null) {
        // console.log('ref: ', ref.current);
        if (ref.current.childElementCount === 0) {
          console.log('No children found, adding viewer!');

          const v = await setupCesiumViewer(
            ref.current,
            terrainProvider,
            false,
            infoBox,
          );
          const currentTilesets: Record<string, Cesium3DTileset> = {};
          for (let tsParams of initialTilesets) {
            console.log('tsparams', tsParams);
            const ts = await addTilesetToViewer(v, tsParams, true);
            if (tsParams.defaultCamera) {
              v.scene.camera.flyTo({
                destination: tsParams.defaultCamera.position,
                orientation: tsParams.defaultCamera.orientation,
              });
            }
            if (enableNavigation) {
              const navOptions = {
                enableCompass: true,
                enableZoomControls: true,
                enableDistanceLegend: true,
                enableCompassOuterRing: true,
                defaultResetView: ts,
                resetTooltip: 'Reset View',
                zoomInTooltip: 'Zoom In',
                zoomOutTooltip: 'Zoom Out',
              };
              if (!nav) {
                const cesNav = new CesiumNavigation(v, navOptions);
                console.log('nav', cesNav);
                setNav(cesNav);
              }
              if (enableClickPlacement && ts) {
                enableTilesetPlacement(v, ts, updatePlacement);
              }
            }
            // setTilesets((prev) => [...prev, ts!]);
            // TODO: Fix to send multiple tilesets at once
            if (ts) {
              sendTileset(ts);
              currentTilesets[tsParams.assetToken] = ts;
            }
            console.log('Added tileset:', ts);
          }
          v.camera.percentageChanged = 0.01;
          v.scene.camera.changed.addEventListener(function () {
            cameraCallback(v);
          });
          setTilesets(currentTilesets);
          console.log('viewer', v)
          setViewer(v);
          sendCesiumViewer(v);
          afterLoad(v);
        }
      }
    }

    initialSetup();
  }, [
    terrainProvider,
    initialTilesets,
    enableNavigation,
    sendTileset,
    updatePlacement,
    nav,
    cameraCallback,
    enableClickPlacement,
    infoBox,
    sendCesiumViewer,
    afterLoad,
  ]);

  useEffect(() => {
    const hideAssetTokens = hideTilesets.map((tsParam) => tsParam.assetToken);
    Object.keys(tilesets).forEach((asset) => {
      const ts = tilesets[asset];
      if (!ts) return;
      if (hideAssetTokens.includes(asset)) {
        ts.show = false;
      } else {
        ts.show = true;
      }
    })
  }, [initialTilesets, hideTilesets, tilesets])

  useEffect(() => {
    if (tilesetClipping !== null && viewer) {
      setDepthTest(viewer, tilesetClipping);
    }
  }, [tilesetClipping, viewer]);

  useEffect(() => {
    if (showGlobe !== null && viewer) {
      setShowGlobe(viewer, showGlobe);
    }
  }, [showGlobe, viewer]);

  useEffect(() => {
    async function addPolygons() {
      if (viewer && polygonsData.length > 0) {
        if (polygons) {
          polygons.forEach(p => viewer.entities.remove(p));
          setPolygons([]);
        }
        const polygonEntities = await addPolygonsToViewer(viewer, polygonsData);
        setPolygons(polygonEntities);
        if (polygonEntities.length > 0) {
          viewer.zoomTo(polygonEntities);
        }
      }
    }
    addPolygons();
  }, [polygonsData, polygons, viewer]);

  // function getCameraInfo() {
  //   if (!viewer) {
  //     console.log("Viewer isn't mounted!");
  //     return;
  //   }

  //   const pos = viewer.camera.position.toString();
  //   console.log('Viewer instance:', viewer);
  //   window.alert(`Camera position is ${pos}`);
  // }

  return (
    <>
      <div ref={ref} id="cesiumviewer" className="aspect-5/4 w-full"></div>
      {/* <Button onClick={getCameraInfo} disabled={!viewer} color="primary">
        Get camera info
      </Button> */}
    </>
  );
}
