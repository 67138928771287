import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeleteBookmarkMutation,
  DeleteBookmarkMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { useAuth } from '~/contexts/auth';
import type { Bookmark } from '~/utils/modules/bookmark';
import { isBookmarkOwner, isCompanyBookmark } from '~/utils/modules/bookmark';

const DELETE_BOOKMARK = gql`
  mutation DeleteBookmark($bookmarkId: Int!) {
    deleteBookmark(bookmarkId: $bookmarkId)
  }
`;

type Props = {
  children: (deleteBookmark: () => Promise<void>) => JSX.Element;
  bookmark: Bookmark;
  refetchQueries: PureQueryOptions[];
};

export function DeleteBookmark({ children, bookmark, refetchQueries }: Props) {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be logged in');

  const [deleteBookmark] = useMutation<
    DeleteBookmarkMutation,
    DeleteBookmarkMutationVariables
  >(DELETE_BOOKMARK, {
    variables: { bookmarkId: bookmark.id },
    refetchQueries,
  });

  const baseConfirmText = 'Are you sure you want to delete this bookmark?';
  const personalConfirmText = baseConfirmText;
  const companyConfirmText =
    baseConfirmText +
    ' Since this is a company bookmark, it will no longer be visible to anyone in your company once deleted.';
  const otherUserConfirmText =
    'This bookmark belongs to another user, if it is deleted they will no longer have access to it! Are you sure you want to proceed?';

  const handleDelete = async () => {
    let confirmText: string;
    if (isCompanyBookmark(bookmark)) confirmText = companyConfirmText;
    else if (isBookmarkOwner(authority.user.id, bookmark))
      confirmText = personalConfirmText;
    else confirmText = otherUserConfirmText;

    if (!window.confirm(confirmText)) return;

    try {
      await deleteBookmark();
      toast.info('Bookmark deleted successfully.');
    } catch (err) {
      console.log('Error deleting bookmark', err);
      toast.error(
        'There was a problem deleting the bookmark. It may have been already deleted.',
      );
    }
  };

  return children(handleDelete);
}
