import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useParams } from 'react-router';
import React from 'react';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  LithostratTypePageQuery,
  LithostratTypePageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import type { Props as DiagramRendererProps } from '~/components/diagram/DiagramRenderer';
import { DiagramRenderer } from '~/components/diagram/DiagramRenderer';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';

const LITHOSTRAT_TYPE_PAGE = gql`
  query LithostratTypePage($id: Int!) {
    lithostratTypeList(id: $id) {
      ...lithostratTypeParts
      diagram {
        ...diagramParts
        file {
          ...fileParts
        }
        diagramAreas {
          ...diagramAreaParts
        }
      }
    }
  }

  ${fragments.lithostratTypeParts}
  ${fragments.diagramParts}
  ${fragments.diagramAreaParts}
  ${fragments.fileParts}
`;

export default function LithostratTypeDetailRoute() {
  const params = useParams();
  invariant(params.lithostratTypeId, 'lithostratTypeId param required');
  const ltId = parseInt(params.lithostratTypeId);

  const { data, loading } = useQuery<
    LithostratTypePageQuery,
    LithostratTypePageQueryVariables
  >(LITHOSTRAT_TYPE_PAGE, {
    variables: { id: ltId },
  });

  const lithostratType = (data?.lithostratTypeList ?? []).find(
    lt => lt.id === ltId,
  );

  // This is totally unnecessary but TypeScript can't seem to agree that
  // a file actually exists when checking it inline...
  const diagram = React.useMemo((): DiagramRendererProps['diagram'] | null => {
    const d = lithostratType?.diagram;
    if (d && d.file) return { ...d, file: d.file };
    return null;
  }, [lithostratType?.diagram]);

  useBreadcrumb(
    'routes/wiki/regional/lithostrat/type.$lithostratTypeId',
    lithostratType?.name ?? 'Lithostrat Type',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!lithostratType) return <NotFound />;

  return (
    <>
      <PageHeading>{lithostratType.name}</PageHeading>

      {diagram ? (
        <DiagramRenderer diagram={diagram} />
      ) : (
        <div className="text-muted">
          This type doesn't have a diagram associated with it.
        </div>
      )}
    </>
  );
}
