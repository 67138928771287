import {
  DrawingManagerF,
  GoogleMap,
  MarkerF,
  PolygonF,
  PolylineF,
} from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import type {
  GeoreferencePartsFragment,
  LatLngInput,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import {
  createBounds,
  createMarker,
  isMarkerType,
  isPolygonType,
  isPolylineType,
} from '~/utils/georeference';

const red = '#bf3638';

const polygonOptions: google.maps.PolygonOptions = {
  strokeColor: red,
  strokeWeight: 5,
  fillColor: red,
  fillOpacity: 0.1,
};
const polylineOptions: google.maps.PolylineOptions = {
  strokeColor: red,
  strokeWeight: 5,
};

function getMapMarkers(georeferences: GeoreferencePartsFragment[]) {
  return georeferences
    .filter(g => isMarkerType(g.dataType))
    .map(g => createMarker(g, { key: g.id }));
}

function getMapPolygons(georeferences: GeoreferencePartsFragment[]) {
  return georeferences
    .filter(g => isPolygonType(g.dataType))
    .map(item => (
      <PolygonF key={item.id} path={item.data} options={polygonOptions} />
    ));
}

function getMapPolylines(georeferences: GeoreferencePartsFragment[]) {
  return georeferences
    .filter(g => isPolylineType(g.dataType))
    .map(g => <PolylineF key={g.id} path={g.data} options={polylineOptions} />);
}

type LatLng = google.maps.LatLng;

type Props = {
  georeferences: GeoreferencePartsFragment[];
  drawingModes: google.maps.drawing.OverlayType[];
  handleDrawingComplete: (coordinates: LatLngInput[] | null) => void;
};

export function MapEditor({
  georeferences,
  drawingModes,
  handleDrawingComplete,
}: Props) {
  const [map, setMap] = useState<google.maps.Map>();
  const [isBoundsSet, setIsBoundsSet] = useState(false);

  // State to hold a temporary marker or polygon/line that gets cleared automatically
  const [tmpMarker, setTmpMarker] = useState<LatLng | null>(null);
  const [tmpPolygon, setTmpPolygon] = useState<LatLng[] | null>(null);
  const [tmpPolyline, setTmpPolyline] = useState<LatLng[] | null>(null);

  function handleLoad(mapInstance: google.maps.Map) {
    setMap(mapInstance);
  }

  function fitBounds() {
    if (!map || isBoundsSet) return;

    if (georeferences.length > 0) {
      const bounds = createBounds(georeferences);
      map.fitBounds(bounds);
    } else {
      map.setZoom(1);
      map.setCenter({ lat: 0, lng: 0 });
    }

    setIsBoundsSet(true);
  }

  function handlePolygonComplete(polygon: google.maps.Polygon) {
    const path = polygon.getPath().getArray();
    const coordinates = path.map<LatLngInput>(coords => ({
      lat: coords.lat(),
      lng: coords.lng(),
    }));

    polygon.setMap(null);
    setTmpPolygon(path);
    handleDrawingComplete(coordinates);
  }

  function handlePolylineComplete(polyline: google.maps.Polyline) {
    const path = polyline.getPath().getArray();
    const coordinates = path.map<LatLngInput>(coords => ({
      lat: coords.lat(),
      lng: coords.lng(),
    }));

    polyline.setMap(null);
    setTmpPolyline(path);
    handleDrawingComplete(coordinates);
  }

  function handleMarkerComplete(marker: google.maps.Marker) {
    const position = marker.getPosition();
    if (!position) {
      handleDrawingComplete(null);
      return;
    }

    marker.setMap(null);
    setTmpMarker(position);
    handleDrawingComplete([{ lat: position.lat(), lng: position.lng() }]);
  }

  function handleMarkerDragged(event: google.maps.MapMouseEvent) {
    const position = event.latLng;
    if (!position) {
      handleDrawingComplete(null);
      return;
    }

    setTmpMarker(position);
    handleDrawingComplete([{ lat: position.lat(), lng: position.lng() }]);
  }

  useEffect(() => {
    console.log('Drawing modes updated, clearing temporary drawn elements');
    setTmpMarker(null);
    setTmpPolygon(null);
    setTmpPolyline(null);
  }, [drawingModes]);

  const markers = getMapMarkers(georeferences);
  const polygons = getMapPolygons(georeferences);
  const polylines = getMapPolylines(georeferences);

  const isDrawingEnabled = drawingModes.length > 0;

  return (
    <>
      <Heading level={3}>Preview</Heading>

      <GoogleMap
        onLoad={handleLoad}
        onIdle={fitBounds}
        mapTypeId="terrain"
        mapContainerStyle={{ height: '600px' }}
      >
        <DrawingManagerF
          options={{
            drawingMode: drawingModes.at(0) ?? null,
            drawingControl: isDrawingEnabled,
            drawingControlOptions: {
              drawingModes,
              position: google.maps.ControlPosition.TOP_CENTER,
            },
          }}
          onMarkerComplete={handleMarkerComplete}
          onPolygonComplete={handlePolygonComplete}
          onPolylineComplete={handlePolylineComplete}
        />

        {markers}
        {polygons}
        {polylines}

        {tmpMarker && (
          <MarkerF
            position={tmpMarker}
            draggable
            onDragEnd={handleMarkerDragged}
          />
        )}
        {tmpPolygon && <PolygonF path={tmpPolygon} />}
        {tmpPolyline && <PolylineF path={tmpPolyline} />}
      </GoogleMap>
    </>
  );
}
