import * as R from 'ramda';
import { useCallback, useState } from 'react';
import type {
  GeoreferencePartsFragment,
  RegionPageQuery,
  RegionPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { isGlobalRegion } from '~/utils/modules/region';
import { OutcropList } from './OutcropList';
import type { RegionWithOutline } from './RegionMap';
import { RegionMap } from './RegionMap';

type Props = {
  region: RegionPartsFragment;
  regionOutline: GeoreferencePartsFragment | undefined;
  outcrops: RegionPageQuery['regionList'][number]['outcrops'];
  allRegions: RegionWithOutline[];
};

export function RegionOverview({
  region,
  regionOutline,
  outcrops,
  allRegions,
}: Props) {
  const [selectedOCId, setSelectedOCId] = useState<number | null>(null);

  const sortedOutcrops = R.sortBy(R.prop('name'), outcrops);

  const handleOutcropClick = useCallback((outcropId: number | null) => {
    setSelectedOCId(outcropId);
  }, []);

  const outcropList = (
    <OutcropList
      outcrops={sortedOutcrops}
      selectedOutcropId={selectedOCId}
      onMapButtonClick={handleOutcropClick}
    />
  );

  if (isGlobalRegion(region)) {
    return outcropList;
  }

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      <div className="lg:col-span-5">{outcropList}</div>
      <div className="lg:col-span-7">
        <RegionMap
          regionOutline={regionOutline}
          outcrops={outcrops}
          selectedOutcropId={selectedOCId}
          onOutcropClick={handleOutcropClick}
          allRegions={allRegions}
        />
      </div>
    </div>
  );
}
