import type { DocumentNode, PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { FormErrors } from '~/components/common/FormErrors';
import type {
  SupportObject,
  SupportObjectFormValues,
  SupportObjectType,
} from '~/utils/modules/supportObject';
import {
  initialSupportingObject,
  toSOInputType,
} from '~/utils/modules/supportObject';
import { SupportObjectForm } from './SupportObjectForm';

type Props = {
  outcropId?: number;
  studyId?: number;
  supportObject: SupportObject;
  supportObjectType: SupportObjectType;
  updateMutation: DocumentNode;
  refetchQueries: PureQueryOptions[];
  onUpdateSuccess?: () => void;
};

export function UpdateSupportObject({
  outcropId,
  studyId,
  updateMutation,
  supportObject,
  supportObjectType,
  refetchQueries,
  onUpdateSuccess,
}: Props) {
  const [updateSupportingObject, { loading, error }] = useMutation(
    updateMutation,
    { refetchQueries },
  );

  async function handleSubmit(values: SupportObjectFormValues) {
    const so = toSOInputType(supportObjectType, values);

    try {
      await updateSupportingObject({
        variables: {
          id: supportObject.id,
          [supportObjectType]: so,
        },
      });

      toast.success('Support object updated successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error updating support object:', err);
      toast.error('There was a problem updating the support object.');
    }
  }

  return (
    <Formik
      initialValues={initialSupportingObject(supportObject)}
      onSubmit={handleSubmit}
    >
      <Form className="space-y-4">
        <div>
          <SupportObjectForm
            studyId={studyId}
            supportObjectType={supportObjectType}
          />
        </div>

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
