import { gql } from '~/apollo/client-v3';
import {} from '@apollo/client';

export const CREATE_GEOREFERENCE = gql`
  mutation CreateGeoreference(
    $parentType: GeoreferenceParent!
    $parentId: Int!
    $georeference: GeoreferenceInput!
  ) {
    createGeoreference(
      parent: $parentType
      parentId: $parentId
      georeference: $georeference
    ) {
      id
    }
  }
`;

export const DELETE_GEOREFERENCE = gql`
  mutation DeleteGeoreference(
    $id: Int!
    $parentId: Int!
    $parent: GeoreferenceParent!
  ) {
    deleteGeoreference(id: $id, parent: $parent, parentId: $parentId)
  }
`;
