import type { GoogleMapProps } from '@react-google-maps/api';
import { GoogleMap, PolygonF } from '@react-google-maps/api';
import type { ReportOutcropPartsFragment } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { Prose } from '~/components/common/Prose';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { TabularView } from '~/components/keyParameter/KeyParametersPanel/TabularView';
import { OutcropPaleomapsTab } from '~/components/outcrop/PaleomapsTab';
import type { ReportDisplayReportItem } from '~/components/report/ReportDisplay/ReportItem';
import { createBounds, polygonOptions } from '~/utils/georeference';
import { supportedGeologyTypes } from '~/utils/modules/keyParameters';
import { fieldLabel } from '~/utils/modules/outcrop';
import { ucwords } from '~/utils/text';

function HtmlSection({
  title,
  content,
}: {
  title: string;
  content: string | null | undefined;
}) {
  if (!content || content.length === 0) return null;

  return (
    <>
      <Heading level={4}>{title}</Heading>
      <Prose dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
}

export function ReportItemOutcrop({
  reportItem,
  outcrop,
}: {
  reportItem: ReportDisplayReportItem;
  outcrop: ReportOutcropPartsFragment;
}) {
  const handleMapLoad: GoogleMapProps['onLoad'] = map => {
    const boundsGeorefs = outcrop.outline ? [outcrop.outline] : [];
    const bounds = createBounds(boundsGeorefs);
    map.fitBounds(bounds);
  };

  type KPsForTable = {
    geologyType: string;
    keyParameters: typeof outcrop.keyParameters;
  }[];
  const separatedKPs = supportedGeologyTypes.reduce<KPsForTable>((acc, gt) => {
    const gtKps = outcrop.keyParameters.filter(kp => kp.geologyType === gt);
    if (gtKps.length > 0) {
      acc.push({
        geologyType: gt,
        keyParameters: gtKps,
      });
    }
    return acc;
  }, []);

  return (
    <>
      <Heading level={4} className="mt-0 mb-2 text-muted">
        {outcrop.region.location.country} - {outcrop.region.name}
      </Heading>

      <div className="grid grid-cols-3 lg:grid-cols-4 gap-6">
        <div className="col-span-2 lg:col-span-3">
          <div className="space-y-4">
            {reportItem.outcropShowIntroduction && (
              <HtmlSection
                title={fieldLabel('introduction', outcrop.outcropCategory)}
                content={outcrop.introduction}
              />
            )}
            {reportItem.outcropShowDescription && (
              <HtmlSection
                title={fieldLabel('description', outcrop.outcropCategory)}
                content={outcrop.description}
              />
            )}
            {reportItem.outcropShowEnvironments && (
              <HtmlSection
                title={fieldLabel('environments', outcrop.outcropCategory)}
                content={outcrop.environments}
              />
            )}
            {reportItem.outcropShowStratigraphy && (
              <HtmlSection
                title={fieldLabel('stratigraphy', outcrop.outcropCategory)}
                content={outcrop.stratigraphy}
              />
            )}
            {reportItem.outcropShowDiagenesisAndPetrophysicalProperties && (
              <HtmlSection
                title={fieldLabel(
                  'diagenesisAndPetrophysicalProperties',
                  outcrop.outcropCategory,
                )}
                content={outcrop.diagenesisAndPetrophysicalProperties}
              />
            )}
            {reportItem.outcropShowStructuralGeology && (
              <HtmlSection
                title={fieldLabel('structuralGeology', outcrop.outcropCategory)}
                content={outcrop.structuralGeology}
              />
            )}
            {reportItem.outcropShowNotablesAndAnalogues && (
              <HtmlSection
                title={fieldLabel(
                  'notablesAndAnalogues',
                  outcrop.outcropCategory,
                )}
                content={outcrop.notablesAndAnalogues}
              />
            )}
            {reportItem.outcropShowLocation && (
              <HtmlSection
                title={fieldLabel('location', outcrop.outcropCategory)}
                content={outcrop.location}
              />
            )}
            {reportItem.outcropShowSafari && (
              <HtmlSection
                title={fieldLabel('safari', outcrop.outcropCategory)}
                content={outcrop.safari} // Key References and Links
              />
            )}

            {reportItem.outcropShowKeyParameters && (
              <div className="space-y-2">
                <Heading level={4}>Key Parameters</Heading>
                {separatedKPs.map(skp => (
                  <div key={skp.geologyType} className="space-y-1">
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title>
                          <div className="flex items-center gap-2">
                            <GeologyTypeIcon
                              geologyType={skp.geologyType}
                              className="inline-block w-4 h-4"
                              hideTooltip
                            />
                            <span>{ucwords(skp.geologyType)}</span>
                          </div>
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        <TabularView
                          geologyType={skp.geologyType}
                          keyParameters={skp.keyParameters}
                          sortable={false}
                        />
                      </Panel.Body>
                    </Panel>
                  </div>
                ))}
              </div>
            )}

            {reportItem.outcropShowPaleogeography && (
              <div className="space-y-2">
                <Heading level={4}>Palaeogeography</Heading>
                <OutcropPaleomapsTab outcrop={outcrop} />
              </div>
            )}
          </div>
        </div>

        <div className="space-y-2">
          {outcrop.thumbnail && (
            <div className="thumbnail">
              <img src={outcrop.thumbnail.signedUrl} alt="Outcrop thumbnail" />
            </div>
          )}

          {outcrop.outline && (
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '300px' }}
              onLoad={handleMapLoad}
            >
              <PolygonF
                path={outcrop.outline.data}
                options={polygonOptions()}
              />
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  );
}
