import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import { camelize, decamelize } from 'humps';
import * as R from 'ramda';
import { twMerge } from 'tailwind-merge';
export { v4 as uuid } from 'uuid';

export function truncateText(
  text: string | null | undefined,
  numCharacters: number,
): string {
  if (!text) {
    return '';
  }

  let newString = text.substring(0, numCharacters);

  if (text.length > numCharacters) {
    newString += '...';
  }

  return newString;
}

export function arraysEqual<T>(a: T[], b: T[]): boolean {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  if (typeof a !== 'object' || typeof b !== 'object') {
    return false;
  }

  a = a.slice(0).sort();
  b = b.slice(0).sort();

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

export function sortByArray(unsorted: string[], order: string[]): string[];
export function sortByArray<T>(
  unsorted: T[],
  order: string[],
  getter: (item: T) => string,
): T[];
export function sortByArray<T>(
  unsorted: string[] | T[],
  order: string[],
  getter?: (item: T) => string,
) {
  let priorityOrder = order.map(item => item.toLowerCase());
  let sorted = unsorted.slice(0).sort((a, b) => {
    const aProp = getter && typeof a === 'object' ? getter(a) : a;
    const bProp = getter && typeof b === 'object' ? getter(b) : b;

    const priorityA = priorityOrder.indexOf(String(aProp).toLowerCase());
    const priorityB = priorityOrder.indexOf(String(bProp).toLowerCase());

    // If the item is not found in order array, put it at end of list
    if (priorityA === -1) return 1;
    if (priorityB === -1) return -1;

    if (priorityA > priorityB) return 1;
    if (priorityA === priorityB) return 0;
    if (priorityA < priorityB) return -1;

    return 0;
  });

  return sorted;
}

export const formatName = (name: string): string => {
  const camelized = camelize(name);
  return decamelize(camelized, { separator: ' ' }).replace(/\b\w/g, m =>
    m.toUpperCase(),
  );
};

export const generateSlug = (title: string): string =>
  title
    .toLowerCase()
    .replace(/[^\w\d\s]/g, '') // remove non-alphanumeric and non-space characters
    .replace(/\b(of|the|an|a)\b/g, '') // remove common words
    .trim()
    .replace(/\s+/g, '-') // replace spaces with dashes
    .substring(0, 150);

/** Checks if a string/number field contains some value and doesn't contain the string "undefined" */
export function hasValue(val: number | string | null | undefined): boolean {
  if (R.isNil(val)) return false;
  if (val === 'undefined') return false;
  if (typeof val === 'number') return true;
  return val.length > 0;
}

export function filterUnique<T>(item: T, i: number, self: T[]): boolean {
  return self.indexOf(item) === i;
}

export const filterUniqueBy =
  <T, K>(searchFn: (arrayItem: T) => K) =>
  (item: T, i: number, self: T[]): boolean =>
    self.findIndex(arItem => searchFn(arItem) === searchFn(item)) === i;

export function rejectNil<T>(item: T): item is NonNullable<T> {
  return typeof item !== 'undefined' && item !== null;
}

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export type TSEnum = Record<string, string>;

export function isEnumMember<T extends TSEnum>(
  enumType: T,
  value: unknown,
): value is T[keyof T] {
  return Object.values(enumType).some(v => v === value);
}

export function enumMember<T extends TSEnum>(
  enumType: T,
  value: unknown,
): asserts value is T[keyof T] {
  if (!isEnumMember(enumType, value)) {
    throw new Error(`"${value}" is not an enum member}`);
  }
}
