import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageIntro } from '~/components/layout/PageIntro';
import { PageLoader } from '~/components/page/PageLoader';

export default function StandardRoute() {
  useBreadcrumb('routes/standard', 'Standard');

  return (
    <div className="space-y-4">
      <PageIntro
        pageName="intro_standard"
        iconPath="/assets/images/page/icon-standard.png"
      />

      <a
        href="/voyager.html"
        className="btn btn-primary btn-sm"
        target="_blank"
        rel="noopener noreferrer"
      >
        Interactive Tool
      </a>

      <hr />

      <PageLoader pageName="standard" showTitle={false} />
    </div>
  );
}
