import { useState } from 'react';
import { Button } from '~/components/ui/button';
import type { UserPartsFragment } from '~/apollo/generated/v3/graphql';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { AdminUserListItem } from './AdminUserListItem';

type Props = {
  userList: UserPartsFragment[];
  colSpan: number;
};

export function AdminUserListDeletedUsers({ userList, colSpan }: Props) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(exp => !exp);

  return (
    <tbody>
      <tr>
        <td colSpan={colSpan}>
          <Button
            type="button"
            color="ghost"
            size="sm"
            onClick={toggleExpanded}
          >
            {userList.length} Deleted Users
            <ExpandedIcon expanded={expanded} className="ml-2" />
          </Button>
        </td>
      </tr>
      {expanded &&
        userList.map(user => (
          <AdminUserListItem key={user.id} user={user} deleted />
        ))}
    </tbody>
  );
}
