import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';

import type { ClimateSelectOptionsQuery } from '~/apollo/generated/v3/graphql';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

export const CLIMATE_SELECT_OPTIONS = gql`
  query ClimateSelectOptions {
    wikiEnum(enumType: OTHER_CLIMATE) {
      values
    }
  }
`;

type Props = FieldProps & {
  disabled?: boolean;
};

export function ClimateSelect({
  form,
  field,
  disabled = false,
  ...props
}: Props) {
  const { data, loading } = useQuery<ClimateSelectOptionsQuery>(
    CLIMATE_SELECT_OPTIONS,
    {},
  );

  const values = data?.wikiEnum.values ?? [];
  const options = values.map(opt => ({ value: opt, label: opt }));

  return (
    <FormikSelectField
      {...props}
      form={form}
      field={field}
      options={options}
      disabled={disabled || loading}
    />
  );
}
