import { z } from 'zod';
import { CesiumAssetState } from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export const placementFormSchema = yup.object({
  latitude: yup.number().label('latitude').min(-90).max(90).required(),
  longitude: yup.number().label('longitude').min(-180).max(180).required(),
  height: yup.number().label('height').required(),
  heading: yup.number().label('heading').min(-180).max(180).required(),
  pitch: yup.number().label('pitch').min(-180).max(180).required(),
  roll: yup.number().label('roll').min(-90).max(90).required(),
});

export const utmFormSchema = yup.object({
  northing: yup.number().label('northing').min(0).required(),
  easting: yup.number().label('easting').min(0).required(),
  zone: yup.number().label('zone').min(1).max(60).required(),
  hemisphere: yup
    .string()
    .label('hemisphere')
    .test('n/s', 'Must be either north or south', val => {
      return val === 'NORTH' || val === 'SOUTH';
    })
    .required(),
});

export const locationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
  height: z.number(),
  heading: z.number(),
  pitch: z.number(),
  roll: z.number(),
});

export const utmSchema = z.object({
  utm_northings: z.number(),
  utm_eastings: z.number(),
  utm_zone: z.number(),
  utm_hemisphere: z.string(),
});

const updatedCesiumAssetState: Record<string, string> = {};
Object.entries(CesiumAssetState).forEach(
  ([k, v]) => (updatedCesiumAssetState[v] = k.toLowerCase()),
);
const CesiumAssetStateEnum = z.nativeEnum(updatedCesiumAssetState);

export const attributesSchema = z.object({
  // location: locationSchema.nullable(),
  // default_camera: locationSchema.nullable(),
  utm_data: utmSchema.nullable(),
  location_id: z.number(),
  default_camera_id: z.number(),
  is_clipping: z.boolean(),
  state: CesiumAssetStateEnum,
});
