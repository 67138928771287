import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeletePaleoMapOutcropLinkMutation,
  DeletePaleoMapOutcropLinkMutationVariables,
} from '~/apollo/generated/v3/graphql';

export const DELETE_PALEO_MAP_OUTCROP_LINK = gql`
  mutation DeletePaleoMapOutcropLink($outcropId: Int!, $paleoMapId: Int!) {
    deletePaleoMapOutcropLink(outcropId: $outcropId, paleoMapId: $paleoMapId)
  }
`;

type Props = {
  children: (
    handleDelete: () => Promise<void>,
    loading: boolean,
  ) => JSX.Element;
  paleoMapId: number;
  outcropId: number;
  refetchQueries: PureQueryOptions[];
};

export function DeletePaleoMapOutcropLink({
  children,
  paleoMapId,
  outcropId,
  refetchQueries,
}: Props) {
  const [deleteLink, { loading }] = useMutation<
    DeletePaleoMapOutcropLinkMutation,
    DeletePaleoMapOutcropLinkMutationVariables
  >(DELETE_PALEO_MAP_OUTCROP_LINK, {
    variables: { paleoMapId, outcropId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteLink();
      toast.success('Outcrop unlinked successfully.');
    } catch (err) {
      console.log('Error unlinking outcrop', err);
      toast.error(
        'There was a problem unlinking the outcrop, please refresh the page and try again.',
      );
    }
  }

  return children(handleDelete, loading);
}
