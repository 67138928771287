import * as R from 'ramda';
import type { QueryOptionsBrowse } from '~/apollo/operations/analogueSearch';
import type { SearchOutcropsOptionsQuery } from '~/apollo/generated/v3/graphql';

export interface AnalogueSearchFormValues {
  grossDepositionalEnvironment: string[];
  depositionalEnvironment: string[];
  depositionalSubEnvironment: string[];
  architecturalElement: string[];
  climate: string[];
  basinType: string[];
  outcropCategory: string[];
  supportingObjectTypes: string[];
  geologyType: string[];
  geologyAge: string[];
  netToGross: string[];
  distanceToSourceArea: string[];
  country: string[];
  textSearch: string;
}
/** All of the filters that can be picked from lists */
export type FilterableAnalogueSearchFormValues = Omit<
  AnalogueSearchFormValues,
  'textSearch'
>;

export type AnalogueSearchDefaults = Partial<AnalogueSearchFormValues>;

export function defaultAnalogueSearch(
  defaults?: AnalogueSearchDefaults,
): AnalogueSearchFormValues {
  const params: AnalogueSearchFormValues = {
    grossDepositionalEnvironment:
      defaults?.grossDepositionalEnvironment ??
      defaults?.grossDepositionalEnvironment ??
      [],
    depositionalEnvironment: defaults?.depositionalEnvironment ?? [],
    depositionalSubEnvironment: defaults?.depositionalSubEnvironment ?? [],
    architecturalElement: defaults?.architecturalElement ?? [],
    climate: defaults?.climate ?? [],
    basinType: defaults?.basinType ?? [],
    outcropCategory: defaults?.outcropCategory ?? [
      'outcrop',
      'production',
      'seismic',
      'modern',
    ],
    supportingObjectTypes: defaults?.supportingObjectTypes ?? [],
    geologyType: defaults?.geologyType ?? [],
    geologyAge: defaults?.geologyAge ?? [],
    netToGross: defaults?.netToGross ?? [],
    distanceToSourceArea: defaults?.distanceToSourceArea ?? [],
    country: defaults?.country ?? [],
    textSearch: defaults?.textSearch ?? '', // The API accepts '' as being empty, no need to convert to null
  };

  return params;
}

export type AnalogueSearchOptionSynthetic = {
  name: string;
};

export type AnalogueSearchOption = {
  name?: string | null | undefined;
  count: number;
};

export type AnalogueSearchOptionItem =
  | AnalogueSearchOptionSynthetic
  | AnalogueSearchOption;

export interface AnalogueSearchOptions {
  grossDepositionalEnvironment: AnalogueSearchOption[];
  depositionalEnvironment: AnalogueSearchOption[];
  depositionalSubEnvironment: AnalogueSearchOption[];
  architecturalElement: AnalogueSearchOption[];
  climate: AnalogueSearchOption[];
  basinType: AnalogueSearchOption[];
  geologyType: AnalogueSearchOption[];
  geologyAge: AnalogueSearchOption[];
  netToGross: AnalogueSearchOption[];
  distanceToSourceArea: AnalogueSearchOption[];
  country: AnalogueSearchOption[];

  // Synthetic options -- These optiosn are static and not generated or updated by the search results
  outcropCategory: AnalogueSearchOptionSynthetic[];
  supportingObjectTypes: AnalogueSearchOptionSynthetic[];
}

export function defaultAnalogueSearchOptions(): AnalogueSearchOptions {
  return {
    geologyType: [],
    grossDepositionalEnvironment: [],
    depositionalEnvironment: [],
    depositionalSubEnvironment: [],
    architecturalElement: [],
    climate: [],
    basinType: [],
    geologyAge: [],
    netToGross: [],
    distanceToSourceArea: [],
    country: [],

    outcropCategory: [
      { name: 'outcrop' },
      { name: 'production' },
      { name: 'seismic' },
    ],

    supportingObjectTypes: [
      { name: 'virtual_outcrop_models_with_interpretations' },
      { name: 'virtual_outcrop_models_with_cesium' },
      { name: 'virtual_outcrop_models' },
      { name: 'virtual_outcrop_mini_models' },
      { name: 'image_files' },
      { name: 'cross_sections' },
      { name: 'sedimentary_logs' },
      { name: 'facies_schemas' },
      { name: 'production_data' },
      { name: 'variograms' },
      // { name: 'mps/ti' }, <- nothing in the database
      { name: 'reservoir_models' },
      { name: 'panoramas' },
    ],
  };
}

export function updateCounts(
  oldOpts: AnalogueSearchOptions,
  newOpts: SearchOutcropsOptionsQuery['searchOutcrops']['queryOptions'],
): AnalogueSearchOptions {
  const queryResultKeys: Array<keyof QueryOptionsBrowse> = [
    'geologyType',
    'grossDepositionalEnvironment',
    'depositionalEnvironment',
    'depositionalSubEnvironment',
    'architecturalElement',
    'climate',
    'basinType',
    'geologyAge',
    'netToGross',
    'distanceToSourceArea',
    'country',
  ];

  const nextOpts: AnalogueSearchOptions = {
    ...newOpts,
    outcropCategory: oldOpts.outcropCategory,
    supportingObjectTypes: oldOpts.supportingObjectTypes,
  };

  queryResultKeys.forEach(fieldName => {
    oldOpts[fieldName].forEach((option: any) => {
      let key = 'id' in option ? 'id' : 'name';

      const isPresentInNewOpts = R.pipe(
        R.find(R.propEq(key, option[key])),
        R.complement(R.isNil),
      )(newOpts[fieldName]);

      if (!isPresentInNewOpts) {
        nextOpts[fieldName] = [
          ...nextOpts[fieldName],
          {
            ...option,
            count: 0,
          },
        ];
      }
    });
  });

  return nextOpts;
}

export const availableDataTopOptions = [
  'virtual_outcrop_models_with_cesium',
  'virtual_outcrop_models',
  'sedimentary_logs',
  'image_files',
];
