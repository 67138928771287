import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { LinkProps } from 'react-router';
import { Link } from 'react-router';
import * as R from 'ramda';

import type {
  WikiOverviewPageQuery,
  WikiOverviewPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { Heading } from '~/components/common/Heading';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { ListGroup } from '~/components/common/ListGroup';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { PageIntro } from '~/components/layout/PageIntro';
import { useSortFilter } from '~/hooks/data';
import {
  geologyWikiOverviewRoute,
  geologyWikiPageRoute,
  lithostratFormationRoute,
  lithostratGroupRoute,
  lithostratMemberRoute,
  lithostratTypeRoute,
  otherWikiDetailRoute,
} from '~/paths';

const WIKI_OVERVIEW_PAGE = gql`
  query WikiOverviewPage {
    # Geology articles for list
    depositionalList {
      id
      title
      geologyType
    }
    # Other articles for list
    otherList {
      id
      title
    }

    # Lithostrat pages for list
    lithostratFormationList(hasWiki: true) {
      id
      name
    }
    lithostratGroupList(hasWiki: true) {
      id
      name
    }
    lithostratMemberList(hasWiki: true) {
      id
      name
    }

    # Depositional thumbnail(s)
    gdeThumbnail: depositionalList(
      type: GROSS_DEPOSITIONAL_ENVIRONMENT
      value: "Continental"
    ) {
      id
      diagram {
        id
        file {
          id
          signedUrl
        }
      }
    }
    # Lithostrat thumbnails
    lithostratTypeList {
      id
      diagram {
        id
        file {
          id
          signedUrl
        }
      }
    }
  }
`;

type WikiPageLink = {
  type: 'geology' | 'other' | 'formation' | 'group' | 'member';
  title: string;
  href: string;
  geologyType?: string | null;
};

type SidebarLinkProps = {
  title: string;
  to: LinkProps['to'];
  imgSrc?: string;
};
function SidebarLink({
  title,
  to,
  imgSrc = '/assets/images/common/safari-logo-black.png',
}: SidebarLinkProps) {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>{title}</Panel.Title>
      </Panel.Heading>
      <Panel.Body className="space-y-2">
        <Link to={to}>
          <div
            className="w-full h-36 bg-center bg-no-repeat bg-contain"
            style={{ backgroundImage: `url(${imgSrc})` }}
          />
        </Link>

        <div className="text-center">
          <Link to={to} className="link">
            More {title} Articles
          </Link>
        </div>
      </Panel.Body>
    </Panel>
  );
}

export default function WikiIndexRoute() {
  const { data, loading } = useQuery<
    WikiOverviewPageQuery,
    WikiOverviewPageQueryVariables
  >(WIKI_OVERVIEW_PAGE, {});

  const depositionalList = data?.depositionalList ?? [];
  const depositionalWikiLinks: WikiPageLink[] = depositionalList.map(
    wikiPage => ({
      type: 'geology',
      title: wikiPage.title,
      href: geologyWikiPageRoute(wikiPage.id),
      geologyType: wikiPage.geologyType,
    }),
  );

  const otherWikiList = data?.otherList ?? [];
  const otherWikiLinks: WikiPageLink[] = otherWikiList.map(wikiPage => ({
    type: 'other',
    title: wikiPage.title,
    href: otherWikiDetailRoute(wikiPage.id),
  }));

  const formationList = data?.lithostratFormationList ?? [];
  const formationWikiLinks: WikiPageLink[] = formationList.map(fm => ({
    type: 'formation',
    title: fm.name,
    href: lithostratFormationRoute(fm.id),
  }));

  const groupList = data?.lithostratGroupList ?? [];
  const groupWikiLinks: WikiPageLink[] = groupList.map(g => ({
    type: 'group',
    title: g.name,
    href: lithostratGroupRoute(g.id),
  }));

  const memberList = data?.lithostratMemberList ?? [];
  const memberWikiLinks: WikiPageLink[] = memberList.map(mb => ({
    type: 'member',
    title: mb.name,
    href: lithostratMemberRoute(mb.id),
  }));

  const wikiPages = [
    depositionalWikiLinks,
    otherWikiLinks,
    formationWikiLinks,
    groupWikiLinks,
    memberWikiLinks,
  ].reduce(R.concat);

  const { items, filterSearchProps } = useSortFilter(
    wikiPages,
    'title',
    'title',
    'wikiOverviewList',
  );

  const gdeThumbnailUrl = data?.gdeThumbnail?.[0].diagram?.file?.signedUrl;

  const lithostratThumbnailUrl = (data?.lithostratTypeList ?? []).find(
    lt => lt.id === 3,
  )?.diagram?.file?.signedUrl;

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      <PageIntro
        pageName="intro_wiki"
        iconPath="/assets/images/page/icon-standard.png"
      />

      <p className="text-base ">
        What do you want to know? Type your search or choose a category from the
        sidebar:
      </p>

      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-7 space-y-4">
          <FilterSearch
            {...filterSearchProps}
            renderSearch={cmp => cmp} // Makes it full-width
            stick={false}
            showAlphabet={false}
          />

          <Heading level={3}>
            {filterSearchProps.includesValue ? 'Search Results' : ''}
          </Heading>

          <ListGroup className="max-h-screen overflow-y-auto">
            {items.map(link => (
              <ListGroup.Item
                key={link.href}
                as={Link}
                to={link.href}
                className="flex justify-between items-center w-full h-full p-2"
              >
                <div className="flex justify-start gap-2 items-center">
                  <div className="font-semibold">{link.title}</div>
                  {link.type === 'geology' && link.geologyType && (
                    <GeologyTypeIcon
                      geologyType={link.geologyType}
                      className="h-5"
                    />
                  )}
                </div>
                <small className="text-muted">
                  {link.type === 'geology' && 'Geology'}
                  {link.type === 'other' && 'Other Articles'}
                  {link.type === 'formation' && 'Lithostrat Formation'}
                  {link.type === 'group' && 'Lithostrat Group'}
                  {link.type === 'member' && 'Lithostrat Member'}
                </small>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        <div className="lg:col-span-5 space-y-6">
          <SidebarLink
            title="Geology"
            to={geologyWikiOverviewRoute()}
            imgSrc={gdeThumbnailUrl}
          />
          <SidebarLink
            title="Lithostrat"
            to={lithostratTypeRoute(3)}
            imgSrc={lithostratThumbnailUrl}
          />
        </div>
      </div>
    </div>
  );
}
