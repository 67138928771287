import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeleteSavedDataSearchMutation,
  DeleteSavedDataSearchMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';

const DELETE_SAVED_DATA_SEARCH = gql`
  mutation DeleteSavedDataSearch($id: Int!) {
    deleteSavedDataSearch(id: $id)
  }
`;

type Props = {
  children: (confirmDelete: () => void, loading: boolean) => JSX.Element;
  searchId: number;
  onDeleteSuccess: () => Promise<void> | void;
};

export function DeleteSds({ searchId, children, onDeleteSuccess }: Props) {
  const [deleteSds, { loading }] = useMutation<
    DeleteSavedDataSearchMutation,
    DeleteSavedDataSearchMutationVariables
  >(DELETE_SAVED_DATA_SEARCH, {
    variables: { id: searchId },
  });

  const handleDelete = async () => {
    try {
      await deleteSds();
      toast.success('Saved search successfully deleted.');
      onDeleteSuccess();
    } catch (err) {
      console.log('Error deleting sds', err);
      toast.error(
        'There was a problem deleting the saved search. Please refresh the page and try again.',
      );
    }
  };

  return (
    <Confirm
      onConfirm={handleDelete}
      text="This bookmarked data, along with all of its revisions, will be permanently deleted."
      submitButtonColor="error"
      submitText="Permanently delete search"
    >
      {confirmDelete => children(confirmDelete, loading)}
    </Confirm>
  );
}
