import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { graphql } from '~/apollo/generated/v3';
import { BookmarkParentType } from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportingObjectList } from '~/components/supportingObject/SupportingObjectList';
import { useStudyOutletContext } from '~/routes/study/$studyId';

const STUDY_WELL_LOGS_ROUTE = graphql(`
  query StudyWellLogsRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      wellLogs {
        ...wellLogParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        georeference {
          ...georeferenceParts
        }
      }
    }
  }
`);

export default function StudyWellLogsRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery(STUDY_WELL_LOGS_ROUTE, {
    variables: { studyId },
  });

  const refetchQueries: PureQueryOptions[] = [
    { query: STUDY_WELL_LOGS_ROUTE, variables: { studyId } },
  ];

  const wellLogs = data?.studyList.find(s => s.id === studyId)?.wellLogs ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <SupportingObjectList
      items={wellLogs}
      soParentType="study"
      soParentId={studyId}
      bookmarkParentType={BookmarkParentType.WellLog}
      refetchQueries={refetchQueries}
    />
  );
}
