import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import { fileParts } from '~/apollo/fragments';
import type {
  FileManagerPageQuery,
  FileManagerPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import CmsFileList from '~/components/upload/cms/CmsFileList';
import CmsFileUploader from '~/components/upload/cms/CmsFileUploader';

const FILE_MANAGER_PAGE = gql`
  query FileManagerPage {
    cmsFileList {
      ...fileParts
    }
  }

  ${fileParts}
`;

export default function FileManagerPage() {
  useBreadcrumb('routes/upload/file-manager', 'File Manager');

  const { data, loading, refetch } = useQuery<
    FileManagerPageQuery,
    FileManagerPageQueryVariables
  >(FILE_MANAGER_PAGE, {});

  const refetchQueries: [PureQueryOptions<FileManagerPageQueryVariables>] = [
    { query: FILE_MANAGER_PAGE },
  ];

  return (
    <>
      <SpinnerPlaceholder show={loading} />

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-9">
          <CmsFileList
            files={data?.cmsFileList ?? []}
            refetchQueries={refetchQueries}
          />
        </div>

        <div className="col-span-12 lg:col-span-3">
          <CmsFileUploader onAllSettled={refetch} />
        </div>
      </div>
    </>
  );
}
