import type {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  ReactNode,
} from 'react';
import { cn } from '~/utils/common';

// Can either be a controlled checkbox or only an onToggle cb is provided
type CheckEvent = ChangeEventHandler<HTMLInputElement>;
type CheckboxControls =
  | { open: boolean; onToggle: CheckEvent }
  | { open?: never; onToggle?: CheckEvent };

type ComponentProps = {
  icon?: 'arrow' | 'plus';
  title: ReactNode;
  titleClassName?: string;
  contentClassName?: string;
};

export type CollapseProps = ComponentProps &
  CheckboxControls &
  ComponentPropsWithoutRef<'div'>;

export function Collapse({
  icon,
  title,
  className,
  titleClassName,
  contentClassName,
  open,
  onToggle,
  children,
}: CollapseProps) {
  return (
    <div
      className={cn(
        'collapse',
        {
          'collapse-arrow': icon === 'arrow',
          'collapse-plus': icon === 'plus',
        },
        className,
      )}
    >
      <input type="checkbox" checked={open} onChange={onToggle} />
      <div className={cn('collapse-title', titleClassName)}>{title}</div>
      <div className={cn('collapse-content', contentClassName)}>{children}</div>
    </div>
  );
}
