import type {
  IssueInput,
  IssuePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { IssueType, IssueWorkStatus } from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type FormValues = {
  title: string;
  issueType: string;
  content: string;
  workStatus: string;
};

export const defaultIssue = (issue?: IssuePartsFragment): FormValues => ({
  title: issue?.title ?? '',
  issueType: issue?.issueType ?? '',
  content: issue?.content ?? '',
  workStatus: issue?.workStatus ?? IssueWorkStatus.Open,
});

export const validationSchema = yup.object({
  title: yup.string().required().label('title'),
  content: yup.string().required().label('issue description'),
  issueType: yup
    .string()
    .oneOf(Object.values(IssueType))
    .required()
    .label('issue type'),
  workStatus: yup
    .string()
    .oneOf(Object.values(IssueWorkStatus))
    .required()
    .label('issue work status'),
});

export function castIssueType(input: string): IssueType {
  switch (input) {
    case IssueType.Bug:
      return IssueType.Bug;
    case IssueType.FeatureRequest:
      return IssueType.FeatureRequest;
    case IssueType.Support:
      return IssueType.Support;
    default:
      throw new Error(`Invalid issue type ${input}`);
  }
}

export function castWorkStatus(input: string): IssueWorkStatus {
  if (Object.values(IssueWorkStatus).some(val => val === input)) {
    return input as IssueWorkStatus;
  }
  throw new Error(`Invalid work status ${input}`);
}

export function issueFormValuesToInputType(fv: FormValues): IssueInput {
  return {
    ...fv,
    issueType: castIssueType(fv.issueType),
    workStatus: castWorkStatus(fv.workStatus),
  };
}
