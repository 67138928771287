import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import type { SearchOutcropsOutcropsQuery } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { outcropRoute, regionRoute } from '~/paths';

type Outcrop =
  SearchOutcropsOutcropsQuery['searchOutcrops']['outcrops'][number];

function thumbnailUrl(outcrop: Outcrop) {
  if (outcrop.thumbnail?.signedUrl) {
    return outcrop.thumbnail.signedUrl;
  }

  switch (outcrop.outcropCategory) {
    case 'modern':
      return '/assets/images/analogue-type/modern.png';
    case 'production':
      return '/assets/images/analogue-type/production.png';
    case 'seismic':
      return '/assets/images/analogue-type/seismic.png';
    default:
      return '/assets/images/page/icon-outcrop.jpg';
  }
}

export function SearchResultItem({ outcrop }: { outcrop: Outcrop }) {
  const thumbnail = thumbnailUrl(outcrop);

  return (
    <div className="py-4 border-b border-b-slate-200">
      <div className="grid grid-cols-4 gap-6">
        <div>
          <Link to={outcropRoute(outcrop.id)} target="_blank">
            <img
              className="outcrop-thumbnail hidden-xs hidden-sm"
              src={thumbnail}
              alt={`${outcrop.name} thumbnail`}
            />
          </Link>
        </div>

        <div className="col-span-3">
          <div className="float-right space-x-1">
            {outcrop.geologyType.map(gt => (
              <GeologyTypeIcon
                key={gt}
                geologyType={gt}
                className="inline-block h-6"
              />
            ))}
          </div>

          <Heading level={4}>
            <Link
              to={outcropRoute(outcrop.id)}
              target="_blank"
              className="link"
            >
              {outcrop.name}
            </Link>

            {outcrop.region && (
              <Link
                to={regionRoute(outcrop.region.id)}
                target="_blank"
                className="text-muted ml-2"
              >
                <small>
                  <em>{outcrop.region.name}</em>
                </small>
              </Link>
            )}
          </Heading>

          {/* 
          The shortDescription field has no HTML tags, but there are still
          some HTML entities, e.g. &deg; that are present in the text.
          Just use dangerouslySetInnerHTML since this is an admin field anyway.
          See issue 1678
          */}
          <div
            dangerouslySetInnerHTML={{
              __html: `${outcrop.shortDescription}...`,
            }}
            className="text-base"
          />

          <div className="mt-2">
            <Link
              to={outcropRoute(outcrop.id)}
              className="btn btn-sm btn-primary gap-1"
              target="_blank"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> View Outcrop
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
