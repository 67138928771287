import { Outlet } from 'react-router';
import { NavLink } from '~/components/common/NavLink';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Menu } from '~/components/ui/menu';
import { myProfileAdminsRoute, myProfileRoute } from '~/paths';

// import UpdateProfile from 'components/userProfile/UpdateProfile';
// import UserCompanyAdmins from 'components/userProfile/UserCompanyAdmins';

export default function ProfileRoute() {
  useBreadcrumb('routes/profile', 'My Profile');

  return (
    <>
      <PageHeading>My Profile</PageHeading>

      <div className="grid lg:grid-cols-4 gap-6">
        <div>
          <Menu>
            <Menu.Item>
              <NavLink to={myProfileRoute()} end>
                Update Details
              </NavLink>
            </Menu.Item>

            <Menu.Item>
              <NavLink to={myProfileAdminsRoute()}>Admins</NavLink>
            </Menu.Item>
          </Menu>
        </div>

        <div className="lg:col-span-3">
          <Outlet />
        </div>
      </div>

      {/* <Row>
        <Col md={3}>
          <ul className="nav nav-pills nav-stacked"></ul>
        </Col>

        <Col md={9}>
          <Routes>
            <Route index element={<UpdateProfile />} />
            <Route path="admins" element={<UserCompanyAdmins />} />
          </Routes>
        </Col>
      </Row> */}
    </>
  );
}
