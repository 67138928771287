import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { itemStatus } from './FileUploadQueueItem';
import type {
  PictureUploadField,
  QueueItem,
  QueueItemEditableField,
} from './PictureUpload';

type Props = {
  item: QueueItem;
  fields: PictureUploadField[];
  onFieldChange: <T extends QueueItemEditableField>(
    file: File,
    field: T,
    value: QueueItem[T],
  ) => void;
  onRemove: (file: File) => void;
};

export function PictureUploadQueueItem({
  item,
  fields,
  onFieldChange,
  onRemove,
}: Props) {
  const isRemoveDisabled = ['success', 'failed'].includes(item.status);

  return (
    <tr>
      <td className="w-1/12">
        <div className="thumbnail">
          <img src={URL.createObjectURL(item.file)} alt="Pending thumbnail" />
        </div>
      </td>
      <td className="w-6/12">
        <div className="space-y-1">
          {fields.map(field => (
            <div key={field.field} className="form-control">
              <label className="label" htmlFor={field.field}>
                <span className="label-text">{field.label}</span>
              </label>
              <input
                id={field.field}
                type="text"
                value={item[field.field] ?? ''}
                onChange={e =>
                  onFieldChange(item.file, field.field, e.target.value)
                }
                className="input input-bordered input-sm"
                required={field.required}
              />
            </div>
          ))}
        </div>
      </td>

      <td className="col-xs-2">
        <div className="text-center">{itemStatus(item.status)}</div>
      </td>

      <td className="col-xs-1">
        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={() => onRemove(item.file)}
          disabled={isRemoveDisabled}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
}
