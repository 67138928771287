import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { Outlet, useOutletContext, useParams } from 'react-router';
import invariant from 'tiny-invariant';

import * as fragments from '~/apollo/fragments';
import type {
  UpdatePaleomapPageQuery,
  UpdatePaleomapPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { DeletePaleoMap } from '~/components/upload/paleomap/PaleoMapOutcropManager/DeletePaleomap';
import {
  uploadPaleoMapUpdatePicturesRoute,
  uploadPaleoMapUpdateRoute,
} from '~/paths';

const UPDATE_PALEOMAP_PAGE = gql`
  query UpdatePaleomapPage($id: Int!) {
    paleoMapList(id: $id) {
      ...paleoMapParts
      outcrops {
        ...outcropParts
        region {
          ...regionParts
        }
      }
      age {
        ...lithostratAgeParts
      }
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
    }
  }

  ${fragments.paleoMapParts}
  ${fragments.outcropParts}
  ${fragments.regionParts}
  ${fragments.lithostratAgeParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

type OutletContext = {
  paleoMap: UpdatePaleomapPageQuery['paleoMapList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function UpdatePaleomapRoute() {
  const params = useParams();
  invariant(params.paleoMapId, 'paleoMapId param required');
  const paleoMapId = parseInt(params.paleoMapId);

  const { loading, data } = useQuery<
    UpdatePaleomapPageQuery,
    UpdatePaleomapPageQueryVariables
  >(UPDATE_PALEOMAP_PAGE, {
    variables: { id: paleoMapId },
  });

  const refetchQueries: [PureQueryOptions<UpdatePaleomapPageQueryVariables>] = [
    {
      query: UPDATE_PALEOMAP_PAGE,
      variables: { id: paleoMapId },
    },
  ];

  const paleoMapList = data?.paleoMapList ?? [];
  const paleoMap = paleoMapList.find(pm => pm.id === paleoMapId);

  useBreadcrumb('routes/upload/paleomap/$paleoMapId', 'Update Palaeomap');

  if (loading) return <SpinnerPlaceholder />;
  if (!paleoMap) return <NotFound />;

  const outletContext: OutletContext = {
    paleoMap: paleoMap,
    refetchQueries,
  };

  return (
    <>
      <PageHeading>Update Palaeomap</PageHeading>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={uploadPaleoMapUpdateRoute(paleoMapId)} end>
          Details
        </NavTabs.Tab>
        <NavTabs.Tab to={uploadPaleoMapUpdatePicturesRoute(paleoMapId)}>
          Pictures
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />

      <div className="mt-6 text-center">
        <DeletePaleoMap paleoMapId={paleoMapId} refetchQueries={refetchQueries}>
          {showDeleteModal => (
            <Button
              type="button"
              variant="link"
              onClick={showDeleteModal}
              className="text-error hover:text-error"
              startIcon={<FontAwesomeIcon icon={faTrash} />}
            >
              Delete Paleomap
            </Button>
          )}
        </DeletePaleoMap>
      </div>
    </>
  );
}

export function useUpdatePaleoMapOutletContext() {
  return useOutletContext<OutletContext>();
}
