import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import type {
  StudyAuditLogsQuery,
  StudyAuditLogsQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  AuditLogTable,
  auditLogTableFragment,
} from '~/components/auditLogs/AuditLogTable';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

const STUDY_AUDIT_LOGS = gql`
  query StudyAuditLogs($id: Int!) {
    studyList(id: $id) {
      id
      auditLogs {
        ...auditLogTableFragment
      }
    }
  }

  ${auditLogTableFragment}
`;

export default function StudyAuditLogsRoute() {
  const { study } = useUpdateStudyOutletContext();

  const { data, loading } = useQuery<
    StudyAuditLogsQuery,
    StudyAuditLogsQueryVariables
  >(STUDY_AUDIT_LOGS, { variables: { id: study.id } });

  const queryStudy = data?.studyList.find(s => s.id === study.id);
  const auditLogs = queryStudy?.auditLogs ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Audit Logs</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <AuditLogTable auditLogs={auditLogs} sortStorageName="studyAuditLogs" />
      </Panel.Body>
    </Panel>
  );
}
