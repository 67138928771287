import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import type { MafSearchQuery } from '~/apollo/generated/v3/graphql';
import type { MAFToolFormValues } from '~/routes/modern/index';
import { modernPointIcon } from '~/utils/modules/modern';

type Props = {
  coordinates: MafSearchQuery['modernAnalogueFinderSearch']['coordinates'];
};

export function ModernMap({ coordinates }: Props) {
  const { values, setValues } = useFormikContext<MAFToolFormValues>();
  const [map, setMap] = useState<google.maps.Map>();

  function handleMapLoad(googleMap: google.maps.Map) {
    googleMap.setCenter({ lat: 0, lng: 0 });
    googleMap.setZoom(2);
    setMap(googleMap);
  }

  function handleMapIdle() {
    const bounds = map?.getBounds();
    if (!bounds) return;

    setValues(prevValues => ({
      ...prevValues,
      bounds: {
        northeast: {
          latitude: bounds.getNorthEast().lat(),
          longitude: bounds.getNorthEast().lng(),
        },
        southwest: {
          latitude: bounds.getSouthWest().lat(),
          longitude: bounds.getSouthWest().lng(),
        },
      },
    }));
  }

  return (
    <GoogleMap
      mapTypeId={window.google.maps.MapTypeId.TERRAIN}
      onLoad={handleMapLoad}
      onIdle={handleMapIdle}
      options={{ scaleControl: true }}
      mapContainerStyle={{
        height: '600px',
        width: '100%',
      }}
    >
      {coordinates.map(c => (
        <MarkerF
          key={`${c.latitude},${c.longitude}`}
          position={{ lat: c.latitude, lng: c.longitude }}
          icon={modernPointIcon(values.gde, c.classification)}
          clickable={false}
        />
      ))}
    </GoogleMap>
  );
}
