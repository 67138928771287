import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';

import type { PictureTypesQuery } from '~/apollo/generated/v3/graphql';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

export const PICTURE_TYPES = gql`
  query PictureTypes {
    pictureEnum {
      values
    }
  }
`;

export function PictureTypeSelectFormik(props: FieldProps) {
  const { data, loading } = useQuery<PictureTypesQuery>(PICTURE_TYPES, {});

  const values = data?.pictureEnum.values ?? [];
  const options = values.map(opt => ({ value: opt, label: opt }));

  return <FormikSelectField {...props} options={options} disabled={loading} />;
}
