import { faCopy, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import type { HTMLProps } from 'react';
import React from 'react';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type { FileManagerPageQuery } from '~/apollo/generated/v3/graphql';
import { Tooltip } from '~/components/common/Tooltip';
import { envVars } from '~/environment';
import { copyToClipboard } from '~/utils/clipboard';

type FileType = FileManagerPageQuery['cmsFileList'][number];

const isImage = (filename: string): boolean =>
  R.pipe(
    R.split('.'),
    R.last,
    R.toLower,
    R.contains(R.__, ['jpg', 'jpeg', 'png', 'gif', 'svg']),
  )(filename);

function downloadLink(file: FileType) {
  const baseUrl = `${envVars.VITE_CLIENT_URL}/api/v3/cms/file`;
  const privacy = file.public ? 'public' : 'private';

  return `${baseUrl}/${privacy}/${file.id}/${file.name}`;
}

type DownloadLinkProps = HTMLProps<HTMLAnchorElement> & {
  file: FileType;
  children: React.ReactNode;
};

const DownloadLink: React.FC<DownloadLinkProps> = ({
  file,
  children,
  ...props
}: DownloadLinkProps) => {
  const url = downloadLink(file);

  return (
    <a {...props} href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

type Props = {
  file: FileType;
};

const FilePreview: React.FC<Props> = ({ file }: Props) => {
  function handleCopy(event: React.MouseEvent<HTMLButtonElement>) {
    const target = event.target as HTMLButtonElement;
    copyToClipboard(downloadLink(file));
    target.focus();
    toast.info('URL copied to clipboard.');
  }

  return (
    <>
      <DownloadLink file={file}>
        {isImage(file.name ?? '') ? (
          <div className="avatar">
            <img src={downloadLink(file)} alt="file preview" />
          </div>
        ) : (
          <div className="avatar">
            <FontAwesomeIcon
              icon={faFile}
              className="text-4xl text-slate-500"
            />
          </div>
        )}
      </DownloadLink>

      <div style={{ marginTop: '4px' }}>
        <Tooltip
          message="Click to copy the URL of this file to the clipboard"
          position="right"
        >
          <Button
            type="button"
            color="ghost"
            size="sm"
            onClick={handleCopy}
            startIcon={<FontAwesomeIcon icon={faCopy} />}
          >
            Copy URL
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default FilePreview;
