import { useMutation } from '@apollo/client';
import {
  faBroom,
  faCopy,
  faFileUpload,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { graphql } from '~/apollo/generated/v3';
import type {
  OutcropPictureNamesPageQuery,
  PictureNameMgrPartsFragment,
  StudyPictureNamesPageQuery,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { Panel } from '~/components/common/Panel';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { cn } from '~/utils/common';
import { useHookForm } from '~/utils/forms';
import {
  formValuesToPictureInput,
  initialPicture,
} from '~/utils/modules/supportObject';
import { ucwords } from '~/utils/text';

export const pictureNameMgrParts = graphql(`
  fragment pictureNameMgrParts on Picture {
    ...pictureParts
    reviewed
    file {
      id
      name
      signedUrl
    }
  }
`);

// A picture name is considered "good" if it's different from its filename
function hasGoodName(picture: PictureNameMgrPartsFragment) {
  return picture.name.trim().length && picture.name !== picture.file.name;
}

type SOFields = Omit<Outcrop, '__typename' | 'id' | 'name' | 'pictures'>;
const soFields: Array<keyof SOFields> = [
  'facies',
  'crossSections',
  'sedimentaryLogs',
  'wellLogs',
  'production',
  'reservoirModels',
  'trainingImages',
  'variograms',
  'gigaPans',
];
type SO = SOFields[keyof SOFields][number];

function soHasPictures(so: SO) {
  return so.pictures.length > 0;
}

type EntityCount = { total: number; reviewed: number };
function entityCounts(entities: Array<Outcrop | Study>) {
  return entities
    .flatMap(entity =>
      entity.pictures
        .concat(entity.facies.flatMap(facies => facies.pictures))
        .concat(entity.crossSections.flatMap(cs => cs.pictures))
        .concat(entity.sedimentaryLogs.flatMap(sl => sl.pictures))
        .concat(entity.wellLogs.flatMap(wl => wl.pictures))
        .concat(entity.production.flatMap(prod => prod.pictures))
        .concat(entity.reservoirModels.flatMap(rm => rm.pictures))
        .concat(entity.trainingImages.flatMap(ti => ti.pictures))
        .concat(entity.variograms.flatMap(v => v.pictures))
        .concat(entity.gigaPans.flatMap(gp => gp.pictures)),
    )
    .reduce<EntityCount>(
      (acc, picture) => {
        return {
          total: acc.total + 1,
          reviewed: acc.reviewed + (picture.reviewed ? 1 : 0),
        };
      },
      { total: 0, reviewed: 0 },
    );
}

type Outcrop = OutcropPictureNamesPageQuery['outcropList'][number];
type Study = StudyPictureNamesPageQuery['studyList'][number];

export function PictureNamesManager({
  entities,
  onSectionOpen,
}: {
  entities: Array<Outcrop | Study>;
  onSectionOpen: () => void;
}) {
  const categories = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ'.split('');
  const divided = categories.map(category =>
    entities.filter(entity =>
      entity.name.trimStart().slice(0, 1).toUpperCase().startsWith(category),
    ),
  );
  const other = entities.filter(
    entity =>
      !categories.includes(entity.name.trimStart().slice(0, 1).toUpperCase()),
  );

  return (
    <div className="space-y-4">
      {categories.map((letter, i) => (
        <SubdividedSection
          key={letter}
          title={letter}
          entities={divided.at(i) ?? []}
          onOpen={onSectionOpen}
        />
      ))}
      {other.length > 0 && (
        <SubdividedSection
          title="Other"
          entities={other}
          onOpen={onSectionOpen}
        />
      )}
    </div>
  );
}

function SubdividedSection({
  title,
  entities,
  onOpen,
}: {
  title: string;
  entities: Array<Outcrop | Study>;
  onOpen: () => void;
}) {
  const [open, setOpen] = useState(false);

  const count = entityCounts(entities);

  function handleToggle() {
    if (!open) {
      onOpen();
    }
    setOpen(!open);
  }

  return (
    <div className="flex gap-6 w-full">
      <div className="relative shrink">
        <div className="sticky top-0">
          <Button
            type="button"
            onClick={handleToggle}
            color="ghost"
            size="md"
            className="w-full flex justify-between items-center"
            disabled={!count.total}
          >
            <span>{title}</span>
            <Badge
              color={
                !count.total
                  ? 'ghost'
                  : count.reviewed === count.total
                    ? 'success'
                    : 'error'
              }
            >
              {count.reviewed}/{count.total}
            </Badge>
            <ExpandedIcon expanded={open} />
          </Button>
        </div>
      </div>

      <div className="grow space-y-2">
        {open && (
          <div className="space-y-2">
            {entities.map(entity => (
              <EntityPanel key={entity.id} entity={entity} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function EntityPanel({ entity }: { entity: Outcrop | Study }) {
  const sosWithPictures = soFields.filter(soField =>
    entity[soField].some(soHasPictures),
  );

  const hasPictures = entity.pictures.length > 0;

  if (!hasPictures && !sosWithPictures.length) {
    return null;
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>
          {entity.name} <Badge color="ghost">{entity.id}</Badge>
        </Panel.Title>
      </Panel.Heading>

      <Panel.Body className="space-y-2">
        {entity.pictures.length > 0 && (
          <>
            <Heading level={5}>Pictures</Heading>
            <div className="space-y-1">
              {entity.pictures.map(picture => (
                <PictureEditor key={picture.id} picture={picture} />
              ))}
            </div>
          </>
        )}

        {sosWithPictures.map(soField => (
          <div key={soField}>
            <Heading level={5}>{ucwords(soField)}</Heading>
            <div className="space-y-2">
              {entity[soField].filter(soHasPictures).map(so => (
                <div key={so.id}>
                  <div className="font-bold text-sm">{so.name}</div>
                  <div className="space-y-1">
                    {so.pictures.map(picture => (
                      <PictureEditor
                        key={picture.id}
                        picture={picture}
                        soName={so.name}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Panel.Body>
    </Panel>
  );
}

const pictureSchema = z.object({
  name: z.string().min(1),
  type: z.string(),
});
type FormValues = z.infer<typeof pictureSchema>;

const UPDATE_PICTURE_NAME = graphql(`
  mutation UpdatePictureName($id: Int!, $picture: PictureInput!) {
    updatePicture(id: $id, picture: $picture) {
      ...pictureNameMgrParts
    }
  }
`);

function PictureEditor({
  picture,
  soName,
}: {
  picture: PictureNameMgrPartsFragment;
  soName?: string;
}) {
  const [updatePicture, { loading, error }] = useMutation(UPDATE_PICTURE_NAME);

  const form = useHookForm<FormValues>({
    values: { name: picture.name, type: picture.type },
    schema: pictureSchema,
  });
  const nameValue = form.watch('name');
  const good = hasGoodName({ ...picture, name: nameValue });

  async function handleSubmit(values: FormValues) {
    const input = formValuesToPictureInput(
      initialPicture({ ...picture, ...values }),
    );
    try {
      await updatePicture({ variables: { id: picture.id, picture: input } });
    } catch (err) {
      console.log('Error updating picture', err);
      toast.error('There was a problem updating ' + picture.name);
    }
  }

  function handleCopySOName() {
    if (soName) {
      form.setValue('name', soName);
      form.setFocus('name');
    }
  }

  function handleCopyFilename() {
    if (picture.file.name) {
      form.setValue('name', picture.file.name);
      form.setFocus('name');
    }
  }

  function handleCleanUp() {
    // Remove leading and trailing whitespace, replace underscores with spaces, remove trailing image extension if present
    const cleaned = nameValue
      .trim()
      .replace(/_/g, ' ')
      .replace(/\.(jpg|jpeg|png|gif|svg)$/i, '')
      .replace(/\.$/, '');
    form.setValue('name', cleaned);
    form.setFocus('name');
  }

  return (
    <div
      className={cn('border-l-4 flex gap-2', {
        'border-slate-300 bg-slate-50': good && !picture.reviewed,
        'border-rose-500 bg-rose-50': !good && !picture.reviewed,
        'border-success bg-emerald-50': picture.reviewed,
      })}
    >
      <div
        className="w-20 h-auto min-h-20 bg-cover bg-center shrink-0"
        style={{
          backgroundImage: `url(${picture.file.signedUrl})`,
        }}
      />

      <div className="py-2 pr-2 space-y-1 grow">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="space-y-1">
              <div className="join w-full">
                <input
                  {...form.register('name')}
                  type="text"
                  className="input input-sm w-full join-item"
                />
                <select
                  {...form.register('type')}
                  className="select input-sm w-22 join-item"
                >
                  {[
                    'single overview photo',
                    'panorama overview photo',
                    'detailed photo',
                    'figure',
                    'map',
                    'other',
                    'undefined',
                    'photo montage',
                    'log',
                    'table',
                    'core photo',
                    'thin-section',
                    'data plot',
                    'schematic diagrams',
                  ].map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
                <Button
                  type="submit"
                  color="primary"
                  size="sm"
                  startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
                  className="join-item"
                  loading={loading}
                >
                  Save
                </Button>
              </div>

              <div>
                <div className="text-xs text-slate-700 font-bold">
                  {picture.file.name}
                </div>
                <div className="text-xs text-slate-500">
                  {picture.description}
                </div>
              </div>

              <div className="space-x-2">
                <Button
                  type="button"
                  onClick={handleCleanUp}
                  variant="soft"
                  color="primary"
                  size="sm"
                  startIcon={<FontAwesomeIcon icon={faBroom} />}
                >
                  Clean Name
                </Button>
                <Button
                  type="button"
                  onClick={handleCopyFilename}
                  variant="soft"
                  color="primary"
                  size="sm"
                  startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                >
                  Copy Filename
                </Button>
                {soName && (
                  <Button
                    type="button"
                    onClick={handleCopySOName}
                    variant="soft"
                    color="primary"
                    size="sm"
                    startIcon={<FontAwesomeIcon icon={faCopy} />}
                  >
                    Copy SO Name
                  </Button>
                )}
              </div>

              <HookFormErrors graphQLError={error} />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
