import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_RESERVOIR_MODEL,
  DELETE_RESERVOIR_MODEL,
  UPDATE_RESERVOIR_MODEL,
} from '~/apollo/operations/supportingObject';
import type {
  UploadStudyUpdateReservoirModelsTabQuery,
  UploadStudyUpdateReservoirModelsTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const RESERVOIR_MODELS_TAB = gql`
  query UploadStudyUpdateReservoirModelsTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      reservoirModels {
        ...reservoirModelParts
        outcropTag {
          ...outcropParts
        }
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.reservoirModelParts}
  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateStudyReservoirModelsRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateReservoirModelsTabQuery,
    UploadStudyUpdateReservoirModelsTabQueryVariables
  >(RESERVOIR_MODELS_TAB, {
    variables: { studyId },
  });

  const refetchQueries = [
    {
      query: RESERVOIR_MODELS_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const reservoirModels = study?.reservoirModels ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SupportObjectManager
      studyId={studyId}
      supportObject={reservoirModels}
      supportObjectType="reservoirModel"
      parentType="RESERVOIR_MODEL"
      createMutation={CREATE_RESERVOIR_MODEL}
      updateMutation={UPDATE_RESERVOIR_MODEL}
      deleteMutation={DELETE_RESERVOIR_MODEL}
      refetchQueries={refetchQueries}
    />
  );
}
