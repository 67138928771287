import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { pollTilingMutation } from '~/components/upload/vom/modelData/states/UploadingState';

const INTERVAL_DELAY = 1000 * 30;

export function ProcessingState({
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [pollTiling, { data, loading }] = useMutation(pollTilingMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  useEffect(() => {
    const interval = window.setInterval(pollTiling, INTERVAL_DELAY);
    pollTiling();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [cesiumAssetId, pollTiling]);

  const progress = data?.cesiumAssetPollTiling?.result?.cesiumProgress ?? 0;

  return (
    <div className="text-center">
      <div
        role="progressbar"
        className="radial-progress progress-success"
        // https://github.com/saadeghi/daisyui/discussions/2391
        style={{ '--value': progress } as React.CSSProperties}
      >
        <span className={loading ? 'text-muted' : undefined}>{progress}%</span>
      </div>
    </div>
  );
}
