import {
  faBookOpen,
  faBookmark,
  faChartPie,
  faChartSimple,
  faCircleInfo,
  faCircleQuestion,
  faEarthAmericas,
  faFolderOpen,
  faHeadset,
  faMagnifyingGlass,
  faScroll,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import invariant from 'tiny-invariant';
import { Role } from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { Panel } from '~/components/common/Panel';
import { useAuth } from '~/contexts/auth';
import * as routes from '~/paths';

export function DashboardSidebar() {
  const { authority } = useAuth();
  invariant(authority, 'must be logged in');

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading>
          <Panel.Title>My Safari</Panel.Title>
        </Panel.Heading>
        <Panel.Body className="space-y-1">
          <Link
            to={routes.bookmarksRoute()}
            className="btn btn-primary btn-sm btn-block justify-start gap-1"
          >
            <FontAwesomeIcon icon={faBookmark} /> Bookmarks &amp; Collections
          </Link>

          <Link
            to={routes.reportsRoute()}
            className="btn btn-ghost btn-sm bg-slate-100 btn-block justify-start gap-1"
          >
            <FontAwesomeIcon icon={faScroll} /> Reports
          </Link>
        </Panel.Body>
      </Panel>

      <div className="space-y-1">
        <Link
          to={routes.wikiRoute()}
          className="btn btn-ghost btn-sm bg-wine-500 hover:bg-wine-600 text-white btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faBookOpen} /> Knowledge Base
        </Link>
        <Link
          to={routes.searchRoute()}
          className="btn btn-ghost btn-sm bg-wine-500 hover:bg-wine-600 text-white btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
        </Link>
        <Link
          to={routes.searchRoute()}
          className="btn btn-ghost btn-sm bg-wine-500 hover:bg-wine-600 text-white btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faChartSimple} /> Data
        </Link>
        <Link
          to={routes.vftRoute()}
          className="btn btn-ghost btn-sm bg-wine-500 hover:bg-wine-600 text-white btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faEarthAmericas} /> VFT
        </Link>
      </div>

      <div className="space-y-1">
        <Link
          to={routes.howToRoute()}
          className="btn btn-primary btn-sm btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faCircleQuestion} /> How to use SAFARI
        </Link>

        <Link
          to={routes.statsRoute()}
          className="btn btn-primary btn-sm btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faChartPie} /> Database Statistics
        </Link>
      </div>

      <div className="space-y-1">
        <Link
          to={routes.limeRoute()}
          className="btn btn-ghost btn-sm bg-lime-btn hover:bg-lime-btn-focus text-white btn-block justify-start gap-1"
        >
          <img
            src="/assets/images/lime_dark.png"
            className="h-4 w-auto"
            alt=""
          />
          <span>LIME</span>
        </Link>
      </div>

      <div className="space-y-1">
        <Link
          to={routes.supportRoute()}
          className="btn btn-ghost bg-slate-100 btn-sm btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faHeadset} /> Support
        </Link>
        <Link
          to={routes.aboutRoute('sc-docs')}
          className="btn btn-ghost bg-slate-100 btn-sm btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faUserGroup} /> Steering Committee
        </Link>
        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={routes.documentsRoute()}
            className="btn btn-ghost bg-slate-100 btn-sm btn-block justify-start gap-1"
          >
            <FontAwesomeIcon icon={faFolderOpen} /> Documents
          </Link>
        </RoleSecured>
        <Link
          to={routes.aboutRoute('background')}
          className="btn btn-ghost bg-slate-100 btn-sm btn-block justify-start gap-1"
        >
          <FontAwesomeIcon icon={faCircleInfo} /> About
        </Link>
      </div>

      <div className="text-center">
        <div className="text-sm text-muted">
          Logged in through <b>{authority.user.company.name}</b>
        </div>
        <Link to={routes.myProfileRoute()} className="link text-base">
          My profile
        </Link>
      </div>
    </div>
  );
}
