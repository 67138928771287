import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import {
  faBookmark,
  faChevronRight,
  faScroll,
  faThumbTackSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { Badge } from '~/components/ui/badge';
import { Outlet, useOutletContext } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { MySafariPageNavigationQuery } from '~/apollo/generated/v3/graphql';
import { BookmarkCollectionIcon } from '~/components/bookmark/icons';
import { PinCollectionToggle } from '~/components/bookmark/PinCollectionToggle';
import { Heading } from '~/components/common/Heading';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { NavLink } from '~/components/common/NavLink';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageIntro } from '~/components/layout/PageIntro';
import { Button } from '~/components/ui/button';
import { Menu } from '~/components/ui/menu';
import {
  bookmarkCollectionRoute,
  bookmarksRoute,
  collectionsRoute,
  companyBookmarksRoute,
  companyCollectionsRoute,
  companyReportsRoute,
  reportsRoute,
} from '~/paths';
import { cn } from '~/utils/common';

const MY_SAFARI_PAGE = graphql(`
  query MySafariPageNavigation {
    bookmarkCollectionList(includePersonal: true, includeCompany: true) {
      ...bookmarkCollectionParts
    }
    unsortedBookmarkList(includePersonal: true, includeCompany: true) {
      id
      companyId
    }
  }
`);

type Collection = MySafariPageNavigationQuery['bookmarkCollectionList'][number];

function sortCollections(collections: Collection[]): Collection[] {
  const pinned = collections
    .filter(c => c.isPinned)
    .sort(R.ascend(c => c.name.toLowerCase()));

  const notPinned = collections
    .filter(c => !c.isPinned)
    .sort(R.ascend(c => c.name.toLowerCase()));

  return pinned.concat(notPinned);
}

type OutletContext = {
  personalCollections: Collection[];
  companyCollections: Collection[];
  allCollections: Collection[];
  refetchQueries: PureQueryOptions[];
};

export default function MySafariRoute() {
  useBreadcrumb('routes/my-safari', 'My Safari');

  const [isBookmarksVisible, setIsBookmarksVisible] = useState(true);
  const [isReportsVisible, setIsReportsVisible] = useState(true);

  const { data, loading } = useQuery(MY_SAFARI_PAGE);

  const refetchQueries: PureQueryOptions[] = [{ query: MY_SAFARI_PAGE }];

  const collections = useMemo(() => {
    const allCollections = sortCollections(data?.bookmarkCollectionList ?? []);
    return {
      allCollections,
      personalCollections: allCollections.filter(c => !c.companyId),
      companyCollections: allCollections.filter(c => !!c.companyId),
    };
  }, [data?.bookmarkCollectionList]);

  const unsorted = data?.unsortedBookmarkList ?? [];
  const unsortedPersonalCount = unsorted.filter(b => !b.companyId).length;
  const unsortedCompanyCount = unsorted.filter(b => !!b.companyId).length;

  if (loading) return <SpinnerPlaceholder />;

  const outletContext: OutletContext = {
    ...collections,
    refetchQueries,
  };

  return (
    <div className="space-y-4">
      <PageIntro pageName="my-safari" />

      <div className="grid lg:grid-cols-4 gap-6">
        <div className="space-y-4">
          <div>
            <button
              type="button"
              onClick={() => setIsBookmarksVisible(!isBookmarksVisible)}
              className="cursor-pointer flex justify-between w-full pr-8"
            >
              <Heading level={5}>
                <FontAwesomeIcon icon={faBookmark} className="mr-2" />
                Bookmarks
              </Heading>
              <ExpandedIcon expanded={isBookmarksVisible} />
            </button>

            {isBookmarksVisible && (
              <>
                <BookmarksMenu
                  collections={collections.personalCollections}
                  unsortedCount={unsortedPersonalCount}
                />
                <BookmarksMenu
                  isCompany
                  collections={collections.companyCollections}
                  unsortedCount={unsortedCompanyCount}
                />
              </>
            )}
          </div>

          <div>
            <button
              type="button"
              onClick={() => setIsReportsVisible(!isReportsVisible)}
              className="cursor-pointer flex justify-between w-full pr-8"
            >
              <Heading level={5}>
                <FontAwesomeIcon icon={faScroll} className="mr-2" />
                Reports
              </Heading>
              <ExpandedIcon expanded={isReportsVisible} />
            </button>

            {isReportsVisible && (
              <Menu>
                <Menu.Item>
                  <NavLink to={reportsRoute()} end>
                    My Reports
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={companyReportsRoute()}>Company Reports</NavLink>
                </Menu.Item>
              </Menu>
            )}
          </div>
        </div>

        <div className="lg:col-span-3">
          <Outlet context={outletContext} />
        </div>
      </div>
    </div>
  );
}

export function useMySafariOutletContext() {
  return useOutletContext<OutletContext>();
}

function BookmarksMenu({
  isCompany,
  collections,
  unsortedCount,
}: {
  isCompany?: boolean;
  collections: Collection[];
  unsortedCount: number;
}) {
  // Only show pinned collections in the sidebar
  const pinned = collections.filter(c => c.isPinned);

  return (
    <>
      <Menu className={isCompany ? 'mt-0 pt-0' : ''}>
        {isCompany && (
          <Menu.Title className="pt-0">Shared with company</Menu.Title>
        )}

        {unsortedCount > 0 && (
          <Menu.Item>
            <NavLink
              to={isCompany ? companyBookmarksRoute() : bookmarksRoute()}
              end={!isCompany}
              className="w-full flex justify-between items-center"
            >
              {({ isActive }) => (
                <>
                  <div className="flex gap-2 items-center justify-between">
                    <BookmarkCollectionIcon
                      isCompany={isCompany}
                      className={cn('text-xl', { 'text-white': isActive })}
                    />
                    <span>Unsorted</span>
                  </div>
                  <Badge color="ghost">{unsortedCount}</Badge>
                </>
              )}
            </NavLink>
          </Menu.Item>
        )}

        {pinned.map(collection => (
          <Menu.Item key={collection.id}>
            <NavLink
              to={bookmarkCollectionRoute(collection.id)}
              className="flex w-full justify-between items-center gap-2"
            >
              {({ isActive }) => (
                <>
                  <div className="flex grow gap-2 items-center">
                    <BookmarkCollectionIcon
                      isCompany={!!collection.companyId}
                      className={cn('text-xl', {
                        'text-white': isActive,
                      })}
                    />{' '}
                    {collection.name}
                  </div>

                  <div>
                    <PinCollectionToggle
                      collectionId={collection.id}
                      isPinned={collection.isPinned}
                    >
                      {(pinCollection, loading) => (
                        <Tooltip message="Unpin this collection from the sidebar">
                          <Button
                            type="button"
                            onClick={event => {
                              event.preventDefault();
                              pinCollection();
                            }}
                            disabled={loading}
                            color="ghost"
                            size="xs"
                          >
                            <FontAwesomeIcon icon={faThumbTackSlash} />
                          </Button>
                        </Tooltip>
                      )}
                    </PinCollectionToggle>
                  </div>
                </>
              )}
            </NavLink>
          </Menu.Item>
        ))}

        <Menu.Item>
          <NavLink
            to={isCompany ? companyCollectionsRoute() : collectionsRoute()}
            end
            className="text-sm"
          >
            View all <FontAwesomeIcon icon={faChevronRight} />
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}
