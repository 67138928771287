import React from 'react';

type Props = {
  show?: boolean;
  colSpan?: number;
  children?: React.ReactNode;
};

export function NoItemsRow({
  show = false,
  colSpan = 999,
  children = <em>No items to display.</em>,
}: Props) {
  if (!show) return null;

  return (
    <tr>
      <td colSpan={colSpan} className="text-center text-slate-500">
        {children}
      </td>
    </tr>
  );
}
