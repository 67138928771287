import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import type {
  DeleteOutcropMutation,
  DeleteOutcropMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Panel } from '~/components/common/Panel';
import { uploadOutcropsRoute } from '~/paths';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const DELETE_OUTCROP = gql`
  mutation DeleteOutcrop($id: Int!) {
    deleteOutcrop(id: $id)
  }
`;

export default function UpdateOutcropDeleteOutcropRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const navigate = useNavigate();
  const [deleteOutcrop, { loading }] = useMutation<
    DeleteOutcropMutation,
    DeleteOutcropMutationVariables
  >(DELETE_OUTCROP, {
    variables: { id: outcropId },
  });

  async function handleDelete() {
    try {
      await deleteOutcrop();
      toast.success('The outcrop was deleted successfully.');
      navigate(uploadOutcropsRoute());
    } catch (err) {
      console.log('Error deleting outcrop', err);
      toast.error('There was a problem deleting the outcrop.');
    }
  }

  return (
    <div className="lg:w10/12 lg:mx-auto">
      <Panel variant="error">
        <Panel.Heading>
          <Panel.Title>Delete Outcrop</Panel.Title>
        </Panel.Heading>

        <Panel.Body>
          <div className="space-y-2">
            <p>
              This outcrop will be <b>PERMANENTLY DELETED</b>.
            </p>
            <p>
              All supporting objects that belong to this outcrop will be{' '}
              <b>PERMANENTLY DELETED</b>.
            </p>
            <p>
              All supporting objects that are linked to this outcrop via a study
              will be <b>unlinked</b> (but not deleted).
            </p>
            <p>
              Paleomaps that are linked to this outcrop will be <b>unlinked</b>{' '}
              (but not deleted).
            </p>

            <p>This action cannot be undone.</p>
          </div>
        </Panel.Body>

        <Panel.Footer className="flex justify-end">
          <Confirm
            onConfirm={handleDelete}
            submitText="Permanently Delete Outcrop"
            submitButtonColor="error"
          >
            {handleConfirm => (
              <Button
                type="button"
                color="error"
                onClick={handleConfirm}
                loading={loading}
              >
                Delete Outcrop
              </Button>
            )}
          </Confirm>
        </Panel.Footer>
      </Panel>
    </div>
  );
}
