import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  UploadStudyKeyParametersTabQuery,
  UploadStudyKeyParametersTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { KeyParametersParent } from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersUpload } from '~/components/upload/keyParameter/KeyParametersUpload';
import { useUpdateStudyOutletContext } from '../$studyId';

const UPLOAD_STUDY_KEY_PARAMETERS_TAB = gql`
  query UploadStudyKeyParametersTab($id: Int!) {
    studyList(id: $id) {
      ...studyParts
      keyParameters {
        ...keyParametersParts
      }
      outcrops {
        ...outcropParts
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.keyParametersParts}
  ${fragments.outcropParts}
`;

export default function UpdateStudyKeyParametersRoute() {
  const { study: outletStudy } = useUpdateStudyOutletContext();

  const { data, loading } = useQuery<
    UploadStudyKeyParametersTabQuery,
    UploadStudyKeyParametersTabQueryVariables
  >(UPLOAD_STUDY_KEY_PARAMETERS_TAB, {
    variables: { id: outletStudy.id },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyKeyParametersTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_STUDY_KEY_PARAMETERS_TAB,
      variables: { id: outletStudy.id },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === outletStudy.id);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return null;

  const geologyTypes = R.pipe(
    R.pluck('geologyType'),
    R.flatten,
    R.uniq,
    R.filter(R.complement(R.isNil)),
  )(study.outcrops);

  return (
    <KeyParametersUpload
      parentType={KeyParametersParent.Study}
      parentId={study.id}
      keyParameters={study.keyParameters}
      geologyTypes={geologyTypes}
      refetchQueries={refetchQueries}
    />
  );
}
