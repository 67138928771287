import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import {
  faCloudDownload,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import * as fragments from '~/apollo/fragments';
import type { AllOutcropsGeorefsQuery } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { saveAs } from '~/utils/export';
import { createKmlDocument, createKmlFolder } from '~/utils/kml';

const ALL_OUTCROPS_GEOREFS = gql`
  query AllOutcropsGeorefs {
    outcropList {
      ...outcropParts
      georeferences {
        ...georeferenceParts
      }
      studies {
        ...studyParts
        georeferences {
          ...georeferenceParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.studyParts}
  ${fragments.georeferenceParts}
`;

export default function ExportGeoreferencesAllOutcropsRoute() {
  const [includeStudies, setIncludeStudies] = useState(false);
  const { data, loading, error } = useQuery<AllOutcropsGeorefsQuery>(
    ALL_OUTCROPS_GEOREFS,
    {},
  );

  const outcrops = data?.outcropList ?? [];

  async function handleClick() {
    const outcropFolders = outcrops.map(outcrop => {
      let outcropStudiesFolders: any = null;
      if (includeStudies) {
        const studyFolders = outcrop.studies.map(study => {
          return createKmlFolder(
            study.name,
            `${study.name} Georeferences`,
            study.georeferences,
          );
        });
        outcropStudiesFolders = [
          createKmlFolder('Studies', null, [], studyFolders),
        ];
      }

      return createKmlFolder(
        outcrop.name,
        `${outcrop.name} Georeferences`,
        outcrop.georeferences,
        outcropStudiesFolders,
      );
    });

    const document = await createKmlDocument(outcropFolders);
    saveAs(document, 'SafariDB Outcrops.kml', 'application/vnd;charset=utf-8');
  }

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading data for all outcrops
        {includeStudies && ' and their linked studies'}, this may take a long
        time...
      </SpinnerPlaceholder>
    );
  }

  return (
    <div>
      <Panel>
        <Panel.Heading>
          <Panel.Title>Export All Outcrop Georeferences</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <div className="form-control">
            <label className="label flex justify-start gap-2">
              <input
                type="checkbox"
                value="1"
                onChange={() => setIncludeStudies(!includeStudies)}
                checked={includeStudies}
                className="checkbox"
              />
              <span className="label-text">
                Include outcrops' linked study georeferences
              </span>
            </label>
          </div>
          {error && (
            <div className="alert alert-error">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-xl"
              />
              <span>Network error</span>
            </div>
          )}
        </Panel.Body>

        <Panel.Footer className="text-right">
          <Button
            type="button"
            color="primary"
            onClick={handleClick}
            loading={loading}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faCloudDownload} /> Save KML
          </Button>
        </Panel.Footer>
      </Panel>
    </div>
  );
}
