import { zodResolver } from '@hookform/resolvers/zod';
import type { FieldValues } from 'react-hook-form';
import { useForm as useReactHookForm } from 'react-hook-form';
import type { ZodSchema } from 'zod';

/**
 * Wrapper for react-hook-form's `useForm` with a few default settings.
 * Allows for passing a zod schema directly (as `schema`) rather than calling zodResolver(schema) */
export function useHookForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  schema,
  ...params
}: Parameters<
  typeof useReactHookForm<TFieldValues, TContext, TTransformedValues>
>[0] & {
  schema?: ZodSchema;
}) {
  return useReactHookForm({
    ...params,
    mode: params.mode ?? 'all',
    criteriaMode: params.criteriaMode ?? 'all',
    resolver: schema ? zodResolver(schema) : params.resolver,
  });
}
