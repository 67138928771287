import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  VirtualOutcropDetailTabQuery,
  VirtualOutcropDetailTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { VomDetailPage } from '~/components/vom/VomDetailPage';

export const VIRTUAL_OUTCROP_DETAIL_TAB = gql`
  query VirtualOutcropDetailTab($vomId: Int!) {
    virtualOutcropModelList(id: $vomId) {
      ...vomParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
      georeference {
        ...georeferenceParts
      }
      interpreted
      cesiumAsset {
        approved
        state
      }
    }
  }

  ${fragments.vomParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
`;

export default function VomDetailRoute() {
  const params = useParams();
  invariant(params.vomId, 'vomId param required');
  const vomId = parseInt(params.vomId);

  const { data, loading } = useQuery<
    VirtualOutcropDetailTabQuery,
    VirtualOutcropDetailTabQueryVariables
  >(VIRTUAL_OUTCROP_DETAIL_TAB, {
    variables: { vomId },
  });

  const vom = data?.virtualOutcropModelList.find(v => v.id === vomId);

  useBreadcrumb(
    'routes/outcrop/$outcropId/virtual-outcrops/$vomId',
    vom?.name ?? 'Virtual Outcrop',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  return <VomDetailPage vom={vom} />;
}
