import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  CreateVirtualOutcropModelMutation,
  CreateVirtualOutcropModelMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { PageHeading } from '~/components/common/PageHeading';
import { VomDescriptionForm } from '~/components/upload/vom/VomDescriptionForm';
import * as routes from '~/paths';
import type { VomFormValues } from '~/utils/modules/vom';
import { initialVom, toVomInput, validationSchema } from '~/utils/modules/vom';
import { UpdateVomNavigation } from './$vomId/__UpdateVomNavigation';

export const CREATE_VIRTUAL_OUTCROP_MODEL = gql`
  mutation createVirtualOutcropModel($vom: VirtualOutcropModelInput!) {
    createVirtualOutcropModel(vom: $vom) {
      id
    }
  }
`;

export default function CreateVomRoute() {
  const navigate = useNavigate();

  const [createVom, { loading, error }] = useMutation<
    CreateVirtualOutcropModelMutation,
    CreateVirtualOutcropModelMutationVariables
  >(CREATE_VIRTUAL_OUTCROP_MODEL, {});

  async function handleSubmit(
    values: VomFormValues,
    helpers: FormikHelpers<VomFormValues>,
  ) {
    const vom = toVomInput(values);

    try {
      const res = await createVom({
        variables: {
          vom,
        },
      });
      invariant(res.data);

      toast.success('VOM created successfully.');
      const vomId = res.data.createVirtualOutcropModel.id;
      navigate(routes.uploadVomUpdateRoute(vomId));
    } catch (err) {
      toast.error('There was a problem creating the VOM. Please try again.');
    }
  }

  return (
    <>
      <PageHeading>Create Virtual Outcrop Model</PageHeading>

      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-2">
          <UpdateVomNavigation vomId={-1} />
        </div>

        <div className="lg:col-span-10">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialVom()}
            validationSchema={validationSchema}
          >
            <Form className="space-y-4">
              <VomDescriptionForm />
              <FormErrors graphQLError={error} />

              <div className="text-center">
                <Button type="submit" color="primary" loading={loading}>
                  Save
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
