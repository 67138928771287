import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { z } from 'zod';
import type {
  FieldPicsOutcropListQuery,
  FieldPicsOutcropListQueryVariables,
  LatLngInput,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import type { BulkFieldPictureUploaderDefaultValues } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/DefaultValuesForm';
import { DefaultValuesForm } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/DefaultValuesForm';
import { FieldPicsDropzone } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/FieldPicsDropzone';
import { MetadataEditor } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/MetadataEditor';
import { envVars } from '~/environment';

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  console.log('before unload event');
  event.preventDefault();
  return (event.returnValue = '');
};

// Don't care about any of the other properties, we aren't using them here.
const uploadFPResponseSchema = z.object({
  id: z.number().int(),
});

async function uploadFieldPicture(
  file: File,
  metadata: UploadQueueItem['metadata'],
) {
  const createFieldPicEndpoint = new URL(
    '/api/v3/supporting-object/field-picture',
    envVars.VITE_CLIENT_URL,
  );

  const body = new FormData();
  body.append('file', file);
  body.append('outcropId', String(metadata.outcropId || ''));
  body.append('author', String(metadata.author?.trim() || ''));
  body.append('description', String(metadata.description?.trim() || ''));
  body.append('locationApproximate', String(metadata.locationApproximate));

  if (metadata.location) {
    body.append('location_lat', String(metadata.location.lat));
    body.append('location_lng', String(metadata.location.lng));
  }

  const res = await fetch(createFieldPicEndpoint, { method: 'POST', body });
  const result = await res.json();
  return uploadFPResponseSchema.parse(result);
}

const FIELD_PICS_OUTCROP_LIST = gql`
  query FieldPicsOutcropList {
    outcropList {
      id
      name
      center {
        lat
        lng
      }
    }
  }
`;

export type UploadQueueItem = {
  tmpId: string;
  file: File;
  state: 'pending' | 'uploading' | 'success' | 'failed';
  valid: boolean;
  metadata: {
    outcropId: number | null;
    author: string | null;
    description: string | null;
    location: LatLngInput | null;
    locationApproximate: boolean;
  };
};

type DefaultValues = BulkFieldPictureUploaderDefaultValues;
export type UpdateMetadataHandler = (
  tmpId: UploadQueueItem['tmpId'],
  meta: UploadQueueItem['metadata'],
  valid: boolean,
) => void;

type BulkFieldPictureUploaderProps = {
  outcropId?: number;
  onUploadSuccess?: () => void;
};

export function BulkFieldPictureUploader({
  outcropId,
  onUploadSuccess,
}: BulkFieldPictureUploaderProps) {
  const { data } = useQuery<
    FieldPicsOutcropListQuery,
    FieldPicsOutcropListQueryVariables
  >(FIELD_PICS_OUTCROP_LIST);

  const [isUploading, setIsUploading] = useState(false);
  const [queue, setQueue] = useState<UploadQueueItem[]>([]);
  const [defaults, setDefaults] = useState<DefaultValues>({
    author: '',
    outcropId: '',
    location: null,
  });

  const outcrops = R.sortBy(R.prop('name'), data?.outcropList ?? []);

  useEffect(() => {
    if (outcropId && data?.outcropList.length) {
      const defaultOutcrop = data.outcropList.find(oc => oc.id === outcropId);
      if (defaultOutcrop && defaultOutcrop.center) {
        setDefaults({
          author: '',
          outcropId: String(outcropId),
          location: defaultOutcrop.center,
        });
      } else {
        window.alert(
          "The selected outcrop doesn't have a center point set and cannot be used.",
        );
      }
    }
  }, [data?.outcropList, outcropId]);

  function handleAdd(moreFiles: File[]) {
    setQueue(prevFiles => {
      const newFiles = moreFiles.map<UploadQueueItem>(file => ({
        file,
        tmpId: v4(),
        state: 'pending',
        valid: false,
        metadata: {
          outcropId: null,
          author: null,
          description: null,
          location: null,
          locationApproximate: false,
        },
      }));

      return prevFiles.concat(newFiles);
    });
  }

  function handleRemove(tmpId: string) {
    if (isUploading) {
      console.log("Can't remove items while upload in progress!");
      return;
    }

    const item = queue.find(item => item.tmpId === tmpId);
    if (item?.state === 'pending' || item?.state === 'failed') {
      const message =
        "This item hasn't been uploaded yet, are you sure you want to remove it?";
      if (!window.confirm(message)) {
        console.log('Removal cancelled');
        return;
      }
    }

    setQueue(items => items.filter(item => item.tmpId !== tmpId));
  }

  const updateMeta: UpdateMetadataHandler = (tmpId, metadata, valid) => {
    setQueue(prevQueue =>
      prevQueue.map(item => {
        if (item.tmpId !== tmpId) return item;
        return { ...item, valid, metadata };
      }),
    );
  };

  function setItemState(tmpId: string, state: UploadQueueItem['state']) {
    setQueue(prevQueue =>
      prevQueue.map(qi => {
        if (qi.tmpId === tmpId) {
          return { ...qi, state };
        } else {
          return qi;
        }
      }),
    );
  }

  async function handleStartUploading() {
    const hasAnyInvalid = queue.reduce((acc, cur) => acc || !cur.valid, false);
    if (hasAnyInvalid) {
      toast.error(
        'One or more items is missing some required data, please correct any issues and try again.',
      );
      return;
    }

    const successes: string[] = [];
    const failures: string[] = [];

    setIsUploading(true);

    const itemsToUpload = queue.filter(
      item => item.state === 'pending' || item.state === 'failed',
    );

    for (const item of itemsToUpload) {
      setItemState(item.tmpId, 'uploading');

      try {
        await uploadFieldPicture(item.file, item.metadata);
        setItemState(item.tmpId, 'success');
        successes.push(item.tmpId);
      } catch (err) {
        console.error(err);
        console.log('Error uploading item:', item);
        setItemState(item.tmpId, 'failed');
        failures.push(item.tmpId);
      } finally {
        if (onUploadSuccess) onUploadSuccess();
      }
    }

    setIsUploading(false);
    toast.info(
      `Finished uploading with ${successes.length} successful and ${failures.length} failed.`,
    );
  }

  const hasAnyInvalid = queue.reduce((acc, cur) => acc || !cur.valid, false);
  const hasItemsToUpload =
    queue.filter(item => item.state === 'pending' || item.state === 'failed')
      .length > 0;

  useEffect(() => {
    if (hasItemsToUpload) {
      console.log('Setting beforeunload listener');
      addEventListener('beforeunload', beforeUnloadListener, { capture: true });
    } else {
      console.log('Removing beforeunload listener');
      removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      });
    }
  }, [hasItemsToUpload]);

  return (
    <div className="space-y-4">
      <FieldPicsDropzone hasFiles={queue.length > 0} onDrop={handleAdd} />

      {queue.length > 0 && (
        <>
          <div className="flex items-center justify-center gap-2">
            <span>
              {queue.length} picture{queue.length === 1 ? '' : 's'} selected
            </span>

            <Confirm
              onConfirm={() => setQueue([])}
              text="Are you sure you want to clear all images? You'll lose any unsaved changes."
            >
              {toggleConfirm => (
                <Button
                  type="button"
                  color="ghost"
                  size="sm"
                  onClick={toggleConfirm}
                  startIcon={<FontAwesomeIcon icon={faRefresh} />}
                >
                  Start Over
                </Button>
              )}
            </Confirm>
          </div>

          <div className="space-y-4">
            <DefaultValuesForm
              values={defaults}
              onChange={setDefaults}
              outcrops={outcrops}
              outcropId={outcropId}
            />

            {queue.map(item => (
              <MetadataEditor
                key={item.tmpId}
                item={item}
                outcropId={outcropId}
                defaultValues={defaults}
                outcrops={outcrops}
                onRemove={handleRemove}
                onSubmit={updateMeta}
              />
            ))}

            <div className="text-center">
              <Button
                type="button"
                onClick={handleStartUploading}
                color="primary"
                disabled={hasAnyInvalid || !hasItemsToUpload}
                loading={isUploading}
              >
                Upload
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
