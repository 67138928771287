import type { EditReportPageQuery } from '~/apollo/generated/v3/graphql';
import { ReportItemType } from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { EditItemBookmark } from '~/components/report/ReportEditor/EditItemModal/EditItemBookmark';
import { EditItemText } from '~/components/report/ReportEditor/EditItemModal/EditItemText';
import { useModalState } from '~/hooks/modal';

type Report = EditReportPageQuery['reportList'][number];
type Item = Report['items'][number];

export function ReportEditorEditItemModal({
  children,
  item,
}: {
  children: (showModal: () => void) => JSX.Element;
  item: Item;
}) {
  const { show, showModal, hideModal } = useModalState();

  const handleUpdateSuccess = hideModal;

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading={`Edit Item`}>
          <div className="space-y-4">
            {item.itemType === ReportItemType.Bookmark && item.bookmark && (
              <EditItemBookmark
                item={item}
                bookmark={item.bookmark}
                onUpdateSuccess={handleUpdateSuccess}
              />
            )}
            {item.itemType === ReportItemType.Text && (
              <EditItemText item={item} onUpdateSuccess={handleUpdateSuccess} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
