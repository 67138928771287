import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { NavLink, Outlet, useOutletContext } from 'react-router';
import { gql } from '~/apollo/client-v3';
import { pageParts } from '~/apollo/fragments';
import type {
  AdminPagePageQuery,
  AdminPagePageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { Menu } from '~/components/ui/menu';
import { adminPageRoute, adminPageUpdateRoute } from '~/paths';
import { cn } from '~/utils/common';

const ADMIN_PAGE_PAGE = gql`
  query AdminPagePage {
    pageList {
      ...pageParts
    }
  }

  ${pageParts}
`;

export type OutletContext = {
  pageList: AdminPagePageQuery['pageList'];
  refetchQueries: PureQueryOptions[];
};

export default function AdminPagePage() {
  useBreadcrumb('routes/admin/page', 'Pages');

  const { data, loading } = useQuery<
    AdminPagePageQuery,
    AdminPagePageQueryVariables
  >(ADMIN_PAGE_PAGE);

  const refetchQueries: [PureQueryOptions<AdminPagePageQueryVariables>] = [
    { query: ADMIN_PAGE_PAGE },
  ];

  const pageList = R.sortBy(p => p.pageName, data?.pageList ?? []);

  const outletContext: OutletContext = { pageList: pageList, refetchQueries };

  if (loading) return <SpinnerIcon />;

  return (
    <div className="grid grid-cols-12 gap-x-4">
      <div className="col-span-12 md:col-span-3">
        <Menu>
          <Menu.Item>
            <NavLink to={adminPageRoute()} end>
              <FontAwesomeIcon icon={faPlus} /> Create Page
            </NavLink>
          </Menu.Item>
          {pageList.map(p => (
            <Menu.Item key={p.id}>
              <NavLink to={adminPageUpdateRoute(p.id)} className="block">
                {({ isActive }) => (
                  <>
                    <div className="font-semibold">{p.title}</div>
                    <div
                      className={cn('text-sm', {
                        'text-muted': !isActive,
                      })}
                    >
                      {p.pageName}
                    </div>
                  </>
                )}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
      </div>

      <div className="col-span-12 md:col-span-9">
        <Outlet context={outletContext} />
      </div>
    </div>
  );
}

export function useUploadPageContext(): OutletContext {
  return useOutletContext<OutletContext>();
}
