import type { ReactNode } from 'react';
import { Navigate } from 'react-router';
import type { Role } from '~/apollo/generated/v3/graphql';
import { useAuth } from '~/contexts/auth';
import { forbiddenRoute, loginRoute } from '~/paths';

type Props = {
  roles?: Role[];
  children: ReactNode;
};

export function Authorize({ roles, children }: Props) {
  const { authority, hasAnyRole } = useAuth();

  const searchParams = new URLSearchParams();
  searchParams.set('redirectTo', window.location.href);

  if (!authority) {
    return (
      <Navigate
        to={{
          pathname: loginRoute(),
          search: searchParams.toString(),
        }}
      />
    );
  }

  if (roles && roles.length > 0 && !hasAnyRole(roles)) {
    return <Navigate to={forbiddenRoute()} />;
  }

  return children;
}
