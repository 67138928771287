import type { PureQueryOptions } from '@apollo/client';
import React from 'react';
import type { PictureParent } from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { PictureUpload } from '~/components/upload/file/PictureUpload';
import { useModalState } from '~/hooks/modal';

type Props = {
  parentType: PictureParent;
  parentId: number;
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function UploadVomPicturesModal({
  parentType,
  parentId,
  refetchQueries,
  children,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading="Upload Pictures">
          <PictureUpload
            parentType={parentType}
            parentId={parentId}
            refetchQueries={refetchQueries}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
