import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { graphql } from '~/apollo/generated/v4';

const INTERVAL_DELAY = 1000 * 5;

export const pollTilingMutation = graphql(`
  mutation PollTiling($id: ID!) {
    cesiumAssetPollTiling(id: $id) {
      result {
        ...cesiumAssetParts
        cesiumDownloadTodo
        cesiumDownloadTotal
        cesiumProgress
        cesiumUploadTodo
        cesiumUploadTotal
        error
      }
    }
  }
`);

export function UploadingState({
  vomId,
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [pollTiling, { data, loading }] = useMutation(pollTilingMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  useEffect(() => {
    const interval = window.setInterval(pollTiling, INTERVAL_DELAY);
    pollTiling();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [vomId, cesiumAssetId, pollTiling]);

  const total = data?.cesiumAssetPollTiling?.result?.cesiumUploadTotal;
  const todo = data?.cesiumAssetPollTiling?.result?.cesiumUploadTodo ?? 0;
  const progress = total ? Math.floor(((total - todo) / total) * 100) : 0;

  return (
    <div className="text-center">
      <div
        className="radial-progress progress-success"
        style={{ '--value': progress } as React.CSSProperties}
      >
        <span className={loading ? 'text-muted' : undefined}>{progress}%</span>
      </div>
    </div>
  );
}
