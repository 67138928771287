import type { ComponentPropsWithoutRef, ComponentPropsWithRef } from 'react';
import { forwardRef } from 'react';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { InputBase } from '~/components/ui/forms/InputBase';
import { cn } from '~/utils/common';

export type TextInputProps = {
  name: string;
  containerProps?: ComponentPropsWithoutRef<'div'>;
  label?: FormLabelProps['label'];
} & ComponentPropsWithRef<'input'>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { containerProps, label, className, ...inputProps }: TextInputProps,
    ref,
  ) => {
    // Default the ID to the name in case an ID isn't set
    const elementId = inputProps.id ?? inputProps.name;

    return (
      <InputBase
        id={elementId}
        name={inputProps.name}
        label={label}
        required={inputProps.required}
      >
        <input
          {...inputProps}
          placeholder=""
          ref={ref}
          id={elementId}
          className={cn('input w-full', className)}
        />
      </InputBase>
    );
  },
);
TextInput.displayName = 'TextInput';
