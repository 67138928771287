import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { UpdateVomModelDataPageQueryQueryVariables } from '~/apollo/generated/v4/graphql';
import { CesiumAssetState } from '~/apollo/generated/v4/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Alert } from '~/components/ui/alert';
import { CreateCesiumAsset } from '~/components/upload/vom/modelData/CreateCesiumAsset';
import { ArchivingState } from '~/components/upload/vom/modelData/states/ArchivingState';
import { DownloadingState } from '~/components/upload/vom/modelData/states/DownloadingState';
import { InitializedState } from '~/components/upload/vom/modelData/states/InitializedState';
import { PlaceableCompleteState } from '~/components/upload/vom/modelData/states/PlaceableCompleteState';
import { ProcessingState } from '~/components/upload/vom/modelData/states/ProcessingState';
import { QualityAssuranceState } from '~/components/upload/vom/modelData/states/QualityAssuranceState';
import { UploadingState } from '~/components/upload/vom/modelData/states/UploadingState';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';
import { cn } from '~/utils/common';

const updateVomModelDataPageQuery = graphql(`
  query UpdateVomModelDataPageQuery($id: ID!) {
    virtualOutcropModelGet(id: $id) {
      id
      cesiumAsset {
        ...cesiumAssetParts
      }
    }
  }
`);

function steps(state: CesiumAssetState) {
  const steps = [
    [CesiumAssetState.Initialized, 'Upload files'],
    [CesiumAssetState.Uploading, 'Send to cesium'],
    [CesiumAssetState.Processing, 'Process'],
    [CesiumAssetState.Archiving, 'Archive'],
    [CesiumAssetState.Downloading, 'Download'],
    [CesiumAssetState.QualityAssurance, 'QA'],
  ];

  if (state === CesiumAssetState.Complete) {
    steps.push([CesiumAssetState.Complete, 'Done']);
  } else {
    steps.push([CesiumAssetState.Placeable, 'Place']);
  }

  return steps;
}

function Stepper({ state }: { state: CesiumAssetState }) {
  const possibleSteps = steps(state);
  const curStep = possibleSteps.findIndex(([s, _]) => s === state);

  // Upload failed or something else happened
  if (curStep === -1) {
    return null;
  }

  return (
    <ul className="steps min-w-full">
      {steps(state).map(([name, label], i) => (
        <li
          key={name}
          className={cn('step', i <= curStep ? 'step-primary' : 'step-neutral')}
        >
          <span
            className={cn('text-sm cursor-default', {
              'text-slate-500': i > curStep,
            })}
          >
            {label}
          </span>
        </li>
      ))}
    </ul>
  );
}

function UpdateVomModelDataPageInner() {
  const ctx = useUpdateVomOutletContext();

  const { data, loading } = useQuery(updateVomModelDataPageQuery, {
    variables: { id: ctx.vom.id },
  });

  const refetchQueries: [
    PureQueryOptions<UpdateVomModelDataPageQueryQueryVariables>,
  ] = [{ query: updateVomModelDataPageQuery, variables: { id: ctx.vom.id } }];

  const vom = data?.virtualOutcropModelGet;
  const cesiumAsset = vom?.cesiumAsset;

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  return (
    <div className="space-y-4">
      {cesiumAsset?.state && <Stepper state={cesiumAsset.state} />}
      {!cesiumAsset && (
        <CreateCesiumAsset
          vomId={parseInt(vom.id)}
          refetchQueries={refetchQueries}
        />
      )}
      {cesiumAsset?.state && (
        <UploadStep
          vomId={parseInt(vom.id)}
          cesiumAssetId={parseInt(cesiumAsset.id)}
          state={cesiumAsset.state}
          refetchQueries={refetchQueries}
        />
      )}
    </div>
  );
}

export default function UpdateVomModelDataPage() {
  return (
    <ApolloProviderV4>
      <UpdateVomModelDataPageInner />
    </ApolloProviderV4>
  );
}

function UploadStep({
  vomId,
  cesiumAssetId,
  state,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  state: CesiumAssetState;
  refetchQueries: PureQueryOptions[];
}): JSX.Element {
  const props = { vomId, cesiumAssetId, refetchQueries };

  switch (state) {
    case CesiumAssetState.Initialized:
      return <InitializedState {...props} />;
    case CesiumAssetState.Uploading:
      return <UploadingState {...props} />;
    case CesiumAssetState.Processing:
      return <ProcessingState {...props} />;
    case CesiumAssetState.Archiving:
      return <ArchivingState {...props} />;
    case CesiumAssetState.Downloading:
      return <DownloadingState {...props} />;
    case CesiumAssetState.QualityAssurance:
      return <QualityAssuranceState {...props} />;
    case CesiumAssetState.Placeable:
    case CesiumAssetState.Complete:
      return <PlaceableCompleteState {...props} state={state} />;
    case CesiumAssetState.Failed:
      return (
        <Alert status="error">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl" />
          <p>There was a problem in the Cesium Asset process.</p>
          {/* Add .error to the cesium fragment and display the text here.
              Also add the reset to initialized button here */}
        </Alert>
      );
  }
}
