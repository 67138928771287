import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '~/components/ui/button';
import { Tooltip } from '~/components/common/Tooltip';
import { Badge } from '~/components/ui/badge';

type Props = {
  defaultExpanded?: boolean;
  /** Text to be rendered on the page next to the chevron */
  label: (isExpanded: boolean) => React.ReactNode;
  /** Text to be rendered in the tooltip */
  tooltipText: (expanded: boolean) => string;
  children: React.ReactNode;
  renderWhenCollapsed?: () => React.ReactNode;
  numSelected: number;
};

export function Expander({
  defaultExpanded = false,
  label: renderLabel,
  tooltipText,
  children,
  renderWhenCollapsed,
  numSelected,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const wasDefaultExpanded = useRef(defaultExpanded);

  useEffect(() => {
    if (defaultExpanded && !wasDefaultExpanded.current && !isExpanded) {
      setIsExpanded(true);
    }

    // This should maybe be !isExpanded to prevent collapsing when deselecting all items.
    // I'm not sure what the best UX is here. -DF
    if (!defaultExpanded && wasDefaultExpanded.current && isExpanded) {
      setIsExpanded(false);
    }

    wasDefaultExpanded.current = defaultExpanded;
  }, [defaultExpanded, isExpanded]);

  const toggle = (
    <Tooltip message={tooltipText(isExpanded)}>
      <Button
        type="button"
        color="ghost"
        size="xs"
        onClick={() => setIsExpanded(prevValue => !prevValue)}
        className="gap-2"
      >
        <span>{renderLabel(isExpanded)}</span>
        {!isExpanded && numSelected > 0 && (
          <Badge color="primary">{numSelected} selected</Badge>
        )}
        <span>
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronRight} />
        </span>
      </Button>
    </Tooltip>
  );

  return (
    <>
      {!isExpanded && renderWhenCollapsed && renderWhenCollapsed()}
      {!isExpanded && toggle}
      {isExpanded && children}
      {isExpanded && toggle}
    </>
  );
}
