import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Panel } from '~/components/common/Panel';

const createCesiumAssetMutation = graphql(`
  mutation CreateCesiumAsset($input: CesiumAssetCreateInitialFromVomInput!) {
    cesiumAssetCreateInitialFromVom(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreateCesiumAsset({
  vomId,
  refetchQueries,
}: {
  vomId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [createCesiumAsset, { loading }] = useMutation(
    createCesiumAssetMutation,
    {
      variables: { input: { vomId } },
      refetchQueries,
    },
  );

  async function handleCreateClick() {
    try {
      await createCesiumAsset();
      toast.success('Cesium asset created successfully.');
    } catch (err) {
      console.log('Error creating cesium asset', err);
      toast.error(
        'There was a problem creating the cesium asset. Please reload the page; one may have already been created.',
      );
    }
  }
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Cesium Asset</Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <div className="text-center space-y-4">
          <p>This Virtual Outcrop doesn't have a Cesium Asset created yet.</p>

          <Button
            type="button"
            onClick={handleCreateClick}
            loading={loading}
            color="success"
            startIcon={<FontAwesomeIcon icon={faCirclePlay} />}
          >
            Initialize Cesium Asset
          </Button>
        </div>
      </Panel.Body>
    </Panel>
  );
}
