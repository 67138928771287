import {
  StudyDataSourceType,
  type DataHistoryPartsFragment,
  type StudyInput,
  type StudyPartsFragment,
  type StudyQualityParametersPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type StudyFormValues = {
  name: string;
  projectId: number | string;
  /** outcropId only needs to be set on create, to set an initial outcrop relationship */
  outcropId?: number | null;
  type: string;
  abstract: string;
  keyReferences: string;
  approved: boolean;
  readyForApproval: boolean;
  dataHistory: DataHistoryFormValues;
  qualityParameters: StudyQualityParametersValues;
  qcCompleted: boolean;
  redundant: boolean;
  dominantGeologyType: string;
  keywords: string;
  publicationLicense: string;
  numericalDataAspects: string;
  dataSourceType: string;
};

export type StudyQualityParametersValues = {
  id?: number | null;
  spatialObservationType: string;
  dataAcquisitionMethod: string[];
  publicationType: string[];
};

export type DataHistoryFormValues = {
  id?: number | null;
  collectedBy: string;
  date: string;
};

type StudyForCreation = StudyPartsFragment & {
  dataHistory?: DataHistoryPartsFragment | null;
  qualityParameters?: StudyQualityParametersPartsFragment | null;
};

export function defaultStudy(
  study?: StudyForCreation | null,
  outcropId?: number | null,
): StudyFormValues {
  return {
    name: study?.name ?? '',
    type: study?.type ?? '',
    projectId: study?.projectId ?? '',
    outcropId: outcropId ?? -1,
    abstract: study?.abstract ?? '',
    keyReferences: study?.keyReferences ?? '',
    approved: study?.approved ?? false,
    readyForApproval: study?.readyForApproval ?? false,
    qcCompleted: study?.qcCompleted ?? false,
    redundant: study?.redundant ?? false,
    dominantGeologyType: study?.dominantGeologyType ?? '',
    keywords: study?.keywords || '',
    publicationLicense: study?.publicationLicense || '',
    numericalDataAspects: study?.numericalDataAspects || '',
    dataSourceType: study?.dataSourceType ?? '',
    dataHistory: {
      id: study?.dataHistory?.id,
      collectedBy: study?.dataHistory?.collectedBy ?? '',
      date: String(study?.dataHistory?.date ?? ''),
    },
    qualityParameters: {
      id: study?.qualityParameters?.id,
      spatialObservationType:
        study?.qualityParameters?.spatialObservationType ?? '',
      dataAcquisitionMethod:
        study?.qualityParameters?.dataAcquisitionMethod ?? [],
      publicationType: study?.qualityParameters?.publicationType ?? [],
    },
  };
}

export const validationSchema = () => {
  return yup.object({
    name: yup.string().label('name').required(),
    projectId: yup.number().integer().label('project').required(),
    outcropId: yup.number().integer().label('outcrop').required(),
    type: yup.string().label('study type').required(),
    abstract: yup.string().label('abstract').nullable(),
    keyReferences: yup.string().label('key references').nullable(),
    approved: yup.boolean().label('approved'),
    readyForApproval: yup.boolean().label('approved'),
    qcCompleted: yup.boolean().label('qcCompleted').required(),
    redundant: yup.boolean().label('redundant').required(),
    dominantGeologyType: yup.string().label('dominant geology type').nullable(),
    numericalDataAspects: yup
      .string()
      .label('numerical data and other notable aspects')
      .nullable(),
    dataSourceType: yup
      .string()
      .oneOf(Object.values(StudyDataSourceType))
      .required(),
    dataHistory: yup
      .object({
        collectedBy: yup.string().label('collected by').required(),
        date: yup
          .number()
          .integer()
          .min(0)
          .max(new Date().getFullYear())
          .label('study date')
          .required(),
      })
      .required(),
    qualityParameters: yup.object({
      spatialObservationType: yup
        .string()
        .label('spatial observation type')
        .nullable(),
      dataAcquisitionMethod: yup
        .array(yup.string())
        .label('data acquisition method')
        .nullable(),
      publicationType: yup
        .array(yup.string())
        .label('publication type')
        .min(1, 'at least one publication type is required')
        .required(),
    }),
  });
};

export const toStudyInput = (values: StudyFormValues): StudyInput => {
  const valueOrNull = (value: string): string | null => {
    if (!value.length) return null;
    return value;
  };

  const input: StudyInput = {
    name: values.name,
    outcropId: values.outcropId ?? -1,
    projectId:
      typeof values.projectId === 'number'
        ? values.projectId
        : parseInt(values.projectId),
    type: values.type,
    abstract: values.abstract,
    keyReferences: values.keyReferences,
    approved: values.approved,
    readyForApproval: values.readyForApproval,
    qcCompleted: values.qcCompleted,
    redundant: values.redundant,
    dominantGeologyType: valueOrNull(values.dominantGeologyType),
    keywords: values.keywords,
    publicationLicense: values.publicationLicense,
    numericalDataAspects: values.numericalDataAspects,
    dataSourceType: values.dataSourceType as StudyDataSourceType,

    dataHistory: {
      ...values.dataHistory,
      date: parseInt(values.dataHistory.date),
    },
    qualityParameters: {
      ...values.qualityParameters,
      spatialObservationType: valueOrNull(
        values.qualityParameters.spatialObservationType,
      ),
    },
  };

  return input;
};
