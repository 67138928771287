import { faChartColumn, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { Button } from '~/components/ui/button';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

type Props = {
  disabled?: boolean;
};

export function GraphTypeSelectors({ disabled = false }: Props) {
  const { clearMeasurements } = useDataSearchContext();
  const { values, setFieldValue } = useFormikContext<DataSearchFormValues>();

  const value = values.graphType;

  const handleChange = (graphType: string) => () => {
    if (!disabled) {
      setFieldValue('graphType', graphType);
      clearMeasurements();
    }
  };

  return (
    <div className="text-center space-x-2">
      <Button
        type="button"
        color={value === 'crossPlot' ? 'primary' : 'ghost'}
        onClick={handleChange('crossPlot')}
        startIcon={<FontAwesomeIcon icon={faChartLine} />}
      >
        Cross Plot
      </Button>
      <Button
        type="button"
        color={value === 'histogram' ? 'primary' : 'ghost'}
        onClick={handleChange('histogram')}
        startIcon={<FontAwesomeIcon icon={faChartColumn} />}
      >
        Histogram
      </Button>
    </div>
  );
}
