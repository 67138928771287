import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type { MemberListSelectorQuery } from '~/apollo/generated/v3/graphql';
import { FormikReactSelectField } from '~/components/common/FormikField/FormikReactSelectField';

const MEMBER_LIST_SELECTOR = gql`
  query MemberListSelector {
    lithostratMemberList {
      ...lithostratMemberParts
      wikiPage {
        ...lithostratWikiPageParts
      }
    }
  }

  ${fragments.lithostratMemberParts}
  ${fragments.lithostratWikiPageParts}
`;

type Props = FieldProps & {
  lithostratTypeId?: number;
  disabled?: boolean;
  onlyPublished?: boolean;
};

export function MemberSelector({
  lithostratTypeId,
  onlyPublished = false,
  ...props
}: Props) {
  const { loading, data } = useQuery<MemberListSelectorQuery>(
    MEMBER_LIST_SELECTOR,
    {},
  );

  const mbList = data?.lithostratMemberList ?? [];
  const filtered = mbList.filter(mb => {
    if (!lithostratTypeId) return true;
    return mb.lithostratTypeId === lithostratTypeId;
  });
  const sorted = R.sortBy(mb => mb.name, filtered);
  const options = sorted.map(mb => ({
    value: mb.id,
    label: mb.name,
    disabled: onlyPublished && !mb.wikiPage?.published,
  }));

  return (
    <FormikReactSelectField {...props} options={options} loading={loading} />
  );
}
