import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '~/components/ui/button';
import { Heading } from '~/components/common/Heading';
import { Well } from '~/components/common/Well';
import { ExportStatistics } from '~/components/statistics/MeasurementGraph/ExportStatistics';
import { useMeasurementStatistics } from '~/components/statistics/MeasurementGraph/useMeasurementStatistics';
import { ExportData } from '~/components/dataSearch/ExportData';

type StatNumProps = {
  label: string;
  valueX: string | number;
  valueY: string | number | null;
};
function StatNum({ label, valueX, valueY }: StatNumProps) {
  return (
    <div className="text-left">
      <small className="text-muted" style={{ fontSize: '9pt' }}>
        {label}:
      </small>
      <br />
      {/* Don't show 'x' label if y isn't set */}
      {valueY !== null && (
        <small className="text-muted" style={{ fontSize: '9pt' }}>
          x:{' '}
        </small>
      )}
      {valueX}
      {valueY !== null && (
        <>
          <br />
          <small className="text-muted" style={{ fontSize: '9pt' }}>
            y:{' '}
          </small>
          {valueY}
        </>
      )}
    </div>
  );
}

type Props = {
  dataX: number[];
  dataY?: number[] | null;
  children?: React.ReactNode;
};
export function MeasurementStatistics({ dataX, dataY, children }: Props) {
  const stats = useMeasurementStatistics(dataX, dataY);

  return (
    <Well className="space-y-2">
      <Heading level={4}>Statistics</Heading>

      {children}

      {(stats['Minimum'] || stats['Maximum']) && (
        <div className="grid lg:grid-cols-4 print:grid-cols-4 gap-6">
          {stats.Minimum && (
            <StatNum
              label="Minimum"
              valueX={stats.Minimum.x}
              valueY={stats.Minimum.y}
            />
          )}
          {stats.Maximum && (
            <StatNum
              label="Maximum"
              valueX={stats.Maximum.x}
              valueY={stats.Maximum.y}
            />
          )}
        </div>
      )}

      <div className="grid lg:grid-cols-4 print:grid-cols-4 gap-6">
        {stats.P10 && (
          <StatNum label="P10" valueX={stats.P10.x} valueY={stats.P10.x} />
        )}
        {stats.P50 && (
          <StatNum label="P50" valueX={stats.P50.x} valueY={stats.P50.y} />
        )}
        {stats.P90 && (
          <StatNum label="P90" valueX={stats.P90.x} valueY={stats.P90.y} />
        )}
      </div>

      <div className="grid lg:grid-cols-4 print:grid-cols-4 gap-6">
        {stats.Mean && (
          <StatNum label="Mean" valueX={stats.Mean.x} valueY={stats.Mean.y} />
        )}
        {stats.Median && (
          <StatNum
            label="Median"
            valueX={stats.Median.x}
            valueY={stats.Median.y}
          />
        )}
        {stats.Mode && (
          <StatNum label="Mode" valueX={stats.Mode.x} valueY={stats.Mode.y} />
        )}
        {stats['Std Deviation'] && (
          <StatNum
            label="Std Deviation"
            valueX={stats['Std Deviation'].x}
            valueY={stats['Std Deviation'].y}
          />
        )}
      </div>

      {stats.n && (
        <div className="text-center">
          <em className="text-muted">n</em> = {stats.n.x}
        </div>
      )}

      <div className="text-center space-x-2 pt-2">
        <ExportData>
          {(exportData, canExport) => (
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={exportData}
              startIcon={<FontAwesomeIcon icon={faFileExport} />}
              disabled={!canExport}
            >
              Export Data
            </Button>
          )}
        </ExportData>

        <ExportStatistics dataX={dataX} dataY={dataY}>
          {(handleExport, canExport) => (
            <Button
              type="button"
              onClick={handleExport}
              disabled={!canExport}
              color="primary"
              size="sm"
              startIcon={<FontAwesomeIcon icon={faFileExport} />}
            >
              Export Statistics
            </Button>
          )}
        </ExportStatistics>
      </div>
    </Well>
  );
}
