import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { fileParts, supplementalDocumentParts } from '~/apollo/fragments';
import {
  type UploadOutcropSupplementalDocsRouteQuery,
  type UploadOutcropSupplementalDocsRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { SuppDocList } from '~/components/upload/supportingObject/supplementalDocs/SuppDocList';
import { SuppDocUploader } from '~/components/upload/supportingObject/supplementalDocs/SuppDocUploader';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

const UPLOAD_OUTCROP_SUPPLEMENTAL_DOCS_ROUTE = gql`
  query UploadOutcropSupplementalDocsRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      id
      supplementalDocuments {
        ...supplementalDocumentParts
        file {
          ...fileParts
        }
      }
    }
  }

  ${supplementalDocumentParts}
  ${fileParts}
`;

export default function UploadOutcropSupplementalDocumentsRoute() {
  const { outcrop } = useUploadOutcropUpdateRouteOutletContext();

  const { data, loading } = useQuery<
    UploadOutcropSupplementalDocsRouteQuery,
    UploadOutcropSupplementalDocsRouteQueryVariables
  >(UPLOAD_OUTCROP_SUPPLEMENTAL_DOCS_ROUTE, {
    variables: { outcropId: outcrop.id },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropSupplementalDocsRouteQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_SUPPLEMENTAL_DOCS_ROUTE,
      variables: { outcropId: outcrop.id },
    },
  ];

  const [uploaderVisible, setUploaderVisible] = useState(false);

  const docs = data?.outcropList.find(
    oc => oc.id === outcrop.id,
  )?.supplementalDocuments;

  if (loading) return <SpinnerPlaceholder />;
  invariant(docs, 'Error loading supplemental documents!');

  return (
    <div className="space-y-4">
      <Panel variant={uploaderVisible ? 'primary' : 'default'}>
        <Panel.Heading>
          <button
            type="button"
            onClick={() => setUploaderVisible(!uploaderVisible)}
            className="flex justify-between items-center gap-2 w-full"
          >
            <Panel.Title>Upload Supplemental Documents</Panel.Title>
            <ExpandedIcon expanded={uploaderVisible} />
          </button>
        </Panel.Heading>

        {uploaderVisible && (
          <Panel.Body>
            <SuppDocUploader
              outcropId={outcrop.id}
              refetchQueries={refetchQueries}
            />
          </Panel.Body>
        )}
      </Panel>

      <SuppDocList docs={docs} refetchQueries={refetchQueries} />
    </div>
  );
}
