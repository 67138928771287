import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { LimeUpload } from '~/components/upload/lime/limeUpload';

export default function VomLimeUploadRoute() {
  const params = useParams();
  invariant(params.vomId, 'vomId param required');
  const vomId = parseInt(params.vomId);
  console.log('routign to lie upload vomId:', vomId);

  return <LimeUpload vomId={vomId} />;
}
