import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeleteFormationMutation,
  DeleteFormationMutationVariables,
} from '~/apollo/generated/v3/graphql';

const DELETE_FORMATION = gql`
  mutation DeleteFormation($id: Int!) {
    deleteLithostratFormation(id: $id)
  }
`;

type Props = {
  id: number;
  children: (onDelete: () => void, loading: boolean) => JSX.Element;
  onDeleteSuccess?: () => any;
  refetchQueries?: PureQueryOptions[];
};

export function DeleteFormation({
  id,
  children,
  onDeleteSuccess,
  refetchQueries,
}: Props) {
  const [deleteMember, { loading }] = useMutation<
    DeleteFormationMutation,
    DeleteFormationMutationVariables
  >(DELETE_FORMATION, {
    variables: { id },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteMember();
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    } catch (err) {
      console.log('Error deleting formation', err);
      toast.error('There was a problem deleting the formation.');
    }
  }

  return children(handleDelete, loading);
}
