import type { PureQueryOptions } from '@apollo/client';
import {
  faCancel,
  faMapLocationDot,
  faMapMarker,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import type {
  GeoreferenceDataType,
  GeoreferenceParent,
  GeoreferencePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { GeoreferenceManager } from '~/components/upload/georeference/GeoreferenceManager';

export type GeoreferenceEditorLimits = Partial<
  Record<GeoreferenceDataType, number>
>;

type Props = {
  georeference?: GeoreferencePartsFragment[];
  limits?: GeoreferenceEditorLimits;
  dataTypes: GeoreferenceDataType[];
  parentType: GeoreferenceParent;
  parentId: number;
  refetchQueries: PureQueryOptions[];
  outcropId?: number;
  studyId?: number;
};

export function SupportingObjectGeoreference({
  limits,
  georeference,
  dataTypes,
  parentType,
  parentId,
  refetchQueries,
}: Props) {
  const [isManagerVisible, setIsManagerVisible] = useState(false);

  return (
    <>
      <div className="space-y-2">
        <div className="flex justify-between">
          <Heading level={4}>{!isManagerVisible && 'Georeference'}</Heading>

          <Button
            type="button"
            color="ghost"
            size="xs"
            onClick={() => setIsManagerVisible(!isManagerVisible)}
            startIcon={
              <FontAwesomeIcon
                icon={isManagerVisible ? faCancel : faMapLocationDot}
              />
            }
          >
            {isManagerVisible ? 'Close' : 'Manage'}
          </Button>
        </div>

        {isManagerVisible && (
          <GeoreferenceManager
            georeferences={georeference ?? []}
            limits={limits}
            dataTypes={dataTypes}
            parentType={parentType}
            parentId={parentId}
            refetchQueries={refetchQueries}
          />
        )}

        {!isManagerVisible && (
          <>
            {georeference?.length ? (
              <div className="flex justify-start items-center gap-2">
                <FontAwesomeIcon
                  icon={faMapMarker}
                  className="text-error text-lg"
                />
                <span>Georeference set</span>
              </div>
            ) : (
              <div className="text-base text-muted">No georeference added.</div>
            )}
          </>
        )}
      </div>
    </>
  );
}
