import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import type {
  OutcropAnaloguesRouteQuery,
  OutcropAnaloguesRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { AnalogueSearch } from '~/components/analogueSearch/AnalogueSearch';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const ANALOGUES_TAB = gql`
  query OutcropAnaloguesRoute($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      basin {
        ...basinParts
      }
      keyParameters {
        ...keyParametersParts
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.basinParts}
  ${fragments.keyParametersParts}
`;

export default function OutcropAnaloguesRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    OutcropAnaloguesRouteQuery,
    OutcropAnaloguesRouteQueryVariables
  >(ANALOGUES_TAB, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  if (!outcrop.basin?.basinType || !outcrop.basin?.climate) {
    return (
      <p>
        A basin must be set for this outcrop before analogues can be viewed.
      </p>
    );
  }

  return (
    <AnalogueSearch
      outcropId={outcropId}
      geologyType={outcrop.geologyType}
      basinType={outcrop.basin.basinType}
      climate={outcrop.basin.climate}
      keyParameters={outcrop.keyParameters ?? []}
    />
  );
}
