import type { HTMLProps } from 'react';
import type { LinkProps, NavLinkProps } from 'react-router';
import { Link, NavLink } from 'react-router';
import { cn } from '~/utils/common';

export type NavTabsProps = HTMLProps<HTMLDivElement>;

export function NavTabs({ className, ...divProps }: NavTabsProps) {
  return (
    <div
      className={cn('tabs tabs-border inline-block', className)}
      {...divProps}
    />
  );
}

type NavTabProps = LinkProps & {
  disabled?: boolean;
  end?: NavLinkProps['end'];
};

function NavTab({
  className,
  disabled,
  to,
  children,
  end,
  ...linkProps
}: NavTabProps) {
  if (disabled) {
    return (
      <Link
        {...linkProps}
        to={to}
        className={cn('tab text-slate-300 cursor-not-allowed')}
        onClick={e => e.preventDefault()}
      >
        {children}
      </Link>
    );
  }

  return (
    <NavLink
      to={to}
      end={end}
      className={({ isActive }) =>
        cn('tab', className, {
          'tab-active': isActive,
        })
      }
    >
      {children}
    </NavLink>
  );
}

NavTabs.Tab = NavTab;
