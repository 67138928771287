import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import * as fragments from '~/apollo/fragments';
import {
  GeoreferenceDataType,
  GeoreferenceParent,
  type UploadStudyUpdateGeoreferencesTabQuery,
  type UploadStudyUpdateGeoreferencesTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GeoreferenceManager } from '~/components/upload/georeference/GeoreferenceManager';
import { useUpdateStudyOutletContext as useUpdateStudyPageOutletContext } from '~/routes/upload/model/study/$studyId';

const GEOREFERENCES_TAB = gql`
  query UploadStudyUpdateGeoreferencesTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      georeferences {
        ...georeferenceParts
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateStudyGeoreferencesRoute() {
  const ctx = useUpdateStudyPageOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateGeoreferencesTabQuery,
    UploadStudyUpdateGeoreferencesTabQueryVariables
  >(GEOREFERENCES_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateGeoreferencesTabQueryVariables>,
  ] = [
    {
      query: GEOREFERENCES_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const georeferences = study?.georeferences ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <GeoreferenceManager
      name={`${study.name} Georeferences`}
      georeferences={georeferences}
      dataTypes={[
        GeoreferenceDataType.Centre,
        GeoreferenceDataType.Outline,
        GeoreferenceDataType.Polygon,
        GeoreferenceDataType.Polyline,
        GeoreferenceDataType.Point,
      ]}
      limits={{
        [GeoreferenceDataType.Outline]: 1,
        [GeoreferenceDataType.Centre]: 1,
      }}
      parentType={GeoreferenceParent.Study}
      parentId={studyId}
      refetchQueries={refetchQueries}
    />
  );
}
