import ReactSelect from 'react-select';
import type { FieldPicsOutcropListQuery } from '~/apollo/generated/v3/graphql';

type Props = {
  value: string; // stringified outcropId
  onChange: (outcropId: string) => void;
  outcrops: FieldPicsOutcropListQuery['outcropList'];
  disabled?: boolean;
};

export function OutcropPicker({ value, onChange, outcrops, disabled }: Props) {
  const selectOptions = outcrops.map(oc => ({
    value: oc.id,
    label: `${oc.name} (${oc.id})`,
    isDisabled: oc.center === null,
  }));

  const selectValue = selectOptions.find(opt => String(opt.value) === value);

  return (
    <ReactSelect
      id="outcropId"
      isSearchable
      className="w-full"
      options={selectOptions}
      value={selectValue}
      onChange={val => onChange(String(val?.value ?? ''))}
      isClearable
      isDisabled={disabled}
    />
  );
}
