import { gql } from '~/apollo/client-v3';
import {} from '@apollo/client';
import * as fragments from '~/apollo/fragments';

export const SEARCH_OUTCROPS_OPTIONS = gql`
  query SearchOutcropsOptions(
    $grossDepositionalEnvironment: [String!]
    $depositionalEnvironment: [String!]
    $depositionalSubEnvironment: [String!]
    $architecturalElement: [String!]
    $climate: [String!]
    $basinType: [String!]
    $outcropCategory: [String!]
    $supportingObjectTypes: [String!]
    $geologyAge: [String!]
    $geologyType: [String!]
    $country: [String!]
    $textSearch: String
    $netToGross: [String!]
    $distanceToSourceArea: [String!]
  ) {
    searchOutcrops(
      grossDepositionalEnvironment: $grossDepositionalEnvironment
      depositionalEnvironment: $depositionalEnvironment
      depositionalSubEnvironment: $depositionalSubEnvironment
      architecturalElement: $architecturalElement
      climate: $climate
      basinType: $basinType
      outcropCategory: $outcropCategory
      supportingObjectTypes: $supportingObjectTypes
      geologyAge: $geologyAge
      geologyType: $geologyType
      country: $country
      textSearch: $textSearch
      netToGross: $netToGross
      distanceToSourceArea: $distanceToSourceArea
    ) {
      queryOptions {
        grossDepositionalEnvironment {
          count
          name
        }
        depositionalEnvironment {
          count
          name
        }
        depositionalSubEnvironment {
          count
          name
        }
        architecturalElement {
          count
          name
        }
        basinType {
          count
          name
        }
        climate {
          count
          name
        }
        geologyType {
          count
          name
        }
        geologyAge {
          count
          name
        }
        netToGross {
          count
          name
        }
        distanceToSourceArea {
          count
          name
        }
        country {
          count
          name
        }
      }
    }
  }
`;

interface QueryOptionField {
  name: string;
  count: number;
}
export interface QueryOptionsBrowse {
  geologyType: QueryOptionField[];
  architecturalElement: QueryOptionField[];
  basinType: QueryOptionField[];
  climate: QueryOptionField[];
  depositionalEnvironment: QueryOptionField[];
  depositionalSubEnvironment: QueryOptionField[];
  grossDepositionalEnvironment: QueryOptionField[];
  geologyAge: QueryOptionField[];
  country: QueryOptionField[];
  netToGross: QueryOptionField[];
  distanceToSourceArea: QueryOptionField[];
}

/** Same query as outcrop search but just the outcrop results */
export const OUTCROP_EXAMPLES = gql`
  query SearchOutcrops(
    $grossDepositionalEnvironment: [String]
    $depositionalEnvironment: [String]
    $depositionalSubEnvironment: [String]
    $architecturalElement: [String]
    $climate: [String]
    $basinType: [String]
    $outcropCategory: [String]
    $supportingObjectTypes: [String]
    $geologyAge: [String]
    $geologyType: [String]
    $country: [String]
    $textSearch: String
  ) {
    searchOutcrops(
      grossDepositionalEnvironment: $grossDepositionalEnvironment
      depositionalEnvironment: $depositionalEnvironment
      depositionalSubEnvironment: $depositionalSubEnvironment
      architecturalElement: $architecturalElement
      climate: $climate
      basinType: $basinType
      outcropCategory: $outcropCategory
      supportingObjectTypes: $supportingObjectTypes
      geologyAge: $geologyAge
      geologyType: $geologyType
      country: $country
      textSearch: $textSearch
    ) {
      outcrops {
        ...outcropParts
        shortDescription
        region {
          ...regionParts
        }
        thumbnail {
          ...fileParts
          signedUrl
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.fileParts}
  ${fragments.regionParts}
`;
