import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import type {
  DeletePaleoMapMutation,
  DeletePaleoMapMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { uploadPaleoMapRoute } from '~/paths';

const DELETE_PALEO_MAP = gql`
  mutation DeletePaleoMap($id: Int!) {
    deletePaleoMap(id: $id)
  }
`;

type Props = {
  paleoMapId: number;
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function DeletePaleoMap({
  paleoMapId,
  refetchQueries,
  children,
}: Props) {
  const navigate = useNavigate();
  const [deletePaleoMap] = useMutation<
    DeletePaleoMapMutation,
    DeletePaleoMapMutationVariables
  >(DELETE_PALEO_MAP, {
    variables: { id: paleoMapId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deletePaleoMap();
      toast.success('Paleomap deleted successfully.');
      navigate(uploadPaleoMapRoute(), { replace: true });
    } catch (err) {
      console.log('Error deleting paleomap', err);
      toast.error('There was a problem deleting the paleomap.');
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="The paleomap and all its pictures will be permanently deleted."
    >
      {onConfirm => children(onConfirm)}
    </Confirm>
  );
}
