import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type {
  DataHistoryPartsFragment,
  OutcropPanoramasRouteQuery,
  OutcropPanoramasRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { PublishedContainer } from '~/components/common/Published';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { GigaPanViewer } from '~/components/supportingObject/GigaPanViewer';
import { PanoramaListItem } from '~/components/supportingObject/PanoramaListItem';
import {
  outcropPanoramasTabRoute,
  studyPanoramasTabRoute,
  studyRoute,
} from '~/paths';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';

const OUTCROP_PANORAMAS_ROUTE = graphql(`
  query OutcropPanoramasRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      gigaPans {
        ...gigaPanParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
      }

      studies {
        ...studyParts

        dataHistory {
          ...dataHistoryParts
        }

        gigaPans {
          ...gigaPanParts
          pictures {
            ...pictureParts
            file {
              ...fileParts
              signedUrl
            }
          }
        }
      }
    }
  }
`);

export default function OutcropPanoramasRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery(OUTCROP_PANORAMAS_ROUTE, {
    variables: { outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<OutcropPanoramasRouteQueryVariables>,
  ] = [{ query: OUTCROP_PANORAMAS_ROUTE, variables: { outcropId } }];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  type Outcrop = OutcropPanoramasRouteQuery['outcropList'][number];
  type Study = Outcrop['studies'][number];

  const outcropGigaPans = R.pipe(
    () => outcrop?.gigaPans ?? [],
    R.sortBy(gigaPan => gigaPan.priority ?? Infinity),
  )();

  const studyGigaPans = (study: Study) =>
    study.gigaPans
      .filter(gp => gp.outcropTagId === outcropId)
      .sort(R.ascend(gp => gp.priority ?? Infinity));

  const studies = R.pipe(
    () => outcrop?.studies ?? [],
    studies => studies.filter(s => studyGigaPans(s).length > 0),
    R.sortBy(study => study.name),
  )();

  const gigaPanPictures = (gigaPan: Outcrop['gigaPans'][number]) =>
    R.pipe(
      () => gigaPan.pictures,
      R.sortBy(picture => picture.priority ?? Infinity),
    )();

  // See if any panos (or tagged panos) have a gigaPanHash set
  const hasAnyGigaPans =
    studies
      .flatMap(s => s.gigaPans)
      .concat(outcropGigaPans)
      .findIndex(gp => !!gp.gigaPanHash) > -1;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      {hasAnyGigaPans && (
        <p>
          Note: Please be aware that the Gigapans load extremely slowly because
          they are embedded externally from the Gigapan website. A better
          hosting solution based on the Safari database is under discussion.
        </p>
      )}

      {outcropGigaPans.map(gigaPan => (
        <div key={gigaPan.id}>
          <PublishedContainer published={gigaPan.published}>
            <GigaPanViewer
              hash={gigaPan.gigaPanHash}
              bookmarkable={{
                parentType: BookmarkParentType.Outcrop,
                parentId: outcropId,
                targetType: BookmarkTargetType.GigaPan,
                targetId: gigaPan.id,
                path: outcropPanoramasTabRoute(outcropId),
              }}
            />
            <PanoramaListItem
              pictures={gigaPanPictures(gigaPan)}
              bookmarkable={{
                parentType: BookmarkParentType.GigaPan,
                parentId: gigaPan.id,
                path: outcropPanoramasTabRoute(outcropId),
              }}
              refetchQueries={refetchQueries}
            />
          </PublishedContainer>
        </div>
      ))}

      {studies.map(study => (
        <Panel key={study.id}>
          <Panel.Heading className="flex justify-between items-start">
            <Panel.Title>
              <strong>{study.name}</strong>
              <StudyAuthor dataHistory={study.dataHistory} />
            </Panel.Title>

            <Link
              to={studyRoute(study.id)}
              target="_blank"
              className="btn btn-ghost btn-xs gap-1"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} /> View Study
            </Link>
          </Panel.Heading>
          <Panel.Body>
            {studyGigaPans(study).map(gigaPan => (
              <div key={gigaPan.id}>
                <PublishedContainer published={gigaPan.published}>
                  <GigaPanViewer
                    hash={gigaPan.gigaPanHash}
                    bookmarkable={{
                      parentType: BookmarkParentType.GigaPan,
                      parentId: study.id,
                      targetType: BookmarkTargetType.GigaPan,
                      targetId: gigaPan.id,
                      path: studyPanoramasTabRoute(study.id),
                    }}
                  />
                  <PanoramaListItem
                    pictures={gigaPanPictures(gigaPan)}
                    bookmarkable={{
                      parentType: BookmarkParentType.GigaPan,
                      parentId: gigaPan.id,
                      path: studyPanoramasTabRoute(study.id),
                    }}
                    refetchQueries={refetchQueries}
                  />
                </PublishedContainer>
              </div>
            ))}
          </Panel.Body>
        </Panel>
      ))}
    </div>
  );
}

function StudyAuthor({
  dataHistory,
}: {
  dataHistory?: DataHistoryPartsFragment | null;
}) {
  if (!dataHistory?.collectedBy && !dataHistory?.date) {
    return null;
  }

  return (
    <div className="space-x-2">
      {dataHistory.collectedBy && <span>{dataHistory.collectedBy}</span>}
      {dataHistory.date && (
        <span className="text-muted">{dataHistory.date}</span>
      )}
    </div>
  );
}
