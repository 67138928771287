import Papa from 'papaparse';
import type {
  AePartsFragment,
  ArchitecturalMeasurementInput,
  BulkUploadArchitecturalMeasurementInput,
  MeasurementPartsFragment,
  ProcessedBulkUploadArchitecturalMeasurement,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export const completenessTypes = [
  'complete',
  'partial',
  'incomplete',
  'undefined',
];

export const dataTypes = [
  'horizontal_width',
  'horizontal_length',
  'corrected_horizontal_width',
  'corrected_horizontal_length',
  'thickness',
  'corrected_thickness',
  'net_to_gross',
  'mean_paleo_current',
  'element_orientation',
  'strike_direction',
  'dip_direction',
  'dip_angle',
  'width_thickness_ratio',
  'trend',
  'plunge',
  'amplitude',
  'wavelength',
  'aperture',
  'displacement',
  'throw',
  'heave',
  'sinuosity',
  'distributary_count',
];

export const measurementQualities = [
  'level_1',
  'level_2',
  'level_3',
  'level_4',
  'undifferentiated',
];

export interface MeasurementFormValues {
  completeness: string;
  dataType: string;
  quality: string;
  value: string;
}

export const initialMeasurementFormValues = (
  m?: MeasurementPartsFragment,
): MeasurementFormValues => ({
  completeness: m?.completeness ?? '',
  dataType: m?.dataType ?? '',
  quality: m?.quality ?? '',
  value: `${m?.value ?? ''}`,
});

export const validationSchema = yup.object({
  completeness: yup.string().oneOf(completenessTypes).required(),
  dataType: yup.string().oneOf(dataTypes).required(),
  quality: yup.string().oneOf(measurementQualities).required(),
  value: yup.number().required(),
});

export const formValuesToMeasurementInput = (
  values: MeasurementFormValues,
  outcropArchitecturalElementId: ArchitecturalMeasurementInput['outcropArchitecturalElementId'],
): ArchitecturalMeasurementInput => ({
  outcropArchitecturalElementId,
  completeness: values.completeness,
  dataType: values.dataType,
  quality: values.quality,
  value: parseFloat(values.value),
});

// Bulk upload V3 :

export const bulkUploadMeasurementFields: Array<
  keyof ProcessedBulkUploadArchitecturalMeasurement
> = [
  'architecturalElementName',
  'completeness',
  'dataType',
  'quality',
  'value',
];

export const csvRowToBulkMeasurementInput = (
  row: string[],
): BulkUploadArchitecturalMeasurementInput => ({
  architecturalElementName: row[0],
  completeness: row[1],
  dataType: row[2],
  quality: row[3],
  value: parseFloat(row[4]),
});

// In this case nothing is being done with the fields, it simply removes __typename
export const formatProcessedMeasurementForBulkUpload = (
  m: ProcessedBulkUploadArchitecturalMeasurement,
) => ({
  architecturalElementName: m.architecturalElementName,
  completeness: m.completeness,
  dataType: m.dataType,
  quality: m.quality,
  value: m.value,
});

export type MeasurementExportFields = MeasurementPartsFragment & {
  outcropArchitecturalElement: Pick<AePartsFragment, 'name'>;
};

type MKey = keyof ProcessedBulkUploadArchitecturalMeasurement;
export const formatMeasurementForExport = (
  m: MeasurementExportFields,
): Partial<Record<MKey, ProcessedBulkUploadArchitecturalMeasurement[MKey]>> => {
  return {
    architecturalElementName: m.outcropArchitecturalElement.name,
    completeness: m.completeness,
    dataType: m.dataType,
    quality: m.quality,
    value: m.value,
  };
};

export const formatAsCSV = (measurements: MeasurementExportFields[]) =>
  Papa.unparse(measurements.map(formatMeasurementForExport), {
    columns: bulkUploadMeasurementFields,
    header: true,
    delimiter: ';',
  });

export function dataTypeUnitHint(dataType: string) {
  switch (dataType) {
    case 'horizontal_width':
    case 'horizontal_length':
    case 'thickness':
    case 'corrected_horizontal_width':
    case 'corrected_horizontal_length':
    case 'corrected_thickness':
    case 'amplitude':
    case 'wavelength':
    case 'displacement':
    case 'throw':
    case 'heave':
      return 'meters';

    case 'aperture':
      return 'millimeters';

    case 'net_to_gross':
      return 'number (0..1)';

    case 'element_orientation':
    case 'strike_direction':
    case 'dip_direction':
    case 'dip_angle':
    case 'mean_paleo_current':
    case 'trend':
    case 'plunge':
      return 'degrees';

    case 'width_thickness_ratio':
    case 'sinuosity':
    case 'distributary_count':
    default:
      return null;
  }
}

export function dataTypeUnit(dataType: string): string | null {
  switch (dataType) {
    case 'horizontal_width':
    case 'horizontal_length':
    case 'thickness':
    case 'corrected_horizontal_width':
    case 'corrected_horizontal_length':
    case 'corrected_thickness':
    case 'amplitude':
    case 'wavelength':
    case 'displacement':
    case 'throw':
    case 'heave':
      return 'm';

    case 'aperture':
      return 'mm';

    case 'net_to_gross':
      return '%';

    case 'element_orientation':
    case 'strike_direction':
    case 'dip_direction':
    case 'dip_angle':
    case 'mean_paleo_current':
    case 'trend':
    case 'plunge':
      return '°';

    case 'width_thickness_ratio':
      return 'N:1';

    case 'sinuosity':
    case 'distributary_count':
    default:
      return null;
  }
}
