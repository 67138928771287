import { useMutation, type PureQueryOptions } from '@apollo/client';
import {
  faArrowRightArrowLeft,
  faBan,
  faCheckCircle,
  faExternalLink,
  faFlag,
  faMapMarker,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import type { UploadOutcropMiniModelsPageQuery } from '~/apollo/generated/v3/graphql';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteUrlBasedSO } from '~/components/supportingObject/urlBasedSO/DeleteUrlBasedSO';
import { TransferMiniModelModal } from '~/components/supportingObject/urlBasedSO/TransferMiniModelModal';
import {
  UPDATE_MINI_MODEL,
  UpdateMiniModelForm,
} from '~/components/supportingObject/urlBasedSO/UpdateMiniModelForm';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { uploadOutcropSOPlacementsRoute } from '~/paths';
import { cn } from '~/utils/common';
import { initialMiniModel, toMiniModelInput } from '~/utils/modules/urlBasedSO';

type Outcrop = UploadOutcropMiniModelsPageQuery['outcropList'][number];
type MiniModel = Outcrop['miniModels'][number];

export function MiniModelItem({
  outcropId,
  miniModel,
  geologyTypes,
  refetchQueries,
}: {
  outcropId: number;
  miniModel: MiniModel;
  geologyTypes: string[];
  refetchQueries?: PureQueryOptions[];
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(!miniModel.approved);

  return (
    <Panel key={miniModel.id}>
      <Panel.Heading>
        <div className="flex justify-between gap-6 items-center w-full">
          <Button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            color="ghost"
            size="md"
          >
            <span>{miniModel.name}</span>
            <span className="text-muted text-sm">{miniModel.id}</span>
            <ExpandedIcon expanded={isOpen} />
          </Button>

          <div className="space-x-2">
            <MiniModelPublishedButtons miniModel={miniModel} />
            <span className="text-slate-300">|</span>
            <Link
              to={uploadOutcropSOPlacementsRoute(outcropId)}
              className={cn('btn btn-xs btn-soft', {
                'btn-success': miniModel.placement,
                'btn-error': !miniModel.placement,
              })}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={miniModel.placement ? faMapMarker : faBan}
              />
              {miniModel.placement
                ? 'Placed in Cesium'
                : 'Not Placed in Cesium'}
            </Link>
          </div>
        </div>
      </Panel.Heading>

      {(isOpen || isEditing) && (
        <Panel.Body>
          {isEditing ? (
            <UpdateMiniModelForm
              miniModel={miniModel}
              geologyTypes={geologyTypes ?? []}
              onUpdateSuccess={() => setIsEditing(false)}
            />
          ) : (
            <MiniModelsDetail miniModel={miniModel} />
          )}
        </Panel.Body>
      )}

      <Panel.Footer>
        <div className="flex justify-between items-center">
          <div className="shrink">
            {!isEditing && (
              <TransferMiniModelModal
                miniModelId={miniModel.id}
                refetchQueries={refetchQueries}
              >
                {showTransferModal => (
                  <Button
                    type="button"
                    onClick={showTransferModal}
                    color="ghost"
                    size="xs"
                    startIcon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
                  >
                    Transfer
                  </Button>
                )}
              </TransferMiniModelModal>
            )}
          </div>

          <div className="text-right space-x-1">
            <Button
              type="button"
              onClick={() => setIsEditing(!isEditing)}
              color="ghost"
              size="xs"
              startIcon={
                <FontAwesomeIcon icon={isEditing ? faBan : faPencil} />
              }
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>

            {!isEditing && (
              <DeleteUrlBasedSO
                soType="MiniModel"
                id={miniModel.id}
                isPlaced={!!miniModel.placement?.id}
                refetchQueries={refetchQueries}
              >
                {(deleteItem, isDeleting) => (
                  <Tooltip message="Delete item and any related data (e.g. placements, camera position)">
                    <Button
                      type="button"
                      color="ghost"
                      size="xs"
                      onClick={deleteItem}
                      disabled={isDeleting}
                      loading={isDeleting}
                      startIcon={<FontAwesomeIcon icon={faTrash} />}
                    >
                      Delete
                    </Button>
                  </Tooltip>
                )}
              </DeleteUrlBasedSO>
            )}
          </div>
        </div>
      </Panel.Footer>
    </Panel>
  );
}

function RowLabel({ children }: { children: ReactNode }) {
  return (
    <div className="lg:col-span-2 md:col-span-2 text-muted">{children}</div>
  );
}

function RowValue({ children }: { children: ReactNode }) {
  return <div className="lg:col-span-8 md:col-span-4">{children}</div>;
}

function MiniModelsDetail({ miniModel }: { miniModel: MiniModel }) {
  return (
    <div className="grid lg:grid-cols-10 md:grid-cols-6 gap-x-6 gap-y-0">
      <RowLabel>URL</RowLabel>
      <RowValue>
        <div className="break-all">
          <a
            href={miniModel.url}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            {miniModel.url}{' '}
            <FontAwesomeIcon
              icon={faExternalLink}
              className="text-sm text-slate-300"
            />
          </a>
        </div>
      </RowValue>

      <RowLabel>Collected</RowLabel>
      <RowValue>
        {miniModel.collectedBy}, {miniModel.yearCollected}
      </RowValue>

      <RowLabel>Equipment</RowLabel>
      <RowValue>{miniModel.equipment}</RowValue>

      <RowLabel>Scaniverse name</RowLabel>
      <RowValue>
        {miniModel.scaniverseName ?? (
          <span className="text-error">NOT SET</span>
        )}
      </RowValue>
      {miniModel.sketchfabName && (
        <>
          <RowLabel>Sketchfab name</RowLabel>
          <RowValue>{miniModel.sketchfabName}</RowValue>
        </>
      )}
      {miniModel.description && (
        <>
          <RowLabel>Description</RowLabel>
          <RowValue>{miniModel.description}</RowValue>
        </>
      )}
      {(miniModel.latitude || miniModel.longitude) && (
        <>
          <RowLabel>Coordinates</RowLabel>
          <RowValue>
            {miniModel.longitude ?? '???'}, {miniModel.latitude ?? '???'}
          </RowValue>
        </>
      )}
      {miniModel.comments && (
        <>
          <RowLabel>Comments</RowLabel>
          <RowValue>{miniModel.comments}</RowValue>
        </>
      )}
      {miniModel.architecturalElements?.length && (
        <>
          <RowLabel>Architectural Elements</RowLabel>
          <RowValue>
            <div className="flex flex-wrap gap-1">
              {R.sortBy(ae => ae, miniModel.architecturalElements).map(ae => (
                <Badge key={ae} color="ghost">
                  {ae}
                </Badge>
              ))}
            </div>
          </RowValue>
        </>
      )}
    </div>
  );
}

function MiniModelPublishedButtons({ miniModel }: { miniModel: MiniModel }) {
  const [updateMiniModel, { loading }] = useMutation(UPDATE_MINI_MODEL);

  const mmInput = toMiniModelInput(initialMiniModel(miniModel));

  async function approve() {
    try {
      await updateMiniModel({
        variables: {
          id: miniModel.id,
          miniModel: { ...mmInput, approved: true, readyForApproval: false },
        },
      });
      toast.success('Mini-model approved.');
    } catch (err) {
      console.log('Error approving mini-model', err);
      toast.error('Something went wrong, please reload the page and try again');
    }
  }

  async function unapprove() {
    try {
      await updateMiniModel({
        variables: {
          id: miniModel.id,
          miniModel: { ...mmInput, approved: false },
        },
      });
      toast.info('Mini-model unapproved.');
    } catch (err) {
      console.log('Error approving mini-model', err);
      toast.error('Something went wrong, please reload the page and try again');
    }
  }

  return (
    <>
      {miniModel.readyForApproval && (
        <Badge color="warning">
          <FontAwesomeIcon icon={faFlag} /> Ready for Approval
        </Badge>
      )}

      {miniModel.approved ? (
        <Button
          type="button"
          color="success"
          size="xs"
          variant="soft"
          startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
          onClick={unapprove}
          loading={loading}
        >
          Approved
        </Button>
      ) : (
        <Button
          type="button"
          color="error"
          size="xs"
          variant="soft"
          startIcon={<FontAwesomeIcon icon={faBan} />}
          onClick={approve}
          loading={loading}
        >
          Not Approved
        </Button>
      )}
    </>
  );
}
