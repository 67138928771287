import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components/ui/button';
import {
  Link,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';

import * as fragments from '~/apollo/fragments';
import type {
  GroupUpdateRouteQuery,
  GroupUpdateRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { DeleteGroup } from '~/components/upload/lithostrat/group/DeleteGroup';
import * as routes from '~/paths';

export const UPDATE_GROUP_PAGE = gql`
  query GroupUpdateRoute($id: Int!) {
    lithostratGroupList(id: $id) {
      ...lithostratGroupParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      formations {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
        startAge {
          ...lithostratAgeParts
        }
        lithostratType {
          ...lithostratTypeParts
        }
      }
      wikiPage {
        ...lithostratWikiPageParts
        keyParameters {
          ...keyParametersParts
        }
        regions {
          id
          name
        }
      }
    }
  }

  ${fragments.lithostratGroupParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratTypeParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.keyParametersParts}
`;

type OutletContext = {
  group: GroupUpdateRouteQuery['lithostratGroupList'][number];
  refetchQueries: PureQueryOptions[];
};

export default function GroupUpdateRoute() {
  const params = useParams<{ groupId: string }>();
  if (!params.groupId) throw new Error('groupId param required');
  const groupId = parseInt(params.groupId);

  const navigate = useNavigate();

  const { data, loading } = useQuery<
    GroupUpdateRouteQuery,
    GroupUpdateRouteQueryVariables
  >(UPDATE_GROUP_PAGE, {
    variables: { id: groupId },
  });

  const refetchQueries: [PureQueryOptions<GroupUpdateRouteQueryVariables>] = [
    {
      query: UPDATE_GROUP_PAGE,
      variables: { id: groupId },
    },
  ];

  const groupList = data?.lithostratGroupList ?? [];
  const group = groupList.find(g => g.id === groupId);

  useBreadcrumb(
    'routes/upload/lithostrat/group/$groupId',
    group?.name ?? 'Update Group',
  );

  function handleDeleteSuccess() {
    navigate(routes.uploadLithostratGroupListRoute());
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!group) return <NotFound />;

  const outletContext: OutletContext = { group, refetchQueries };

  return (
    <>
      <Link
        to={routes.lithostratGroupRoute(group.id)}
        className="btn btn-primary btn-sm float-right gap-1"
        target="_blank"
      >
        Wiki Page <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading>{group.name}</PageHeading>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={routes.uploadLithostratGroupUpdateRoute(group.id)} end>
          Group
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratGroupUpdateGeologicalAgeRoute(group.id)}
        >
          Geological Age
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratGroupUpdateExamplesRoute(group.id)}
        >
          Examples
        </NavTabs.Tab>
        <NavTabs.Tab to={routes.uploadLithostratGroupFormationsRoute(group.id)}>
          Formations
        </NavTabs.Tab>
        <NavTabs.Tab to={routes.uploadLithostratGroupUpdateWikiRoute(group.id)}>
          Wiki
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />

      <div className="text-center mt-6">
        <DeleteGroup id={group.id} onDeleteSuccess={handleDeleteSuccess}>
          {(onDelete, loading) => (
            <Confirm
              onConfirm={onDelete}
              submitButtonColor="error"
              text="This group will be permanently deleted. Linked formations will NOT be deleted."
            >
              {confirmDelete => (
                <Button
                  type="button"
                  variant="link"
                  onClick={confirmDelete}
                  disabled={loading}
                  className="text-error hover:text-error gap-1"
                >
                  <FontAwesomeIcon icon={faTrash} />
                  Delete Group
                </Button>
              )}
            </Confirm>
          )}
        </DeleteGroup>
      </div>
    </>
  );
}

export function useGroupUpdateOutletContext() {
  return useOutletContext<OutletContext>();
}
