import { stringify } from 'qs';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageIntro } from '~/components/layout/PageIntro';
import { AnalogueSearchForm } from '~/components/outcrop/AnalogueSearchForm';
import { useQueryString } from '~/hooks/routing';
import type {
  AnalogueSearchDefaults,
  AnalogueSearchFormValues,
} from '~/utils/modules/analogueSearch';

export default function SearchRoute() {
  const [, setSearchParams] = useSearchParams();
  const { query } = useQueryString<AnalogueSearchDefaults>();
  const [initialQuery] = useState(query);

  useBreadcrumb('routes/search', 'Search Analogues');

  // Callback to fire when the search is updated
  // This stringifies the values and pushes it to the browser's querystring
  const handleSearchChange = useCallback(
    (query: AnalogueSearchFormValues) => {
      const nextQuery = stringify(query);

      const url = new URL(window.location.toString());
      url.search = nextQuery;
      const length = url.toString().length;

      const maxUrlLength = 2048;
      if (length <= maxUrlLength) {
        setSearchParams(url.searchParams, { preventScrollReset: true });
      } else {
        console.log(
          `Search query too long to push to URL: ${length} characters (max ${maxUrlLength})`,
        );
      }
    },
    [setSearchParams],
  );

  return (
    <>
      <PageIntro
        pageName="intro_browse"
        iconPath="/assets/images/page/icon-outcrop.jpg"
      />

      <AnalogueSearchForm
        initialQuery={initialQuery}
        onSearchChange={handleSearchChange}
      />
    </>
  );
}
