import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_VARIOGRAM,
  DELETE_VARIOGRAM,
  UPDATE_VARIOGRAM,
} from '~/apollo/operations/supportingObject';
import type {
  UploadStudyUpdateVariogramsTabQuery,
  UploadStudyUpdateVariogramsTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const VARIOGRAMS_TAB = gql`
  query UploadStudyUpdateVariogramsTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      variograms {
        ...variogramParts
        outcropTag {
          ...outcropParts
        }
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
      }
    }
  }

  ${fragments.studyParts}
  ${fragments.variogramParts}
  ${fragments.outcropParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateStudyVariogramsRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery<
    UploadStudyUpdateVariogramsTabQuery,
    UploadStudyUpdateVariogramsTabQueryVariables
  >(VARIOGRAMS_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateVariogramsTabQueryVariables>,
  ] = [
    {
      query: VARIOGRAMS_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const variograms = study?.variograms ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SupportObjectManager
      studyId={studyId}
      supportObject={variograms}
      supportObjectType="variogram"
      parentType="VARIOGRAM"
      createMutation={CREATE_VARIOGRAM}
      updateMutation={UPDATE_VARIOGRAM}
      deleteMutation={DELETE_VARIOGRAM}
      refetchQueries={refetchQueries}
    />
  );
}
