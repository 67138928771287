import * as R from 'ramda';
import type { ReportGigaPanPartsFragment } from '~/apollo/generated/v3/graphql';
import { GigaPanViewer } from '~/components/supportingObject/GigaPanViewer';
import { ReportItemPicture } from './ReportItemPicture';

type Props = {
  gigaPan: ReportGigaPanPartsFragment;
};

export function ReportItemGigaPan({ gigaPan }: Props) {
  const pictures = R.sortWith(
    [R.ascend(R.propOr(Infinity, 'priority'))],
    gigaPan.pictures,
  );
  const thumbnail = pictures.length > 0 ? pictures[0] : null;

  return (
    <>
      {gigaPan.gigaPanHash && <GigaPanViewer hash={gigaPan.gigaPanHash} />}
      {thumbnail && <ReportItemPicture picture={thumbnail} />}
    </>
  );
}
