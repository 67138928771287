import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import type { LithostratAgeHierarchyFullQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { ucwords } from '~/utils/text';

export const LITHOSTRAT_AGE_HIERARCHY = gql`
  query LithostratAgeHierarchyFull {
    lithostratAgeHierarchyFull {
      eons {
        name
        eras {
          name
          systems {
            name
            series {
              name
              stages {
                name
              }
            }
          }
        }
      }
    }
  }
`;

// Note in Formik state these are actually prefixed as `${ageName}.${fieldName}`
// This satisfies typescript for pulling the values in and out, though.
type FormValues = {
  eon: string;
  era: string;
  system: string;
  series: string;
  stage: string;
  comments: string;
};

type HierarchyKey = 'eon' | 'era' | 'system' | 'series' | 'stage';
const hierarchy: HierarchyKey[] = ['eon', 'era', 'system', 'series', 'stage'];

type Props = {
  ageName:
    | 'startAge'
    | 'endAge'
    // "age" is used for paleomaps where only a single age is specified
    | 'age';
};

export function LithostratAgeFormFields({ ageName }: Props) {
  const { data, loading } = useQuery<LithostratAgeHierarchyFullQuery>(
    LITHOSTRAT_AGE_HIERARCHY,
    {},
  );
  const schema = data?.lithostratAgeHierarchyFull ?? { eons: [] };

  const { values, setFieldValue } = useFormikContext<FormValues>();
  const fieldName = (name: string): string => `${ageName}.${name}`;

  const getValue = (key: keyof FormValues) =>
    // @ts-ignore The field names are actually prefixed, I don't know of a better way to do this
    values[ageName][key] ?? '';

  const setValue = (key: keyof FormValues, value: string) =>
    setFieldValue(fieldName(key), value);

  const eonValue = getValue('eon');
  const eraValue = getValue('era');
  const systemValue = getValue('system');
  const seriesValue = getValue('series');
  // const stageValue = getValue('stage');

  const eons = schema.eons ?? [];
  const eras = eons.find(e => e.name === eonValue)?.eras ?? [];
  const systems = eras.find(e => e.name === eraValue)?.systems ?? [];
  const serieses = systems.find(s => s.name === systemValue)?.series ?? [];
  const stages = serieses.find(s => s.name === seriesValue)?.stages ?? [];

  const getNames = (items: Array<{ name: string }>) => items.map(i => i.name);
  const options: Record<HierarchyKey, string[]> = {
    eon: getNames(eons),
    era: getNames(eras),
    system: getNames(systems),
    series: getNames(serieses),
    stage: getNames(stages),
  };

  const handleHierarchyChange =
    (key: HierarchyKey) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(key, event.target.value);

      const keyIdx = hierarchy.indexOf(key);
      hierarchy.forEach((h, hIdx) => {
        if (hIdx > keyIdx) {
          setValue(h, '');
        }
      });
    };

  return (
    <>
      {hierarchy.map(field => (
        <Field
          key={field}
          name={fieldName(field)}
          label={ucwords(field)}
          component={FormikField}
          type="select"
          options={options[field].map(opt => ({ value: opt, label: opt }))}
          onChange={handleHierarchyChange(field)}
          disabled={!options[field].length || loading}
        />
      ))}

      <Field
        name={fieldName('comments')}
        label="Comments"
        component={FormikField}
      />
    </>
  );
}
