import type { Event, LabelGraphics, MapProjection } from 'cesium';
import {
  CameraEventType,
  Cartesian2,
  Cartesian3,
  Cartographic,
  Cesium3DTileset,
  Math as CesiumMath,
  Color,
  ConstantPositionProperty,
  ConstantProperty,
  CustomDataSource,
  defined,
  DistanceDisplayCondition,
  Ellipsoid,
  Entity,
  Fullscreen,
  GeometryInstance,
  Globe,
  GroundPolylineGeometry,
  GroundPolylinePrimitive,
  HeadingPitchRoll,
  HeightReference,
  HorizontalOrigin,
  Ion,
  JulianDate,
  KeyboardEventModifier,
  Matrix4,
  NearFarScalar,
  PinBuilder,
  PointGraphics,
  PolylineMaterialAppearance,
  PrimitiveCollection,
  Rectangle,
  Resource,
  sampleTerrainMostDetailed,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Terrain,
  Transforms,
  VerticalOrigin,
  Viewer,
} from 'cesium';
import { envVars } from '~/environment';
import type { LatLngHeightHPR } from './CesiumViewer';
import type { PolygonEntityStruct } from './limeParse';

type LatLngHeight = {
  latitude: number;
  longitude: number;
  height: number;
  heading?: number;
  pitch?: number;
  roll?: number;
};

export type entityView = {
  entity: Entity;
  show: boolean;
};

export type CameraParams = {
  position: Cartesian3;
  orientation: HeadingPitchRoll;
};

export type DataSourcePoint = {
  latitude: number;
  longitude: number;
  name: string;
  id?: number;
};

type TransformParams =
  | {
      location: Cartographic;
      orientation: HeadingPitchRoll;
    }
  | { none: boolean }
  | { local: boolean };

export enum TerrainProvider {
  None = 'NONE',
  World = 'WORLD',
  Bathymetry = 'BATHYMETRY',
}

export type TilesetParams = {
  transform: TransformParams;
  assetToken: string;
  assetTokenExpiry?: number;
  localPath: string;
  defaultCamera?: CameraParams;
};

export async function setupCesiumViewer(
  element: HTMLElement,
  terrainProvider: TerrainProvider,
  enableCollision: boolean = false,
  infoBox: boolean = false,
): Promise<Viewer> {
  Ion.defaultAccessToken = envVars.VITE_ION_ASSET_TOKEN;
  let terrain: undefined | Terrain;
  let globe: false | Globe | undefined = undefined;
  let mapProjection: undefined | MapProjection;
  let projectionPicker = false;
  switch (terrainProvider) {
    case TerrainProvider.None:
      terrain = undefined;
      // hide globe instead of no globe for cesium navigation
      globe = new Globe();
      projectionPicker = true;
      globe.show = false;
      break;
    case TerrainProvider.World:
      // mapProjection = new MapProjection()
      globe = new Globe(Ellipsoid.WGS84);
      terrain = Terrain.fromWorldTerrain();
      break;
    case TerrainProvider.Bathymetry:
      // mapProjection = new Cesium.MapProjection()
      // globe = new Cesium.Globe(mapProjection.ellipsoid)
      terrain = Terrain.fromWorldTerrain();
      break;
  }

  const viewerOptions: Viewer.ConstructorOptions = {
    terrain: terrain,
    animation: false,
    baseLayerPicker: false,
    fullscreenButton: true,
    fullscreenElement: element,
    geocoder: false,
    homeButton: false,
    infoBox: infoBox,
    globe: globe,
    mapProjection: mapProjection,
    sceneModePicker: false,
    projectionPicker: projectionPicker,
    selectionIndicator: false,
    navigationHelpButton: false,
    navigationInstructionsInitiallyVisible: false,
    scene3DOnly: true,
    clockViewModel: undefined,
    timeline: false,
  };
  const viewer = new Viewer(element, viewerOptions);
  if (terrainProvider === TerrainProvider.None) {
    updateCameraControls(viewer);
    panCamera(viewer);
  }
  if (!enableCollision) {
    viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
  }
  if (infoBox) {
    viewer.infoBox.frame.removeAttribute('sandbox');
    viewer.infoBox.frame.src = 'about:blank';
  }

  if (viewerOptions.fullscreenButton) {
    // fullscreen resizes the canvas so when it comes back from full screen
    // we need to resize it back to the original size
    const canvas = viewer.canvas;
    const height = canvas.height;
    const width = canvas.width;
    const fullScreenHandler = () => {
      if (Fullscreen.fullscreen) {
        canvas.height = height;
        canvas.width = width;
      }
      Fullscreen.requestFullscreen(element);
    };

    viewer.fullscreenButton.viewModel.command.beforeExecute.addEventListener(
      fullScreenHandler,
    );
  }
  return viewer;
}

export async function updateCameraControls(viewer: Viewer) {
  const cameraController = viewer.scene.screenSpaceCameraController;
  cameraController.rotateEventTypes = [];
  cameraController.tiltEventTypes = [CameraEventType.LEFT_DRAG];

  cameraController.inertiaZoom = 0;
  viewer.scene.screenSpaceCameraController.maximumMovementRatio = 0.2;
  // @ts-ignore
  viewer.scene.screenSpaceCameraController._maximumRotateRate = 0.4;
  // @ts-ignore
  viewer.scene.screenSpaceCameraController._zoomFactor = 0.5;
}

async function panCamera(viewer: Viewer) {
  let looking: boolean = false;
  let startMousePosition: Cartesian2;
  let mousePosition: Cartesian2;
  let moveRate = 15;

  const handler = new ScreenSpaceEventHandler(viewer.canvas);

  handler.setInputAction(function (
    movement: ScreenSpaceEventHandler.PositionedEvent,
  ) {
    looking = true;
    const position = movement.position;
    viewer.scene.screenSpaceCameraController.enableZoom = false;
    mousePosition = startMousePosition = Cartesian2.clone(position);
    const picked = viewer.scene.pick(position);
    const pickPosition = viewer.scene.pickPosition(position);
    if (picked && picked.content.tileset) {
      const camera_position = viewer.camera.position;
      const distance = Math.abs(
        Cartesian3.distance(camera_position, pickPosition),
      );
      moveRate = distance / 50;
    } else {
      moveRate = 15;
    }
  }, ScreenSpaceEventType.RIGHT_DOWN);

  handler.setInputAction(function (
    movement: ScreenSpaceEventHandler.MotionEvent,
  ) {
    mousePosition = movement.endPosition;
  }, ScreenSpaceEventType.MOUSE_MOVE);

  handler.setInputAction(function (
    position: ScreenSpaceEventHandler.PositionedEvent,
  ) {
    looking = false;
    viewer.scene.screenSpaceCameraController.enableZoom = true;
  }, ScreenSpaceEventType.RIGHT_UP);

  viewer.clock.onTick.addEventListener(function (clock) {
    const camera = viewer.camera;

    if (looking) {
      const width = viewer.canvas.clientWidth;
      const height = viewer.canvas.clientHeight;

      // Coordinate (0.0, 0.0) will be where the mouse was clicked.
      const x = -(mousePosition.x - startMousePosition.x) / width;
      const y = (mousePosition.y - startMousePosition.y) / height;
      // const cameraHeight = Cesium.Ellipsoid.WGS84.cartesianToCartographic(
      //   camera.position
      // ).height;
      // const moveRate = cameraHeight / 100.0;
      camera.moveRight(x * moveRate);
      camera.moveUp(y * moveRate);
    }
  });
}

export async function loadTileset(
  modelUrl: string,
  assetToken: string,
  assetTokenExpiry: number = 60,
  tokenFetcher: Function = () => undefined,
  transform: TransformParams = { none: true },
): Promise<Cesium3DTileset | undefined> {
  const retryCallback: Resource.RetryCallback = async (resource, error) => {
    if (error?.statusCode === 401 || error?.statusCode === 403) {
      const newToken = await new Promise(resolve => {
        tokenFetcher(resolve);
      });
      if (typeof newToken !== 'undefined' && typeof resource !== 'undefined') {
        resource.queryParameters['token'] = newToken;
        return true;
      }
    }
    return false;
  };
  const resource = new Resource({
    url: modelUrl,
    queryParameters: {
      token: assetToken,
      tokenExpiry: assetTokenExpiry,
    },
    retryCallback: retryCallback,
    retryAttempts: 5,
  });
  const modelMatrix = transformToModelMatrix(transform);
  return Cesium3DTileset.fromUrl(resource, { modelMatrix: modelMatrix });
}

export const transformToModelMatrix = (transform: TransformParams): Matrix4 => {
  if ('orientation' in transform && 'location' in transform) {
    const position = Cartesian3.fromRadians(
      transform.location.longitude,
      transform.location.latitude,
      transform.location.height,
    );
    const hpr = new HeadingPitchRoll(
      CesiumMath.toRadians(transform.orientation.heading),
      CesiumMath.toRadians(transform.orientation.pitch),
      CesiumMath.toRadians(transform.orientation.roll),
    );
    return Transforms.headingPitchRollToFixedFrame(position, hpr);
  }

  console.log('No location or hpr included, adding 0,0 fixed frame');
  if ('local' in transform) {
    return Transforms.eastNorthUpToFixedFrame(Cartesian3.fromDegrees(0, 0));
  }
  return Transforms.eastNorthUpToFixedFrame(Cartesian3.fromDegrees(0, 0));
};

export async function addTilesetToViewer(
  viewer: Viewer,
  tileset: TilesetParams,
  zoomTo = true,
) {
  const t = await loadTileset(
    tileset.localPath,
    tileset.assetToken,
    tileset.assetTokenExpiry,
    () => {},
    tileset.transform,
  );
  viewer.scene.primitives.add(t);
  if (t && 'local' in tileset.transform && zoomTo) {
    viewer.zoomTo(t);
  }
  if (t && 'location' in tileset.transform && zoomTo) {
    viewer.zoomTo(t);
  }
  return t;
}

export async function enableTilesetPlacement(
  viewer: Viewer,
  tileset: Cesium3DTileset,
  callback: (placement: LatLngHeightHPR) => void,
) {
  const handler = new ScreenSpaceEventHandler(viewer.canvas);
  handler.setInputAction(
    function (movement: ScreenSpaceEventHandler.PositionedEvent) {
      const cartesian =
        viewer.scene.pickPosition(movement.position) ||
        viewer.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid,
        );
      if (!cartesian) {
        return;
      }
      const cartographic = Cartographic.fromCartesian(cartesian);
      const currentHPR = reverseRotationMatrix(tileset.modelMatrix);
      const newModelMatrix = updateTransformationMatrixTranslation(
        tileset.modelMatrix,
        CesiumMath.toDegrees(cartographic.longitude),
        CesiumMath.toDegrees(cartographic.latitude),
        cartographic.height,
        // 0, // mamually set cartographic.height to 0 since pickposition might provide incorrect results
        // https://github.com/CesiumGS/cesium/issues/4368
      );
      tileset.modelMatrix = newModelMatrix;
      callback({
        latitude: CesiumMath.toDegrees(cartographic.latitude),
        longitude: CesiumMath.toDegrees(cartographic.longitude),
        height: 0, // same as above
        heading: currentHPR.heading,
        pitch: currentHPR.pitch,
        roll: currentHPR.roll,
      });
    },
    ScreenSpaceEventType.LEFT_DOWN,
    KeyboardEventModifier.ALT,
  );
}

export function convertFromCartesian(
  cartesian: Cartesian3 | undefined,
): LatLngHeight {
  if (!cartesian) {
    return {
      latitude: 0,
      longitude: 0,
      height: 0,
      heading: 0,
      pitch: 0,
      roll: 0,
    } satisfies LatLngHeightHPR;
  }
  const cartographic = Cartographic.fromCartesian(cartesian);
  return {
    latitude: CesiumMath.toDegrees(cartographic.latitude),
    longitude: CesiumMath.toDegrees(cartographic.longitude),
    height: cartographic.height,
  };
}

export function updateTransformationMatrixTranslation(
  mtx: Matrix4,
  longitude: number,
  latitude: number,
  height: number,
) {
  const newTranslation = Cartesian3.fromDegrees(
    longitude,
    latitude,
    height,
    Ellipsoid.WGS84,
    new Cartesian3(),
  );
  const updatedTransformation = Matrix4.setTranslation(
    mtx,
    newTranslation,
    new Matrix4(),
  );
  return updatedTransformation;
}

export function reverseTransformationMatrix(mtx: Matrix4) {
  const translationMatrix = Matrix4.getTranslation(mtx, new Cartesian3());
  const cartesian = Cartographic.fromCartesian(translationMatrix);
  return {
    longitude: CesiumMath.toDegrees(cartesian.longitude),
    latitude: CesiumMath.toDegrees(cartesian.latitude),
    height: cartesian.height,
  };
}

export function reverseRotationMatrix(mtx: Matrix4): HeadingPitchRoll {
  return Transforms.fixedFrameToHeadingPitchRoll(
    mtx,
    Ellipsoid.WGS84,
    Transforms.eastNorthUpToFixedFrame,
    new HeadingPitchRoll(),
  );
}

export function modifyhpr(
  tilesetLocation: LatLngHeight,
  hpr: HeadingPitchRoll,
) {
  const modelMatrix = Transforms.headingPitchRollToFixedFrame(
    Cartesian3.fromDegrees(
      tilesetLocation.longitude,
      tilesetLocation.latitude,
      tilesetLocation.height,
    ),
    hpr,
  );
  return modelMatrix;
}
export async function addPolygonsToViewer(
  viewer: Viewer,
  polygons: PolygonEntityStruct[],
): Promise<Entity[]> {
  const polygonEntities = polygons.map(polygon => {
    return viewer.entities.add({
      polygon: {
        hierarchy: polygon.heirarchy,
        material: polygon.material,
        perPositionHeight: polygon.perPositionHeight,
      },
    });
  });
  return polygonEntities;
}

export function getCamera(viewer: Viewer | null): CameraParams | null {
  if (!viewer) {
    return null;
  }
  const camera = viewer.camera;
  const position = camera.position;
  const heading = camera.heading;
  const pitch = camera.pitch;
  const roll = camera.roll;
  return {
    position: position,
    orientation: new HeadingPitchRoll(heading, pitch, roll),
  };
}

export function getCameraCartographic(
  viewer: Viewer | null,
): LatLngHeightHPR | null {
  if (!viewer) {
    return null;
  }
  const camera = viewer.camera;
  const cartographic = Cartographic.fromCartesian(camera.position);
  const heading = camera.heading;
  const pitch = camera.pitch;
  const roll = camera.roll;
  return {
    latitude: CesiumMath.toDegrees(cartographic.latitude),
    longitude: CesiumMath.toDegrees(cartographic.longitude),
    height: cartographic.height,
    heading,
    pitch,
    roll,
  };
}

export function resetCameraOrientation(viewer: Viewer | null): Viewer | null {
  if (!viewer) {
    return null;
  }
  const camera = viewer.camera;
  camera.flyTo({
    destination: camera.position,
    orientation: {
      heading: 0,
    },
  });
  return viewer;
}

export function updateTilesetModelMatrixWithPositionHpr(
  tileset: Cesium3DTileset,
  values: LatLngHeightHPR,
): Cesium3DTileset {
  const latLngHeight = {
    latitude: values.latitude,
    longitude: values.longitude,
    height: values.height,
  };
  const hpr = new HeadingPitchRoll(
    CesiumMath.toRadians(values.heading),
    CesiumMath.toRadians(values.pitch),
    CesiumMath.toRadians(values.roll),
  );
  const hprMatrix = modifyhpr(latLngHeight, hpr);
  const mm = updateTransformationMatrixTranslation(
    hprMatrix,
    values.longitude,
    values.latitude,
    values.height,
  );
  tileset.modelMatrix = mm;
  return tileset;
}

export function getCurrentZoomDistance(viewer: Viewer | null): number | null {
  if (!viewer) {
    return null;
  }
  const cameraPositionCart = viewer.scene.camera.positionCartographic;
  const globeHeight = viewer.scene.globe.getHeight(cameraPositionCart);
  // const detailedHeight = await sampleTerrainMostDetailed(viewer.terrainProvider, [cameraPositionCart])
  if (globeHeight) {
    return cameraPositionCart.height - globeHeight;
  }
  return null;
}

export async function updateHeightByTerrain(
  viewer: Viewer,
  position: Cartographic,
): Promise<Cartographic> {
  const prevHeight = position.height;
  const detailedPosition = await sampleTerrainMostDetailed(
    viewer.terrainProvider,
    [position],
  );
  if (!detailedPosition[0].height) {
    detailedPosition[0].height = prevHeight;
  }
  return detailedPosition[0];
}

export function setDepthTest(viewer: Viewer | null, clippingEnabled: boolean) {
  if (!viewer) {
    console.log('viewer not set yet');
    return;
  }
  viewer.scene.globe.depthTestAgainstTerrain = clippingEnabled;
}

export function setShowGlobe(viewer: Viewer | null, showGlobe: boolean) {
  if (!viewer) {
    console.log('viewer not set yet');
    return;
  }
  viewer.scene.globe.show = showGlobe;
  viewer.scene.skyAtmosphere.show = showGlobe;
  viewer.scene.skyBox.show = showGlobe;
  viewer.scene.sun.show = showGlobe;
}

export function createCustomDataSourcePoints(
  dataPoints: DataSourcePoint[],
): CustomDataSource {
  const customDataSource = new CustomDataSource('customDataSource');
  dataPoints.forEach((dp: DataSourcePoint) => {
    let entity = new Entity({
      id: dp.id?.toString(),
      name: dp.name,
      position: new ConstantPositionProperty(
        Cartesian3.fromDegrees(dp.longitude, dp.latitude),
      ),
    });
    entity.point = new PointGraphics();
    entity.point.pixelSize = new ConstantProperty(5);
    entity.point.color = new ConstantProperty(Color.RED.withAlpha(0.9));
    customDataSource.entities.add(entity);
  });
  return customDataSource;
}

export function addCustomPinStyle(
  dataSource: CustomDataSource,
): Event.RemoveCallback {
  const pinBuilder = new PinBuilder();
  const pin200 = pinBuilder.fromText('200+', Color.BLACK, 48).toDataURL();
  const pin100 = pinBuilder.fromText('100+', Color.BLACK, 48).toDataURL();
  const pin50 = pinBuilder.fromText('50+', Color.RED, 48).toDataURL();
  const pin40 = pinBuilder.fromText('40+', Color.ORANGE, 48).toDataURL();
  const pin30 = pinBuilder.fromText('30+', Color.YELLOW, 48).toDataURL();
  const pin20 = pinBuilder.fromText('20+', Color.GREEN, 48).toDataURL();
  const pin10 = pinBuilder.fromText('10+', Color.BLUE, 48).toDataURL();

  const singleDigitPins = new Array(9);
  for (let i = 0; i < singleDigitPins.length; ++i) {
    singleDigitPins[i] = pinBuilder
      .fromText(`${i + 1}`, Color.VIOLET, 48)
      .toDataURL();
  }

  const removeListener = dataSource.clustering.clusterEvent.addEventListener(
    function (clusteredEntities, cluster) {
      cluster.label.show = false;
      cluster.billboard.show = true;
      cluster.billboard.id = cluster.label.id;
      cluster.billboard.verticalOrigin = VerticalOrigin.BOTTOM;

      if (clusteredEntities.length >= 200) {
        cluster.billboard.image = pin200;
      } else if (clusteredEntities.length >= 100) {
        cluster.billboard.image = pin100;
      } else if (clusteredEntities.length >= 50) {
        cluster.billboard.image = pin50;
      } else if (clusteredEntities.length >= 40) {
        cluster.billboard.image = pin40;
      } else if (clusteredEntities.length >= 30) {
        cluster.billboard.image = pin30;
      } else if (clusteredEntities.length >= 20) {
        cluster.billboard.image = pin20;
      } else if (clusteredEntities.length >= 10) {
        cluster.billboard.image = pin10;
      } else {
        cluster.billboard.image = singleDigitPins[clusteredEntities.length - 1];
      }
    },
  );
  return removeListener;
}

// export function createTilesetCollection(viewer: Viewer, tilesets: Cesium3DTileset[]): PrimitiveCollection {
//   const collection = new PrimitiveCollection();
//   for (let ts of tilesets) {
//     ts.properties
//     collection.add(tileset);
//   }
// }

export async function cameraInViewHandler(
  viewer: Viewer,
  dataSource: CustomDataSource,
  callback: (ids: number[]) => void,
) {
  const camera = viewer.camera;

  function inViewCheck() {
    const inViewIds: number[] = [];

    const calculatedEntities = calculateInViewEntities(viewer, dataSource);
    for (let e of calculatedEntities) {
      const ent = dataSource.entities.getById(e.entity.id);
      if (ent) {
        ent.show = e.show;
      }
      if (e.show && e.entity.id && parseFloat(e.entity.id)) {
        inViewIds.push(parseFloat(e.entity.id));
      }
    }
    callback(inViewIds);
  }
  return camera.moveEnd.addEventListener(debounce(inViewCheck, 500));
}

export function calculateInViewEntities(
  viewer: Viewer,
  dataSource: CustomDataSource,
): entityView[] {
  const cameraRectangle = viewer.camera.computeViewRectangle();
  const entitiesVisibility = dataSource.entities.values.map(entity => {
    const currentPosition = entity.position?.getValue(viewer.clock.currentTime);
    if (cameraRectangle && currentPosition) {
      if (
        Rectangle.contains(
          cameraRectangle,
          Cartographic.fromCartesian(currentPosition),
        )
      ) {
        return { entity: entity, show: true };
      }
    }
    return { entity: entity, show: false };
  });
  return entitiesVisibility;
}

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export function toggleEntityVisibility(viewer: Viewer, id: string) {
  const entity = viewer.entities.getById(id);
  if (entity) {
    entity.show = !entity?.show;
  }
  return entity;
}

export function toggleEntityLabelVisibility(viewer: Viewer | null, id: string) {
  if (!viewer) {
    return;
  }
  const entity = viewer.entities.getById(id);
  if (entity) {
    // @ts-expect-error (only way to get entity children)
    if (entity._children) {
      // @ts-expect-error (only way to get entity children)
      entity._children.forEach(e => {
        if (e.label) {
          let currentLabelShow = e.label.show?.getValue(new JulianDate());
          if (currentLabelShow === undefined) {
            currentLabelShow = true;
          }
          e.label.show = new ConstantProperty(!currentLabelShow);
        }
      });
    }
    if (entity.label) {
      let currentLabelShow = entity.label.show?.getValue(new JulianDate());
      if (currentLabelShow === undefined) {
        currentLabelShow = true;
      }
      entity.label.show = new ConstantProperty(!currentLabelShow);
    }
  }
}

export function addBillboard(
  viewer: Viewer,
  id: string,
  panelUrl: string,
  position: LatLngHeightHPR,
  label: string,
  width?: number,
  height?: number,
) {
  const entityOptions: Entity.ConstructorOptions = {
    id: id,
    position: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      position.height,
    ),
    billboard: {
      image: panelUrl,
      sizeInMeters: true,
      heightReference: HeightReference.CLAMP_TO_3D_TILE,
      scaleByDistance: new ConstantProperty(
        new NearFarScalar(1.5e3, 0.1, 3.5e5, 0.0),
      ),
      horizontalOrigin: HorizontalOrigin.LEFT,
      verticalOrigin: VerticalOrigin.BOTTOM,
    },
  };
  if (width && height && entityOptions.billboard) {
    entityOptions.billboard.width = width;
    entityOptions.billboard.height = height;
  }
  const entity = viewer.entities.add(entityOptions);
  return entity;
}

export function addPoint(
  viewer: Viewer,
  id: string,
  position: LatLngHeightHPR,
  label?: string,
  url?: string,
  iframe?: boolean,
) {
  const entityOptions: Entity.ConstructorOptions = {
    id: id,
    name: label,
    position: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      position.height,
    ),
    point: {
      heightReference: HeightReference.CLAMP_TO_3D_TILE,
      pixelSize: 10,
      color: Color.YELLOW,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      distanceDisplayCondition: new DistanceDisplayCondition(0, 5e3),
    },
  };
  if (iframe) {
    entityOptions.description = `
    <iframe width="100%" height="100%" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="${url}"> </iframe>
    `;
  } else {
    entityOptions.description = `
    <div style="width: 100%; min-height: 300px; height: 100%; background: url(${url}); background-size: contain; background-repeat: no-repeat "></div>
    `;
  }
  if (label) {
    entityOptions.label = {
      text: label,
      font: '20px sans-serif',
      pixelOffset: new Cartesian2(0.0, -1.0),
      pixelOffsetScaleByDistance: new NearFarScalar(0, 1, 3e5, 150),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      distanceDisplayCondition: new DistanceDisplayCondition(0, 5e3),
    };
  }
  const entity = viewer.entities.add(entityOptions);
  return entity;
}

export async function addIconPoint(
  viewer: Viewer,
  id: string,
  icon: string | null | undefined,
  position: LatLngHeightHPR,
  label?: string,
  url?: string,
  iframe?: boolean,
): Promise<Entity> {
  icon = icon || 'marker';

  const entityOptions: Entity.ConstructorOptions = {
    id: id,
    name: label,
    position: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      position.height,
    ),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_3D_TILE,
      // image: pin.toDataURL(),
      image: `/assets/images/so-icons/${icon}.svg`,
      height: 35,
      width: 35,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      distanceDisplayCondition: new DistanceDisplayCondition(0, 5e3),
    },
  };
  if (iframe) {
    entityOptions.description = `
    <iframe width="100%" height="100%" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="${url}"> </iframe>
    `;
  } else {
    entityOptions.description = `
    <div style="width: 100%; min-height: 300px; height: 100%; background: url(${url}); background-size: contain; background-repeat: no-repeat "></div>
    `;
  }
  if (label) {
    entityOptions.label = {
      text: label,
      font: '20px sans-serif',
      pixelOffset: new Cartesian2(0, 1),
      pixelOffsetScaleByDistance: new NearFarScalar(0, -40, 3e5, -20),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      distanceDisplayCondition: new DistanceDisplayCondition(0, 5e3),
    };
  }
  const entity = viewer.entities.add(entityOptions);
  return entity;
}

export function addBillboardPoint(
  viewer: Viewer,
  id: string,
  panelUrl: string,
  position: LatLngHeightHPR,
  label: string,
  width?: number,
  height?: number,
) {
  const parent = viewer.entities.add({
    id: id,
    position: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      position.height,
    ),
  });
  // const billboard = addBillboard(viewer, `${id}-bb`, panelUrl, position, label, width, height)
  // billboard.parent = parent;
  const point = addPoint(viewer, `${id}-point`, position);
  point.parent = parent;
  return parent;
}

export function addInfoBoxPoint(
  viewer: Viewer,
  id: string,
  panelUrl: string,
  position: LatLngHeightHPR,
  label: string,
  iframe: boolean,
  width?: number,
  height?: number,
) {
  const parent = viewer.entities.add({
    id: id,
    position: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      position.height,
    ),
  });
  // const billboard = addBillboard(viewer, `${id}-bb`, panelUrl, position, label, width, height)
  // billboard.parent = parent;
  const point = addPoint(
    viewer,
    `${id}-point`,
    position,
    label,
    panelUrl,
    iframe,
  );
  point.parent = parent;
  return parent;
}

export async function addInfoBoxIconPoint(
  viewer: Viewer,
  id: string,
  panelUrl: string,
  position: LatLngHeightHPR,
  label: string,
  iframe: boolean,
  iconName: string | null | undefined,
  width?: number,
  height?: number,
): Promise<Entity> {
  const parent = viewer.entities.add({
    id: id,
    position: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      position.height,
    ),
  });
  // const billboard = addBillboard(viewer, `${id}-bb`, panelUrl, position, label, width, height)
  // billboard.parent = parent;
  const point = await addIconPoint(
    viewer,
    `${id}-point`,
    iconName,
    position,
    label,
    panelUrl,
    iframe,
  );
  point.parent = parent;
  return parent;
}

export async function enableEntityClickPlacement(
  viewer: Viewer,
  entity: Entity,
  callback: (placement: LatLngHeightHPR) => void,
): Promise<ScreenSpaceEventHandler> {
  const handler = new ScreenSpaceEventHandler(viewer.canvas);
  handler.setInputAction(
    function (movement: ScreenSpaceEventHandler.PositionedEvent) {
      const cartesian =
        viewer.scene.pickPosition(movement.position) ||
        viewer.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid,
        );
      if (!cartesian) {
        return;
      }
      const cartographic = Cartographic.fromCartesian(cartesian);
      entity.position = new ConstantPositionProperty(cartesian);
      //@ts-ignore (_children is private but theres no other way to access entity children)
      entity._children.forEach(e => {
        e.position = new ConstantPositionProperty(cartesian);
      });
      callback({
        latitude: CesiumMath.toDegrees(cartographic.latitude),
        longitude: CesiumMath.toDegrees(cartographic.longitude),
        height: cartographic.height,
        heading: 0,
        pitch: 0,
        roll: 0,
      });
    },
    ScreenSpaceEventType.LEFT_DOWN,
    KeyboardEventModifier.ALT,
  );
  return handler;
}

export function setBillboardSize(
  viewer: Viewer | null,
  entity: Entity | null,
  width: number,
  height: number,
) {
  if (!entity || !entity.billboard || !viewer) {
    return;
  }
  entity.billboard.height = new ConstantProperty(height);
  entity.billboard.width = new ConstantProperty(width);
}

export function convertCartesian3Tollhhpr(
  cartesian: Cartesian3,
): LatLngHeightHPR {
  const cartographic = Cartographic.fromCartesian(cartesian);
  return {
    latitude: CesiumMath.toDegrees(cartographic.latitude),
    longitude: CesiumMath.toDegrees(cartographic.longitude),
    height: cartographic.height,
    heading: 0,
    pitch: 0,
    roll: 0,
  } satisfies LatLngHeightHPR;
}

export function enableEntityInfoBox(
  viewer: Viewer,
  callback: (entity: Entity | null) => void,
): ScreenSpaceEventHandler {
  const handler = new ScreenSpaceEventHandler(viewer.canvas);
  handler.setInputAction(function (
    movement: ScreenSpaceEventHandler.PositionedEvent,
  ) {
    const pickedFeature = viewer.scene.pick(movement.position);
    if (!defined(pickedFeature)) {
      return;
    }
    if (!pickedFeature.id) {
      return;
    }
    viewer.selectedEntity = new Entity({
      id: `infobox-entity`,
      description: pickedFeature.description,
    });
  }, ScreenSpaceEventType.LEFT_DOWN);
  return handler;
}

export function updateEntityLabel(entity: Entity, label: string) {
  // @ts-expect-error (only way to get entity children)
  if (entity._children) {
    // @ts-expect-error (only way to get entity children)
    entity._children.forEach(e => {
      if (e.label) {
        e.label.text = new ConstantProperty(label);
      }
    });
  }
  if (entity.label) {
    entity.label.text = new ConstantProperty(label);
  }
}

export function addOutcropCenterPoint(
  viewer: Viewer,
  center: { latitude: number; longitude: number },
): Entity {
  const centerEntity = viewer.entities.add({
    position: Cartesian3.fromDegrees(center.longitude, center.latitude),
    point: {
      heightReference: HeightReference.CLAMP_TO_GROUND,
      pixelSize: 10,
      color: Color.RED,
    },
  });
  return centerEntity;
}

export function addOutcropOutline(
  viewer: Viewer,
  outline: { latitude: number; longitude: number }[],
): PrimitiveCollection {
  const instance = new GeometryInstance({
    geometry: new GroundPolylineGeometry({
      positions: Cartesian3.fromDegreesArray(
        outline
          .map(o => {
            return [o.longitude, o.latitude];
          })
          .flat(),
      ),
      width: 4.0,
    }),
    id: 'outcrop-outline',
  });
  viewer.scene.groundPrimitives.add(
    new GroundPolylinePrimitive({
      geometryInstances: instance,
      appearance: new PolylineMaterialAppearance(),
    }),
  );
  return viewer.scene.groundPrimitives;
}
