import * as R from 'ramda';
import React from 'react';
import type { LithostratWikiPagePartsFragment } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { LastUpdated } from '~/components/common/LastUpdated';
import { Prose } from '~/components/common/Prose';

type WikiSectionProps = {
  title: string;
  content?: string | null;
};

const WikiSection: React.FC<WikiSectionProps> = ({
  title,
  content,
}: WikiSectionProps) => {
  // Hide the section entirely if the content is empty (or "...")
  const isEmpty = R.anyPass([
    R.isNil,
    R.isEmpty,
    R.equals<any>('<p>...</p>'),
    R.equals<any>('...'),
  ])(content);

  if (isEmpty) {
    return null;
  }

  return (
    <div>
      <Heading level={3}>{title}</Heading>
      {content && <Prose dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};

type Props = {
  wiki: LithostratWikiPagePartsFragment;
};

export function WikiDescriptionText({ wiki }: Props) {
  return (
    <div className="space-y-6">
      {wiki.introduction && wiki.alternativeNames ? (
        // Combine intro & alternative names if they're both set
        <WikiSection
          title="Description and Alternative Names"
          content={`${wiki.introduction} ${wiki.alternativeNames}`}
        />
      ) : (
        <>
          <WikiSection title="Description" content={wiki.introduction} />
          <WikiSection
            title="Alternative Names"
            content={wiki.alternativeNames}
          />
        </>
      )}
      <WikiSection
        title="Distribution and Thickness"
        content={wiki.distributionThickness}
      />
      <WikiSection
        title="Lithology and Depositional Environments"
        content={wiki.lithologyDepositional}
      />
      <WikiSection
        title="Litho- and Sequence Stratigraphy"
        content={wiki.lithologySequence}
      />
      <WikiSection
        title="Analogues and Notable Aspects"
        content={wiki.analogues}
      />
      <WikiSection title="Key References" content={wiki.literatureReferences} />

      <LastUpdated date={wiki.updatedAt} />
    </div>
  );
}
