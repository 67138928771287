import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as R from 'ramda';
import { Button } from '~/components/ui/button';
import type { AesTabQuery } from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { saveAsCSV } from '~/utils/export';
import { formatAsCSV as formatAEsAsCSV } from '~/utils/modules/architecturalElement';
import { formatAsCSV as formatMeasurementsAsCSV } from '~/utils/modules/architecturalMeasurement';

type Props = {
  children: (showModal: () => void) => void;
  studyId: number;
  aes: AesTabQuery['studyList'][number]['architecturalElements'];
};

export function ExportToCSVModal({ children, studyId, aes }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const exportAEs = () => {
    const csvFormatted = formatAEsAsCSV(aes);
    const date = moment().format('YYYY-MM-DD HHmmss');
    const filename = `Study ${studyId} AEs - ${date}.csv`;

    saveAsCSV(csvFormatted, filename);
  };

  const exportMeasurements = () => {
    const measurements = R.pipe(R.pluck('measurements'), R.flatten)(aes);
    const csvFormatted = formatMeasurementsAsCSV(measurements);
    const date = moment().format('YYYY-MM-DD HHmmss');
    const filename = `Study ${studyId} Measurements - ${date}.csv`;

    saveAsCSV(csvFormatted, filename);
  };

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Export Architectural Elements">
          <div className="space-y-4">
            <p>
              Use the buttons below to create two export files for this study:
              one for the AEs themselves, and one for their measurements.
            </p>
            <p>
              If you are planning on re-importing these AEs, you'll need to
              import the measurements again as well, or they will be lost when
              the original AEs are deleted.
            </p>

            <div className="grid lg:grid-cols-2 gap-4">
              <div>
                <Button
                  type="button"
                  color="primary"
                  block
                  onClick={exportAEs}
                  className="gap-1"
                >
                  <FontAwesomeIcon icon={faFileDownload} /> Export Arch Elems
                </Button>
              </div>

              <div>
                <Button
                  type="button"
                  color="primary"
                  block
                  onClick={exportMeasurements}
                  className="gap-1"
                >
                  <FontAwesomeIcon icon={faFileDownload} /> Export Measurements
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" color="ghost" onClick={hideModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
