import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { LoginButton } from '~/components/layout/Navbar/LoginButton';
import { useAuth } from '~/contexts/auth';
import { dashboardRoute } from '~/paths';

export default function LoginPage() {
  useBreadcrumb('routes/login', 'Login');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(dashboardRoute());
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="lg:w-1/2 mx-auto">
      <Panel>
        <Panel.Heading>
          <Panel.Title>Login</Panel.Title>
        </Panel.Heading>
        <Panel.Body className="space-y-4">
          <p>Please log in to SafariDB:</p>

          <div className="text-center">
            <LoginButton
              postLoginRedirect={searchParams.get('redirectTo')}
              className="btn btn-primary btn-lg"
            />
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
