import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { graphql } from '~/apollo/generated/v3';
import type { UploadStudyUpdateWellLogsTabQueryVariables } from '~/apollo/generated/v3/graphql';
import {
  CREATE_WELL_LOG,
  DELETE_WELL_LOG,
  UPDATE_WELL_LOG,
} from '~/apollo/operations/supportingObject';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

export const STUDY_WELL_LOGS_TAB = graphql(`
  query UploadStudyUpdateWellLogsTab($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      wellLogs {
        ...wellLogParts
        outcropTag {
          ...outcropParts
        }
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        georeference {
          ...georeferenceParts
        }
      }
    }
  }
`);

export default function UpdateStudyWellLogsRoute() {
  const ctx = useUpdateStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery(STUDY_WELL_LOGS_TAB, {
    variables: { studyId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadStudyUpdateWellLogsTabQueryVariables>,
  ] = [
    {
      query: STUDY_WELL_LOGS_TAB,
      variables: { studyId },
    },
  ];

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);
  const wellLogs = study?.wellLogs ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SupportObjectManager
      studyId={studyId}
      supportObject={wellLogs}
      supportObjectType="wellLog"
      parentType="WELL_LOG"
      createMutation={CREATE_WELL_LOG}
      updateMutation={UPDATE_WELL_LOG}
      deleteMutation={DELETE_WELL_LOG}
      refetchQueries={refetchQueries}
    />
  );
}
