import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FieldProps } from 'formik';
import * as R from 'ramda';
import React from 'react';

import * as fragments from '~/apollo/fragments';
import type {
  RegionSelectQuery,
  RegionSelectQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

const REGION_SELECT = gql`
  query RegionSelect {
    regionList {
      ...regionParts
    }
  }

  ${fragments.regionParts}
`;

type Props = FieldProps & {
  disabled: boolean;
};

export const RegionSelect: React.FC<Props> = ({
  form,
  field,
  disabled = false,
  ...props
}: Props) => {
  const { data, loading } = useQuery<
    RegionSelectQuery,
    RegionSelectQueryVariables
  >(REGION_SELECT, {});
  const dataRegions = data?.regionList ?? [];
  const regionList = R.sortBy(R.prop('name'), dataRegions);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    form.setFieldValue(field.name, parseInt(e.target.value) ?? '');

  return (
    <FormikSelectField
      form={form}
      field={field}
      {...props}
      onChange={onChange}
      options={regionList.map(region => ({
        value: region.id,
        label: region.name,
      }))}
      disabled={disabled || loading}
    />
  );
};
