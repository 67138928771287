import { Outlet } from 'react-router';
import { Role } from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { adminPageRoute, adminUploadRoute, companyAdminRoute } from '~/paths';

export default function AdminTemplate() {
  useBreadcrumb('routes/admin', 'Administration');

  return (
    <>
      <PageHeading>Administration</PageHeading>

      <div>
        <NavTabs className="mb-2">
          <RoleSecured roles={[Role.RoleAdmin]}>
            <NavTabs.Tab to={adminPageRoute()}>Pages</NavTabs.Tab>
          </RoleSecured>
          <RoleSecured roles={[Role.RoleCompanyAdmin]}>
            <NavTabs.Tab to={companyAdminRoute()}>Company Admin</NavTabs.Tab>
          </RoleSecured>
          <RoleSecured roles={[Role.RoleAdmin]}>
            <NavTabs.Tab to={'/admin/superadmin'}>Superuser Admin</NavTabs.Tab>
          </RoleSecured>
          <RoleSecured roles={[Role.RoleAdmin]}>
            <NavTabs.Tab to={adminUploadRoute()}>Upload</NavTabs.Tab>
          </RoleSecured>
        </NavTabs>
      </div>

      <Outlet />
    </>
  );
}
