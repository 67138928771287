import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { ADD_BOOKMARK_TO_COLLECTION } from '~/apollo/operations/bookmark';
import type {
  AddBookmarkToCollectionMutation,
  AddBookmarkToCollectionMutationVariables,
} from '~/apollo/generated/v3/graphql';

type RenderFnArgs = {
  addToCollection: () => Promise<void>;
  loading: boolean;
};

type Props = {
  children: (args: RenderFnArgs) => JSX.Element;
  bookmarkId: number;
  collectionId: number;
  refetchQueries: PureQueryOptions[];
};

export function AddToCollection({
  children,
  bookmarkId,
  collectionId,
  refetchQueries,
}: Props) {
  const [addToCollectionMutation, { loading }] = useMutation<
    AddBookmarkToCollectionMutation,
    AddBookmarkToCollectionMutationVariables
  >(ADD_BOOKMARK_TO_COLLECTION, {
    variables: { bookmarkId, collectionId },
    refetchQueries,
    awaitRefetchQueries: true,
  });

  async function handleAdd() {
    try {
      await addToCollectionMutation();
      toast.success('Bookmark added to collection successfully.');
    } catch (err) {
      console.log('Error adding bookmark to collection', err);
      toast.error(
        'There was a problem adding the bookmark to the collection. Please reload the page and try again.',
      );
    }
  }

  return children({ addToCollection: handleAdd, loading });
}
