import { cn } from '~/utils/common';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React from 'react';
import type { Props as ReactSelectProps, SingleValue } from 'react-select';
import ReactSelect from 'react-select';
import { FormLabel } from './FormLabel';

type TValue = string | number | boolean;

type SelectOption<T extends TValue> = {
  value: T;
  label: string;
  disabled?: boolean;
};

type ReactSelectOption<T extends TValue> = {
  value: T;
  label: string;
  isDisabled?: boolean;
};

type Props<T extends TValue> = FieldProps & {
  options: SelectOption<T>[];
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  label?: React.ReactNode;
  helpText?: string;
  renderInput?: (input: JSX.Element) => JSX.Element;
  reactSelectProps?: ReactSelectProps;
  onChange?: (option: T | null) => void;
};

export function FormikReactSelectField<T extends TValue>({
  field,
  form,
  options,
  loading = false,
  disabled = false,
  required = false,
  isInvalid = false,
  label = null,
  renderInput,
  helpText,
  reactSelectProps,
  onChange,
}: Props<T>) {
  function handleChange(option: SingleValue<SelectOption<T>>) {
    const value = option?.value || null;
    if (onChange) {
      onChange(value);
    } else {
      form.setFieldValue(field.name, value);
    }
  }

  const selectedValue = options.find(opt => opt.value === field.value);

  // Convert generic `disabled` used everywhere else into react-select's `isDisabled` prop
  const selectOptions = options.map(opt => {
    const nextOpt: ReactSelectOption<T> = {
      value: opt.value,
      label: opt.label,
    };
    if (opt.disabled) nextOpt.isDisabled = true;
    return nextOpt;
  });

  const input = (
    <ReactSelect
      value={selectedValue}
      isLoading={loading}
      isDisabled={disabled}
      onChange={handleChange}
      options={selectOptions}
      isSearchable={reactSelectProps?.isSearchable ?? true}
      isClearable={reactSelectProps?.isClearable ?? true}
    />
  );

  return (
    <div
      className={cn('form-group', {
        'has-error': isInvalid,
        'text-error': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
}
