import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '~/components/ui/button';
import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';

const gigaPanUrl = (hash: string) =>
  `https://www.gigapan.com/gigapans/${hash}/options/nosnapshots/iframe/flash.html?height=725`;

type Props = {
  hash?: string | null;
  bookmarkable?: TargetBookmarksManagerModalProps;
};

export function GigaPanViewer({ hash = null, bookmarkable }: Props) {
  if (!hash) return null;

  const fullscreenRef = React.createRef<HTMLIFrameElement>();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (fullscreenRef.current) {
      fullscreenRef.current.requestFullscreen();
    }
  }

  return (
    <>
      <div className="flex justify-between items-baseline gap-6">
        <Button
          type="button"
          color="primary"
          size="sm"
          className="gap-1"
          style={{ marginBottom: '0.5%', marginTop: '2%' }}
          onClick={handleClick}
        >
          View fullscreen <FontAwesomeIcon icon={faExpand} />
        </Button>

        {bookmarkable && (
          <div className="text-right">
            <TargetBookmarksManagerModal {...bookmarkable} />
          </div>
        )}
      </div>

      <iframe
        title="GigaPan Preview"
        ref={fullscreenRef}
        src={gigaPanUrl(hash)}
        frameBorder="0"
        height="750"
        scrolling="no"
        width="100%"
      />
    </>
  );
}
