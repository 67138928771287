import { FeaturedLinks } from '~/components/home/FeaturedLinks';
import { Hero } from '~/components/home/Hero';
import { TourPages, TourSection } from '~/components/home/TourPages';
import { Footer } from '~/components/layout/Footer';
import { Navbar } from '~/components/layout/Navbar';
import { PageLoader } from '~/components/page/PageLoader';

export default function HomeRoute() {
  return (
    <>
      <div className="h-[90vh] md:h-[75vh] relative">
        <Hero />
        <Navbar transparent />
      </div>

      <div className="bg-slate-50 mt-0">
        <div className="container mx-auto max-w-7xl">
          <div className="py-8">
            <FeaturedLinks />
          </div>

          <div className="bg-white p-8 shadow-sm">
            <TourPages />
          </div>

          <TourSection>
            <PageLoader pageName="tour-faq" />
          </TourSection>
        </div>
      </div>

      <Footer />
    </>
  );
}
