import { useFormContext } from 'react-hook-form';
import { Checkbox } from '~/components/ui/forms/Checkbox';
import { TextInput } from '~/components/ui/forms/TextInput';
import { TinyMceInput } from '~/components/ui/forms/TinyMceInput';
import type { PageFormValues } from '~/utils/modules/page';

export function PageFormFields() {
  const { register, setValue, watch } = useFormContext<PageFormValues>();

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          <TextInput {...register('title')} label="Title" required />
        </div>
        <div>
          <TextInput
            {...register('pageName')}
            label="Internal page name"
            required
          />
        </div>
      </div>

      <TinyMceInput
        {...register('content')}
        value={watch('content')}
        onChange={value => setValue('content', value)}
        name="content"
        label="Content"
        config={{ height: 300 }}
        required
      />

      <Checkbox {...register('secured')} label="Secured" />
    </div>
  );
}
