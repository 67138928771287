import { useFormikContext } from 'formik';
import React from 'react';
import type { DepositionalHierarchyQuery } from '~/apollo/generated/v3/graphql';
import type {
  DataSearchFormValues,
  DataSearchOption,
  DataSearchOptions,
} from '~/utils/modules/dataSearch';
import { collateHierarchyOptions } from '~/utils/modules/dataSearch';
import { ageSortOrder } from '~/utils/modules/lithostratAge';
import type { DataSearchFilterProps } from './DataSearchFilter';
import { DataSearchFilter } from './DataSearchFilter';

export function KeyParamsFilters({
  hierarchy,
  options,
}: {
  hierarchy: DepositionalHierarchyQuery['depositionalHierarchyFull'];
  options: DataSearchOptions;
}) {
  const { values } = useFormikContext<DataSearchFormValues>();

  const hierarchyOptions = React.useMemo(() => {
    return collateHierarchyOptions(hierarchy);
  }, [hierarchy]);

  const getCount = (fieldOptions: DataSearchOption[]) => (value: string) => {
    return fieldOptions.find(opt => opt.name === value)?.count ?? 0;
  };

  type PickableOption = DataSearchFilterProps['options'][number];
  const mapOption =
    (countFn: ReturnType<typeof getCount>) =>
    (name: string): PickableOption => ({
      name,
      count: countFn(name),
    });

  return (
    <div className="space-y-4">
      <DataSearchFilter
        fieldName="geologyType"
        title="Geology Type"
        options={options.geologyType}
        values={values.geologyType}
        defaultCollapsed={!values.geologyType.length}
      />
      <DataSearchFilter
        fieldName="outcropCategory"
        title="Analogue Type"
        options={options.outcropCategory}
        values={values.outcropCategory}
        defaultCollapsed={!values.outcropCategory.length}
      />
      <DataSearchFilter
        fieldName="grossDepositionalEnvironment"
        title="Gross Depositional Env."
        options={hierarchyOptions.gde.map(
          mapOption(getCount(options.grossDepositionalEnvironment)),
        )}
        values={values.grossDepositionalEnvironment}
        defaultCollapsed={!values.grossDepositionalEnvironment.length}
      />
      <DataSearchFilter
        fieldName="depositionalEnvironment"
        title="Depositional Environment"
        options={hierarchyOptions.de.map(
          mapOption(getCount(options.depositionalEnvironment)),
        )}
        values={values.depositionalEnvironment}
        defaultCollapsed={!values.depositionalEnvironment.length}
      />
      <DataSearchFilter
        fieldName="depositionalSubEnvironment"
        title="Subenvironment"
        options={hierarchyOptions.se.map(
          mapOption(getCount(options.depositionalSubEnvironment)),
        )}
        values={values.depositionalSubEnvironment}
        defaultCollapsed={!values.depositionalSubEnvironment.length}
      />

      <DataSearchFilter
        fieldName="basinType"
        title="Basin Type"
        options={options.basinType}
        values={values.basinType}
        defaultCollapsed={!values.basinType.length}
      />
      <DataSearchFilter
        fieldName="climate"
        title="Climate"
        options={options.climate}
        values={values.climate}
        defaultCollapsed={!values.climate.length}
      />
      <DataSearchFilter
        fieldName="netToGross"
        title="Net to Gross"
        options={options.netToGross}
        values={values.netToGross}
        defaultCollapsed={!values.netToGross.length}
      />
      <DataSearchFilter
        fieldName="distanceToSourceAreaDesc"
        title="Estimated Distance to Source Area"
        options={options.distanceToSourceAreaDesc}
        values={values.distanceToSourceAreaDesc}
        defaultCollapsed={!values.distanceToSourceAreaDesc.length}
      />
      <DataSearchFilter
        fieldName="startAge"
        title="Outcrop Age"
        options={options.startAge}
        values={values.startAge}
        defaultCollapsed={!values.startAge.length}
        sortOrder={ageSortOrder}
      />
      {/* <DataSearchFilter
        fieldName="country"
        title="Country"
        options={options.country}
        values={values.country}
        defaultCollapsed={!values.country.length}
        sortOrder={countryList}
      /> */}
    </div>
  );
}
