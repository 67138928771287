import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import { lithostratWikiPageParts } from '~/apollo/fragments';
import type {
  KeyParametersPartsFragment,
  LithostratWikiPagePartsFragment,
  RegionPartsFragment,
  UpdateLithostratWikiPageMutation,
  UpdateLithostratWikiPageMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { KeyParametersParent } from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { KeyParametersUpload } from '~/components/upload/keyParameter/KeyParametersUpload';
import { LithostratWikiRegionManager } from '~/components/upload/lithostrat/wiki/LithostratWikiRegionManager';
import type { LithostratWikiPageFormValues } from '~/utils/modules/lithostrat';
import {
  initialLithostratWikiPage,
  lithostratWikiPageValidationSchema,
} from '~/utils/modules/lithostrat';
import { LithostratWikiFormFields } from './LithostratWikiPageFormFields';

const UPDATE_LITHOSTRAT_WIKI_PAGE = gql`
  mutation UpdateLithostratWikiPage(
    $id: Int!
    $wikiPage: LithostratWikiPageInput!
  ) {
    updateLithostratWikiPage(id: $id, wikiPage: $wikiPage) {
      ...lithostratWikiPageParts
    }
  }

  ${lithostratWikiPageParts}
`;

type LithostratWiki = LithostratWikiPagePartsFragment & {
  keyParameters: KeyParametersPartsFragment[];
  regions: Array<Pick<RegionPartsFragment, 'id' | 'name'>>;
};

type Props = {
  wikiPage: LithostratWiki;
  refetchQueries: PureQueryOptions[];
};

export function UpdateLithostratWikiForm({ wikiPage, refetchQueries }: Props) {
  const [updateLithostratWikiPage, { loading, error }] = useMutation<
    UpdateLithostratWikiPageMutation,
    UpdateLithostratWikiPageMutationVariables
  >(UPDATE_LITHOSTRAT_WIKI_PAGE);

  async function handleSubmit(values: LithostratWikiPageFormValues) {
    try {
      await updateLithostratWikiPage({
        variables: {
          id: wikiPage.id,
          wikiPage: values,
        },
      });
      toast.success('Wiki page updated successfully.');
    } catch (err) {
      toast.error('There was a problem saving the wiki page.');
      console.log('Error updating parent query:', err);
    }
  }

  const showKeyParameters = wikiPage.geologyType;

  return (
    <>
      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-7">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialLithostratWikiPage(wikiPage)}
            validationSchema={lithostratWikiPageValidationSchema}
          >
            <Form>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>Update Wiki Page</Panel.Title>
                </Panel.Heading>
                <Panel.Body className="space-y-4">
                  <LithostratWikiFormFields />
                  <FormErrors graphQLError={error} />
                </Panel.Body>
                <Panel.Footer className="text-right">
                  <Button type="submit" color="primary" loading={loading}>
                    Save
                  </Button>
                </Panel.Footer>
              </Panel>
            </Form>
          </Formik>
        </div>

        <div className="lg:col-span-5 space-y-4">
          <Panel>
            <Panel.Heading>
              <Panel.Title>Linked Regions</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <LithostratWikiRegionManager
                lithostratWikiId={wikiPage.id}
                currentRegions={wikiPage.regions}
                refetchQueries={refetchQueries}
              />
            </Panel.Body>
          </Panel>

          <Panel>
            <Panel.Heading>
              <Panel.Title>Key Parameters</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              {!showKeyParameters && (
                <p>
                  Key parameters will be available after saving the wiki page
                  and setting a geology type.
                </p>
              )}
              {showKeyParameters && (
                <KeyParametersUpload
                  parentType={KeyParametersParent.LithostratWikiPage}
                  parentId={wikiPage.id}
                  geologyTypes={wikiPage.geologyType ?? []}
                  refetchQueries={refetchQueries}
                  keyParameters={wikiPage.keyParameters}
                />
              )}
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </>
  );
}
