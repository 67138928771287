import type { ReactNode } from 'react';
import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';

type FullWidthContextValue = {
  isFullWidth: boolean;
  setIsFullWidth: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FullWidthContext = createContext<FullWidthContextValue>({
  isFullWidth: false,
  setIsFullWidth: () => {},
});

export function FullWidthProvider({ children }: { children: ReactNode }) {
  const [isFullWidth, setIsFullWidth] = useState(false);

  return (
    <FullWidthContext.Provider value={{ isFullWidth, setIsFullWidth }}>
      <Fragment>{children}</Fragment>
    </FullWidthContext.Provider>
  );
}

export function useFullWidthContext() {
  return useContext(FullWidthContext);
}

/** Sets a page to full width and unsets it when leaving */
export function useFullWidthPage() {
  const { isFullWidth, setIsFullWidth } = useFullWidthContext();

  useEffect(() => {
    if (!isFullWidth) {
      setIsFullWidth(true);
    }
    return () => {
      if (isFullWidth) {
        setIsFullWidth(false);
      }
    };
  }, [isFullWidth, setIsFullWidth]);
}
