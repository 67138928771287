import type { PureQueryOptions } from '@apollo/client';
import type {
  FilePartsFragment,
  PicturePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { BookmarkTargetType } from '~/apollo/generated/v3/graphql';
import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import type { BookmarkableSOProps } from './SupportingObjectList';

type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
};

type Props = {
  pictures?: Picture[];
  bookmarkable?: BookmarkableSOProps & { parentId: number };
  refetchQueries: PureQueryOptions[];
};

export function PanoramaListItem({
  pictures,
  bookmarkable,
  refetchQueries,
}: Props) {
  if (!pictures?.length) return null;

  const bookmarkableProps = (
    picture: Picture,
  ): TargetBookmarksManagerModalProps | undefined => {
    if (!bookmarkable) return undefined;
    return {
      ...bookmarkable,
      targetType: BookmarkTargetType.Picture,
      targetId: picture.id,
    };
  };

  return (
    <div>
      {pictures.map(p => (
        <PictureThumbnail
          key={p.id}
          picture={p}
          imgSrc={p.file.signedUrl}
          bookmarkable={bookmarkableProps(p)}
          refetchQueries={refetchQueries}
        />
      ))}
    </div>
  );
}
