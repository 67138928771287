import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { toast } from 'react-toastify';
import type {
  CreateLithostratWikiPageMutation,
  CreateLithostratWikiPageMutationVariables,
  LithostratWikiParentType,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { LithostratWikiFormFields } from '~/components/upload/lithostrat/wiki/LithostratWikiPageFormFields';
import type { LithostratWikiPageFormValues } from '~/utils/modules/lithostrat';
import {
  initialLithostratWikiPage,
  lithostratWikiPageValidationSchema,
} from '~/utils/modules/lithostrat';

const CREATE_LITHOSTRAT_WIKI_PAGE = gql`
  mutation CreateLithostratWikiPage(
    $parentType: LithostratWikiParentType!
    $parentId: Int!
    $wikiPage: LithostratWikiPageInput!
  ) {
    createLithostratWikiPage(
      parentType: $parentType
      parentId: $parentId
      wikiPage: $wikiPage
    ) {
      id
    }
  }
`;

type Props = {
  parentType: LithostratWikiParentType;
  parentId: number;
  parentName: string;
  refetchQueries: PureQueryOptions[];
};

export function CreateLithostratWikiPage({
  parentType,
  parentId,
  parentName,
  refetchQueries,
}: Props) {
  const [createLithostratWikiPage, { loading, error }] = useMutation<
    CreateLithostratWikiPageMutation,
    CreateLithostratWikiPageMutationVariables
  >(CREATE_LITHOSTRAT_WIKI_PAGE, {
    refetchQueries,
  });

  async function handleSubmit(values: LithostratWikiPageFormValues) {
    try {
      await createLithostratWikiPage({
        variables: {
          parentType,
          parentId,
          wikiPage: values,
        },
      });
      toast.success('Wiki page created successfully.');
    } catch (e) {
      console.log('Error creating wiki page', e);
    }
  }

  return (
    <div className="grid lg:grid-cols-12 gap-6">
      <div className="lg:col-span-7">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ ...initialLithostratWikiPage(), name: parentName }}
          validationSchema={lithostratWikiPageValidationSchema}
        >
          <Form>
            <Panel>
              <Panel.Heading>
                <Panel.Title>Create Wiki Page</Panel.Title>
              </Panel.Heading>
              <Panel.Body className="space-y-4">
                <LithostratWikiFormFields />
                <FormErrors graphQLError={error} />
              </Panel.Body>
              <Panel.Footer className="text-right">
                <Button color="primary" loading={loading}>
                  Save
                </Button>
              </Panel.Footer>
            </Panel>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
