import { Link } from 'react-router';
import type { FilterSearchProps } from '~/components/common/FilterSearch';
import { FilterSearch } from '~/components/common/FilterSearch';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import {
  OutcropApprovalIcon,
  OutcropQCIcon,
} from '~/components/upload/outcrop/outcrop-icons';
import * as routes from '~/paths';
import type { SortableOutcrop } from '~/utils/modules/outcrop';
import { ucwords } from '~/utils/text';

type Props = {
  outcrops: SortableOutcrop[];
  sortIndicatorProps: SortIndicatorProps;
  filterSearchProps: FilterSearchProps;
};

export function UploadOutcropList({
  outcrops,
  sortIndicatorProps: siProps,
  filterSearchProps,
}: Props) {
  return (
    <div className="space-y-4">
      <FilterSearch {...filterSearchProps} />

      <table className="table w-full table-pin-rows table-compact">
        <thead>
          <tr>
            <th className="text-center">
              <SortTrigger
                colName="approvalState"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={ucwords}
              >
                <Tooltip message="Approved">
                  <span>Appr.</span>
                </Tooltip>
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="id" sortIndicatorProps={siProps}>
                ID
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="geologyType"
                sortIndicatorProps={siProps}
                filterable
              >
                Geo. Type
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="region.name"
                sortIndicatorProps={siProps}
                filterable
              >
                Region
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="region.location.country"
                sortIndicatorProps={siProps}
                filterable
              >
                Country
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numStudies"
                sortIndicatorProps={siProps}
                filterable
              >
                Studies
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="hasPaleomaps"
                sortIndicatorProps={siProps}
                filterable
              >
                Paleomaps
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numVoms"
                sortIndicatorProps={siProps}
                filterable
              >
                VOMs
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="qcCompleted"
                sortIndicatorProps={siProps}
                filterable
              >
                QC
              </SortTrigger>
            </th>
          </tr>
        </thead>

        <tbody>
          <NoItemsRow colSpan={10} show={!outcrops.length} />
          {outcrops.map(outcrop => (
            <OutcropRow key={outcrop.id} outcrop={outcrop} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function OutcropRow({ outcrop }: { outcrop: SortableOutcrop }) {
  return (
    <tr>
      <td className="text-center">
        <OutcropApprovalIcon approvalState={outcrop.approvalState} />
      </td>
      <td>{outcrop.id}</td>
      <td>
        <Link to={routes.uploadOutcropUpdateRoute(outcrop.id)} className="link">
          {outcrop.name}
        </Link>
      </td>
      <td className="space-x-1">
        {outcrop.geologyType.map(gt => (
          <div key={gt} className="inline-block w-5 h-5">
            <GeologyTypeIcon geologyType={gt} hideTooltip />
          </div>
        ))}
      </td>
      <td>
        <Link
          to={routes.uploadRegionUpdateRoute(outcrop.region.id)}
          className="link"
        >
          {outcrop.region.name}
        </Link>
      </td>
      <td>{outcrop.region.location.country}</td>
      <td className="text-center">
        <Link
          to={routes.uploadOutcropUpdateStudiesRoute(outcrop.id)}
          className="link"
        >
          {outcrop.numStudies}
        </Link>
      </td>

      <td className="text-center">
        <EnabledIndicator value={outcrop.hasPaleomaps} />
      </td>

      <td className="text-center">
        <Link
          to={routes.uploadOutcropUpdateVirtualOutcropsRoute(outcrop.id)}
          className="link"
        >
          {outcrop.numVoms}
        </Link>
      </td>

      <td className="text-center">
        <OutcropQCIcon qcCompleted={outcrop.qcCompleted} />
      </td>
    </tr>
  );
}
