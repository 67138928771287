import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import React, { useState } from 'react';
import { Button } from '~/components/ui/button';
import * as fragments from '~/apollo/fragments';
import type {
  BookmarkParentType,
  BookmarkTargetType,
  TargetBookmarksQuery,
  TargetBookmarksQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { BookmarkRenderer } from '~/components/bookmark/BookmarkRenderer';
import { CreateBookmark } from '~/components/bookmark/TargetBookmarksManagerModal/CreateBookmark';
import { Heading } from '~/components/common/Heading';
import { Modal } from '~/components/common/Modal';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';
import { useModalState } from '~/hooks/modal';

type CreateCollectionTogglerProps = {
  children: React.ReactNode;
  onToggle: () => void;
  isCreating: boolean;
  isCompany?: boolean;
};

function CreateCollectionToggler({
  children,
  onToggle,
  isCreating,
  isCompany = false,
}: CreateCollectionTogglerProps) {
  return (
    <div
      className={cn(
        'border-4 border-dashed px-4',
        isCompany ? 'border-wine-600 bg-wine-100' : 'border-sky-600 bg-sky-200',
        isCreating ? 'py-4' : 'py-8',
      )}
    >
      {isCreating ? (
        <>
          <Heading level={4} className="space-x-2 mt-0">
            <FontAwesomeIcon
              icon={faBookmark}
              className={isCompany ? 'text-wine-500' : 'text-sky-500'}
            />
            <span>Create {isCompany ? 'Company' : 'Personal'} Bookmark</span>
          </Heading>
          {children}
        </>
      ) : (
        <div className="text-center space-y-4">
          <Button
            type="button"
            className={cn('btn border-0', {
              'bg-wine-600 hover:bg-wine-400 text-white': isCompany,
              'bg-sky-600 hover:bg-sky-400 text-white': !isCompany,
            })}
            onClick={onToggle}
          >
            Create {isCompany ? 'Company' : 'Personal'} Bookmark
          </Button>

          <div
            className={cn('text-base', {
              'text-wine-900': isCompany,
              'text-sky-900': !isCompany,
            })}
          >
            {isCompany
              ? 'Create a bookmark that is shared between all members of your company.'
              : 'Create a private bookmark only visible to you.'}
          </div>
        </div>
      )}

      {isCreating && (
        <div className="text-center mt-4">
          <Button type="button" color="ghost" size="xs" onClick={onToggle}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

const TARGET_BOOKMARKS = gql`
  query TargetBookmarks($targetType: BookmarkTargetType!, $targetId: Int!) {
    bookmarkList(targetType: $targetType, targetId: $targetId) {
      ...bookmarkParts
      target {
        ...bookmarkTargetParts
      }
      company {
        ...companyParts
      }
      user {
        ...publicUserParts
      }
      collections {
        ...bookmarkCollectionParts
      }
    }

    personalCollections: bookmarkCollectionList(
      includePersonal: true
      includeCompany: false
    ) {
      ...bookmarkCollectionParts
    }
    companyCollections: bookmarkCollectionList(
      includePersonal: false
      includeCompany: true
    ) {
      ...bookmarkCollectionParts
    }
  }

  ${fragments.bookmarkParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.bookmarkCollectionParts}
  ${fragments.companyParts}
  ${fragments.publicUserParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export type TargetBookmarksManagerModalProps = {
  /** Type of the target entity to load */
  targetType: BookmarkTargetType;
  /** ID of the target entity to load */
  targetId: number;
  /** Type of the parent entity when creating new bookmarks */
  parentType: BookmarkParentType;
  /** ID of the parent entity when creating new bookmarks */
  parentId: number;
  /** Full path of the target when creating new bookmarks */
  path: string;
};

export function TargetBookmarksManagerModal({
  targetType,
  targetId,
  parentType,
  parentId,
  path,
}: TargetBookmarksManagerModalProps) {
  const { authority } = useAuth();
  const user = authority?.user;
  if (!user) throw new Error('Must be authenticated');

  const { show, showModal, hideModal } = useModalState();

  const [isCreatingPersonal, setIsCreatingPersonal] = useState(false);
  const [isCreatingCompany, setIsCreatingCompany] = useState(false);

  const queryVariables: TargetBookmarksQueryVariables = {
    targetType,
    targetId,
  };

  const { data, loading, refetch } = useQuery<
    TargetBookmarksQuery,
    TargetBookmarksQueryVariables
  >(TARGET_BOOKMARKS, {
    variables: queryVariables,
  });

  const refetchQueries: [PureQueryOptions<TargetBookmarksQueryVariables>] = [
    {
      query: TARGET_BOOKMARKS,
      variables: queryVariables,
    },
  ];

  const handleCreateSuccess = () => {
    setIsCreatingPersonal(false);
    setIsCreatingCompany(false);
    refetch();
  };

  const allBookmarks = data?.bookmarkList ?? [];

  const myBookmarks = allBookmarks.filter(
    b => b.userId === user.id && !b.companyId,
  );
  const companyBookmarks = allBookmarks.filter(
    b => b.companyId === user.companyId,
  );

  const hasPersonalBookmark = myBookmarks.length > 0;
  const hasCompanyBookmark = companyBookmarks.length > 0;

  const personalCollections = data?.personalCollections ?? [];
  const companyCollections = data?.companyCollections ?? [];

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <Button
        color="ghost"
        size="sm"
        onClick={showModal}
        className="text-primary gap-1"
      >
        <FontAwesomeIcon
          icon={allBookmarks.length > 0 ? faBookmark : farBookmark}
        />
        {allBookmarks.length > 0 && ` ${allBookmarks.length}`}
      </Button>

      <Modal open={show} onHide={hideModal}>
        <Modal.Body
          heading={allBookmarks.length ? 'Bookmarks' : 'Add a bookmark'}
        >
          <div className="space-y-4">
            {myBookmarks.map(b => (
              <BookmarkRenderer
                key={b.id}
                bookmark={b}
                collections={personalCollections}
                refetchQueries={refetchQueries}
              />
            ))}

            {!hasPersonalBookmark && (
              <CreateCollectionToggler
                onToggle={() => setIsCreatingPersonal(prev => !prev)}
                isCreating={isCreatingPersonal}
              >
                <CreateBookmark
                  path={path}
                  parentType={parentType}
                  parentId={parentId}
                  targetType={targetType}
                  targetId={targetId}
                  collections={personalCollections}
                  onCreateSuccess={handleCreateSuccess}
                />
              </CreateCollectionToggler>
            )}

            {companyBookmarks.map(b => (
              <BookmarkRenderer
                key={b.id}
                bookmark={b}
                collections={companyCollections}
                refetchQueries={refetchQueries}
              />
            ))}

            {!hasCompanyBookmark && (
              <CreateCollectionToggler
                onToggle={() => setIsCreatingCompany(prev => !prev)}
                isCreating={isCreatingCompany}
                isCompany
              >
                <CreateBookmark
                  path={path}
                  parentType={parentType}
                  parentId={parentId}
                  targetType={targetType}
                  targetId={targetId}
                  collections={companyCollections}
                  onCreateSuccess={handleCreateSuccess}
                  isCompany
                />
              </CreateCollectionToggler>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
