import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import {
  faCheck,
  faCopy,
  faExclamationTriangle,
  faScroll,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import type { ReactNode } from 'react';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import {
  bookmarkParts,
  bookmarkTargetParts,
  fileParts,
  pictureParts,
  reportItemParts,
} from '~/apollo/fragments';
import type {
  CopyReportMutation,
  CopyReportMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { ReportItemType } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Modal } from '~/components/common/Modal';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useModalState } from '~/hooks/modal';
import { editReportRoute } from '~/paths';
import { reportItemTitle, reportItemTypeName } from '~/utils/modules/report';

const COPY_REPORT = gql`
  mutation CopyReport($reportId: Int!) {
    copyReport(reportId: $reportId) {
      report {
        id
      }
      items {
        sourceItem {
          ...reportItemParts
          bookmark {
            ...bookmarkParts
            target {
              ...bookmarkTargetParts
            }
          }
        }
        targetItem {
          id
          order
        }
        errorMessage
      }
    }
  }

  ${reportItemParts}
  ${bookmarkParts}
  ${bookmarkTargetParts}
  ${pictureParts}
  ${fileParts}
`;

export function CopyReportModal({
  children,
  reportId,
  isCompany,
}: {
  children: (showModal: () => void) => ReactNode;
  reportId: number;
  isCompany: boolean;
}) {
  const { show, showModal, hideModal } = useModalState();

  const [copyReport, { loading, data }] = useMutation<
    CopyReportMutation,
    CopyReportMutationVariables
  >(COPY_REPORT, { variables: { reportId } });

  async function handleSubmit() {
    try {
      await copyReport();
    } catch (err) {
      console.log('Error copying report', err);
      toast.error(
        'There was a problem copying the report, please reload the page and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Copy Report">
          <SpinnerPlaceholder show={loading} />

          {data ? (
            <CopySuccessMessage
              newReportId={data.copyReport.report.id}
              copyResult={data.copyReport}
              handleClose={hideModal}
            />
          ) : (
            <CopyInstructions
              isCompany={isCompany}
              handleSubmit={handleSubmit}
              handleCancel={hideModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

function CopyInstructions({
  isCompany,
  handleSubmit,
  handleCancel,
}: {
  isCompany: boolean;
  handleCancel: () => any;
  handleSubmit: () => any;
}) {
  return (
    <div className="space-y-4">
      <p>
        This action will create a copy of this report and its contents to{' '}
        <strong>{isCompany ? 'My' : 'Company'} Reports</strong>.
      </p>
      <p>
        All bookmarks, data bookmarks, and text items will be copied and added
        to this new report.
      </p>
      <p>
        If this report has any bookmarks associated with it, they will be
        automatically placed into a new collection so they can be easily
        located.
      </p>

      <div className="flex justify-center items-center gap-2">
        <Button type="button" color="ghost" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="button" color="primary" onClick={handleSubmit}>
          <FontAwesomeIcon icon={faCopy} /> Copy Report
        </Button>
      </div>
    </div>
  );
}

function CopySuccessMessage({
  newReportId,
  copyResult,
  handleClose,
}: {
  newReportId: number;
  copyResult: CopyReportMutation['copyReport'];
  handleClose: () => void;
}) {
  const items = R.sortBy(
    item =>
      item.targetItem
        ? (item.targetItem.order ?? item.sourceItem.order ?? Infinity)
        : -Infinity,
    copyResult.items,
  );

  const bookmarksCopied = items.filter(
    item => item.sourceItem.itemType === ReportItemType.Bookmark,
  );

  const successfulItems = items.reduce((acc, cur) => {
    if (cur.targetItem) return acc + 1;
    return acc;
  }, 0);
  const failedItems = items.reduce((acc, cur) => {
    if (!cur.targetItem) return acc + 1;
    return acc;
  }, 0);

  return (
    <div className="border border-emerald-200 bg-emerald-50 p-4 space-y-4">
      <Heading level={3}>Success</Heading>

      <div className="space-y-1">
        <p>
          The report was successfully copied with{' '}
          <strong>{successfulItems}</strong> items.
        </p>
        {failedItems > 0 && (
          <p>
            <strong className="text-error">{failedItems}</strong> items
            encountered an error when attempting to be copied.
          </p>
        )}
      </div>

      <ul className="list-inside space-y-2">
        {items.map(copiedItem => (
          <li key={copiedItem.sourceItem.id}>
            <div className="flex gap-6">
              <div>
                <FontAwesomeIcon
                  icon={copiedItem.targetItem ? faCheck : faExclamationTriangle}
                  className={
                    copiedItem.targetItem ? 'text-success' : 'text-error'
                  }
                />
              </div>
              <div>
                <strong>
                  {reportItemTypeName(copiedItem.sourceItem.itemType)}:
                </strong>{' '}
                {reportItemTitle(copiedItem.sourceItem)}
                {copiedItem.errorMessage && (
                  <div className="text-error">
                    {copyErrorMessage(copiedItem.errorMessage)}
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>

      {bookmarksCopied.length > 0 && (
        <p>
          The copied bookmarks were placed into a new Collection with report's
          name.
        </p>
      )}

      <div className="flex justify-center items-center gap-2">
        <Button type="button" color="ghost" onClick={handleClose}>
          Close
        </Button>

        <Link to={editReportRoute(newReportId)} className="btn btn-primary">
          <FontAwesomeIcon icon={faScroll} /> View Copied Report
        </Link>
      </div>
    </div>
  );
}

function copyErrorMessage(message: string) {
  switch (message) {
    case 'may_not_copy_bookmark':
      return "You don't have permission to copy this bookmark.";
    case 'bookmark_not_found':
    case 'target_deleted':
    case 'parent_deleted':
      return 'The bookmarked item or its parent could not be found.';
    default:
      return 'There was a problem copying the item.';
  }
}
