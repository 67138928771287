import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import {
  faCheckCircle,
  faNewspaper,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from '~/components/ui/button';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { reportItemParts, reportParts } from '~/apollo/fragments';
import type {
  EditReportPageQuery,
  UpdateReportMutation,
  UpdateReportMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { ReportState } from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Heading } from '~/components/common/Heading';
import { StickyBar } from '~/components/common/StickyBar';
import { ReportFormFields } from '~/components/report/ReportEditor/ReportFormFields';
import { ReportItemSummary } from '~/components/report/ReportItemSummary';
import { editReportAddItemRoute, reportRoute } from '~/paths';
import type { ReportFormValues } from '~/utils/modules/report';
import {
  initialReportFormValues,
  reportValidationSchema,
  toUpdateReportInput,
} from '~/utils/modules/report';
import { ReportEditorItems } from './ReportEditorItems';
import { PageHeading } from '~/components/common/PageHeading';
import { UpdateReportState } from '~/components/report/UpdateReportState';
import { Tooltip } from '~/components/common/Tooltip';

const UPDATE_REPORT = gql`
  mutation UpdateReport(
    $reportId: Int!
    $report: UpdateReportInput!
    $ordering: [UpdateReportItemsOrderInput!]!
  ) {
    updateReport(reportId: $reportId, report: $report) {
      ...reportParts
    }
    updateReportItemsOrder(reportId: $reportId, ordering: $ordering) {
      ...reportParts
      items {
        ...reportItemParts
      }
    }
  }

  ${reportParts}
  ${reportItemParts}
`;

type Props = {
  report: EditReportPageQuery['reportList'][number];
  refetchQueries: PureQueryOptions[];
};

export function ReportEditor({ report, refetchQueries }: Props) {
  const [updateReport, { loading, error }] = useMutation<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >(UPDATE_REPORT);

  async function handleFormSubmission(
    values: ReportFormValues,
    helpers: FormikHelpers<ReportFormValues>,
  ) {
    try {
      const result = await updateReport({
        variables: {
          reportId: report.id,
          report: toUpdateReportInput(values),
          ordering: values.ordering,
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });

      // Manually call resetForm after saving so `dirty` gets reset
      helpers.resetForm({
        values: initialReportFormValues(result.data?.updateReport),
      });

      toast.success('Report saved successfully.');
    } catch (err) {
      console.log('Error updating report', err);
      toast.error('There was a problem saving the report. Please try again.');
    }
  }

  return (
    <Formik
      onSubmit={handleFormSubmission}
      initialValues={initialReportFormValues(report)}
      validationSchema={reportValidationSchema}
      enableReinitialize
    >
      {({ handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex justify-between gap-6">
            <PageHeading className="mt-0">Report Editor</PageHeading>

            <div className="space-x-2">
              {report.state === ReportState.Draft && (
                <UpdateReportState
                  reportId={report.id}
                  isCompany={!!report.companyId}
                  isCompleted={false}
                >
                  {confirmPublish => (
                    <Tooltip
                      disabled={!dirty}
                      message="Changes must be saved before report can be published."
                    >
                      <Button
                        type="button"
                        onClick={confirmPublish}
                        color="success"
                        size="sm"
                        startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                        disabled={dirty}
                      >
                        Mark as Completed
                      </Button>
                    </Tooltip>
                  )}
                </UpdateReportState>
              )}

              <Link
                to={reportRoute(report.id)}
                target="_blank"
                className="float-right ml-2 mb-2 btn btn-primary btn-sm"
              >
                <div className="space-x-2">
                  <FontAwesomeIcon icon={faNewspaper} />
                  <span>View Report</span>
                </div>
              </Link>
            </div>
          </div>

          <ReportFormFields disabled={report.state !== ReportState.Draft} />
          <FormErrors graphQLError={error} />

          <div className="flex justify-between items-center gap-6">
            <Heading level={3}>
              Report Items
              <div className="inline-block ml-2">
                <ReportItemSummary
                  summary={report.itemSummary}
                  isCompany={!!report.companyId}
                />
              </div>
            </Heading>

            <div>
              <Link
                to={editReportAddItemRoute(report.id)}
                className={cn('btn btn-primary btn-sm', {
                  'btn-disabled': report.state !== ReportState.Draft,
                })}
              >
                <div className="block space-x-2">
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Add Item</span>
                </div>
              </Link>
            </div>
          </div>

          <div className="space-y-3">
            <ReportEditorItems
              report={report}
              items={report.items}
              refetchQueries={refetchQueries}
            />
          </div>

          <StickyBar stickTo="bottom" show={dirty}>
            <span className="mr-6">This report has unsaved changes.</span>

            <Button type="submit" color="primary" loading={loading}>
              Save Changes
            </Button>
          </StickyBar>
        </Form>
      )}
    </Formik>
  );
}
