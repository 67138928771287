import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router';
import { Alert } from '~/components/ui/alert';
import invariant from 'tiny-invariant';

import type {
  LithostratMemberRouteQuery,
  LithostratMemberRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import { LithostratTypeBacklink } from '~/components/wiki/lithostratType/LithostratTypeBacklink';
import * as fragments from '~/apollo/fragments';
import {
  lithostratMemberAnaloguesRoute,
  lithostratMemberExamplesRoute,
  lithostratMemberRoute,
  uploadLithostratMemberUpdateRoute,
} from '~/paths';

export const LITHOSTRAT_MEMBER_ROUTE = gql`
  query LithostratMemberRoute($memberId: Int!) {
    lithostratMemberList(id: $memberId, hasWiki: true) {
      ...lithostratMemberParts
      lithostratType {
        ...lithostratTypeParts
      }
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      formation {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      outcropLinkAnalogues {
        ...outcropLinkAnalogueParts
      }
      wikiPage {
        ...lithostratWikiPageParts
        keyParameters {
          ...keyParametersParts
        }
      }
    }
  }

  ${fragments.lithostratMemberParts}
  ${fragments.lithostratTypeParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.outcropLinkAnalogueParts}
  ${fragments.keyParametersParts}
`;

type OutletContext = {
  member: LithostratMemberRouteQuery['lithostratMemberList'][number];
};

export default function LithostratMemberRoute() {
  const params = useParams();
  invariant(params.memberId, 'memberId param required');
  const memberId = parseInt(params.memberId);

  const { loading, data } = useQuery<
    LithostratMemberRouteQuery,
    LithostratMemberRouteQueryVariables
  >(LITHOSTRAT_MEMBER_ROUTE, {
    variables: { memberId },
  });

  const member = data?.lithostratMemberList.find(mb => mb.id === memberId);

  useBreadcrumb(
    'routes/wiki/regional/lithostrat/member.$memberId',
    member?.name ?? 'Lithostrat Member',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!member) return <NotFound />;

  const outletContext: OutletContext = { member };

  return (
    <>
      {!member.wikiPage?.published && (
        <Alert status="info" className="p-2 block">
          <Heading level={3}>Notice</Heading>
          <p>
            Until this member is approved, it is only visible to you and Safari
            administrators.
          </p>
        </Alert>
      )}

      <div className="float-right space-x-1">
        <TargetBookmarksManagerModal
          parentType={BookmarkParentType.Member}
          parentId={member.id}
          targetType={BookmarkTargetType.Member}
          targetId={member.id}
          path={lithostratMemberRoute(member.id)}
        />

        <TargetReviewCommentsModal
          parentId={member.id}
          parentType={ReviewCommentParentType.LithostratMember}
          name={member.name}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={uploadLithostratMemberUpdateRoute(member.id)}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </RoleSecured>
      </div>

      <LithostratTypeBacklink />

      <PageHeading hasSubtitle>
        {member.name} <small>{member.formation?.name}</small>
      </PageHeading>

      <p className="text-base space-y-4">{member.description}</p>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={lithostratMemberRoute(member.id)} end>
          Description
        </NavTabs.Tab>

        {member.outcropLinkAnalogues.length > 0 && (
          <NavTabs.Tab to={lithostratMemberExamplesRoute(member.id)}>
            Examples
          </NavTabs.Tab>
        )}

        <NavTabs.Tab to={lithostratMemberAnaloguesRoute(member.id)}>
          Linked Analogues
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />
    </>
  );
}

export function useMemberDetailOutletContext() {
  return useOutletContext<OutletContext>();
}
