import Papa from 'papaparse';
import * as R from 'ramda';
import type {
  AePartsFragment,
  ArchitecturalElementInput,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type AEFormValues = {
  name: string;
  comments: string;
  connectivity: string;
  depositionalConfinement: string;
  description: string;
  outcropOrder: string;
  shapeKind: string;
  background: boolean;
  geologyType: string;
  grossDepositionalEnvironment: string;
  depositionalEnvironment: string;
  depositionalSubEnvironment: string;
  architecturalElement: string;
  outcropId: string;

  channelMorphology: string[];
  diageneticGeometry: string[];
  diageneticProcess: string[];
  diageneticSetting: string[];
  dominantLithology: string[];
  duneShape: string[];
  faultRocksMembranes: string[];
  interactionNetwork: string[];
  multipleFolds: string[];
  reactivation1stPhase: string[];
  reactivation2ndPhase: string[];
  riverProfileLocation: string[];
  secondaryStructures: string[];
  shorelineTractory: string[];
  symmetryGeometry: string[];
  synSedimentaryDeformation: string[];
  systemsTract: string[];
  tectonicSetting: string[];
  waterTemperature: string[];
  lateralAggregation: string[];
};

export const initialAEFormValues = (ae?: AePartsFragment): AEFormValues => {
  return {
    name: ae?.name ?? '',
    comments: ae?.comments ?? '',
    connectivity: ae?.connectivity ?? '',
    depositionalConfinement: ae?.depositionalConfinement ?? '',
    description: ae?.description ?? '',
    outcropOrder: `${ae?.outcropOrder ?? ''}`,
    shapeKind: ae?.shapeKind ?? '',
    background: ae?.background ?? false,
    geologyType: ae?.geologyType ?? '',
    grossDepositionalEnvironment: ae?.grossDepositionalEnvironment ?? '',
    depositionalEnvironment: ae?.depositionalEnvironment ?? '',
    depositionalSubEnvironment: ae?.depositionalSubEnvironment ?? '',
    architecturalElement: ae?.architecturalElement ?? '',
    outcropId: `${ae?.outcropId ?? ''}`,

    channelMorphology: ae?.channelMorphology ?? [],
    diageneticGeometry: ae?.diageneticGeometry ?? [],
    diageneticProcess: ae?.diageneticProcess ?? [],
    diageneticSetting: ae?.diageneticSetting ?? [],
    dominantLithology: ae?.dominantLithology ?? [],
    duneShape: ae?.duneShape ?? [],
    faultRocksMembranes: ae?.faultRocksMembranes ?? [],
    interactionNetwork: ae?.interactionNetwork ?? [],
    multipleFolds: ae?.multipleFolds ?? [],
    reactivation1stPhase: ae?.reactivation1stPhase ?? [],
    reactivation2ndPhase: ae?.reactivation2ndPhase ?? [],
    riverProfileLocation: ae?.riverProfileLocation ?? [],
    secondaryStructures: ae?.secondaryStructures ?? [],
    shorelineTractory: ae?.shorelineTractory ?? [],
    symmetryGeometry: ae?.symmetryGeometry ?? [],
    synSedimentaryDeformation: ae?.synSedimentaryDeformation ?? [],
    systemsTract: ae?.systemsTract ?? [],
    tectonicSetting: ae?.tectonicSetting ?? [],
    waterTemperature: ae?.waterTemperature ?? [],
    lateralAggregation: ae?.lateralAggregation ?? [],
  };
};

export const validationSchema = yup.object({
  // Required
  name: yup.string().required(),
  outcropId: yup.number().required(),
  geologyType: yup.string().required(),
  grossDepositionalEnvironment: yup.string().required(),
  depositionalEnvironment: yup.string().required(),
  depositionalSubEnvironment: yup.string().required(),
  architecturalElement: yup.string().nullable(),
  background: yup.boolean().required(),

  // Nullable
  comments: yup.string().nullable(),
  connectivity: yup.string().nullable(),
  depositionalConfinement: yup.string().nullable(),
  description: yup.string().nullable(),
  outcropOrder: yup.string().nullable(),

  channelMorphology: yup.array(yup.string()).nullable(),
  diageneticGeometry: yup.array(yup.string()).nullable(),
  diageneticProcess: yup.array(yup.string()).nullable(),
  diageneticSetting: yup.array(yup.string()).nullable(),
  dominantLithology: yup.array(yup.string()).nullable(),
  duneShape: yup.array(yup.string()).nullable(),
  faultRocksMembranes: yup.array(yup.string()).nullable(),
  interactionNetwork: yup.array(yup.string()).nullable(),
  multipleFolds: yup.array(yup.string()).nullable(),
  reactivation1stPhase: yup.array(yup.string()).nullable(),
  reactivation2ndPhase: yup.array(yup.string()).nullable(),
  riverProfileLocation: yup.array(yup.string()).nullable(),
  secondaryStructures: yup.array(yup.string()).nullable(),
  shorelineTractory: yup.array(yup.string()).nullable(),
  symmetryGeometry: yup.array(yup.string()).nullable(),
  synSedimentaryDeformation: yup.array(yup.string()).nullable(),
  systemsTract: yup.array(yup.string()).nullable(),
  tectonicSetting: yup.array(yup.string()).nullable(),
  waterTemperature: yup.array(yup.string()).nullable(),
  lateralAggregation: yup.array(yup.string()).nullable(),
});

/** Casts an empty array to null */
function arrayOrNull(values: string[] | null) {
  if (!values?.length) return null;
  return values;
}

export function toAEInputV3(
  formValues: AEFormValues,
): ArchitecturalElementInput {
  const input: ArchitecturalElementInput = {
    name: formValues.name,
    outcropId: parseInt(formValues.outcropId),
    geologyType: formValues.geologyType,
    grossDepositionalEnvironment: formValues.grossDepositionalEnvironment,
    depositionalEnvironment: formValues.depositionalEnvironment,
    depositionalSubEnvironment: formValues.depositionalSubEnvironment,
    architecturalElement: formValues.architecturalElement,
  };

  input.background = formValues.background || null;
  input.comments = formValues.comments || null;
  input.connectivity = formValues.connectivity || null;
  input.depositionalConfinement = formValues.depositionalConfinement || null;
  input.description = formValues.description || null;
  input.outcropOrder = formValues.outcropOrder || null;
  input.shapeKind = formValues.shapeKind || null;

  input.channelMorphology = arrayOrNull(formValues.channelMorphology);
  input.diageneticGeometry = arrayOrNull(formValues.diageneticGeometry);
  input.diageneticProcess = arrayOrNull(formValues.diageneticProcess);
  input.diageneticSetting = arrayOrNull(formValues.diageneticSetting);
  input.dominantLithology = arrayOrNull(formValues.dominantLithology);
  input.duneShape = arrayOrNull(formValues.duneShape);
  input.faultRocksMembranes = arrayOrNull(formValues.faultRocksMembranes);
  input.interactionNetwork = arrayOrNull(formValues.interactionNetwork);
  input.multipleFolds = arrayOrNull(formValues.multipleFolds);
  input.reactivation1stPhase = arrayOrNull(formValues.reactivation1stPhase);
  input.reactivation2ndPhase = arrayOrNull(formValues.reactivation2ndPhase);
  input.riverProfileLocation = arrayOrNull(formValues.riverProfileLocation);
  input.secondaryStructures = arrayOrNull(formValues.secondaryStructures);
  input.shorelineTractory = arrayOrNull(formValues.shorelineTractory);
  input.symmetryGeometry = arrayOrNull(formValues.symmetryGeometry);
  input.synSedimentaryDeformation = arrayOrNull(
    formValues.synSedimentaryDeformation,
  );
  input.systemsTract = arrayOrNull(formValues.systemsTract);
  input.tectonicSetting = arrayOrNull(formValues.tectonicSetting);
  input.waterTemperature = arrayOrNull(formValues.waterTemperature);
  input.lateralAggregation = arrayOrNull(formValues.lateralAggregation);

  return input;
}

export type BulkUploadAEField = {
  name: keyof ArchitecturalElementInput;
  multiple: boolean;
};
export const bulkUploadAEFields: BulkUploadAEField[] = [
  { name: 'name', multiple: false },
  { name: 'geologyType', multiple: false },
  { name: 'grossDepositionalEnvironment', multiple: false },
  { name: 'depositionalEnvironment', multiple: false },
  { name: 'depositionalSubEnvironment', multiple: false },
  { name: 'architecturalElement', multiple: false },
  { name: 'background', multiple: false },
  { name: 'description', multiple: false },
  { name: 'comments', multiple: false },
  { name: 'outcropOrder', multiple: false },
  { name: 'connectivity', multiple: false },
  { name: 'depositionalConfinement', multiple: false },
  { name: 'shapeKind', multiple: false },
  { name: 'outcropId', multiple: false },
  { name: 'channelMorphology', multiple: true },
  { name: 'diageneticGeometry', multiple: true },
  { name: 'diageneticProcess', multiple: true },
  { name: 'diageneticSetting', multiple: true },
  { name: 'dominantLithology', multiple: true },
  { name: 'duneShape', multiple: true },
  { name: 'faultRocksMembranes', multiple: true },
  { name: 'interactionNetwork', multiple: true },
  { name: 'multipleFolds', multiple: true },
  { name: 'reactivation1stPhase', multiple: true },
  { name: 'reactivation2ndPhase', multiple: true },
  { name: 'riverProfileLocation', multiple: true },
  { name: 'secondaryStructures', multiple: true },
  { name: 'shorelineTractory', multiple: true },
  { name: 'symmetryGeometry', multiple: true },
  { name: 'synSedimentaryDeformation', multiple: true },
  { name: 'systemsTract', multiple: true },
  { name: 'tectonicSetting', multiple: true },
  { name: 'waterTemperature', multiple: true },
  { name: 'lateralAggregation', multiple: true },
];

export const csvRowToAEInput = (row: string[]): ArchitecturalElementInput => {
  const splitMultiple = (value: string | undefined) => {
    if (!value?.length) return null;
    return value.split('|').map(str => str.trim());
  };

  return {
    name: row[0],
    geologyType: row[1],
    grossDepositionalEnvironment: row[2],
    depositionalEnvironment: row[3],
    depositionalSubEnvironment: row[4],
    architecturalElement: row[5] || null,
    background: row[6] === '0' ? false : true,
    description: row[7] || null,
    comments: row[8] || null,
    outcropOrder: row[9] || null,
    connectivity: row[10] || null,
    depositionalConfinement: row[11] || null,
    shapeKind: row[12] || null,
    outcropId: parseInt(row[13]),

    channelMorphology: splitMultiple(row[14]),
    diageneticGeometry: splitMultiple(row[15]),
    diageneticProcess: splitMultiple(row[16]),
    diageneticSetting: splitMultiple(row[17]),
    dominantLithology: splitMultiple(row[18]),
    duneShape: splitMultiple(row[19]),
    faultRocksMembranes: splitMultiple(row[20]),
    interactionNetwork: splitMultiple(row[21]),
    multipleFolds: splitMultiple(row[22]),
    reactivation1stPhase: splitMultiple(row[23]),
    reactivation2ndPhase: splitMultiple(row[24]),
    riverProfileLocation: splitMultiple(row[25]),
    secondaryStructures: splitMultiple(row[26]),
    shorelineTractory: splitMultiple(row[27]),
    symmetryGeometry: splitMultiple(row[28]),
    synSedimentaryDeformation: splitMultiple(row[29]),
    systemsTract: splitMultiple(row[30]),
    tectonicSetting: splitMultiple(row[31]),
    waterTemperature: splitMultiple(row[32]),
    lateralAggregation: splitMultiple(row[33]),
  };
};

export const formatProcessedAEForBulkUpload = (
  processedAE: ArchitecturalElementInput,
) => R.omit(['__typename'], processedAE);

type AEKey = keyof ArchitecturalElementInput;
export const formatAEForExport = (
  ae: AePartsFragment,
): Partial<Record<AEKey, ArchitecturalElementInput[AEKey]>> => {
  const joinMultiple = (value?: string[] | null) => {
    if (!value?.length) return '';
    return value.join('|');
  };

  return {
    name: ae.name,
    geologyType: ae.geologyType ?? '',
    grossDepositionalEnvironment: ae.grossDepositionalEnvironment,
    depositionalEnvironment: ae.depositionalEnvironment,
    depositionalSubEnvironment: ae.depositionalSubEnvironment,
    architecturalElement: ae.architecturalElement,
    background: ae.background === true ? '1' : '0',
    description: ae.description,
    comments: ae.comments,
    outcropOrder: ae.outcropOrder ? String(ae.outcropOrder) : '',
    connectivity: ae.connectivity,
    depositionalConfinement: ae.depositionalConfinement,
    shapeKind: ae.shapeKind,
    outcropId: String(ae.outcropId),

    channelMorphology: joinMultiple(ae.channelMorphology),
    diageneticGeometry: joinMultiple(ae.diageneticGeometry),
    diageneticProcess: joinMultiple(ae.diageneticProcess),
    diageneticSetting: joinMultiple(ae.diageneticSetting),
    dominantLithology: joinMultiple(ae.dominantLithology),
    duneShape: joinMultiple(ae.duneShape),
    faultRocksMembranes: joinMultiple(ae.faultRocksMembranes),
    interactionNetwork: joinMultiple(ae.interactionNetwork),
    multipleFolds: joinMultiple(ae.multipleFolds),
    reactivation1stPhase: joinMultiple(ae.reactivation1stPhase),
    reactivation2ndPhase: joinMultiple(ae.reactivation2ndPhase),
    riverProfileLocation: joinMultiple(ae.riverProfileLocation),
    secondaryStructures: joinMultiple(ae.secondaryStructures),
    shorelineTractory: joinMultiple(ae.shorelineTractory),
    symmetryGeometry: joinMultiple(ae.symmetryGeometry),
    synSedimentaryDeformation: joinMultiple(ae.synSedimentaryDeformation),
    systemsTract: joinMultiple(ae.systemsTract),
    tectonicSetting: joinMultiple(ae.tectonicSetting),
    waterTemperature: joinMultiple(ae.waterTemperature),
    lateralAggregation: joinMultiple(ae.lateralAggregation),
  };
};

export const formatAsCSV = (aes: AePartsFragment[]) =>
  Papa.unparse(aes.map(formatAEForExport), {
    columns: bulkUploadAEFields.map(f => f.name),
    header: true,
    delimiter: ';',
  });
