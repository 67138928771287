import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_SEDIMENTARY_LOG,
  DELETE_SEDIMENTARY_LOG,
  UPDATE_SEDIMENTARY_LOG,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropUpdateSedimentaryLogsTabQuery,
  UploadOutcropUpdateSedimentaryLogsTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const UPLOAD_OUTCROP_UPDATE_SEDIMENTARY_LOGS_TAB = gql`
  query UploadOutcropUpdateSedimentaryLogsTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      sedimentaryLogs {
        ...sedimentaryLogParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        georeference {
          ...georeferenceParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.sedimentaryLogParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
`;

export default function UpdateOutcropSedimentaryLogsRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateSedimentaryLogsTabQuery,
    UploadOutcropUpdateSedimentaryLogsTabQueryVariables
  >(UPLOAD_OUTCROP_UPDATE_SEDIMENTARY_LOGS_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateSedimentaryLogsTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_UPDATE_SEDIMENTARY_LOGS_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const sedlogs = outcrop?.sedimentaryLogs ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SupportObjectManager
      outcropId={outcropId}
      supportObject={sedlogs}
      supportObjectType="sedimentaryLog"
      parentType="SEDIMENTARY_LOG"
      createMutation={CREATE_SEDIMENTARY_LOG}
      updateMutation={UPDATE_SEDIMENTARY_LOG}
      deleteMutation={DELETE_SEDIMENTARY_LOG}
      refetchQueries={refetchQueries}
    />
  );
}
