import type { UpdatePaleomapPageQuery } from '~/apollo/generated/v3/graphql';
import type { LithostratAgeFormValues } from '~/utils/modules/lithostratAge';
import { initialLithostratAge } from '~/utils/modules/lithostratAge';
import { yup } from '~/utils/validation';

export interface PaleoMapFormValues {
  paleoMap: {
    description: string;
  };
  age: LithostratAgeFormValues;
}

export const defaultPaleoMap = (
  p?: UpdatePaleomapPageQuery['paleoMapList'][number],
): PaleoMapFormValues => ({
  paleoMap: {
    description: p?.description ?? '',
  },
  age: initialLithostratAge(p?.age ?? undefined),
});

export const paleoMapValidationSchema = () =>
  yup.object({
    paleoMap: yup.object({
      description: yup.string().label('description').min(1).required(),
    }),
  });
