import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { toast } from 'react-toastify';

import type {
  DeleteOutcropStudyLinkMutation,
  DeleteOutcropStudyLinkMutationVariables,
} from '~/apollo/generated/v3/graphql';

export const DELETE_OUTCROP_STUDY_LINK = gql`
  mutation DeleteOutcropStudyLink($outcropId: Int!, $studyId: Int!) {
    deleteOutcropStudyLink(outcropId: $outcropId, studyId: $studyId)
  }
`;

type Props = {
  outcropId: number;
  studyId: number;
  refetchQueries: PureQueryOptions[];
  children: (deleteLink: () => void, loading: boolean) => JSX.Element;
};

export function DeleteOutcropLink({
  outcropId,
  studyId,
  refetchQueries = [],
  children,
}: Props) {
  const [deleteLink, { loading }] = useMutation<
    DeleteOutcropStudyLinkMutation,
    DeleteOutcropStudyLinkMutationVariables
  >(DELETE_OUTCROP_STUDY_LINK, {
    variables: { outcropId, studyId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteLink();
      toast.success('Link removed successfully.');
    } catch (err) {
      console.log('Error deleting outcrop study link', err);
      toast.error('There was a problem removing the link.');
    }
  }

  return children(handleDelete, loading);
}
