import {
  faCancel,
  faCheckCircle,
  faCloudUpload,
  faExclamationTriangle,
  faHourglass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DropzoneProps } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { ListGroup } from '~/components/common/ListGroup';
import { Tooltip } from '~/components/common/Tooltip';
import { Button } from '~/components/ui/button';
import type {
  FilePart,
  QueueItem,
  UseUploadQueueConfig,
} from '~/components/upload/file/QueueableMultipartUpload/useUploadQueue';
import { useUploadQueue } from '~/components/upload/file/QueueableMultipartUpload/useUploadQueue';
import { cn } from '~/utils/common';

type Props = {
  uploadConfig: UseUploadQueueConfig;
};

export function QueueableMultipartUpload({ uploadConfig: config }: Props) {
  const { queue, addFiles, startUpload, abortItem } = useUploadQueue(config);

  const handleDrop: DropzoneProps['onDrop'] = acceptedFiles => {
    addFiles(acceptedFiles);
  };

  const hasPendingItems =
    queue.items.filter(item =>
      ['pending', 'failed'].some(s => item.status === s),
    ).length > 0;

  return (
    <div className="space-y-4">
      <div className="w-full">
        <Dropzone onDrop={handleDrop} multiple>
          {({ getRootProps, getInputProps }) => (
            <DropzoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <span>Drop files here or click to browse.</span>
            </DropzoneContainer>
          )}
        </Dropzone>
      </div>

      <div className="">
        <ListGroup>
          {queue.items.map(item => (
            <ListGroup.Item key={item.tmpId}>
              <div className="flex justify-between">
                <div>{item.file.name}</div>
                <div className="w-28 text-right space-x-2">
                  {(item.status === 'failed' || item.status === 'aborting') && (
                    <Tooltip message="Cancel this upload">
                      <Button
                        type="button"
                        onClick={() => abortItem(item.tmpId)}
                        size="xs"
                        color="ghost"
                        startIcon={
                          <FontAwesomeIcon
                            icon={faCancel}
                            className="text-muted"
                          />
                        }
                        loading={item.status === 'aborting'}
                      />
                    </Tooltip>
                  )}
                  <ItemStatus item={item} />
                </div>
              </div>
              {item.error && (
                <div className="text-error mt-1">{item.error}</div>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>

      <div className="text-center">
        <Button
          type="button"
          onClick={startUpload}
          disabled={!hasPendingItems || queue.status !== 'idle'}
          loading={queue.status === 'uploading'}
          startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
          color="primary"
        >
          Upload
        </Button>
      </div>
    </div>
  );
}

function ItemStatus({ item }: { item: QueueItem }) {
  switch (item.status) {
    case 'pending':
      return <FontAwesomeIcon icon={faHourglass} className="text-slate-200" />;
    case 'uploading':
      return <ProgressBar parts={item.parts} />;
    case 'success':
      return <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
    case 'failed':
    case 'aborting':
      return (
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-error" />
      );
  }
}

function ProgressBar({ parts }: { parts: FilePart[] }) {
  const partsDone = parts.filter(part => part.status !== 'pending').length;
  const hasFailedPart = !!parts.find(p => p.status === 'failed');

  return (
    <progress
      className={cn(
        'progress',
        hasFailedPart ? 'progress-error' : 'progress-primary',
      )}
      max={parts.length}
      value={partsDone}
    />
  );
}
